import type { AddonAdvertisingData } from '../../../addons/addon-playout-data';
import type { AdBreaks, VodAd } from '../../../addons/adverts/common';
import type { NonLinearAdData } from '../../../addons/adverts/non-linear-adverts/non-linear-ad-types';

export type CsaiAdBreaks = {
    adBreaks: AdBreaks<VodAd>;
    nonLinearAds: Array<NonLinearAdData>;
};

export abstract class BaseCsaiAdBreaksProvider {
    public abstract getAdBreaks(advertisingData: AddonAdvertisingData): Promise<CsaiAdBreaks>;
}
