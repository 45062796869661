import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';

import { InternalSessionState } from '../../core/session-controller/internal-session-state';
import type { InternalSessionInterface, PlaybackTimeline } from '../../core/session-controller/session-controller-internal';
import { sdkLogger } from '../../logger';
import type { Addon } from '../addon';
import type { Ad, AdBreak } from '../adverts/common';

export class AdvertBufferingBreadcrumbAddon implements Addon {
    public name = 'Advert Buffering Breadcrumb Addon';
    private receivedAdBreaks: Array<AdBreak> | null = null;
    private currentAdBreak: AdBreak | null = null;
    private currentAd: Ad | null = null;
    private logger: Logger = sdkLogger.withContext('DLA');
    private currentState: InternalSessionState = InternalSessionState.Initialized;
    private currentPosition?: number;

    constructor(session: InternalSessionInterface) {
        session.onAdBreakDataReceived(this.handleAdBreakDataReceived);
        session.onAdBreakStarted(this.handleAdBreakStarted);
        session.onAdBreakFinished(this.handleAdBreakFinished);
        session.onAdStarted(this.handleAdStarted);
        session.onAdFinished(this.handleAdFinished);
        session.onStateChanged(this.handleSessionStateChanged);
        session.onPlaybackTimelineUpdated(this.handlePlaybackTimelineChanged);
    }

    public destroy(): void {}

    private handleAdBreakDataReceived = (adBreaks: Array<AdBreak>): void => {
        this.receivedAdBreaks = adBreaks;
    };

    private handleAdBreakStarted = (adBreak: AdBreak): void => {
        this.currentAdBreak = adBreak;
    };

    private handleAdBreakFinished = (): void => {
        this.currentAdBreak = null;
    };

    private handleAdStarted = (ad: Ad): void => {
        this.currentAd = ad;
    };

    private handleAdFinished = (): void => {
        this.currentAd = null;
    };

    private handleSessionStateChanged = (nextState: InternalSessionState): void => {
        if (this.currentState !== InternalSessionState.Rebuffering && nextState === InternalSessionState.Rebuffering) {
            this.logRebufferingBreadcrumbs();
        }

        this.currentState = nextState;
    };

    private handlePlaybackTimelineChanged = ({ position }: PlaybackTimeline): void => {
        this.currentPosition = position;
    };

    private logRebufferingBreadcrumbs = (): void => {
        if (!this.receivedAdBreaks) {
            return;
        }

        if (this.currentAdBreak) {
            const adId = this.currentAd?.id || 'unknown';
            const adName = this.currentAd?.name || 'unknown';
            this.logger.breadcrumb(`Rebuffering in break id: ${this.currentAdBreak.id}, ad id: ${adId}, ad name: ${adName}`);
        } else {
            this.logger.breadcrumb(`Rebuffering in content at adapted position: ${this.currentPosition}`);
        }
    };
}
