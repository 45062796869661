import type { DeviceInformation } from '@sky-uk-ott/client-lib-js-device';

import type { DvrWindowDuration, PlaybackType, StreamVendor } from '../../core/player/playout-data';
import type { VideoColourSpace, VideoFormat } from '../../core/player/video-format';
import type { SessionRestartControllerVpfRetryConfig } from '../../core/session-controller/restart/session-restart-controller';
import type { CompatibleStreamPropertyGroup } from '../../core/player/stream-capability-specification';
import type { PartialDeep } from '../../utils/partial-deep';
import type { SdkAddonsRemoteConfigSplit } from '../adapters/addons-config-adapter/addons-config-adapter';
import type { SdkPlayersRemoteConfigSplit } from '../adapters/players-config-adapter/players-config-adapter';
import type { Metalist, PrefetchingConfig } from '../internal-config';
import type { CORE_VIDEO_CONFIG_VALUE_UNDEFINED } from './core-video-config-provider';
import type { SdkConfig, SdkConfigWithRemoteConfig } from '../sdk-config';
import type { MatcherType } from './core-video-config.enums';
export { MatcherType } from './core-video-config.enums';

export type CoreVideoConfigMetadata = {
    configIdentifier: string;
    configTimestamp?: string;
    appliedDeviceMatchers?: Array<string>;
    appliedPlaybackTypeOverride?: PlaybackType;
    cvsdkConfigVersionPath?: string;
};

export type RemoteConfigurationServiceClientConfig = { enabled: boolean; host: string };

export function isRemoteConfigEnabled(sdkConfig: SdkConfig): sdkConfig is SdkConfigWithRemoteConfig {
    return Boolean(sdkConfig.remoteConfigurationService?.enabled);
}

export type OptionalParamsBecomeCustomUndefined<T> = T extends undefined
    ? typeof CORE_VIDEO_CONFIG_VALUE_UNDEFINED
    : T extends Function
      ? never
      : T extends object
        ? { [P in keyof T]: OptionalParamsBecomeCustomUndefined<T[P]> }
        : T;

export type PreferredStreamPropertyGroup = {
    maxFrameRate: number;
    supportedColourSpaces: Array<keyof typeof VideoColourSpace>;
    maxVideoFormat: keyof typeof VideoFormat;
    codecs?: Array<string>;
};

export type AllowedDvrWindowDurations = { [key in StreamVendor]?: Array<DvrWindowDuration> };

// Base Config
// Config that is stored in the `default` field and used partially
// inside each playback type or device matcher override
export type CoreVideoConfigBase = {
    hasExtendedDvrSupport?: boolean;
    addons?: SdkAddonsRemoteConfigSplit['remoteConfig'];
    players?: SdkPlayersRemoteConfigSplit['remoteConfig'];
    preferredStreamPropertyGroups?: Array<PreferredStreamPropertyGroup>;
    allowedDvrWindowDurations?: AllowedDvrWindowDurations;
    prefetching?: PrefetchingConfig;
    vpfRetry?: SessionRestartControllerVpfRetryConfig;
    allowedAudioCodecs?: Array<string>;
    metalist?: Metalist;
};

// Merged Config
// Config after it has been merged with device matchers and
// PlaybackType overrides (undefined if there were no overrides for that PlaybackType)
export type CoreVideoConfigMerged = Omit<CoreVideoConfigBase, 'preferredStreamPropertyGroups'> & {
    configMetadata: CoreVideoConfigMetadata;
    preferredStreamPropertyGroups?: Array<CompatibleStreamPropertyGroup>;
};

type PartialBaseConfig = PartialDeep<CoreVideoConfigBase>;

// Playback Types Config
// Config that includes the based config on `default` and any
// Playback specific overrides as partials of the base config
export type CoreVideoConfigWithPlaybackTypes = { [key in PlaybackType]?: PartialBaseConfig } & {
    default: CoreVideoConfigBase;
};

export type StringMatcher = {
    type: MatcherType.String;
    is?: Array<string>;
    isNot?: Array<string>;
};

export type VersionMatcher = {
    type: MatcherType.Version;
    test: string; // Using syntax from @npm/semver `satisfies`
};

export type NumberMatcher = {
    type: MatcherType.Number;
    min?: number;
    max?: number;
    isNot?: Array<number>;
};

type BasicMatcher = string;
type ComplexMatcher = StringMatcher | VersionMatcher | NumberMatcher;
export type MatcherValue = BasicMatcher | ComplexMatcher;

export type MatcherObject = { [key in keyof DeviceInformation]?: MatcherValue };

export type DeviceMatcherSubConfig = {
    readableIdentifier: string;
    matchers: Array<MatcherObject>;
    config: PartialDeep<CoreVideoConfigWithPlaybackTypes>;
};

// Config With Device Matchers
// Config that extends the Playback Types Config with a device matchers array.
// The device matchers contain partials of the playback types config and will be
// used to override specific values based on matching device information
export type CoreVideoConfigWithDeviceMatchers = CoreVideoConfigWithPlaybackTypes & {
    deviceMatchers: Array<DeviceMatcherSubConfig>;
};

// Compiled Config
// Duplicate of the Device Matchers config, except that every optional parameter
// is extended to allow for the custom "undefined" string. Since JSON does not support
// actual `undefined` values, this string is used to store fake "undefined" and we
// replace all references to it with `undefined` once the file is fetched.
export type CoreVideoConfigCompiled = OptionalParamsBecomeCustomUndefined<CoreVideoConfigWithDeviceMatchers> & {
    cvsdkCommitHash: string;
    cvsdkVersion: string;
    configIdentifier: string;
    configTimestamp: string;
};
