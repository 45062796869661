import type { AdvertsManager } from '../../addons/adverts-manager';
import { sdkLogger } from '../../logger';
import type { PlaybackTimelineInternal } from '../session-controller/session-controller-internal';
import type {
    OnEventCueUpdatedCallback,
    EventCue,
    EventCueCallback,
    EventTrack,
    EventCueTrigger,
    OnEventCueRegisteredCallback,
    InternalRegisteredEventCue,
    EventCueType,
} from './event-types';
import { calculateStreamPositionFromDate } from './event-utils';
import { CvsdkEventCueType } from './event.enums';

/**
 * Helper map that determines firing priority of event types when they have overlapping timestamps.
 * Start events always take precedence over exit events.
 */
const EVENT_CUE_TYPE_PRIORITY_MAP = {
    start: {
        [CvsdkEventCueType.AdBreak]: 4,
        [CvsdkEventCueType.Ad]: 5,
        [CvsdkEventCueType.AdTracking]: 6,
        // Ad events take precedence
        [CvsdkEventCueType.Custom]: 8,
    },
    end: {
        [CvsdkEventCueType.AdBreak]: 3,
        [CvsdkEventCueType.Ad]: 2,
        [CvsdkEventCueType.AdTracking]: 1,
        // Ad events take precedence
        [CvsdkEventCueType.Custom]: 7,
    },
};

/** Helper map to determine if a cue's timestamps are already based on stream time as opposed to the content time */
const STREAM_TIME_CUE_TYPE_MAP: Set<string> = new Set([CvsdkEventCueType.AdBreak, CvsdkEventCueType.Ad, CvsdkEventCueType.AdTracking]);

/* Reset firedLast values for the start/end events on the current cue id, then set firedLast on the current trigger */
const setFiredLast = (cueTriggers: Array<EventCueTrigger>, trigger: EventCueTrigger) => {
    for (const target of cueTriggers) {
        if (target.cue.id === trigger.cue.id) {
            target.firedLast = false;
        }
    }
    trigger.firedLast = true;
};

const adCues: Array<EventCueType> = [CvsdkEventCueType.AdBreak, CvsdkEventCueType.Ad, CvsdkEventCueType.AdTracking];
/**
 * Responsible for storing the cues and the callbacks, When registering points
 * with the EventTrack, is responsible for passing the right callbacks to the
 * EventTrack. The EventTrack is only responsible for invoking the callback at the
 * correct time
 */
export class EventManager {
    private cueStartCallbacks = new Map<string, Array<EventCueCallback>>();
    private cueEndCallbacks = new Map<string, Array<EventCueCallback>>();
    private cueUpdatedCallbacks = new Map<string, Array<OnEventCueUpdatedCallback>>();
    private cueRegisteredCallbacks = new Map<string, Array<OnEventCueRegisteredCallback>>(); // Note: We pass a normal EventCue as it's possible for the RegisteredEventCue properties to not be available right away
    private cueUnregisteredCallbacks = new Map<string, Array<EventCueCallback>>();
    private cueTriggers: Array<EventCueTrigger> = []; // From a cue we will have a start trigger and an end trigger in this array
    private cuesPendingDateTranslation: Map<string, EventCue> = new Map(); // If clients provide a date in the startTime or endTime, we store it here until we have a seekableRange we can use to translate it back into positions
    private cueIdsPendingUnregistration: Set<string> = new Set(); // For non-persistent cues we need to unregister them, but will be deferred to the next second, as the eventTrack could still be iterating them;
    private currentIdIncrement = 1;
    private logger = sdkLogger.withContext('EventManager');
    private completedCueIds: Set<string> = new Set();

    constructor(
        private eventTrack: EventTrack,
        onPlaybackTimelineUpdated: (callback: (playbackTimeline: PlaybackTimelineInternal) => void) => void,
        private advertsManager: AdvertsManager
    ) {
        onPlaybackTimelineUpdated((playbackTimeline) => {
            this.unregisterCuesFallenOutOfLiveWindow(playbackTimeline);
            this.unregisterNonPersistentCues();
            if (this.cuesPendingDateTranslation.size > 0) {
                this.translateDatesToPositionsAndCompleteRegistration(playbackTimeline);
            }
        });
    }

    private toStreamTime(cue: EventCue, time: number): number {
        if (STREAM_TIME_CUE_TYPE_MAP.has(cue.type)) {
            return time;
        }
        return this.advertsManager.contentTimeToTimeIncludingAds(time);
    }

    public registerEventCue<T>(cue: EventCue<T>): string {
        const cueId = this.internalRegisterEventCue(cue, this.generateCueId(cue));

        const registerCallback = this.cueRegisteredCallbacks.get(cue.type);

        if (registerCallback) {
            registerCallback.forEach((callback) => callback(cueId, cue));
        }

        return cueId;
    }

    /**
     * Incase of UnboundedEventCue updateEndTimeForEventCue will provide a way to update cueTriggers and eventTrack
     * @param cueId
     * @param newTime
     * @returns Boolean
     */
    public updateEventCueEndTime(cueId: string, newTime: number): boolean {
        const cueEndTrigger = this.cueTriggers.find((trigger) => trigger.cue.id === cueId && trigger.eventToFire === 'end');
        if (!cueEndTrigger) return false;
        cueEndTrigger.timeToFire = newTime;
        cueEndTrigger.cue.endTime = newTime;

        const updatedCallback = this.cueUpdatedCallbacks.get(cueEndTrigger.cue.type);

        if (updatedCallback) {
            updatedCallback.forEach((callback) => callback(cueId, cueEndTrigger.cue));
        }

        this.eventTrack.updateCueEndTime(cueEndTrigger.cue.id, newTime, this.getAndFireEligibleCallbacks);
        return true;
    }

    /**
     * This callback is passed to the event track and triggered whenever an eligible cue start or cue end time is reached.
     * This method itself is responsible for determining which events should fire and invoking them. Since we have an
     * array of all the startTime, endTimes and types in the `this.cueTriggers` array, we can filter this down to the time
     * and sort them in priority order.
     * @param time
     * @returns
     */
    private getAndFireEligibleCallbacks = (time: number) => {
        const eligibleCueTriggers = this.cueTriggers.filter((trigger) => trigger.timeToFire === time);
        const cueTriggersInPriorityOrder = eligibleCueTriggers.sort((a, b) => {
            const prA = this.calculatePriority(a.cue.type, a.eventToFire);
            const prB = this.calculatePriority(b.cue.type, b.eventToFire);
            return prA - prB;
        });

        const target = this.getNextEventTrigger(cueTriggersInPriorityOrder);
        if (target) {
            target.count++;
            const callbacks =
                target.eventToFire === 'start' ? this.cueStartCallbacks.get(target.cue.type) : this.cueEndCallbacks.get(target.cue.type);

            if (!callbacks) return;
            target.cue.isRepeatEvent = this.completedCueIds.has(target.cue.id);
            callbacks.forEach((callback) => callback(target!.cue));

            if (target.eventToFire === 'end') {
                this.completedCueIds.add(target.cue.id);

                if (!target.cue.isPersistent) {
                    this.cueIdsPendingUnregistration.add(target.cue.id);
                }
            }
        }
    };

    /**
     * Private because we shouldn't pass id from outside
     *
     * Register an EventCue, if the EventCue has been provided with Dates for startTime and endTime instead of numbers,
     * it will held temporarily in `cuesPendingDateTranslation` until the seekableRange is available and we can
     * translate the date to a stream position. When the seekableRange is available. We call `translateDatesToPositionsAndCompleteRegistration`
     * which will call this method again, this time passing in its existingId.
     *
     * @param cue
     * @param id passed in when attempting to re-register a cue which formerly had Dates for its times instead of stream positions
     * @returns
     */
    private internalRegisterEventCue<T>(cue: EventCue<T>, id: string): string {
        id ??= this.generateCueId(cue);
        this.logger.verbose(`attempting to register cue with id of ${id}`);

        /**
         * This callback is passed to the event track and triggered whenever an eligible cue start or cue end time is reached.
         * This method itself is responsible for determining which events should fire and invoking them. Since we have an
         * array of all the startTime, endTimes and types in the `this.cueTriggers` array, we can filter this down to the time
         * and sort them in priority order.
         * @param streamTime
         * @returns
         */
        const getAndFireEligibleCallback = (streamTime: number) => {
            const eligibleCueTriggers = this.cueTriggers.filter((trigger) => {
                if (trigger.timeToFire === streamTime) {
                    if (trigger.eventToFire === 'end') {
                        const startCue = this.cueTriggers.find(
                            (cueTrigger) => cueTrigger.cue.id === trigger.cue.id && cueTrigger.eventToFire === 'start'
                        );
                        // Check if the cue's start event fired last out of the start/end pair. If not, don't fire the end event
                        return !(startCue && startCue.firedLast === false);
                    } else {
                        return !trigger.firedLast;
                    }
                } else {
                    return false;
                }
            });
            const cueTriggersInPriorityOrder = eligibleCueTriggers.sort((a, b) => {
                const prA = this.calculatePriority(a.cue.type, a.eventToFire);
                const prB = this.calculatePriority(b.cue.type, b.eventToFire);
                return prA - prB;
            });

            const target = this.getNextEventTrigger(cueTriggersInPriorityOrder);
            if (target) {
                target.count++;
                setFiredLast(this.cueTriggers, target);
                const callbacks =
                    target.eventToFire === 'start' ? this.cueStartCallbacks.get(target.cue.type) : this.cueEndCallbacks.get(target.cue.type);

                if (!callbacks) return;
                target.cue.isRepeatEvent = this.completedCueIds.has(target.cue.id);
                callbacks.forEach((callback) => callback(target.cue));

                if (target.eventToFire === 'end') {
                    this.completedCueIds.add(target.cue.id);

                    if (!target.cue.isPersistent) {
                        this.cueIdsPendingUnregistration.add(target.cue.id);
                    }
                }
            }
        };

        if (cue.startTime instanceof Date || cue.endTime instanceof Date) {
            // queue them until we have the seekableRange available, then we can calculate them and translate them into stream positions
            // unfortunately we don't know we will have the seekableRange available at this moment in time so we have to queue them and rely on
            // translateDatesToPositionsAndCompleteRegistration to finish up
            this.cuesPendingDateTranslation.set(id, cue);
        } else {
            // this cue already has startTime and endTime as a stream position, these are ready to register
            const registeredEventCue: InternalRegisteredEventCue = {
                id: id,
                type: cue.type,
                startTime: this.toStreamTime(cue, cue.startTime),
                endTime: this.toStreamTime(cue, cue.endTime),
                data: cue.data,
                isPersistent: cue.isPersistent,
            };
            const triggers = this.createEventCueTriggers(registeredEventCue);
            this.cueTriggers.push(...triggers);

            this.eventTrack.registerCue(id, registeredEventCue, getAndFireEligibleCallback);
        }

        return id;
    }

    /** Please note the seekable range is a smaller window nested inside the live window that provides some tolerance */
    private unregisterCuesFallenOutOfLiveWindow({ liveWindow }: PlaybackTimelineInternal) {
        if (!liveWindow) return;
        const dvrWindowStart: number = liveWindow.start;
        const cuesIdsToRemove: Array<string> = [];
        for (let i = 0; i < this.cueTriggers.filter((t) => t.eventToFire === 'end').length; i++) {
            const trigger = this.cueTriggers[i];
            if (!adCues.includes(trigger.cue.type) && (trigger.cue.endTime as number) < dvrWindowStart) {
                this.logger.verbose(`Cue "${trigger.cue.id}" falls out of the DVR window`);
                cuesIdsToRemove.push(trigger.cue.id);
            }
        }
        cuesIdsToRemove.forEach((id) => this.unregisterEventCue(id));
    }

    private unregisterNonPersistentCues() {
        if (this.cueIdsPendingUnregistration.size > 0) {
            this.cueIdsPendingUnregistration.forEach((id) => this.unregisterEventCue(id));
            this.cueIdsPendingUnregistration.clear();
        }
    }

    private translateDatesToPositionsAndCompleteRegistration(playbackTimeline: PlaybackTimelineInternal) {
        if (!playbackTimeline.seekableRange?.startDate && !playbackTimeline.absolutePosition) return;

        this.cuesPendingDateTranslation.forEach((cue, id) => {
            cue.startTime = calculateStreamPositionFromDate(cue.startTime as Date, playbackTimeline);
            cue.endTime = calculateStreamPositionFromDate(cue.endTime as Date, playbackTimeline);
            this.internalRegisterEventCue(cue, id);
        });
        this.cuesPendingDateTranslation.clear();
    }

    private generateCueId<T>(cue: EventCue<T>): string {
        return `${cue.type}-${this.currentIdIncrement++}`;
    }

    private calculatePriority(type: string, position: 'start' | 'end'): number {
        if ([CvsdkEventCueType.AdBreak, CvsdkEventCueType.AdTracking, CvsdkEventCueType.Ad].includes(type as CvsdkEventCueType)) {
            const eventCueType = type as CvsdkEventCueType;
            return EVENT_CUE_TYPE_PRIORITY_MAP[position][eventCueType];
        } else {
            return EVENT_CUE_TYPE_PRIORITY_MAP[position][CvsdkEventCueType.Custom];
        }
    }

    /**
     * Given an array of triggers, works out which trigger is next to be fired, by returning either the
     * first item with a count lower than its previous, or the first trigger item if all the counts are
     * the same.
     * @param cueTriggers an array of triggers, a mixture of start and end events ordered by priority
     * @returns the next cueTrigger to fire
     */
    private getNextEventTrigger(cueTriggers: Array<EventCueTrigger>): EventCueTrigger | null {
        let targetTrigger: EventCueTrigger | null = null;
        for (let i = 0; i < cueTriggers.length; i++) {
            targetTrigger = cueTriggers[i];
            const nextTrigger = cueTriggers[i + 1];
            if (!nextTrigger) break;
            if (targetTrigger.count > nextTrigger.count) {
                // return the first one which is smaller
                return nextTrigger;
            }
        }
        // they're all the same return the first
        return cueTriggers[0];
    }

    public unregisterEventCue(cueId: string): boolean {
        const triggerStartIndex = this.cueTriggers.findIndex((trigger) => trigger.cue.id === cueId && trigger.eventToFire === 'start');
        if (triggerStartIndex < 0) return false;

        const targetStartCue = this.cueTriggers[triggerStartIndex];
        this.eventTrack.unregisterCue(targetStartCue.cue.id);
        this.cueTriggers.splice(triggerStartIndex, 1);

        const triggerEndIndex = this.cueTriggers.findIndex((trigger) => trigger.cue.id === cueId && trigger.eventToFire === 'end');
        this.cueTriggers.splice(triggerEndIndex, 1);

        const unregisterCallback = this.cueUnregisteredCallbacks.get(targetStartCue.cue.type);
        if (unregisterCallback) {
            unregisterCallback.forEach((callback) => callback(targetStartCue.cue));
        }

        return true;
    }

    public onEventCueEntered(type: string, callback: EventCueCallback): void {
        let callbackList: Array<EventCueCallback> | undefined = this.cueStartCallbacks.get(type);

        if (!callbackList) {
            callbackList = [];
            this.cueStartCallbacks.set(type, callbackList);
        }

        callbackList.push(callback);
    }

    public onEventCueExited(type: string, callback: EventCueCallback): void {
        let callbackList: Array<EventCueCallback> | undefined = this.cueEndCallbacks.get(type);
        if (!callbackList) {
            callbackList = [];
            this.cueEndCallbacks.set(type, callbackList);
        }

        callbackList.push(callback);
    }

    public onEventCueRegistered(type: string, callback: OnEventCueRegisteredCallback): void {
        let callbackList: Array<OnEventCueRegisteredCallback> | undefined = this.cueRegisteredCallbacks.get(type);
        if (!callbackList) {
            callbackList = [];
            this.cueRegisteredCallbacks.set(type, callbackList);
        }

        callbackList.push(callback);
    }

    public onEventCueUpdated(type: string, callback: OnEventCueUpdatedCallback): void {
        let callbackList: Array<OnEventCueUpdatedCallback> | undefined = this.cueUpdatedCallbacks.get(type);
        if (!callbackList) {
            callbackList = [];
            this.cueUpdatedCallbacks.set(type, callbackList);
        }

        callbackList.push(callback);
    }

    public onEventCueUnregistered(type: string, callback: EventCueCallback): void {
        let callbackList: Array<EventCueCallback> | undefined = this.cueUnregisteredCallbacks.get(type);
        if (!callbackList) {
            callbackList = [];
            this.cueUnregisteredCallbacks.set(type, callbackList);
        }

        callbackList.push(callback);
    }

    /**
     * From a cue and Id, creates two EventCueTriggers, one for the start/onEnterCue and one for the end/onExitCue
     */
    private createEventCueTriggers(registeredEventCue: InternalRegisteredEventCue): [EventCueTrigger<'start'>, EventCueTrigger<'end'>] {
        const newCue = { ...registeredEventCue, startTime: registeredEventCue.startTime as number, endTime: registeredEventCue.endTime as number };
        return [
            {
                count: 0,
                firedLast: false,
                // Note: We preserve the original cue start time and end as an externally facing RegisteredEventCue to abstract away the stream time for VOD, as clients don't expect any translation
                cue: newCue,
                eventToFire: 'start',
                timeToFire: newCue.startTime,
            },
            {
                count: 0,
                firedLast: false,
                cue: newCue,
                eventToFire: 'end',
                timeToFire: newCue.endTime,
            },
        ];
    }

    public destroy() {
        this.eventTrack.destroy?.();
    }
}
