// cspell:words QWILT EDGIO caas MEDIACDN
import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';
import { CoreVideoInternal } from '../../core-video-internal';
import type { Observable } from '../../utils/observables/observable';
import type { Cdn, PlayerBitrateLimits } from '../player/playout-data';
import { sdkLogger } from '../../logger';
import { TestingOverrides } from '../services/testing-overrides';
import { JsonUtils } from '../../utils/json-utils';
import { type CapInstructions, type MetaListManager, type MetaListInstructions, getPrioritizedCdns } from './meta-list-manager';
import { BitrateCapReason } from './meta-list-manager.enums';
export { BitrateCapReason } from './meta-list-manager.enums';

export type CdnAbbreviationMapping = { [abbreviation: string]: string };

const KnownCdns: CdnAbbreviationMapping = {
    LIMELIGHT: 'll',
    EDGIO: 'll', // note: Peacock uses the name LIMELIGHT for this CDN, but Showmax uses EDGIO
    FASTLY: 'fy',
    CLOUDFRONT: 'cf',
    AKAMAI: 'ak',
    COMCAST: 'cc',
    LEVEL3: 'cl',
    GOOGLE: 'mc',
    MEDIACDN: 'mc',
    QWILT: 'qw',
} as const;

export type CoordinatedBitrateCapping = {
    /**
     * Constructed URL for polling bitrate cap from CDN:
     * ${domainBase}${domainNumber}${domainMid}${domainProd || domainStage}${cdnAbbreviation}.${host}${CDN_URL_PATHNAME}
     */
    urlPieces: {
        domainBase: string;
        domainMid: string;
        domainProd: string;
        domainStage: string;
        host: string;
    };
};

type BitrateCapObservables = {
    bitrateCapChangedObservable: Observable<BitrateCapData>;
    bitrateCapRequestedObservable: Observable<BitrateCapData>;
};

export type BitrateCapData = {
    value: number;
    reason: BitrateCapReason;
    requestedCapChanged?: boolean;
    currentCapChanged?: boolean;
};

const BITRATE_CAP_INSTRUCTIONS_DEFAULT_POLLING_TIMEOUT = 60_000;

const LEGACY_CDN_URL_PATHNAME = '/caas/bitrate/cap-instructions.json';

interface CurrentCapsMapType {
    [BitrateCapReason.UserConfiguredCap]: number;
    [BitrateCapReason.CoordinatedBitrateCap]: number;
    [BitrateCapReason.DeviceCapabilityCap]: number;
    [BitrateCapReason.MobileNetworkThrottle]: number; // currently not used by CVSDK
    [BitrateCapReason.SecurityEnforcedCap]: number; // currently not used by CVSDK
}

const getStartingCaps = () => {
    return {
        [BitrateCapReason.UserConfiguredCap]: Infinity,
        [BitrateCapReason.CoordinatedBitrateCap]: Infinity,
        [BitrateCapReason.DeviceCapabilityCap]: CoreVideoInternal.playerCapabilities.maxBitrate ?? Infinity,
        [BitrateCapReason.MobileNetworkThrottle]: Infinity,
        [BitrateCapReason.SecurityEnforcedCap]: Infinity,
    };
};

export const getStartingMaxBitrate = (requestedMaxBitRate = Infinity): number => {
    const currentCaps: CurrentCapsMapType = getStartingCaps();

    const maxBitRateData = getLowestBitrateCap(requestedMaxBitRate, BitrateCapReason.UserConfiguredCap, currentCaps);
    return maxBitRateData.value;
};

// Get the lowest value of all caps, taking into account the requested cap and all current caps, if any.
export const getLowestBitrateCap = (
    requestedMaxBitRate = Infinity,
    requestReason: BitrateCapReason,
    currentCaps: CurrentCapsMapType,
    currentAppliedCap?: number
): BitrateCapData => {
    let currentCapChanged = false;
    let requestedCapChanged = false;
    let currentReason: BitrateCapReason = requestReason;

    if (currentCaps[requestReason] !== requestedMaxBitRate) {
        requestedCapChanged = true;
    }
    currentCaps[requestReason] = requestedMaxBitRate;

    const lowestCap = Math.min(...Object.values(currentCaps));

    for (const cap in currentCaps) {
        if (currentCaps[cap as BitrateCapReason] === lowestCap) {
            currentReason = cap as BitrateCapReason;
            break;
        }
    }

    if (!currentAppliedCap || lowestCap !== currentAppliedCap) {
        currentCapChanged = true;
    }

    return { value: lowestCap, reason: currentReason, requestedCapChanged, currentCapChanged };
};

export class BitrateCapManager {
    private currentAppliedCap: number | undefined;
    private currentCaps: CurrentCapsMapType = getStartingCaps();
    private handleApplyCap: ((playerBitrateLimits: PlayerBitrateLimits, bitrateCapReason?: BitrateCapReason) => void) | undefined;
    /** @deprecated To be removed once legacy polling is removed. */
    private legacyPrimaryCdn: Cdn | undefined;

    private bitrateCapInstructionPollingTimeout: NodeJS.Timer | undefined = undefined;
    private logger: Logger = sdkLogger.withContext('BCM');

    constructor(private bitrateCapObservables: BitrateCapObservables) {}

    public start(
        handleApplyCap: (playerBitrateLimits: PlayerBitrateLimits, bitrateCapReason?: BitrateCapReason) => void,
        metaListManager: MetaListManager,
        cdns: Array<Cdn>,
        options?: RequestInit,
        startIndex = 0
    ) {
        this.handleApplyCap = handleApplyCap;
        metaListManager.registerCallBack(this.handleResponse.bind(this));
        metaListManager.handleStartPooling(cdns, options, startIndex);
    }

    /** @deprecated To be removed once legacy polling is removed. */
    public setLegacyPrimaryCdn(cdn: Cdn): void {
        this.legacyPrimaryCdn = cdn;
    }

    private legacyGetRequestUrl(currentCdn: Cdn): string {
        const cdnAbbreviation = KnownCdns[currentCdn.name];
        const cappingUrlData = CoreVideoInternal.getPropositionExtensions()?.coordinatedBitrateCapping?.urlPieces;

        if (!cappingUrlData || !cdnAbbreviation) {
            return '';
        }

        const cdnUrl = currentCdn.originalUrl ?? currentCdn.url; // prod uses both original url and url, stable-int has only url

        const { domainBase, domainMid, domainProd, domainStage, host } = cappingUrlData;

        const domainNumber = cdnUrl.match('^https://[a-z][0-9][0-9]([0-9])')?.[1] ?? '1';
        const domainPrdOrStg = cdnUrl.slice(0, cdnUrl.indexOf(host)).includes(domainStage) ? domainStage : domainProd;
        return `${domainBase}${domainNumber}${domainMid}${domainPrdOrStg}${cdnAbbreviation}.${host}${LEGACY_CDN_URL_PATHNAME}`;
    }

    public getMaxBitRate = (requestedMaxBitRate: number | undefined, requestReason: BitrateCapReason): BitrateCapData => {
        return getLowestBitrateCap(requestedMaxBitRate, requestReason, this.currentCaps, this.currentAppliedCap);
    };

    private handleResponse(jsonResponse: MetaListInstructions, cdnName: string): boolean {
        this.logger.verbose!(`Current bitrate caps: ${JsonUtils.stringify(this?.currentCaps)}`);
        const jsonResponseInstructions: CapInstructions['capInstructions'] = jsonResponse.globalConfigs?.capInstructions;

        if (jsonResponseInstructions) {
            // Only set if we aren't using testing overrides
            const coordinatedBitrateCap = TestingOverrides.coordinatedBitrateCap;

            if (coordinatedBitrateCap?.bitrateCap) {
                jsonResponseInstructions[cdnName] = coordinatedBitrateCap.bitrateCap !== Infinity ? coordinatedBitrateCap.bitrateCap : null;
            }

            const currentCdnCap = jsonResponseInstructions[cdnName] ?? Infinity;
            if (currentCdnCap !== this.currentCaps[BitrateCapReason.CoordinatedBitrateCap] && this.handleApplyCap) {
                this.handleApplyCap({ maxBitRate: currentCdnCap ?? Infinity }, BitrateCapReason.CoordinatedBitrateCap);
            } else {
                this.logger.verbose('Coordinated bitrate cap has not changed.');
            }
            return true;
        }
        return false;
    }

    /*
     ** At each polling cycle:
     **     1) Fetch bitrate cap instruction config from CDNs
     **     2) Fire bitrate capping callback if needed
     **     3) Start polling timeout for next cycle based on new time-to-live
     */
    public legacyCapInstructionPollingCycleHandler = async (
        cdns: Array<Cdn>,
        handleApplyCap: (playerBitrateLimits: PlayerBitrateLimits, bitrateCapReason?: BitrateCapReason) => void,
        options?: RequestInit,
        startIndex = 0
    ) => {
        cdns = getPrioritizedCdns(this.legacyPrimaryCdn, cdns);

        let capInstructionsTimeToLiveMs: number | undefined;

        this.logger.verbose!(`Current bitrate caps: ${JsonUtils.stringify(this?.currentCaps)}`);

        if (TestingOverrides.coordinatedBitrateCap?.pollingTimeout) {
            capInstructionsTimeToLiveMs = TestingOverrides.coordinatedBitrateCap.pollingTimeout;
        }

        let i;
        for (i = 0; i < cdns.length; i++) {
            try {
                // Do this to start in middle of array and loop back to beginning if necessary
                const currentIndex: number = (i + startIndex) % cdns.length;
                const currentCdn = cdns[currentIndex];

                const cdnBitrateCapConfigUrl = this.legacyGetRequestUrl(currentCdn);
                if (!cdnBitrateCapConfigUrl) {
                    continue;
                }

                const cdnName = currentCdn.name;

                const response = await fetch(cdnBitrateCapConfigUrl, options);
                if (!response.ok) {
                    this.logger.warn(`Error in bitrate cap instructions response: ${response.statusText}`);
                    continue;
                }

                const jsonResponse = await response.json();

                if (jsonResponse && jsonResponse.capInstructions) {
                    // Only set if we aren't using testing overrides
                    if (!capInstructionsTimeToLiveMs) {
                        capInstructionsTimeToLiveMs = jsonResponse.timeToLiveSeconds * 1000;
                    }

                    if (TestingOverrides.coordinatedBitrateCap?.bitrateCap) {
                        jsonResponse.capInstructions[cdnName] =
                            TestingOverrides.coordinatedBitrateCap.bitrateCap !== Infinity ? TestingOverrides.coordinatedBitrateCap.bitrateCap : null;
                    }

                    const currentCdnCap = jsonResponse.capInstructions[cdnName] ?? Infinity;
                    if (currentCdnCap !== this.currentCaps[BitrateCapReason.CoordinatedBitrateCap]) {
                        handleApplyCap({ maxBitRate: currentCdnCap ?? Infinity }, BitrateCapReason.CoordinatedBitrateCap);
                    } else {
                        this.logger.verbose('Coordinated bitrate cap has not changed.');
                    }

                    startIndex = currentIndex;

                    // Only get the first successful response
                    break;
                }
            } catch (error) {
                this.logger.warn(`Error fetching bitrate cap instructions: ${JsonUtils.stringify(error)}`);
            }
        }

        if (i === cdns.length) {
            // If we reach here, we didn't break the loop
            // i.e. all of the requests failed
            startIndex = 0;
        }

        this.stopCapInstructionPolling();
        // Regardless of failure or success, set new timeout
        this.bitrateCapInstructionPollingTimeout = setTimeout(() => {
            this.legacyCapInstructionPollingCycleHandler(cdns, handleApplyCap, options, startIndex);
        }, capInstructionsTimeToLiveMs ?? BITRATE_CAP_INSTRUCTIONS_DEFAULT_POLLING_TIMEOUT);
    };

    public stopCapInstructionPolling = () => {
        clearTimeout(this.bitrateCapInstructionPollingTimeout);
        this.bitrateCapInstructionPollingTimeout = undefined;
    };

    public notifyRequestedBitrateCap = (requestedBitrateCapData: BitrateCapData): void => {
        this.logger.verbose(`Notifying requested bitrate cap - value: ${requestedBitrateCapData.value}; reason: ${requestedBitrateCapData.reason}`);
        this.bitrateCapObservables.bitrateCapRequestedObservable.notifyObservers({
            value: requestedBitrateCapData.value,
            reason: requestedBitrateCapData.reason,
        });
    };

    public notifyAppliedBitrateCap = (appliedBitrateCapData: BitrateCapData): void => {
        this.logger.verbose(`Notifying applied bitrate cap - value: ${appliedBitrateCapData.value}; reason: ${appliedBitrateCapData.reason}`);
        this.bitrateCapObservables.bitrateCapChangedObservable.notifyObservers({
            value: appliedBitrateCapData.value,
            reason: appliedBitrateCapData.reason,
        });
        this.currentAppliedCap = appliedBitrateCapData.value; // update current cap for future comparisons
    };

    public destroy = () => {
        this.stopCapInstructionPolling();
    };
}
