import { getTimeNow } from './time-util';

/**
 * Intervals in JS are never exact
 *
 * Each execution will receive as a parameter the actual elapsed time since
 * the previous execution
 */
export type SafeIntervalExecutor = (elapsedTimeMs: number) => void;
/**
 * It's generally preferred not to use setInterval
 *
 * In low performance conditions, setInterval calls may queue
 * up on one another and cause inaccurate measurement or
 * make performance even worse
 */
export class SafeInterval {
    private currentHandle?: ReturnType<typeof setTimeout>;
    private lastRunTime: number;

    constructor(private config: { executor: SafeIntervalExecutor; timeMs: number }) {
        this.lastRunTime = getTimeNow();
    }

    public start() {
        this.stop();
        this.schedule();
    }

    public stop() {
        if (this.currentHandle === undefined) {
            return;
        }
        clearTimeout(this.currentHandle);
    }

    private schedule() {
        this.currentHandle = setTimeout(() => {
            const elapsed = getTimeNow() - this.lastRunTime;
            this.lastRunTime = getTimeNow();
            this.config.executor(elapsed);
            this.schedule();
        }, this.config.timeMs);
    }
}
