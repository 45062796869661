import type { PlayerEngine } from '../../player/player-engine';
import type { PlayerEngineItem } from '../../player/player-engine-item';
import { PlayerState } from '../../player/player-state';
import type { PlayoutData } from '../../player/playout-data';
import type { SessionControllerPrecursor } from '../precursor/session-controller-precursor';

import type { AdvertBoltOns, DrmBoltOns } from './timeline-manager';

type VolumeDescription = {
    value?: number;
    isMuted?: boolean;
};

export class TimelinePlayerCreationHelper {
    private static peiIdCount = 0;
    private playerEngineItems: Map<string, PlayerEngineItem> = new Map();
    private volumeDescription: VolumeDescription = {};

    constructor(
        private playerEngine: PlayerEngine,
        private sessionPrecursor: SessionControllerPrecursor,
        private advertBoltOns: AdvertBoltOns,
        private drmBoltons: DrmBoltOns
    ) {}

    public createPlayerEngineItem(playoutData: PlayoutData): PlayerEngineItem {
        const modifiedPlayoutData = {
            ...playoutData,
            volume: this.volumeDescription.value ?? playoutData.volume,
            muted: this.volumeDescription.isMuted ?? playoutData.muted,
        };

        const pei = this.sessionPrecursor.createPlayerEngineItem(this.playerEngine, modifiedPlayoutData, this.advertBoltOns, this.drmBoltons);

        const id = this.getPeiId();
        this.playerEngineItems.set(id, pei);

        this.propagateVolumeChanges(pei);

        pei.onStateChanged((playerState) => {
            if (playerState === PlayerState.Stopped) {
                pei.removeEventListeners(this);
                this.playerEngineItems.delete(id);
            }
        }, this);

        return pei;
    }

    private getPeiId(): string {
        return `PEI-${`0000${++TimelinePlayerCreationHelper.peiIdCount}`.slice(-4)}`;
    }

    private propagateVolumeChanges(newPei: PlayerEngineItem): void {
        newPei.onVolumeChanged((volume) => {
            if (volume !== this.volumeDescription.value) {
                this.volumeDescription.value = volume;
                Array.from(this.playerEngineItems.values()).forEach((pei) => {
                    if (pei !== newPei) {
                        pei.setVolume(volume);
                    }
                });
            }
        }, this);

        newPei.onMuteChanged((isMuted) => {
            if (isMuted !== this.volumeDescription.isMuted) {
                this.volumeDescription.isMuted = isMuted;
                Array.from(this.playerEngineItems.values()).forEach((pei) => {
                    if (pei !== newPei) {
                        pei.setMute(isMuted);
                    }
                });
            }
        }, this);
    }
}
