import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';

import type { AdvertsManager } from '../../../addons/adverts-manager';
import { sdkLogger } from '../../../logger';
import { createErrorLoggingObservable } from '../../../utils/observables/error-logging-observable';
import type { Observable } from '../../../utils/observables/observable';
import type { PlayerCapabilities } from '../../player/player-engine';
import type { LiveWindow, PlayerEngineItem } from '../../player/player-engine-item';
import { InternalSessionState, FINAL_STATES, INITIAL_STATES } from '../internal-session-state';
import type { PlaybackTimeline, SeekableRange } from '../session-controller-internal';
import { PlaybackType } from '../../player/playout-data';
import { CoreVideoInternal } from '../../../core-video-internal';

// On slower networks / lower performance devices it can take a longer time to seek.
// The SEEKING_TOLERANCE_SECONDS is an added position margin that reduces the chance of falling out of the live window before seeking completes.
const SEEKING_TOLERANCE_SECONDS = 30;
// WINDOW_TOLERANCE_SECONDS is an added tolerance to detect user falling outside live widow early on. It will also be reported to the clients on top of the live start value
const WINDOW_TOLERANCE_SECONDS = 30;

// TWO_MINUTE_DVR_TOLERANCE_SECONDS is an added tolerance for 2m streams where the tolerance logic is disabled so that we avoid falling into Seeking-Playing bug when seeking to live start
const TWO_MINUTE_DVR_TOLERANCE_SECONDS = 30;

// Needed to disable tolerance logic for streams with less than two minutes DVR window
const TWO_MINUTE_DVR_WINDOW = 120;

// Prevent seeking if the range is smaller than this
const MIN_SEEKABLE_RANGE = 0.01;

type PlaybackTimelineInternal = PlaybackTimeline & {
    liveWindow?: LiveWindow;
};
export class SeekingManager {
    private playerEngineItem?: PlayerEngineItem;
    private liveToleranceLogicEnabled = true;
    private currentState?: InternalSessionState;
    private playbackTimeline?: PlaybackTimelineInternal;
    private isSeekingToLiveStart?: boolean;
    private minWindowRecoveryDelayExceeded?: boolean;
    private logger: Logger = sdkLogger.withContext('Seeking Manager');
    private seekStartedObservable: Observable<number> = createErrorLoggingObservable<number>('SeekStarted', this.logger);
    private seekEndedObservable: Observable<void> = createErrorLoggingObservable<void>('SeekEnded', this.logger);
    private _isSeeking = false;

    public get isSeeking() {
        return this._isSeeking;
    }

    constructor(
        private advertsManager: AdvertsManager,

        isManifestLinearType: boolean,
        private playerCapabilities: PlayerCapabilities,
        private callSeekOnTimelineManager: (seekTo: number) => void,
        private onPlaybackTimelineUpdated: (callback: (playbackTimeline: PlaybackTimeline) => void) => void,
        onStateChanged: (callback: (state: InternalSessionState) => void) => void
    ) {
        onStateChanged((state) => {
            this.currentState = state;
        });
        this.onPlaybackTimelineUpdated((playbackTimeline) => {
            this.playbackTimeline = playbackTimeline;
            const { seekableRange } = this.playbackTimeline;
            if (
                isManifestLinearType &&
                this.playerCapabilities.isLinearScrubbingSupported &&
                !this.playerCapabilities.isDvrWindowNativelyHandled &&
                isSeekable(seekableRange)
            ) {
                this.avoidFallingOutsideLiveWindow();
            }
        });
    }

    public setPlayerEngineItem(pei: PlayerEngineItem): void {
        this.playerEngineItem = pei;
    }

    public adjustLiveWindow(liveWindow: LiveWindow): SeekableRange {
        // Please note the `liveWindow` object is only for our internal use to determine when
        // the user falls outside the actual live window but we use it to create the
        // value of the `seekableRange` which we surface to the client and it has our
        // added tolerance to it
        let modifiedSeekableRange: SeekableRange = liveWindow;

        if (!liveWindow) {
            return liveWindow;
        }

        if (this.playerCapabilities.isDvrWindowNativelyHandled || liveWindow.end - liveWindow.start <= TWO_MINUTE_DVR_WINDOW) {
            this.liveToleranceLogicEnabled = false;
        }

        let startToleranceS = 0;

        if (this.liveToleranceLogicEnabled) {
            startToleranceS = WINDOW_TOLERANCE_SECONDS + SEEKING_TOLERANCE_SECONDS;
            // Adding Window Tolerance and Seeking Tolerance to the live start in the seekable range so we don't seek outside the live window and to reflect that on the UI for clients
            modifiedSeekableRange = {
                ...modifiedSeekableRange,
                start: liveWindow.start + startToleranceS,
            };
        }

        if (liveWindow?.startDate) {
            const startToleranceMs = startToleranceS * 1000;
            const windowLengthMs = (modifiedSeekableRange.end - modifiedSeekableRange.start) * 1000;
            modifiedSeekableRange.startDate = new Date(liveWindow.startDate?.getTime() + startToleranceMs);
            modifiedSeekableRange.endDate = new Date(modifiedSeekableRange.startDate.getTime() + windowLengthMs);
        }

        return modifiedSeekableRange;
    }
    public avoidFallingOutsideLiveWindow(): void {
        if (!this.playbackTimeline) {
            return;
        }
        const { position, seekableRange, liveWindow } = this.playbackTimeline;
        let adjustedLiveWindowStart = seekableRange?.start;
        if (liveWindow && this.liveToleranceLogicEnabled) {
            adjustedLiveWindowStart = liveWindow.start + WINDOW_TOLERANCE_SECONDS;
        }

        if (this.currentState === InternalSessionState.Paused && !this.playerCapabilities.forcesLiveWindowRecoveryWhilePaused) {
            return;
        } else if (this.isSeekingToLiveStart && this.minWindowRecoveryDelayExceeded) {
            this.logger.warn(`Seeking outside live window has exceeded the ${SEEKING_TOLERANCE_SECONDS} seconds timeout, firing seek again`);
            this.manageFallingOutsideLiveWindow();
        } else if (!this.isSeekingToLiveStart && seekableRange && position <= adjustedLiveWindowStart!) {
            this.manageFallingOutsideLiveWindow();
        }
    }

    public seek(contentTime: number): void {
        if (this.isBlockingUserInput()) {
            this.logger.warn('SDK.SESSION.SEEK.INVALID_STATE');
            return;
        }

        if (typeof contentTime !== 'number' || !isFinite(contentTime)) {
            this.logger.error(`Rejected attempt to seek to invalid time "${contentTime}"`);
            return;
        }
        if (!this.playbackTimeline) {
            return;
        }

        // Please be aware that if the liveToleranceLogic is enabled, the Seekable
        // range at this point will already have both the window and seeking tolerances included
        // in it as this is done in the notifyLiveWindowUpdate function
        const { seekableRange } = this.playbackTimeline;
        if (!seekableRange) {
            return;
        }

        const safeContentTime = this.getSafeContentTime(contentTime);
        if (safeContentTime !== contentTime) {
            this.logger.warn(
                `Position ${contentTime} is outside of seekable range (${seekableRange.start}-${seekableRange.end}), converting position to ${safeContentTime}`
            );
        }
        const engineItemTime = this.advertsManager.contentTimeToTimeIncludingAds(safeContentTime);
        if (engineItemTime === this.playerEngineItem?.getCurrentPosition()) {
            this.logger.warn(`Attempted to seek to current position: "${engineItemTime}"`);
            return;
        }

        this.callSeekOnTimelineManager(engineItemTime);
    }

    public seekToAdjustedStartPosition(contentTime: number): void {
        const startPositionIncludingAds = this.advertsManager.contentTimeToTimeIncludingAds(contentTime);
        const managedStartPosition = this.advertsManager.manageStartPosition(startPositionIncludingAds);
        this.playerEngineItem?.seek(managedStartPosition, true);
    }

    public onSeekStarted(callback: (positionMs: number, currentPosition?: number) => void): void {
        this.seekStartedObservable.registerObserver(callback, this);
    }
    public onSeekEnded(callback: (_: void, currentPosition?: number) => void): void {
        this.seekEndedObservable.registerObserver(callback, this);
    }

    public handleSeekStarted = (timeIncludingAds: number): void => {
        this._isSeeking = true;
        this.advertsManager.handleSeekStarted(timeIncludingAds);
        const contentTime = this.advertsManager.timeIncludingAdsToContentTime(timeIncludingAds);
        this.seekStartedObservable.reset();
        this.seekStartedObservable.notifyObservers(
            contentTime,
            this.advertsManager.timeIncludingAdsToContentTime(this.playerEngineItem?.getCurrentPosition() as number)
        );
    };

    public handleSeekEnded = (): void => {
        this._isSeeking = false;
        this.isSeekingToLiveStart = false;
        this.minWindowRecoveryDelayExceeded = false;
        this.seekEndedObservable.notifyObservers(
            undefined,
            this.advertsManager.timeIncludingAdsToContentTime(this.playerEngineItem?.getCurrentPosition() as number)
        );
    };
    public async seekToLiveStart(): Promise<void> {
        // TODO support seeking to event start of an SLE, as there maybe time
        // between the start of the stream and the beginning of the content.
        const liveWindow = await this.getLiveWindow();
        if (liveWindow) {
            const seekTo = this.liveToleranceLogicEnabled ? liveWindow.start : liveWindow.start + TWO_MINUTE_DVR_TOLERANCE_SECONDS;
            if (liveWindow.startDate?.getTime() !== new Date(0).getTime()) {
                this.seek(seekTo);
            }
        }
    }
    public async seekToLiveEdge(): Promise<void> {
        const liveWindow = await this.getLiveWindow();
        if (liveWindow) {
            this.seek(liveWindow.end);
        }
    }

    public destroy(): void {
        this.seekStartedObservable.unregisterObservers(this);
        this.seekEndedObservable.unregisterObservers(this);
    }

    private getSafeContentTime(contentTime: number): number {
        const { seekableRange } = this.playbackTimeline!;
        const safeContentTime = Math.min(seekableRange!.end, Math.max(seekableRange!.start, contentTime));

        if (
            safeContentTime === seekableRange!.end &&
            this.playerEngineItem?.playoutData.type === PlaybackType.VOD &&
            CoreVideoInternal.playerCapabilities.requiresEndOfStreamSeekingTolerance
        ) {
            // On some devices, seeking into the exact last position causes playback issues,
            // For example duration of video element breaking. This tolerance solves the issue.
            // Applies only to VOD to keep the latency correct on linears
            const END_OF_STREAM_SEEKING_TOLERANCE = 4;
            return Math.floor(safeContentTime - END_OF_STREAM_SEEKING_TOLERANCE);
        }

        return safeContentTime;
    }
    private manageFallingOutsideLiveWindow(): void {
        this.isSeekingToLiveStart = true;
        this.minWindowRecoveryDelayExceeded = false;
        setTimeout(() => {
            // This is needed to avoid a `Seeking - Playing` loop bug on AAMP under poor bandwidth where
            // by the time we seek to live start + the added tolerance we will be outside the live tolerance and
            // AAMP's forcesLiveWindowRecoveryWhilePaused would kick in causing the bug to happen
            this.minWindowRecoveryDelayExceeded = true;
        }, WINDOW_TOLERANCE_SECONDS * 1000);

        this.logger.warn('Seekable range or current position are outside the live window, triggering Seek to live start');
        this.seekToLiveStart();
    }
    private isBlockingUserInput(): boolean {
        return [...INITIAL_STATES, ...FINAL_STATES].includes(this.currentState!);
    }

    private getLiveWindow = async (): Promise<LiveWindow | null> => {
        if (this.isBlockingUserInput()) {
            this.logger.warn('SDK.SESSION.GET_LIVE_WINDOW.INVALID_STATE');
            return null;
        }

        return this.playerEngineItem?.getLiveWindow() || null;
    };
}

function isSeekable(seekableRange?: SeekableRange): boolean {
    if (!seekableRange) {
        return false;
    }
    return seekableRange.end - seekableRange.start > MIN_SEEKABLE_RANGE;
}
