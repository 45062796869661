import { CoreVideoInternal } from '../../../core-video-internal';
import { sdkLogger } from '../../../logger';
import { PerfKey, perfLogger, PerfTag } from '../../../utils/perf';
import { checkIsManifestVodType } from '../../../utils/playback-type';
import { getStreamVendor } from '../../../utils/stream-vendor';
import type { VpiSessionRequestOptions, VpiSessionResponseOptions } from '../../../video-platforms/integration-provider';
import type { PlayoutData, Stream } from '../../player/playout-data';
import { VideoColourSpace } from '../../player/video-format';
import type { RestartOptions } from '../restart/session-restart-controller';
import { SessionRestartType } from '../restart/session-restart-controller';
import type { TelemetryDataType } from '../../telemetry/telemetry-session';
import { TelemetryNotificationKey } from '../../telemetry/telemetry-session';
import type { VideoPlatformIntegration } from '../../video-platform-integration/video-platform-integration';
import type { RawSessionItem, SessionItem, VpiSessionItem } from '../session-controller';
import { isRawSessionItem } from '../session-controller';

export class VideoProviderController {
    private telemetryData: Array<TelemetryDataType> = [];
    public async fetchPlayoutData(
        sessionItem: SessionItem,
        videoPlatformIntegration: VideoPlatformIntegration,
        retrySessionOverrides: RestartOptions | null,
        isPrefetch: boolean
    ): Promise<PlayoutData> {
        perfLogger.measure(PerfKey.root, PerfTag.playoutRequest);
        // reset telemetryData before calculating the new ovpDownloadTime
        this.telemetryData = [];
        this.telemetryData.push({ key: TelemetryNotificationKey.ovpRequestStart, value: new Date().getTime() });
        let vpiPlayoutDataPromise: Promise<PlayoutData>;
        if (isRawSessionItem(sessionItem)) {
            vpiPlayoutDataPromise = Promise.resolve(this.getStaticPlayoutData(sessionItem));
        } else {
            vpiPlayoutDataPromise = this.getVpiPlayoutData(sessionItem, videoPlatformIntegration, retrySessionOverrides, isPrefetch);
        }

        this.telemetryData.push({ key: TelemetryNotificationKey.ovpResponseEnd, value: new Date().getTime() });
        return vpiPlayoutDataPromise.then((r) => {
            perfLogger.measure(PerfKey.root, PerfTag.playoutResponse);
            return r;
        });
    }

    public getTelemetryData(): Array<TelemetryDataType> {
        return this.telemetryData;
    }

    private getInitialBitrate(sessionItem: SessionItem): number | undefined {
        let initialBitrate = sessionItem.initialBitrateBps;
        if (initialBitrate === undefined && CoreVideoInternal.bandwidthEstimator) {
            initialBitrate =
                'getBandwidthEstimate' in CoreVideoInternal.bandwidthEstimator
                    ? CoreVideoInternal.bandwidthEstimator.getBandwidthEstimate({ forceUpdate: true })
                    : CoreVideoInternal.bandwidthEstimator.getEstimate({ forceUpdate: true });
        }

        sdkLogger.info(`Using initial bitrate: ${initialBitrate ?? 'default'}`);
        return initialBitrate;
    }

    private getStaticPlayoutData(sessionItem: RawSessionItem): PlayoutData {
        const {
            manifests = [],
            startPosition: position,
            protocol,
            reporting,
            autoplay,
            preferredAudioMetadata,
            preferredSubtitleMetadata,
            preferredAudioLanguages,
            preferredSubtitlesLanguages,
            source,
            playerBitrateLimits,
            type,
            drmConfiguration,
            advertising,
            colourSpace = VideoColourSpace.SDR,
            sessionId,
        } = sessionItem;
        const cdns = manifests.map((manifest, index) => ({ url: manifest, name: `CDN ${index}`, vendor: getStreamVendor(manifest) }));
        const stream: Stream = { protocol, colourSpace };

        const playoutData: PlayoutData = {
            type,
            cdns,
            stream,
            source,
            autoplay,
            position,
            drmConfiguration,
            preferredAudioMetadata,
            preferredSubtitleMetadata,
            preferredAudioLanguages,
            preferredSubtitlesLanguages,
            custom: { nativeReporting: reporting && { ...reporting } },
            playerBitrateLimits,
            advertising,
            sessionId,
            initialBitrateBps: this.getInitialBitrate(sessionItem),
        };
        return playoutData;
    }

    private getVpiPlayoutData = async (
        sessionItem: VpiSessionItem,
        videoPlatformIntegration: VideoPlatformIntegration,
        retrySessionOverrides: RestartOptions | null,
        isPrefetch: boolean
    ): Promise<PlayoutData> => {
        perfLogger.measure(PerfKey.root, PerfTag.ovpRequest);
        const playoutData: PlayoutData = {
            ...(await videoPlatformIntegration.getPlayoutData(
                sessionItem,
                this.getVpiSessionRequestOptions(retrySessionOverrides, isPrefetch),
                this.getVpiSessionResponseOptions(retrySessionOverrides)
            )),
            initialBitrateBps: this.getInitialBitrate(sessionItem),
        };
        const originalPlayoutData = this.overridePlayoutDataWithRestartOptions(playoutData, sessionItem, retrySessionOverrides);
        perfLogger.measure(PerfKey.root, PerfTag.ovpResponse);
        return originalPlayoutData;
    };

    private overridePlayoutDataWithRestartOptions(
        playoutData: PlayoutData,
        sessionItem: SessionItem,
        retrySessionOverrides: RestartOptions | null
    ): PlayoutData {
        if (retrySessionOverrides === null) {
            return playoutData;
        }

        const isVodManifest = checkIsManifestVodType(sessionItem.type);

        const { muted, volume, preferredAudioTrack, preferredSubtitlesTrack, linearPosition, position } = retrySessionOverrides.playoutDataOverrides!;
        const playoutDataOverrides: Partial<PlayoutData> = {
            ...(!playoutData.autoplay && { autoplay: true }),
            ...(Boolean(muted !== undefined) && { muted }),
            ...(Boolean(volume !== undefined) && { volume }),
            ...(Boolean(preferredAudioTrack !== undefined) && { preferredAudioTrack }),
            ...(Boolean(preferredSubtitlesTrack !== undefined) && { preferredSubtitlesTrack }),
            ...(Boolean(isVodManifest && position) && { position }),
            ...(Boolean(!isVodManifest && linearPosition) && { linearPosition }),
        } as Partial<PlayoutData>;

        return { ...playoutData, ...playoutDataOverrides };
    }

    private getVpiSessionRequestOptions = (retrySessionOverrides: RestartOptions | null, isPrefetch: boolean): VpiSessionRequestOptions => {
        return {
            isPrefetch,
            isRetrying: retrySessionOverrides?.restartType === SessionRestartType.VPF_RETRY,
            ...retrySessionOverrides?.playoutRequestOverrides,
        };
    };

    private getVpiSessionResponseOptions = (retrySessionOverrides: RestartOptions | null): VpiSessionResponseOptions => {
        return {
            penalizedCdnName: retrySessionOverrides?.penalizedCdnName,
            isRetrying: retrySessionOverrides?.restartType === SessionRestartType.VPF_RETRY,
        };
    };
}
