import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';
import { LogLevel } from '@sky-uk-ott/core-video-sdk-js-logger';

import { CoreVideoInternal } from '../../core-video-internal';
import { JsonUtils } from '../json-utils';

import { createErrorLoggingObservable } from './error-logging-observable';
import type { AdaptedState } from './observable';
import { createErrorLogger, Observable } from './observable';

export function createLoggingObservable<T>(name: string, logger: Logger): Observable<T> {
    if (logger.getLogLevel() === LogLevel.Verbose) {
        const verboseLogger = (logValue: string) => {
            logger.verbose(`${name}:`, logValue);
        };
        return new VerboseLoggingObservable<T>(verboseLogger, createErrorLogger(name, logger));
    }
    return createErrorLoggingObservable(name, logger);
}

class VerboseLoggingObservable<T> extends Observable<T> {
    constructor(
        private verboseLoggingHandler: (logValue: string) => void,
        errorHandler?: (e: Error) => void
    ) {
        super(errorHandler);
    }

    public notifyObservers(newState?: T, currentPosition?: number): AdaptedState<T> {
        if (CoreVideoInternal.config.staticConfig.debug?.fullySerializedVerboseObservables) {
            this.verboseLoggingHandler(JsonUtils.stringify(newState));
        } else {
            this.verboseLoggingHandler(
                typeof newState === 'object' ? 'set sdkConfig.debug.fullySerializedVerboseObservables to log payload' : '' + newState
            );
        }
        return super.notifyObservers(newState, currentPosition);
    }
}
