import { UrlScheme } from './video-platforms.enums';
export { UrlScheme } from './video-platforms.enums';

export type UrlType<Params extends {}> = {
    scheme?: UrlScheme;
    domain?: Array<string>;
    path?: Array<string>;
    params?: Params;
};

export class Url<QueryParams extends {}> {
    protected static regex = /^(?:([a-z]*:)\/\/|)([^\s/?]*)(?:\/|)(.*?|)(?:\?(.*)|)$/;

    constructor(protected url: UrlType<QueryParams> = {}) {}

    public static make<T extends {}>(url: UrlType<T> = {}): Url<T> {
        return new Url(url);
    }

    public static parse<T extends {}>(url: string): Url<T> {
        const match: RegExpExecArray | null = this.regex.exec(url);
        const [, schemeMatch, domainMatch, pathMatch, paramsMatch] = match!;

        const scheme = this.getScheme(schemeMatch);
        const domain = domainMatch ? domainMatch.split('.') : [];
        const path = pathMatch ? ['', ...pathMatch.split('/')] : [];

        const paramItems = paramsMatch?.split('&');
        const params: T | undefined = paramItems?.filter((v) => v).length
            ? paramItems.reduce((a, c) => {
                  const [k, v] = c.split('=');

                  return { ...a, [k]: v };
              }, {} as T)
            : undefined;

        return new Url({
            ...(scheme && { scheme }),
            ...(domain.length && { domain }),
            ...(path.length && { path }),
            ...(params && { params }),
        });
    }

    public static getScheme(s: string): UrlScheme {
        switch (s) {
            case UrlScheme.Http:
                return UrlScheme.Http;
            case UrlScheme.Https:
                return UrlScheme.Https;
            case UrlScheme.File:
                return UrlScheme.File;
            default:
                // throw ?
                return s as UrlScheme;
        }
    }

    public toString(): string {
        const { scheme, domain, path, params } = this.url;

        const schemeString = scheme ? `${scheme}//` : '';
        const domainString = domain?.join('.') || '';
        const pathString = path?.join('/') || '';
        const paramsString = Object.keys(params || {})?.length
            ? '?' +
              Object.entries(params!)
                  .map((v) => v.join('='))
                  .join('&')
            : '';

        return `${schemeString}${domainString}${pathString}${paramsString}`;
    }

    public toObject(): Url<QueryParams> {
        return this.url as Url<QueryParams>;
    }

    public addParameter<Key extends keyof QueryParams>(key: Key, value: QueryParams[Key]): Url<QueryParams> {
        if (this.url?.params?.[key]) {
            throw new Error(`removeParameter - key \`${key.toString()}\` already exist`);
        }

        this.url.params = {
            ...this.url?.params!,
            [key]: value,
        };

        return this;
    }

    public setUrlScheme(secure = true): Url<QueryParams> {
        this.url.scheme = secure ? UrlScheme.Https : UrlScheme.Http;

        return this;
    }

    public setHttp(): Url<QueryParams> {
        this.url.scheme = UrlScheme.Http;

        return this;
    }

    public setHttps(): Url<QueryParams> {
        this.url.scheme = UrlScheme.Https;

        return this;
    }

    public setParameter<Key extends keyof QueryParams>(key: Key, value: QueryParams[Key]): Url<QueryParams> {
        this.url.params = {
            ...this.url?.params!,
            [key]: value,
        };

        return this;
    }

    public transformParameter<Key extends keyof QueryParams>(key: Key, fn: (p: QueryParams[Key]) => QueryParams[Key]): Url<QueryParams> {
        if (!this.url?.params?.[key]) {
            throw new Error(`transformParameter - key \`${key.toString()}\` does not exist`);
        }

        this.url.params[key] = fn(this.url.params[key]);
        return this;
    }

    public transformParameters(fn: (p: QueryParams | undefined) => QueryParams): Url<QueryParams> {
        this.url.params = fn(this.url?.params);
        return this;
    }

    public removeParameter<Key extends keyof QueryParams>(key: Key): Url<QueryParams> {
        if (!this.url?.params?.[key]) {
            throw new Error(`removeParameter - key \`${key.toString()}\` does not exist`);
        }

        delete this.url?.params?.[key];
        return this;
    }

    public emptyParameters(): Url<QueryParams> {
        delete this.url.params;
        return this;
    }

    public if(condition: boolean | undefined, callback: (url: Url<QueryParams>) => void): Url<QueryParams> {
        if (condition) {
            callback(this);
        }

        return this;
    }
}
