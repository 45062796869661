export enum UhdHdrCodec {
    Uhd = 'Uhd', // 4K
    Hdr10 = 'Hdr10',
    Hdr10Plus = 'Hdr10Plus',
    HdrHlg = 'HdrHlg',
    DolbyVision = 'DolbyVision',
}

export enum AudioCodecType {
    MPEG_4_LC = 'MPEG_4_LC', // AAC LC (Low Complexity)
    MPEG_4_SBR = 'MPEG_4_SBR', // SBR (Spectral Band Replication)
    DolbyDigital = 'DolbyDigital',
    DolbyDigitalPlus = 'DolbyDigitalPlus',
}

export enum DrmKind {
    Widevine = 'com.widevine.alpha',
    PlayReady = 'com.microsoft.playready.recommended',
    FairPlay = 'com.apple.fps',
}

export enum StatusPolicies {
    USABLE = 'usable',
}
