import type { ClientAgfNielsenConfig } from '../../../addons/reporting/agf-nielsen/agf-nielsen-browser-sdk/agf-nielsen-browser-sdk-addon';
import type { AddonConfig } from '../../internal-config';
import type { RemoteConfigSplit } from '../../sdk-config';
import type { DeclaredOptionalsAddonsConfig, InternalReportingConfig, RequiredPropertiesDefinition } from './addons-config-adapter-common';
import { genericConfigEnabledCheck } from './addons-config-adapter-common';

// Internal Config
type AgfNielsenInternalConfig = InternalReportingConfig['agfNielsen'];

// Client Config when Remote Config is disabled
export type AgfNielsenClientConfig = AddonConfig<ClientAgfNielsenConfig>;
export function adaptAgfNielsenClientConfig(config: AgfNielsenClientConfig | undefined): AgfNielsenInternalConfig {
    return config;
}

// Server Config when Remote Config is enabled
type AgfNielsenRemoteServerConfig = AddonConfig<{}>;

// Client Config when Remote Config is enabled
type AgfNielsenRemoteClientConfig = ClientAgfNielsenConfig;

export type AgfNielsenRemoteConfigSplit = RemoteConfigSplit<AgfNielsenRemoteServerConfig, AgfNielsenRemoteClientConfig>;
export function adaptAgfNielsenRemoteConfigSplit(config: AgfNielsenRemoteConfigSplit): DeclaredOptionalsAddonsConfig<AgfNielsenInternalConfig> {
    const requiredFields: RequiredPropertiesDefinition<AgfNielsenRemoteClientConfig> = { appName: null, apId: null, apiUrl: null, debug: null };
    const isEnabled = genericConfigEnabledCheck(config, requiredFields, 'AgfNielsen');
    if (!isEnabled) {
        return;
    }

    const { clientConfig } = config;
    return {
        enabled: true,
        config: {
            appName: clientConfig!.appName,
            apiUrl: clientConfig!.apiUrl,
            apId: clientConfig!.apId,
            debug: clientConfig!.debug,
            nrsUrl: clientConfig?.nrsUrl,
        },
    };
}
