import type { AddonsConfig } from '../../config/internal-config';
import { CoreVideoInternal } from '../../core-video-internal';
import type { PlayoutData } from '../../core/player/playout-data';
import { AdsFailoverReason } from '../../core/player/playout-data';
import type { SessionItem } from '../../core/session-controller/session-controller';
import { AdvertsManagerState } from './adverts-manager-stage.enums';
export { AdvertsManagerState } from './adverts-manager-stage.enums';

export class AdvertsInitialisationStageManager {
    private initialisationState: string = AdvertsManagerState.Uninitialised;

    constructor(private adInsertionClientConfig: AddonsConfig['adInsertion']) {}

    public initialise(sessionItem: SessionItem, initialPlayoutData: PlayoutData) {
        if (!this.adInsertionClientConfig?.enabled || sessionItem.adInsertion?.disabled) {
            this.initialisationState = AdvertsManagerState.Disabled;
            return initialPlayoutData;
        }

        if (initialPlayoutData.advertising?.isKillswitchEnabled) {
            this.initialisationState = AdvertsManagerState.Disabled;
            return { ...initialPlayoutData, adsFailoverReason: AdsFailoverReason.Killswitch };
        }

        if (!initialPlayoutData.vamPrerollEnabled && !initialPlayoutData.vamMidrollEnabled) {
            this.initialisationState = AdvertsManagerState.Disabled;
            return initialPlayoutData;
        }

        if (CoreVideoInternal.getPropositionExtensions().adsDisabled?.[CoreVideoInternal.deviceType]?.(sessionItem)) {
            this.initialisationState = AdvertsManagerState.Disabled;
            return initialPlayoutData;
        }

        this.initialisationState = AdvertsManagerState.Initialised;
        return initialPlayoutData;
    }

    public destroy(): void {
        this.initialisationState = AdvertsManagerState.Uninitialised;
    }

    public isDisabled(): boolean {
        return this.initialisationState === AdvertsManagerState.Disabled;
    }

    public getInitialisationStatus(): string {
        return this.initialisationState;
    }
}
