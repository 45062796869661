import { CoreVideoInternal } from '../../../core-video-internal';
import type { LinearStreamInfo, PlaybackType, Cdn as PlayoutCdn } from '../../../core/player/playout-data';
import { DvrWindowDuration, StreamVendor } from '../../../core/player/playout-data';
import { sdkLogger } from '../../../logger';
import { getLinearStreamInfo } from '../../../utils/stream-vendor';
import { ExtendedWindowManager } from '../extended-window-manager';
import { LinearStreamEndpointFactory } from '../peacock-linear-cdn-builder';

export class OvpUrlExtendedWindowManager {
    public static getBestWindowDurationBasedOnPlayerCapabilitiesAndStreamProvider(
        playbackType: PlaybackType,
        preferredWindowDuration: DvrWindowDuration = DvrWindowDuration.Restricted,
        stream: LinearStreamInfo
    ): DvrWindowDuration | undefined {
        const extendedWindowConfig = ExtendedWindowManager.getExtendedWindowConfig(
            playbackType,
            preferredWindowDuration,
            CoreVideoInternal.playerCapabilities,
            CoreVideoInternal.config
        );

        return extendedWindowConfig[stream.vendor];
    }

    public static buildExtendedAndFullEventDvrWindowWhenPossible(
        ovpEndpoints: Array<PlayoutCdn>,
        playbackType: PlaybackType,
        preferredWindowDuration: DvrWindowDuration = DvrWindowDuration.Restricted
    ): Array<PlayoutCdn> {
        const modifiedCdnList: Array<PlayoutCdn> = [];
        const unmodifiedCdnList: Array<PlayoutCdn> = [];

        // If user requests extended window on linear stream
        // Start with extended window and fall back to unmodified CDNs
        ovpEndpoints.forEach((endpoint: PlayoutCdn) => {
            const stream = getLinearStreamInfo(endpoint.url);
            const windowDuration = this.getBestWindowDurationBasedOnPlayerCapabilitiesAndStreamProvider(
                playbackType,
                preferredWindowDuration,
                stream
            );

            if (stream.vendor !== StreamVendor.Unknown && windowDuration !== DvrWindowDuration.Restricted) {
                const endpointBuilder = LinearStreamEndpointFactory.fromVendor(stream);
                let extendedUrl = endpoint.url;
                if (windowDuration === DvrWindowDuration.Extended) {
                    extendedUrl = endpointBuilder.buildExtendedDvrWindowUrl(endpoint.url, CoreVideoInternal.capabilities);
                    sdkLogger.info(`Adding Extended window for vendor: ${stream.vendor}`);
                } else if (windowDuration === DvrWindowDuration.FullEvent) {
                    extendedUrl = endpointBuilder.buildFullEventDvrWindowUrl(endpoint.url);
                    sdkLogger.info(`Adding FullEvent window for vendor: ${stream.vendor}`);
                }
                modifiedCdnList.push({ ...endpoint, url: extendedUrl, name: endpoint.name });
            }

            unmodifiedCdnList.push({ ...endpoint, url: endpoint.url, name: endpoint.name });
        });

        return modifiedCdnList.concat(unmodifiedCdnList);
    }
}
