import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';

import type { Ad, AdBreak } from '../../../addons/adverts/common';
import type { PauseAdvertDispatcher } from '../../../addons/adverts/pause-adverts/pause-advert-dispatcher';
import type { AdAssetGroup } from '../../../addons/adverts/vod-adverts/vod-adverts-addon';
import { CoreVideoInternal } from '../../../core-video-internal';
import { CvsdkError, ErrorSeverity } from '../../../error';
import { sdkLogger } from '../../../logger';
import type { BaseSsaiAdBreaksProvider } from '../../../players/bolt-ons/ad-breaks-provider/base-ssai-ad-breaks-provider';
import type { AdEventsManager } from '../../../players/bolt-ons/ad-events-manager';
import type { AdBreakTimeAdapter } from '../../../players/bolt-ons/ad-break-time-adapter';
import type { SessionSideAdTransitionBoltOn } from '../../../players/bolt-ons/session-side-ad-transition-bolt-on';
import type { TimedActionsScheduler } from '../../../players/bolt-ons/timed-actions-scheduler';
import type { AdPolicyManager } from '../../../propositions/ad-policy/ad-policy-manager';
import { AdPolicyManagerContentTimeAdapter } from '../../../propositions/ad-policy/ad-policy-manager-content-time-adapter';
import type { InterItemAdPolicyManager } from '../../../propositions/ad-policy/inter-item-ad-policy-manager';
import { Observable } from '../../../utils/observables/observable';
import { checkIsManifestLinearType } from '../../../utils/playback-type';
import type { LicenseRequest, LicenseResponse } from '../../../video-platforms/integration-provider';
import type { PlayerEngine } from '../../player/player-engine';
import type { CdnSwitchEvent, PlayerEngineItem } from '../../player/player-engine-item';
import { PlayerState } from '../../player/player-state';
import type { AdInsertionConfig, PlayoutData, PlayoutRules } from '../../player/playout-data';
import { StreamQuality } from '../../player/playout-data';
import type { Asset } from '../asset';
import type { SessionControllerPrecursor } from '../precursor/session-controller-precursor';

import type { BufferingLimitConfig } from './buffering-limit';
import { BufferingLimit } from './buffering-limit';
import { CdnSelector } from './cdn-selector';
import type { TimelineItem, TimelineOptions } from './timeline';
import { Timeline, TimelineItemType } from './timeline';
import { TimelineError } from './timeline-error';
import { TimelinePlayerCreationHelper } from './timeline-player-creation-helper';
import { VideoFormat } from '../../player/video-format';
import { PerfKey, PerfTag, perfLogger } from '../../../utils/perf';
import { TimelineState } from './timeline.enums';
export { TimelineState } from './timeline.enums';
import type { AdvertSession } from '../../../addons/adverts-manager';
import { areAdBreaksSame, cloneAdBreak } from '../../../utils/ad-break-utils';

const PRELOAD_BUFFERING_SECS = 10;
const LINEAR_BREAK_OFFSET_SECS = 0.5;
const MAX_SEEKING_WAIT_MS = 300;

function isNumber(v: unknown): v is number {
    return typeof v === 'number';
}

export interface TimelineManagerConfig {
    preloadTime?: number;
    bufferingLimit: BufferingLimitConfig;
}

export interface AdvertBoltOns {
    adBreakTimeAdapter?: AdBreakTimeAdapter;
    transition?: SessionSideAdTransitionBoltOn;
    policyManager?: AdPolicyManager;
    breaksProvider?: BaseSsaiAdBreaksProvider;
    eventsManager?: AdEventsManager;
    timedActionsScheduler?: TimedActionsScheduler;
    pauseAdvertDispatcher?: PauseAdvertDispatcher;
}

export interface DrmBoltOns {
    decorateLicenseRequest?(request: LicenseRequest): LicenseRequest;
    validateLicenseResponse?(response: LicenseResponse): boolean;
}

export interface TimelineEventData {
    timelineItem: TimelineItem;
    playerEngineItem: PlayerEngineItem;
}

interface TimelinePlayerEngineItem extends PlayerEngineItem {
    videoElement?: HTMLVideoElement;
}

export class TimelineManager {
    private logger: Logger | null = null;
    private timeline?: Timeline;
    private playerEngineItems: Map<string, TimelinePlayerEngineItem> = new Map<string, TimelinePlayerEngineItem>();
    private currentTimelineItem: TimelineItem | null = null;
    private endingPlayerEngineItemPromise: { [key: string]: Promise<void> | null } = {};
    private timelineState: TimelineState = TimelineState.WaitingToStart;
    private timelineItemStartedObservable: Observable<TimelineEventData> = new Observable<TimelineEventData>();
    private timelineItemPlayoutObservable: Observable<PlayoutData> = new Observable<PlayoutData>();
    private timelineItemPlayoutRulesObservable: Observable<PlayoutRules> = new Observable<PlayoutRules>();
    private timelineItemEndedObservable: Observable<TimelineEventData> = new Observable<TimelineEventData>();
    private timelineFinishedObservable: Observable<void> = new Observable<void>();
    private timelineItemPreloadingObservable: Observable<TimelineEventData> = new Observable<TimelineEventData>();
    private timelineEndedObservable: Observable<void> = new Observable<void>();
    private timelineErrorObservable: Observable<CvsdkError> = new Observable<CvsdkError>();
    private qualityFailoverObservable: Observable<VideoFormat> = new Observable<VideoFormat>();
    private cdnSwitchedObservable: Observable<CdnSwitchEvent> = new Observable<CdnSwitchEvent>();
    private adBreakFinishedObservable: Observable<AdBreak> = new Observable<AdBreak>();
    private cdnSelector: CdnSelector;
    private bufferingLimit: BufferingLimit;
    private mainAsset?: Asset;
    private adTimelineItemToAd: Map<TimelineItem, Ad> = new Map<TimelineItem, Ad>();
    private exactEndOfTimelineItemTimeout?: number;
    private previousBreakStartClock?: number;
    private previousBreakExpectedDuration?: number;
    private interItemAdPolicyManager?: InterItemAdPolicyManager;
    private mainContentIsLinear: boolean;
    private timelinePlayerCreationHelper!: TimelinePlayerCreationHelper;
    private timelinePreconstructPreloadPromises: Map<TimelineItem, Promise<void>> = new Map<TimelineItem, Promise<void>>();

    constructor(
        private playerEngine: PlayerEngine,
        private config: TimelineManagerConfig,
        private options: TimelineOptions,
        private sessionPrecursor: SessionControllerPrecursor,
        protected session: AdvertSession
    ) {
        this.cdnSelector = new CdnSelector();
        this.mainContentIsLinear = checkIsManifestLinearType(options.playbackType);

        this.bufferingLimit = new BufferingLimit(this.config.bufferingLimit);
        this.bufferingLimit.onBufferingLimitReached((e) => {
            this.handleTimelineError(
                new TimelineError(
                    {
                        message: e.message,
                        code: `SDK.${e.code}`,
                    },
                    this.currentTimelineItem!.type
                ),
                e.shouldFailSilently
            );
        }, this);
        this.onTimelineItemStarted((timelineEventData) => this.bufferingLimit.playerEngineItemChanged(timelineEventData), this);
    }

    public initializeTimeline(mainAsset: Asset, advertBoltOns: AdvertBoltOns, drmBoltOns: DrmBoltOns, adInsertionConfig?: AdInsertionConfig): void {
        let modifiedAdvertBoltOns = advertBoltOns;
        if (adInsertionConfig?.isTimelineAdManagementRequired) {
            this.interItemAdPolicyManager = new AdPolicyManagerContentTimeAdapter(advertBoltOns.policyManager!, this.options.playbackType);
            modifiedAdvertBoltOns = { ...advertBoltOns, policyManager: undefined };
            this.interItemAdPolicyManager.onShouldSeek((position) => this.performSeek(position, true));
        }
        this.mainAsset = mainAsset;
        this.timeline = new Timeline(mainAsset, this.options);
        this.logger = sdkLogger.withContext(this.timeline.id);
        this.timelinePlayerCreationHelper = new TimelinePlayerCreationHelper(
            this.playerEngine,
            this.sessionPrecursor,
            modifiedAdvertBoltOns,
            drmBoltOns
        );
    }

    public async startTimeline(startPosition = 0): Promise<void> {
        if (this.timelineState !== TimelineState.WaitingToStart) return;

        this.timelineState = TimelineState.Started;

        this.logger?.info(`Timeline starting:\n${this.timeline?.toString()}`);
        const managedStartPosition = this.interItemAdPolicyManager ? this.interItemAdPolicyManager.manageStartPosition(startPosition) : startPosition;

        let adTimelineItemToPlay: TimelineItem | undefined;

        if (this.interItemAdPolicyManager)
            for (const [timelineItem, { adBreak, id }] of this.adTimelineItemToAd) {
                if (!adBreak) {
                    this.logger?.warn(`No ad break was found for ad with id ${id}`);
                    continue;
                }

                if (
                    (Number(adBreak.position) || 0) <= managedStartPosition &&
                    this.interItemAdPolicyManager?.shouldPlayAdBreak(adBreak, managedStartPosition, managedStartPosition > 0)
                ) {
                    adTimelineItemToPlay = timelineItem;
                    break;
                }
            }

        if (adTimelineItemToPlay) return this.playTimelineItem(adTimelineItemToPlay, true);

        const timelineItem = this.timeline?.getMainContentItemForPosition(managedStartPosition);
        return this.playTimelineItem(timelineItem, true, managedStartPosition);
    }

    public async endTimeline(): Promise<void> {
        if (this.timelineState === TimelineState.Ended) {
            return;
        }
        this.timelineState = TimelineState.Ended;

        if (this.currentTimelineItem) {
            const nextItem: TimelineItem = this.timeline?.getNextTimelineItem(this.currentTimelineItem)!;
            await Promise.all([this.endTimelineItem(this.currentTimelineItem), this.endTimelineItem(nextItem)]);
        }
        this.currentTimelineItem = null;

        this.timelineEndedObservable.notifyObservers();
        this.destroy();
        this.logger?.info(`Timeline ended.`);
    }

    public insertAdverts(adAssetGroup: AdAssetGroup): void {
        const { assets, position, adBreak } = adAssetGroup;
        let addedItems: Array<TimelineItem> | undefined;

        const isPreroll = position === 0 || position === 'start';

        if (this.mainContentIsLinear && !isPreroll) {
            if (!isNumber(position)) {
                this.logger?.warn('Received an unexpected live break position: ', position);
                return;
            }
            addedItems = this.timeline?.insertAdverts(
                assets,
                position - LINEAR_BREAK_OFFSET_SECS,
                adBreak.expectedDuration! + LINEAR_BREAK_OFFSET_SECS
            );
        } else {
            addedItems = this.timeline?.insertAdverts(assets, position, adBreak.expectedDuration!);
        }

        addedItems?.forEach((item: TimelineItem, i: number) => {
            // note the assumption here that the array of ads
            // corresponds exactly to the array of assets
            // and that the array of assets corresponds exactly
            // to the array of timeline items.
            const matchingAd = adBreak.ads[i];
            this.adTimelineItemToAd.set(item, matchingAd);
        });

        const adBreaksData = [...(this.interItemAdPolicyManager?.getAdBreaks() ?? []), adBreak];
        this.interItemAdPolicyManager?.setAdBreaksData(adBreaksData);
        this.session.notifyAdBreakDataReceived(adBreaksData);
    }

    public removeAdverts(adAssetGroup: AdAssetGroup): void {
        const { assets, adBreak } = adAssetGroup;
        const removedItems = this.timeline?.removeAdverts(assets);
        const existingAdBreaks = this.interItemAdPolicyManager?.getAdBreaks();
        removedItems?.forEach((item) => this.adTimelineItemToAd.delete(item));

        const adBreaksData = existingAdBreaks!.filter((x) => !areAdBreaksSame(x, adBreak));
        this.interItemAdPolicyManager?.setAdBreaksData(adBreaksData);
        this.session.notifyAdBreakDataReceived(adBreaksData);
    }

    public async seek(targetContentPosition: number): Promise<void> {
        if (this.currentTimelineItem?.type === TimelineItemType.Advert || this.currentTimelineItem?.isSuspending) {
            // don't allow seeking while an ad timeline item is playing or in the process of suspending
            this.logger?.warn('Seeking cannot be performed as either an advert is playing or timeline item is suspending');
            return;
        }

        self.clearTimeout(this.exactEndOfTimelineItemTimeout);
        const currentContentPosition = (this.currentTimelineItem && this.getCurrentPlayerEngineItem()?.getCurrentPosition()) || 0;

        const targetPosition = this.interItemAdPolicyManager
            ? this.interItemAdPolicyManager.manageSeek(currentContentPosition, targetContentPosition)
            : targetContentPosition;
        await this.performSeek(targetPosition!);
    }

    public seeked(): void {
        this.interItemAdPolicyManager?.seekEnded();
    }

    public onTimelineItemStarted(callback: (timelineEventData: TimelineEventData) => void, owner: {}): void {
        this.timelineItemStartedObservable.registerObserver(callback, owner);
    }

    public onTimelineItemPlayoutReceived(callback: (playout: PlayoutData) => void, owner: {}): void {
        this.timelineItemPlayoutObservable.registerObserver(callback, owner);
    }

    public onTimelineItemPlayoutRulesReceived(callback: (playoutRules: PlayoutRules) => void, owner: {}) {
        this.timelineItemPlayoutRulesObservable.registerObserver(callback, owner);
    }

    public onTimelineItemEnded(callback: (timelineEventData: TimelineEventData) => void, owner: {}): void {
        this.timelineItemEndedObservable.registerObserver(callback, owner);
    }

    public onTimelineItemPreloading(callback: (timelineEventData: TimelineEventData) => void, owner: {}): void {
        this.timelineItemPreloadingObservable.registerObserver(callback, owner);
    }

    public onTimelineEnded(callback: () => void, owner: {}): void {
        this.timelineEndedObservable.registerObserver(callback, owner);
    }

    public onTimelineFinished(callback: () => void, owner: {}): void {
        this.timelineFinishedObservable.registerObserver(callback, owner);
    }

    public onError(callback: (error: CvsdkError) => void, callbackOwner: {}): void {
        this.timelineErrorObservable.registerObserver(callback, callbackOwner);
    }

    public onQualityFailover(callback: (event: VideoFormat) => void, callbackOwner: {}): void {
        this.qualityFailoverObservable.registerObserver(callback, callbackOwner);
    }

    public onCdnSwitch(callback: (event: CdnSwitchEvent) => void, callbackOwner: {}): void {
        this.cdnSwitchedObservable.registerObserver(callback, callbackOwner);
    }

    public onAdBreakFinished(callback: (adBreak: AdBreak) => void): void {
        this.adBreakFinishedObservable.registerObserver(callback, this);
    }

    public removeEventListeners(owner: {}): void {
        this.timelineItemStartedObservable.unregisterObservers(owner);
        this.timelineItemEndedObservable.unregisterObservers(owner);
        this.timelineEndedObservable.unregisterObservers(owner);
        this.timelineErrorObservable.unregisterObservers(owner);
        this.qualityFailoverObservable.unregisterObservers(owner);
        this.cdnSwitchedObservable.unregisterObservers(owner);
        this.timelineItemPlayoutObservable.unregisterObservers(owner);
        this.timelineItemPlayoutRulesObservable.unregisterObservers(owner);
        this.timelineItemPreloadingObservable.unregisterObservers(owner);
        this.timelineFinishedObservable.unregisterObservers(owner);
        this.adBreakFinishedObservable.unregisterObservers(owner);
    }

    private async performSeek(targetPosition: number, isResumingFromBreak?: boolean): Promise<void> {
        const targetItem = this.timeline?.getMainContentItemForPosition(targetPosition);

        if (targetItem && targetItem !== this.currentTimelineItem) {
            targetItem.resumePosition = targetPosition;

            // if the target main item is different but this was a managed seek (AdsPolicy) then
            // we don't need to change the current timeline item (since the timeline is going to do it already)
            if (!isResumingFromBreak) {
                await this.suspendTimelineItem(this.currentTimelineItem!);
                this.playTimelineItem(targetItem);
            }
        } else {
            const currentPei = this.getCurrentPlayerEngineItem();
            const seekedPosition = currentPei?.seek(targetPosition);
            // we need to investigate why we need to run the position handling logic immediately after the seek
            if (seekedPosition) {
                await this.handlePlayerPositionUpdate(currentPei!, seekedPosition);
            }
        }
    }

    private destroy(): void {
        this.bufferingLimit.removeEventListeners(this);
        this.bufferingLimit.destroy();
        this.removeEventListeners(this);
    }

    private loadPei(playoutData: PlayoutData, timelineItem: TimelineItem): void {
        if (this.timelineState === TimelineState.Ended) {
            return;
        }

        const playerEngineItem = this.timelinePlayerCreationHelper.createPlayerEngineItem(playoutData);
        this.addPlayerEngineItemListeners(playerEngineItem);
        // If the timeline item was pre-loaded but failed we'll also get here
        timelineItem.isPreloaded = false;
        timelineItem.isPreconstructed = false;

        this.timelineItemStartedObservable.notifyObservers({
            timelineItem,
            playerEngineItem,
        });

        this.logger?.info(`Created engine item ${playerEngineItem.id} for ${timelineItem.id} ${timelineItem.asset.id}.`);
        this.playerEngineItems.set(timelineItem.asset.id, playerEngineItem);
        playerEngineItem.load();
    }

    private async getItemPlayout(
        nextTimelineItem: TimelineItem,
        isInitialPlayout?: boolean,
        initialPlayoutStartPosition?: number
    ): Promise<PlayoutData> {
        const [playoutData, mainAssetPlayoutData] = await Promise.all([nextTimelineItem.asset.getPlayoutData(), this.mainAsset?.getPlayoutData()]);

        if (isInitialPlayout) {
            playoutData.autoplay = mainAssetPlayoutData?.autoplay;
            playoutData.position = initialPlayoutStartPosition;
        } else {
            playoutData.autoplay = true;
            if (nextTimelineItem.startTime !== 'start') {
                playoutData.position = nextTimelineItem.startTime;
            }
        }

        const isMainContent = nextTimelineItem.type === TimelineItemType.MainContent;
        if (isMainContent) {
            const firstCdn = this.cdnSelector.setCdns(playoutData.cdns);
            const modifiedPlayout = {
                ...playoutData,
                cdns: [firstCdn],
            };

            return modifiedPlayout;
        }

        playoutData.custom = {
            ...playoutData.custom,
            mainContentProtocol: mainAssetPlayoutData?.stream.protocol,
        };

        return playoutData;
    }

    private async handlePreconstructAndPreload(nextTimelineItem: TimelineItem): Promise<void> {
        if (!CoreVideoInternal.playerCapabilities.preconstructItems || nextTimelineItem?.isPreconstructed) {
            return;
        }

        const nextPei = this.playerEngineItems.get(nextTimelineItem.asset.id);

        if (nextPei) {
            return;
        }

        let resolver: () => void;
        const promise = new Promise<void>((res) => (resolver = res));
        this.timelinePreconstructPreloadPromises.set(nextTimelineItem, promise);

        nextTimelineItem.isPreconstructed = true;
        nextTimelineItem.isPreloaded = false;
        this.logger?.info(`Pre-constructing next item in timeline: ${nextTimelineItem.id} ${nextTimelineItem.asset.id}`);
        const playoutData = await this.getItemPlayout(nextTimelineItem);
        this.preconstructPei(playoutData, nextTimelineItem);

        if (CoreVideoInternal.playerCapabilities.preloadItems) {
            this.preloadTimelineItem(nextTimelineItem);
        }

        resolver!();
        this.timelinePreconstructPreloadPromises.delete(nextTimelineItem);
    }

    private preconstructPei(playoutData: PlayoutData, timelineItem: TimelineItem): void {
        const modifiedPlayout = { ...playoutData, autoplay: false };
        const playerEngineItem = this.timelinePlayerCreationHelper.createPlayerEngineItem(modifiedPlayout);
        playerEngineItem.setVisibility(false);

        this.logger?.info(`Created engine item ${playerEngineItem.id} for ${timelineItem.id} ${timelineItem.asset.id}.`);
        this.playerEngineItems.set(timelineItem.asset.id, playerEngineItem);
    }

    private preloadTimelineItem(timelineItem: TimelineItem): void {
        timelineItem.isPreloaded = true;
        const preconstructedPei = this.playerEngineItems.get(timelineItem.asset.id);

        this.timelineItemPreloadingObservable.notifyObservers({ timelineItem, playerEngineItem: preconstructedPei! });

        preconstructedPei?.load();
    }

    private async playTimelineItem(
        nextTimelineItem?: TimelineItem | null,
        isInitialPlayout?: boolean,
        initialPlayoutStartPosition?: number
    ): Promise<void> {
        if (!nextTimelineItem) {
            this.logger?.info('No items left in timeline');
            this.timelineFinishedObservable.notifyObservers();
            await this.endTimeline();
            return;
        }

        const lastTimelineItem = this.currentTimelineItem;

        const existingPlayerEngineItem = this.playerEngineItems.get(nextTimelineItem.asset.id);
        let playoutData: PlayoutData | null = null;

        if (!existingPlayerEngineItem) {
            playoutData = await this.getItemPlayout(nextTimelineItem, isInitialPlayout, initialPlayoutStartPosition);

            // If we are already preconstructing/preloading we should wait for that to finish.
            await this.timelinePreconstructPreloadPromises.get(nextTimelineItem);
        } else {
            playoutData = existingPlayerEngineItem.playoutData;
        }

        this.timelineItemPlayoutObservable.notifyObservers(playoutData);
        if (playoutData.playoutRules) {
            this.timelineItemPlayoutRulesObservable.notifyObservers(playoutData.playoutRules);
        }

        this.manageFinishItemTransition(lastTimelineItem!, nextTimelineItem);

        // We must change the current timeline item after the transition is managed
        // to allow for resuming logic to happen before proceeding
        this.currentTimelineItem = nextTimelineItem;

        if (!existingPlayerEngineItem || (!nextTimelineItem.isPreconstructed && !existingPlayerEngineItem.isActive())) {
            this.logger?.info(`Playing next item in timeline: ${nextTimelineItem.id} ${nextTimelineItem.asset.id}`);
            this.loadPei(playoutData, nextTimelineItem);
        } else {
            this.logger?.info(`Resuming existing item: ${existingPlayerEngineItem.id} for ${nextTimelineItem.id}  ${nextTimelineItem.asset.id}.`);
            this.addPlayerEngineItemListeners(existingPlayerEngineItem);
            this.timelineItemStartedObservable.notifyObservers({
                timelineItem: nextTimelineItem,
                playerEngineItem: existingPlayerEngineItem,
            });

            existingPlayerEngineItem.setVisibility(true);

            const isResumingLoadedItem = nextTimelineItem.isPreloaded || existingPlayerEngineItem.state !== PlayerState.WaitingToLoad;

            if (isResumingLoadedItem) {
                await this.resumeTimelineItem(nextTimelineItem, existingPlayerEngineItem);
            } else {
                await this.waitForTime(CoreVideoInternal.playerCapabilities.loadNewItemDelayMs);
                existingPlayerEngineItem.load();

                await existingPlayerEngineItem.waitForState([PlayerState.Playing, PlayerState.Paused, PlayerState.Stopped]);

                if (existingPlayerEngineItem.state === PlayerState.Stopped) {
                    return;
                }

                await this.waitForTime(CoreVideoInternal.playerCapabilities.playNewItemDelayMs);
                existingPlayerEngineItem.play();
            }
        }

        this.manageStartItemTransition(lastTimelineItem!, nextTimelineItem);
    }

    private async resumeTimelineItem(timelineItem: TimelineItem, playerEngineItem: PlayerEngineItem): Promise<void> {
        await this.waitForTime(CoreVideoInternal.playerCapabilities.transitionToSuspendedItemDelayMs);

        if (isNumber(timelineItem.resumePosition)) {
            playerEngineItem.seek(timelineItem.resumePosition);
            timelineItem.resumePosition = null;
        }

        if (this.mainContentIsLinear && timelineItem.type === TimelineItemType.MainContent && isNumber(timelineItem.startTime)) {
            // we need to seek over the ad slate on live streams
            const realBreakDuration = Date.now() - this.previousBreakStartClock! / 1000;
            const startOffset = this.previousBreakExpectedDuration! > realBreakDuration ? this.previousBreakExpectedDuration! - realBreakDuration : 0;
            if (startOffset) {
                this.logger?.info(`Adjusting resume time by -${startOffset} secs due to errors during the break`);
            }
            playerEngineItem.seek(timelineItem.startTime - startOffset);
        }

        await this.waitForStateForMaxTime(playerEngineItem, [PlayerState.Seeking, PlayerState.Rebuffering], MAX_SEEKING_WAIT_MS);

        // This call to play causes issues for preloaded content and we might get an incorrect order of events
        if (!timelineItem.isPreloaded) {
            // Calling play here fixes an issue on LG which gets stuck in rebuffering
            // when resuming a timeline item if it was a managed seek (seeking over the adBreak)
            // I wish I knew why this worked.
            playerEngineItem.play();
        }

        await playerEngineItem.waitForState([PlayerState.Paused, PlayerState.Playing]);

        playerEngineItem.play();
    }

    private async waitForStateForMaxTime(
        playerEngineItem: PlayerEngineItem,
        state: PlayerState | Array<PlayerState>,
        timeoutMs: number
    ): Promise<void> {
        const timeout = this.waitForTime(timeoutMs);
        const stateEmitted = playerEngineItem.waitForState(state);

        await Promise.race([timeout, stateEmitted]);
    }

    private async waitForTime(timeMs?: number): Promise<void> {
        if (timeMs) {
            await new Promise((resolve) => {
                setTimeout(resolve, timeMs);
            });
        }
    }

    private manageFinishItemTransition(lastTimelineItem?: TimelineItem, nextTimelineItem?: TimelineItem): void {
        // Note in "manageStartItemTransition" and "manageFinishItemTransition" methods:
        // 'Finish' events are raised before their corresponding start events
        // The last ad's finish event is raised before the ad break's finish
        // The first ad's start is raised after the ad break's start
        // So in the edge case of two consecutive ad breaks, the sequence is:
        // 1) ad ends; 2) ad break ends; 3) ad break starts; 4) ad starts

        if (lastTimelineItem?.type === TimelineItemType.Advert) {
            const ad = this.adTimelineItemToAd.get(lastTimelineItem);
            if (ad) {
                const managedAd = this.interItemAdPolicyManager?.manageAdFinished(ad);
                if (managedAd) {
                    this.session.notifyAdFinished(managedAd);
                }
            }
        }

        if (nextTimelineItem?.type !== TimelineItemType.Advert && lastTimelineItem?.type === TimelineItemType.Advert) {
            if (!this.interItemAdPolicyManager) {
                return;
            }
            const ad = this.adTimelineItemToAd.get(lastTimelineItem);
            if (!ad) {
                return;
            }

            // Note: Since we sourced the break from the ad policy manager, it must be cloned in order to retain the original watched status before ad policy manager mutates it
            const adBreakCopy = cloneAdBreak(ad.adBreak);
            const managedAdBreak = this.interItemAdPolicyManager.manageAdBreakFinished(ad.adBreak);
            if (managedAdBreak) {
                this.adBreakFinishedObservable.notifyObservers(adBreakCopy);

                if (!adBreakCopy.watched) {
                    // Note: Ad policy manager itself is source of truth for break data in this case, so we notify of watched status change here
                    this.session.notifyAdBreakDataReceived(this.interItemAdPolicyManager.getAdBreaks());
                }
            }
        }
    }

    private manageStartItemTransition(lastTimelineItem?: TimelineItem, nextTimelineItem?: TimelineItem): void {
        // Note in "manageStartItemTransition" and "manageFinishItemTransition" methods:
        // 'Finish' events are raised before their corresponding start events
        // The last ad's finish event is raised before the ad break's finish
        // The first ad's start is raised after the ad break's start
        // So in the edge case of two consecutive ad breaks, the sequence is:
        // 1) ad ends; 2) ad break ends; 3) ad break starts; 4) ad starts

        if (nextTimelineItem?.type === TimelineItemType.Advert && lastTimelineItem?.type !== TimelineItemType.Advert) {
            this.previousBreakStartClock = Date.now();
            this.previousBreakExpectedDuration = 0;
            const ad = this.adTimelineItemToAd.get(nextTimelineItem);
            if (ad) {
                const managedAdBreak = this.interItemAdPolicyManager?.manageAdBreakStarted(ad.adBreak);
                if (managedAdBreak) {
                    this.previousBreakExpectedDuration = managedAdBreak.expectedDuration;
                    this.session.notifyAdBreakStarted(managedAdBreak);
                }
            }
        }

        if (nextTimelineItem?.type === TimelineItemType.Advert) {
            const ad = this.adTimelineItemToAd.get(nextTimelineItem);
            if (ad) {
                const managedAd = this.interItemAdPolicyManager?.manageAdStarted(ad);
                const videoElement = this.playerEngineItems.get(nextTimelineItem.asset.id)?.videoElement;
                if (managedAd) {
                    this.session.notifyAdStarted({ ...managedAd, videoElement });
                }
            }
        }
    }

    private async suspendTimelineItem(timelineItem: TimelineItem): Promise<void> {
        await this.endTimelineItem(timelineItem, true);
    }

    private async endTimelineItem(timelineItem: TimelineItem, shouldSuspend?: boolean): Promise<void | null> {
        if (!timelineItem) {
            return;
        }

        const playerEngineItem = this.playerEngineItems.get(timelineItem.asset.id);

        self.clearTimeout(this.exactEndOfTimelineItemTimeout);

        if (!playerEngineItem || !playerEngineItem.isActive()) {
            this.logger?.warn(
                `Tried to ${shouldSuspend ? 'suspend' : 'end'} TimelineItem which doesn't have an active PlayerEngineItem associated with it.`
            );
            return;
        }

        if (this.endingPlayerEngineItemPromise[timelineItem.asset.id]) {
            this.logger?.warn(
                `Tried to ${shouldSuspend ? 'suspend' : 'end'} a TimelineItem that was already ${
                    shouldSuspend ? 'suspending' : 'ending'
                }. Returning existing promise.`
            );
            return this.endingPlayerEngineItemPromise[timelineItem.asset.id];
        }

        playerEngineItem.removeEventListeners(this);

        if (shouldSuspend) {
            timelineItem.isSuspending = true;
            await playerEngineItem.waitForState([PlayerState.Playing, PlayerState.Paused]);
            playerEngineItem.pause();
            await playerEngineItem.waitForState(PlayerState.Paused);
            timelineItem.isSuspending = false;
            playerEngineItem.setVisibility(false);
        } else {
            playerEngineItem.stop();
            this.getCurrentPlayerEngineItem()?.resetErrorObservable();
            this.endingPlayerEngineItemPromise[timelineItem.asset.id] = playerEngineItem.waitForState(PlayerState.Stopped);
        }

        try {
            await this.endingPlayerEngineItemPromise[timelineItem.asset.id];
        } catch (err) {
            this.logger?.error('Unable to wait for stopped state due to error', err);
            this.timelineErrorObservable.notifyObservers(
                CvsdkError.from({
                    code: 'SDK.SESSION.TIMELINE.WAIT_FOR_STOP_FAILURE',
                    severity: ErrorSeverity.Fatal,
                    message: 'Unable to wait for stopped state',
                    cause: err,
                })
            );
        }
        this.endingPlayerEngineItemPromise[timelineItem.asset.id] = null;
        this.timelineItemEndedObservable.notifyObservers({ timelineItem, playerEngineItem });
    }

    private scheduleTransitionToNextItem(currentTimelineItem: TimelineItem, position: number, itemEndTime: number, logPrefix: string): number {
        // TODO: currently if the user fast forwards past the advert start time, we play the adverts. We need to find the requirements for this,
        // it might end up being configurable (e.g. timeline items might have to have a `isPassable` as well as a `isSkippable`)
        const timeOfTransitionScheduled = new Date().getTime();

        return (self as unknown as Window).setTimeout(
            async () => {
                if (this.timelineState !== TimelineState.Started) {
                    return;
                }

                const timeSinceTransitionScheduled = new Date().getTime() - timeOfTransitionScheduled;
                const currentPosition = (position + timeSinceTransitionScheduled / 1000).toFixed(2);
                this.logger?.info(`${logPrefix} reached end of TimelineItem (${currentPosition}/${currentTimelineItem.endTime})`);

                if (currentTimelineItem.type === TimelineItemType.MainContent && CoreVideoInternal.playerCapabilities.preconstructItems) {
                    await this.suspendTimelineItem(currentTimelineItem);
                } else {
                    await this.endTimelineItem(currentTimelineItem);
                }

                this.playTimelineItem(this.timeline?.getNextTimelineItem(currentTimelineItem));
            },
            (itemEndTime - position) * 1000
        );
    }

    private handlePlayerPositionUpdate = async (playerEngineItem: PlayerEngineItem, position: number): Promise<void> => {
        const logPrefix = this.getLogPrefix(playerEngineItem);
        this.currentTimelineItem?.asset.notifyPositionChanged(position);
        this.logger?.verbose(`${logPrefix} at position ${position.toFixed(2)}. Ending at ${this.currentTimelineItem?.endTime}.`);

        if (this.currentTimelineItem?.type === TimelineItemType.Advert) {
            this.updateAdPosition(this.currentTimelineItem, position);
        }
        const itemEndTime = isNumber(this.currentTimelineItem?.endTime) ? this.currentTimelineItem?.endTime : playerEngineItem.duration;
        if (!itemEndTime) {
            return;
        }

        const timeUntilItemEnd = itemEndTime - position;
        const isNearItemEnd = timeUntilItemEnd < (CoreVideoInternal.playerCapabilities.preloadBufferTimeSecs ?? PRELOAD_BUFFERING_SECS);

        if (!isNearItemEnd) {
            return;
        }

        const nextTimelineItem = this.timeline?.getNextTimelineItem(this.currentTimelineItem);

        if (nextTimelineItem) {
            this.handlePreconstructAndPreload(nextTimelineItem);
        }

        if (this.currentTimelineItem?.endTime !== 'end') {
            self.clearTimeout(this.exactEndOfTimelineItemTimeout);
            this.exactEndOfTimelineItemTimeout = this.scheduleTransitionToNextItem(this.currentTimelineItem!, position, itemEndTime, logPrefix);
        }
    };

    private getLogPrefix(playerEngineItem: PlayerEngineItem): string {
        return `${playerEngineItem.id} (${this.currentTimelineItem?.id} ${this.currentTimelineItem?.asset.id})`;
    }

    private addPlayerEngineItemListeners(playerEngineItem: PlayerEngineItem): void {
        const logPrefix = this.getLogPrefix(playerEngineItem);
        let hasLoaded = false;

        playerEngineItem.onFinished(async () => {
            perfLogger.measure(PerfKey.root, PerfTag.timelineTransitionStart);

            this.logger?.verbose(`${playerEngineItem.id} (${this.currentTimelineItem?.id}) finished`);
            await this.endTimelineItem(this.currentTimelineItem!);

            await this.playTimelineItem(this.timeline?.getNextTimelineItem(this.currentTimelineItem));
        }, this);

        self.clearTimeout(this.exactEndOfTimelineItemTimeout);
        playerEngineItem.onPositionChanged((position) => this.handlePlayerPositionUpdate(playerEngineItem, position), this);

        playerEngineItem.onStateChanged((state) => {
            this.currentTimelineItem?.asset.notifyStateChanged(state);

            this.logger?.info(`${logPrefix} in state ${PlayerState[state]}`);
            self.clearTimeout(this.exactEndOfTimelineItemTimeout);
            if (state === PlayerState.Stopped) {
                this.logger?.info(`${logPrefix} stopped, removing from cache`);
                this.playerEngineItems.delete(this.currentTimelineItem!.asset.id);
            } else if (![PlayerState.Loading, PlayerState.Rebuffering].includes(state)) {
                hasLoaded = true;
            }
        }, this);

        playerEngineItem.onUserStopped(() => {
            this.endTimeline();
        }, this);

        playerEngineItem.onError(async (error: CvsdkError) => {
            this.currentTimelineItem?.asset.notifyError(error);
            this.logger?.error(`${logPrefix} had an error:`, error);

            if (error.severity === ErrorSeverity.Warning) {
                this.timelineErrorObservable.notifyObservers(error);
                return;
            }

            if (this.currentTimelineItem?.type === TimelineItemType.MainContent && !hasLoaded && this.checkErrorShouldCdnSwitch(error)) {
                await this.attemptCdnSwitch(error);
            } else {
                this.handleTimelineError(new TimelineError(error, this.currentTimelineItem!.type), false);
            }
        }, this);
    }

    private updateAdPosition(timelineItem: TimelineItem, position: number): void {
        const currentAd = this.adTimelineItemToAd.get(timelineItem);
        if (currentAd) {
            const adIndex = currentAd.adBreak.ads.findIndex((ad) => ad.id === currentAd.id);
            const previousAdsDuration = adIndex < 1 ? 0 : currentAd.adBreak.ads.slice(0, adIndex).reduce((a, b) => a + b.expectedDuration, 0);

            this.session.notifyAdPositionChanged({
                ad: currentAd,
                adBreak: currentAd.adBreak,
                adBreakPosition: previousAdsDuration + position,
                adPosition: position,
            });
        }
    }

    private isDrmError(error: CvsdkError): boolean {
        return /((\.|_)DRM)|((\.|_)HDCP)/gi.test(error.code);
    }

    private isBackgroundedError(error: CvsdkError): boolean {
        return /((\.|_)BACKGROUNDED)/gi.test(error.code);
    }

    private checkErrorShouldCdnSwitch(error: CvsdkError) {
        return error.severity === ErrorSeverity.Fatal && !this.isDrmError(error) && !this.isBackgroundedError(error);
    }

    private getCurrentPlayerEngineItem(): PlayerEngineItem | undefined {
        return this.playerEngineItems.get(this.currentTimelineItem!.asset.id);
    }

    private async handleTimelineError(error: CvsdkError, shouldFailSilently: boolean): Promise<void> {
        if (shouldFailSilently) {
            this.logger?.warn('Failed with silenced error:', error);
        } else {
            this.timelineErrorObservable.notifyObservers(error);
        }

        if (this.currentTimelineItem?.type === TimelineItemType.MainContent) {
            await this.endTimeline();
        } else if (this.currentTimelineItem?.type === TimelineItemType.Advert) {
            const nextItem = this.timeline?.getNextTimelineItem(this.currentTimelineItem);
            await this.endTimelineItem(this.currentTimelineItem);
            await this.playTimelineItem(nextItem);
        }
    }

    private qualityFailoverIntercept = (): void => {
        this.qualityFailoverObservable.notifyObservers(VideoFormat.HD);
        const message = 'UHD quality failover to HD - preventing CDN switch';

        this.logger?.warn(message);
        this.handleTimelineError(
            new TimelineError(
                {
                    message,
                    code: 'SDK.QUALITY.FAILOVER',
                    cause: 'UHD asset unable to be played, quality switch over to HD',
                },
                this.currentTimelineItem!.type
            ),
            false
        );

        return;
    };

    private async cdnSwitch(cdnSwitchEvent: CdnSwitchEvent): Promise<void> {
        this.logger?.info(`Switching to next CDN: "${cdnSwitchEvent.toCdn.name}"`);

        const isFirstTimelineItem = this.currentTimelineItem?.asset.id === this.timeline?.getFirstTimelineItem().asset.id;

        // On devices (namely Xbox) that do not have a direct mechanism for notifying when a
        // CDN request has failed, events like `endTimelineItem` and the 'player changing to a stopped
        // state' - they reset the BufferingLimit timer. This results in the limit never being reached during a
        // CDN switch and so we set a flag here to prevent the BufferingLimit being reset until after
        // we attempt the switch
        this.bufferingLimit.isCdnSwitching = true;
        await this.endTimelineItem(this.currentTimelineItem!);

        const currentPei = this.getCurrentPlayerEngineItem();

        const failedCdns = (currentPei?.playoutData.failedCdns || []).concat(cdnSwitchEvent.fromCdn.name);

        // Assume  true unless explicit false
        const mainPlayout = await this.mainAsset?.getPlayoutData();

        const originalAutoplay = mainPlayout?.autoplay ?? true;
        const autoplay = isFirstTimelineItem ? originalAutoplay : true;

        const modifiedToCdn = {
            ...cdnSwitchEvent.toCdn,
        };

        this.cdnSwitchedObservable.notifyObservers({
            ...cdnSwitchEvent,
            toCdn: modifiedToCdn,
            switchError: {
                ...cdnSwitchEvent.switchError,
                severity: ErrorSeverity.Warning,
            },
        });

        const playoutData = currentPei?.playoutData as PlayoutData;
        const playout = this.sessionPrecursor.modifyPlayoutDataForCdnSwitch({
            ...playoutData,
            cdns: [modifiedToCdn],
            failedCdns,
            autoplay,
        });

        await this.loadPei(playout, this.currentTimelineItem!);
        this.bufferingLimit.isCdnSwitching = false;
    }

    private async attemptCdnSwitch(switchError: CvsdkError): Promise<void> {
        const selection = this.cdnSelector.selectNextCdn();
        const mainPlayout = await this.mainAsset?.getPlayoutData();
        const { toCdn, fromCdn } = selection;
        this.logger?.info(`CDN ("${fromCdn.name}") failed`, switchError);

        if (toCdn) {
            if (
                this.sessionPrecursor.sessionItem.videoFormatConfig?.support?.maxVideoFormat === VideoFormat.UHD &&
                mainPlayout?.stream.quality === StreamQuality.UHD &&
                this.sessionPrecursor.sessionItem.enableQualityFailover
            ) {
                return this.qualityFailoverIntercept();
            }

            await this.cdnSwitch({ toCdn, fromCdn, switchError });
        } else {
            this.logger?.warn(`No more CDNs for ${this.currentTimelineItem?.id} (${this.currentTimelineItem?.asset.id})`);
            await this.handleTimelineError(switchError, false);
        }
    }
}
