import { DeviceType } from '@sky-uk-ott/client-lib-js-device';

export enum ShakaEcmaScriptVersion {
    ES5 = 'ES5',
    ES_2015 = 'ES_2015',
}

export enum ShakaTimeoutPolicy {
    DEFAULT = 'DEFAULT',
    EXPERIMENT_A = 'EXPERIMENT_A',
    EXPERIMENT_B = 'EXPERIMENT_B',
    EXPERIMENT_C = 'EXPERIMENT_C',
}

export enum ShakaPlayerVersion {
    V_3_0_5 = 'v3.0.5',
    V_3_3_10 = 'v3.3.10',
    V_4_2_12 = 'v4.2.12',
    V_41000 = 'v41000',
}

/**
 * @public
 */
export enum OverrideableDevice {
    Browser = DeviceType.Browser,
    Quest = DeviceType.Quest,
}

/**
 * @public
 */
export enum Proposition {
    NOWOTT = 'NOWOTT', // Gold
    NowTV = 'NowTV',
    OneApp = 'OneApp',
    Peacock = 'Peacock',
    SkyStore = 'SkyStore',
    SkyShowtime = 'SkyShowtime',
    NBC = 'NBC', // Handles clients who needed a replacement player for the PDK, currently includes NBC, and AccessHollywood. Others will follow
    Showmax = 'Showmax',
}
