export enum SessionRestartType {
    /** For restarts corresponding to playback failure. */
    VPF_RETRY,
    /** For non-error conditions, such as restarting the session to apply a new bitrate cap. */
    RESTART,
}

export enum SessionRestartStatus {
    /** No active restart in progress. */
    IDLE,
    /** An error-related restart is in progress. */
    VPF_RETRYING,
    /** A non-error related restart is in progress. */
    RESTARTING,
}
