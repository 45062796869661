import type { AddonConfig, StreamProxyConfig } from '../../internal-config';
import type { RemoteConfigSplit } from '../../sdk-config';
import type { DeclaredOptionalsAddonsConfig, InternalAddonsConfig, RequiredPropertiesDefinition } from './addons-config-adapter-common';
import { genericConfigEnabledCheck } from './addons-config-adapter-common';

// Internal Config
type StreamProxyInternalConfig = InternalAddonsConfig['streamProxy'];

// Client Config when Remote Config is disabled
export type StreamProxyClientConfig = AddonConfig<StreamProxyConfig>;
export function adaptStreamProxyClientConfig(config: StreamProxyClientConfig | undefined): StreamProxyInternalConfig {
    return config;
}

// Server Config when Remote Config is enabled
type StreamProxyRemoteServerConfig = AddonConfig<{}>;

// Client Config when Remote Config is enabled
type StreamProxyRemoteClientConfig = StreamProxyConfig;

export type StreamProxyRemoteConfigSplit = RemoteConfigSplit<StreamProxyRemoteServerConfig, StreamProxyRemoteClientConfig>;
export function adaptStreamProxyRemoteConfigSplit(config: StreamProxyRemoteConfigSplit): DeclaredOptionalsAddonsConfig<StreamProxyInternalConfig> {
    const requiredFields: RequiredPropertiesDefinition<StreamProxyRemoteClientConfig> = {
        proxyHost: null,
    };
    const isEnabled = genericConfigEnabledCheck(config, requiredFields, 'Stream Proxy');
    if (!isEnabled) {
        return;
    }

    const { clientConfig } = config;
    return {
        enabled: isEnabled,
        config: {
            proxyHost: clientConfig!.proxyHost,
            maxAudioTracks: clientConfig!.maxAudioTracks,
            maxSubtitleTracks: clientConfig!.maxSubtitleTracks,
        },
    };
}
