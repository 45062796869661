// cspell:words dtiw MDMP
import { DeviceType } from '@sky-uk-ott/client-lib-js-device';

import { OverrideableDevice } from '../../config/internal-config';
import type { Tizen } from '@sky-uk-ott/client-lib-js-device';
import { sdkLogger } from '../../logger';

interface Context extends Window {
    Windows?: typeof Windows;
    PalmSystem?: string;
    tizen?: Tizen;
}

const getStringContains = (subject?: string) => (matchers: Array<string>) =>
    subject && !!matchers.find((matcher) => subject.toLowerCase().includes(matcher.toLowerCase()));

/**
 * @public
 * @deprecated As an alternative, use `device.type` or `CoreVideoSdk.deviceType` after having loaded the device with `loadDeviceFactory` from `@sky-uk-ott/client-lib-js-device`.
 */
// eslint-disable-next-line complexity
export function getCurrentDeviceType(context: Context, override?: OverrideableDevice): DeviceType {
    if (Object.prototype.hasOwnProperty.call(context, 'ion')) {
        return DeviceType.Ion;
    }

    const userAgent = getUserAgent(context);
    const containsUserAgents = getStringContains(userAgent);

    if (override && OverrideableDevice[override]) {
        return OverrideableDevice[override] as string as DeviceType;
    }

    // This rule needs to take precedence over chromecast because the vizio agent string can also includes 'chromecast'
    if (containsUserAgents(['vizio', 'conjure', 'smartcast'])) {
        return DeviceType.Vizio;
    }

    // This rule needs to take precedence over the android one because the chromecast agent string can also include an 'android' word
    if (containsUserAgents(['crkey'])) {
        return DeviceType.Chromecast;
    }

    if (containsUserAgents(['hisense', 'vidaa'])) {
        return DeviceType.Vidaa;
    }

    if (containsUserAgents(['playstation 3'])) {
        return DeviceType.WebMaf;
    }

    if (containsUserAgents(['playstation 5'])) {
        return DeviceType.Prospero;
    }

    if (containsUserAgents(['playstation 4'])) {
        const webMafVersionStr = userAgent.toLowerCase().match(/webmaf\/v([0-9]+)(.*)/)?.[1];
        if (!webMafVersionStr) {
            sdkLogger.warn("Can't lookup WebMafVersion, will fallback to WebMaf");
            return DeviceType.WebMaf;
        }
        const webmafVersion = parseInt(webMafVersionStr, 10);
        if (webmafVersion >= 3) {
            return DeviceType.Prospero;
        }
        return DeviceType.WebMaf;
    }

    if (containsUserAgents(['web0s'])) {
        return DeviceType.WebOS;
    }

    if (self && Object.prototype.hasOwnProperty.call(self, 'tizen') && context.tizen) {
        return DeviceType.Tizen;
    }

    if (containsUserAgents(['youviewhtml'])) {
        return DeviceType.YouView;
    }

    if (self && Object.prototype.hasOwnProperty.call(self, 'Android')) {
        return DeviceType.Android;
    }

    if (containsUserAgents(['msapphost'])) {
        const deviceForm = context.Windows?.System.Profile.AnalyticsInfo.deviceForm;

        if (getStringContains(deviceForm)(['xbox series'])) {
            return DeviceType.XboxSeries;
        }
        return DeviceType.XboxOne;
    }

    if (containsUserAgents(['FVP_STB'])) {
        return DeviceType.Netgem;
    }

    // Must be above Xfinity due to containing 'WPE'
    if (containsUserAgents(['sky_', 'xioneuk'])) {
        return DeviceType.Llama;
    }

    // cspell:disable-next-line
    if (containsUserAgents(['EOSSTB', 'EOS2STB', 'APLSTB'])) {
        return DeviceType.OneSdk;
    }

    if (containsUserAgents(['MDMP1001S', 'PS5525IMC'])) {
        return DeviceType.MultiChoiceRdk;
    }

    if (containsUserAgents(['xfinity', ' wpe'])) {
        return DeviceType.Xfinity;
    }

    if (containsUserAgents(['dt_stb_bcm'])) {
        return DeviceType.Zenterio;
    }

    if (containsUserAgents(['OculusBrowser'])) {
        return DeviceType.Quest;
    }

    const containsQueryString = getStringContains(context.location.search);
    if (containsQueryString(['deviceModel=KPN'])) {
        return DeviceType.Kpn;
    }

    if (containsQueryString(['deviceModel=generic'])) return DeviceType.Generic;

    return DeviceType.Browser;
}

export function getUserAgent(context: Context): string {
    return context && context.navigator && context.navigator.userAgent && context.navigator.userAgent.toLowerCase();
}
