import type { AddonsConfig } from '../config/internal-config';
import { CoreVideoInternal } from '../core-video-internal';
import type { PlayerCapabilities } from '../core/player/player-engine';
import type { PlayoutData } from '../core/player/playout-data';
import type { Track } from '../core/player/track';
import type { SessionItem } from '../core/session-controller/session-controller';
import type { SessionControllerInternal } from '../core/session-controller/session-controller-internal';
import type { VideoPlatformIntegration } from '../core/video-platform-integration/video-platform-integration';
import { sdkLogger } from '../logger';
import type { PromiseResult } from '../utils/promise-utils';
import { allSettled } from '../utils/promise-utils';

import type { AdvertisingData } from './addon-playout-data';
import type {
    AddonsFactories,
    BookmarkUpdateEnhancerAddonFactory,
    EventBoundaryAddonFactory,
    HeartbeatAddonFactory,
    LinearContentEmbargoAddonFactory,
    StreamMetadataFiltererAddonFactory,
    StreamMetadataParserAddonFactory,
    SyntheticEventAddonFactory,
    WatermarkingAddonFactory,
} from './addons-factories';
import type { BookmarkUpdateEnhancerAddon } from './bookmark-update-enhancer/bookmark-update-enhancer-addon';
import type { EventBoundaryAddon } from './event-boundary/event-boundary-addon';
import type { HeartbeatAddon } from './heartbeat/heartbeat-addon';
import type { LinearContentEmbargoAddon } from './linear-content-embargo/linear-content-embargo-addon';
import type { ReportingAddon } from './reporting/reporting-addon';
import type { StreamMetadataFiltererAddon } from './stream-metadata/filterer';
import type { StreamMetadataParserAddon } from './stream-metadata/stream-metadata-parser';
import type { SyntheticEventAddon } from './synthetic-events/synthetic-event-addon';
import type { WatermarkingAddon } from './watermarking/watermarking-addon';

export class AddonManager {
    private eventBoundaryAddon?: EventBoundaryAddon;
    private heartbeatAddon?: HeartbeatAddon;
    private bookmarkUpdateEnhancerAddon?: BookmarkUpdateEnhancerAddon;
    private watermarkingAddon?: WatermarkingAddon;
    private streamMetadataFiltererAddon?: StreamMetadataFiltererAddon;
    private streamMetadataParserAddon?: StreamMetadataParserAddon;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private syntheticEventAddons?: Array<SyntheticEventAddon<any>>;
    private linearContentEmbargoAddon?: LinearContentEmbargoAddon;
    private isAdvertisingPrepared = false;
    private currentVideoElement?: HTMLVideoElement;

    constructor(
        private addonsConfig: AddonsConfig,
        private sessionItem: SessionItem,
        private videoPlatformIntegration: VideoPlatformIntegration,
        private session: SessionControllerInternal,
        private addonsFactories: AddonsFactories
    ) {
        this.session.onVideoElementCreated(this.handleVideoElementCreated.bind(this));
    }
    public async load(playerCapabilities: PlayerCapabilities): Promise<void> {
        this.eventBoundaryAddon = this.instantiateAddon<EventBoundaryAddon, EventBoundaryAddonFactory>(this.addonsFactories.eventBoundary, [
            this.addonsConfig.eventBoundary,
            this.session,
        ]);
        this.heartbeatAddon = this.instantiateAddon<HeartbeatAddon, HeartbeatAddonFactory>(this.addonsFactories.heartbeat, [
            this.addonsConfig.heartbeat,
            this.videoPlatformIntegration,
            this.session,
            this.sessionItem,
        ]);

        this.streamMetadataFiltererAddon = this.instantiateAddon<StreamMetadataFiltererAddon, StreamMetadataFiltererAddonFactory>(
            this.addonsFactories.streamMetadataFilterer,
            [this.session, this.sessionItem]
        );

        this.streamMetadataParserAddon = this.instantiateAddon<StreamMetadataParserAddon, StreamMetadataParserAddonFactory>(
            this.addonsFactories.streamMetadataParser,
            [this.session, this.sessionItem]
        );

        this.syntheticEventAddons =
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.instantiateAddon<Array<SyntheticEventAddon<any>>, SyntheticEventAddonFactory>(this.addonsFactories.syntheticEvents, [
                this.session,
                this.sessionItem,
                playerCapabilities,
            ]) || [];

        this.bookmarkUpdateEnhancerAddon = this.instantiateAddon<BookmarkUpdateEnhancerAddon, BookmarkUpdateEnhancerAddonFactory>(
            this.addonsFactories.bookmarkUpdateEnhancer,
            [this.addonsConfig.bookmarkUpdateEnhancer, this.session, this.sessionItem, this.videoPlatformIntegration]
        );

        this.linearContentEmbargoAddon = this.instantiateAddon<LinearContentEmbargoAddon, LinearContentEmbargoAddonFactory>(
            this.addonsFactories.linearContentEmbargo,
            [this.session]
        );
    }

    // Load and initialize addons lazily as PlayoutData demands
    public async lazyLoad(playerCapabilities: PlayerCapabilities, playoutData: PlayoutData): Promise<void> {
        sdkLogger.verbose('Lazy loading addons');
        const { addonLoaders } = CoreVideoInternal.getPropositionExtensions();

        // We want failed addons to fail without blocking other addons from loading
        await allSettled(
            addonLoaders
                .filter((loader) => loader.isLazy && loader.shouldLoadForPlayout(playoutData))
                .map((loader) => {
                    return loader.load(CoreVideoInternal.config);
                })
        );

        if (this.addonsFactories.watermarking && playoutData.watermarking) {
            this.watermarkingAddon = this.instantiateAddon<WatermarkingAddon, WatermarkingAddonFactory>(this.addonsFactories.watermarking, [
                this.addonsConfig.watermarking,
                this.session,
                CoreVideoInternal.getPropositionExtensions().watermarkingPolicy ?? null,
            ]);

            // Upon load, the addon may have missed on the initial playback observable notifications
            this.watermarkingAddon?.lazyInit(this.currentVideoElement, playoutData, this.session.getCurrentState());
        }
    }

    public getReportingAddons(): Array<ReportingAddon> {
        return this.session.getPrecursor()!.getReportingAddons();
    }

    public prepareAdvertising(advertisingData: AdvertisingData): void {
        if (!this.isAdvertisingPrepared) {
            this.getReportingAddons().forEach((a) => a.setupAdvertising(advertisingData));
            this.isAdvertisingPrepared = true;
        }
    }

    public filterAudioTracks(audioTracks: Array<Track>): Array<Track> {
        if (this.streamMetadataFiltererAddon) {
            return this.streamMetadataFiltererAddon.filterAudioTracks(audioTracks);
        }
        return audioTracks;
    }

    public filterSubtitlesTracks(subtitlesTracks: Array<Track>): Array<Track> {
        if (this.streamMetadataFiltererAddon) {
            return this.streamMetadataFiltererAddon.filterSubtitlesTracks(subtitlesTracks);
        }
        return subtitlesTracks;
    }

    public isWatermarkingEnabled() {
        return Boolean(this.watermarkingAddon);
    }

    public destroy(): Promise<Array<PromiseResult>> {
        const wrappedPromises = [
            this.eventBoundaryAddon,
            this.heartbeatAddon,
            this.watermarkingAddon,
            this.bookmarkUpdateEnhancerAddon,
            this.streamMetadataParserAddon,
            this.linearContentEmbargoAddon,
            ...(this.syntheticEventAddons || []),
        ]
            .filter((addon) => !!addon)
            .map(async (addon) => {
                try {
                    await addon?.destroy();
                    sdkLogger.verbose(`Addon destroyed: ${addon?.name}`);
                    return;
                } catch (e) {
                    const message = `Error while destroying addon [${addon?.name}]: ${e}`;
                    sdkLogger.warn(message);
                    this.session.notifyWarning('ADDON_MANAGER.DESTROY', message);
                    throw e;
                }
            });

        return allSettled(wrappedPromises);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private instantiateAddon<T, F extends (...a: Array<any>) => any>(factory: F | undefined, args: Parameters<F>): T | undefined {
        if (!factory) {
            return undefined;
        }

        try {
            return factory(...args);
        } catch (error) {
            const msg = `Error while loading addons: ${error}`;
            sdkLogger.error(msg, error);
            this.session.notifyWarning('ADDON_MANAGER.LOAD', msg);
        }
    }

    private handleVideoElementCreated(videoElement: HTMLVideoElement): void {
        this.currentVideoElement = videoElement;
    }
}
