import type { SessionItem } from '../../../core/session-controller/session-controller';
import type { SessionControllerInternalProxy } from '../../../core/session-controller/session-controller-internal-proxy';
import type { AddonsFactories } from '../../addons-factories';

export class MseWrapManager {
    constructor(
        private session: SessionControllerInternalProxy,
        private addonsFactories: AddonsFactories,
        private sessionItem: SessionItem
    ) {
        this.addonsFactories.mseWrap?.(this.session, this.sessionItem);
    }
}
