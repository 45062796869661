import type { DeviceInformation } from '@sky-uk-ott/client-lib-js-device';

import { Resolutions } from '../../utils/resolution';
import type { LimitationEntry } from '../../utils/supported-formats';
import { getSupportedFormats } from '../../utils/supported-formats';
import { matchPartialDeviceInformation } from '../../utils/device-information';
import type { SessionItem } from '../session-controller/session-controller';

import type { CompatibleStreamPropertyGroup } from './stream-capability-specification';
import { VideoFormat, VideoColourSpace } from './player.enums';
export { VideoFormat, VideoColourSpace, VideoCodec, HdcpLevel, WidevineSecurityLevel } from './player.enums';

export type VideoResolution = {
    width: number;
    height: number;
};

export const RESOLUTION_540P: VideoResolution = {
    width: 960,
    height: 540,
};

export const RESOLUTION_576P: VideoResolution = {
    width: 960,
    height: 576,
};

export const DEFAULT_VIDEO_FORMAT = VideoFormat.HD;
export const DEFAULT_FRAME_RATE = 30;
export const MIN_4K_HDCP_VERSION = '2.2';
export const MIN_HD_HDCP_VERSION = '1.0';

export function getMaxFrameRate(
    sessionItem: SessionItem,
    deviceInfo: DeviceInformation,
    compatibleStreamPropertyGroup: CompatibleStreamPropertyGroup | undefined
) {
    const deviceLimitationEntries =
        sessionItem.videoFormatConfig?.deviceLimitationList
            ?.filter((entry) => {
                return matchPartialDeviceInformation(entry.device, deviceInfo);
            })
            .map((entry) => entry.support.maxFrameRate) ?? [];

    const formatLimitations: Array<number> = [
        ...deviceLimitationEntries,
        compatibleStreamPropertyGroup?.maxFrameRate,
        sessionItem.videoFormatConfig?.support?.maxFrameRate,
    ].filter((format) => format !== undefined && format !== null) as Array<number>;

    return formatLimitations.length > 0 ? Math.min(...formatLimitations) : DEFAULT_FRAME_RATE;
}

export function getMaxVideoFormat(
    sessionItem: SessionItem,
    deviceInfo: DeviceInformation,
    compatibleStreamPropertyGroup: CompatibleStreamPropertyGroup | undefined
): VideoFormat {
    const deviceLimitationEntries =
        sessionItem.videoFormatConfig?.deviceLimitationList
            ?.filter((entry) => {
                return matchPartialDeviceInformation(entry.device, deviceInfo);
            })
            .map((entry) => entry.support.maxVideoFormat) ?? [];

    const formatLimitations: Array<VideoFormat | undefined> = [
        ...deviceLimitationEntries,
        compatibleStreamPropertyGroup?.maxVideoFormat,
        sessionItem.videoFormatConfig?.support?.maxVideoFormat,
    ].filter((format) => format !== undefined && format !== null);

    return formatLimitations.length > 0 ? Math.min(...(formatLimitations as Array<VideoFormat>)) : DEFAULT_VIDEO_FORMAT;
}

export function getVideoFormatFromResolution(res: { width: number; height: number }): VideoFormat {
    if (res.width >= Resolutions.UHD.minWidth && res.height >= Resolutions.UHD.minHeight) {
        return VideoFormat.UHD;
    } else if (res.width >= Resolutions.HD.minWidth && res.height >= Resolutions.HD.minHeight) {
        return VideoFormat.HD;
    }

    return VideoFormat.SD;
}

export function getSupportedColourSpaces(
    sessionItem: SessionItem,
    deviceInfo: DeviceInformation,
    compatibleStreamPropertyGroup: CompatibleStreamPropertyGroup | undefined
): Array<VideoColourSpace> {
    const deviceLimitationEntries = sessionItem.videoFormatConfig?.deviceLimitationList?.filter((entry) => {
        return matchPartialDeviceInformation(entry.device, deviceInfo) && entry.support.supportedColourSpaces?.length! > 0;
    });

    const deviceLimitations = deviceLimitationEntries?.map((entry) => entry.support.supportedColourSpaces);

    return getSupportedFormats(
        compatibleStreamPropertyGroup?.supportedColourSpaces || [VideoColourSpace.SDR],
        sessionItem.videoFormatConfig?.support?.supportedColourSpaces,
        deviceLimitations as Array<LimitationEntry<VideoColourSpace>>
    );
}
