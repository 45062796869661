import type { Cdn, StreamingProtocol } from '../../core/player/playout-data';
import type { Position } from '../../core/session-controller/timeline/timeline';
import type { NonLinearAdData } from './non-linear-adverts/non-linear-ad-types';
import type { AdvertisingContentType } from './adverts.enums';

/**
 * @public
 */
export enum AdBreakType {
    Preroll = 'Preroll',
    Midroll = 'Midroll',
    Postroll = 'Postroll',
}

/**
 * @public
 */
export enum SsaiStitcherType {
    Yospace = 'Yospace',
    MediaTailor = 'MediaTailor',
    None = 'NA' /* None applies to CSAI */,
}

/**
 * @public
 */
export enum AdMetadataSource {
    Manifest = 'Manifest',
    MediaTailor = 'MediaTailor',
}

export type AdBreakFeatures = {
    containsBrightlineAds?: boolean;
};

export type AdFeatures = {
    isBrightlineAd?: boolean;
};

/**
 * @public
 */
export interface AdBreak<T extends Ad = Ad> {
    id: string;
    ads: Array<T>;
    trackingEvents?: Array<AdTrackingEvent>;
    type: AdBreakType;
    watched: boolean;
    position?: Position;
    expectedDuration?: number;
    streamType: AdStreamType;
    ssaiStitcherType: SsaiStitcherType;
    features?: AdBreakFeatures;
    metadataSource?: AdMetadataSource;
}

/**
 * @public
 */
export type AdBreaks<T extends Ad = Ad> = Array<AdBreak<T>>;

/**
 * @public
 */
export type CombinedAdBreaks<T extends Ad = Ad> = {
    adBreaks: AdBreaks<T>;
    nonLinearAds: Array<NonLinearAdData>;
};

/**
 * @public
 */
export type PartialAdBreak = Pick<AdBreak, 'id' | 'ads' | 'position' | 'expectedDuration' | 'features' | 'watched'>;

export type AdSystem = 'FreeWheel';

export interface BaseAd {
    id: string;
    creativeId?: string;
    freewheelId?: string;
    name: string;
    expectedDuration: number;
    streamingProtocol: StreamingProtocol;
    adSystem?: AdSystem;
    advertiser?: string;
    status?: AdStatus;
    isSlate?: boolean;
    extensions?: Array<AdExtension>;
    companions?: Array<CompanionAd>;
    verifications?: Array<AdVerification>;
    features?: AdFeatures;
    advertisingContentType?: AdvertisingContentType;
    adBreak: AdBreak;
    trackingEvents?: Array<AdTrackingEvent>;
    coppaApplies?: boolean;
    videoElement?: HTMLVideoElement;
}

export interface VodAd extends BaseAd {
    skipOffset?: number;
    clickUrl?: string;
    cdns: Array<Cdn>;
    trackingEvents: Array<AdTrackingEvent>;
}

export interface ServerSideAd extends BaseAd {
    clickUrl?: string;
}

export interface ClientSideAd extends BaseAd {
    clickUrl?: string;
}

/**
 * @public
 */
export type Ad = VodAd | ServerSideAd | ClientSideAd;

/**
 * @public
 */
export type AdPosition = {
    adPosition: number;
    ad?: Ad;
    adBreakPosition: number;
    adBreak: PartialAdBreak;
};

export type AdEvent = {
    ad: Ad;
    event: TrackingEventType;
    adKey: string;
};

export enum AdExtensionType {
    FreeWheel = 'FreeWheel',
}

export interface AdExtensionParam {
    creativeId: string | null;
    name: string | null;
    type: string | null;
    value?: string;
}

export interface ProgrammaticNodes {
    name: string;
    value: string;
}

export interface AdExtension {
    type: AdExtensionType | string;
    parameters: Array<AdExtensionParam>;
    programmatic?: Array<ProgrammaticNodes>;
}

export interface AdVerification {
    vendor: string;
    javaScriptResource: Array<GenericResource>;
    verificationParameters: string;
}

interface GenericResource {
    apiFramework: string;
    uri: string;
}

/**
 * @public
 */
export enum CompanionProvider {
    Brightline = 'Brightline',
}

export interface CompanionAd {
    provider: CompanionProvider;
    attributes?: {
        // Defined by the VAST spec
        width?: number; // Required property in the vast spec but missing from ATV data
        height?: number; // Required property in the vast spec but missing from ATV data
        id?: string;
        assetWidth?: number;
        assetHeight?: number;
        expandedWidth?: number;
        expandedHeight?: number;
        apiFramework?: string;
        adSlotId?: string;
        pxratio?: number;
        renderingMode?: 'default' | 'end-card' | 'concurrent';
    };
    url: string;
    tracking: Array<{
        type: string;
        url: string;
    }>;
}

export interface AdTrackingEvent {
    type: TrackingEventType;
    url: string;
    isActivated?: boolean;
    isPersistent?: boolean;
}

export enum TrackingEventType {
    BreakStart = 'breakStart',
    BreakEnd = 'breakEnd',
    AdImpression = 'impression',
    AdStart = 'start',
    AdFinished = 'complete',
    ContentResuming = 'complete',
    Skipped = 'skip',
    FirstQuartile = 'firstQuartile',
    Midpoint = 'midpoint',
    ThirdQuartile = 'thirdQuartile',
    Error = 'error',
    Mute = 'mute',
    Unmute = 'unmute',
    Collapse = 'collapse',
    Expand = 'expand',
    Pause = 'pause',
    Resume = 'resume',
    Rewind = 'rewind',
    AcceptInvitation = 'acceptInvitation',
    Close = 'close',
    CreativeView = 'creativeView',
}

/**
 * Used to define multiview player types. ( Reference: https://gspcloud.atlassian.net/browse/VPTJS-9492 )
 * Be aware the keys and values of this enum are used to define the player display types.
 * For example when in multiview mode the enum key will be used to create a property of `multiplayer` and the value of `multiview`
 * will be assigned to it. If you have a need to change the key or value please ensure that it will not cause any unintended issues.
 * The key and value are paired together on purpose as they result in vamParams and those should not be changed without adequate reason and verification.
 */
export enum MultiPlayerDisplayType {
    MultiView = 'multiview',
}

export enum AdStreamType {
    InStream = 'InStream',
    Separate = 'Separate',
}

export enum AdStatus {
    Unwatched = 'Unwatched',
    Watched = 'Watched',
    Skipped = 'Skipped',
    Errored = 'Errored',
}

export enum AdBreakEventStatus {
    NEW_BREAK = 'NEW_BREAK',
    ACTIVE_BREAK = 'ACTIVE_BREAK',
    UPDATED_BREAK = 'UPDATED_BREAK',
}

export enum SupportedMediaFileTypes {
    VideoMp4 = 'video/mp4',
    ApplicationDash = 'application/dash+xml',
}

export enum MetadataType {
    PodBegin = 'PodBegin',
    PrerollPodBegin = 'PrerollPodBegin',
    PodEnd = 'PodEnd',
    PrerollPodEnd = 'PrerollPodEnd',
    AdBegin = 'AdBegin',
    AdEnd = 'AdEnd', // Not 100% sure this event exists, but it would make sense if it did.
    NonLinearPod = 'NonLinearPod',
}

export enum AdBreakSource {
    Paginated = 'Paginated',
    Unpaginated = 'Unpaginated',
    Manifest = 'Manifest',
    OutOfBand = 'OutOfBand', // From VAM or another source
}

export enum AdBreakUpdateMergeStrategy {
    Overwrite = 'overwrite',
    Merge = 'merge',
}
