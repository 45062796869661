/** VideoStartupStates keeps the states used internal video startup state control */
export enum VideoStartupStates {
    Uninitialised = 'Uninitialised',
    Initialised = 'Initialised',
    VideoProviderCalled = 'VideoProviderCalled',
    AdvertsInitialised = 'AdvertsInitialised',
    VamCalled = 'VamCalled',
    AdsBootstrapped = 'AdsBootstrapped',
    AdsInsertionCalled = 'AdsInsertionCalled',
    EngineStarted = 'EngineStarted',
    Discarded = 'Discarded',
    Destroyed = 'Destroyed',
    Retried = 'Retried',
}
