export enum ErrorReason {
    Fetch = 'Fetch',
    EmptyResult = 'EmptyResult',
    ParsingFreewheelVmapResponse = 'ParsingXml',
    Undetermined = 'Undetermined',
    FreewheelRequest = 'FreewheelRequest',
    EmptyFreewheelVmapResponse = 'EmptyFreewheelVmapResponse',
    PreparingAdverts = 'PreparingAdverts',
    DRM = 'DRM',
    HDCP = 'HDCP',
    Embargo = 'Embargo',
}

/**
 * @public
 */
export enum ErrorSeverity {
    Fatal = 'fatal',
    Warning = 'warning',
}

export enum AddonErrorCategory {
    CsaiAdRequest = 'CsaiAdBreaks',
}

export enum SessionErrorCategory {
    SessionStart = 'SessionStart',
}

export enum PlayerErrorCategory {
    VSF = 'VideoStartFailure',
    VPF = 'VideoPlaybackFailure',
}
