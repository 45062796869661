import { PlaybackType } from '../../core/player/playout-data';
import type { SessionItem } from '../../core/session-controller/session-controller';
import type { BaseCsaiAdBreaksProvider } from '../../players/bolt-ons/ad-breaks-provider/base-csai-ad-breaks-provider';
import { checkIsSessionItemSleAutoplayType } from '../../utils/playback-type';
import { checkUnsupportedValue } from '../../utils/ts';

import type { AdagioStaticConfig, FreewheelStaticConfig } from './vod-adverts/ad-server-providers';
import type { LocalVodAdvertsConfig } from './vod-adverts/base';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
type VmapProviderModule = typeof import('./vod-adverts/ad-server-providers');

export function createVacFreewheelProvider(vmapProviderModule: VmapProviderModule): BaseCsaiAdBreaksProvider {
    const { FreewheelAdBreaksProvider, VacFreewheelUrlBuilder } = vmapProviderModule;

    return new FreewheelAdBreaksProvider(new VacFreewheelUrlBuilder());
}

export function createProviderFromAdInsertionConfig(
    multiPlayerCsaiConfig: LocalVodAdvertsConfig,
    vmapProviderModule: VmapProviderModule,
    profileId: string
    // @ts-ignore
): BaseCsaiAdBreaksProvider {
    const { FreewheelAdBreaksProvider, AdagioAdBreaksProvider, StaticConfigFreewheelUrlBuilder } = vmapProviderModule;

    // TODO: change `adServer` type to an enum so that `as` inside each case can be removed.
    switch (multiPlayerCsaiConfig.profile.adServer) {
        case 'freewheel':
            return new FreewheelAdBreaksProvider(
                new StaticConfigFreewheelUrlBuilder({
                    ...(multiPlayerCsaiConfig.server as FreewheelStaticConfig),
                    userId: profileId,
                })
            );
        case 'adagio':
            return new AdagioAdBreaksProvider(multiPlayerCsaiConfig.server as AdagioStaticConfig);
        default:
            checkUnsupportedValue(multiPlayerCsaiConfig.profile.adServer as never);
    }
}

export function shouldLoadAdvertisingAddons(sessionItem: SessionItem): boolean {
    return !(sessionItem.reporting?.isTrendingNow || sessionItem.type === PlaybackType.Preview || checkIsSessionItemSleAutoplayType(sessionItem));
}
