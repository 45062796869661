import { CvsdkError, ErrorSeverity } from '../../../error';

import { TimelineItemType } from './timeline';

export class TimelineError extends CvsdkError {
    constructor(error: Omit<CvsdkError, 'severity' | 'name'>, currentTimelineItemType: TimelineItemType) {
        super(error.code, error.message, ErrorSeverity.Warning, error.cause, error.category, error.reason, error.parameters, error.originalSeverity);
        Object.setPrototypeOf(this, TimelineError.prototype); // restore prototype chain
        this.name = 'TimelineError';

        if (currentTimelineItemType === TimelineItemType.MainContent) {
            this.message = `${error.message}, during Main Content - Ending Timeline`;
            this.code = `${error.code}.MAIN_CONTENT`;
            this.severity = ErrorSeverity.Fatal;
        } else if (currentTimelineItemType === TimelineItemType.Advert) {
            this.message = `${error.message}, during Advert - Starting next Item in Timeline`;
            this.code = `${error.code}.ADVERT`;
            this.severity = ErrorSeverity.Warning;
        }
    }
}
