import type { EventBoundaryConfig } from '../../../addons/event-boundary/event-boundary-addon';
import type { AddonConfig } from '../../internal-config';
import type { RemoteConfigSplit } from '../../sdk-config';
import type { DeclaredOptionalsAddonsConfig, InternalAddonsConfig, RequiredPropertiesDefinition } from './addons-config-adapter-common';
import { genericConfigEnabledCheck } from './addons-config-adapter-common';

// Internal Config
type EventBoundaryInternalConfig = InternalAddonsConfig['eventBoundary'];

// Client Config when Remote Config is disabled
export type EventBoundaryClientConfig = AddonConfig<EventBoundaryConfig>;
export function adaptEventBoundaryClientConfig(config: EventBoundaryClientConfig | undefined): EventBoundaryInternalConfig {
    return config;
}

// Server Config when Remote Config is enabled
type EventBoundaryRemoteServerConfig = AddonConfig<{}>;

// Client Config when Remote Config is enabled
type EventBoundaryRemoteClientConfig = EventBoundaryConfig;

export type EventBoundaryRemoteConfigSplit = RemoteConfigSplit<EventBoundaryRemoteServerConfig, EventBoundaryRemoteClientConfig>;
export function adaptEventBoundaryRemoteConfigSplit(
    config: EventBoundaryRemoteConfigSplit
): DeclaredOptionalsAddonsConfig<EventBoundaryInternalConfig> {
    const requiredFields: RequiredPropertiesDefinition<EventBoundaryRemoteClientConfig> = { validStreamTypes: null };
    const isEnabled = genericConfigEnabledCheck(config, requiredFields, 'Event Boundary');
    if (!isEnabled) {
        return;
    }

    const { clientConfig } = config;
    return {
        enabled: isEnabled,
        config: {
            validStreamTypes: clientConfig!.validStreamTypes,
            maxBoundaryDataInterval: clientConfig!.maxBoundaryDataInterval,
        },
    };
}
