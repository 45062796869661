export enum AdServer {
    freewheel = 'freewheel',
    adagio = 'adagio',
}

/**
 * @public
 */
export enum VodAdvertsAddonType {
    VMAP = 'VMAP',
}

export enum AdsConfigProvider {
    Local = 'Local',
    Vam = 'Vam',
}
