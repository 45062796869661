type TokenizedStringParams = {
    targetStr: string;
    startDelimiter: string;
    endDelimiter: string;
    variable: string;
    value: string;
};

export class TokenizedString {
    private static escapeRegExp(string: string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    public static findTokensBetweenDelimiters({ startDelimiter, endDelimiter, targetStr }: Partial<TokenizedStringParams>) {
        if (!startDelimiter || !endDelimiter || !targetStr) {
            return;
        }

        const regex = new RegExp(`${this.escapeRegExp(startDelimiter)}(.*?)${this.escapeRegExp(endDelimiter)}`, 'g');
        const matches = [];
        let match;

        while ((match = regex.exec(targetStr)) !== null) {
            matches.push(match[1]);
            // Reset lastIndex if delimiters are empty or same
            if (startDelimiter === endDelimiter || startDelimiter === '' || endDelimiter === '') {
                regex.lastIndex++;
            }
        }

        return matches;
    }

    public static replaceAll({ targetStr, startDelimiter, endDelimiter, variable, value }: TokenizedStringParams) {
        let intermediate = targetStr.replace(`${startDelimiter}${variable}${endDelimiter}`, value);
        let next = '';

        while (intermediate !== next) {
            // Linked List approach. Replacing variable in string until there is nothing left to replace
            if (next) {
                intermediate = next;
            }

            next = intermediate.replace(`${startDelimiter}${variable}${endDelimiter}`, value);
        }

        return next;
    }
}
