import type { Cdn } from '../../player/playout-data';

export class CdnSelector {
    private cdns?: Array<Cdn>;
    private currentCdnIdx?: number;

    public setCdns(cdns: Array<Cdn>): Cdn {
        this.currentCdnIdx = 0;
        this.cdns = cdns;
        return this.cdns[this.currentCdnIdx];
    }

    public selectNextCdn(): {
        fromCdn: Cdn;
        toCdn: Cdn;
    } {
        const fromCdn = this.cdns![this.currentCdnIdx!];
        this.currentCdnIdx!++;
        const toCdn = this.cdns![this.currentCdnIdx!] || null;
        return { fromCdn, toCdn };
    }
}
