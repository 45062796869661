/**
 * @public
 */
export enum VacType {
    VAM = 'VAM',
    CLIENTPROVIDED = 'CLIENTPROVIDED',
}

export interface BaseVacConfig {
    type: VacType;
    url: string;
    requestData: {
        [key: string]: unknown;
    };
}
