import type { DeviceInformation } from '@sky-uk-ott/client-lib-js-device';

export function matchPartialDeviceInformation(compareDevice: Partial<DeviceInformation>, currentDeviceInformation: DeviceInformation): boolean {
    if (!compareDevice) {
        return false;
    }

    const compareKeys: Array<keyof typeof currentDeviceInformation> = [
        'browserName',
        'browserVersion',
        'deviceBrand',
        'deviceManufacturer',
        'deviceModel',
        'deviceFullModel',
        'deviceType',
        'deviceVersion',
        'deviceFirmware',
        'frameworkName',
        'frameworkVersion',
        'operatingSystemName',
        'operatingSystemVersion',
    ];

    for (const key of compareKeys) {
        if (compareDevice[key] && compareDevice[key] !== currentDeviceInformation[key]) {
            return false;
        }
    }

    return true;
}
