/**
 * Passed as a value to the 'adType' option
 */
export enum AdType {
    Ad = 'Ad',
    VodAd = 'VodAd',
    ServerSideAd = 'ServerSideAd',
    ClientSideAd = 'ClientSideAd',
}

export enum AdvertisingContentType {
    Advert = 'Advert',
    Other = 'Other',
}

export enum AdContentType {
    Promotional = 'Promotional',
    Advertisement = 'Advertisement',
}
