import { LogLevel, sdkLogger } from '../../logger';
import type { InternalRegisteredEventCue, EventTrack, EventTrackCallback, TimeRangeEvent } from './event-types';
import { byEndTime, byStartTime, validateCueHasPositionsNotDates } from './event-utils';
import { isChromeOrChromium, isSafariDesktop } from '../../utils/device-type';
import type { SessionControllerInternal } from '../session-controller/session-controller-internal';
import { CoreVideoInternal } from '../../core-video-internal';
import type { EventTrackType } from './event-track.enums';
import { orderedInsert, removeElement } from '../../utils/array-utils';
import { InternalSessionState } from '../session-controller/session-controller.enums';
export { EventTrackType } from './event-track.enums';

const CVSDK_EVENT_TRACK_LABEL = 'cvsdk-event-track'; // corresponds with label referenced in the shaka-player-code
const isCvsdkEventTrackPredicate = (t: TextTrack) => t.label === CVSDK_EVENT_TRACK_LABEL;
/**
 * Allows us to schedule events which will be fired at a certain time during playback
 * We would like to favour the VideoElementEventTrack if the browser supports it, this option is
 * a fallback should we not have that option available
 */
export class CommonEventTrack implements EventTrack {
    private logger = sdkLogger.withContext('CommonEventTrack');
    private orderedEnterTimes: Array<TimeRangeEvent> = [];
    private orderedExitTimes: Array<TimeRangeEvent> = [];
    /** The index of the last checked non-future TimedRangeEvent based on entrance time */
    private lastPastEnterIndex = 0;
    /** The index of the last checked non-future TimedRangeEvent based on exit time */
    private lastPastExitIndex = 0;
    private lastPosition: number | null = null;
    private seekStarted = false;
    constructor(
        onPlaybackTimelineUpdated: SessionControllerInternal['onPlaybackTimelineUpdated'],
        onSeekStarted: SessionControllerInternal['onSeekStarted']
    ) {
        onSeekStarted(() => {
            this.seekStarted = true;
        });
        /**
         * The high level idea is we only need to check for events that happened since the last position update.
         * Every time a position update comes in, save where we left off and only check starting there until an event is encountered
         * that's in the future. Since these are sorted by time, we can then stop checking.
         */
        onPlaybackTimelineUpdated(({ currentTime }) => {
            if (this.seekStarted) {
                this.seekStarted = false;

                /**
                 * If a backwards seek happens, older entries need to be re-checked
                 */
                if (this.lastPosition !== null && currentTime < this.lastPosition) {
                    this.handleBackwardsSeek(currentTime);
                }

                /**
                 * If a seek happens, reset the last position
                 * This is done so we don't accidentally fire completed events that occurred
                 * in the area that the user just seeked over
                 */
                this.lastPosition = currentTime;
            } else if (this.lastPosition === null) {
                this.lastPosition = currentTime;
                if (this.lastPosition > 0) {
                    // wait until the next update to fire any events since we are likely starting from a bookmark
                    // this avoids accidentally triggering events between 0 and the bookmark
                    return;
                }
            }

            /**
             * Check all ranges (sorted by entry time) until one is encountered that is in the future
             * Remember where the check left off -- no need to re-check old entries
             */
            for (let i = this.lastPastEnterIndex; i < this.orderedEnterTimes.length; i++) {
                const timeRangeEvent = this.orderedEnterTimes[i];
                const [startTime, endTime] = timeRangeEvent.range;

                this.lastPastEnterIndex = i;

                if (startTime > currentTime) {
                    break;
                }

                // Did the entry both start and end between the last time we checked and now?
                // Note: If lastPosition and startTime are both 0, we need to consider the entry point not having occurred yet
                if ((startTime > this.lastPosition || (startTime === 0 && this.lastPosition === 0)) && endTime <= currentTime) {
                    // Fire off both the enter and exit
                    timeRangeEvent.onenter();
                    timeRangeEvent.onexit();
                } else if (!timeRangeEvent.isEntered && startTime < this.lastPosition && endTime >= this.lastPosition && endTime < currentTime) {
                    // Similar to above scenario except occurs only when cues are registered a tick before they would have ended
                    // This is to align with VTTCue which has higher position update precision and would have interpolated between positions
                    this.logger.info(
                        `Detected completed cue from the previous tick that has not been triggered, forcing re-entry and exit for cue: ${timeRangeEvent.id}`
                    );
                    timeRangeEvent.onenter();
                    timeRangeEvent.onexit();
                } else if (!timeRangeEvent.isEntered && endTime >= currentTime) {
                    timeRangeEvent.isEntered = true;
                    timeRangeEvent.onenter();
                }
            }

            /**
             * Check all ranges (sorted by exit time) until one is encountered that is in the future
             * Remember where the check left off -- no need to re-check old entries
             */
            for (let i = this.lastPastExitIndex; i < this.orderedExitTimes.length; i++) {
                const timeRangeEvent = this.orderedExitTimes[i];
                const endTime = timeRangeEvent.range[1];

                this.lastPastExitIndex = i;

                if (endTime > currentTime) {
                    break;
                }

                if (timeRangeEvent.isEntered && endTime <= currentTime) {
                    timeRangeEvent.isEntered = false;
                    timeRangeEvent.onexit();
                }
            }

            this.lastPosition = currentTime;
        });
    }

    /**
     *@param id The id of the cue
     * @param cue* @param cue cue must have had its startTime and endTime converted to a number representing the stream position
     * @param onCueEvent a function which when invoked will get all the cue events in priority order, with a startTime or endTime matching the current time. When invoked will also invoke the clients onEntered callbacks
     */
    public registerCue(id: string, cue: InternalRegisteredEventCue, onCueEvent: EventTrackCallback): void {
        validateCueHasPositionsNotDates(cue);
        const event: TimeRangeEvent = {
            id,
            range: [cue.startTime, cue.endTime],
            isEntered: false,
            onenter: () => onCueEvent(cue.startTime as number),
            onexit: () => onCueEvent(cue.endTime as number),
        };

        const startInsertionPoint = orderedInsert(this.orderedEnterTimes, event, byStartTime);
        const endInsertionPoint = orderedInsert(this.orderedExitTimes, event, byEndTime);

        /**
         * If the new entry is in the past, kick the index back so the algorithm checks it next position update
         */
        if (startInsertionPoint < this.lastPastEnterIndex) {
            this.lastPastEnterIndex = startInsertionPoint;
        }

        if (endInsertionPoint < this.lastPastExitIndex) {
            this.lastPastExitIndex = endInsertionPoint;
        }
    }

    /**
     *
     * @param id : cueId
     * @param newEndTime : endTime for unboundedEventCue
     * @param onCueEvent: callback listener at start/end of time
     */
    public updateCueEndTime(id: string, newEndTime: number, onCueEvent: EventTrackCallback): void {
        this.orderedExitTimes.forEach((matchingEvent) => {
            if (matchingEvent.id === id) {
                matchingEvent.range = [matchingEvent.range[0], newEndTime];
                matchingEvent.onexit = () => onCueEvent(newEndTime);
            }
        });
    }

    public unregisterCue(id: string): void {
        removeElement(this.orderedEnterTimes, (t) => t.id === id);
        removeElement(this.orderedExitTimes, (t) => t.id === id);
        this.resetCheckWindow();
    }

    private handleBackwardsSeek(seekPosition: number) {
        // Check all active events, fire onExit for any that have a start position greater than the seek position
        // meaning we have now exited the event by seeking backwards out of it
        for (let i = 0; i <= this.lastPastEnterIndex && i < this.orderedEnterTimes.length; i++) {
            const timeRangeEvent = this.orderedEnterTimes[i];
            if (!timeRangeEvent.isEntered) {
                continue;
            }
            const [startTime, _] = timeRangeEvent.range;
            if (startTime > seekPosition) {
                timeRangeEvent.isEntered = false;
                timeRangeEvent.onexit();
            }
        }
        this.resetCheckWindow();
    }

    private resetCheckWindow() {
        this.lastPastEnterIndex = 0;
        this.lastPastExitIndex = 0;
    }
}

/**
 * Allows us to schedule events using the textTrack cues functionality as a means to
 * fire events. This is more accurate than the CommonEventTrack, preference should be
 * given to this implementation if the device and player supports it.
 */
export class VideoElementEventTrack implements EventTrack {
    private logger = sdkLogger.withContext('VideoElementEventTrack');
    private textTrack: TextTrack | null = null;
    private tempVttCues: Array<VTTCue> = [];
    private initialised = false;
    private videoElement?: HTMLVideoElement;
    /** Helper structure for ensuring it is impossible to fire cue exit events when there was no associated cue enter event that has been fired. */
    private activeCues: Set<string> = new Set();
    private deferCueRegistration: boolean = true;

    constructor(
        onVideoElementCreated: SessionControllerInternal['onVideoElementCreated'],
        onStateChanged: SessionControllerInternal['onStateChanged']
    ) {
        onVideoElementCreated((videoElement: HTMLVideoElement) => {
            const existingTrack = Array.from(videoElement.textTracks ?? []).find(isCvsdkEventTrackPredicate);
            this.videoElement = videoElement;

            if (existingTrack) {
                // There maybe a remnant from a previous playout (if so recycle as we can't delete it)
                this.textTrack = existingTrack;
            } else {
                this.textTrack = videoElement.addTextTrack('metadata', CVSDK_EVENT_TRACK_LABEL);
            }

            // https://developer.mozilla.org/en-US/docs/Web/API/TextTrack/mode#hidden
            // Events will still be fired, hidden as there is no text to show
            this.textTrack.mode = 'hidden';

            this.initialised = true;
        });

        onStateChanged((state: InternalSessionState) => {
            if ([InternalSessionState.Playing, InternalSessionState.Paused].includes(state)) {
                this.deferCueRegistration = false;
                this.flushTempVttCues();
            } else {
                this.deferCueRegistration = true;
            }
        });
    }

    private flushTempVttCues(): void {
        if (this.tempVttCues.length > 0) {
            this.tempVttCues.forEach((t) => {
                this.textTrack?.addCue(t);
            });
            this.tempVttCues.length = 0;
        }
    }

    public registerCue(id: string, cue: InternalRegisteredEventCue, onCueEvent: EventTrackCallback): void {
        validateCueHasPositionsNotDates(cue);

        const dataCue = new VTTCue(cue.startTime, cue.endTime, '');
        dataCue.id = id;

        dataCue.onenter = (_ev: Event) => {
            /**
             * If a cue is registered behind the position e.g. register a cue {startTime: 1, endTime: 3} when the player is at position 8,
             * the onenter and onexit function will fire off immediately on the first instance. To prevent this from happening, we can validate
             * that the time the cue event wants to fire off is within a tolerance of the videoElement.currentTime
             * This is a chrome/chromium issue, an issue has been raised here https://issues.chromium.org/issues/324909835
             * When this is fixed on chrome/chromium, we can remove the if block
             * NOTE: this happens on Safari as well
             */

            if (isChromeOrChromium(CoreVideoInternal.deviceInfo) || isSafariDesktop(CoreVideoInternal.deviceInfo, CoreVideoInternal.deviceType)) {
                this.logger.info(`Video Element time: ${this.videoElement?.currentTime}`);
                this.onCueEnterWithTimeValidation(cue, this.videoElement?.currentTime ?? 0, onCueEvent);
            } else {
                this.activeCues.add(cue.id);
                onCueEvent(cue.startTime);
            }
        };
        dataCue.onexit = (_ev: Event) => {
            if (isChromeOrChromium(CoreVideoInternal.deviceInfo) || isSafariDesktop(CoreVideoInternal.deviceInfo, CoreVideoInternal.deviceType)) {
                this.onCueExitWithTimeValidation(cue, this.videoElement?.currentTime ?? 0, onCueEvent);
            } else {
                this.activeCues.delete(cue.id);
                onCueEvent(cue.endTime);
            }
        };

        if (!this.textTrack || this.deferCueRegistration) {
            if (this.deferCueRegistration) {
                this.logger.verbose('cues cannot be added while state is not playing or paused, registration will be deferred');
            } else if (this.initialised) {
                this.logger.warn('videoElementCreated should have created the text track, but instead text track is undefined');
            } else {
                this.logger.verbose(
                    `Text track is not initialised, putting the cue:${cue} in the queue to be processed when videoElement is available`,
                    this.textTrack
                );
            }
            this.tempVttCues.push(dataCue);
        } else {
            this.textTrack?.addCue(dataCue);
        }
    }

    public unregisterCue(id: string): void {
        const cue = this.textTrack?.cues?.getCueById(id);
        if (cue) {
            this.activeCues.delete(id);
            this.textTrack?.removeCue(cue);
        }
    }

    public updateCueEndTime(id: string, newEndTime: number): void {
        const cue = this.textTrack?.cues?.getCueById(id);
        if (cue) {
            cue.endTime = newEndTime;
        }
    }

    public destroy = () => {
        if (!this.textTrack) {
            this.logger.warn('text track was undefined and so could not remove cues');
            return;
        }

        this.activeCues.clear();
        while (this.textTrack.cues?.length) {
            const cue = this.textTrack.cues?.[0];
            if (cue) {
                this.textTrack.removeCue(cue);
            }
        }
    };

    private onCueEnterWithTimeValidation(cue: InternalRegisteredEventCue, currentTime: number, onCueEvent: EventTrackCallback) {
        // Note: Need to check start time inclusive, otherwise we will miss cues that align perfectly with the player time (i.e. position 0)
        if (currentTime >= cue.startTime && currentTime < cue.endTime) {
            this.activeCues.add(cue.id);
            onCueEvent(cue.startTime);
        } else {
            if (this.logger.getLogLevel() && (this.logger.getLogLevel() as LogLevel) >= LogLevel.Warn) {
                this.logger.warn(
                    `cue onenter event not fired, the supposed fired time of ${cue.startTime} was outside of the actual video position of ${currentTime}`
                );
            }
        }
    }

    private onCueExitWithTimeValidation(cue: InternalRegisteredEventCue, currentTime: number, onCueEvent: EventTrackCallback) {
        // Please see above note in dataCue.onenter, about cues firing off when registered behind the current position
        if (!this.activeCues.has(cue.id)) {
            if (this.logger.getLogLevel() && (this.logger.getLogLevel() as LogLevel) >= LogLevel.Warn) {
                this.logger.warn(
                    `cue onexit event not fired, there was no associated cue enter event that has been fired for cue: ${cue.id} at time ${currentTime}`
                );
            }
        } else {
            this.activeCues.delete(cue.id);
            onCueEvent(cue.endTime);
        }
    }
}

export type EventTrackUnionType = keyof typeof EventTrackType;
