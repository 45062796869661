import type { OpenMeasurementConfig } from '../../../addons/reporting/open-measurement-addon/open-measurement-addon';
import type { AddonConfig } from '../../internal-config';
import type { RemoteConfigSplit } from '../../sdk-config';
import type { DeclaredOptionalsAddonsConfig, InternalAddonsConfig, RequiredPropertiesDefinition } from './addons-config-adapter-common';
import { genericConfigEnabledCheck } from './addons-config-adapter-common';

// Internal Config
type InternalReportingConfig = NonNullable<InternalAddonsConfig['reporting']>;
type OpenMeasurementInternalConfig = InternalReportingConfig['openMeasurement'];

// Client Config when Remote Config is disabled
export type OpenMeasurementClientConfig = AddonConfig<OpenMeasurementConfig>;
export function adaptOpenMeasurementClientConfig(config: OpenMeasurementClientConfig | undefined): OpenMeasurementInternalConfig {
    return config;
}

// Server Config when Remote Config is enabled
type OpenMeasurementRemoteServerConfig = AddonConfig<{}>;

// Client Config when Remote Config is enabled
type OpenMeasurementRemoteClientConfig = OpenMeasurementConfig;

export type OpenMeasurementRemoteConfigSplit = RemoteConfigSplit<OpenMeasurementRemoteServerConfig, OpenMeasurementRemoteClientConfig>;
export function adaptOpenMeasurementRemoteConfigSplit(
    config: OpenMeasurementRemoteConfigSplit
): DeclaredOptionalsAddonsConfig<OpenMeasurementInternalConfig> {
    const requiredFields: RequiredPropertiesDefinition<OpenMeasurementRemoteClientConfig> = { partnerName: null };
    const isEnabled = genericConfigEnabledCheck(config, requiredFields, 'OpenMeasurement');
    if (!isEnabled) {
        return;
    }

    const { clientConfig } = config;
    return {
        enabled: isEnabled,
        config: {
            partnerName: clientConfig!.partnerName,
            isDebug: clientConfig!.isDebug,
        },
    };
}
