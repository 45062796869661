import { filter, parse } from '@sky-uk-ott/core-video-sdk-js-external-txml/dist/txml';
import type { tNode } from './txml-types';

export function parseTxmlContent(content: string): (string | tNode)[] {
    return parse(content) as (string | tNode)[];
}

export function filterTxmlContent(nodes: (string | tNode)[], fn: (node: tNode) => boolean): tNode[] {
    return filter(nodes, fn);
}

export function findNodeWithTagName(parent: tNode, tagName: string): tNode | undefined {
    return (parent.children as tNode[]).find((node: tNode) => node.tagName === tagName);
}

export function getAttributeFromNode(node: tNode, attribute: string): string {
    return node.attributes[attribute];
}
