import type { AdInsertionConfig } from '../../../internal-config';
import type { RemoteConfigSplit } from '../../../sdk-config';
import type { DeclaredOptionals } from '../../../../utils/ts';
import type { RequiredPropertiesDefinition } from '../addons-config-adapter-common';
import { checkRequiredConfigProperties } from '../addons-config-adapter-common';
import type { YospaceClientConfig as YospaceAddonConfig } from '../../../../addons/adverts/server-adverts/yospace/yospace-config';

// Internal Config
type YospaceInternalConfig = AdInsertionConfig['yospace'];

// Client Config when Remote Config is disabled
export type YospaceClientConfig = YospaceAddonConfig;
export function adaptYospaceClientConfig(config: YospaceClientConfig | undefined): YospaceInternalConfig {
    return config;
}

// Server Config when Remote Config is enabled
type YospaceRemoteServerConfig = {};

// Client Config when Remote Config is enabled
type YospaceRemoteClientConfig = YospaceAddonConfig;

export type YospaceRemoteConfigSplit = RemoteConfigSplit<YospaceRemoteServerConfig, YospaceRemoteClientConfig>;
export function adaptYospaceRemoteConfigSplit(config: YospaceRemoteConfigSplit): DeclaredOptionals<YospaceInternalConfig> {
    const requiredFields: RequiredPropertiesDefinition<YospaceRemoteClientConfig> = {
        hosts: null,
    };

    const missingProperties = checkRequiredConfigProperties(config.clientConfig || {}, requiredFields);
    if (missingProperties.length > 0) {
        return;
    }

    const { clientConfig } = config;
    return {
        hosts: clientConfig!.hosts,
        debug: clientConfig!.debug,
        vodContextId: clientConfig!.vodContextId,
        siteSection: clientConfig!.siteSection,
        streamFormat: clientConfig!.streamFormat,
        network: clientConfig!.network,
        deviceType: clientConfig!.deviceType,
        tags: clientConfig!.tags,
        apiKey: clientConfig!.apiKey,
        seekPosition: clientConfig!.seekPosition,
        failoverIsActive: clientConfig!.failoverIsActive,
        requestDelayRange: clientConfig!.requestDelayRange,
        fwVcid2: clientConfig!.fwVcid2,
        yospaceFeatures: clientConfig!.yospaceFeatures,
    };
}
