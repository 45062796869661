import type { DeclaredOptionals } from '../../../utils/ts';
import type { AampPlayerConfig, InternalConfig } from '../../internal-config';
import type { RemoteConfigSplit } from '../../sdk-config';

// Internal Config
type InternalPlayersConfig = NonNullable<InternalConfig['players']>;
type AampInternalConfig = InternalPlayersConfig['aamp'];

// Client Config when Remote Config is disabled
export type AampClientConfig = AampPlayerConfig;
export function adaptAampClientConfig(config: AampClientConfig | undefined): AampInternalConfig {
    return config;
}

type AampRemoteConfigProperties =
    | 'manifestTimeoutInSeconds'
    | 'preCachePlaylistTimeInMinutes'
    | 'shouldUseSurround'
    | 'shouldUseAverageBandwidth'
    | 'resumeInAdBreakOffsetInSeconds'
    | 'downloadStallTimeoutInSeconds'
    | 'downloadStartTimeoutInSeconds'
    | 'minimumSafeBitrate'
    | 'shouldUseNewAbr'
    | 'enableInternalRetryFunctionality'
    | 'useRetuneForUnpairedDiscontinuity'
    | 'playlistTimeout'
    | 'vodNetworkTimeout'
    | 'linearNetworkTimeout'
    | 'missingProgressUpdateLimit'
    | 'initFragmentRetryCount'
    | 'enableSeekableRange'
    | 'enableLiveLatencyCorrection'
    | 'enableAsyncTune';
// Server Config when Remote Config is enabled
type AampRemoteServerConfig = Pick<AampClientConfig, AampRemoteConfigProperties>;

// Client Config when Remote Config is enabled
type AampRemoteClientConfig = Omit<AampClientConfig, AampRemoteConfigProperties>;
export type AampRemoteConfigSplit = RemoteConfigSplit<AampRemoteServerConfig, AampRemoteClientConfig>;
export function adaptAampRemoteConfigSplit(config: AampRemoteConfigSplit): DeclaredOptionals<AampInternalConfig> {
    const { clientConfig = {}, remoteConfig = {} } = config;
    return {
        manifestTimeoutInSeconds: remoteConfig.manifestTimeoutInSeconds,
        preCachePlaylistTimeInMinutes: remoteConfig.preCachePlaylistTimeInMinutes,
        shouldUseSurround: remoteConfig.shouldUseSurround,
        shouldUseAverageBandwidth: remoteConfig.shouldUseAverageBandwidth,
        resumeInAdBreakOffsetInSeconds: remoteConfig.resumeInAdBreakOffsetInSeconds,
        downloadStallTimeoutInSeconds: remoteConfig.downloadStallTimeoutInSeconds,
        downloadStartTimeoutInSeconds: remoteConfig.downloadStartTimeoutInSeconds,
        minimumSafeBitrate: remoteConfig.minimumSafeBitrate,
        shouldUseNewAbr: remoteConfig.shouldUseNewAbr,
        enableInternalRetryFunctionality: remoteConfig.enableInternalRetryFunctionality,
        useRetuneForUnpairedDiscontinuity: remoteConfig.useRetuneForUnpairedDiscontinuity,
        playlistTimeout: remoteConfig.playlistTimeout,
        vodNetworkTimeout: remoteConfig.vodNetworkTimeout,
        linearNetworkTimeout: remoteConfig.linearNetworkTimeout,
        missingProgressUpdateLimit: remoteConfig.missingProgressUpdateLimit,
        initFragmentRetryCount: remoteConfig.initFragmentRetryCount,
        enableSeekableRange: remoteConfig.enableSeekableRange,
        enableLiveLatencyCorrection: remoteConfig.enableLiveLatencyCorrection,
        enableAsyncTune: remoteConfig.enableAsyncTune,
        progressReportingInterval: clientConfig.progressReportingInterval,
        enablePTSReStamp: clientConfig.enablePTSReStamp,
    };
}
