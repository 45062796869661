import { DeviceType, type Device, type DeviceInformation } from '@sky-uk-ott/client-lib-js-device';
import deepmerge from 'deepmerge';

import type {
    DeviceMatcherSubConfig,
    CoreVideoConfigBase,
    CoreVideoConfigCompiled,
    CoreVideoConfigWithPlaybackTypes,
    CoreVideoConfigWithDeviceMatchers,
    OptionalParamsBecomeCustomUndefined,
    CoreVideoConfigMerged,
    CoreVideoConfigMetadata,
    PreferredStreamPropertyGroup,
} from './core-video-config-types';
import { isRemoteConfigEnabled } from './core-video-config-types';
import { PlaybackType } from '../../core/player/playout-data';
import type { SdkConfig, SdkConfigWithRemoteConfig } from '../sdk-config';
import { version } from '../../version';
import type { ConfigPerPlaybackType, InternalConfigDynamic } from '../internal-config';
import { VideoColourSpace, VideoFormat } from '../../core/player/video-format';
import type { CompatibleStreamPropertyGroup } from '../../core/player/stream-capability-specification';
import { sdkLogger } from '../../logger';
import { CvsdkError, ErrorSeverity } from '../../error';
import { adaptSdkAddonsClientConfig } from '../adapters/addons-config-adapter/addons-config-adapter';
import { adaptSdkPlayersClientConfig } from '../adapters/players-config-adapter/players-config-adapter';
import type { DeclaredOptionals } from '../../utils/ts';
import { checkForMatch } from './core-video-config-device-matcher-tester';
import { safeRequestIdleCallback } from '../../utils/safe-request-idle-callback';
import { promiseWithTimeout } from '../../utils/promise-utils';
import { RemoteConfigErrorCodes } from './core-video-config.enums';
export { RemoteConfigErrorCodes } from './core-video-config.enums';

export const CORE_VIDEO_CONFIG_VALUE_UNDEFINED = 'core-video-config-custom-undefined' as const;

const REMOTE_CONFIG_REQUEST_TIMEOUT = 5000;

type UnknownObject = { [key: string | number]: unknown };
export function fixUndefinedValues<T extends UnknownObject = UnknownObject>(config: OptionalParamsBecomeCustomUndefined<T>): T {
    Object.keys(config).forEach((configKey) => {
        const configValue = config[configKey];
        if (configValue && typeof configValue === 'object') {
            fixUndefinedValues(configValue as UnknownObject);
            return;
        }

        if (configValue === CORE_VIDEO_CONFIG_VALUE_UNDEFINED) {
            const typedKey = configKey as keyof typeof config;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            config[typedKey] = undefined as any;
        }
    });

    return config as T;
}

export function mergeConfigs<C1 extends object, C2 extends object>(c1: C1, c2: C2, onWarning: (warning: string) => void) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const unionMergeWithWarning = (parentConfigArray: Array<any>, newArray: Array<any>): Array<any> => {
        const isParentComparable = parentConfigArray.every((val) => typeof val !== 'object');
        const isNewArrayComparable = newArray.every((val) => typeof val !== 'object');
        if (isParentComparable && isNewArrayComparable) {
            // If there are elements in the merged array which do not exist in the new array
            // It means the new array is more restrictive. Since the array merge is a union,
            // more restrictive merges are not supported and could indicate an incorrectly
            // defined config
            const isNewArrayMoreRestrictive = parentConfigArray.some((val) => !newArray.includes(val));
            if (isNewArrayMoreRestrictive) {
                const message = `A sub-config tried to define a more restrictive array than its parent config which is not valid for union merging of arrays.
This likely indicates an incorrectly defined config.\n
Parent Config Array: [${parentConfigArray.join(', ')}]\n
Sub-Config Config Array: [${newArray.join(', ')}]\n`;
                onWarning(message);
            }
        }

        const dedupedNewArray = newArray.filter((val) => !parentConfigArray.includes(val));
        return [...parentConfigArray, ...dedupedNewArray];
    };

    const mergedConfig = deepmerge(c1, c2, { arrayMerge: unionMergeWithWarning }) as C1;

    if ('deviceMatchers' in mergedConfig) {
        const c1Matchers = 'deviceMatchers' in c1 && Array.isArray(c1.deviceMatchers) ? c1.deviceMatchers : [];
        const c2Matchers = 'deviceMatchers' in c2 && Array.isArray(c2.deviceMatchers) ? c2.deviceMatchers : [];
        const mergedMatchers = [...c1Matchers, ...c2Matchers];
        mergedConfig['deviceMatchers'] = mergedMatchers;
    }

    return mergedConfig;
}

export type CoreVideoConfigPerPlaybackType = ConfigPerPlaybackType<CoreVideoConfigMerged>;

export class CoreVideoConfigProvider {
    private configPath: string;
    private etag?: string;
    private coreVideoConfigPerPlaybackType?: CoreVideoConfigPerPlaybackType;
    private localCoreVideoConfigPerPlaybackType?: CoreVideoConfigPerPlaybackType;
    private requestConfigPromise?: Promise<CoreVideoConfigCompiled | undefined>;
    private failoverReason?: string;
    private logger = sdkLogger.withContext('Remote Config Provider');

    constructor(
        private sdkConfig: SdkConfig,
        private device: Device,
        private compiledConfigLoader: (configPath: string) => Promise<CoreVideoConfigCompiled>
    ) {
        this.configPath = this.buildPath();
    }

    public async initialise() {
        this.failoverReason = RemoteConfigErrorCodes.Default;
        this.device.lifecycle?.onResuming?.(async () => {
            safeRequestIdleCallback(() => {
                if (isRemoteConfigEnabled(this.sdkConfig)) {
                    return this.refreshRemoteConfig(this.sdkConfig);
                }
            });
        });

        try {
            safeRequestIdleCallback(() => {
                if (isRemoteConfigEnabled(this.sdkConfig)) {
                    return this.refreshRemoteConfig(this.sdkConfig);
                }
            });

            const storedConfig = await this.compiledConfigLoader(this.configPath);

            // Remote Config will have additional identifiable information which we don't
            // have when using the bundled default config.
            storedConfig.configIdentifier = '@sky-uk-ott/core-video-sdk-core-video-config';
            storedConfig.configTimestamp = `${Date.now()}`;

            const [playbackTypesConfig, appliedDeviceMatchers] = this.buildPlaybackTypesConfig(storedConfig);
            const coreVideoConfigPerPlaybackType = this.mergePlaybackTypesConfig(storedConfig, playbackTypesConfig, appliedDeviceMatchers);

            if (!this.coreVideoConfigPerPlaybackType) {
                this.coreVideoConfigPerPlaybackType = coreVideoConfigPerPlaybackType;
                this.localCoreVideoConfigPerPlaybackType = deepmerge({}, this.coreVideoConfigPerPlaybackType);
                this.logger.verbose('Using default local config to set initial config values.');
            } else if (!this.localCoreVideoConfigPerPlaybackType) {
                this.localCoreVideoConfigPerPlaybackType = coreVideoConfigPerPlaybackType;
                this.logger.verbose('Remote config json already fetched, setting default local config only.');
            }
        } catch (defaultConfigLoadError) {
            this.throwLocalConfigInitializationError(defaultConfigLoadError);
        }
    }

    private throwLocalConfigInitializationError(defaultConfigLoadError: unknown) {
        this.logger.error('Failed to load default config with error: ', defaultConfigLoadError);
        const error = CvsdkError.from({
            code: 'REMOTE_CONFIG_INITIALISATION_FAILURE',
            message: 'Failed to load default config',
            severity: ErrorSeverity.Fatal,
            cause: {
                defaultConfigLoadError,
            },
        });

        throw error;
    }

    public getConfig(): CoreVideoConfigPerPlaybackType {
        if (!this.coreVideoConfigPerPlaybackType) {
            throw new Error('Tried to retrieve config before remote config provider was initialised');
        }

        return this.coreVideoConfigPerPlaybackType;
    }

    public getFailoverReason(): string | undefined {
        return this.failoverReason;
    }

    public buildInternalConfig(playbackType: 'default', device: Device): InternalConfigDynamic;
    public buildInternalConfig(playbackType: PlaybackType, device: Device): InternalConfigDynamic | undefined;
    public buildInternalConfig(playbackType: PlaybackType | 'default', device: Device): InternalConfigDynamic | undefined {
        if (!this.coreVideoConfigPerPlaybackType) {
            throw new Error('Tried to retrieve config before remote config provider was initialised');
        }

        if (!this.localCoreVideoConfigPerPlaybackType) {
            throw new Error('No default local config available for given device');
        }

        const coreVideoConfig = this.coreVideoConfigPerPlaybackType[playbackType] || this.coreVideoConfigPerPlaybackType.default;
        const localCoreVideoConfig = this.localCoreVideoConfigPerPlaybackType[playbackType] || this.localCoreVideoConfigPerPlaybackType.default;
        if (!coreVideoConfig || !localCoreVideoConfig) {
            return;
        }

        const { addons, players, telemetry, csai, cmcd, performance, reducedLatency, prefetching, metalist } = this.sdkConfig;

        const addonsConfig = adaptSdkAddonsClientConfig({
            clientConfig: addons,
            defaultConfig: localCoreVideoConfig.addons,
        });
        const playersConfig = adaptSdkPlayersClientConfig({ defaultConfig: localCoreVideoConfig.players, clientConfig: players }, device);

        const dynamicConfig: DeclaredOptionals<InternalConfigDynamic> = {
            configMetadata: {
                ...coreVideoConfig.configMetadata,
            },
            players: playersConfig,
            addons: addonsConfig,
            hasExtendedDvrSupport: localCoreVideoConfig.hasExtendedDvrSupport,
            preferredStreamPropertyGroups: coreVideoConfig.preferredStreamPropertyGroups,
            allowedDvrWindowDurations: localCoreVideoConfig.allowedDvrWindowDurations,
            prefetching,
            telemetry,
            csai,
            cmcd,
            performance,
            metalist,
            reducedLatency,
            allowedAudioCodecs: localCoreVideoConfig.allowedAudioCodecs,
        };

        return dynamicConfig;
    }

    public checkHasConfigChanged(lastConfigIdentifier: string): boolean {
        const currentConfigIdentifier = this.coreVideoConfigPerPlaybackType?.default.configMetadata.configIdentifier;
        return lastConfigIdentifier !== currentConfigIdentifier;
    }

    private buildPath() {
        const lowerCaseDeviceType = this.device.type.toLowerCase();
        const lowerCaseDeviceModel = this.device.deviceInfo.deviceModel.toLowerCase();
        const lowerCaseProposition = this.sdkConfig.proposition.toLowerCase();
        return `${version}/${lowerCaseProposition}/${lowerCaseDeviceType}/${
            this.device.type === DeviceType.Ion ? `${lowerCaseDeviceModel}/${lowerCaseDeviceModel}` : `${lowerCaseDeviceType}`
        }`;
    }

    private buildPlaybackTypesConfig(storedConfig: CoreVideoConfigCompiled) {
        const configWithUndefinedValues: CoreVideoConfigWithDeviceMatchers = fixUndefinedValues<CoreVideoConfigWithDeviceMatchers>(storedConfig);
        return this.applyDeviceMatchers(configWithUndefinedValues, this.device.deviceInfo);
    }

    private mergePlaybackTypesConfig(
        storedConfig: CoreVideoConfigCompiled,
        playbackTypesConfig: CoreVideoConfigWithPlaybackTypes,
        appliedDeviceMatchers: Array<string>
    ): CoreVideoConfigPerPlaybackType {
        const configMetadata: CoreVideoConfigMetadata = {
            configIdentifier: storedConfig.configIdentifier,
            configTimestamp: storedConfig.configTimestamp,
            appliedDeviceMatchers: appliedDeviceMatchers,
            cvsdkConfigVersionPath: this.configPath,
        };

        const mergedConfig: CoreVideoConfigPerPlaybackType = {
            default: {
                ...playbackTypesConfig.default,
                preferredStreamPropertyGroups: this.adaptPreferredStreamPropertyGroups(playbackTypesConfig.default.preferredStreamPropertyGroups),
                configMetadata,
            },
        };

        Object.keys(PlaybackType).forEach((playbackTypeKey) => {
            const playbackType = playbackTypeKey as PlaybackType;
            if (!playbackTypesConfig[playbackType]) {
                return;
            }

            mergedConfig[playbackType] = this.buildConfigForPlaybackType(playbackTypesConfig, configMetadata, playbackType);
        });

        return mergedConfig;
    }

    private buildConfigForPlaybackType(
        playbackTypesConfig: CoreVideoConfigWithPlaybackTypes,
        configMetadata: CoreVideoConfigMetadata,
        playbackType: PlaybackType
    ): CoreVideoConfigMerged | undefined {
        const playbackTypeConfig = playbackTypesConfig[playbackType];
        if (!playbackTypeConfig) {
            return;
        }

        const mergedBaseConfig: CoreVideoConfigBase = mergeConfigs(playbackTypesConfig.default, playbackTypeConfig, (msg: string) =>
            this.logger.warn(msg)
        );
        const mergedConfig: CoreVideoConfigMerged = {
            ...mergedBaseConfig,
            preferredStreamPropertyGroups: this.adaptPreferredStreamPropertyGroups(mergedBaseConfig.preferredStreamPropertyGroups),
            configMetadata: {
                ...configMetadata,
                appliedPlaybackTypeOverride: playbackType,
            },
        };

        return mergedConfig;
    }

    private adaptPreferredStreamPropertyGroups(
        streamPropertyGroups: Array<PreferredStreamPropertyGroup> | undefined
    ): Array<CompatibleStreamPropertyGroup> | undefined {
        return streamPropertyGroups?.map((group) => {
            return {
                ...group,
                maxVideoFormat: VideoFormat[group.maxVideoFormat],
                supportedColourSpaces: group.supportedColourSpaces.map((cs) => VideoColourSpace[cs]),
            };
        });
    }

    private async refreshRemoteConfig(config: SdkConfigWithRemoteConfig) {
        if (this.requestConfigPromise) {
            return this.requestConfigPromise;
        }

        // set failoverReason while request in progress
        this.failoverReason = RemoteConfigErrorCodes.RequestInProgress;
        this.requestConfigPromise = this.requestConfig(config);
        let storedConfig;
        try {
            storedConfig = await this.requestConfigPromise;
        } catch (error) {
            if (error instanceof CvsdkError) {
                this.failoverReason = error.code;
            }
            throw error;
        } finally {
            this.requestConfigPromise = undefined;
        }

        if (!storedConfig) {
            return;
        }

        if (storedConfig.configIdentifier === this.coreVideoConfigPerPlaybackType?.default?.configMetadata?.configIdentifier) {
            return;
        }

        const [playbackTypesConfig, appliedDeviceMatchers] = this.buildPlaybackTypesConfig(storedConfig);
        this.coreVideoConfigPerPlaybackType = this.mergePlaybackTypesConfig(storedConfig, playbackTypesConfig, appliedDeviceMatchers);
        this.logger.verbose('Using remote config json to set initial config values.');
    }

    private async requestConfig(config: SdkConfigWithRemoteConfig): Promise<CoreVideoConfigCompiled | undefined> {
        const baseUrl = `${config.remoteConfigurationService.host}/js`;
        const url = `${baseUrl}/${this.configPath}-config.json`;
        try {
            const encodedUrl = encodeURIComponent(url);
            decodeURIComponent(encodedUrl);
        } catch (e) {
            if (e instanceof URIError && e.message === 'URI malformed') {
                const error = CvsdkError.from({
                    code: RemoteConfigErrorCodes.MalformedUri,
                    message: 'URI malformed',
                    severity: ErrorSeverity.Warning,
                });

                throw error;
            }
        }
        // TODO https://gspcloud.atlassian.net/browse/VPTJS-11707
        // This error handling is not strictly correct.
        // For example, if a device is offline, there’s a CORS or CSP issue, `fetch` fails immediately.
        // By catching _any_ errors and rethrowing them as timeout errors we are misrepresenting what occurred.
        const fetchResult = await promiseWithTimeout(fetch(url), REMOTE_CONFIG_REQUEST_TIMEOUT).catch((requestTimeoutError) => {
            const error = CvsdkError.from({
                code: RemoteConfigErrorCodes.RequestTimedOut,
                message: `Call to remote Config has exceeded ${REMOTE_CONFIG_REQUEST_TIMEOUT}ms`,
                severity: ErrorSeverity.Warning,
            });

            throw error;
        });

        // reset failoverReason to default when response is successful
        this.failoverReason = RemoteConfigErrorCodes.Default;
        const status = fetchResult.status;
        if (status >= 400) {
            const error = CvsdkError.from({
                code: `${RemoteConfigErrorCodes.HttpBadResponse}${status}`,
                message: `Remote CVSDK config request failed with status code ${status}`,
                severity: ErrorSeverity.Warning,
            });

            throw error;
        }

        const etag = fetchResult.headers.get('etag');
        if (etag === this.etag) {
            return;
        }

        try {
            const parsedConfig: CoreVideoConfigCompiled = await fetchResult.json();
            if (Object.keys(parsedConfig).length === 0) {
                // set failoverReason when request in successful but with empty response
                this.failoverReason = `${RemoteConfigErrorCodes.SuccessfulEmptyResponse}${status}`;
                return;
            }
            this.etag = etag || undefined;
            return parsedConfig;
        } catch (e) {
            const error = CvsdkError.from({
                code: `${RemoteConfigErrorCodes.ParsingFailed}${status}`,
                message: `Remote CVSDK config request returned an invalid JSON with error ${RemoteConfigErrorCodes.ParsingFailed}${status}`,
                severity: ErrorSeverity.Warning,
            });
            throw error;
        }
    }

    private applyDeviceMatchers(
        config: CoreVideoConfigWithDeviceMatchers,
        deviceInfo: DeviceInformation
    ): [CoreVideoConfigWithPlaybackTypes, Array<string>] {
        // eslint-disable-next-line prefer-const
        let { deviceMatchers, ...newConfig } = config;
        if (!deviceMatchers) {
            return [newConfig, []];
        }

        const appliedMatchers: Array<string> = [];
        deviceMatchers.forEach(({ matchers, config: deviceConfig, readableIdentifier }: DeviceMatcherSubConfig) => {
            const isDeviceMatch = checkForMatch(deviceInfo, matchers);

            if (isDeviceMatch) {
                appliedMatchers.push(readableIdentifier);
                newConfig = mergeConfigs(newConfig, deviceConfig, (msg: string) => this.logger.warn(msg));
            }
        });

        return [newConfig, appliedMatchers];
    }
}
