export enum PlayerState {
    Stopped = 0,
    Playing = 1,
    Paused = 2,
    Rebuffering = 3,
    Finished = 4,
    Seeking = 5, // TODO revisit our definition of the seeking state, as we lose information as to whether seeking includes rebuffering
    Loading = 6,
    WaitingToLoad = 7,
}
