import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';
import type { KnownLinearStreamInfo, PlaybackType, Cdn as PlayoutCdn } from '../../../core/player/playout-data';
import { DvrWindowDuration } from '../../../core/player/playout-data';
import { sdkLogger } from '../../../logger';
import { getLinearStreamInfo } from '../../../utils/stream-vendor';
import { TokenizedString } from '../../../utils/tokenized-string';
import { OvpUrlExtendedWindowManager } from '../ovp-url-extended-window-manager/ovp-url-extended-window-manager';
import { DvrWindowDurationValues, type DvrWindowDurationStreamVariant, type OvpStreamVariants } from '@sky-uk-ott/client-lib-js-ott-ovp-service';

export interface OvpUrlTemplateParams {
    ovpEndpoints: Array<PlayoutCdn>;
    streamVariants: Array<OvpStreamVariants>;
    dvrWindowSupported: boolean;
    playbackType: PlaybackType;
    preferredWindowDuration: DvrWindowDuration;
}
export class OvpUrlTemplateManager {
    private static logger: Logger = sdkLogger.withContext('OVP_URL_TEMPLATE_MANAGER');

    private static expectedVariablesInUrl = ['dvrWindowDuration'];

    private static sortCDNsByPreferredWindowDuration(
        modifiedCdnList: Array<PlayoutCdn>,
        preferredWindowDuration: DvrWindowDuration = DvrWindowDuration.Restricted
    ) {
        this.logger.verbose(`Sorting URLs by preferredWindowDuration - ${preferredWindowDuration}`);

        // zero means higher priority => will choose this CDN
        let sortingPriorityMap = {
            [DvrWindowDuration.Restricted]: 0,
            [DvrWindowDuration.Extended]: 0,
            [DvrWindowDuration.FullEvent]: 999,
        };

        if ([DvrWindowDuration.Restricted, DvrWindowDuration.FullEvent].includes(preferredWindowDuration)) {
            if (preferredWindowDuration === DvrWindowDuration.FullEvent) {
                this.logger.warn(
                    `Preferred Window Duration - ${preferredWindowDuration} - is not supported. Falling back to ${DvrWindowDuration.Restricted}`
                );
            }

            sortingPriorityMap = {
                ...sortingPriorityMap,
                [DvrWindowDuration.Restricted]: 0,
                [DvrWindowDuration.Extended]: 1,
            };
        } else if (preferredWindowDuration === DvrWindowDuration.Extended) {
            sortingPriorityMap = {
                ...sortingPriorityMap,
                [DvrWindowDuration.Restricted]: 1,
                [DvrWindowDuration.Extended]: 0,
            };
        }

        return modifiedCdnList.sort((a: PlayoutCdn, b: PlayoutCdn) => {
            return (
                sortingPriorityMap[(a.streamInfo as KnownLinearStreamInfo).windowDuration] -
                sortingPriorityMap[(b.streamInfo as KnownLinearStreamInfo).windowDuration]
            );
        });
    }

    private static replaceUrlTemplateByStreamVariant(ovpUrlTemplateParams: OvpUrlTemplateParams) {
        const { ovpEndpoints, streamVariants, dvrWindowSupported, playbackType, preferredWindowDuration } = ovpUrlTemplateParams;

        let modifiedCdnList: Array<PlayoutCdn> = [];
        this.logger.verbose(`DvrWindowSupported is ${dvrWindowSupported}`);

        ovpEndpoints.forEach((endpoint) => {
            const variablesInUrl: Array<string> = TokenizedString.findTokensBetweenDelimiters({
                startDelimiter: '$',
                endDelimiter: '$',
                targetStr: endpoint.url,
            })!;

            this.logger.verbose(`Variable extracted from URL between $$: ${variablesInUrl.map((variable) => `${this.logger.verbose(variable)}, `)}`);

            variablesInUrl.forEach((variable: string) => {
                streamVariants.forEach((variant: OvpStreamVariants) => {
                    if (!variant[variable]) {
                        this.logger.warn(`Stream Variants do not contain URL variable`);
                        return;
                    }

                    const variablesMapper = variant[variable];
                    const possibleVariableValues: Array<DvrWindowDurationStreamVariant> = Object.values(variablesMapper);

                    const isVariableExpected = Boolean(this.expectedVariablesInUrl.includes(variable));

                    for (let i = 0; i < possibleVariableValues.length; i++) {
                        const possibleVariableValue = possibleVariableValues[i];
                        const { value } = possibleVariableValue;

                        if (!isVariableExpected && !possibleVariableValue.default) {
                            // if it is not expected, then we will only use default case and skip the rest
                            this.logger.warn(`Received an unexpected variable: ${variable}, but it is not default. Skipping`);
                            continue;
                        }

                        const modifiedUrl = TokenizedString.replaceAll({
                            targetStr: endpoint.url,
                            startDelimiter: '$',
                            endDelimiter: '$',
                            variable,
                            value,
                        });

                        this.logger.verbose(`Replacing URL Template variable: ${variable} by value: ${value}`);

                        const stream = getLinearStreamInfo(modifiedUrl);
                        const windowDuration = OvpUrlExtendedWindowManager.getBestWindowDurationBasedOnPlayerCapabilitiesAndStreamProvider(
                            playbackType,
                            preferredWindowDuration,
                            stream
                        );

                        if (value === DvrWindowDurationValues.FOUR_HOUR) {
                            // Supports only 2hr and 2min for now
                            continue;
                        }

                        if (!dvrWindowSupported && value === DvrWindowDurationValues.TWO_HOUR) {
                            // OVP Driven - Supports only 2 minutes
                            continue;
                        }

                        if (windowDuration === DvrWindowDuration.Restricted && value === DvrWindowDurationValues.TWO_HOUR) {
                            // DeviceCapabilities x StreamProvider x PreferredDuration combo - Supports only 2 minutes
                            continue;
                        }

                        this.logger.verbose(`Adding Modified URL: ${modifiedUrl}`);

                        modifiedCdnList.push({
                            ...endpoint,
                            url: modifiedUrl,
                            streamInfo: stream,
                        });

                        if (!isVariableExpected && possibleVariableValue.default) {
                            // if it is not expected, then we will only use default case and skip the rest
                            this.logger.warn(`The variable ${variable} is unexpected, so using only default value (${value}) as replacement`);
                            break;
                        }
                    }
                });
            });
        });

        if (preferredWindowDuration === DvrWindowDuration.FullEvent) {
            this.logger.warn(
                `Preferred Window Duration - ${preferredWindowDuration} - is not supported. Falling back to ${DvrWindowDuration.Restricted}`
            );

            modifiedCdnList = modifiedCdnList.filter(
                (playoutCdn: PlayoutCdn) => (playoutCdn.streamInfo as KnownLinearStreamInfo).windowDuration === DvrWindowDuration.Restricted
            );
        }

        if (!modifiedCdnList.length) {
            throw new Error('OVP URL Template replacement resulted in an empty array. Probably because no variable matched streamVariants. Aborting');
        }

        return this.sortCDNsByPreferredWindowDuration(modifiedCdnList, preferredWindowDuration);
    }

    public static parseDvrWindowUrlTemplate(ovpUrlTemplateParams: OvpUrlTemplateParams): Array<PlayoutCdn> {
        const modifiedCdnList: Array<PlayoutCdn> = this.replaceUrlTemplateByStreamVariant(ovpUrlTemplateParams);

        this.logger.verbose(`Final Modified Cdn List: `);
        modifiedCdnList.map((cdn) => this.logger.verbose(`${cdn.url}`));
        return modifiedCdnList;
    }
}
