import type { AddonConfig, MseWrapConfig } from '../../internal-config';
import type { RemoteConfigSplit } from '../../sdk-config';
import type { DeclaredOptionalsAddonsConfig, InternalAddonsConfig, RequiredPropertiesDefinition } from './addons-config-adapter-common';
import { genericConfigEnabledCheck } from './addons-config-adapter-common';

// Internal Config
type MseWrapInternalConfig = InternalAddonsConfig['mseWrap'];

// Client Config when Remote Config is disabled
export type MseWrapClientConfig = AddonConfig<MseWrapConfig>;
export function adaptMseWrapClientConfig(config: MseWrapClientConfig | undefined): MseWrapInternalConfig {
    return config;
}

// Server Config when Remote Config is enabled
type MseWrapRemoteServerConfig = AddonConfig<{}>;

// Client Config when Remote Config is enabled
type MseWrapRemoteClientConfig = MseWrapConfig;

export type MseWrapRemoteConfigSplit = RemoteConfigSplit<MseWrapRemoteServerConfig, MseWrapConfig>;
export function adaptMseWrapRemoteConfigSplit(config: MseWrapRemoteConfigSplit): DeclaredOptionalsAddonsConfig<MseWrapInternalConfig> {
    const requiredFields: RequiredPropertiesDefinition<MseWrapRemoteClientConfig> = { host: null };
    const isEnabled = genericConfigEnabledCheck(config, requiredFields, 'MseWrap');
    if (!isEnabled) {
        return;
    }

    const { clientConfig } = config;
    return {
        enabled: isEnabled,
        config: {
            host: clientConfig!.host,
            enablePlayerView: clientConfig!.enablePlayerView,
            sessionDurationMs: clientConfig!.sessionDurationMs,
        },
    };
}
