import type { CompanionAd } from '../../../addons/adverts/common';
import { CompanionProvider, CompanionProviderMap } from '../../../addons/adverts/common';

import type { JsonAdTrackingMetadata, JsonCompanionAds, JsonCompanionAdsTracking } from './json-ad-breaks';

function processAttributes(attributes: JsonCompanionAds['attributes']): CompanionAd['attributes'] {
    const { width, height, assetWidth, assetHeight, expandedWidth, expandedHeight, pxratio, ...rest } = attributes;
    const processedAttributes: CompanionAd['attributes'] = { ...rest, width: parseInt(width, 10), height: parseInt(height, 10) };

    processedAttributes.assetWidth = parseInt(assetWidth, 10) || undefined;
    processedAttributes.assetHeight = parseInt(assetHeight, 10) || undefined;
    processedAttributes.expandedWidth = parseInt(expandedWidth, 10) || undefined;
    processedAttributes.expandedHeight = parseInt(expandedHeight, 10) || undefined;
    processedAttributes.pxratio = parseInt(pxratio, 10) || undefined;

    return processedAttributes;
}

function processTacking(tracking: Array<JsonAdTrackingMetadata>): Array<JsonCompanionAdsTracking> {
    const trackingEvents: Array<JsonCompanionAdsTracking> = [];
    tracking.forEach((track) => {
        track.beaconUrls.forEach((url) => {
            trackingEvents.push({
                type: track.eventType,
                url: url,
            });
        });
    });
    return trackingEvents;
}

export function mapCompanionAds(companions: Array<JsonCompanionAds>): Array<CompanionAd> | undefined {
    const ads: Array<CompanionAd> = [];
    if (companions) {
        companions.forEach((companion) => {
            const adSlotId = companion.attributes.adSlotId;
            const provider = CompanionProviderMap[adSlotId];

            switch (provider) {
                case CompanionProvider.Brightline: {
                    const attributes = processAttributes(companion.attributes);
                    const url = companion.iFrameResource || companion.staticResource;
                    const tracking = processTacking(companion.trackingEvents);
                    ads.push({
                        provider: CompanionProvider.Brightline,
                        attributes,
                        url,
                        tracking,
                    });
                    break;
                }
                default:
                    return;
            }
        });
    }
    return ads;
}
