import type { AdInsertionConfig } from '../../../internal-config';
import type { InternalAddonsConfig } from '../addons-config-adapter-common';

import * as VacAdapter from './vac-config-adapter';
import * as MultiPlayerCsaiAdapter from './multi-player-csai-config-adapter';
import * as YospaceAdapter from './yospace-config-adapter';
import * as MediaTailorAdapter from './media-tailor-config-adapter';
import type { RemoteConfigSplit } from '../../../sdk-config';
import type { DeclaredOptionals } from '../../../../utils/ts';

type AdInsertionInternalConfig = InternalAddonsConfig['adInsertion'];

// Client Config when Remote Config is disabled
export type AdInsertionClientConfig = AdInsertionConfig;
export function adaptAdInsertionClientConfig(config: AdInsertionClientConfig | undefined): AdInsertionInternalConfig {
    return config;
}

// Server Config when Remote Config is enabled
type AdInsertionRemoteServerConfig = {
    mediaTailorAdInsertionOverrideVod?: boolean;
    mediaTailorAdInsertionOverrideFer?: boolean;
    mediaTailorAdInsertionOverrideLinear?: boolean;
    enabled: boolean;
    vac?: VacAdapter.VacRemoteConfigSplit['remoteConfig'];
    multiPlayerCsai?: MultiPlayerCsaiAdapter.MultiPlayerCsaiRemoteConfigSplit['remoteConfig'];
    yospace?: YospaceAdapter.YospaceRemoteConfigSplit['remoteConfig'];
    mediaTailor?: MediaTailorAdapter.MediaTailorRemoteConfigSplit['remoteConfig'];
};

// Client Config when Remote Config is enabled
type AdInsertionRemoteClientConfig = {
    vac?: VacAdapter.VacRemoteConfigSplit['clientConfig'];
    multiPlayerCsai?: MultiPlayerCsaiAdapter.MultiPlayerCsaiRemoteConfigSplit['clientConfig'];
    yospace?: YospaceAdapter.YospaceRemoteConfigSplit['clientConfig'];
    mediaTailor?: MediaTailorAdapter.MediaTailorRemoteConfigSplit['clientConfig'];
};

export type AdInsertionRemoteConfigSplit = RemoteConfigSplit<AdInsertionRemoteServerConfig, AdInsertionRemoteClientConfig>;

export function adaptAdInsertionRemoteConfigSplit(config: AdInsertionRemoteConfigSplit): DeclaredOptionals<InternalAddonsConfig['adInsertion']> {
    const { clientConfig, remoteConfig } = config;
    if (!remoteConfig?.enabled || !clientConfig) {
        return;
    }

    return {
        enabled: remoteConfig?.enabled,
        mediaTailorAdInsertionOverrideVod: remoteConfig?.mediaTailorAdInsertionOverrideVod,
        mediaTailorAdInsertionOverrideFer: remoteConfig?.mediaTailorAdInsertionOverrideFer,
        mediaTailorAdInsertionOverrideLinear: remoteConfig?.mediaTailorAdInsertionOverrideLinear,
        vac: VacAdapter.adaptVacRemoteConfigSplit({ remoteConfig: remoteConfig.vac, clientConfig: clientConfig.vac }),
        mediaTailor: MediaTailorAdapter.adaptMediaTailorRemoteConfigSplit({
            remoteConfig: remoteConfig.mediaTailor,
            clientConfig: clientConfig.mediaTailor,
        }),
        multiPlayerCsai: MultiPlayerCsaiAdapter.adaptMultiPlayerCsaiRemoteConfigSplit({
            remoteConfig: remoteConfig.multiPlayerCsai,
            clientConfig: clientConfig.multiPlayerCsai,
        }),
        yospace: YospaceAdapter.adaptYospaceRemoteConfigSplit({ remoteConfig: remoteConfig.yospace, clientConfig: clientConfig.yospace }),
    };
}
