//cspell: words adbreak
export enum TelemetryEventType {
    AdBreakStart = 'ADBREAK_START',
    AdBreakEnd = 'ADBREAK_END',
    AdBreakError = 'ADBREAK_ERROR',
    EndError = 'END_ERROR',
    Periodic = 'PERIODIC',
}

export enum TelemetryNotificationKey {
    sessionStart = 'sessionStart',
    videoStartUp = 'videoStartUp',
    vacRequestStart = 'vacRequestStart',
    vacResponseEnd = 'vacResponseEnd',
    ovpRequestStart = 'ovpRequestStart',
    ovpResponseEnd = 'ovpResponseEnd',
    mediaTailorRequestStart = 'mediaTailorRequestStart',
    mediaTailorResponseEnd = 'mediaTailorResponseEnd',
    trackingUrlRequestStart = 'trackingUrlRequestStart',
    trackingUrlResponseEnd = 'trackingUrlResponseEnd',
}
