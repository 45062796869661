import { DeviceType } from '@sky-uk-ott/client-lib-js-device';
import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';

import { CoreVideoInternal } from '../../../core-video-internal';
import type { TimedMetadata } from '../../../core/player/player-engine-item';
import { TimedMetadataType } from '../../../core/player/player-engine-item';
import { PlayerState } from '../../../core/player/player-state';
import type { AdInsertionConfig, Cdn, PlayoutData } from '../../../core/player/playout-data';
import { AdInsertionStrategy, MediaContainer, PlaybackType, StreamingProtocol } from '../../../core/player/playout-data';
import type { Asset } from '../../../core/session-controller/asset';
import { StaticAsset } from '../../../core/session-controller/asset';
import type { SessionItem } from '../../../core/session-controller/session-controller';
import type { InternalSessionInterface } from '../../../core/session-controller/session-controller-internal';
import type { CvsdkError } from '../../../error';
import { sdkLogger } from '../../../logger';
import type { BaseCsaiAdBreaksProvider } from '../../../players/bolt-ons/ad-breaks-provider/base-csai-ad-breaks-provider';
import { checkIsManifestLinearType } from '../../../utils/playback-type';
import { Scte35Parser } from '../../../utils/scte35';
import { appendQueryParams, getOriginFromUrl } from '../../../utils/url-builder';
import { AdInsertionType } from '../../ad-insertion-type';
import type { AddonAdvertisingData, AdvertisingData } from '../../addon-playout-data';
import type { Vac } from '../../vac/vac-addon';
import { Vam } from '../../vac/vam/vam-addon';
import type { AdBreak, VodAd } from '../common';
import { AdBreakType, TrackingEventType } from '../common';
import type { PauseAdvertDispatcher } from '../pause-adverts/pause-advert-dispatcher';
import { PixelFetch } from '../pixel-fetch';

import type { AdAssetGroup, VodAdvertsAddon } from './vod-adverts-addon';
import { InternalSessionState } from '../../../core/session-controller/session-controller.enums';

const SESSION_START_AD_INSERTION_SAFEGUARD = 1;

export class TimelineAdvertsAddon implements VodAdvertsAddon {
    public readonly name: string = 'TimelineAdverts';
    public readonly playoutOptions: AdInsertionConfig = {
        adInsertionStrategy: AdInsertionStrategy.CSAI,
        isPlayerAdEventsRequired: false,
        isPlayerAdBreakDataRequired: false,
        isTimelineAdManagementRequired: true,
        adProvider: AdInsertionType.MultiPlayerCsai,
    };
    protected advertising!: AddonAdvertisingData;
    private unregisterSessionTimedMetadataReceivedObserver: () => void;
    private liveBreakCounter: number;
    private logger: Logger = sdkLogger.withContext('TimelineAds');
    private processedMetadata: { [key: string]: boolean } = {};
    private vacAddon?: Vac.Addon;
    constructor(
        private adBreakProvider: BaseCsaiAdBreaksProvider,
        private session: InternalSessionInterface,
        private playbackType: PlaybackType,
        private pauseAdDispatcher: PauseAdvertDispatcher,
        private playoutData?: PlayoutData,
        private pixelFetch: PixelFetch = new PixelFetch(self as unknown as Window),
        private adBreaksFilter?: (sessionItem: SessionItem, breaks: Array<AdBreak<VodAd>>) => Array<AdBreak<VodAd>>
    ) {
        this.liveBreakCounter = 1;
        this.unregisterSessionTimedMetadataReceivedObserver = session.onTimedMetadataReceived(this.handleTimedMetadata);
    }

    public async prepareAdvertising(requestOptions: Vac.RequestOptions): Promise<Vac.ResponseData | null> {
        if (!this.vacAddon) {
            this.logger.warn('Vac addon not initialised yet');
            return Promise.resolve(null);
        }

        return this.vacAddon.fetchVacResponse(requestOptions);
    }

    public get advertisingData(): AdvertisingData {
        return this.advertising;
    }

    public setAdvertising(advertising: AdvertisingData): void {
        this.advertising = advertising;
    }

    public setVacAddon(vacAddon: Vac.Addon): void {
        this.vacAddon = vacAddon;
    }

    public async getAdvertsForSession(advertising: AdvertisingData = this.advertising, linearBreakStartTime?: number): Promise<Array<AdAssetGroup>> {
        if (!('contentId' in advertising)) {
            return [];
        }

        const adData = await this.adBreakProvider.getAdBreaks(advertising);
        this.pauseAdDispatcher.addAdEventsFromAdData(adData.nonLinearAds);

        const isEmpty = (adBreak: AdBreak<VodAd>) => {
            return !adBreak.ads || adBreak.ads.length === 0;
        };

        const emptyPrerolls = adData.adBreaks.filter((adBreak) => adBreak.type === AdBreakType.Preroll && isEmpty(adBreak));

        if (emptyPrerolls.length > 0) {
            this.handleEmptyPrerolls(emptyPrerolls);
        }

        const unmappedFilteredAdBreaks = this.adBreaksFilter
            ? this.adBreaksFilter(this.session.getPrecursor()!.sessionItem, adData.adBreaks)
            : adData.adBreaks;
        const filteredAdBreaks = unmappedFilteredAdBreaks.map((adBreak) => {
            if (linearBreakStartTime && adBreak.position === 0) {
                adBreak.position = linearBreakStartTime;
            }
            let expectedDuration = 0;
            adBreak.ads.forEach((ad) => {
                if (CoreVideoInternal.deviceType === DeviceType.YouView && this.playoutData) {
                    // For more information please see https://github.com/sky-uk/core-video-team/issues/7043
                    this.logger.info('Appending yo.up into CDNs uri returned by Ad Breaks Provider');
                    ad.cdns.map((cdn) => this.hydrateAdvertisingCdn(cdn));
                }
                ad.adBreak = adBreak;
                expectedDuration += ad.expectedDuration;
            });
            adBreak.expectedDuration = expectedDuration;
            return adBreak;
        });

        this.session.notifyAdBreakDataReceived(filteredAdBreaks);

        const advertAssetGroups = filteredAdBreaks.map((adBreak) => {
            const assetGroup = {
                assets: adBreak.ads.map((ad, index) => {
                    const adAsset = this.createAsset(ad);

                    // the first advert playing marks the start of the ad break
                    if (index === 0) {
                        adAsset.onStateChanged((state) => {
                            if (state === PlayerState.Playing) {
                                this.triggerTrackingEvent(adBreak, TrackingEventType.BreakStart);
                            }
                        }, this);
                    }

                    this.listenToAdEvents(ad, adAsset);

                    // the last advert finishing marks the end of the ad break
                    if (index === adBreak.ads.length - 1) {
                        adAsset.onStateChanged((state) => {
                            if (state === PlayerState.Finished) {
                                this.triggerTrackingEvent(adBreak, TrackingEventType.BreakEnd);
                            }
                        }, this);
                    }

                    return adAsset;
                }),
                position: adBreak.position!,
                adBreak,
            };

            return assetGroup;
        });

        return advertAssetGroups;
    }

    public destroy(): void {
        this.unregisterSessionTimedMetadataReceivedObserver();
    }

    private handleEmptyPrerolls(adBreaks: Array<AdBreak<VodAd>>) {
        adBreaks.forEach((adBreak) => {
            adBreak.trackingEvents?.forEach((event) => {
                if (!event.isActivated) {
                    this.logger.verbose(`Firing tracking event ${event.url} for empty preroll ad break ${adBreak.id}`);
                    event.isActivated = true;
                    this.pixelFetch.fetch(event.url);
                }
            });
        });
    }

    private hydrateAdvertisingCdn(cdn: Cdn): Cdn {
        const cdnEndpoint = getOriginFromUrl(this.playoutData!.cdns[0].url);
        const queryParams = {
            'yo.up': cdnEndpoint!,
        };

        cdn.url = appendQueryParams(cdn.url, queryParams, { shouldNotEncodeURI: true });
        return cdn;
    }

    private createAsset(ad: VodAd): Asset {
        const adPlayoutData: PlayoutData = {
            cdns: ad.cdns ?? [],
            stream: {
                protocol: ad.streamingProtocol,
            },
            type: PlaybackType.Advert,
            heartbeat: this.playoutData?.heartbeat,
            prefetch: this.playoutData?.prefetch,
        };
        if (ad.streamingProtocol === StreamingProtocol.PDL) {
            adPlayoutData.stream.container = MediaContainer.MP4;
        }
        return new StaticAsset(adPlayoutData);
    }

    private listenToAdEvents(ad: VodAd, asset: Asset): void {
        asset.onStateChanged((state) => this.handleAdStateChanged(ad, state), this);

        asset.onPositionChanged((position) => this.handleAdPositionChanged(ad, position), this);

        asset.onError((error) => this.handleAdError(ad, error), this);

        asset.onSkipped(() => this.handleAdSkipped(ad), this);
    }

    private handleTimedMetadata = async (metadata: TimedMetadata): Promise<void> => {
        const scteScheme = 'urn:scte:scte35:2014:xml+bin';
        if (metadata.type === TimedMetadataType.EventStream && metadata.schemeIdUri === scteScheme && checkIsManifestLinearType(this.playbackType)) {
            if (!metadata.scte35Data) {
                return;
            }
            try {
                const scteParser = new Scte35Parser();
                const linearBreakStartTime = metadata.startTime;
                const adData = scteParser.parseAdDataFromScte35Base64(metadata.scte35Data);

                if (!adData) return; // not an advert SCTE

                const eventKey = `break:${linearBreakStartTime}`;
                if (eventKey in this.processedMetadata) return;

                this.processedMetadata[eventKey] = true;

                await this.session.waitForOneOfStates([InternalSessionState.Playing, InternalSessionState.Paused]);
                const contentTimePosition = this.session.getContentTimePosition();
                if (contentTimePosition && contentTimePosition - SESSION_START_AD_INSERTION_SAFEGUARD > linearBreakStartTime) {
                    this.logger.info('Ignoring linear ad due to late session start');
                    return;
                }

                const advertAssetGroups = await this.getAdvertsForSession(this.hydrateAdvertisingData(adData), linearBreakStartTime);
                this.session.notifyTimelineAssetsAvailable(advertAssetGroups);
            } catch (e) {
                const message = `Error when getting ads for linear Timeline CSAI: ${e}`;
                this.logger.error(message);
                this.session.notifyWarning(`SESSION.DUAL_PLAYER_ADS.LIVE_ADS_FAILED`, message);
            }
        }
    };

    private hydrateAdvertisingData(adData: { contentId: string; duration: number }): AdvertisingData {
        const vac = this.advertising.vac;

        if (!vac) throw new Error('unavailable ads configuration');

        const hydratedVac = Vam.hydrateVamResponse(vac, adData.contentId, adData.duration, this.liveBreakCounter++);

        return {
            ...this.advertising,
            vac: hydratedVac!,
        };
    }

    private handleAdPositionChanged(ad: VodAd, position: number): void {
        const quarterDuration = ad.expectedDuration / 4;
        if (position > quarterDuration * 3) {
            this.triggerTrackingEvent(ad, TrackingEventType.ThirdQuartile);
        } else if (position > quarterDuration * 2) {
            this.triggerTrackingEvent(ad, TrackingEventType.Midpoint);
        } else if (position > quarterDuration) {
            this.triggerTrackingEvent(ad, TrackingEventType.FirstQuartile);
        }
    }

    private handleAdStateChanged(ad: VodAd, state: PlayerState): void {
        if (state === PlayerState.Playing) {
            this.triggerTrackingEvent(ad, TrackingEventType.AdImpression);
        } else if (state === PlayerState.Finished) {
            this.triggerTrackingEvent(ad, TrackingEventType.AdFinished);
        }
    }

    private handleAdError(ad: VodAd, _error: CvsdkError): void {
        this.triggerTrackingEvent(ad, TrackingEventType.Error);
    }

    private handleAdSkipped(ad: VodAd): void {
        this.triggerTrackingEvent(ad, TrackingEventType.Skipped);
    }

    private triggerTrackingEvent(ad: VodAd | AdBreak, eventType: TrackingEventType): void {
        ad.trackingEvents?.forEach((trackingEvent) => {
            if (trackingEvent.type === eventType && !trackingEvent.isActivated) {
                trackingEvent.isActivated = true;

                this.pixelFetch.fetch(trackingEvent.url);
            }
        });
    }
}
