import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';
import deepmerge from 'deepmerge';

import type { SessionItem } from '../../core/session-controller/session-controller';
import { CoreVideoInternal } from '../../core-video-internal';
import type { SessionControllerInternalProxy } from '../../core/session-controller/session-controller-internal-proxy';
import { sdkLogger } from '../../logger';
import type { ReportingAddonFactory, ReportingAddonSessionData } from '../addons-factories';
import type { AddonsConfig } from '../../config/internal-config';
import { InternalConfigProvider } from '../../config/internal-config-provider';

import { DeferredEventQueue } from './deferred-event-queue';
import type { ReportingAddon } from './reporting-addon';

const DEFER_TIME_MILLISECONDS = 12000;

export class ReportingManager {
    private reportingAddons: Array<ReportingAddon> = new Array<ReportingAddon>();
    private eventQueue: DeferredEventQueue | null = null;
    private logger: Logger = sdkLogger.withContext('ReportingManager');
    private _initialised = false;
    constructor(
        private session: SessionControllerInternalProxy,
        private reportingFactories: Array<ReportingAddonFactory>,
        private sessionItem: SessionItem
    ) {}

    public initialise(): void {
        this.eventQueue = new DeferredEventQueue(CoreVideoInternal.capabilities.hasLowerEndSpecs() ? DEFER_TIME_MILLISECONDS : 0);
        // TODO: Create a better way to support addonsConfigOverride across all the addons
        // OR just remove support for the api.
        // https://github.com/sky-uk/core-video-team/issues/7837
        const config = InternalConfigProvider.getForPlaybackType(CoreVideoInternal.config, this.sessionItem.type);
        const addonsConfigWithOverrides = this.applyAddonsConfigOverrides(config.addons, this.sessionItem.addonsConfigOverride!);

        for (const raf of this.reportingFactories || []) {
            const reportingSessionConfig = addonsConfigWithOverrides.reporting || config.addons.reporting;
            const reportingAddonSessionData: ReportingAddonSessionData = {
                common: addonsConfigWithOverrides.common || config.addons.common,
                reportingSessionConfig,
                session: this.session,
                sessionItem: this.sessionItem,
                eventQueue: this.eventQueue,
            };

            try {
                const addon = raf(reportingAddonSessionData);

                if (addon) {
                    this.reportingAddons.push(addon);
                }
            } catch (e) {
                const msg = `Error while loading reporting addon: ${e}`;
                sdkLogger.error(msg, e);
                this.session.notifyWarning('REPORTING_MANAGER.LOAD', msg);
            }
        }

        this._initialised = true;
    }

    public get initialised(): boolean {
        return this._initialised;
    }

    public destroy(): void {
        this.eventQueue?.executePending();

        this.reportingAddons.forEach((addon) => {
            try {
                addon.destroy();
                this.logger.verbose(`Addon ${addon.name} destroyed`);
            } catch (e) {
                this.logger.warn(`Error destroying addon ${addon.name}`, e);
            }
        });
    }

    public getReportingAddons(): Array<ReportingAddon> {
        return this.reportingAddons;
    }

    private applyAddonsConfigOverrides(addonsConfig: AddonsConfig, sessionAddonOverrides: AddonsConfig): AddonsConfig {
        return deepmerge({ ...addonsConfig }, { ...sessionAddonOverrides });
    }
}
