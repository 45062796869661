import type { LinearStreamInfo } from '../core/player/playout-data';
import { DvrWindowDuration, StreamVendor } from '../core/player/playout-data';

export const ISTREAMPLANET_2M_PATH = '/2m/';
export const ISTREAMPLANET_2H_PATH = '/2h/';
export const ISTREAMPLANET_EVENT_PATH = '/event/';

export const HARMONIC_2M_PATH = 'master_2min';
export const HARMONIC_2H_PATH = 'master_2hr';
export const HARMONIC_4H_PATH = 'master_4hr';
export const HARMONIC_V1_2M_PATH = 'master';
export const HARMONIC_EVENT_PATH = 'master';

const HARMONIC_V2_SUFFIX = '-v2';
export const HARMONIC_V2 = '-4s-v2';
export const HARMONIC_V1 = '-4s';

function isRestrictedHarmonic(url: string) {
    return url.includes(HARMONIC_2M_PATH) || (url.includes(HARMONIC_V1) && !url.includes(HARMONIC_V2_SUFFIX));
}

function isExtendedHarmonic(url: string) {
    return url.includes(HARMONIC_2H_PATH);
}

function isFullEventHarmonic(url: string) {
    return url.includes(HARMONIC_4H_PATH) || (url.includes(HARMONIC_V2) && url.includes(HARMONIC_EVENT_PATH));
}

function isRestrictedIStreamPlanet(url: string) {
    return url.includes(ISTREAMPLANET_2M_PATH);
}

function isExtendedIStreamPlanet(url: string) {
    return url.includes(ISTREAMPLANET_2H_PATH);
}

function isFullEventIStreamPlanet(url: string) {
    return url.includes(ISTREAMPLANET_EVENT_PATH);
}

export function getLinearStreamInfo(url: string): LinearStreamInfo {
    if (isRestrictedHarmonic(url)) {
        return {
            vendor: StreamVendor.Harmonic,
            windowDuration: DvrWindowDuration.Restricted,
        };
    } else if (isExtendedHarmonic(url)) {
        return {
            vendor: StreamVendor.Harmonic,
            windowDuration: DvrWindowDuration.Extended,
        };
    } else if (isFullEventHarmonic(url)) {
        return {
            vendor: StreamVendor.Harmonic,
            windowDuration: DvrWindowDuration.FullEvent,
        };
    } else if (isRestrictedIStreamPlanet(url)) {
        return {
            vendor: StreamVendor.IStreamPlanet,
            windowDuration: DvrWindowDuration.Restricted,
        };
    } else if (isExtendedIStreamPlanet(url)) {
        return {
            vendor: StreamVendor.IStreamPlanet,
            windowDuration: DvrWindowDuration.Extended,
        };
    } else if (isFullEventIStreamPlanet(url)) {
        return {
            vendor: StreamVendor.IStreamPlanet,
            windowDuration: DvrWindowDuration.FullEvent,
        };
    } else {
        return {
            vendor: StreamVendor.Unknown,
        };
    }
}

export function getStreamVendor(url: string): StreamVendor {
    return getLinearStreamInfo(url).vendor;
}
