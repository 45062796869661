export interface QueryParams {
    [name: string]: string | number | boolean | undefined;
}

interface Options {
    keepEmptyValues?: boolean;
    shouldUseInitialDelimiter?: boolean;
    shouldNotEncodeURI?: boolean;
}

const defaultOptions: Options = { keepEmptyValues: true, shouldUseInitialDelimiter: true };

export function appendQueryParams(url: string, queryParams: QueryParams, userOptions?: Options): string {
    const options = Object.assign({}, defaultOptions, userOptions);

    let delimiter: string;
    if (!options.shouldUseInitialDelimiter) {
        delimiter = '';
    } else if (url && url.slice(-1) === '?') {
        delimiter = '';
    } else if (url && url.indexOf('?') === -1) {
        delimiter = '?';
    } else {
        delimiter = '&';
    }

    const queryString = Object.entries(queryParams).reduce((acc, [key, value]) => {
        if (value === null || value === undefined || (!options.keepEmptyValues && value === '')) {
            return acc;
        }

        const processedValue = options.shouldNotEncodeURI ? value : encodeURIComponent(value);
        if (acc) {
            return `${acc}&${key}=${processedValue}`;
        } else {
            return `${delimiter}${key}=${processedValue}`;
        }
    }, '');

    return `${url}${queryString}`;
}

export function getOriginFromUrl(url: string): string | null {
    const { origin } = new URL(url);

    return origin === 'null' ? null : origin;
}

export function getPathFromUrl(url: string): string | null {
    const regex = /.+?:\/\/.+?(\/.+?)(?:#|\?|$)/;
    const pathName = regex.exec(url);

    return pathName && pathName[1];
}

export function getProtocol(url: string): string {
    return new URL(url).protocol;
}

export function replaceUrlProtocol(originalUrl: string, newProtocol: string): string {
    const url = new URL(originalUrl);
    url.protocol = newProtocol;
    return url.toString();
}
