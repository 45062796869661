import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';
import { DrmType } from '../core/player/playout-data';
import { AudioCodec, AudioFormat } from '../core/player/audio-format';
import { MIN_4K_HDCP_VERSION, MIN_HD_HDCP_VERSION } from '../core/player/video-format';
import { sdkLogger } from '../logger';

import { Resolutions } from './resolution';
import { AudioCodecType, DrmKind, StatusPolicies, UhdHdrCodec } from './probe-utils.enums';

const audioCodecMimeType = 'audio/mp4';

type CodecSpecs = {
    codec: string;
    hdrTransferFunc?: string;
    mimeType: string;
    rawCodecs: string;
    rawCodecsUnquoted: string;
    hdrMetadataType?: HdrMetadataType;
    colorGamut?: ColorGamut;
    transferFunction?: TransferFunction;
};

interface UhdHdrCodecMapType {
    [key: string]: CodecSpecs;
}

interface StatusForPolicyOptions {
    minHdcpVersion: string;
}

type MediaKey =
    | boolean
    | (MediaKeys & {
          getStatusForPolicy: (options: StatusForPolicyOptions) => Promise<StatusPolicies>;
      });

// 4k + HDR Codecs used by OVP
export const UhdHdrCodecs: UhdHdrCodecMapType = {
    [UhdHdrCodec.Uhd]: {
        codec: 'video/mp4;codecs="hvc1.1.6.L150.90"', // H.265 Main profile, level 5 (up to 4k). 8 bits per color per pixel (SDR)
        mimeType: 'video/mp4',
        rawCodecs: '"hvc1.1.6.L150.90"',
        rawCodecsUnquoted: 'hvc1.1.6.L150.90',
    },
    [UhdHdrCodec.HdrHlg]: {
        codec: 'video/mp4;codecs="hvc1.1.6.L150.90"',
        mimeType: 'video/mp4',
        rawCodecs: '"hvc1.1.6.L150.90"',
        hdrMetadataType: '',
        colorGamut: 'rec2020',
        transferFunction: 'hlg',
        rawCodecsUnquoted: 'hvc1.1.6.L150.90',
    },
    [UhdHdrCodec.Hdr10]: {
        codec: 'video/mp4;codecs="hvc1.2.4.L150.90"', // H.265 Main 10 profile, level 5 (up to 4k). Up to 10 bits per color per pixel; capable of containing HDR content but is not required to
        hdrTransferFunc: 'eotf="smpte2084"', // electro-optical transfer function that describes HDR color space (for use with 10-bit profiles only)
        mimeType: 'video/mp4',
        rawCodecs: '"hvc1.2.4.L150.90"',
        hdrMetadataType: 'smpteSt2086',
        colorGamut: 'rec2020',
        transferFunction: 'pq',
        rawCodecsUnquoted: 'hvc1.2.4.L150.90',
    },
    [UhdHdrCodec.Hdr10Plus]: {
        codec: 'video/mp4;codecs="hvc1.2.4.L150.90"', // H.265 Main 10 profile, level 5 (up to 4k). Up to 10 bits per color per pixel; capable of containing HDR content but is not required to
        hdrTransferFunc: 'eotf="smpte2084"', // electro-optical transfer function that describes HDR color space (for use with 10-bit profiles only)
        mimeType: 'video/mp4',
        rawCodecs: '"hvc1.2.4.L150.90"',
        hdrMetadataType: 'smpteSt2094-10',
        colorGamut: 'rec2020',
        transferFunction: 'pq',
        rawCodecsUnquoted: 'hvc1.2.4.L150.90',
    },
    [UhdHdrCodec.DolbyVision]: {
        codec: 'video/mp4;codecs="dvh1.05.06"',
        mimeType: 'video/mp4',
        rawCodecs: '"dvh1.05.06"',
        hdrMetadataType: 'smpteSt2094-10',
        colorGamut: 'rec2020',
        transferFunction: 'pq',
        rawCodecsUnquoted: 'dvh1.05.06',
    },
};

export const AudioCodecs: UhdHdrCodecMapType = {
    [AudioCodecType.MPEG_4_LC]: getAudioCodecSpecs(AudioCodec.MP4A2),
    [AudioCodecType.MPEG_4_SBR]: getAudioCodecSpecs(AudioCodec.MP4A5),
    [AudioCodecType.DolbyDigital]: getAudioCodecSpecs(AudioCodec.AC3), // Dolby Digital. Max 5.1 channels
    [AudioCodecType.DolbyDigitalPlus]: getAudioCodecSpecs(AudioCodec.EC3), // Dolby Digital Plus. Max 7.1 channels. Can carry Dolby Atmos if device also supports spatial rendering
};

interface DrmLevelType {
    [key: string]: string | undefined;
}

// DRM levels for UHD codecs
export const drmUHDlevel: DrmLevelType = {
    [DrmKind.Widevine]: 'HW_SECURE_ALL',
    [DrmKind.PlayReady]: '3000',
    [DrmKind.FairPlay]: undefined,
};

const uhdStreamSpecs = { bitrate: 12500000, width: Resolutions.UHD.minWidth, height: Resolutions.UHD.minHeight, framerate: 30 };

type DecodingInfo = {
    supported: boolean;
    smooth: boolean;
    powerEfficient: boolean;
};

interface MediaConfiguration {
    audio?: AudioConfiguration;
    video?: VideoConfiguration;
}

interface MediaDecodingConfiguration extends MediaConfiguration {
    type: MediaDecodingType;
}

type ColorGamut = 'p3' | 'rec2020' | 'srgb';
type HdrMetadataType = 'smpteSt2086' | 'smpteSt2094-10' | 'smpteSt2094-40' | ''; // HLG has no `hdrMetadataType`
type TransferFunction = 'hlg' | 'pq' | 'srgb';

interface AudioConfiguration {
    bitrate?: number;
    channels?: number;
    contentType: string;
    samplerate?: number;
    spatialRendering?: boolean;
}

interface VideoConfiguration {
    bitrate: number;
    colorGamut?: ColorGamut;
    contentType: string;
    framerate: number;
    hdrMetadataType?: HdrMetadataType;
    height: number;
    scalabilityMode?: string;
    transferFunction?: TransferFunction;
    width: number;
}

type MediaDecodingType = 'file' | 'media-source' | 'webrtc';

interface MediaDecodingConfiguration extends MediaConfiguration {
    type: MediaDecodingType;
}

function getAudioCodecSpecs(audioCodec: AudioCodec): CodecSpecs {
    return {
        codec: `${audioCodecMimeType};codecs="${audioCodec}"`,
        mimeType: audioCodecMimeType,
        rawCodecs: `"${audioCodec}"`,
        rawCodecsUnquoted: audioCodec,
    };
}

function isCodecSupported(codec: string): boolean {
    if (codec && 'MediaSource' in self && self.MediaSource.isTypeSupported) {
        return self.MediaSource.isTypeSupported(codec);
    }
    return false;
}

export function isVideoCodecSupported(codec: UhdHdrCodec, eotf?: boolean): boolean {
    let codecString: string = UhdHdrCodecs[codec].codec;
    if (codecString && eotf && UhdHdrCodecs[codec].hdrTransferFunc) {
        codecString += `;${UhdHdrCodecs[codec].hdrTransferFunc}`;
    }
    return isCodecSupported(codecString);
}

export function isAudioCodecSupported(codec: AudioCodecType): boolean {
    return isCodecSupported(AudioCodecs[codec]?.codec);
}

export function probeSupportedAudioCodecs(): string[] {
    const codecs = [];
    if (isCodecSupported(AudioCodecs.DolbyDigitalPlus.codec)) {
        codecs.push(AudioCodecs.DolbyDigitalPlus.rawCodecsUnquoted);
    } else if (isCodecSupported(AudioCodecs.MPEG_4_LC.codec)) {
        codecs.push(AudioCodecs.MPEG_4_LC.rawCodecsUnquoted);
    }

    // Should only be used by Showmax
    if (isCodecSupported(AudioCodecs.MPEG_4_SBR.codec)) {
        codecs.push(AudioCodecs.MPEG_4_SBR.rawCodecsUnquoted);
    }

    return codecs;
}

export function getMediaSourceSupportedAudioFormats(): Array<AudioFormat> {
    const audioFormats: Array<AudioFormat> = [AudioFormat.STEREO];
    if (isAudioCodecSupported(AudioCodecType.DolbyDigitalPlus)) {
        audioFormats.push(AudioFormat.SURROUND_5_1, AudioFormat.SURROUND_7_1, AudioFormat.ATMOS);
    } else if (isAudioCodecSupported(AudioCodecType.DolbyDigital)) {
        audioFormats.push(AudioFormat.SURROUND_5_1);
    }
    return audioFormats;
}

// Probe HDR10 support for codec via navigator.mediaCapabilities with hdrMetadataType
export async function probeHdrSupportByMediaCapabilities(codecType: UhdHdrCodec = UhdHdrCodec.Hdr10): Promise<boolean> {
    const logger: Partial<Logger> = sdkLogger.withContext('PRB');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (navigator && 'mediaCapabilities' in navigator && (navigator as any).mediaCapabilities.decodingInfo) {
        const config: MediaDecodingConfiguration = {
            type: 'media-source',

            video: {
                contentType: UhdHdrCodecs[codecType].codec,
                framerate: uhdStreamSpecs.framerate,
                bitrate: uhdStreamSpecs.bitrate,
                height: uhdStreamSpecs.height,
                width: uhdStreamSpecs.width,
                hdrMetadataType: UhdHdrCodecs[codecType].hdrMetadataType,
                colorGamut: UhdHdrCodecs[codecType].colorGamut,
                transferFunction: UhdHdrCodecs[codecType].transferFunction,
            },
        };

        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const decodingSupport: DecodingInfo = await (navigator as any).mediaCapabilities.decodingInfo(config);
            return decodingSupport.supported;
        } catch (e) {
            logger.warn!(`mediaCapabilities.decodingInfo() failed: ${e}`);
            return false;
        }
    } else {
        return false;
    }
}

export type getHDCPstatusReturn = {
    callSuccessful: boolean;
    isSupported: boolean;
};

/**
 * @internal Probes device for UHD-required HDCP 2.2 support of given codec
 * @param codec: codec to check
 * @param drm: flavor of DRM (required for Media Keys API)
 * @returns codecDrmProbeReturn object containing flag for successful completion of getStatusForPolicy() call and resulting HDCP 2.2 support
 */
export async function getHDCPstatusForUHD(codec: UhdHdrCodec, drm: DrmKind): Promise<getHDCPstatusReturn> {
    let isSupported = false;
    let callSuccessful = false;
    const codecString = UhdHdrCodecs[codec].codec;
    const logger: Partial<Logger> = sdkLogger.withContext('PRB');
    const config: Array<MediaKeySystemConfiguration> = [
        {
            initDataTypes: ['cenc'],
            videoCapabilities: [
                {
                    contentType: codecString,
                    robustness: drmUHDlevel[drm],
                    width: Resolutions.UHD.minWidth,
                    height: Resolutions.UHD.minHeight,
                } as MediaKeySystemMediaCapability,
            ],
        },
    ];

    const robustnessStr = config![0].videoCapabilities![0].robustness ? ` w/ ${config![0].videoCapabilities![0].robustness}` : ``;

    if ('requestMediaKeySystemAccess' in navigator) {
        try {
            const mediaKeySystemAccess = await navigator.requestMediaKeySystemAccess(drm, config);
            const mediaKeys = await mediaKeySystemAccess.createMediaKeys();

            if (!('getStatusForPolicy' in mediaKeys)) {
                logger.warn!(`HDCP Policy Check API is not available: getStatusForPolicy does not exist.`);
            } else {
                try {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const status = await (mediaKeys as any).getStatusForPolicy({ minHdcpVersion: MIN_4K_HDCP_VERSION });
                    if (status !== 'usable') {
                        callSuccessful = true;
                        logger.info!(`No HDCP support for ${MIN_4K_HDCP_VERSION}${robustnessStr}`);
                    } else {
                        callSuccessful = true;
                        isSupported = true;
                        logger.info!(`HDCP is supported for ${MIN_4K_HDCP_VERSION}${robustnessStr}`);
                    }
                } catch (error) {
                    logger.warn!(`HDCP support cannot be determined for ${MIN_4K_HDCP_VERSION}${robustnessStr}: ${error}`);
                }
            }
        } catch (error) {
            logger.warn!(`Error getting mediaKeySystemAccess/mediaKeys for ${codecString} : ${error}`);
        }
    } else {
        logger.warn!(`navigator.requestMediaKeySystemAccess does not exist`);
    }

    return { callSuccessful, isSupported };
}

const getMediaKeysHelper = (keySystems: string[], config: Array<MediaKeySystemConfiguration>): Promise<MediaKey[]> => {
    const promises = keySystems.map((keySystem) =>
        navigator
            ?.requestMediaKeySystemAccess(keySystem, config)
            .then((access) => access?.createMediaKeys())
            .catch(() => false)
    );
    return Promise.all(promises) as Promise<MediaKey[]>;
};

const drmToHDCPVersion = {
    [DrmType.Widevine]: {
        keySystems: ['com.widevine.alpha'],
        config: [
            {
                videoCapabilities: [
                    {
                        contentType: 'video/mp4; codecs="avc1.42E01E"',
                        robustness: 'SW_SECURE_DECODE', // Widevine L3
                    },
                ],
            },
        ],
        getMediaKeys: function () {
            return getMediaKeysHelper(this.keySystems, this.config);
        },
    },
    [DrmType.PlayReady]: {
        keySystems: ['com.microsoft.playready'],
        config: [{ videoCapabilities: [{ contentType: 'video/mp4; codecs="avc1.42E01E"' }] }],
        getMediaKeys: function () {
            return getMediaKeysHelper(this.keySystems, this.config);
        },
    },
    [DrmType.Fairplay]: {
        keySystems: [],
        getMediaKeys: async function (): Promise<MediaKey[]> {
            return [true];
        },
    },
    [DrmType.Marlin]: {
        keySystems: [],
        getMediaKeys: async function (): Promise<MediaKey[]> {
            return [true];
        },
    },
};

const isMediaKeyUsable = async (mediaKey: MediaKey): Promise<boolean> => {
    if (!mediaKey) {
        return false;
    }
    if (mediaKey === true) {
        return true;
    }
    const statusForPolicy = await mediaKey.getStatusForPolicy({ minHdcpVersion: MIN_HD_HDCP_VERSION });
    return statusForPolicy === StatusPolicies.USABLE;
};

const probeHDCPStatusForHD = async (mediaKeys: MediaKey[]): Promise<boolean> => {
    const logger: Partial<Logger> = sdkLogger.withContext('PRB');

    if (!mediaKeys) {
        return false;
    }

    try {
        for (const mediaKey of mediaKeys) {
            if (await isMediaKeyUsable(mediaKey)) {
                return true;
            }
        }
    } catch (err) {
        logger.warn!(`mediaKey.getStatusForPolicy() failed: ${err}`);
    }
    return false;
};

export async function getHDCPStatusForHD(drmType?: DrmType): Promise<boolean> {
    // If there is no DRM type then it is by default compliant and HD should be enabled
    if (drmType === undefined) {
        return true;
    }
    const drmHDCPVersion = drmToHDCPVersion[drmType];
    const mediaKeys = await drmHDCPVersion.getMediaKeys();
    return probeHDCPStatusForHD(mediaKeys);
}

export function areCodecsCompatible(codec1: string | null | undefined, codec2: string | null | undefined): boolean {
    return getCodecBase(codec1) === getCodecBase(codec2);
}

function getCodecBase(codec: string | null | undefined): string {
    return codec ? codec.split('.')[0] : '';
}
