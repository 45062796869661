import type { VideoStartupOperations } from './video-startup-operations';
import { VideoStartupStates } from './video-startup.enums';
export { VideoStartupStates } from './video-startup.enums';

export class VideoStartupState implements VideoStartupOperations {
    private _name: VideoStartupStates;

    constructor(name: VideoStartupStates) {
        this._name = name;
    }

    public getName(): string {
        return this._name;
    }

    private buildInvalidOperation(actionName: string): string {
        const state = this.getName();
        return `Invalid operation: can't ${actionName} while in ${state} State`;
    }

    public initialise = (): VideoStartupState => {
        const actionName = 'Initialise';
        throw new Error(this.buildInvalidOperation(actionName));
    };

    public callVideoProvider = (): VideoStartupState => {
        const actionName = 'Call Video Provider';
        throw new Error(this.buildInvalidOperation(actionName));
    };

    public initialiseAdverts = (): VideoStartupState => {
        const actionName = 'Initialise Adverts Manager';
        throw new Error(this.buildInvalidOperation(actionName));
    };

    public callVam = (): VideoStartupState => {
        const actionName = 'Call Vam';
        throw new Error(this.buildInvalidOperation(actionName));
    };

    public bootstrapAds = (): VideoStartupState => {
        const actionName = 'Bootstrap Ads';
        throw new Error(this.buildInvalidOperation(actionName));
    };

    public callAdsData = (): VideoStartupState => {
        const actionName = 'Call Advertisement';
        throw new Error(this.buildInvalidOperation(actionName));
    };

    public startEngine = (): VideoStartupState => {
        const actionName = 'Start Engine';
        throw new Error(this.buildInvalidOperation(actionName));
    };

    public skipAdvertisement = (): VideoStartupState => {
        const actionName = 'Skip Advertisement';
        throw new Error(this.buildInvalidOperation(actionName));
    };

    public destroy = (): VideoStartupState => {
        const actionName = 'Destroy';
        throw new Error(this.buildInvalidOperation(actionName));
    };

    public discard = (): VideoStartupState => {
        const actionName = 'Discard';
        throw new Error(this.buildInvalidOperation(actionName));
    };

    public retry = (): VideoStartupState => {
        const actionName = 'Retry';
        throw new Error(this.buildInvalidOperation(actionName));
    };

    public toString = () => {
        return this.getName();
    };

    public type = (): VideoStartupStates => {
        const state = this.getName();
        return VideoStartupStates[state as keyof typeof VideoStartupStates];
    };
}

class DestroyableVideoStartupState extends VideoStartupState {
    public destroy = (): VideoStartupState => {
        return new DestroyedState(VideoStartupStates.Destroyed);
    };
}

export class UninitialisedState extends DestroyableVideoStartupState {
    public initialise = (): VideoStartupState => {
        return new InitialisedState(VideoStartupStates.Initialised);
    };
}

export class InitialisedState extends DestroyableVideoStartupState {
    public callVideoProvider = (): VideoStartupState => {
        return new VideoProviderCalledState(VideoStartupStates.VideoProviderCalled);
    };

    public discard = (): VideoStartupState => {
        return new DiscardedState(VideoStartupStates.Discarded);
    };
}

export class VideoProviderCalledState extends DestroyableVideoStartupState {
    public initialiseAdverts = (): VideoStartupState => {
        return new AdvertsInitialisedState(VideoStartupStates.AdvertsInitialised);
    };

    public discard = (): VideoStartupState => {
        return new DiscardedState(VideoStartupStates.Discarded);
    };

    // If there's no advertisement at all
    public startEngine = (): VideoStartupState => {
        return new EngineStartedState(VideoStartupStates.EngineStarted);
    };
}

export class AdvertsInitialisedState extends DestroyableVideoStartupState {
    public callVam = (): VideoStartupState => {
        return new VamCalledState(VideoStartupStates.VamCalled);
    };

    // Now TV does not call VAM so it can call Advertisement
    // after calling VPI/OVP
    public bootstrapAds = (): VideoStartupState => {
        return new BootstrapAdsState(VideoStartupStates.AdsBootstrapped);
    };

    // Semantically different from the previous one
    // even if we go to the same state
    public skipAdvertisement = (): VideoStartupState => {
        return new AdInsertionCalledState(VideoStartupStates.AdsInsertionCalled);
    };
}

export class VamCalledState extends DestroyableVideoStartupState {
    public bootstrapAds = (): VideoStartupState => {
        return new BootstrapAdsState(VideoStartupStates.AdsBootstrapped);
    };

    public callAdsData = (): VideoStartupState => {
        return new AdInsertionCalledState(VideoStartupStates.AdsInsertionCalled);
    };

    public skipAdvertisement = (): VideoStartupState => {
        return new AdInsertionCalledState(VideoStartupStates.AdsInsertionCalled);
    };

    public discard = (): VideoStartupState => {
        return new DiscardedState(VideoStartupStates.Discarded);
    };
}

export class BootstrapAdsState extends DestroyableVideoStartupState {
    public callAdsData = (): VideoStartupState => {
        return new AdInsertionCalledState(VideoStartupStates.AdsInsertionCalled);
    };

    // Semantically different from the previous one
    // even if we go to the same state
    public skipAdvertisement = (): VideoStartupState => {
        return new AdInsertionCalledState(VideoStartupStates.AdsInsertionCalled);
    };

    public discard = (): VideoStartupState => {
        return new DiscardedState(VideoStartupStates.Discarded);
    };
}

export class AdInsertionCalledState extends DestroyableVideoStartupState {
    public startEngine = (): VideoStartupState => {
        return new EngineStartedState(VideoStartupStates.EngineStarted);
    };

    public discard = (): VideoStartupState => {
        return new DiscardedState(VideoStartupStates.Discarded);
    };
}

export class EngineStartedState extends DestroyableVideoStartupState {
    public discard = (): VideoStartupState => {
        return new DiscardedState(VideoStartupStates.Discarded);
    };

    public destroy = (): VideoStartupState => {
        return new DestroyedState(VideoStartupStates.Destroyed);
    };

    public retry = (): VideoStartupState => {
        return new RetriedState(VideoStartupStates.Retried);
    };
}

export class DiscardedState extends DestroyableVideoStartupState {
    // We might want to initialise if a prefetch
    // scenario failed or even if an "initialised"
    // scenario (non-prefetch) failed and we want to
    // retry the start of the session
    public initialise = (): VideoStartupState => {
        return new InitialisedState(VideoStartupStates.Initialised);
    };
}

export class DestroyedState extends VideoStartupState {
    // We may enter a destroyed state if the prefetch is canceled by client,
    // Or when a cache expires
    public initialise = (): VideoStartupState => {
        return new InitialisedState(VideoStartupStates.Initialised);
    };

    public retry = (): VideoStartupState => {
        return new RetriedState(VideoStartupStates.Retried);
    };
}

export class RetriedState extends VideoStartupState {
    public initialise = (): VideoStartupState => {
        return new InitialisedState(VideoStartupStates.Initialised);
    };

    public destroy = (): VideoStartupState => {
        return new DestroyedState(VideoStartupStates.Destroyed);
    };
}
