import { SCTE35 } from '@sky-uk-ott/core-video-sdk-js-external-scte35';
import type { ISegmentationDescriptor, ISpliceDescriptor } from '@sky-uk-ott/core-video-sdk-js-external-scte35/lib/descriptors';
import type { ISpliceInfoSection } from '@sky-uk-ott/core-video-sdk-js-external-scte35/lib/ISCTE35';
import { parse } from '@sky-uk-ott/core-video-sdk-js-external-txml/dist/txml';

import type { tNode } from './txml-types';
import { SegmentationTypeId, SegmentationUpidType, SpliceDescriptorTag } from './scte35.enums';
export { SegmentationTypeId, SegmentationUpidType, SpliceDescriptorTag } from './scte35.enums';

const FREQUENCY_KHZ = 90000;

interface AdDataSegDescriptor {
    contentId: string;
    duration: number;
}

export class Scte35Parser {
    public extractScteBase64FromDashSignal(xmlString: string): string | null {
        const xmlScte = `<scte xmlns:scte35="http://www.w3.org/2001/XMLSchema">${xmlString}</scte>`;
        const signalXml = parse(xmlScte)[0];

        if (typeof signalXml === 'string') return null;

        const signalElement = (signalXml as tNode).children.filter((child) => typeof child !== 'string' && child.tagName === 'Signal')[0];

        if (!signalElement || typeof signalElement === 'string') {
            return null;
        }

        const binaryElement = (signalElement as tNode).children.find((e) => typeof e !== 'string' && (e as tNode).tagName === 'Binary');

        if (!binaryElement || binaryElement instanceof String) {
            return null;
        }

        return (binaryElement as tNode).children.find((elem) => typeof elem === 'string') as string;
    }

    public parseAdDataFromScte35Base64(b64binaryData: string): AdDataSegDescriptor | null {
        const scte35Data = this.parseScte35Base64(b64binaryData);

        const adStartSegDescriptor = this.extractSegDescriptor(scte35Data, SegmentationTypeId.PROVIDER_ADVERTISEMENT_START);

        return adStartSegDescriptor ? this.extractAdDataFromAdStartSegDescriptor(adStartSegDescriptor) : null;
    }

    private parseScte35Base64(b64binaryData: string): ISpliceInfoSection {
        const scte35 = new SCTE35();
        return scte35.parseFromB64(b64binaryData);
    }

    private extractSegDescriptor(scteData: ISpliceInfoSection, segTypeId: SegmentationTypeId): ISegmentationDescriptor | void {
        const segmentationDescriptors = scteData.descriptors?.filter(this.isSegDescriptor);

        if (!segmentationDescriptors) {
            return;
        }

        return segmentationDescriptors.find((d) => (d.segmentationTypeId as unknown as SegmentationTypeId) === segTypeId);
    }

    private extractAdDataFromAdStartSegDescriptor(descriptor: ISegmentationDescriptor): AdDataSegDescriptor | null {
        const segUpId = String.fromCharCode.apply(null, [...descriptor.segmentationUpid!]);

        // TODO delete code above on the method and uncomment the following
        // when we use typescript 2.8+

        // const decoder = new TextDecoder();
        // const segUpId = decoder.decode(descriptor.segmentationUpid);

        let contentId: string | null = null;

        switch (descriptor.segmentationUpidType as unknown as SegmentationUpidType) {
            case SegmentationUpidType.MPU: {
                // TODO: Need a stream to test this use case for MPU
                // MPU should only be for SLE so we need to test for that
                const proposition = segUpId.substring(0, 4);
                if (proposition === 'NBCU') {
                    const nbcuData: { assetId: string; cueData: Object } = JSON.parse(segUpId.substring(4, segUpId.length));
                    contentId = nbcuData.assetId;
                }
                break;
            }
            case SegmentationUpidType.ADS:
                contentId = segUpId;
                break;
            default:
                break;
        }

        if (!contentId) {
            return null;
        }

        return {
            contentId,
            // NOTE the following gives us the duration in seconds
            duration: descriptor.segmentationDuration! / FREQUENCY_KHZ,
        };
    }

    private isSegDescriptor(descriptor: ISpliceDescriptor): descriptor is ISegmentationDescriptor {
        return (descriptor.spliceDescriptorTag as unknown as SpliceDescriptorTag) === SpliceDescriptorTag.SEGMENTATION_DESCRIPTOR;
    }
}
