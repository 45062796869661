import type { AddonConfig, PineConfig } from '../../internal-config';
import type { RemoteConfigSplit } from '../../sdk-config';
import type { DeclaredOptionalsAddonsConfig, InternalAddonsConfig, RequiredPropertiesDefinition } from './addons-config-adapter-common';
import { genericConfigEnabledCheck } from './addons-config-adapter-common';

// Internal Config
type PineInternalConfig = InternalAddonsConfig['pine'];

// Client Config when Remote Config is disabled
export type PineClientConfig = AddonConfig<PineConfig>;
export function adaptPineClientConfig(config: PineClientConfig | undefined): PineInternalConfig {
    return config;
}

// Server Config when Remote Config is enabled
type PineRemoteServerConfig = AddonConfig<{}>;

// Client Config when Remote Config is enabled
type PineRemoteClientConfig = PineConfig;

export type PineRemoteConfigSplit = RemoteConfigSplit<PineRemoteServerConfig, PineRemoteClientConfig>;
export function adaptPineRemoteConfigSplit(config: PineRemoteConfigSplit): DeclaredOptionalsAddonsConfig<PineInternalConfig> {
    const requiredFields: RequiredPropertiesDefinition<PineRemoteClientConfig> = { clientId: null };
    const isEnabled = genericConfigEnabledCheck(config, requiredFields, 'Pine');
    if (!isEnabled) {
        return;
    }

    const { clientConfig } = config;
    return {
        enabled: isEnabled,
        config: {
            clientId: clientConfig!.clientId,
            trunkHost: clientConfig!.trunkHost,
            enableMocks: clientConfig!.enableMocks,
            enableMockAdverts: clientConfig!.enableMockAdverts,
            mockOnly: clientConfig!.mockOnly,
        },
    };
}
