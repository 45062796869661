export class SimpleTimer {
    private timer: ReturnType<typeof setTimeout> | null = null;

    public stop() {
        if (this.timer !== null) {
            clearTimeout(this.timer);
        }
    }

    public destroy() {
        this.stop();
    }

    public start(config: { timeMs: number; callback: () => void }) {
        this.stop();
        this.timer = setTimeout(config.callback, config.timeMs);
    }
}
