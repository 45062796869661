import type { WatermarkingConfig } from '../../../addons/watermarking/watermarking-addon';
import type { AddonConfig } from '../../internal-config';
import type { RemoteConfigSplit } from '../../sdk-config';
import type { DeclaredOptionalsAddonsConfig, InternalAddonsConfig } from './addons-config-adapter-common';

// Internal Config
type WatermarkingInternalConfig = InternalAddonsConfig['watermarking'];

// Client Config when Remote Config is disabled
export type WatermarkingClientConfig = AddonConfig<WatermarkingConfig>;
export function adaptWatermarkingClientConfig(config: WatermarkingClientConfig | undefined): WatermarkingInternalConfig {
    return config;
}

// Server Config when Remote Config is enabled
type WatermarkingRemoteServerConfig = AddonConfig<{}>;

// Client Config when Remote Config is enabled
type WatermarkingRemoteClientConfig = WatermarkingConfig;

export type WatermarkingRemoteConfigSplit = RemoteConfigSplit<WatermarkingRemoteServerConfig, WatermarkingRemoteClientConfig>;
export function adaptWatermarkingRemoteConfigSplit(config: WatermarkingRemoteConfigSplit): DeclaredOptionalsAddonsConfig<WatermarkingInternalConfig> {
    return {
        enabled: Boolean(config.remoteConfig?.enabled),
        config: {
            type: config.clientConfig?.type,
            asid: config.clientConfig?.asid,
            isRawMode: config.clientConfig?.isRawMode,
            watermarkingDeferTimeMs: config.clientConfig?.watermarkingDeferTimeMs,
        },
    };
}
