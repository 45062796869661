import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';

import type { PlayoutData } from '../../core/player/playout-data';
import type { InternalSessionInterface } from '../../core/session-controller/session-controller-internal';
import { sdkLogger } from '../../logger';
import type { BaseSsaiAdBreaksProvider } from '../../players/bolt-ons/ad-breaks-provider/base-ssai-ad-breaks-provider';
import type { AdPolicyManager } from '../../propositions/ad-policy/ad-policy-manager';
import { JsonUtils } from '../../utils/json-utils';

import type { AdInsertionAddon } from '../addons-factories';
import type { Ad, AdBreak } from '../adverts/common';
import { AdBreakType } from '../adverts/common';
import type { PauseAdvertDispatcher } from '../adverts/pause-adverts/pause-advert-dispatcher';
import type { ServerAdvertsAddon } from '../adverts/server-adverts/server-adverts-addon';
import { JsonAdBreaksProvider } from '../adverts/vod-adverts/json-ad-breaks-provider';
import { TimelineAdvertsAddon } from '../adverts/vod-adverts/timeline-adverts-addon';
import type { PlayerCapabilities } from '../../core/player/player-engine';

export class AdvertsInsertionStageManager {
    private logger: Logger = sdkLogger.withContext('AdvertsInsertionStageManager');

    private timelineAdsAddon: TimelineAdvertsAddon | null = null;
    private currentAdBreak: AdBreak<Ad> | null = null;
    private currentAd: Ad | null = null;

    constructor(
        private session: InternalSessionInterface,
        private playoutData: PlayoutData,
        private pauseAdvertDispatcher: PauseAdvertDispatcher | null,
        private adPolicyManager: AdPolicyManager | null,
        private adBreaksProvider: BaseSsaiAdBreaksProvider | null,
        private adInsertionAddon: AdInsertionAddon | null,
        private ssaiAddon?: ServerAdvertsAddon | undefined
    ) {
        this.registerAdvertsEvents();
    }

    private registerAdvertsEvents() {
        this.session.onAdBreakStarted((adBreak: AdBreak<Ad>) => {
            this.currentAdBreak = adBreak;
        });

        this.session.onAdStarted((ad: Ad) => {
            this.currentAd = ad;
        });

        this.session.onAdFinished(() => {
            this.currentAd = null;
        });

        this.session.onAdBreakFinished((adBreak: AdBreak) => {
            if (this.playoutData!.advertising?.vac?.sleCsai && adBreak.type === AdBreakType.Preroll) {
                this.ssaiAddon?.setDispatchDeferred(false);
            }
            this.currentAdBreak = null;
        });
    }

    public async fetchAdvertisementData(
        populatedPlayoutData: PlayoutData,
        initialPlayoutData: PlayoutData,
        playerAdsCapabilities: Pick<PlayerCapabilities, 'supportsNativeCsai'>
    ) {
        this.playoutData = populatedPlayoutData;

        if (populatedPlayoutData.adsFailoverReason) {
            this.logger.warn(
                `Error Fetching Advertising data. Destroying Adverts Manager: ${JsonUtils.stringify(populatedPlayoutData.adsFailoverReason)}`
            );
            return populatedPlayoutData;
        }

        this.adInsertionAddon?.setAdvertising(populatedPlayoutData.advertising!);

        // "Modified" playout data has had existing properties modified by the addons e.g. CDN url
        let modifiedPlayoutData: PlayoutData = populatedPlayoutData;

        if (initialPlayoutData.vamMidrollEnabled === false) {
            this.logger.warn('Midrolls disabled by OVP. Using initial playout data.');
        } else {
            modifiedPlayoutData = await this.modifyPlayoutData(populatedPlayoutData);
        }

        this.adPolicyManager?.initialise(this.session.onPlaybackTimelineUpdated.bind(this.session));
        this.adBreaksProvider?.setPlayoutData(modifiedPlayoutData);
        const sleCsai = modifiedPlayoutData.advertising?.vac?.sleCsai;

        if (sleCsai) {
            // Preroll should only have one avail
            const hasSleCsaiAds = sleCsai?.avails?.[0]?.ads.length > 0;
            if (hasSleCsaiAds) {
                this.ssaiAddon?.setDispatchDeferred(true);
            }
            if (modifiedPlayoutData.adInsertionConfig && !playerAdsCapabilities.supportsNativeCsai) {
                modifiedPlayoutData.adInsertionConfig.isTimelineAdManagementRequired = true;
            }
            this.timelineAdsAddon = new TimelineAdvertsAddon(
                new JsonAdBreaksProvider(),
                this.session,
                initialPlayoutData.type,
                this.pauseAdvertDispatcher!,
                initialPlayoutData
            );
            this.timelineAdsAddon.setAdvertising(modifiedPlayoutData.advertising!);
        }
        this.playoutData = modifiedPlayoutData;
        return modifiedPlayoutData;
    }

    private async modifyPlayoutData(populatedPlayoutData: PlayoutData): Promise<PlayoutData> {
        if (this.ssaiAddon) {
            try {
                return await this.ssaiAddon.getModifiedPlayout(populatedPlayoutData);
            } catch (e) {
                const message = 'Error getting modified playout data, ads may not be shown';
                this.logger.warn(message, JsonUtils.stringify(e));
                const code = 'SDK.ADVERTSMGR.SETUP_ADS.SSAI.GET_MODIFIED_PLAYOUT_DATA';
                this.session.notifyWarning(code, message);
                populatedPlayoutData.adsFailoverReason = this.ssaiAddon.getAdsFailoverReason();

                return populatedPlayoutData;
            }
        }

        return populatedPlayoutData;
    }

    public getTimelineAdsAddon(): TimelineAdvertsAddon | null {
        return this.timelineAdsAddon;
    }

    public getCurrentAd(): Ad | null {
        return this.currentAd;
    }

    public getCurrentAdBreak(): AdBreak<Ad> | null {
        return this.currentAdBreak;
    }

    public destroy(): void {
        this.currentAd = null;
        this.currentAdBreak = null;
        this.timelineAdsAddon?.destroy();
        this.timelineAdsAddon = null;
    }
}
