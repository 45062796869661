import type { Device, DeviceInformation, DeviceType } from '@sky-uk-ott/client-lib-js-device';

import type { AddonsFactories } from '../addons/addons-factories';
import type { AddonLoader } from '../addons/loader/addon-loader';
import type { AsidVersion } from '../addons/watermarking/friend-watermarking-addon/friends-watermarking-config';
import type { InternalConfigPerPlaybackType } from '../config/internal-config';
import { Proposition } from '../config/internal-config';
import type { CoordinatedBitrateCapping } from '../core/meta-list-manager/bitrate-cap-manager';
import type { PlayerType } from '../core/player/player-type';
import type { DrmProvider, DrmType, PlaybackType, PlayerPlayoutData } from '../core/player/playout-data';
import type { StreamCapabilitySpecification } from '../core/player/stream-capability-specification';
import type { TextTrackMimeType } from '../core/player/text-track-mime-type';
import type { SecurityLevelBasedCapManager } from '../drm/security-level-based-cap-manager';
import { sdkLogger } from '../logger';
import type { ThumbnailConfig } from '../players/player-extensions/thumbnails/thumbnail-types';
import type { DeviceShakaConfig } from '../players/shaka/shaka-configuration-helper';
import type { DeviceTheoConfig } from '../players/theo/configuration/theo-configuration-helper';
import type { VideoPlatformIntegrationProvider } from '../video-platforms/integration-provider';
import type { AdPolicy } from './ad-policy/ad-policy';
import { getDefaultDevicePlayerMap } from './default-device-player-map';
import type { WatermarkingPolicy } from './watermarking-policy/watermarking-policy';
import type { SessionItem } from '../core/session-controller/session-controller';
import type { DeviceTapeConfig } from '../players/tape/tape-player-configuration-helper';

export type propositionOvpConfig = {
    cdnParameters?: {
        audio?: boolean;
        audioCodec?: boolean;
        subtitle?: boolean;
        forcedNarrative?: boolean;
        trickplay?: boolean;
    };
    fxbwReplacement?: {
        [key in DeviceType]?: boolean;
    };
    watermarking?: {
        version?: AsidVersion;
    };
    drm?: {
        excludeCertificateUrl?: boolean;
    };
    endpoints?: {
        [key in PlaybackType]?: {
            shouldApplyLinearEndpointModifications?: boolean;
            variantCapable?: boolean;
        };
    };
};

export type PropositionPlayerConfig = {
    [PlayerType.Chromecast]?: DeviceChromecastConfig;
    [PlayerType.Shaka]?: DeviceShakaConfig;
    [PlayerType.Theo]?: DeviceTheoConfig;
    [PlayerType.Tape]?: DeviceTapeConfig;
    [PlayerType.WebMaf]?: DeviceWebMafConfig;
    [PlayerType.Android]?: DeviceAndroidTVConfig;
};

export type DeviceChromecastConfig = {
    /** Sets total playback duration as only asset duration, not asset duration + ad duration (Chromecast default) */
    overrideCafDuration?: boolean;
    subtitlesFormat?: TextTrackMimeType[];
    /**
     * If true, queue item will be prefetched when the previous queue item is nearing completion
     */
    prefetchQueueItems?: boolean;
};

export type DeviceWebMafConfig = {
    seekPlayTimeSanitiser?: (position: number) => number;
    shouldFilterAudioCodecs?: boolean;
    outOfBoundManifestRequest?: boolean;
};

export type DeviceAndroidTVConfig = {
    omitRegionCodeFromAudioTrack?: boolean;
};

export type PropositionDrmConfig = {
    [key in DrmType]?: {
        provider: DrmProvider;
    };
};

export type DevicePlaybackCapabilities = {
    slePrerollSupported?: boolean;
    getNumberOfAudioChannels: (playoutData: PlayerPlayoutData, supportsCodecSwitching: boolean) => number;
};

export type SleThumbnails = {
    allowed: boolean;
};

export type AdsDisabled = {
    [key in DeviceType]?: (sessionItem: SessionItem) => boolean;
};

/**
 * @public
 */
export interface PropositionExtensions {
    addonLoaders: Array<AddonLoader>;
    addonFactories: AddonsFactories; // will pass proposition specific config for addons (e.g. reporting tag adapters )
    propositionOvpConfig?: propositionOvpConfig; // will pass proposition specific config for ovp (e.g ovp data adapter)
    propositionPlayerConfig?: PropositionPlayerConfig; // will pass proposition specific config for players
    propositionDrmConfig?: PropositionDrmConfig;
    vpiProvider?: VideoPlatformIntegrationProvider;
    devicePlaybackCapabilities: DevicePlaybackCapabilities;
    thumbnailConfig?: ThumbnailConfig;
    adPolicy?: { new (): AdPolicy };
    streamCapabilitySpecifications?: {
        [key in PlaybackType | 'default']?: Array<StreamCapabilitySpecification>;
    };
    watermarkingPolicy?: WatermarkingPolicy;
    coordinatedBitrateCapping?: CoordinatedBitrateCapping;
    securityLevelBasedCapManager?: SecurityLevelBasedCapManager;
    sleThumbnails?: SleThumbnails;
    adsDisabled?: AdsDisabled;
    highFramerateValue: 50 | 60; // used for comparisons to enable high rate playback
}

type DevicePlayerValueType = {
    default: PlayerType;
    native?: PlayerType;
};

export type DevicePlayerMapType = { [key in DeviceType]: DevicePlayerValueType | null }; // Exclude is temporary until the device library is updated

type LoaderType = (config: InternalConfigPerPlaybackType, device: Device) => Promise<PropositionExtensions>;

/**
 * @public
 */
export async function loadPropositionExtensions(
    p: Proposition,
    internalConfigPerPlaybackType: InternalConfigPerPlaybackType,
    device: Device
): Promise<PropositionExtensions> {
    try {
        return await propositionExtensions[p].loadExtensions(internalConfigPerPlaybackType, device);
    } catch (e) {
        sdkLogger.error(`loadPropositionExtensions: failed to get extensions for proposition ${p}.`);
        throw e;
    }
}

type PropositionExtensionItems = {
    getDevicePlayerMap: (deviceInfo?: DeviceInformation, deviceType?: DeviceType) => DevicePlayerMapType;
    loadExtensions: LoaderType;
};

type PropositionExtensionItemType = {
    [key in Proposition]: PropositionExtensionItems;
};

export const getDevicePlayerMap = (proposition: Proposition, deviceInfo?: DeviceInformation, deviceType?: DeviceType): DevicePlayerMapType => {
    if (!propositionExtensions[proposition]) {
        sdkLogger.warn(`getDevicePlayerMap: map for proposition ${proposition} is not found. Using default map.`);
        return getDefaultDevicePlayerMap(deviceInfo);
    }
    return propositionExtensions[proposition].getDevicePlayerMap(deviceInfo, deviceType);
};

const propositionExtensions: PropositionExtensionItemType = {
    [Proposition.OneApp]: {
        getDevicePlayerMap: getDefaultDevicePlayerMap,
        loadExtensions: loadOneAppExtensions,
    },
    [Proposition.Peacock]: {
        getDevicePlayerMap: getDefaultDevicePlayerMap,
        loadExtensions: loadPeacockExtensions,
    },
    [Proposition.Showmax]: {
        getDevicePlayerMap: getDefaultDevicePlayerMap,
        loadExtensions: loadShowmaxExtensions,
    },
    [Proposition.NOWOTT]: {
        getDevicePlayerMap: getDefaultDevicePlayerMap,
        loadExtensions: loadNowExtensions,
    },
    [Proposition.NowTV]: {
        getDevicePlayerMap: getDefaultDevicePlayerMap,
        loadExtensions: loadNowTvExtensions,
    },
    [Proposition.SkyStore]: {
        getDevicePlayerMap: getDefaultDevicePlayerMap,
        loadExtensions: loadSkyStoreExtensions,
    },
    [Proposition.SkyShowtime]: {
        getDevicePlayerMap: getDefaultDevicePlayerMap,
        loadExtensions: loadSkyShowtimeExtensions,
    },
    [Proposition.NBC]: {
        getDevicePlayerMap: getDefaultDevicePlayerMap,
        loadExtensions: loadNBCExtensions,
    },
};

async function loadOneAppExtensions(config: InternalConfigPerPlaybackType, device: Device): Promise<PropositionExtensions> {
    const { load } = await import(/* webpackChunkName: "sdk-ext-oneapp" */ './oneapp/loader');

    return load(config, device);
}

async function loadPeacockExtensions(config: InternalConfigPerPlaybackType, device: Device): Promise<PropositionExtensions> {
    const { load } = await import(/* webpackChunkName: "sdk-ext-peacock" */ './peacock/loader');

    return load(config, device);
}

async function loadShowmaxExtensions(config: InternalConfigPerPlaybackType, device: Device): Promise<PropositionExtensions> {
    const { load } = await import(/* webpackChunkName: "sdk-ext-showmax" */ './showmax/loader');

    return load(config, device);
}

async function loadSkyShowtimeExtensions(config: InternalConfigPerPlaybackType, device: Device): Promise<PropositionExtensions> {
    const { load } = await import(/* webpackChunkName: "sdk-ext-skyshowtime" */ './skyshowtime/loader');

    return load(config, device);
}

async function loadNowExtensions(config: InternalConfigPerPlaybackType, device: Device): Promise<PropositionExtensions> {
    const { load } = await import(/* webpackChunkName: "sdk-ext-nowott" */ './nowott/loader');

    return load(config, device);
}

async function loadNowTvExtensions(config: InternalConfigPerPlaybackType, device: Device): Promise<PropositionExtensions> {
    const { load } = await import(/* webpackChunkName: "sdk-ext-nowtv" */ './nowtv/loader');

    return load(config, device);
}

async function loadSkyStoreExtensions(config: InternalConfigPerPlaybackType, device: Device): Promise<PropositionExtensions> {
    const { load } = await import(/* webpackChunkName: "sdk-ext-skystore" */ './skystore/loader');

    return load(config, device);
}

async function loadNBCExtensions(config: InternalConfigPerPlaybackType, device: Device): Promise<PropositionExtensions> {
    const { load } = await import(/* webpackChunkName: "sdk-ext-nbc" */ './nbc/loader');

    return await load(config, device);
}
