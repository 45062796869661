export enum InternalSessionState {
    Initialized = 'Initialized',
    Loading = 'Loading',
    PlayerLoading = 'PlayerLoading',
    Playing = 'Playing',
    Paused = 'Paused',
    Rebuffering = 'Rebuffering',
    Seeking = 'Seeking',
    Stopping = 'Stopping',
    Stopped = 'Stopped',
    Finished = 'Finished',
    WaitingForPin = 'WaitingForPin',
    CsaiTransition = 'CsaiTransition',
}

/**
 * @public
 */
export enum PinRequiredCause {
    Invalid = 'Invalid',
    PinServiceDown = 'PinServiceDown',
    Required = 'Required',
}

/**
 * @public
 */
export enum AddonSdks {
    NIELSEN = 'Nielsen',
    AGF_NIELSEN = 'AGF Nielsen',
    COMSCORE = 'Comscore',
}

/**
 * Enum that represents the different states of a session's lifecycle
 *
 * @remarks
 * Used by the SessionController{@link SessionController} to represent the current state of a session
 *
 * @public
 */
export enum SessionState {
    /**
     * The state set at the start of the a new session lifecycle (right after the {@link SessionController}'s constructor has been invoked)
     */
    Initialized = 'Initialized',

    /**
     * The state expected after the {@link SessionController}'s constructor has been invoked but before any Addons have been initialised
     */
    Loading = 'Loading',

    /**
     * The content is playing (whether is be program content or pre/mid/end ad content)
     */
    Playing = 'Playing',

    /**
     * A pause event has been initiated
     */
    Paused = 'Paused',

    /**
     * The content is rebuffering
     */
    Rebuffering = 'Rebuffering',

    /**
     * The player is seeking to a new point in the content timeline
     */
    Seeking = 'Seeking',

    /**
     * The process of stopping playback has been initiated by the player
     */
    Stopping = 'Stopping',

    /**
     * The content has stopped playing
     */
    Stopped = 'Stopped',

    /**
     * Playback has reached the end of the timeline
     */
    Finished = 'Finished',

    /**
     * @internal Not currently in use
     */
    WaitingForPin = 'WaitingForPin',
}

export enum TrackableEvent {
    VideoTrackChanged = 'VideoTrackChanged',
    AudioTrackChanged = 'AudioTrackChanged',
    SessionStateChanged = 'SessionStateChanged',
}
