import type { DeviceInformation } from '@sky-uk-ott/client-lib-js-device';

import type { AdvertisingData, AssetMetadata, ReportingData, UserInfo, VideoInitiate } from '../../addons/addon-playout-data';
import type { Ad, AdBreak, AdPosition } from '../../addons/adverts/common';
import type { NonLinearAdEvent } from '../../addons/adverts/non-linear-adverts/non-linear-ad-types';
import type { Vac } from '../../addons/vac/vac-addon';
import type { AddonsConfig, InternalConfig } from '../../config/internal-config';
import type { CvsdkError } from '../../error';
import { sdkLogger } from '../../logger';
import type { ThumbnailRenderInfo, ThumbnailVariant } from '../../players/player-extensions/thumbnails/thumbnail-types';
import { JsonUtils } from '../../utils/json-utils';
import type { SkyStoreOptions } from '../../video-platforms/skystore/playout-types';
import type { AudioFormat } from '../player/audio-format';
import type { BitRateLevel, LiveWindow, SubtitleCue, VideoTrack } from '../player/player-engine-item';
import type {
    AbrConfiguration,
    DrmConfiguration,
    DvrWindowDuration,
    PlaybackType,
    PlayerBitrateLimits,
    PlayoutData,
    Source,
    StreamQuality,
    StreamingProtocol,
    PlayoutRules,
    AudioTrackMetadata,
    SubtitleTrackMetadata,
} from '../player/playout-data';
import type { Track } from '../player/track';
import type { HdcpLevel, VideoCodec, VideoColourSpace, VideoFormat, WidevineSecurityLevel } from '../player/video-format';
import type { SessionRetryConfig } from './restart/session-restart-controller';

import type {
    CompanionAdOpportunityStartedEvent,
    CompanionAdOpportunityEndedEvent,
} from '../../addons/adverts/companion-adverts/companion-advert-dispatcher';
import type { BoundaryEvent } from './event-boundary';
import { InternalSessionState } from './internal-session-state';
import type { InternalSessionInterface, PlaybackTimeline } from './session-controller-internal';
import type { SessionControllerInternalProxy } from './session-controller-internal-proxy';
import type { EventTrackType } from '../events/event-track';
import type { EventCueCallback, EventCue, OnEventCueRegisteredCallback, OnEventCueUpdatedCallback } from '../events/event-types';
import type { ItuQaSessionData } from '@sky-uk-ott/core-video-sdk-js-itu-quality-assessment';
import { type PinRequiredCause, type AddonSdks, SessionState } from './session-controller.enums';
import type { WatermarkData } from '../../addons/watermarking/base';
import type { OvpOptions } from '@sky-uk-ott/client-lib-js-ott-ovp-service';
export { PinRequiredCause, SessionState } from './session-controller.enums';

export type AddonsConfigs = {
    heartbeat?: boolean; // Often disabled by clients for Clips
    optedOutSdks: Array<AddonSdks>;
};

export interface VideoSupportVariant {
    maxVideoFormat?: VideoFormat;
    supportedColourSpaces?: Array<VideoColourSpace>;
    maxFrameRate?: number;
}

export interface LimitedDevice {
    device: Partial<DeviceInformation>;
    support: VideoSupportVariant;
}

export type VideoFormatConfig = {
    support?: VideoSupportVariant;
    deviceLimitationList?: Array<LimitedDevice>;
};

/**
 * @public
 */
export type SessionOptions = {
    sessionId?: string;
    addonsConfigOverride?: AddonsConfig;
    autoplay?: boolean;
    display?: {
        playerViewAlwaysPresentedFullScreen?: boolean;
    };
    muted?: boolean;
    preferredAudioMetadata?: Array<AudioTrackMetadata>;
    preferredSubtitleMetadata?: Array<SubtitleTrackMetadata>;
    preferredAudioLanguages?: Array<string>;
    preferredSubtitlesLanguages?: Array<string>;
    abrConfiguration?: AbrConfiguration;
    drmConfiguration?: DrmConfiguration;
    reporting?: ReportingData;
    source?: Source;
    startPosition?: number;
    disableBookmarking?: boolean;
    user?: UserInfo;
    vac?: Vac.ClientData;
    isMiniPlayer?: boolean;
    slePrerollEnabled?: boolean;
    isMultiview?: boolean;
    parallelAudioTracks?: number;
    minimumSamsungSdkVersion?: boolean;
    playerBitrateLimits?: PlayerBitrateLimits;
    shouldUseTwoSecondFragments?: boolean;
    disableCdnOverwrite?: boolean;
    cerTestMode?: boolean;
    cerUsePredeterminedCues?: boolean;
    eventTrackType?: EventTrackType;
    preferredWindowDuration?: DvrWindowDuration;
    liveEdgeToleranceSeconds?: number;
    sessionRetryConfig?: SessionRetryConfig;
    enableQualityFailover?: boolean;
    disableCoordinatedBitrateCapping?: boolean;
    enableSleThumbnails?: boolean;

    // Overrides addon configurations
    addons?: AddonsConfigs;

    enablePeacockStreamingFormatOverride?: boolean;
    enableVariantCapableOverride?: boolean;
    adInsertion?: {
        // Optionally disables adInsertion on session start
        disabled?: boolean;
        yospace?: {
            // NowTV specific be used as a Yospace query parameter
            caid?: string;
            // Enables debug mode, so ads team can see ad requests
            // https://github.com/sky-uk/atlas-web-and-tv/pull/20061/files#r447097383
            debug?: boolean;
        };
        // Needed by Chromecast as some adInsertion properties only come in on load request
        requestParameters?: {
            deviceAdvertisingId?: string;
            deviceAdvertisingIdType?: string;
        };
        mediaTailor?: {
            caid: string;
        };
    };
    videoFormatConfig?: VideoFormatConfig;
    initialBitrateBps?: number;
    adobeMvtOptimizely?: string;
    videoInitiate?: VideoInitiate | undefined;

    /**
     * @deprecated This prop should not be used
     * Now determined internally by the SDK
     */
    forceHTTP?: boolean;
};

/**
 * @public
 */
export type RawSessionItem = SessionOptions & {
    type: PlaybackType;
    manifests: Array<string>;
    protocol: StreamingProtocol;
    drmConfiguration?: DrmConfiguration;
    advertising?: AdvertisingData;
    colourSpace?: VideoColourSpace;
    streamQuality?: StreamQuality;
};

export type OvpSessionItem = SessionOptions & OvpOptions;

export type SkyStoreSessionItem = SessionOptions & SkyStoreOptions;

/**
 * @public
 */
export type VpiSessionItem = OvpSessionItem | SkyStoreSessionItem;

// TODO: Uncomment below once the reference app is ready
/**
 * @public
 */
export type SessionItem = VpiSessionItem | RawSessionItem;

export function isRawSessionItem(sessionItem: SessionItem): sessionItem is RawSessionItem {
    return 'manifests' in sessionItem;
}

/**
 * @public
 */
export interface SessionObservers {
    // Ads
    onAdFinished?: (ad: Ad) => void;
    onAdStarted?: (ad: Ad) => void;
    onAdBreakFinished?: (adBreak: AdBreak) => void;
    onAdBreakStarted?: (adBreak: AdBreak) => void;
    onAdBreakDataReceived?: (adBreaks: Array<AdBreak>) => void;
    onAdPositionChanged?: (adPosition: AdPosition) => void;
    onPauseAd?: (event: NonLinearAdEvent) => void;

    onAudioTrackChanged?: (trackId: Track['id']) => void;
    onAvailableAudioTracksChanged?: (audioTracks: Array<Track>) => void;
    onAvailableSubtitlesTracksChanged?: (subtitleTracks: Array<Track>) => void;
    onAvailableThumbnailVariantsChanged?: (thumbnailVariants: Array<ThumbnailVariant>) => void;
    onBitrateChanged?: (bitrateLevel: BitRateLevel) => void;
    onEncodedFrameRateChanged?: (frameRate: number) => void;
    onRenderedFrameRateChanged?: (frameRate: number) => void;
    onVideoTrackChanged?: (videoTrack: VideoTrack) => void;
    onItuQaSessionData?: (ituQaSessionData: ItuQaSessionData) => void;
    onError?: (error: CvsdkError) => void;
    onMuteChanged?: (isMute: boolean) => void;
    onPlayoutDataReceived?: (playoutData: PlayoutData) => void;
    onPlayoutRulesReceived?: (playoutRules: PlayoutRules) => void;
    onPlaybackTimelineUpdated?: (playbackTimeline: PlaybackTimeline) => void;
    onSessionEnded?: () => void;
    onStateChanged?: (state: SessionState) => void;
    onSeekStarted?: (positionMs: number) => void;
    onSeekEnded?: () => void;
    onSubtitleCuesChanged?: (subtitleCues: Array<SubtitleCue>) => void;
    onSubtitlesTrackChanged?: (trackId?: Track['id']) => void;
    onVolumeChanged?: (volume: number) => void;
    onWarning?: (error: CvsdkError) => void;
    onEventBoundary?: (event: BoundaryEvent) => void;
    onEndOfEventMarkerReceived?: (eventTime: number) => void;
    onPinRequired?: (cause: PinRequiredCause) => void;
    onAutoPlayPolicyPreventedPlayback?: () => void;
    onCompanionAdOpportunityStarted?: (event: CompanionAdOpportunityStartedEvent) => void;
    onCompanionAdOpportunityEnded?: (event: CompanionAdOpportunityEndedEvent) => void;
    onRenderWatermark?: (data: WatermarkData) => void;
    onClearWatermark?: (data: WatermarkData) => void;
    onClearWatermarks?: () => void;

    onSessionRetryStarted?: () => void;
    onSessionRetryEnded?: () => void;
    onSessionRestartStarted?: () => void;
    onSessionRestartEnded?: () => void;

    onFinished?: () => void;
    onLoading?: () => void;
    onPaused?: () => void;
    onPlaying?: () => void;
    onRebuffering?: () => void;
    onSeeking?: () => void;
    onSessionStarted?: () => void;
    onStopped?: () => void;
}

const MappedSessionStateForClients: { [key in InternalSessionState]: SessionState } = {
    [InternalSessionState.Initialized]: SessionState.Initialized,
    [InternalSessionState.Loading]: SessionState.Loading,
    [InternalSessionState.PlayerLoading]: SessionState.Loading,
    [InternalSessionState.Playing]: SessionState.Playing,
    [InternalSessionState.Paused]: SessionState.Paused,
    [InternalSessionState.Rebuffering]: SessionState.Rebuffering,
    [InternalSessionState.Seeking]: SessionState.Seeking,
    [InternalSessionState.Stopping]: SessionState.Stopping,
    [InternalSessionState.Stopped]: SessionState.Stopped,
    [InternalSessionState.Finished]: SessionState.Finished,
    [InternalSessionState.WaitingForPin]: SessionState.WaitingForPin,
    [InternalSessionState.CsaiTransition]: SessionState.Playing,
};

type ValidWaitForState =
    | SessionState.Playing
    | SessionState.Paused
    | SessionState.Rebuffering
    | SessionState.Seeking
    | SessionState.Stopping
    | SessionState.Stopped
    | SessionState.Finished
    | SessionState.WaitingForPin;

const ReverseMappedSessionStateForClients: { [key in ValidWaitForState]: InternalSessionState } = {
    [SessionState.Playing]: InternalSessionState.Playing,
    [SessionState.Paused]: InternalSessionState.Paused,
    [SessionState.Rebuffering]: InternalSessionState.Rebuffering,
    [SessionState.Seeking]: InternalSessionState.Seeking,
    [SessionState.Stopping]: InternalSessionState.Stopping,
    [SessionState.Stopped]: InternalSessionState.Stopped,
    [SessionState.Finished]: InternalSessionState.Finished,
    [SessionState.WaitingForPin]: InternalSessionState.WaitingForPin,
};

/**
 * @public
 */
export class SessionController {
    public readonly getCurrentSessionState: () => SessionState;
    public readonly onStateChanged: (callback: (state: SessionState) => void) => void;
    public readonly onSessionRetryStarted: (callback: () => void) => void;
    public readonly onSessionRetryEnded: (callback: () => void) => void;
    public readonly onSessionRestartStarted: (callback: () => void) => void;
    public readonly onSessionRestartEnded: (callback: () => void) => void;
    public readonly onError: (callback: (error: CvsdkError) => void) => void;
    public readonly onWarning: (callback: (error: CvsdkError) => void) => void;
    public readonly onPlaybackTimelineUpdated: (callback: (playbackTimeline: PlaybackTimeline) => void) => void;
    public readonly onBitrateChanged: (callback: (bitrateLevel: BitRateLevel) => void) => void;
    public readonly onEncodedFrameRateChanged: (callback: (frameRate: number) => void) => void;
    public readonly onRenderedFrameRateChanged: (callback: (frameRate: number) => void) => void;
    public readonly onVideoTrackChanged: (callback: (videoTrack: VideoTrack) => void) => void;
    public readonly onItuQaSessionData: (callback: (ituQaSessionData: ItuQaSessionData) => void) => void;
    public readonly onAvailableAudioTracksChanged: (callback: (audioTracks: Array<Track>) => void) => void;
    public readonly onAvailableSubtitlesTracksChanged: (callback: (subtitleTracks: Array<Track>) => void) => void;
    public readonly onAvailableThumbnailVariantsChanged?: (callback: (thumbnailVariants: Array<ThumbnailVariant>) => void) => void;
    public readonly onAudioTrackChanged: (callback: (trackId: Track['id']) => void) => void;
    public readonly onSeekStarted: (callback: (positionMs: number) => void) => void;
    public readonly onSeekEnded: (callback: () => void) => void;
    public readonly onSubtitlesTrackChanged: (callback: (trackId?: Track['id']) => void) => void;
    public readonly onSubtitleCuesChanged: (callback: (subtitleCues: Array<SubtitleCue>) => void) => void;
    public readonly onVolumeChanged: (callback: (volume: number) => void) => void;
    public readonly onMuteChanged: (callback: (isMuted: boolean) => void) => void;
    public readonly onSessionEnded: (callback: () => void) => void;
    public readonly onPlayoutDataReceived: (callback: (playoutData: PlayoutData) => void) => void;
    public readonly onPlayoutRulesReceived: (callback: (playoutRules: PlayoutRules) => void) => void;
    public readonly onEventBoundary: (callback: (event: BoundaryEvent) => void) => void;
    public readonly onEndOfEventMarkerReceived: (callback: (eventTime: number) => void) => void;
    public readonly onAdStarted: (callback: (adData: Ad) => void) => void;
    public readonly onAdFinished: (callback: (adData: Ad) => void) => void;
    public readonly onAdBreakStarted: (callback: (adData: AdBreak) => void) => void;
    public readonly onAdBreakFinished: (callback: (adData: AdBreak) => void) => void;
    public readonly onAdBreakDataReceived: (callback: (adData: Array<AdBreak>) => void) => void;
    public readonly onAdPositionChanged: (callback: (adPosition: AdPosition) => void) => void;
    public readonly onPauseAd: (callback: (event: NonLinearAdEvent) => void) => void;
    public readonly onVerifyCompanionAdInsertionEnabled?: (callback: (companionAdInsertionEnabled: boolean) => void) => void;
    public readonly onCompanionAdOpportunityStarted?: (callback: (event: CompanionAdOpportunityStartedEvent) => void) => void;
    public readonly onCompanionAdOpportunityEnded?: (callback: (event: CompanionAdOpportunityEndedEvent) => void) => void;
    public readonly onRenderWatermark: (callback: (data: WatermarkData) => void) => void;
    public readonly onClearWatermark: (callback: (data: WatermarkData) => void) => void;
    public readonly onClearWatermarks: (callback: () => void) => void;
    public readonly onPinRequired: (callback: (cause: PinRequiredCause) => void) => void;
    public readonly onPinSuccess: (callback: () => void) => void;
    public readonly onAutoPlayPolicyPreventedPlayback: (callback: () => void) => void;
    public readonly isFinished: () => boolean;
    public readonly restartSession: () => void;
    public readonly retrySession: (error: CvsdkError) => void;
    public readonly play: () => void;
    public readonly pause: () => void;
    public readonly stop: () => Promise<void>;
    public readonly seekToDate: (seekDate: Date) => Promise<void>;
    public readonly seek: (positionMs: number) => void;
    public readonly seekToLiveEdge: () => Promise<void>;
    public readonly seekToLiveStart: () => Promise<void>;
    public readonly isAtLiveEdge: () => Promise<boolean>;
    public readonly isLinearScrubbingSupported: () => boolean;
    public readonly isSeekToDateSupported: () => boolean;
    public readonly setVolume: (volume: number) => void;
    public readonly setMute: (isMuted: boolean) => void;
    public readonly setPlayerBitrateLimits: (playerBitrateLimits: PlayerBitrateLimits) => void;
    public readonly resetPlayerBitrateLimits: () => void;
    public readonly enableSubtitles: (trackId: Track['id']) => void;
    public readonly disableSubtitles: () => void;
    public readonly setAudioTrack: (trackId: Track['id']) => void;
    public readonly setPin: (pin: string) => Promise<void>;
    public readonly setThumbnailVariant: (variantId: string) => Promise<boolean>;
    public readonly getThumbnailForTime: (contentTime: number, velocity?: number) => Promise<ThumbnailRenderInfo | null>;
    public readonly cancelPin: () => void;
    public readonly setUserWaitStarted: () => void;
    public readonly setUserWaitEnded: () => void;
    public readonly getLiveWindow: () => LiveWindow;
    public readonly updateAssetMetadata: (assetMetadata: AssetMetadata) => void;
    public readonly waitForState: (state: SessionState) => Promise<void>;
    public readonly getMaxVideoFormat: () => VideoFormat;
    public readonly getSupportedColourSpaces: () => Array<VideoColourSpace>;
    public readonly getSupportedMaxHdcpLevel?: () => HdcpLevel;
    public readonly getSupportedAudioFormats: () => Array<AudioFormat>;
    public readonly getConnectedHdcpLevel?: () => HdcpLevel;
    public readonly getWidevineSecurityLevel?: () => WidevineSecurityLevel;
    public readonly getSupportedVideoCodecs?: () => Array<VideoCodec>;

    /** register an EventCue, for which we may also register an onCueEntered and onCueExited events.
     * startTime and endTime may be either a number indicating the stream position, or a Date representing
     * the absoluteDate position of the stream.
     */
    public readonly registerCue?: <T>(cue: EventCue<T>) => string;

    /** unregister an EventCue */
    public readonly unregisterCue?: (cueId: string) => boolean;

    /**
     * Register callback(s) for the onCueEntered event for a given type. So that
     * when a registered cue is entered, which has the same 'type', The onCueEntered callback(s) registered under that type will be invoked.
     */
    public readonly onCueEntered?: (type: string, callback: EventCueCallback) => void;

    /**
     * Register callback(s) for the onCueExited event for a given type. So that
     * when a registered cue is exited which has the same 'type'. The onCueExited callback(s) registered under that type will be invoked.
     */
    public readonly onCueExited?: (type: string, callback: EventCueCallback) => void;

    public readonly onCueRegistered?: (type: string, callback: OnEventCueRegisteredCallback) => void;

    public readonly onCueUpdated?: (type: string, callback: OnEventCueUpdatedCallback) => void;

    public readonly onCueUnregistered?: (type: string, callback: EventCueCallback) => void;

    // @ts-ignore
    private error: (error: CvsdkError) => void;
    private currentState!: SessionState;
    private csaiTransitionTimer?: ReturnType<typeof setTimeout>;
    private maxCsaiTransitionTimeSecs = 10;

    constructor(sessionProxy: SessionControllerInternalProxy, config: InternalConfig) {
        this.getCurrentSessionState = () => this.currentState;

        if (config.csai?.maxTransitionTimeSecs) {
            this.maxCsaiTransitionTimeSecs = config.csai.maxTransitionTimeSecs;
        }

        this.onError = sessionProxy.onError.bind(sessionProxy);
        this.onWarning = sessionProxy.onWarning.bind(sessionProxy);
        this.onAvailableAudioTracksChanged = sessionProxy.onAvailableAudioTracksChanged.bind(sessionProxy);
        this.onAvailableSubtitlesTracksChanged = sessionProxy.onAvailableSubtitlesTracksChanged.bind(sessionProxy);
        this.onAvailableThumbnailVariantsChanged = sessionProxy.onAvailableThumbnailVariantsChanged.bind(sessionProxy);
        this.onSeekStarted = sessionProxy.onSeekStarted.bind(sessionProxy);
        this.onSeekEnded = sessionProxy.onSeekEnded.bind(sessionProxy);
        this.onSubtitleCuesChanged = sessionProxy.onSubtitleCuesChanged.bind(sessionProxy);
        this.onVolumeChanged = sessionProxy.onVolumeChanged.bind(sessionProxy);
        this.onMuteChanged = sessionProxy.onMuteChanged.bind(sessionProxy);
        this.onSessionEnded = sessionProxy.onSessionEnded.bind(sessionProxy);
        this.onPlayoutDataReceived = sessionProxy.onPlayoutDataReceived.bind(sessionProxy);
        this.onPlayoutRulesReceived = sessionProxy.onPlayoutRulesReceived.bind(sessionProxy);
        this.onEventBoundary = sessionProxy.onEventBoundary.bind(sessionProxy);
        this.onEndOfEventMarkerReceived = sessionProxy.onEndOfEventMarkerReceived.bind(sessionProxy);
        this.onAdStarted = sessionProxy.onAdStarted.bind(sessionProxy);
        this.onAdFinished = sessionProxy.onAdFinished.bind(sessionProxy);
        this.onAdBreakDataReceived = sessionProxy.onAdBreakDataReceived.bind(sessionProxy);
        this.onAdPositionChanged = sessionProxy.onAdPositionChanged.bind(sessionProxy);
        this.onPauseAd = sessionProxy.onPauseAd.bind(sessionProxy);
        this.onVerifyCompanionAdInsertionEnabled = sessionProxy.onVerifyCompanionAdInsertionEnabled.bind(sessionProxy);
        this.onCompanionAdOpportunityStarted = sessionProxy.onCompanionAdOpportunityStarted.bind(sessionProxy);
        this.onCompanionAdOpportunityEnded = sessionProxy.onCompanionAdOpportunityEnded.bind(sessionProxy);
        this.onRenderWatermark = sessionProxy.onRenderWatermark.bind(sessionProxy);
        this.onClearWatermark = sessionProxy.onClearWatermark.bind(sessionProxy);
        this.onClearWatermarks = sessionProxy.onClearWatermarks.bind(sessionProxy);
        this.onPinRequired = sessionProxy.onPinRequired.bind(sessionProxy);
        this.onPinSuccess = sessionProxy.onPinSuccess.bind(sessionProxy);
        this.onAutoPlayPolicyPreventedPlayback = sessionProxy.onAutoPlayPolicyPreventedPlayback.bind(sessionProxy);
        this.onAdBreakStarted = sessionProxy.onAdBreakStarted.bind(sessionProxy);
        this.onAdBreakFinished = sessionProxy.onAdBreakFinished.bind(sessionProxy);
        this.onAudioTrackChanged = sessionProxy.onAudioTrackChanged.bind(sessionProxy);
        this.onSessionRetryStarted = sessionProxy.onSessionRetryStarted.bind(sessionProxy);
        this.onSessionRetryEnded = sessionProxy.onSessionRetryEnded.bind(sessionProxy);
        this.onSessionRestartStarted = sessionProxy.onSessionRestartStarted.bind(sessionProxy);
        this.onSessionRestartEnded = sessionProxy.onSessionRestartEnded.bind(sessionProxy);
        this.registerCue = sessionProxy.registerCue.bind(sessionProxy);
        this.unregisterCue = sessionProxy.unregisterCue.bind(sessionProxy);
        this.onCueEntered = sessionProxy.onEventCueEntered.bind(sessionProxy);
        this.onCueExited = sessionProxy.onEventCueExited.bind(sessionProxy);
        this.onCueRegistered = sessionProxy.onEventCueRegistered.bind(sessionProxy);
        this.onCueUnregistered = sessionProxy.onEventCueUnregistered.bind(sessionProxy);
        this.onCueUpdated = sessionProxy.onCueUpdated.bind(sessionProxy);

        this.onSubtitlesTrackChanged = (cb) => {
            sessionProxy.onSubtitlesTrackChanged((track) => {
                if (!track?.forced) cb(track?.id);
            });
        };

        this.onPlaybackTimelineUpdated = (cb) => {
            sessionProxy.onPlaybackTimelineUpdated((internalPlaybackTimeline) => {
                // Prevent reporting position updates to clients but notify internal
                // session so that beacons are fired. Example: https://gspcloud.atlassian.net/browse/VPTJS-12164
                if (internalPlaybackTimeline.muteForClients) {
                    return;
                }
                const { liveWindow, currentTime, ...playbackTimeLine } = internalPlaybackTimeline;
                cb(playbackTimeLine);
            });
        };

        this.onBitrateChanged = sessionProxy.onBitrateChanged.bind(sessionProxy);

        this.onEncodedFrameRateChanged = sessionProxy.onEncodedFrameRateChanged.bind(sessionProxy);

        this.onRenderedFrameRateChanged = sessionProxy.onRenderedFrameRateChanged.bind(sessionProxy);

        this.onVideoTrackChanged = sessionProxy.onVideoTrackChanged.bind(sessionProxy);

        this.onItuQaSessionData = sessionProxy.onItuQaSessionData.bind(sessionProxy);

        this.onStateChanged = (callback: (mappedState: SessionState) => void) => {
            sessionProxy.onStateChanged((state: InternalSessionState) => {
                this.handleInternalStateChanged(state, callback);
            });
        };

        const bindWithLogging =
            (fnName: keyof InternalSessionInterface) =>
            (...args: Array<any>) => {
                sdkLogger.verbose(`Called SessionController.${fnName}:`, ...args.map(JsonUtils.stringify));
                return (sessionProxy[fnName] as Function)(...args);
            };

        this.isFinished = bindWithLogging('isFinished');
        this.restartSession = bindWithLogging('restartSession');
        this.retrySession = bindWithLogging('retrySession');
        this.play = bindWithLogging('play');
        this.pause = bindWithLogging('pause');
        this.stop = bindWithLogging('stop');
        this.seek = bindWithLogging('seek');
        this.seekToDate = bindWithLogging('seekToDate');
        this.seekToLiveEdge = bindWithLogging('seekToLiveEdge');
        this.seekToLiveStart = bindWithLogging('seekToLiveStart');
        this.isAtLiveEdge = bindWithLogging('isAtLiveEdge');
        this.setVolume = bindWithLogging('setVolume');
        this.setMute = bindWithLogging('setMute');
        this.setPlayerBitrateLimits = bindWithLogging('setPlayerBitrateLimits');
        this.resetPlayerBitrateLimits = bindWithLogging('resetPlayerBitrateLimits');
        this.enableSubtitles = bindWithLogging('enableSubtitles');
        this.disableSubtitles = bindWithLogging('disableSubtitles');
        this.setAudioTrack = bindWithLogging('setAudioTrack');
        this.setPin = bindWithLogging('setPin');
        this.cancelPin = bindWithLogging('cancelPin');
        this.setUserWaitStarted = bindWithLogging('setUserWaitStarted');
        this.setUserWaitEnded = bindWithLogging('setUserWaitEnded');
        this.setThumbnailVariant = bindWithLogging('setThumbnailVariant');
        this.getThumbnailForTime = bindWithLogging('getThumbnailForTime');
        this.getLiveWindow = bindWithLogging('getLiveWindow');
        this.updateAssetMetadata = bindWithLogging('notifyAssetMetadataUpdated');
        this.getMaxVideoFormat = bindWithLogging('getMaxVideoFormat');
        this.getSupportedColourSpaces = bindWithLogging('getSupportedColourSpaces');
        this.getSupportedAudioFormats = bindWithLogging('getSupportedAudioFormats');
        this.getSupportedMaxHdcpLevel = bindWithLogging('getSupportedMaxHdcpLevel');
        this.getConnectedHdcpLevel = bindWithLogging('getConnectedHdcpLevel');
        this.getWidevineSecurityLevel = bindWithLogging('getWidevineSecurityLevel');
        this.getSupportedVideoCodecs = bindWithLogging('getSupportedVideoCodecs');
        this.error = bindWithLogging('notifyError');

        this.isSeekToDateSupported = sessionProxy.isSeekToDateSupported.bind(sessionProxy);
        this.isLinearScrubbingSupported = sessionProxy.isLinearScrubbingSupported.bind(sessionProxy);

        this.waitForState = (state) => sessionProxy.waitForState(ReverseMappedSessionStateForClients[state as ValidWaitForState]);
    }

    private clearCsaiTransitionTimer() {
        if (this.csaiTransitionTimer) {
            clearTimeout(this.csaiTransitionTimer);
        }
    }

    private startCsaiTransitionTimer(notifySessionStateChanged: (mappedState: SessionState) => void) {
        this.csaiTransitionTimer = setTimeout(() => {
            notifySessionStateChanged(SessionState.Rebuffering);
            this.currentState = SessionState.Rebuffering;
        }, this.maxCsaiTransitionTimeSecs * 1000);
    }

    private handleInternalStateChanged(state: InternalSessionState, notifySessionStateChanged: (mappedState: SessionState) => void) {
        this.clearCsaiTransitionTimer();
        if (state === InternalSessionState.CsaiTransition) this.startCsaiTransitionTimer(notifySessionStateChanged);

        notifySessionStateChanged(MappedSessionStateForClients[state]);
        this.currentState = MappedSessionStateForClients[state];
    }
}
