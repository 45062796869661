import type { AdaptedState } from './observable';
import { Observable } from './observable';

export interface ComposedState<T> {
    state: T;
    shouldReset?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class CompositeObservable<T extends { [key: string]: any }> extends Observable<Partial<T>> {
    constructor(
        private attemptToCombine: (partial: Partial<T>) => ComposedState<T>,
        errorHandler?: (e: Error) => void
    ) {
        super(errorHandler);
        this.currentState = {};
    }

    public reset(): void {
        this.currentState = {};
    }

    public notifyObservers(newState?: Partial<T>, currentPosition?: number): AdaptedState<Partial<T>> {
        const combinedState = {
            ...this.currentState,
            ...newState,
        } as Partial<T>;

        const composedState = this.attemptToCombine(combinedState);

        if (composedState) {
            const res = super.notifyObservers(composedState.state, currentPosition);
            if (composedState.shouldReset) {
                this.reset();
            }
            return res;
        } else {
            this.currentState = combinedState;
            return;
        }
    }
}
