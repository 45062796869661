import type { Clip, Playlist } from '@nbcu/smil-loader';
import { VideoPlatform } from '../integration-provider';

import type { ExternalTrack } from '../../core/player/track';
import { TextTrackMimeType } from '../../core/player/text-track-mime-type';
import type { Cdn, PlayoutData } from '../../core/player/playout-data';
import { StreamingProtocol } from '../../core/player/playout-data';
import type { VpiSessionItem } from '../../core/session-controller/session-controller';
import { CvsdkError, ErrorSeverity } from '../../error';
import { VTT_TEXT_MIMETYPES } from '../../players/player-extensions/subtitles/subtitles-utils';
import { JsonUtils } from '../../utils/json-utils';
import { TextRole } from '../../core/player/role';

const NO_CONTENT = 'No Content URL';

export function playoutResponseAdapter(playlist: Playlist, sessionItem: VpiSessionItem): PlayoutData {
    const cdns: Array<Cdn> = playlist.clips.map((clip: Clip) => ({ url: clip.URL, name: VideoPlatform.SMIL }));
    const protocol = getStreamingProtocol(playlist.clips);
    const extTracks = getExternalTracks(playlist.clips);
    const adaptedData = {
        // Depend on the application
        autoplay: sessionItem.autoplay,
        advertising: {
            contentId: cdns.length > 0 ? cdns[0].url : NO_CONTENT,
        },
        muted: sessionItem.muted,
        preferredAudioMetadata: sessionItem.preferredAudioMetadata,
        preferredAudioLanguages: sessionItem.preferredAudioLanguages,
        preferredSubtitleMetadata: sessionItem.preferredSubtitleMetadata,
        preferredSubtitlesLanguages: sessionItem.preferredSubtitlesLanguages,
        playerBitrateLimits: sessionItem.playerBitrateLimits,
        type: sessionItem.type,

        cdns: cdns,
        stream: {
            protocol: protocol,
        },
        containsMandatoryPinEvents: false,

        abrConfiguration: sessionItem.abrConfiguration,
        position: sessionItem.startPosition || 0,
        externalTracks: extTracks,
        vamPrerollEnabled: true,
        vamMidrollEnabled: true,

        // From SDK itself
        sessionId: sessionItem.sessionId,
    };
    return adaptedData;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function playoutDataErrorAdapter(e: any): CvsdkError {
    return CvsdkError.from({
        code: e.code || (e.response?.status && `SMIL_HTTP_${e.response.status}`) || 'SDK_VPI_FAILURE',
        message: e.description || e.response?.statusText || e.message || JsonUtils.stringify(e),
        severity: ErrorSeverity.Fatal,
        cause: e,
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function playerErrorAdapter(e: CvsdkError): CvsdkError {
    return e;
}

/**
 * Loops over the clip types, choosing DASH if it's found, defaulting to HLS if not
 * @param clips List of the content to be played
 * @returns Streaming Protocol found or defaults to HLS
 */
function getStreamingProtocol(clips: Array<Clip>): StreamingProtocol {
    for (const clip of clips) {
        if (clip.type?.includes('application/dash')) {
            return StreamingProtocol.DASH;
        }
    }

    return StreamingProtocol.HLS;
}

/**
 * Extracts external tracks from the parsed SMIL data
 * @param clips List of content to be played
 * @returns Array of external tracks to be loaded later
 */
function getExternalTracks(clips: Array<Clip>): Array<ExternalTrack> {
    const tracks = new Map<string, ExternalTrack>();
    for (const clip of clips) {
        const newTracks = clip.availableTextTracks?.filter((track) => VTT_TEXT_MIMETYPES.includes(track.MIMEType)) ?? [];
        for (const track of newTracks) {
            // Only set once
            if (!tracks.has(track.URL)) {
                tracks.set(track.URL, {
                    uri: track.URL,
                    id: track.URL,
                    language: track.language,
                    roles: [TextRole.Subtitle], // This is currently always Subtitle
                    mimeType: TextTrackMimeType.WebVTT, // This is currently always WebVTT
                });
            }
        }
    }

    return Array.from(tracks.values());
}
