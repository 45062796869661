export function safeRequestIdleCallback(cb: () => void, timeoutMS?: number) {
    if ('requestIdleCallback' in self) {
        if (!timeoutMS) {
            requestIdleCallback(cb);
        } else {
            requestIdleCallback(cb, { timeout: timeoutMS });
        }
        return;
    }

    setTimeout(cb, timeoutMS || 1);
}
