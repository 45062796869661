import type { PrefetchingConfig } from '../../../config/internal-config';
import { TimedCache } from '../../../utils/timed-cache/timed-cache';

import type { SessionControllerPrecursor } from './session-controller-precursor';

const OVP_LICENSE_TIMEOUT_S: number = 60 * 4;
const CACHE_CLEAN_INTERVAL_S = 60;
const DEFAULT_CACHE_CAPACITY = 1;

export class SessionControllerPrecursorCache {
    private static cache: TimedCache<SessionControllerPrecursor> | null = null;
    private static config?: PrefetchingConfig;
    private constructor() {}
    public static configure(config: PrefetchingConfig | undefined): void {
        this.config = config;
    }
    public static getInstance(): TimedCache<SessionControllerPrecursor> {
        if (!SessionControllerPrecursorCache.cache) {
            const capacity = this.config?.cacheCapacity || DEFAULT_CACHE_CAPACITY;
            const timeout = Math.min(OVP_LICENSE_TIMEOUT_S, this.config?.cacheTimeout || OVP_LICENSE_TIMEOUT_S);
            const destroyHandler = (precursor: SessionControllerPrecursor) => precursor.destroy();
            SessionControllerPrecursorCache.cache = new TimedCache<SessionControllerPrecursor>(
                timeout,
                CACHE_CLEAN_INTERVAL_S,
                destroyHandler,
                capacity
            );
        }
        return SessionControllerPrecursorCache.cache;
    }
}
