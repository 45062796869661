/**
 * @description This service provides a listener and store for sdkconfig overrides that we don't want exposed to clients
 */
import type { PartialClientOptions } from '@sky-uk-ott/ocellus-shared';
import type { AdInsertionType } from '../../addons/ad-insertion-type';
import type { YospaceEnvironment } from '../../addons/adverts/server-adverts/yospace/yospace-config';
import { sdkLogger } from '../../logger';
import { JsonUtils } from '../../utils/json-utils';
import type { CompatibleStreamPropertyGroup } from '../player/stream-capability-specification';
import type { Protection, Transport } from '@sky-uk-ott/client-lib-js-device';

export type SlePreRollOverrideType = 'Default' | 'Enabled' | 'Disabled';

export type CoordinatedBitrateCapOverrideType = {
    pollingTimeout: number | undefined;
    bitrateCap: number | undefined;
    allowAnyAssetType: boolean | undefined;
};

export type DeviceCapability = {
    streamingProtocol?: Transport;
    drmType?: Protection;
};

export type OverridesType = {
    adInsertionType: AdInsertionType;
    yospace: {
        environment?: YospaceEnvironment;
    };
    multiPlayerCsai: {
        staticOverride: boolean;
    };
    isSeekableDuringAds: boolean;
    isForcedUnitedStatesIpAddress: boolean;
    shaka?: {
        streaming: Partial<shaka.extern.StreamingConfiguration>;
        abr: Partial<shaka.extern.AbrConfiguration>;
    };
    uncompiledShaka?: boolean;
    nielsenDtvrOverride?: 'DCR' | 'DTVR';
    deviceModelOverride?: string;
    forceSlePreroll: SlePreRollOverrideType;
    compatibleStreamPropertyGroup?: CompatibleStreamPropertyGroup;
    adsIntervalBeforeNextAdSeconds?: number;
    coordinatedBitrateCap?: CoordinatedBitrateCapOverrideType | undefined;
    ocellusOverrideConfig?: PartialClientOptions;
    ps5EsvmOverride?: boolean;
    metalistEndpointOverride?: string;
    liveActionsTestCustomEvents?: boolean;
    securityBasedBandwidthCapDisable?: boolean;
    testDeviceCapabilities: DeviceCapability | undefined;
    skipWatchedAdsForLinearDvr?: boolean;
};

const overrides: OverridesType = {
    yospace: {},
    multiPlayerCsai: {
        staticOverride: false,
    },
    isSeekableDuringAds: false,
    isForcedUnitedStatesIpAddress: false,
    nielsenDtvrOverride: undefined,
    deviceModelOverride: '',
    forceSlePreroll: 'Default',
    adsIntervalBeforeNextAdSeconds: undefined,
    ocellusOverrideConfig: undefined,
    ps5EsvmOverride: undefined,
    securityBasedBandwidthCapDisable: undefined,
    testDeviceCapabilities: undefined,
} as OverridesType;

export function dangerouslyOverrideAdInsertionType(adInsertionType: AdInsertionType): void {
    sdkLogger.warn(`Setting AdInsertionType SdkConfig overrides`);
    overrides.adInsertionType = adInsertionType;
}

export function dangerouslySetYospaceEnvironment(environment: YospaceEnvironment): void {
    sdkLogger.warn(`Setting YospaceEnvironment SdkConfig overrides`);
    overrides.yospace.environment = environment;
}

export function dangerouslySetMultiPlayerCsaiStatic(shouldOverrideWithStatic: boolean): void {
    sdkLogger.warn(`Setting MultiPlayerCsai overrides`);
    overrides.multiPlayerCsai.staticOverride = shouldOverrideWithStatic;
}

export function dangerouslySetUnitedStatesIpAddress(isForcedUnitedStatesIpAddress: boolean): void {
    sdkLogger.warn(`Setting US IP override`);
    overrides.isForcedUnitedStatesIpAddress = isForcedUnitedStatesIpAddress;
}

export function dangerouslySetShakaConfiguration(shakaOverrides: OverridesType['shaka']): void {
    sdkLogger.warn(`Setting Shaka Configuration`);
    overrides.shaka = shakaOverrides;
}

export function dangerouslySetUncompiledShaka(uncompiledShaka: OverridesType['uncompiledShaka']): void {
    sdkLogger.warn(`Setting uncompiled shaka Configuration`);
    overrides.uncompiledShaka = uncompiledShaka;
}

export function dangerouslySetNielsenDtvrOverride(nielsenDtvrOverride: OverridesType['nielsenDtvrOverride']): void {
    sdkLogger.warn(`Setting Nielsen DTVR Override to: ${nielsenDtvrOverride}`);
    overrides.nielsenDtvrOverride = nielsenDtvrOverride;
}

export function dangerouslySetCoordinatedBitrateCap(coordinatedBitrateCapOverride: OverridesType['coordinatedBitrateCap']): void {
    sdkLogger.warn(
        `Setting coordinatedBitrateCapOverride: interval: ${coordinatedBitrateCapOverride?.pollingTimeout} cap: ${coordinatedBitrateCapOverride?.bitrateCap} allowAnyAssetType: ${coordinatedBitrateCapOverride?.allowAnyAssetType}`
    );
    overrides.coordinatedBitrateCap = coordinatedBitrateCapOverride;
}

export function dangerouslySetDeviceModel(deviceModel: string) {
    sdkLogger.warn(`Setting Override for Device Model, new value: "${deviceModel}"`);
    overrides.deviceModelOverride = deviceModel;
}

export function dangerouslyForceSlePreroll(forceSlePreroll: SlePreRollOverrideType): void {
    sdkLogger.warn(`Setting Force SLE Preroll Override: ${forceSlePreroll}`);
    overrides.forceSlePreroll = forceSlePreroll;
}

export function dangerouslySetCompatibleStreamPropertyGroup(compatibleGroup: CompatibleStreamPropertyGroup | undefined): void {
    sdkLogger.warn(`Setting Compatible Stream Property Group: ${JsonUtils.stringify(compatibleGroup)}`);
    overrides.compatibleStreamPropertyGroup = compatibleGroup;
}

export function dangerouslySetAdsIntervalBeforeNextAdSeconds(seconds: number | undefined): void {
    sdkLogger.warn(`Setting Interval before next Ads: ${seconds}`);
    overrides.adsIntervalBeforeNextAdSeconds = seconds;
}

export function dangerouslySetOcellusOverrideConfig(ocellusOverrideConfig: PartialClientOptions): void {
    sdkLogger.warn(`Setting Ocellus override config: ${JsonUtils.stringify(ocellusOverrideConfig)}`);
    overrides.ocellusOverrideConfig = ocellusOverrideConfig;
}

export function dangerouslyOverridePs5Esvm(enabled: boolean): void {
    sdkLogger.warn(`Setting PS5 ESVM override to ${enabled}`);
    overrides.ps5EsvmOverride = enabled;
}

export function dangerouslyOverrideSecurityBandwidthCap(disabled: boolean): void {
    sdkLogger.warn(`Setting Security Manager Bandwidth Cap Disable to ${disabled}`);
    overrides.securityBasedBandwidthCapDisable = disabled;
}

export function dangerouslyOverrideMetalistEndpoint(endpoint: string | undefined): void {
    if (endpoint) {
        sdkLogger.warn(`Setting Metalist endpoint override to ${endpoint}`);
    }
    overrides.metalistEndpointOverride = endpoint;
}

export function dangerouslyOverrideLiveActionsCustomEvents(liveActionsTestCustomEvents: boolean): void {
    if (liveActionsTestCustomEvents) {
        sdkLogger.warn(`Setting live actions test custom events override to ${liveActionsTestCustomEvents}`);
    }
    overrides.liveActionsTestCustomEvents = liveActionsTestCustomEvents;
}

export function dangerouslyOverrideDeviceCapabilities(deviceCapabilities: DeviceCapability): void {
    overrides.testDeviceCapabilities = deviceCapabilities;
}

export function dangerouslySetSkipWatchedAdsForLinearDvr(enabled: boolean): void {
    sdkLogger.warn(`Setting Skip Watched Ads For Linear Override to: ${enabled}`);
    overrides.skipWatchedAdsForLinearDvr = enabled;
}

export class TestingOverrides {
    public static get adInsertionOverride(): AdInsertionType {
        return overrides.adInsertionType;
    }
    public static get yospaceOverride(): OverridesType['yospace'] {
        return overrides.yospace;
    }
    public static get multiPlayerCsaiOverride(): OverridesType['multiPlayerCsai'] {
        return overrides.multiPlayerCsai;
    }
    public static get isForcedUnitedStatesIpAddress(): boolean {
        return overrides.isForcedUnitedStatesIpAddress;
    }
    public static get shakaOverrides(): OverridesType['shaka'] {
        return overrides.shaka;
    }
    public static get uncompiledShaka(): OverridesType['uncompiledShaka'] {
        return overrides.uncompiledShaka;
    }
    public static get nielsenDtvrOverride(): OverridesType['nielsenDtvrOverride'] {
        return overrides.nielsenDtvrOverride;
    }
    public static get deviceModelOverride(): OverridesType['deviceModelOverride'] {
        return overrides.deviceModelOverride;
    }
    public static get forceSlePreroll(): SlePreRollOverrideType {
        return overrides.forceSlePreroll;
    }
    public static get coordinatedBitrateCap(): OverridesType['coordinatedBitrateCap'] | undefined {
        return overrides.coordinatedBitrateCap;
    }

    public static get compatibleStreamPropertyGroup(): OverridesType['compatibleStreamPropertyGroup'] {
        return overrides.compatibleStreamPropertyGroup;
    }
    public static get adsIntervalBeforeNextAdSeconds(): OverridesType['adsIntervalBeforeNextAdSeconds'] {
        return overrides.adsIntervalBeforeNextAdSeconds;
    }
    public static get ocellusOverrideConfig(): OverridesType['ocellusOverrideConfig'] {
        return overrides.ocellusOverrideConfig;
    }
    public static get ps5EsvmOverride(): OverridesType['ps5EsvmOverride'] {
        return overrides.ps5EsvmOverride;
    }
    public static get metalistEndpointOverride(): OverridesType['metalistEndpointOverride'] {
        return overrides.metalistEndpointOverride;
    }
    public static get liveActionsTestCustomEvents(): OverridesType['liveActionsTestCustomEvents'] {
        return overrides.liveActionsTestCustomEvents;
    }
    public static get securityBasedBandwidthCapDisable(): OverridesType['securityBasedBandwidthCapDisable'] {
        return overrides.securityBasedBandwidthCapDisable;
    }
    public static get testDeviceCapabilities(): OverridesType['testDeviceCapabilities'] {
        return overrides.testDeviceCapabilities;
    }
    public static get skipWatchedAdsForLinearDvr(): OverridesType['skipWatchedAdsForLinearDvr'] {
        return overrides.skipWatchedAdsForLinearDvr;
    }
}
