import type { VideoColourSpace, VideoFormat } from '../core/player/video-format';

import { getArrayIntersection } from './array-utils';

type VideoSupportedFormatType = VideoFormat | VideoColourSpace;

export type LimitationEntry<FormatType extends VideoSupportedFormatType> = Array<FormatType>;

/**
 * Based on the device support, generic SDK Config, and device-specific blocklist
 * Restrict the supported Video/Colour Formats
 * @param playerSupport - Reported supported formats by the physical device/player combination
 * @param sdkConfig - SDK Configuration
 * @param deviceLimitationEntries - List of limitations applicable to this device
 * @returns List of supported Video/Colour Formats
 */
export function getSupportedFormats<FormatType extends VideoSupportedFormatType>(
    playerSupport: Array<FormatType>,
    sdkConfig?: Array<FormatType>,
    deviceLimitationEntries?: Array<LimitationEntry<FormatType>>
): Array<FormatType> {
    // Begin with what the device claims to support and whittle it down based on the overall SDK config
    // and then the device specific block list
    let supportedFormats = getArrayIntersection(playerSupport, sdkConfig);

    // There may be multiple block list entries that match a particular device
    // eg. Samsung may have an overall restriction
    // but one specific Samsung device might have a further restriction
    deviceLimitationEntries?.forEach((listEntry) => {
        supportedFormats = getArrayIntersection(supportedFormats, listEntry);
    });

    return supportedFormats;
}
