import { JsonUtils } from './json-utils';

/**
 * The `never` type is statically incompatible with other types. If we pass some value to this function that is not
 * of the type `never`, we get the following error message at compile time:
 *
 * > Argument of type 'SomeType' is not assignable to parameter of type 'never'.
 *
 * We can therefore use these functions with if and switch statements to protect ourselves from forgetting to handle
 * some use cases. For more information, check out
 * https://2ality.com/2020/01/typescript-enums.html#protecting-against-forgetting-cases-via-exhaustiveness-checks
 */

export function throwUnsupportedValue(value: never, expected: Array<unknown>): never {
    let str: string = value;
    if (typeof value === 'object') {
        str = JsonUtils.stringify(value);
    }

    throw new TypeError(`Unsupported value. Received: ${str} but expected to be one of ${JsonUtils.stringify(expected)}`);
}

export function throwUnsupportedValueEnum(value: never, expected: Array<unknown>, name?: string): void {
    if (!name) {
        throwUnsupportedValue(value, Object.values(expected));
    }

    throw new TypeError(`Unsupported value for enum ${name}. Received: ${value} but expected to be one of ${JsonUtils.stringify(expected)}`);
}

export function checkUnsupportedValue(_value: never): void {}

type UnionToIntersection<U> = (U extends never ? never : (arg: U) => never) extends (arg: infer I) => void ? I : never;
export type UnionToTuple<T> = UnionToIntersection<T extends never ? never : (t: T) => T> extends (_: never) => infer W
    ? [...UnionToTuple<Exclude<T, W>>, W]
    : [];

type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]];

type Join<K, P> = K extends string | number ? (P extends string | number ? `${K}${'' extends P ? '' : '.'}${P}` : never) : never;

// This type will allow you to define object property paths as strings using dot-notation
// in a type-safe manner.
export type LeafPath<T, D extends number = 10> = [D] extends [never]
    ? never
    : T extends object
      ? { [K in keyof T]-?: Join<K, LeafPath<T[K], Prev[D]>> }[keyof T]
      : '';

type RequiredKeys<T> = { [K in keyof T]-?: {} extends Pick<T, K> ? never : K }[keyof T];
type OptionalKeys<T> = { [K in keyof T]-?: {} extends Pick<T, K> ? K : never }[keyof T];

// A type that converts all optional fields to be required but allow undefined.
// This is useful to enforce declaration of an object property when we are dynamically setting object
// properties and want to make sure that some values are not missed
type RequiredKeepUndefined<T> = { [K in keyof T]-?: [T[K]] } extends infer U
    ? U extends Record<keyof U, [unknown]>
        ? { [K in keyof U]: U[K][0] }
        : never
    : never;
export type DeclaredOptionals<T> = T extends Function
    ? T
    : T extends Array<unknown>
      ? T
      : T extends object
        ? RequiredKeepUndefined<{ [K in keyof T]: K extends OptionalKeys<T> ? T[K] | undefined : T[K] }>
        : T;

export type DeclaredOptionalsNested<T> = T extends Function
    ? T
    : T extends Array<unknown>
      ? T
      : T extends object
        ? RequiredKeepUndefined<{ [K in keyof T]: K extends OptionalKeys<T> ? DeclaredOptionals<T[K]> | undefined : DeclaredOptionals<T[K]> }>
        : T;

export type PickRequired<T> = Pick<T, RequiredKeys<T>>;

export type DeepRequired<T> = {
    [K in keyof T]: Required<DeepRequired<T[K]>>;
};
