export class JsonUtils {
    public static stringify(value: unknown, indent = 0): string {
        try {
            if (value instanceof Error) {
                const errorValue = {
                    name: (value as Error).name,
                    message: (value as Error).message,
                    stack: (value as Error).stack,
                };
                return JsonUtils.stringify(errorValue, indent);
            }

            return JSON.stringify(value, JsonUtils.circularReferencesHandler(), indent);
        } catch (e) {
            return '';
        }
    }

    public static parse<T>(json: string, reviver?: ((this: unknown, key: string, value: unknown) => unknown) | undefined): T | null {
        try {
            return JSON.parse(json, reviver) as T;
        } catch (e) {
            return null;
        }
    }

    private static circularReferencesHandler(): (_: string, value: unknown) => unknown {
        const seen = new WeakSet();
        return (_: string, value: unknown) => {
            if (typeof value === 'object' && value !== null) {
                if (seen.has(value)) {
                    return '[circular reference]';
                }
                seen.add(value);
            }
            return value;
        };
    }
}
