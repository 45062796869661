import { sdkLogger } from '../../logger';

type Event = {
    function: Function;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    timeout: any;
};

export class DeferredEventQueue {
    private queue: Array<Event> | null = null;
    constructor(private deferMs: number) {
        this.queue = [];
    }

    public push(fn: Function): void {
        /**
         * In the case where we don't want to defer addons, we will execute the function synchronously.
         * Also should take into account events raised after the queue is destroyed. In this scenario just execute the function
         */
        if (!this.deferMs || !this.queue) {
            fn();
            return;
        }

        const event: Event = {
            function: fn,
            timeout: undefined,
        };

        const timeout = setTimeout(() => {
            this.queue?.shift();
            fn();
        }, this.deferMs);

        event.timeout = timeout;

        this.queue.push(event);
    }

    public executePending(): void {
        this.queue?.forEach((event) => {
            clearTimeout(event.timeout);
            try {
                event.function();
            } catch (e) {
                sdkLogger.withContext('Event Queue').error(e);
            }
        });

        this.queue = null;
    }
}
