import type { DeviceInformation, DeviceType } from '@sky-uk-ott/client-lib-js-device';

import type { Proposition } from '../../config/internal-config';
import { getDefaultDevicePlayerMap } from '../../propositions/default-device-player-map';
import type { DevicePlayerMapType } from '../../propositions/proposition-extensions';
import { getDevicePlayerMap } from '../../propositions/proposition-extensions';

import type { PlayerType } from './player-type';

/**
 * @public
 */
export function getCurrentPlayerType(
    proposition: Proposition,
    deviceType: DeviceType,
    deviceInfo?: DeviceInformation,
    override?: PlayerType,
    nativeOverride?: boolean
): PlayerType {
    if (override && !nativeOverride) {
        // eslint-disable-next-line no-console
        console.log('%cDANGER!', 'color:red; font-size: 20px');
        // eslint-disable-next-line no-console
        console.log('%c>using player override %s', 'font-size: 15px', override);
        return override;
    }

    const defaultDevicePlayerMap = getDefaultDevicePlayerMap(deviceInfo);

    const devicePlayerMap: DevicePlayerMapType = getDevicePlayerMap(proposition, deviceInfo, deviceType);

    const nativePlayer = devicePlayerMap[deviceType]!.native || defaultDevicePlayerMap[deviceType]!.default;
    return nativeOverride ? nativePlayer : devicePlayerMap[deviceType]!.default;
}
