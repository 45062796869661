import type { Device } from '@sky-uk-ott/client-lib-js-device';
import { DeviceType } from '@sky-uk-ott/client-lib-js-device';

import type { DeclaredOptionals } from '../../../utils/ts';
import type { InternalConfig, ShakaPlayerConfig } from '../../internal-config';
import { SHAKA_PLAYER_VERSION_DEFAULT, ShakaPlayerVersion } from '../../internal-config';
import type { ClientConfigSplit, RemoteConfigSplit } from '../../sdk-config';

// Internal Config
type InternalPlayersConfig = NonNullable<InternalConfig['players']>;
type ShakaInternalConfig = InternalPlayersConfig['shaka'];

export type ShakaDefaultConfig = Pick<
    NonNullable<ShakaInternalConfig>,
    'shakaPlayerVersion' | 'postAdBreakCorrectiveSeek' | 'seeker' | 'deferMultiviewSetAudioTrackWhileLoading'
>;
// Client Config when Remote Config is disabled
export type ShakaClientConfig = Pick<
    NonNullable<ShakaInternalConfig>,
    | 'useMuxJS'
    | 'alternativeAbr'
    | 'esVersion'
    | 'useDebug'
    | 'shakaTimeoutPolicy'
    | 'startAtSegmentBoundary'
    | 'useMseOnSafari'
    | 'bufferPoller'
    | 'smoothCodecSwitch'
    | 'liveSync'
    | 'vodDynamicPlaybackRate'
> & {
    maxRetryAttempts?: number;
    retryCooloffTimeMinutes?: number;
    shakaPlayerVersion?: ShakaPlayerVersion;
};
export type ShakaClientConfigSplit = ClientConfigSplit<ShakaDefaultConfig, ShakaClientConfig>;

export function adaptShakaClientConfig(config: ShakaClientConfigSplit = {}, device: Device): DeclaredOptionals<ShakaInternalConfig> {
    const { defaultConfig = {} as ShakaDefaultConfig, clientConfig = {} } = config;
    return {
        internalRetryConfig: {
            maxRetryAttempts: clientConfig.maxRetryAttempts,
            retryCooloffTimeMinutes: clientConfig.retryCooloffTimeMinutes,
        },
        shakaPlayerVersion: clientConfig.shakaPlayerVersion ?? defaultConfig.shakaPlayerVersion ?? getShakaPlayerVersionDefaultForDevice(device),
        useMuxJS: clientConfig.useMuxJS,
        alternativeAbr: clientConfig.alternativeAbr,
        nativeConfig: undefined,
        esVersion: clientConfig.esVersion,
        useDebug: clientConfig.useDebug,
        shakaTimeoutPolicy: clientConfig.shakaTimeoutPolicy,
        startAtSegmentBoundary: clientConfig.startAtSegmentBoundary,
        useMseOnSafari: clientConfig.useMseOnSafari,
        bufferPoller: clientConfig.bufferPoller,
        smoothCodecSwitch: clientConfig.smoothCodecSwitch,
        liveSync: clientConfig.liveSync,
        vodDynamicPlaybackRate: clientConfig.vodDynamicPlaybackRate,
        postAdBreakCorrectiveSeek: defaultConfig.postAdBreakCorrectiveSeek,
        seeker: defaultConfig.seeker,
        deferMultiviewSetAudioTrackWhileLoading: defaultConfig.deferMultiviewSetAudioTrackWhileLoading,
    };
}

// Server Config when Remote Config is enabled
type ShakaRemoteServerConfig = ShakaPlayerConfig;

// Client Config when Remote Config is enabled
type ShakaRemoteClientConfig = {};
export type ShakaRemoteConfigSplit = RemoteConfigSplit<ShakaRemoteServerConfig, ShakaRemoteClientConfig>;
export function adaptShakaRemoteConfigSplit(config: ShakaRemoteConfigSplit, device: Device): DeclaredOptionals<ShakaInternalConfig> {
    const { remoteConfig = {} as Partial<ShakaRemoteServerConfig> } = config;
    return {
        internalRetryConfig: remoteConfig.internalRetryConfig,
        shakaPlayerVersion: remoteConfig.shakaPlayerVersion ?? getShakaPlayerVersionDefaultForDevice(device),
        useMuxJS: remoteConfig.useMuxJS,
        alternativeAbr: remoteConfig.alternativeAbr,
        nativeConfig: remoteConfig.nativeConfig,
        esVersion: remoteConfig.esVersion,
        useDebug: remoteConfig.useDebug,
        shakaTimeoutPolicy: remoteConfig.shakaTimeoutPolicy,
        startAtSegmentBoundary: remoteConfig.startAtSegmentBoundary,
        useMseOnSafari: remoteConfig.useMseOnSafari,
        bufferPoller: remoteConfig.bufferPoller,
        smoothCodecSwitch: remoteConfig.smoothCodecSwitch,
        liveSync: remoteConfig.liveSync,
        vodDynamicPlaybackRate: remoteConfig.vodDynamicPlaybackRate,
        postAdBreakCorrectiveSeek: remoteConfig.postAdBreakCorrectiveSeek,
        seeker: remoteConfig.seeker,
        deferMultiviewSetAudioTrackWhileLoading: remoteConfig.deferMultiviewSetAudioTrackWhileLoading,
    };
}

const getShakaPlayerVersionDefaultForDevice = (device: Device): ShakaPlayerVersion => {
    switch (device.type) {
        case DeviceType.WebMaf:
            return ShakaPlayerVersion.V_3_0_5;
        default:
            return SHAKA_PLAYER_VERSION_DEFAULT;
    }
};
