import type { PlayoutData } from '../player/playout-data';
import { StreamingProtocol } from '../player/playout-data';

export const PSF_PROXY_URL = 'https://int-stream-format.mssai-dev.nbcuni.com/manifest.psf?url=';

export class PeacockStreamingFormatProxy {
    static getModifiedPlayoutData(playoutData: PlayoutData): PlayoutData {
        if (playoutData.stream?.protocol !== StreamingProtocol.DASH) {
            throw new Error('PSF proxy only supports conversion from DASH');
        }

        return {
            ...playoutData,
            stream: {
                ...playoutData.stream,
                protocol: StreamingProtocol.PSF,
            },
            cdns: playoutData.cdns.map((cdn) => ({
                ...cdn,
                url: `${PSF_PROXY_URL}${encodeURIComponent(cdn.url)}`,
            })),
        };
    }
}
