import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';
import type { AdvertsManager } from '../../../addons/adverts-manager';
import { AdvertsManagerState } from '../../../addons/adverts-manager-stages/adverts-initialisation-stage-manager';
import { sdkLogger } from '../../../logger';
import type { Observable } from '../../../utils/observables/observable';
import { createLoggingObservable } from '../../../utils/observables/verbose-logging-observable';
import type { VideoStartupEngineStarterOptions } from './video-startup-engine-starter';
import { VideoStartupEngineStarter } from './video-startup-engine-starter';
import type { PlayoutData } from '../../player/playout-data';
import type { RestartOptions } from '../restart/session-restart-controller';
import { SessionRestartType, SessionRestartController } from '../restart/session-restart-controller';
import type { TelemetryDataType } from '../../telemetry/telemetry-session';
import type { VideoPlatformIntegration } from '../../video-platform-integration/video-platform-integration';
import type { SessionItem } from '../session-controller';
import type { SessionControllerInternal } from '../session-controller-internal';
import type { SessionControllerInternalProxy } from '../session-controller-internal-proxy';
import { VideoProviderController } from '../video-provider/video-provider-controller';
import type { VideoStartupActions } from './video-startup-actions';
import type { VideoStartupState } from './video-startup-states';
import { UninitialisedState, VideoStartupStates } from './video-startup-states';

export class VideoStartupController implements VideoStartupActions {
    private logger: Logger = sdkLogger.withContext('VideoStartupController');
    private telemetryUpdateObservable: Observable<TelemetryDataType> = createLoggingObservable<TelemetryDataType>('TelemetryDataType', this.logger);

    private _shouldSkipAdvertisements = false;

    private _state: VideoStartupState = new UninitialisedState(VideoStartupStates.Uninitialised);

    private _cachedModifiedPlayoutData: PlayoutData | null = null;
    private _cachedOvpOriginalPlayoutData: PlayoutData | null = null;

    private videoProviderController: VideoProviderController;
    private restartController: SessionRestartController | undefined;
    private restartSessionOverrides: RestartOptions | null = null;

    private sessionControllerInternal: SessionControllerInternal | undefined;

    private _engineStarter?: VideoStartupEngineStarter;

    constructor(
        private advertsManager: AdvertsManager,
        private videoStartupEngineStarterOptions: VideoStartupEngineStarterOptions
    ) {
        this.advertsManager = advertsManager;
        this.videoProviderController = new VideoProviderController();
        this.logCurrentState();
    }

    public get state(): VideoStartupState {
        return this._state;
    }

    public get shouldSkipAdvertisements() {
        return this._shouldSkipAdvertisements;
    }

    public get cachedModifiedPlayoutData() {
        return this._cachedModifiedPlayoutData;
    }

    public get cachedOvpOriginalPlayoutData() {
        return this._cachedOvpOriginalPlayoutData;
    }

    public get isDestroyed() {
        return this.state.type() === VideoStartupStates.Destroyed;
    }

    public get engineStarter() {
        return this._engineStarter;
    }

    public onTelemetryUpdate(callback: (vstTelemetry: TelemetryDataType) => void): void {
        this.telemetryUpdateObservable.registerObserver(callback, this);
    }

    private notifyTelemetryUpdate(telemetryData: Array<TelemetryDataType> = []): void {
        if (telemetryData.length > 0) {
            telemetryData.forEach((t) => this.telemetryUpdateObservable.notifyObservers(t));
        }
    }

    public setSessionControllerInternal(sessionControllerInternal: SessionControllerInternal) {
        this.sessionControllerInternal = sessionControllerInternal;
    }

    public startSession() {
        if (this.restartController) {
            this.restartController.startSession();
        } else {
            this.sessionControllerInternal!.start();
        }
    }

    public async createRestartController(
        sessionProxy: SessionControllerInternalProxy,
        sessionItem: SessionItem,
        sessionControllerInternalFactory: () => SessionControllerInternal
    ) {
        if (this.restartController) {
            return;
        }

        this.restartController = new SessionRestartController(sessionControllerInternalFactory, sessionProxy, sessionItem);
        this.restartController.initialise();
    }

    public onRestartSessionInitiatedHandler(callback: (restartOptions: RestartOptions) => RestartOptions): void {
        const handler = (restartOptions: RestartOptions) => {
            const modifiedRestartOptions = callback(restartOptions);
            this.restartSessionOverrides = modifiedRestartOptions;
            this.handleRestartInitiated();
        };
        this.restartController!.onRestartSessionInitiated(handler);
    }

    public setAdvertsManager(advertsManager: AdvertsManager) {
        this.advertsManager = advertsManager;
    }

    private updateState = (updateState: () => VideoStartupState) => {
        try {
            this._state = updateState();
            this.logCurrentState();
        } catch (error) {
            this.logger.error(error);
        }
    };

    private setShouldSkipAdvertisements() {
        this._shouldSkipAdvertisements = true;
    }

    private setCachedModifiedPlayoutData(playoutData: PlayoutData) {
        this._cachedModifiedPlayoutData = playoutData;
    }

    private setCachedOriginalPlayoutData(playoutData: PlayoutData) {
        this._cachedOvpOriginalPlayoutData = playoutData;
    }

    public initialise(): void {
        this.updateState(this._state.initialise);
    }

    public async callVideoProvider(
        sessionItem: SessionItem,
        videoPlatformIntegration: VideoPlatformIntegration,
        isPrefetch: boolean
    ): Promise<boolean> {
        // TODO: for CDN switch purposes (to investigate we might need to check if the original playout data is already set - CDN switch? - if so we don't get it again)
        const modifiedPlayoutData = await this.videoProviderController.fetchPlayoutData(
            sessionItem,
            videoPlatformIntegration,
            this.restartSessionOverrides,
            isPrefetch
        );

        if (this.isDestroyed) {
            this.logger.info(`Video Startup is destroyed, aborting last action`);
            return false;
        }

        this.notifyTelemetryUpdate(this.getVideoProviderControllerTelemetryData());

        this.setCachedOriginalPlayoutData(modifiedPlayoutData);
        this.setCachedModifiedPlayoutData(modifiedPlayoutData);

        this.updateState(this._state.callVideoProvider);

        return true;
    }

    public getVideoProviderControllerTelemetryData(): Array<TelemetryDataType> {
        return this.videoProviderController.getTelemetryData();
    }

    public skipAdvertisement(): void {
        this.setShouldSkipAdvertisements();
        this.updateState(this._state.skipAdvertisement);
    }

    public initialiseAdverts(sessionItem: SessionItem): void {
        const modifiedPlayoutData = this.advertsManager.initialise(sessionItem, this.cachedModifiedPlayoutData!);
        this.setCachedModifiedPlayoutData(modifiedPlayoutData);

        this.updateState(this._state.initialiseAdverts);

        if (this.advertsManager.getAdvertisementInitialisationStatus() === AdvertsManagerState.Disabled) {
            this.skipAdvertisement();
        }
    }

    public async callVam(sessionItem: SessionItem, context?: { isPrefetch: boolean }): Promise<boolean> {
        const modifiedPlayoutData = await this.advertsManager.fetchVamData(sessionItem, this.cachedOvpOriginalPlayoutData! as PlayoutData, context);

        if (this.isDestroyed) {
            this.logger.info(`Video Startup is destroyed, aborting last action`);
            return false;
        }

        this.setCachedModifiedPlayoutData(modifiedPlayoutData);

        this.updateState(this._state.callVam);

        return true;
    }

    public async bootstrapAds(): Promise<boolean> {
        await this.advertsManager.bootstrapAds(this.cachedModifiedPlayoutData!);
        this.updateState(this._state.bootstrapAds);
        return true;
    }

    public async callAdsData(): Promise<boolean> {
        const modifiedPlayoutData = await this.advertsManager.fetchAdvertisementData(
            this.cachedModifiedPlayoutData!,
            this.cachedOvpOriginalPlayoutData!
        );

        if (this.isDestroyed) {
            this.logger.info(`Video Startup is destroyed, aborting last action`);
            return false;
        }

        this.setCachedModifiedPlayoutData(modifiedPlayoutData);

        this.updateState(this._state.callAdsData);

        return true;
    }

    public createEngineStarter(sessionItem: SessionItem): void {
        this._engineStarter = new VideoStartupEngineStarter(sessionItem, this.videoStartupEngineStarterOptions);
    }

    public async startEngine(sessionItem: SessionItem, context?: { isPrefetch: boolean }): Promise<void> {
        if (!this.engineStarter) {
            this.createEngineStarter(sessionItem);
        }

        const modifiedPlayoutData = await this._engineStarter!.startEngine(this.cachedModifiedPlayoutData!, context?.isPrefetch);

        if (this.state.type() === VideoStartupStates.Discarded) {
            // There was a failure during engine start
            return;
        }

        if (!modifiedPlayoutData) {
            return;
        }

        this.setCachedModifiedPlayoutData(modifiedPlayoutData);
        this.updateState(this._state.startEngine);
    }

    /**
     * Destroy entities that have the same lifecycle as an internal session
     */
    private destroyVideoStartupEntities() {
        this.telemetryUpdateObservable.unregisterObservers(this);
        this.engineStarter?.destroy();

        this._cachedModifiedPlayoutData = null;
        this._cachedOvpOriginalPlayoutData = null;
        this._shouldSkipAdvertisements = false;
        this.sessionControllerInternal = undefined;
    }

    /**
     * Destroy entities that have the same lifecycle as the public facing session
     */
    private destroyUserSessionEntities() {
        this.restartController?.destroy();
    }

    public discard(): void {
        this.destroyVideoStartupEntities();
        this.destroyUserSessionEntities();
        this.updateState(this._state.discard);
    }

    public destroy(): void {
        this.destroyVideoStartupEntities();
        this.destroyUserSessionEntities();
        this.updateState(this._state.destroy);
    }

    private logCurrentState(): void {
        this.logger.verbose(`Video Startup Stage set to: ${this._state}`);
    }

    private handleRestartInitiated(): void {
        this.destroyVideoStartupEntities();
        this.updateState(this._state.retry);
    }

    public isRestarting(): boolean {
        return Boolean(this.restartController?.isRestarting());
    }

    public async restart(): Promise<void> {
        if (this.restartController!.isRestarting()) {
            this.logger.info(`Attempting ${SessionRestartType.RESTART} on Retry Controller while it is already restarting`);
            return;
        }
        await this.restartController!.restart(SessionRestartType.RESTART);
    }
}
