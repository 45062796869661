/**
 * @public
 */
export enum ContentType {
    Movies,
    Entertainment,
    Sports,
    Kids,
    News,
    Other,
}

/**
 * see {@link https://github.com/NBCUDTC/client-lib-js-ott/blob/master/packages/client-lib-js-ott-cms/src/constants/node-types.js | node types}
 * @public
 */
export enum AssetType {
    Blooper = 'ASSET/SHORTFORM/BLOOPER',
    Clip = 'ASSET/SHORTFORM/CLIP',
    Episode = 'ASSET/EPISODE',
    Featurette = 'ASSET/SHORTFORM/FEATURETTE',
    Narrative = 'ASSET/SHORTFORM/NARRATIVE',
    Presentation = 'ASSET/SHORTFORM/PRESENTATION',
    Press = 'ASSET/SHORTFORM/PRESS',
    Programme = 'ASSET/PROGRAMME',
    Promotion = 'ASSET/SHORTFORM/PROMOTION',
    Teaser = 'ASSET/SHORTFORM/TEASER',
    Theatrical = 'ASSET/SHORTFORM/THEATRICAL',

    // TODO remove these lowercase duplicates on a breaking release
    programme = 'ASSET/PROGRAMME',
    episode = 'ASSET/EPISODE',
}

/**
 * @public
 */
export enum EventStage {
    UPCOMING = 'UPCOMING',
    DELAYED = 'DELAYED',
    CANCELLED = 'CANCELLED',
    POSTPONED = 'POSTPONED',
    LIVE = 'LIVE',
    CONCLUDED = 'CONCLUDED',
    REPLAY = 'REPLAY',
}

/**
 * @public
 */
export enum VideoInitiate {
    Manual = 'Manual',
    Autoplay = 'Auto-play',
    Continuous = 'Continuous',
}

/**
 * @public
 */
export enum VideoCuration {
    Personalized = 'Personalized',
    Editorial = 'Editorial',
    Sort = 'Sort',
}

export enum NielsenTrackingType {
    DTVR = 'DTVR',
    DCR = 'DCR',
    NOT_REQUIRED = 'NOT_REQUIRED',
}
