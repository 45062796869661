import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';
import type { OVPTokenServiceInstance } from '@sky-uk-ott/client-lib-js-ott-ovp-token';

import type { AddonsConfig, Proposition } from '../config/internal-config';
import { CoreVideoInternal } from '../core-video-internal';
import type { AdInsertionConfig, PlayoutData } from '../core/player/playout-data';
import { AdInsertionStrategy, AdsFailoverReason } from '../core/player/playout-data';
import type { SessionItem } from '../core/session-controller/session-controller';
import type { InternalSessionInterface } from '../core/session-controller/session-controller-internal';
import type { AdvertBoltOns } from '../core/session-controller/timeline/timeline-manager';
import type { CvsdkError } from '../error';
import { sdkLogger } from '../logger';
import type { BaseSsaiAdBreaksProvider } from '../players/bolt-ons/ad-breaks-provider/base-ssai-ad-breaks-provider';
import type { AdEventsManager } from '../players/bolt-ons/ad-events-manager';
import type { AdBreakTimeAdapter } from '../players/bolt-ons/ad-break-time-adapter';
import type { SessionSideAdTransitionBoltOn } from '../players/bolt-ons/session-side-ad-transition-bolt-on';
import type { SessionSideThumbnailInfoUtils } from '../players/bolt-ons/session-side-thumbnail-info-utils';
import type { TimedActionsScheduler } from '../players/bolt-ons/timed-actions-scheduler';
import type { CatchupSeekEvent } from '../propositions/ad-policy/ad-policy-manager';
import { AdPolicyManager } from '../propositions/ad-policy/ad-policy-manager';

import type { AdvertisingData } from './addon-playout-data';
import type { AddonsFactories, AdInsertionAddon, AdInsertionSessionData } from './addons-factories';
import { AdvertsInsertionStageManager } from './adverts-manager-stages/adverts-insertion-stage-manager';
import { AdvertsInitialisationStageManager } from './adverts-manager-stages/adverts-initialisation-stage-manager';
import type { Ad, AdBreak } from './adverts/common';
import { AdBreakSource, AdBreakUpdateMergeStrategy, SsaiStitcherType } from './adverts/common';
import { CompanionAdvertDispatcher } from './adverts/companion-adverts/companion-advert-dispatcher';
import { MidBreakSeekingEventsAddon } from './adverts/mid-break-seeking-events-addon';
import { PauseAdvertDispatcher } from './adverts/pause-adverts/pause-advert-dispatcher';
import type { ServerAdvertsAddon } from './adverts/server-adverts/server-adverts-addon';
import type { AdAssetGroup, VodAdvertsAddon } from './adverts/vod-adverts/vod-adverts-addon';
import { AdvertBufferingBreadcrumbAddon } from './debug/advert-buffering-breadcrumb-addon';
import type { Vac } from './vac/vac-addon';
import { AdvertsVacStageManager } from './adverts-manager-stages/adverts-vac-stage-manager';
import { AdInsertionType } from './ad-insertion-type';
import type { SessionControllerInternalProxy } from '../core/session-controller/session-controller-internal-proxy';
import { JsonUtils } from '../utils/json-utils';

export type AdvertSession = Pick<
    InternalSessionInterface,
    | 'onPlaybackTimelineUpdated'
    | 'onAdBreakDataReceived'
    | 'getProposition'
    | 'notifyAdPositionChanged'
    | 'onAdBreakFinished'
    | 'onAdBreakStarted'
    | 'onAdPositionChanged'
    | 'onAdStarted'
    | 'onAdFinished'
    | 'onError'
    | 'onStateChanged'
    | 'onSeekStarted'
    | 'onTimedMetadataReceived'
    | 'onMuteChanged'
    | 'onSeekEnded'
    | 'onSubtitlesTrackChanged'
    | 'notifyAdBreakDataReceived'
    | 'notifyAdBreakStarted'
    | 'notifyAdBreakFinished'
    | 'notifyAdStarted'
    | 'notifyAdFinished'
    | 'notifyAdTrackingReceived'
    | 'updateTelemetryMetrics'
    | 'onBulkTimedMetadataReceived'
    | 'getLiveWindow'
    | 'registerCue'
    | 'unregisterCue'
    | 'onEventCueEntered'
    | 'onEventCueExited'
    | 'onEventCueRegistered'
    | 'onEventCueUnregistered'
>;
export class AdvertsManager {
    private adInsertionAddon?: AdInsertionAddon | null = null;
    private ssaiAddon?: ServerAdvertsAddon;
    private csaiAddon: VodAdvertsAddon | null = null;
    private isNativeCsai?: boolean = false;
    private adInsertionConfig!: AdInsertionConfig;
    private pauseAdvertDispatcher?: PauseAdvertDispatcher;
    private companionAdvertDispatcher?: CompanionAdvertDispatcher;
    private sessionSideAdTransitionBoltOn?: SessionSideAdTransitionBoltOn;
    private sessionSideThumbnailInfoUtils: SessionSideThumbnailInfoUtils | null = null;
    private adPolicyManager?: AdPolicyManager;
    private adBreakTimeAdapter?: AdBreakTimeAdapter;
    private adBreaksProvider?: BaseSsaiAdBreaksProvider;
    private adEventsManager?: AdEventsManager;
    private timedActionsScheduler?: TimedActionsScheduler;
    private advertBufferingBreadcrumbAddon: AdvertBufferingBreadcrumbAddon | null = null;
    private midBreakSeekingEventsAddon?: MidBreakSeekingEventsAddon;

    private sessionItem?: SessionItem;
    private proposition: Proposition;
    private isVacEnabled?: boolean = false;
    private vacAddon?: Vac.Addon | null = null;

    private logger: Logger = sdkLogger.withContext('AdvertsManager');

    private advertsInitialisationStageManager: AdvertsInitialisationStageManager;
    private advertsVacStageManager: AdvertsVacStageManager | null = null;
    private advertsInsertionStageManager: AdvertsInsertionStageManager | null = null;
    private vodAdverts?: Array<AdAssetGroup>;

    private playoutData!: PlayoutData;

    constructor(
        private adInsertionClientConfig: AddonsConfig['adInsertion'],
        private session: InternalSessionInterface,
        private addonsFactories: AddonsFactories,
        private umvToken: Promise<string | undefined>,
        private getToken?: OVPTokenServiceInstance['getToken']
    ) {
        this.proposition = CoreVideoInternal.config.staticConfig.proposition;
        this.advertsInitialisationStageManager = new AdvertsInitialisationStageManager(this.adInsertionClientConfig);
    }

    public initialise(sessionItem: SessionItem, initialPlayoutData: PlayoutData) {
        this.playoutData = this.advertsInitialisationStageManager.initialise(sessionItem, initialPlayoutData);

        if (this.advertsInitialisationStageManager.isDisabled()) {
            return this.playoutData;
        }

        this.sessionItem = sessionItem;

        this.vacAddon = this.addonsFactories.vac?.(this.adInsertionClientConfig, sessionItem, initialPlayoutData, this.umvToken, this.getToken);
        this.isVacEnabled = Boolean(this.vacAddon);

        this.createAdInsertionAddon(initialPlayoutData, this.umvToken);
        if (!this.adInsertionAddon && !this.isNativeCsai) {
            return initialPlayoutData;
        }

        this.createAdInsertionComponents(sessionItem);

        return this.playoutData;
    }

    public async fetchVamData(sessionItem: SessionItem, initialPlayoutData: PlayoutData, context?: { isPrefetch: boolean }): Promise<PlayoutData> {
        this.advertsVacStageManager = new AdvertsVacStageManager(
            this.adInsertionClientConfig,
            this.session,
            this.adInsertionAddon!,
            this.adInsertionConfig,
            this.isVacEnabled!,
            this.vacAddon,
            this.isNativeCsai,
            context?.isPrefetch
        );

        return this.advertsVacStageManager.fetchVacData(sessionItem, initialPlayoutData);
    }

    public async bootstrapAds(populatedPlayoutData: PlayoutData) {
        try {
            await this.ssaiAddon?.bootstrap(populatedPlayoutData);
        } catch (e) {
            const message = 'Error bootstrapping ads, ads may not be shown';
            this.logger.warn(message, JsonUtils.stringify(e));
            const code = 'SDK.ADVERTSMGR.SETUP_ADS.SSAI.BOOTSTRAP_ADS';
            this.session.notifyWarning(code, message);
            populatedPlayoutData.adsFailoverReason = this.ssaiAddon?.getAdsFailoverReason();
            this.destroy();
        }
    }

    public async fetchAdvertisementData(populatedPlayoutData: PlayoutData, initialPlayoutData: PlayoutData) {
        this.advertsInsertionStageManager = new AdvertsInsertionStageManager(
            this.session,
            populatedPlayoutData,
            this.pauseAdvertDispatcher!,
            this.adPolicyManager!,
            this.adBreaksProvider!,
            this.adInsertionAddon!,
            this.ssaiAddon!
        );

        const { supportsNativeCsai } = CoreVideoInternal.playerCapabilities;

        const modifiedPlayoutData = await this.advertsInsertionStageManager.fetchAdvertisementData(populatedPlayoutData, initialPlayoutData, {
            supportsNativeCsai,
        });

        if (supportsNativeCsai) {
            modifiedPlayoutData.csaiAdBreaks = (await this.getVodAdverts()).map((group) => group.adBreak);
            if (modifiedPlayoutData.csaiAdBreaks && !this.adEventsManager) {
                const message = 'Error processing vod ad breaks. No Ad Events Manager present.';
                this.logger.warn(message);
                const code = 'SDK.ADVERTSMGR.SETUP_ADS.SLE_PREROLL';
                this.session.notifyWarning(code, message);
            } else {
                this.adEventsManager?.handleAdBreakDataReceived({
                    adBreaks: modifiedPlayoutData.csaiAdBreaks,
                    source: AdBreakSource.OutOfBand,
                    mergeStrategy: AdBreakUpdateMergeStrategy.Merge,
                });
            }
        }

        this.adInsertionConfig = { ...this.adInsertionConfig, ...modifiedPlayoutData.adInsertionConfig };
        if (modifiedPlayoutData.adsFailoverReason) {
            this.destroy();
        }

        return modifiedPlayoutData;
    }

    private createAdInsertionComponents(sessionItem: SessionItem): void {
        this.advertBufferingBreadcrumbAddon = new AdvertBufferingBreadcrumbAddon(this.session);
        this.midBreakSeekingEventsAddon = new MidBreakSeekingEventsAddon(this.session);

        if (this.adInsertionAddon) {
            this.adInsertionConfig = this.adInsertionAddon.playoutOptions!;
            this.adInsertionAddon.setVacAddon(this.vacAddon!);

            switch (this.adInsertionConfig.adInsertionStrategy) {
                case AdInsertionStrategy.SSAI:
                    this.ssaiAddon = this.adInsertionAddon as ServerAdvertsAddon;
                    this.adInsertionConfig.ssaiStitcherType = this.ssaiAddon.getSsaiStitcherType();
                    this.sessionSideAdTransitionBoltOn = this.ssaiAddon.getSessionSideAdTransitionBoltOn();
                    this.sessionSideThumbnailInfoUtils = this.ssaiAddon.getSessionSideThumbnailInfoUtils();
                    // Case Yospace, create AdPolicy in the addon-manager
                    // This is needed because Yospace notifies the session with ad events and by creating the ad policy manager
                    // at this level, we'll be able to have access to those events and pass them down to the Yospace ad events manager
                    this.adPolicyManager = this.ssaiAddon.getAdPolicyManager();
                    this.adBreaksProvider = this.ssaiAddon.getAdBreaksProvider();
                    this.adBreakTimeAdapter = this.ssaiAddon.getAdBreakTimeAdapter();
                    this.adEventsManager = this.ssaiAddon.getAdEventsManager();
                    this.timedActionsScheduler = this.ssaiAddon.getTimedActionsScheduler();

                    break;
                case AdInsertionStrategy.CSAI:
                    this.csaiAddon = this.adInsertionAddon as VodAdvertsAddon;
                    this.adInsertionConfig.ssaiStitcherType = SsaiStitcherType.None;
                    this.adPolicyManager = new AdPolicyManager({
                        isSeekManagementRequired: true,
                        isWatchedAdsManagementRequired: false,
                        allowSeekingDuringAdBreaks: false,
                        allowAdSkipCatchup: false,
                        proposition: this.proposition,
                        sessionItem: sessionItem,
                    });
                    break;
                default:
                    break;
            }
        } else if (this.isNativeCsai) {
            const nativeCsaiAdInsertionConfig = {
                adInsertionStrategy: AdInsertionStrategy.CSAI,
                isPlayerAdEventsRequired: true,
                isPlayerAdBreakDataRequired: false,
                isTimelineAdManagementRequired: false,
                adProvider: AdInsertionType.NativeCsai,
                ssaiStitcherType: SsaiStitcherType.None,
            };
            this.adPolicyManager = new AdPolicyManager({
                isSeekManagementRequired: false,
                isWatchedAdsManagementRequired: !CoreVideoInternal.playerCapabilities.hasNativeWatchedAdsManagement,
                allowSeekingDuringAdBreaks: false,
                allowAdSkipCatchup: false,
                proposition: this.proposition,
                sessionItem: sessionItem,
            });
            this.adInsertionConfig = nativeCsaiAdInsertionConfig;
        }
        this.adPolicyManager?.onCatchupSeek(this.handleCatchupSeek);
    }

    private createAdInsertionAddon(initialPlayoutData: PlayoutData, umvToken?: Promise<string | undefined>): AdInsertionAddon | undefined {
        this.pauseAdvertDispatcher = new PauseAdvertDispatcher(this.session);
        const disableCompanionAdsWhenCaptionEnabled =
            this.sessionItem?.vac?.disableFrameAdsWhenCaptionEnabled || this.sessionItem?.vac?.disableCompanionAdsWhenCaptionEnabled;
        this.companionAdvertDispatcher = new CompanionAdvertDispatcher(this.session, disableCompanionAdsWhenCaptionEnabled);
        const sessionData: AdInsertionSessionData = {
            sessionAdEvents: this.session,
            sessionItem: this.sessionItem!,
            playoutData: initialPlayoutData,
            hasNativeAdEvents: CoreVideoInternal.playerCapabilities.hasNativeAdEvents!,
            pauseAdvertDispatcher: this.pauseAdvertDispatcher,
            companionAdvertDispatcher: this.companionAdvertDispatcher,
            adInsertionConfig: this.adInsertionClientConfig,
            playerType: CoreVideoInternal.getCurrentPlayerTypeForPlayoutData(initialPlayoutData),
            umvToken,
        };

        try {
            this.adInsertionAddon = this.addonsFactories.adInsertion?.(sessionData);
            if (!this.adInsertionAddon && this.isVacEnabled) {
                this.isNativeCsai = true;
            }
        } catch (e) {
            this.logger.error('Unable to instantiate Ad Insertion Addon', e);
            this.adInsertionAddon = null;
            return;
        }
    }

    public finishCurrentAd(sessionProxy: SessionControllerInternalProxy): void {
        const ad = this.getCurrentAd();
        const adBreak = this.getCurrentAdBreak();

        if (ad) {
            sessionProxy.notifyAdFinished(ad);
        }

        if (adBreak) {
            sessionProxy.notifyAdBreakFinished(adBreak);
        }
    }

    private handleCatchupSeek = (catchupSeek: CatchupSeekEvent) => {
        this.session.notifyCatchupSeek(catchupSeek);
    };

    public getVodStartPositionAfterCurrentAdvert(): number | null {
        // Return a position 0.5s after the current ad break start
        const currentAdBreak = this.getCurrentAdBreak();
        if (!currentAdBreak) {
            return null;
        }
        const adBreakStartPosition = typeof currentAdBreak.position === 'number' ? currentAdBreak.position : 0;
        return adBreakStartPosition + 0.5;
    }

    public getCurrentAdBreak(): AdBreak<Ad> | null | undefined {
        return this.advertsInsertionStageManager?.getCurrentAdBreak();
    }

    public getCurrentAd(): Ad | null | undefined {
        return this.advertsInsertionStageManager?.getCurrentAd();
    }

    public getAdInsertionConfig(): AdInsertionConfig {
        return this.adInsertionConfig;
    }

    public getAdvertBoltOns(): AdvertBoltOns {
        return {
            adBreakTimeAdapter: this.adBreakTimeAdapter,
            transition: this.sessionSideAdTransitionBoltOn,
            policyManager: this.adPolicyManager,
            breaksProvider: this.adBreaksProvider,
            eventsManager: this.adEventsManager,
            timedActionsScheduler: this.timedActionsScheduler,
            pauseAdvertDispatcher: this.pauseAdvertDispatcher,
        };
    }

    public getPauseAdvertDispatcher(): PauseAdvertDispatcher | undefined {
        return this.pauseAdvertDispatcher;
    }

    public getThumbnailInfoUtils(): SessionSideThumbnailInfoUtils | null {
        return this.sessionSideThumbnailInfoUtils;
    }

    public async getVodAdverts(): Promise<Array<AdAssetGroup>> {
        if (this.vodAdverts) {
            return this.vodAdverts;
        }
        if (this.csaiAddon) {
            this.vodAdverts = await this.csaiAddon.getAdvertsForSession();
        } else if (this.advertsInsertionStageManager?.getTimelineAdsAddon()) {
            this.vodAdverts = await this.advertsInsertionStageManager.getTimelineAdsAddon()!.getAdvertsForSession();
        }

        if (!this.vodAdverts) {
            this.vodAdverts = [];
        }

        return this.vodAdverts;
    }

    public handlePositionChange(timeIncludingAds: number): void {
        if (this.ssaiAddon) {
            this.ssaiAddon.handlePositionChange(timeIncludingAds);
        }
    }

    public handleSeekStarted(timeIncludingAds: number): void {
        if (this.adBreakTimeAdapter) {
            const adPosition = this.adBreakTimeAdapter.timeIncludingAdsToAdPosition(timeIncludingAds);
            this.midBreakSeekingEventsAddon?.handleSeekStarted(adPosition!);
        }
    }

    public timeIncludingAdsToContentTime(timeIncludingAds: number): number {
        if (this.adBreakTimeAdapter) {
            return this.adBreakTimeAdapter.timeIncludingAdsToContentTime(timeIncludingAds);
        } else {
            return timeIncludingAds;
        }
    }

    public manageStartPosition(timeIncludingAds: number): number {
        if (this.adPolicyManager) {
            return this.adPolicyManager.manageStartPosition(timeIncludingAds);
        }
        return timeIncludingAds;
    }

    public contentTimeToTimeIncludingAds(contentTime: number): number {
        if (this.adBreakTimeAdapter) {
            return this.adBreakTimeAdapter.contentTimeToTimeIncludingAds(contentTime);
        } else {
            return contentTime;
        }
    }

    public adaptAdBreaksPositionsToContentTime(adBreaks: Array<AdBreak>): Array<AdBreak> {
        if (this.adBreakTimeAdapter) {
            return adBreaks.map(this.adBreakTimeAdapter.adBreakPositionIncludingAdsToContentPosition, this);
        } else {
            return adBreaks;
        }
    }

    public adBreakPositionIncludingAdsToContentPosition(adBreak: AdBreak): AdBreak {
        if (this.adBreakTimeAdapter) {
            return this.adBreakTimeAdapter.adBreakPositionIncludingAdsToContentPosition(adBreak);
        } else {
            return adBreak;
        }
    }

    public notifyManifestLoadError(error: CvsdkError): void {
        this.destroy();
    }

    public modifyPlayoutDataForCdnSwitch(playoutData: PlayoutData): PlayoutData {
        return this.ssaiAddon !== undefined ? { ...playoutData, adsFailoverReason: AdsFailoverReason.Generic } : playoutData;
    }

    public getAdvertisementInitialisationStatus(): string {
        return this.advertsInitialisationStageManager.getInitialisationStatus();
    }

    public isInitialPositionAdjustmentRequired(): boolean {
        return Boolean(this.ssaiAddon?.isInitialPositionAdjustmentRequired);
    }

    public destroy(): void {
        this.logger.verbose(`Destroying Adverts Manager`);
        this.vacAddon?.destroy();
        this.adInsertionAddon?.destroy();
        this.advertBufferingBreadcrumbAddon?.destroy();
        this.adPolicyManager?.disable();

        this.advertsInsertionStageManager?.destroy();
        this.advertsVacStageManager = null;
        this.advertsInsertionStageManager = null;

        this.vacAddon = null;
        this.adInsertionAddon = null;
        // @ts-ignore
        this.adInsertionConfig = null;
        this.ssaiAddon = undefined;
        this.csaiAddon = null;
        this.advertBufferingBreadcrumbAddon = null;
        this.sessionSideAdTransitionBoltOn = undefined;
        this.pauseAdvertDispatcher = undefined;
        this.adPolicyManager = undefined;
        this.adBreaksProvider = undefined;
        this.adBreakTimeAdapter = undefined;
        this.adEventsManager = undefined;
        this.timedActionsScheduler = undefined;
    }

    public get advertisingData(): AdvertisingData | undefined {
        return this.adInsertionAddon?.advertisingData;
    }
}
