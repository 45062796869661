// cspell:words QWILT EDGIO caas MEDIACDN
import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';

import { CoreVideoInternal } from '../../core-video-internal';
import type { Cdn } from '../player/playout-data';

import { sdkLogger } from '../../logger';
import { JsonUtils } from '../../utils/json-utils';
import { TestingOverrides } from '../services/testing-overrides';
import { UpdateType } from './meta-list.enums';

export type CdnAbbreviationMapping = { [abbreviation: string]: string };

const KnownCdns: CdnAbbreviationMapping = {
    LIMELIGHT: 'll',
    EDGIO: 'll', // note: Peacock uses the name LIMELIGHT for this CDN, but Showmax uses EDGIO
    FASTLY: 'fy',
    CLOUDFRONT: 'cf',
    AKAMAI: 'ak',
    COMCAST: 'cc',
    LEVEL3: 'cl',
    GOOGLE: 'mc',
    MEDIACDN: 'mc',
    QWILT: 'qw',
} as const;

type TimelineEvents = {
    id: string;
    data: {
        momentId?: string;
        [key: string]: unknown;
    };
    startTime: number;
    endTime: number;
    type: string;
    persistent: boolean;
};

export type MetaListInstructions = {
    pvid: string;
    globalConfigs: CapInstructions;
    timelineEvents: Array<TimelineEvents>;
};

export type CapInstructions = {
    capInstructions: {
        [key: string]: number | null;
    };
    timeToLiveSeconds?: number;
};

const CDN_URL_PATHNAME = '/metalist/api/v1/';
const LOOPING_TIME_MS = 4_000;
const MIN_FETCH_TIMEOUT_MS = 3_000;

export class MetaListManager {
    private logger: Logger = sdkLogger.withContext('MLM');
    private metaListInstructionPollingTimeout: NodeJS.Timer | undefined = undefined;
    private callBacks: Array<(jsonResponse: MetaListInstructions, cdnName: string) => boolean> = [];
    private primaryCdn: Cdn | undefined;
    private cdns: Array<Cdn> = [];
    private options?: RequestInit;
    private startIndex = 0;
    private isRequestPending = false;
    private updateType: UpdateType = UpdateType.NO_UPDATE;
    private abortController?: AbortController;
    private shouldAbort = {
        abortTimerHit: false,
        manifestHasUpdated: false,
    };

    constructor(
        private notifyWarning: (errorCode: string, reason: string) => void,
        private providerVariantId: string,
        private minTimeoutMs = MIN_FETCH_TIMEOUT_MS
    ) {}

    public async handleManifestUpdated() {
        this.updateType = UpdateType.MANIFEST_UPDATE;
        this.stopMetaListInstructionPolling();
        if (!this.isRequestPending) {
            await this.makeRequest();
        } else {
            this.abortControllerManager(false);
        }
    }

    private clearShouldAbort() {
        this.shouldAbort.abortTimerHit = false;
        this.shouldAbort.manifestHasUpdated = false;
    }

    private abortControllerManager(isTimer: boolean) {
        if (isTimer) {
            this.shouldAbort.abortTimerHit = true;
        } else {
            this.shouldAbort.manifestHasUpdated = true;
        }
        if (this.updateType === UpdateType.TIMELINE_UPDATE || Object.values(this.shouldAbort).every(Boolean)) {
            this.clearShouldAbort();
            this.abortController?.abort();
        }
    }

    public registerCallBack(callBack: (jsonResponse: MetaListInstructions, cdnName: string) => boolean) {
        this.callBacks.push(callBack);
    }

    public setPrimaryCdn(cdn: Cdn): void {
        this.primaryCdn = cdn;
    }

    public handleStartPooling(cdns: Array<Cdn>, options?: RequestInit, startIndex = 0) {
        this.cdns = getPrioritizedCdns(this.primaryCdn, cdns);
        this.options = options;
        this.startIndex = startIndex;
        if (this.updateType === UpdateType.NO_UPDATE) {
            this.updateType = UpdateType.TIMELINE_UPDATE;
            this.metaListPollingCycleHandler();
        }
    }

    private getRequestUrl(currentCdn: Cdn): string {
        const metalistEndpointOverride = TestingOverrides.metalistEndpointOverride;
        if (metalistEndpointOverride) {
            return metalistEndpointOverride;
        }

        const cdnAbbreviation = KnownCdns[currentCdn.name];
        const cappingUrlData = CoreVideoInternal.getPropositionExtensions()?.coordinatedBitrateCapping?.urlPieces;

        if (!cappingUrlData || !cdnAbbreviation) {
            return '';
        }

        const cdnUrl = currentCdn.originalUrl ?? currentCdn.url; // prod uses both original url and url, stable-int has only url
        const { domainBase, domainMid, domainProd, domainStage, host } = cappingUrlData;
        const domainNumber = cdnUrl.match('^https://[a-z][0-9][0-9]([0-9])')?.[1] ?? '1';
        const domainPrdOrStg = cdnUrl.slice(0, cdnUrl.indexOf(host)).includes(domainStage) ? domainStage : domainProd;

        return `${domainBase}${domainNumber}${domainMid}${domainPrdOrStg}${cdnAbbreviation}.${host}${CDN_URL_PATHNAME}${this.providerVariantId}`;
    }

    private makeRequest = async (): Promise<void> => {
        if (this.callBacks.length === 0 || this.isRequestPending) {
            return;
        }
        this.isRequestPending = true;
        let i = 0;
        const { cdns, options } = this;
        this.clearShouldAbort();
        for (; i < cdns.length; i++) {
            // Do this to start in middle of array and loop back to beginning if necessary
            const currentIndex: number = (i + this.startIndex) % cdns.length;
            const currentCdn = cdns[currentIndex];
            try {
                const cdnMetaListUrl = this.getRequestUrl(currentCdn);
                if (!cdnMetaListUrl) {
                    continue;
                }
                this.abortController = new AbortController();
                const timeoutId = setTimeout(() => this.abortControllerManager(true), this.minTimeoutMs);

                const response = await fetch(cdnMetaListUrl, { ...options, signal: this.abortController.signal });
                clearTimeout(timeoutId);
                if (!response.ok) {
                    this.notifyWarning('METALIST', `${currentCdn.name} - ${response.statusText}`);
                    this.logger.warn(`Error in meta list instructions response: ${response.statusText}`);
                    continue;
                }

                const jsonResponse = await response.json();

                if (jsonResponse) {
                    let success = true;
                    for (let j = 0; j < this.callBacks.length; j++) {
                        success = this.callBacks[j](jsonResponse, currentCdn.name);
                        if (!success) {
                            break;
                        }
                    }
                    if (success) {
                        this.startIndex = currentIndex;

                        // Only get the first successful response
                        break;
                    } else {
                        this.notifyWarning('METALIST', `${currentCdn.name} - ${response.statusText}`);
                        this.logger.warn(`Meta list instructions response unexpected format: ${response.statusText}`);
                    }
                }
            } catch (error) {
                this.notifyWarning('METALIST', `${currentCdn.name} - ${JsonUtils.stringify(error)}`);
                this.logger.warn(`Error fetching meta list instructions: ${JsonUtils.stringify(error)}`);
            }
        }
        if (i === cdns.length) {
            // If we reach here, we didn't break the loop
            // i.e. all of the requests failed
            this.notifyWarning('METALIST', `METALIST CDNS EXHAUSTED`);
            this.startIndex = 0;
        }
        this.isRequestPending = false;
    };

    private metaListPollingCycleHandler = async () => {
        await this.makeRequest();

        this.stopMetaListInstructionPolling();
        // Regardless of failure or success, set new timeout
        if (this.updateType === UpdateType.TIMELINE_UPDATE) {
            this.metaListInstructionPollingTimeout = setTimeout(() => {
                this.metaListPollingCycleHandler();
            }, LOOPING_TIME_MS);
        }
    };

    private stopMetaListInstructionPolling = () => {
        clearTimeout(this.metaListInstructionPollingTimeout);
        this.metaListInstructionPollingTimeout = undefined;
    };

    public destroy = () => {
        this.updateType = UpdateType.NO_UPDATE;
        this.stopMetaListInstructionPolling();
    };
}

/**
 * Helper method to move primary Cdn to the start of the cdns list.
 * @param cdns List of cdns to re-prioritize.
 * @returns If the primaryCdn url is not found (e.g. preroll) is undefined, or is already found at index 0, then the original cdns will be returned.  Otherwise returns a new Array with primaryCdn at index 0.
 */
export function getPrioritizedCdns(primaryCdn: Cdn | undefined, cdns: Array<Cdn>): Array<Cdn> {
    if (primaryCdn) {
        const activeCdnIndex = cdns.findIndex((e) => e.url === primaryCdn?.url);
        if (activeCdnIndex > 0) {
            // Note: A shallow clone for better performance. If mutation is required in the future, this should be updated to be a deep clone.
            cdns = [...cdns];
            cdns[activeCdnIndex] = cdns[0];
            cdns[0] = primaryCdn;
        }
    }
    return cdns;
}
