import clone from '@sky-uk-ott/core-video-sdk-js-external-rfdc';
import { deepEqual, isPrimitive, typesMatch } from './object-utils';
import { JsonUtils } from './json-utils';

export function removeElement<T>(arr: Array<T>, matcher: (element: T) => boolean): boolean {
    const index = arr.findIndex(matcher);
    if (index >= 0) {
        arr.splice(index, 1);
        return true;
    }
    return false;
}

/**
 *
 * @param arr Array to search - this array MUST be sorted in ascending order already
 * @param val Value to find
 * @param comparator Comparator to compare values (see array.sort method)
 * @param params Optional returnInsertionIndex. If true, insertion point will be returned if element is not found.
 * @returns -1 if not found otherwise position. If returnInsertionIndex is set, insert position will be found if element is not found.
 */
export function binarySearch<T>(
    arr: Array<T>,
    val: T,
    comparator: (elementA: T, elementB: T) => number,
    params?: { returnInsertionIndex: boolean }
): number {
    let start = 0;
    let end = arr.length - 1;

    while (start <= end) {
        const mid = Math.floor((start + end) / 2);

        if (arr[mid] === val) {
            return mid;
        }

        if (comparator(val, arr[mid]) < 0) {
            end = mid - 1;
        } else {
            start = mid + 1;
        }
    }
    if (params?.returnInsertionIndex) {
        return start;
    } else {
        return -1;
    }
}

/**
 * Insert a new element into an already sorted array
 * @param arr
 * @param newValue
 * @param comparator
 */
export function orderedInsert<T>(arr: Array<T>, newValue: T, comparator: (elementA: T, elementB: T) => number): number {
    const insertIndex = binarySearch(arr, newValue, comparator, { returnInsertionIndex: true });

    if (insertIndex < 0) {
        arr.push(newValue);
        return 0;
    } else {
        arr.splice(insertIndex, 0, newValue);
        return insertIndex;
    }
}

export function getArrayIntersection<T>(arr1?: Array<T>, arr2?: Array<T>): Array<T> {
    if (!arr1 && !arr2) {
        return [];
    }

    if (!arr1) {
        return arr2!;
    }

    if (!arr2) {
        return arr1;
    }
    return arr1.filter((entry) => arr2.includes(entry));
}

export function getStrictArrayIntersection<T>(arr1?: Array<T>, arr2?: Array<T>): Array<T> {
    if (!arr1 || !arr2) {
        return [];
    }
    return arr1.filter((entry) => arr2.includes(entry));
}

export function arrayHasIntersection<T>(arr1?: Array<T>, arr2?: Array<T>): boolean {
    if (!arr1 || !arr2) {
        return false;
    }

    return arr1.some((element) => arr2.includes(element));
}

export function overwriteMerge(destinationArray: Array<unknown>, sourceArray: Array<unknown>): Array<unknown> {
    return sourceArray;
}

function sortObjectByKeys<T extends object>(obj: T): T {
    const sortedObj = {} as T;
    const sortedKeys = Object.keys(obj).sort();

    sortedKeys.forEach((key) => {
        sortedObj[key as keyof T] = obj[key as keyof T];
    });

    return sortedObj;
}

export function arraysAreEqual<T>(a?: Array<T>, b?: Array<T>, options?: { sortArrays?: boolean; deepEqual?: boolean }) {
    const aClone = clone({})(a);
    const bClone = clone({})(b);

    if (!aClone || !bClone) {
        return false;
    }

    if (aClone === bClone) {
        return true;
    }

    if (aClone.length !== bClone.length) {
        return false;
    }

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    if (options?.sortArrays) {
        const compareFx = (a: unknown, b: unknown) => {
            const s1 = isPrimitive(a) || Array.isArray(a) ? JsonUtils.stringify(a) : JsonUtils.stringify(sortObjectByKeys(a as object));
            const s2 = isPrimitive(b) || Array.isArray(b) ? JsonUtils.stringify(b) : JsonUtils.stringify(sortObjectByKeys(b as object));

            if (s1 < s2) {
                return -1;
            } else if (s1 > s2) {
                return 1;
            }
            return 0;
        };
        aClone.sort(compareFx);
        bClone.sort(compareFx);
    }

    for (let i = 0; i < aClone.length; ++i) {
        if (options?.deepEqual) {
            if (!typesMatch(aClone[i], bClone[i])) {
                return false;
            }

            if (isPrimitive(aClone[i]) && isPrimitive(bClone[i]) && aClone[i] !== bClone[i]) {
                return false;
            } else if (
                Array.isArray(aClone[i]) &&
                Array.isArray(bClone[i]) &&
                !arraysAreEqual(aClone[i] as Array<unknown>, bClone[i] as Array<unknown>, { deepEqual: true })
            ) {
                return false;
            } else if (!deepEqual(aClone[i], bClone[i])) {
                return false;
            }
        } else if (aClone[i] !== bClone[i]) {
            return false;
        }
    }
    return true;
}
