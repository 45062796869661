export enum SecurityRestrictionReason {
    DRM = 'DRM',
    HDCP = 'HDCP',
}

export enum ContentType {
    text = 'text/plain',
    textXml = 'text/xml',
    application = 'application/x-www-form-urlencoded',
    applicationStream = 'application/octet-stream',
}
