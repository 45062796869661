import type { SessionControllerInternal } from '../../core/session-controller/session-controller-internal';

import type { Ad, AdBreak, AdPosition } from './common';

export type MidBreakSeekingEventsAddonSession = Pick<
    SessionControllerInternal,
    'onAdBreakStarted' | 'onAdBreakFinished' | 'onAdStarted' | 'onAdFinished' | 'notifyAdBreakFinished' | 'notifyAdFinished'
>;

export class MidBreakSeekingEventsAddon {
    private currentAdBreak: AdBreak | null = null;
    private currentAd: Ad | null = null;

    constructor(private session: MidBreakSeekingEventsAddonSession) {
        this.session.onAdBreakStarted((adBreak: AdBreak) => {
            this.currentAdBreak = adBreak;
        });
        this.session.onAdBreakFinished((adBreak: AdBreak): void => {
            this.currentAdBreak = null;
        });
        this.session.onAdStarted((ad: Ad): void => {
            this.currentAd = ad;
        });
        this.session.onAdFinished((ad: Ad): void => {
            this.currentAd = null;
        });
    }

    public handleSeekStarted(destinationAdPosition: AdPosition | undefined): void {
        if (destinationAdPosition) {
            this.handleSeekingWithinBreak(destinationAdPosition);
        } else {
            this.handleSeekOutOfBreak();
        }
    }

    private handleSeekOutOfBreak(): void {
        if (this.currentAd) {
            this.session.notifyAdFinished(this.currentAd);
        }

        if (this.currentAdBreak) {
            this.session.notifyAdBreakFinished(this.currentAdBreak);
        }
    }

    private handleSeekingWithinBreak(destinationAdPosition: AdPosition): void {
        if (this.currentAd && destinationAdPosition.ad && destinationAdPosition.ad.id !== this.currentAd.id) {
            this.session.notifyAdFinished(this.currentAd);
        }

        if (this.currentAdBreak && destinationAdPosition.adBreak.id !== this.currentAdBreak.id) {
            this.session.notifyAdBreakFinished(this.currentAdBreak);
        }
    }
}
