export enum Platform {
    androidtv = 'androidtv',
    chromecast = 'chromecast',
    firetv = 'firetv',
    firetvkepler = 'firetv-kepler',
    lgtv = 'lgtv',
    ps4 = 'ps4',
    ps5 = 'ps5',
    safari = 'safari',
    samsungtv = 'samsungtv',
    vidaatv = 'vidaatv',
    viziotv = 'viziotv',
    web = 'web',
    windows = 'windows',
    x1 = 'x1',
    xclass = 'xclass',
    xumosb = 'xumosb',
    youview = 'youview',
    quest = 'quest',
    netgem = 'netgem',
}

export enum StreamType {
    // https://github.com/NBCUDTC/core-video-team/wiki/VAM
    // https://github.com/sky-uk/core-video-team/issues/1156
    // https://videoadsmodulev1.docs.apiary.io/#reference/0/freewheel-params
    // - linear refers to a 24/7 channel
    linear = 'linear',
    // - live refers to a pop up/ single event
    live = 'live',
    // - vod refers to Video On Demand
    vod = 'vod',
}

export enum StreamSubType {
    // https://github.com/sky-uk/core-video-team/issues/1156
    // https://videoadsmodulev1.docs.apiary.io/#reference/0/freewheel-params
    // VOD Sub Types
    // - Short-form content (definition varies), but typically under fifteen minutes. Also referred to as "SF content". Examples: promo video, movie trailer, behind-the-scenes clip
    shortForm = 'shortForm',
    // -  Long-form content (definition varies), but typically over fifteen minutes. This could be something like a documentary or an interview  - not linked to a programme  with episodes.
    longForm = 'longForm',
    // - (FEP) Full episodes of shows that typically fit into 30-minute or 60-minute time slots on a TV broadcast. Also known as VOD (video on demand) or "FEP content".
    fullEpisodePlayer = 'fullEpisodePlayer',
    // - Content that was originally released theatrically in movie theaters.
    movie = 'movie',
    // - Content displayed in the TV channel guide but is just a playlist of VOD content
    vodChannel = 'vodChannel',
    // Live Sub Types
    // - A linear stream that is not broadcast and can only be seen on Peacock
    exclusiveChannel = 'exclusiveChannel',
    // - is a virtual recording of a live event that a user can watch after the original live event.
    fer = 'fullEventReplay',
}
