import { CompanionProvider, TrackingEventType } from './types';

export * from './types';

export const CompanionProviderMap: { [key: string]: CompanionProvider } = {
    bl_overlay: CompanionProvider.Brightline,
};

export const AD_BEACON_EVENTS = [
    TrackingEventType.AdStart,
    TrackingEventType.AdImpression,
    TrackingEventType.FirstQuartile,
    TrackingEventType.Midpoint,
    TrackingEventType.ThirdQuartile,
    TrackingEventType.AdFinished,
];
