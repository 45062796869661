import { sdkLogger } from '../../../logger';
import type { PixelFetch } from '../pixel-fetch';

import type { NonLinearAd, NonLinearAdEvent, NonLinearAdData } from './non-linear-ad-types';

export function mapNonLinearAdDataToEvent(adData: NonLinearAdData, pixelFetch: PixelFetch): NonLinearAdEvent {
    let wasConsumed = false;

    return {
        consume: () => {
            if (wasConsumed) {
                throw new Error('cannot call consume on the same event twice');
            }
            wasConsumed = true;

            if (!adData) {
                return null;
            }

            let wasStarted = false;
            let wasEnded = false;

            const nonLinearAd: NonLinearAd = {
                variants: [...adData.variants],
                shown: () => {
                    if (wasStarted) {
                        sdkLogger.warn('cannot call shown on the same ad twice');
                        return;
                    }
                    wasStarted = true;
                    adData.adStartedBeacons.forEach((b) => pixelFetch.fetch(b));
                },
                ended: () => {
                    if (!wasStarted) {
                        // no logging as this is an expected execution path
                        return;
                    }
                    if (wasEnded) {
                        sdkLogger.warn('cannot dismiss the same ad twice');
                        return;
                    }
                    wasEnded = true;
                    adData.adFinishedBeacons.forEach((b) => pixelFetch.fetch(b));
                },
            };

            if (adData.variants.length === 0) {
                // special case: beacons for empty ads are fired automatically
                wasStarted = true;
                adData.adStartedBeacons.forEach((b) => pixelFetch.fetch(b));
                delete nonLinearAd.shown;
            }

            return nonLinearAd;
        },
    };
}
