import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';
import { v4 as uuid } from 'uuid';
import deepmerge from 'deepmerge';
import type { AddonsFactories } from '../../../addons/addons-factories';
import { AdvertsManager } from '../../../addons/adverts-manager';

import type { ReportingAddon } from '../../../addons/reporting/reporting-addon';
import { ReportingManager } from '../../../addons/reporting/reporting-manager';
import type { InternalConfig } from '../../../config/internal-config';
import { Proposition } from '../../../config/internal-config';
import { CoreVideoInternal } from '../../../core-video-internal';
import { sdkLogger } from '../../../logger';
import type { Observable } from '../../../utils/observables/observable';
import { createLoggingObservable } from '../../../utils/observables/verbose-logging-observable';
import { PerfKey, perfLogger, PerfTag } from '../../../utils/perf';
import { checkIsManifestLinearType, checkIsManifestVodType } from '../../../utils/playback-type';
import type { PlayerEngine } from '../../player/player-engine';
import type { PlayerEngineCreationOptions } from '../../player/player-engine-factory';
import type { PlayerEngineItem } from '../../player/player-engine-item';
import type { Track } from '../../player/track';
import type { PlayerPrecursorEngine } from '../../player/player-precursor-engine';
import type { PlayoutData } from '../../player/playout-data';
import { DvrWindowDuration, PlaybackType } from '../../player/playout-data';
import { getMaxVideoFormat, getSupportedColourSpaces, getMaxFrameRate } from '../../player/video-format';
import type { TelemetryDataType } from '../../telemetry/telemetry-session';
import type { VideoPlatformIntegration } from '../../video-platform-integration/video-platform-integration';
import type { StaticAsset } from '../asset';
import { PrefetchItem } from '../prefetch/prefetch-item';

import type { SessionItem } from '../session-controller';
import { isRawSessionItem, SessionController } from '../session-controller';
import { InternalSessionState } from '../internal-session-state';
import { SessionControllerInternal } from '../session-controller-internal';
import { SessionControllerInternalProxy } from '../session-controller-internal-proxy';
import type { AdvertBoltOns, DrmBoltOns } from '../timeline/timeline-manager';

import type { RestartOptions } from '../restart/session-restart-controller';
import { arraysAreEqual, overwriteMerge } from '../../../utils/array-utils';
import { VideoStartupController } from '../video-startup/video-startup-controller';
import type { VideoStartupEngineStarterOptions } from '../video-startup/video-startup-engine-starter';
import { VideoStartupStates } from '../video-startup/video-startup-states';
import { PineManager } from '../../../addons/pine/pine-manager';
import type { CvsdkError } from '../../../error';
import { SessionEventTracker } from '../session-event-tracker';
import type { ItuQualityAssessmentAddon } from '../../../addons/itu-quality-assessment/itu-quality-assessment-addon';
import { getPreferredTrackFromMetadata } from '../../../utils/select-track';

export class SessionControllerPrecursor {
    private logger: Logger = sdkLogger.withContext('Precursor');
    private sessionProxy: SessionControllerInternalProxy = new SessionControllerInternalProxy();
    private advertsManager: AdvertsManager;
    private initialisePromise: Promise<void> | null = null;
    private reportingManager: ReportingManager;
    private sessionAttachedObservable: Observable<SessionController> = createLoggingObservable<SessionController>('SessionAttached', this.logger);
    private sessionConfig: InternalConfig;
    private sessionId?: string = self.crypto.randomUUID ? self.crypto.randomUUID() : uuid();
    private videoStartupController: VideoStartupController;
    private playoutSessionItem?: SessionItem;
    private pineManager?: PineManager;
    private ituQualityAssessmentAddon?: ItuQualityAssessmentAddon;

    private prefetchItem?: PrefetchItem;
    private _isAppBackgrounded = false;
    private unregisterPostPrefetchSubtitlesObserver?: () => void = undefined;
    private unregisterPostPrefetchAudioObserver?: () => void = undefined;
    private sessionController: SessionController;
    private sessionEventTracker: SessionEventTracker;

    constructor(
        private videoPlatformIntegration: VideoPlatformIntegration,
        private _sessionItem: SessionItem,
        private addonsFactories: AddonsFactories,
        internalConfig: InternalConfig,
        private playerPrecursorEngine?: PlayerPrecursorEngine,
        private engineCreationOptions?: PlayerEngineCreationOptions,
        private playerEngine?: PlayerEngine
    ) {
        CoreVideoInternal.lifecycle?.onSuspending(this.onAppSuspending);
        CoreVideoInternal.lifecycle?.onResuming(this.onAppResuming);

        this.sessionEventTracker = new SessionEventTracker(this.sessionProxy);

        this.sessionConfig = deepmerge<InternalConfig>({}, internalConfig);

        this._sessionItem = this.populateSessionItem(this._sessionItem);

        this.advertsManager = new AdvertsManager(
            this.sessionConfig.addons?.adInsertion,
            this.sessionProxy,
            addonsFactories,
            this.videoPlatformIntegration.getUMVToken(),
            this.sessionConfig.vpi?.useCustomVpip ? undefined : this.sessionConfig.vpi?.tokenService?.getToken
        );

        this.reportingManager = new ReportingManager(this.sessionProxy, addonsFactories.reporting!, this.sessionItem);

        if (this.sessionConfig.addons?.pine?.enabled) {
            this.pineManager = new PineManager(this.sessionConfig.addons?.pine, this.addonsFactories);
        }

        this.ituQualityAssessmentAddon = this.addonsFactories.ituQualityAssessment?.(
            this.sessionConfig.addons?.ituQualityAssessment,
            this.sessionEventTracker,
            this.sessionProxy
        );

        this.videoStartupController = new VideoStartupController(this.advertsManager, this.videoStartupEngineStarterOptions);

        if (Boolean(this.playerEngine)) {
            this.bootstrapSession();
        }

        this.sessionController = new SessionController(this.sessionProxy, this.sessionConfig);

        this.sessionProxy.onSessionEnded(this.handleSessionEnded.bind(this));
    }

    /**
     * Populate session item with default values
     * Note: Modifies session item in place
     * @param sessionItem
     * @returns SessionItem
     */
    private populateSessionItem(sessionItem: SessionItem): SessionItem {
        const defaultAttributes: Partial<SessionItem> = {
            sessionId: this.sessionId,
            autoplay: true,
            isMiniPlayer: false,
            user: {},
            reporting: {
                userWatchedAt: new Date(),
            },
            preferredAudioMetadata: [],
            preferredAudioLanguages: [],
            preferredSubtitleMetadata: [],
            preferredSubtitlesLanguages: [],
            liveEdgeToleranceSeconds: 40,
            vac: { coppaApplies: false },
        };

        sessionItem = deepmerge<SessionItem>(defaultAttributes, sessionItem);

        // TODO: This is only a temporary workaround and will be removed in the future. For now every linear channel is exclusive. This is also something specific to Peacock/SkyShowtime
        if (sessionItem.type === PlaybackType.Live && [Proposition.Peacock, Proposition.SkyShowtime].includes(this.sessionConfig?.proposition)) {
            sessionItem = {
                ...sessionItem,
                reporting: {
                    ...sessionItem.reporting,
                    assetMetadata: {
                        ...sessionItem.reporting?.assetMetadata,
                        isExclusiveChannel: true,
                        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                    } as any,
                },
            };
        }

        if (!sessionItem.videoFormatConfig) {
            sessionItem.videoFormatConfig = {};
        }

        const compatibleStreamPropertyGroup = CoreVideoInternal.getCompatibleStreamPropertyGroup(sessionItem.type);
        sessionItem.videoFormatConfig.support = {
            maxVideoFormat: getMaxVideoFormat(sessionItem, CoreVideoInternal.deviceInfo, compatibleStreamPropertyGroup),
            supportedColourSpaces: getSupportedColourSpaces(sessionItem, CoreVideoInternal.deviceInfo, compatibleStreamPropertyGroup),
            maxFrameRate: getMaxFrameRate(sessionItem, CoreVideoInternal.deviceInfo, compatibleStreamPropertyGroup),
        };

        if (sessionItem.addonsConfigOverride?.adInsertion) {
            this.sessionConfig.addons.adInsertion = deepmerge(sessionItem.adInsertion!, sessionItem.addonsConfigOverride.adInsertion);
            sessionItem.adInsertion = deepmerge(sessionItem.adInsertion!, sessionItem.addonsConfigOverride.adInsertion);
        }

        if (sessionItem.reporting?.videoInitiate) {
            this.logger.warn('Deprecated: Need to move videoInitiate from reporting object to root of sessionItem');
        }
        sessionItem.videoInitiate = sessionItem.reporting?.videoInitiate || sessionItem.videoInitiate;

        return sessionItem;
    }

    private attachOnBeforeRetryHandler() {
        this.videoStartupController.onRestartSessionInitiatedHandler((restartOptions: RestartOptions) => {
            const sessionItemOverrides = restartOptions.sessionItemOverrides!;
            const sessionItem = deepmerge<SessionItem>(this.sessionItem, sessionItemOverrides, { arrayMerge: overwriteMerge });

            if (checkIsManifestVodType(this.sessionItem.type) && !sessionItem.startPosition) {
                const advertsAdjustedStartPosition = this.advertsManager.getVodStartPositionAfterCurrentAdvert();
                if (advertsAdjustedStartPosition) {
                    restartOptions.playoutDataOverrides!.position = advertsAdjustedStartPosition;
                }
            }

            this.advertsManager.finishCurrentAd(this.sessionProxy);
            this.reset(sessionItem);
            return restartOptions;
        });
    }

    private onAppSuspending: () => Promise<void> = () => {
        this._isAppBackgrounded = true;
        return Promise.resolve();
    };

    private onAppResuming: () => Promise<void> = () => {
        this._isAppBackgrounded = false;
        return Promise.resolve();
    };

    public updateSessionItem(newSessionItem: SessionItem) {
        this.playoutSessionItem = this.populateSessionItem(newSessionItem);
    }

    public onSessionAttached(callback: (sessionController: SessionController) => void): void {
        this.sessionAttachedObservable.registerObserver(callback, this);
    }

    public onTelemetryUpdate(callback: (vstTelemetry: TelemetryDataType) => void): void {
        this.videoStartupController.onTelemetryUpdate(callback);
    }

    // TODO - review the need for that accessor instead of keeping prefetch stage inside the playoutData
    public getPrefetchStage(): VideoStartupStates | undefined {
        return this.prefetchItem?.getLastPrefetchStage();
    }

    public isPrefetchedSession(): boolean {
        return Boolean(this.prefetchItem);
    }

    public isPrefetching(): boolean {
        return this.isPrefetchedSession() && !this.prefetchItem!.isPrefetchLocked;
    }

    public getSessionProxy(): SessionControllerInternalProxy {
        return this.sessionProxy;
    }

    public getPlayerEngine(): PlayerEngine | undefined {
        return this.playerEngine;
    }

    public setPlayerEngine(playerEngine: PlayerEngine): void {
        this.playerEngine = playerEngine;
    }

    private isRetryEnabled() {
        const colourSpaceRetryEnabled =
            CoreVideoInternal.display?.activateColourSpace && this.sessionItem.display?.playerViewAlwaysPresentedFullScreen;
        const vpfRetryEnabled = this.sessionItem.sessionRetryConfig?.enabled;
        const qualityFailoverEnabled = Boolean(this.sessionItem.enableQualityFailover);
        return colourSpaceRetryEnabled || vpfRetryEnabled || qualityFailoverEnabled;
    }

    public createSession() {
        const propositionExtensions = CoreVideoInternal.getPropositionExtensions();
        const controller = new SessionControllerInternal(
            this.playerEngine!,
            this.sessionConfig,
            this.videoPlatformIntegration,
            this,
            propositionExtensions.addonFactories!
        );

        this.videoStartupController.setSessionControllerInternal(controller);
    }

    public bootstrapSession() {
        if (this.isRetryEnabled()) {
            this.createRetriableSession();
        } else {
            this.createSession();
        }
    }

    public startSession() {
        this.videoStartupController.startSession();
    }

    public async restartSession(): Promise<void> {
        await this.videoStartupController.restart();
    }

    public async initialise(pin?: string): Promise<void> {
        if (this.initialisePromise) {
            return this.initialisePromise;
        }

        this.initialisePromise = this._initialise(pin);
        await this.initialisePromise;
    }

    public get playoutData(): PlayoutData | null {
        return this.videoStartupController.cachedModifiedPlayoutData;
    }

    public get ovpOriginalPlayoutData(): PlayoutData | null {
        return this.videoStartupController.cachedOvpOriginalPlayoutData;
    }

    public get mainAsset(): StaticAsset | undefined {
        return this.videoStartupController.engineStarter?.mainAsset;
    }

    public get sessionItem(): SessionItem {
        return this._sessionItem;
    }

    public modifyPlayoutDataForCdnSwitch(playoutData: PlayoutData): PlayoutData {
        const newCdnPlayoutData: PlayoutData = { ...playoutData, ...this.getCdnSwitchStartPosition(playoutData) };
        return this.advertsManager.modifyPlayoutDataForCdnSwitch(newCdnPlayoutData);
    }

    public async destroy(): Promise<void> {
        this.videoStartupController.destroy();
        this.reportingManager.destroy();
        this.advertsManager.destroy();
        this.pineManager?.destroy();
        this.ituQualityAssessmentAddon?.destroy();
        this.initialisePromise = null;

        CoreVideoInternal.lifecycle?.unregisterResuming?.(this.onAppResuming);
        CoreVideoInternal.lifecycle?.unregisterSuspending?.(this.onAppSuspending);

        this.sessionAttachedObservable.unregisterObservers(this);
        this.sessionProxy.destroy();
        this.sessionEventTracker.destroy();
    }

    public reset(sessionItem: SessionItem) {
        this._sessionItem = sessionItem;
        this.initialisePromise = null;

        this.advertsManager.destroy();

        this.advertsManager = new AdvertsManager(
            this.sessionConfig.addons?.adInsertion,
            this.sessionProxy,
            this.addonsFactories,
            this.videoPlatformIntegration.getUMVToken(),
            this.sessionConfig.vpi?.useCustomVpip ? undefined : this.sessionConfig.vpi?.tokenService?.getToken
        );
        this.videoStartupController.setAdvertsManager(this.advertsManager);
    }

    public createPlayerEngineItem(
        engine: PlayerEngine,
        modifiedPlayoutData: PlayoutData,
        advertBoltOns: AdvertBoltOns,
        drmBoltOns: DrmBoltOns
    ): PlayerEngineItem {
        return this.videoStartupController.engineStarter!.createPlayerEngineItem(engine, modifiedPlayoutData, advertBoltOns, drmBoltOns);
    }

    private createRetriableSession() {
        const propositionExtensions = CoreVideoInternal.getPropositionExtensions();
        // Retry controller needs factory to create new session when retrying
        const sessionControllerInternalFactory = () => {
            const controller = new SessionControllerInternal(
                this.playerEngine!,
                this.sessionConfig,
                this.videoPlatformIntegration,
                this,
                propositionExtensions.addonFactories!
            );
            return controller;
        };
        this.videoStartupController.createRestartController(this.sessionProxy, this.sessionItem, sessionControllerInternalFactory);
        this.attachOnBeforeRetryHandler();
    }

    private handleSessionEnded(): void {
        this.destroy();
    }

    public isAppBackgrounded(): boolean {
        return this._isAppBackgrounded;
    }

    public attachSession(session: SessionControllerInternal): void {
        this.sessionProxy.attachSession(session);

        this.notifySessionAttached(this.sessionController);

        if (this.playoutSessionItem) {
            this.handlePostPrefetchSessionUpdates(session);
        }

        if (!this.reportingManager.initialised) {
            this.reportingManager.initialise();
        }

        session.waitForState(InternalSessionState.Loading)?.then(() => {
            this.sessionProxy.handleSessionStarted();
        });
    }

    public getAdvertsManager(): AdvertsManager {
        return this.advertsManager;
    }

    public getReportingAddons(): Array<ReportingAddon> {
        return this.reportingManager.getReportingAddons();
    }

    public adaptPlayerError(error: CvsdkError) {
        return this.videoPlatformIntegration.adaptPlayerError(error);
    }

    public getDRMBoltOns(): DrmBoltOns {
        if (!CoreVideoInternal.capabilities.requiresLicensingSigning()) {
            return {};
        }

        if (!CoreVideoInternal.playerCapabilities.hasLicensingSigning) {
            throw Error('The selected player can not perform license signing');
        }

        return {
            decorateLicenseRequest: this.videoPlatformIntegration.decorateLicenseRequest,
            validateLicenseResponse: this.videoPlatformIntegration.validateLicenseResponse,
        };
    }

    private handlePostPrefetchSessionUpdates(session: SessionControllerInternal): void {
        if (!this.prefetchItem) {
            this._sessionItem = this.playoutSessionItem!;
        } else if (!this.unregisterPostPrefetchSubtitlesObserver) {
            // Post-prefetch session item changes
            this.unregisterPostPrefetchSubtitlesObserver = session.onAvailableSubtitlesTracksChanged((tracks) => {
                this.handlePostPrefetchSubtitles(tracks);
            });
            this.unregisterPostPrefetchAudioObserver = session.onAvailableAudioTracksChanged((tracks) => {
                this.handlePostPrefetchAudio(tracks);
            });
        }
    }

    private findTargetTrack(availableTracks: Array<Track>, preferredLanguages?: Array<string>): Track | undefined {
        let targetTrack: Track | undefined = undefined;
        for (const preferredLang of preferredLanguages ?? []) {
            targetTrack = availableTracks.find((track) => track.language === preferredLang || track.languageTag === preferredLang);
            if (targetTrack) {
                break;
            }
        }
        return targetTrack;
    }

    private handlePostPrefetchAudio(tracks: Array<Track>): void {
        let targetTrack: Track | undefined;
        if (!arraysAreEqual(this.sessionItem.preferredAudioMetadata, this.playoutSessionItem?.preferredAudioMetadata)) {
            targetTrack = getPreferredTrackFromMetadata(this.playoutSessionItem?.preferredAudioMetadata, tracks);
        }
        if (!targetTrack && !arraysAreEqual(this.sessionItem.preferredAudioLanguages, this.playoutSessionItem?.preferredAudioLanguages)) {
            targetTrack = this.findTargetTrack(tracks, this.playoutSessionItem?.preferredAudioLanguages);
        }
        if (targetTrack) {
            this.sessionProxy.setAudioTrack(targetTrack.id);
        }
        this.sessionItem.preferredAudioMetadata = this.playoutSessionItem?.preferredAudioMetadata;
        this.sessionItem.preferredAudioLanguages = this.playoutSessionItem?.preferredAudioLanguages;

        this.unregisterPostPrefetchAudioObserver?.();
    }

    private handlePostPrefetchSubtitles(tracks: Array<Track>): void {
        if (!arraysAreEqual(this.sessionItem.preferredSubtitleMetadata, this.playoutSessionItem?.preferredSubtitleMetadata)) {
            if (!this.playoutSessionItem?.preferredSubtitleMetadata || this.playoutSessionItem?.preferredSubtitleMetadata?.length === 0) {
                this.sessionProxy.disableSubtitles();
                return;
            }

            const targetTrack = this.findTargetTrack(
                tracks,
                this.playoutSessionItem?.preferredSubtitleMetadata.map((psm) => psm.languageTag)
            );
            if (targetTrack) {
                this.sessionProxy.enableSubtitles(targetTrack.id);
            }
        }
        if (!arraysAreEqual(this.sessionItem.preferredSubtitlesLanguages, this.playoutSessionItem?.preferredSubtitlesLanguages)) {
            if (!this.playoutSessionItem?.preferredSubtitlesLanguages || this.playoutSessionItem?.preferredSubtitlesLanguages?.length === 0) {
                this.sessionProxy.disableSubtitles();
                return;
            }

            const targetTrack = this.findTargetTrack(tracks, this.playoutSessionItem?.preferredSubtitlesLanguages);
            if (targetTrack) {
                this.sessionProxy.enableSubtitles(targetTrack.id);
            }
        }
        this.sessionItem.preferredSubtitleMetadata = this.playoutSessionItem?.preferredSubtitleMetadata;
        this.sessionItem.preferredSubtitlesLanguages = this.playoutSessionItem?.preferredSubtitlesLanguages;

        this.unregisterPostPrefetchSubtitlesObserver?.();
    }

    private getCdnSwitchStartPosition(cdnSwitchPlayoutData: PlayoutData): Pick<PlayoutData, 'position' | 'linearPosition'> {
        const { streamInfo: newStreamInfo } = cdnSwitchPlayoutData.cdns[0];
        const { streamInfo: originalStreamInfo } = this.ovpOriginalPlayoutData!.cdns[0];
        if (checkIsManifestLinearType(cdnSwitchPlayoutData.type) && newStreamInfo && originalStreamInfo) {
            if (
                'windowDuration' in newStreamInfo &&
                'windowDuration' in originalStreamInfo &&
                originalStreamInfo.windowDuration !== DvrWindowDuration.Restricted &&
                newStreamInfo.windowDuration === DvrWindowDuration.Restricted
            ) {
                return { linearPosition: undefined };
            }
        }

        return { position: this.ovpOriginalPlayoutData!.position };
    }

    private notifySessionAttached(currentSession: SessionController): void {
        this.sessionAttachedObservable.notifyObservers(currentSession);

        // This should only be fired once per precursor, so unregister here just to be safe
        this.sessionAttachedObservable.unregisterObservers(this);
    }

    public getPrefetchItem(): PrefetchItem | undefined {
        return this.prefetchItem;
    }

    private get videoStartupEngineStarterOptions(): VideoStartupEngineStarterOptions {
        return {
            sessionConfig: this.sessionConfig,
            advertsManager: this.advertsManager,
            playerPrecursorEngine: this.playerPrecursorEngine,
            engineCreationOptions: this.engineCreationOptions,
            sessionId: this.sessionId,
            sessionProxy: this.sessionProxy,
            drmBoltons: this.getDRMBoltOns(),
            pineManager: this.pineManager,
        };
    }

    public async prefetch(destroyHandler: () => void, includeAdsPrefetchWhenPossible?: boolean): Promise<PrefetchItem | undefined> {
        if (this.sessionConfig.addons?.pine?.enabled) {
            await this.pineManager!.connect();
        }

        this.prefetchItem = new PrefetchItem(
            this.videoStartupController,
            this.videoPlatformIntegration,
            this.sessionItem,
            destroyHandler,
            includeAdsPrefetchWhenPossible
        );

        try {
            await this.prefetchItem.prefetch();
        } catch (e) {
            this.prefetchItem = undefined;
            throw e;
        }

        return this.prefetchItem;
    }

    // eslint-disable-next-line complexity
    private async _initialise(pin?: string): Promise<void> {
        // Finish the prefetch promise before continuing
        await this.prefetchItem?.lockPrefetchStage();

        if (this.sessionConfig.addons?.pine?.enabled) {
            await this.pineManager!.connect();
            this.pineManager!.init(this.sessionItem, this.videoStartupEngineStarterOptions.sessionProxy);
        }

        if (this.videoStartupController.state.type() === VideoStartupStates.EngineStarted) {
            return;
        }

        try {
            if (pin && !isRawSessionItem(this._sessionItem)) {
                this._sessionItem.parentalControlPin = pin;
            }

            const uninitialisedStates = [VideoStartupStates.Uninitialised, VideoStartupStates.Discarded, VideoStartupStates.Retried];
            if (uninitialisedStates.includes(this.videoStartupController.state.type())) {
                this.videoStartupController.initialise();
            }

            if (this.videoStartupController.state.type() === VideoStartupStates.Initialised) {
                await this.videoStartupController.callVideoProvider(this._sessionItem, this.videoPlatformIntegration, false);
            }

            if (this.videoStartupController.isDestroyed) {
                return;
            }

            if (this.videoStartupController.state.type() === VideoStartupStates.VideoProviderCalled) {
                this.videoStartupController.initialiseAdverts(this._sessionItem);
            }

            if (this.videoStartupController.isDestroyed) {
                return;
            }

            if (this.videoStartupController.shouldSkipAdvertisements) {
                await this.videoStartupController.startEngine(this.sessionItem);
                return;
            }

            if (this.videoStartupController.state.type() === VideoStartupStates.AdvertsInitialised) {
                const isVamCalled = await this.videoStartupController.callVam(this._sessionItem);
                if (isVamCalled && Boolean(this.videoStartupController.cachedModifiedPlayoutData!.vamFailureStatus)) {
                    this.videoStartupController.skipAdvertisement();
                }
            }

            if (this.videoStartupController.isDestroyed) {
                return;
            }

            if (this.videoStartupController.state.type() === VideoStartupStates.VamCalled) {
                await this.videoStartupController.bootstrapAds();
            }

            if (
                !this.videoStartupController.shouldSkipAdvertisements &&
                this.videoStartupController.state.type() === VideoStartupStates.AdsBootstrapped
            ) {
                await this.videoStartupController.callAdsData();
                perfLogger.measure(PerfKey.root, PerfTag.playoutModified);
            }

            if (this.videoStartupController.isDestroyed) {
                return;
            }

            if (this.videoStartupController.state.type() === VideoStartupStates.AdsInsertionCalled) {
                await this.videoStartupController.startEngine(this.sessionItem);
            }
        } catch (e) {
            this.initialisePromise = null;
            this.videoStartupController.discard();
            throw e;
        }
    }
}
