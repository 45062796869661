import type { ClientMParticleConfig } from '../../../addons/reporting/mparticle-addon/mparticle-addon';
import type { AddonConfig } from '../../internal-config';
import type { RemoteConfigSplit } from '../../sdk-config';
import type { DeclaredOptionalsAddonsConfig, InternalReportingConfig, RequiredPropertiesDefinition } from './addons-config-adapter-common';
import { genericConfigEnabledCheck } from './addons-config-adapter-common';

// Internal Config
type MParticleInternalConfig = InternalReportingConfig['mParticle'];

// Client Config when Remote Config is disabled
export type MParticleClientConfig = AddonConfig<ClientMParticleConfig>;
export function adaptMParticleClientConfig(config: MParticleClientConfig | undefined): MParticleInternalConfig {
    return config;
}

// Server Config when Remote Config is enabled
type MParticleRemoteServerConfig = AddonConfig<{}>;

// Client Config when Remote Config is enabled
type MParticleRemoteClientConfig = ClientMParticleConfig;

export type MParticleRemoteConfigSplit = RemoteConfigSplit<MParticleRemoteServerConfig, MParticleRemoteClientConfig>;
export function adaptMParticleRemoteConfigSplit(config: MParticleRemoteConfigSplit): DeclaredOptionalsAddonsConfig<MParticleInternalConfig> {
    const requiredFields: RequiredPropertiesDefinition<MParticleRemoteClientConfig> = { mParticleInstance: null };
    const isEnabled = genericConfigEnabledCheck(config, requiredFields, 'MParticle');
    if (!isEnabled) {
        return;
    }

    const { clientConfig } = config;
    return {
        enabled: true,
        config: {
            mParticleInstance: clientConfig!.mParticleInstance,
            appName: clientConfig?.appName,
            studioName: clientConfig?.studioName,
        },
    };
}
