import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';

import { AddonManager } from '../../addons/addon-manager';
import type { AdvertisingData, AssetMetadata } from '../../addons/addon-playout-data';
import type { AddonsFactories } from '../../addons/addons-factories';
import type { AdvertsManager } from '../../addons/adverts-manager';
import { shouldLoadAdvertisingAddons } from '../../addons/adverts/ad-provider-selection';
import type { Ad, AdBreak, AdPosition } from '../../addons/adverts/common';
import { AdBreakType, SsaiStitcherType } from '../../addons/adverts/common';
import type {
    CompanionAdOpportunityStartedEvent,
    CompanionAdOpportunityEndedEvent,
} from '../../addons/adverts/companion-adverts/companion-advert-dispatcher';
import type { NonLinearAdEvent } from '../../addons/adverts/non-linear-adverts/non-linear-ad-types';
import type { AdAssetGroup } from '../../addons/adverts/vod-adverts/vod-adverts-addon';
import type { InternalConfig, Proposition } from '../../config/internal-config';
import { CoreVideoInternal } from '../../core-video-internal';
import { AddonErrorCategory, CvsdkError, ErrorSeverity, PlayerErrorCategory } from '../../error';
import { sdkLogger } from '../../logger';
import type { ThumbnailRenderInfo, ThumbnailVariant } from '../../players/player-extensions/thumbnails/thumbnail-types';
import { JsonUtils } from '../../utils/json-utils';
import type { ComposedState } from '../../utils/observables/composite-observable';
import { CompositeObservable } from '../../utils/observables/composite-observable';
import type { AdaptedState, Observable } from '../../utils/observables/observable';
import { OBSERVABLE_IGNORE_EVENT, createErrorLogger } from '../../utils/observables/observable';
import { createLoggingObservable } from '../../utils/observables/verbose-logging-observable';
import { PerfKey, perfLogger, PerfTag } from '../../utils/perf';
import { checkIsManifestLinearType, checkIsManifestSingleLiveEventType, checkIsManifestVodType } from '../../utils/playback-type';
import { TimedLock } from '../../utils/timed-lock';
import { AudioFormat } from '../player/audio-format';
import type { PlayerEngine } from '../player/player-engine';
import type {
    BitRateLevel,
    CdnSwitchEvent,
    LiveWindow,
    PlayerEngineItem,
    PlayerHttpRequest,
    PlayerHttpResponse,
    StreamMetadata,
    SubtitleCue,
    TimedMetadata,
    VideoTrack,
} from '../player/player-engine-item';
import type { Track } from '../player/track';
import type { PlayerManifestReceivedEvent } from '../player/player-manifest-received-event';
import { PlayerState } from '../player/player-state';
import type { PlayoutData, PlayerBitrateLimits, PlayoutRules } from '../player/playout-data';
import { isSleCsai, PlaybackType, PlayoutDataError, isMultiplayerCsai } from '../player/playout-data';
import type { HdcpLevel, VideoCodec, WidevineSecurityLevel } from '../player/video-format';
import { VideoColourSpace, VideoFormat } from '../player/video-format';
import type { TelemetryDataType, TelemetryEvent } from '../telemetry/telemetry-session';
import { TelemetryNotificationKey, TelemetrySession } from '../telemetry/telemetry-session';
import type { VideoPlatformIntegration } from '../video-platform-integration/video-platform-integration';

import type { BoundaryEvent } from './event-boundary';
import type { ManifestReceivedEvent } from './manifest-received-event';
import type { SessionControllerPrecursor } from './precursor/session-controller-precursor';
import { SeekingManager } from './seeking-manager/seeking-manager';
import type { SessionItem } from './session-controller';
import { PinRequiredCause } from './session-controller';
import type { TimelineItem } from './timeline/timeline';
import { TimelineItemType } from './timeline/timeline';
import type { TimelineEventData } from './timeline/timeline-manager';
import { TimelineManager } from './timeline/timeline-manager';
import type { VideoStartupStates } from './video-startup/video-startup-states';
import { InternalSessionState, INITIAL_STATES, FINAL_STATES } from './internal-session-state';
import type { CatchupSeekEvent } from '../../propositions/ad-policy/ad-policy-manager';
import type { BitrateCapData } from '../meta-list-manager/bitrate-cap-manager';
import { BitrateCapManager, BitrateCapReason } from '../meta-list-manager/bitrate-cap-manager';
import { TestingOverrides } from '../services/testing-overrides';
import type { EventCueCallback, EventCue, EventTrack, OnEventCueRegisteredCallback, OnEventCueUpdatedCallback } from '../events/event-types';
import { CommonEventTrack, EventTrackType, VideoElementEventTrack } from '../events/event-track';
import { EventManager } from '../events/event-manager';
import { MetaListManager } from '../meta-list-manager/meta-list-manager';
import { LiveActionManager } from '../meta-list-manager/live-action-manager';
import type { EventTransformers } from './session-controller-internal-proxy';
import { ObserverPriority } from '../../utils/observables/observable.enums';
import type { WatermarkData } from '../../addons/watermarking/base';
import type { EventOvpOptions } from '@sky-uk-ott/client-lib-js-ott-ovp-service';
import { VideoColourSpace as ClientVideoColourSpace } from '@sky-uk-ott/client-lib-js-device';

export const COLOUR_SPACE_ACTIVATION_ERROR_PREFIX = 'SESSION.ACTIVATE_COLOUR_SPACE_FAILED';

const PLAYOUT_ERROR_TO_PIN_CAUSE: Partial<Record<PlayoutDataError, PinRequiredCause>> = {
    [PlayoutDataError.PARENTAL_PIN_REQUIRED]: PinRequiredCause.Required,
    [PlayoutDataError.INVALID_PIN_PROVIDED]: PinRequiredCause.Invalid,
    [PlayoutDataError.PIN_SERVICE_DOWN]: PinRequiredCause.PinServiceDown,
};

export type PlaybackTimelineInternal = PlaybackTimeline & {
    liveWindow?: LiveWindow; // Given to us by the player which gives us the hard limits, (surrounds the seekableRange which is a smaller window with built in tolerance)
    /** Stream position specified by the player. */
    currentTime: number;
    muteForClients?: boolean;
};

/**
 * @public
 * A window of time in which we are able to seek forwards or backwards through the content
 */
export type SeekableRange = {
    start: number; // The stream position of the seekable range start
    end: number; // The stream position of the seekable range end
    startDate?: Date; // The dateTime corresponding with the stream start position in the current seekable range
    endDate?: Date; // The dateTime corresponding with the stream start position in the current seekable range
};

/**
 * @public
 */
export type PlaybackTimeline = {
    /** Content time */
    position: number;
    /** Absolute date time (live content only)  */
    absolutePosition?: Date;
    /** A smaller window inside the live window that has some built in tolerance (live content only) */
    seekableRange?: SeekableRange;
    /** Whether or not the player position is at the live edge (live content only) */
    isAtLiveEdge?: boolean;
};

export type InternalSessionInterface = Pick<
    SessionControllerInternal,
    | 'playerVersion'
    | 'getLiveWindow'
    | 'start'
    | 'getPrecursor'
    | 'getPrefetchStage'
    | 'isPrefetchedSession'
    | 'getProposition'
    | 'stop'
    | 'play'
    | 'pause'
    | 'seekToDate'
    | 'seek'
    | 'seekToLiveEdge'
    | 'seekToLiveStart'
    | 'isAtLiveEdge'
    | 'isLinearScrubbingSupported'
    | 'setVolume'
    | 'setMute'
    | 'setPlayerBitrateLimits'
    | 'setUserWaitStarted'
    | 'setUserWaitEnded'
    | 'resetPlayerBitrateLimits'
    | 'enableSubtitles'
    | 'disableSubtitles'
    | 'setAudioTrack'
    | 'waitForState'
    | 'waitForOneOfStates'
    | 'onAdStarted'
    | 'onAdFinished'
    | 'onAdBreakStarted'
    | 'onAdBreakFinished'
    | 'onAdBreakDataReceived'
    | 'onAdTrackingReceived'
    | 'onCatchupSeek'
    | 'onCdnSwitch'
    | 'onStateChanged'
    | 'onError'
    | 'onWarning'
    | 'onPlaybackTimelineUpdated'
    | 'onAdPositionChanged'
    | 'onPauseAd'
    | 'onVerifyCompanionAdInsertionEnabled'
    | 'onCompanionAdOpportunityStarted'
    | 'onRenderWatermark'
    | 'onClearWatermark'
    | 'onClearWatermarks'
    | 'onCompanionAdOpportunityEnded'
    | 'onStreamMetadataReceived'
    | 'onTimedMetadataReceived'
    | 'onBulkTimedMetadataReceived'
    | 'onBitrateChanged'
    | 'onEncodedFrameRateChanged'
    | 'onRenderedFrameRateChanged'
    | 'onVideoTrackChanged'
    | 'onAvailableAudioTracksChanged'
    | 'onAvailableSubtitlesTracksChanged'
    | 'onAvailableThumbnailVariantsChanged'
    | 'onAudioTrackWillChange'
    | 'onAudioTrackChanged'
    | 'onBitrateCapChanged'
    | 'onBitrateCapRequested'
    | 'onPlayoutDataReceived'
    | 'onSubtitlesTrackChanged'
    | 'onSubtitleCuesChanged'
    | 'onPlayStart'
    | 'onSeekStarted'
    | 'onSeekEnded'
    | 'onSessionEnded'
    | 'onVolumeChanged'
    | 'onMuteChanged'
    | 'onEventBoundary'
    | 'getAdvertsManager'
    | 'onEndOfEventMarkerReceived'
    | 'onManifestReceived'
    | 'onPinRequired'
    | 'onPinSuccess'
    | 'onAssetMetadataUpdated'
    | 'onAppBackgrounded'
    | 'onAppForegrounded'
    | 'onQualityFailover'
    | 'onUserWaitStarted'
    | 'onUserWaitEnded'
    | 'requestSessionStop'
    | 'isFinished'
    | 'restartSession'
    | 'retrySession'
    | 'isBlockingUserInput'
    | 'cancelPin'
    | 'setPin'
    | 'setThumbnailVariant'
    | 'getThumbnailForTime'
    | 'isSeekToDateSupported'
    | 'notifyAssetMetadataUpdated'
    | 'notifyEventBoundary'
    | 'notifyEndOfEventMarkerReceived'
    | 'notifyStreamMetadataReceived'
    | 'notifyTimelineAssetsAvailable'
    | 'notifyAdBreakStarted'
    | 'notifyAdStarted'
    | 'notifyAdFinished'
    | 'notifyAdBreakFinished'
    | 'notifyAdBreakDataReceived'
    | 'notifyAdTrackingReceived'
    | 'notifyCatchupSeek'
    | 'notifyError'
    | 'notifyWarning'
    | 'notifyPauseAd'
    | 'notifyCompanionAdInsertionEnabled'
    | 'notifyCompanionAdOpportunityStarted'
    | 'notifyCompanionAdOpportunityEnded'
    | 'notifyAdPositionChanged'
    | 'getMaxVideoFormat'
    | 'getSupportedColourSpaces'
    | 'getSupportedAudioFormats'
    | 'getSupportedMaxHdcpLevel'
    | 'getConnectedHdcpLevel'
    | 'getWidevineSecurityLevel'
    | 'getSupportedVideoCodecs'
    | 'onTelemetry'
    | 'onVideoElementCreated'
    | 'onFullscreenChanged'
    | 'onClicked'
    | 'updateTelemetryMetrics'
    | 'onTelemetryUpdate'
    | 'onRebufferStart'
    | 'onRebufferEnd'
    | 'getContentTimePosition'
    | 'registerCue'
    | 'unregisterCue'
    | 'onEventCueEntered'
    | 'onEventCueExited'
    | 'onEventCueRegistered'
    | 'onEventCueUnregistered'
>;

type HandlePlayerEvent<T> = (observable: Observable<T>, event: T) => void;
type HandleEmptyPlayerEvent = (observable: Observable<void>) => void;

export class SessionControllerInternal {
    public get playbackType(): PlaybackType {
        return this.sessionItem.type;
    }

    public get playerVersion(): string {
        return CoreVideoInternal.playerVersion;
    }
    private addonManager: AddonManager;
    private currentPlayerItem?: PlayerEngineItem;
    private currentTimelineItem?: TimelineItem;
    private currentState: InternalSessionState = InternalSessionState.Initialized;
    private sessionItem: SessionItem;

    private logger: Logger = sdkLogger.withContext('SCI');

    private telemetryObservable: Observable<TelemetryEvent> = createLoggingObservable<TelemetryEvent>('TelemetryObservable', this.logger);
    private telemetryUpdateObservable: Observable<TelemetryDataType> = createLoggingObservable<TelemetryDataType>(
        'telemetryUpdateObservable',
        this.logger
    );
    private httpRequestObservable: Observable<PlayerHttpRequest> = createLoggingObservable<PlayerHttpRequest>('HttpRequestObservable', this.logger);
    private httpResponseObservable: Observable<PlayerHttpResponse> = createLoggingObservable<PlayerHttpResponse>(
        'HttpResponseObservable',
        this.logger
    );
    private sessionStateObservable: Observable<InternalSessionState> = createLoggingObservable<InternalSessionState>('SessionState', this.logger);
    private playStartObservable: Observable<void> = createLoggingObservable<void>('PlayStart', this.logger);
    private rebufferStartObservable: Observable<void> = createLoggingObservable<void>('BufferingStarted', this.logger);
    private rebufferEndObservable: Observable<void> = createLoggingObservable<void>('BufferingEnded', this.logger);
    private errorObservable: Observable<CvsdkError> = createLoggingObservable<CvsdkError>('Error', this.logger);
    private warningObservable: Observable<CvsdkError> = createLoggingObservable<CvsdkError>('Warning', this.logger);
    private adPositionObservable: Observable<AdPosition> = createLoggingObservable<AdPosition>('AdPositionChanged', this.logger);
    private streamMetadataReceivedObservable: Observable<StreamMetadata> = createLoggingObservable<StreamMetadata>(
        'StreamMetadataReceived',
        this.logger
    );
    private timedMetadataReceivedObservable: Observable<TimedMetadata> = createLoggingObservable<TimedMetadata>('TimedMetadataReceived', this.logger);
    private bulkTimedMetadataReceivedObservable: Observable<Array<TimedMetadata>> = createLoggingObservable<Array<TimedMetadata>>(
        'BulkTimedMetadataReceived',
        this.logger
    );
    private bitrateChangedObservable: Observable<BitRateLevel> = createLoggingObservable<BitRateLevel>('BitrateChanged', this.logger);
    private encodedFrameRateChangedObservable: Observable<number> = createLoggingObservable<number>('EncodedFrameRateChanged', this.logger);
    private renderedFrameRateChangedObservable: Observable<number> = createLoggingObservable<number>('RenderedFrameRateChanged', this.logger);
    private videoTrackChangedObservable: Observable<VideoTrack> = createLoggingObservable<VideoTrack>('VideoTrackChanged', this.logger);
    private availableAudioTracksChangedObservable: Observable<Array<Track>> = createLoggingObservable<Array<Track>>(
        'AvailableAudioTracksChanged',
        this.logger
    );
    private availableSubtitlesTracksChangedObservable: Observable<Array<Track>> = createLoggingObservable<Array<Track>>(
        'AvailableSubtitlesTracksChanged',
        this.logger
    );
    private availableThumbnailVariantsChangedObservable: Observable<Array<ThumbnailVariant>> = createLoggingObservable<Array<ThumbnailVariant>>(
        'AvailableThumbnailVariantsChanged',
        this.logger
    );
    private audioTrackWillChangeObservable: Observable<Track['id']> = createLoggingObservable<Track['id']>('AudioTrackWillChange', this.logger);
    private audioTrackChangedObservable: Observable<Track['id']> = createLoggingObservable<Track['id']>('AudioTrackChanged', this.logger);
    private bitrateCapChangedObservable: Observable<BitrateCapData> = createLoggingObservable<BitrateCapData>('BitrateCapChanged', this.logger);
    private bitrateCapRequestedObservable: Observable<BitrateCapData> = createLoggingObservable<BitrateCapData>('BitrateCapChanged', this.logger);
    private playoutDataObservable: Observable<PlayoutData> = createLoggingObservable<PlayoutData>('PlayoutDataReceived', this.logger);
    private playoutRulesObservable: Observable<PlayoutRules> = createLoggingObservable<PlayoutRules>('PlayoutRulesReceived', this.logger);
    private subtitlesTrackChangedObservable: Observable<Track> = createLoggingObservable<Track>('SubtitlesTrackChanged', this.logger);
    private subtitleCuesChangedObservable: Observable<Array<SubtitleCue>> = createLoggingObservable<Array<SubtitleCue>>(
        'SubtitleCuesChanged',
        this.logger
    );
    private volumeChangedObservable: Observable<number> = createLoggingObservable<number>('VolumeChanged', this.logger);
    private isMutedObservable: Observable<boolean> = createLoggingObservable<boolean>('MuteChanged', this.logger);
    private adStartedObservable: Observable<Ad> = createLoggingObservable<Ad>('AdStarted', this.logger);
    private adFinishedObservable: Observable<Ad> = createLoggingObservable<Ad>('AdFinished', this.logger);
    private adBreakStartedObservable: Observable<AdBreak> = createLoggingObservable<AdBreak>('AdBreakStarted', this.logger);
    private adBreakFinishedObservable: Observable<AdBreak> = createLoggingObservable<AdBreak>('AdBreakFinished', this.logger);
    private adBreakDataReceivedObservable: Observable<Array<AdBreak>> = createLoggingObservable<Array<AdBreak>>('AdBreakDataReceived', this.logger);
    private adTrackingReceivedObservable: Observable<Array<AdBreak>> = createLoggingObservable<Array<AdBreak>>('AdTrackingReceived', this.logger);
    private catchupSeekObservable: Observable<CatchupSeekEvent> = createLoggingObservable<CatchupSeekEvent>('CatchupSeek', this.logger);
    private appBackgroundedObservable: Observable<void> = createLoggingObservable<void>('AppBackgrounded', this.logger);
    private appForegroundedObservable: Observable<InternalSessionState> = createLoggingObservable<InternalSessionState>(
        'AppForegrounded',
        this.logger
    );
    private qualityFailoverObservable: Observable<VideoFormat> = createLoggingObservable<VideoFormat>('QualityFailover', this.logger);
    private cdnSwitchObservable: Observable<CdnSwitchEvent> = createLoggingObservable<CdnSwitchEvent>('CdnSwitch', this.logger);
    private manifestReceivedObservable: Observable<ManifestReceivedEvent> = createLoggingObservable<ManifestReceivedEvent>(
        'ManifestReceived',
        this.logger
    );
    private pauseAdObservable: Observable<NonLinearAdEvent> = createLoggingObservable<NonLinearAdEvent>('PauseAd', this.logger);
    private companionAdInsertionEnabledObservable: Observable<boolean> = createLoggingObservable<boolean>('CompanionAdInsertionEnabled', this.logger);
    private companionAdOpportunityStartedObservable: Observable<CompanionAdOpportunityStartedEvent> =
        createLoggingObservable<CompanionAdOpportunityStartedEvent>('CompanionAdOpportunityStarted', this.logger);
    private companionAdOpportunityEndedObservable: Observable<CompanionAdOpportunityEndedEvent> =
        createLoggingObservable<CompanionAdOpportunityEndedEvent>('CompanionAdOpportunityEnded', this.logger);
    private renderWatermarkObservable: Observable<WatermarkData> = createLoggingObservable<WatermarkData>('RenderWatermark', this.logger);
    private clearWatermarkObservable: Observable<WatermarkData> = createLoggingObservable<WatermarkData>('ClearWatermark', this.logger);
    private clearWatermarksObservable: Observable<WatermarkData> = createLoggingObservable<WatermarkData>('ClearWatermarks', this.logger);
    private assetMetadataUpdateObservable: Observable<AssetMetadata> = createLoggingObservable<AssetMetadata>('AssetMetadata', this.logger);
    private playbackTimelineObservable: CompositeObservable<PlaybackTimelineInternal>;
    private videoElementCreatedObservable: Observable<HTMLVideoElement> = createLoggingObservable<HTMLVideoElement>(
        'VideoElementCreated',
        this.logger
    );
    private fullscreenChangedObservable: Observable<boolean> = createLoggingObservable<boolean>('FullscreenChanged', this.logger);
    private clickedObservable: Observable<void> = createLoggingObservable<void>('Clicked', this.logger);

    private timelineManager: TimelineManager;
    private seekingManager: SeekingManager;
    private playerBitrateLimits: PlayerBitrateLimits;
    private sessionEndedObservable: Observable<void> = createLoggingObservable('SessionEnded', this.logger);
    private userWaitStartedObservable: Observable<void> = createLoggingObservable('UserWaitStarted', this.logger);
    private userWaitEndedObservable: Observable<void> = createLoggingObservable('UserWaitEnded', this.logger);
    private eventBoundaryObservable: Observable<BoundaryEvent> = createLoggingObservable<BoundaryEvent>('EventBoundary', this.logger);
    private endOfEventMarkerReceivedObservable: Observable<number> = createLoggingObservable<number>('EndOfEventMarkerReceived', this.logger);
    private pinRequiredObservable: Observable<PinRequiredCause> = createLoggingObservable<PinRequiredCause>('PinRequired', this.logger);
    private autoPlayPolicyPreventedPlaybackObservable: Observable<void> = createLoggingObservable('AutoPlayPolicyPreventedPlayback', this.logger);
    private pinSuccessObservable: Observable<void> = createLoggingObservable('PinSuccess', this.logger);

    private isWaitingForPin?: boolean;
    private advertsManager: AdvertsManager;

    private isListeningForVST = true;
    private startPositionMoved = false;
    private preSeekLock: TimedLock = new TimedLock({ lockTimeMs: 3000 });
    private hasSeekedFromLiveEdge = false;
    private isFirstLoadingStateSet = false;
    private eventManager: EventManager | null = null;

    private telemetrySession: TelemetrySession;

    private bitrateCapManager: BitrateCapManager;
    private metaListManager?: MetaListManager;
    private liveActionManager?: LiveActionManager;

    constructor(
        private playerEngine: PlayerEngine,
        private config: InternalConfig,
        videoPlatformIntegration: VideoPlatformIntegration,
        private sessionControllerPrecursor: SessionControllerPrecursor,
        addonsFactories: AddonsFactories
    ) {
        this.sessionItem = sessionControllerPrecursor.sessionItem;
        this.advertsManager = sessionControllerPrecursor.getAdvertsManager();
        if ((this.sessionItem as EventOvpOptions).providerVariantId || TestingOverrides.metalistEndpointOverride) {
            this.metaListManager = new MetaListManager(
                this.notifyWarning.bind(this),
                (this.sessionItem as EventOvpOptions).providerVariantId || '',
                this.config.metalist?.minTimeoutMs
            );
        }
        this.bitrateCapManager = new BitrateCapManager({
            bitrateCapRequestedObservable: this.bitrateCapRequestedObservable,
            bitrateCapChangedObservable: this.bitrateCapChangedObservable,
        });
        if (this.config.metalist?.liveActions?.enableLiveActions) {
            this.liveActionManager = new LiveActionManager();
        }
        this.addonManager = new AddonManager(config.addons, this.sessionItem, videoPlatformIntegration, this, addonsFactories);
        this.playerBitrateLimits = this.sessionItem.playerBitrateLimits || {};

        const isManifestLinearType = checkIsManifestLinearType(this.sessionItem.type);
        this.timelineManager = new TimelineManager(
            this.playerEngine,
            {
                bufferingLimit: this.config.bufferingLimit!,
            },
            { playbackType: this.sessionItem.type },
            this.sessionControllerPrecursor,
            this
        );
        sessionControllerPrecursor.onTelemetryUpdate((e) => this.updateTelemetryMetrics(e));
        this.timelineManager.onTimelineItemStarted((e) => this.handleTimelineItemStarted(e), this);
        this.timelineManager.onTimelineItemPlayoutReceived((e) => this.notifyPlayoutDataReceived(e), this);
        this.timelineManager.onTimelineItemPlayoutRulesReceived((e) => this.notifyPlayoutRulesReceived(e), this);
        this.timelineManager.onTimelineItemEnded((e) => this.handleTimelineItemEnded(), this);
        this.timelineManager.onTimelineItemPreloading((e) => this.handleTimelineItemPreloading(e), this);
        this.timelineManager.onTimelineEnded(() => this.destroy(), this);
        this.timelineManager.onTimelineFinished(() => this.handleTimelineFinished(), this);
        this.timelineManager.onError((e) => this.notifyError(e), this);
        this.timelineManager.onQualityFailover((e) => this.notifyQualityFailover(e), this);
        this.timelineManager.onCdnSwitch((e) => {
            this.advertsManager.notifyManifestLoadError(e.switchError);
            this.notifyCdnSwitch(e);
        }, this);
        this.timelineManager.onAdBreakFinished((adBreak) => {
            const playoutData = this.sessionControllerPrecursor.playoutData;
            if (playoutData && isSleCsai(playoutData) && adBreak.type === AdBreakType.Preroll) {
                this.setSessionState(InternalSessionState.CsaiTransition);
            } else {
                this.setSessionState(InternalSessionState.Loading);
            }
            this.notifyAdBreakFinished(adBreak);
        });

        this.playbackTimelineObservable = new CompositeObservable<PlaybackTimelineInternal>(
            // @ts-ignore
            this.attemptToCreatePlaybackTimeline,
            createErrorLogger('PlaybackTimelineInternal', this.logger)
        );
        this.seekingManager = new SeekingManager(
            this.advertsManager,
            isManifestLinearType,
            CoreVideoInternal.playerCapabilities,
            this.callSeekOnTimelineManager.bind(this),
            this.onPlaybackTimelineUpdated.bind(this),
            this.onStateChanged.bind(this)
        );

        CoreVideoInternal.lifecycle?.onResuming(this.notifyAppForegrounded);
        CoreVideoInternal.lifecycle?.onSuspending(this.notifyAppBackgrounded);
        this.errorObservable.addTransformer(this.errorTransformer.bind(this));

        this.telemetrySession = new TelemetrySession(this, config.telemetry);
        // Separated logic from telemetrySession initialize in order to send data prior initialization
        this.telemetrySession.listenToSessionEvents();

        const selectedEventTrack = this.createEventTrack(this.sessionItem.eventTrackType, CoreVideoInternal.playerCapabilities.supportsVideoElement);
        this.eventManager = new EventManager(selectedEventTrack, this.onPlaybackTimelineUpdated.bind(this), this.advertsManager);
    }

    // give precedence to the eventTrack specified in the referenceApp, if none was selected then
    private createEventTrack(eventTrackSpecifiedInSessionItem?: EventTrackType, playerSupportsVideoElement?: boolean): EventTrack {
        let eventTrackToUse: EventTrackType | undefined;

        switch (eventTrackSpecifiedInSessionItem) {
            case EventTrackType.Default:
                eventTrackToUse = EventTrackType.CommonEventTrack;
                break;
            case EventTrackType.VideoElementEventTrack:
                eventTrackToUse = playerSupportsVideoElement ? EventTrackType.VideoElementEventTrack : EventTrackType.CommonEventTrack;
                break;
            default:
                eventTrackToUse = EventTrackType.CommonEventTrack;
                break;
        }

        this.logger.info(`creating eventTrack implementation using ${eventTrackToUse} class`);
        switch (eventTrackToUse) {
            case EventTrackType.VideoElementEventTrack:
                return new VideoElementEventTrack(this.onVideoElementCreated.bind(this), this.onStateChanged.bind(this));
            case EventTrackType.CommonEventTrack:
            default: {
                return new CommonEventTrack(this.onPlaybackTimelineUpdated.bind(this), this.onSeekStarted.bind(this));
            }
        }
    }

    public async start(): Promise<void> {
        if (this.currentState !== InternalSessionState.Initialized) {
            throw new Error('SDK.SESSION.START.INVALID_STATE');
        }

        this.sessionControllerPrecursor.attachSession(this);
        this.setSessionState(InternalSessionState.Loading);
        this.logger.verbose(`SDK Internal Config:\n${JsonUtils.stringify(this.config)}`);

        try {
            perfLogger.measure(PerfKey.root, PerfTag.sessionStart);
            this.telemetryUpdateObservable.notifyObservers({ key: TelemetryNotificationKey.sessionStart, value: new Date().getTime() });
            await this.addonManager.load(CoreVideoInternal.playerCapabilities);
            perfLogger.measure(PerfKey.root, PerfTag.addonsLoaded);
            await this.initialisePrecursor();
            perfLogger.measure(PerfKey.root, PerfTag.sessionPrecursorInitialised);

            await this.tryPlayout();
        } catch (e) {
            if (e instanceof Error) {
                this.handleStartError(e);
            } else {
                this.logger.warn('Expected error to be instanceof Error', e);
                this.handleStartError(e as Error);
            }
        }
    }

    public updateCueEndTime(cueId: string, endTime: number): boolean {
        if (!this.eventManager) {
            throw new Error('SDK.EVENT.INVALID_STATE');
        }
        this.logger.verbose(`Updating end time for cue ${cueId} to ${endTime}`);
        return this.eventManager.updateEventCueEndTime(cueId, endTime);
    }

    public registerCue<T>(cue: EventCue<T>): string {
        if (!this.eventManager) {
            throw new Error('SDK.EVENT.INVALID_STATE');
        }

        const isVod = checkIsManifestVodType(this.sessionItem.type);
        const cueId = this.eventManager.registerEventCue(cue);

        this.logger.verbose(`Registered cue ${cueId} for cue: `, cue);

        if (isVod) {
            return cueId;
        }

        this.getLiveWindow().then((liveWindow?: LiveWindow | null) => {
            if (!liveWindow) {
                this.logger.warn(
                    'Live window did not exist at the point of registering a Cue, this maybe fine, but prevents us checking the cue is being registered in the live window'
                );
            } else if (typeof cue.endTime === 'number' && (cue.endTime as number) < liveWindow?.start) {
                this.logger.warn(
                    `Cue registered to end at ${cue.endTime} before the start of the live window (${liveWindow?.start})- will be almost instantly deregistered`
                );
            }
        });

        return cueId;
    }

    public unregisterCue(cueId: string): boolean {
        if (!this.eventManager) {
            throw new Error('SDK.EVENT.INVALID_STATE');
        }

        const cueFound = this.eventManager.unregisterEventCue(cueId);
        return cueFound;
    }

    public onEventCueEntered(type: string, callback: EventCueCallback) {
        this.eventManager?.onEventCueEntered(type, callback);
    }

    public onEventCueExited(type: string, callback: EventCueCallback) {
        this.eventManager?.onEventCueExited(type, callback);
    }

    public onEventCueRegistered(type: string, callback: OnEventCueRegisteredCallback) {
        this.eventManager?.onEventCueRegistered(type, callback);
    }

    public onEventCueUpdated(type: string, callback: OnEventCueUpdatedCallback) {
        this.eventManager?.onEventCueUpdated(type, callback);
    }

    public onEventCueUnregistered(type: string, callback: EventCueCallback) {
        this.eventManager?.onEventCueUnregistered(type, callback);
    }

    public notifyTelemetry(telemetryEvent: TelemetryEvent): void {
        this.telemetryObservable.notifyObservers(telemetryEvent);
    }

    public notifyPlayStart(): void {
        this.playStartObservable.notifyObservers(
            undefined,
            this.advertsManager.timeIncludingAdsToContentTime(this.currentPlayerItem?.getCurrentPosition() as number)
        );
    }

    public notifyRebufferStart(): void {
        this.rebufferStartObservable.notifyObservers(
            undefined,
            this.advertsManager.timeIncludingAdsToContentTime(this.currentPlayerItem?.getCurrentPosition() as number)
        );
    }

    public notifyRebufferEnd(): void {
        this.rebufferEndObservable.notifyObservers(
            undefined,
            this.advertsManager.timeIncludingAdsToContentTime(this.currentPlayerItem?.getCurrentPosition() as number)
        );
    }

    public getContentTimePosition(): number | undefined {
        return this.advertsManager.timeIncludingAdsToContentTime(this.currentPlayerItem?.getCurrentPosition() as number);
    }

    public getPrecursor(): SessionControllerPrecursor | undefined {
        return this.sessionControllerPrecursor;
    }

    public getPrefetchStage(): VideoStartupStates | undefined {
        return this.sessionControllerPrecursor.getPrefetchStage();
    }

    public isPrefetchedSession(): boolean {
        return this.sessionControllerPrecursor.isPrefetchedSession();
    }

    public getAdvertsManager(): AdvertsManager | undefined {
        return this.advertsManager;
    }

    public getHasSeekedFromLiveEdge(): boolean {
        return this.hasSeekedFromLiveEdge;
    }

    public getProposition(): Proposition {
        return this.config.proposition;
    }

    public async stop(): Promise<void> {
        this.setSessionState(InternalSessionState.Stopping);
        await this.timelineManager.endTimeline();
    }

    public play(): void {
        if (this.isBlockingUserInput() || this.shouldBlockSeekingState()) {
            this.logger.warn('SDK.SESSION.PLAY.INVALID_STATE');
            return;
        }

        if (this.currentState !== InternalSessionState.Playing) {
            this.currentPlayerItem?.play();
        }
    }

    public pause(): void {
        if (this.isBlockingUserInput() || this.shouldBlockSeekingState()) {
            this.logger.warn('SDK.SESSION.PAUSE.INVALID_STATE');
            return;
        }
        if (this.currentState !== InternalSessionState.Paused && this.currentState !== InternalSessionState.Seeking) {
            this.hasSeekedFromLiveEdge = true;
            this.advertsManager.getPauseAdvertDispatcher()?.registerUserPause();
            this.currentPlayerItem?.pause();
        }
    }

    public async seekToDate(seekDate: Date): Promise<void> {
        if (!this.isSeekToDateSupported()) {
            this.logger.warn('SDK.SESSION.SEEK_TO_DATE.NOT_SUPPORTED');
            return;
        }

        const liveWindow = await this.getLiveWindow();
        this.logger.verbose(`Live Start: ${liveWindow}`);

        if (!liveWindow) {
            this.logger.warn('SDK.SESSION.SEEK_TO_DATE.NO_LIVE_WINDOW');
            return;
        }

        if (!liveWindow.startDate) {
            this.logger.warn('SDK.SESSION.SEEK_TO_DATE.NO_START_DATE');
            return;
        }

        const contentTime = this.absoluteSeekDateToContentTime(liveWindow, seekDate);
        this.logger.info(`Seek Date: ${seekDate} translated to content time: ${contentTime}`);
        // Seeking manager will take care of bounding to Live Window here
        return this.seek(contentTime);
    }

    public seek(contentTime: number): void {
        this.hasSeekedFromLiveEdge = true;
        this.seekingManager.seek(contentTime);
    }

    public async seekToLiveEdge(): Promise<void> {
        this.hasSeekedFromLiveEdge = false;
        this.seekingManager.seekToLiveEdge();
    }

    public async seekToLiveStart(): Promise<void> {
        this.hasSeekedFromLiveEdge = true;
        this.seekingManager.seekToLiveStart();
    }

    public async isAtLiveEdge(position?: number): Promise<boolean> {
        if (!checkIsManifestLinearType(this.playbackType)) {
            return false;
        }

        const liveWindow = await this.getLiveWindow();
        if (!liveWindow) {
            return false;
        }

        return this.calculateIfAtLiveEdge(liveWindow, position ?? this.currentPlayerItem!.getCurrentPosition());
    }

    public isLinearScrubbingSupported(): boolean {
        return !!CoreVideoInternal.playerCapabilities.isLinearScrubbingSupported;
    }

    public setVolume(volume: number): void {
        if (this.isBlockingUserInput()) {
            this.logger.warn('SDK.SESSION.SET_VOLUME.INVALID_STATE');
            return;
        }
        this.currentPlayerItem?.setVolume(volume);
    }

    public setMute(isMuted: boolean): void {
        if (this.isBlockingUserInput()) {
            this.logger.warn('SDK.SESSION.SET_MUTE.INVALID_STATE');
            return;
        }
        this.currentPlayerItem?.setMute(isMuted);
    }

    public setPlayerBitrateLimits(playerBitrateLimits: PlayerBitrateLimits): void {
        this.setPlayerBitrateLimitsWithReason(playerBitrateLimits, BitrateCapReason.UserConfiguredCap);
    }

    public setPlayerBitrateLimitsWithReason(
        playerBitrateLimits: PlayerBitrateLimits,
        bitrateCapReason: BitrateCapReason = BitrateCapReason.UserConfiguredCap
    ): void {
        if (this.isBlockingUserInput()) {
            this.logger.warn('SDK.SESSION.SET_VIDEO_BITRATE_LIMITS.INVALID_STATE');
            return;
        }

        const midStreamCappingSupported = CoreVideoInternal.playerCapabilities.canSetMidStreamPlayerBitrateLimits;
        const maxBitRateCapData = this.bitrateCapManager.getMaxBitRate(playerBitrateLimits.maxBitRate, bitrateCapReason);
        if (maxBitRateCapData.requestedCapChanged || bitrateCapReason === BitrateCapReason.UserConfiguredCap) {
            this.bitrateCapManager.notifyRequestedBitrateCap({ value: playerBitrateLimits.maxBitRate ?? Infinity, reason: bitrateCapReason });
        }

        if (maxBitRateCapData.currentCapChanged) {
            this.logger.verbose(`setPlayerBitrateLimits: setting the new bitrate cap: ${maxBitRateCapData.value} / ${maxBitRateCapData.reason}`);
            if (midStreamCappingSupported) {
                this.bitrateCapManager.notifyAppliedBitrateCap(maxBitRateCapData);
                this.currentPlayerItem?.setPlayerBitrateLimits({ ...playerBitrateLimits, maxBitRate: maxBitRateCapData.value });
            } else {
                this.logger.warn('SDK.SESSION.SET__MID_STREAM_VIDEO_BITRATE_LIMITS.NOT_SUPPORTED');
            }
        }
    }

    public resetPlayerBitrateLimits(): void {
        if (this.isBlockingUserInput()) {
            this.logger.warn('SDK.SESSION.RESET_VIDEO_BITRATE_LIMITS.INVALID_STATE');
            return;
        }
        const midStreamCappingSupported = CoreVideoInternal.playerCapabilities.canSetMidStreamPlayerBitrateLimits;
        const maxBitRateCapData = this.bitrateCapManager.getMaxBitRate(Infinity, BitrateCapReason.UserConfiguredCap);

        this.bitrateCapManager.notifyRequestedBitrateCap({ value: Infinity, reason: BitrateCapReason.UserConfiguredCap });

        if (maxBitRateCapData.currentCapChanged) {
            this.logger.verbose(`resetPlayerBitrateLimits: setting the new bitrate cap: ${maxBitRateCapData.value} / ${maxBitRateCapData.reason}`);
            if (midStreamCappingSupported) {
                this.bitrateCapManager.notifyAppliedBitrateCap(maxBitRateCapData);
                if (maxBitRateCapData.value !== Infinity) {
                    this.currentPlayerItem?.setPlayerBitrateLimits({ maxBitRate: maxBitRateCapData.value });
                } else {
                    this.currentPlayerItem?.resetPlayerBitrateLimits();
                }
            } else {
                this.logger.warn('SDK.SESSION.RESET__MID_STREAM_VIDEO_BITRATE_LIMITS.NOT_SUPPORTED');
            }
        }
    }

    public enableSubtitles(trackId: Track['id']): void {
        if (this.isBlockingUserInput()) {
            this.logger.warn('SDK.SESSION.ENABLE_SUBTITLES.INVALID_STATE');
            return;
        }

        this.currentPlayerItem?.enableSubtitles(trackId);
    }

    public disableSubtitles(): void {
        if (this.isBlockingUserInput()) {
            this.logger.warn('SDK.SESSION.DISABLE_SUBTITLES.INVALID_STATE');
            return;
        }

        this.currentPlayerItem?.disableSubtitles();
    }

    public getMaxVideoFormat(): VideoFormat | undefined {
        return this.sessionItem?.videoFormatConfig?.support?.maxVideoFormat ?? VideoFormat.HD;
    }

    public getSupportedColourSpaces(): Array<VideoColourSpace> | undefined {
        return this.sessionItem?.videoFormatConfig?.support?.supportedColourSpaces ?? [VideoColourSpace.SDR];
    }

    public getSupportedAudioFormats(): Array<AudioFormat> | undefined {
        return CoreVideoInternal.playerCapabilities?.supportedAudioFormats ?? [AudioFormat.STEREO];
    }

    public getSupportedMaxHdcpLevel(): HdcpLevel | undefined {
        return CoreVideoInternal.playerCapabilities?.supportedMaxHdcpLevel;
    }

    public getConnectedHdcpLevel(): HdcpLevel | undefined {
        return CoreVideoInternal.playerCapabilities?.connectedHdcpLevel;
    }

    public getWidevineSecurityLevel(): WidevineSecurityLevel | undefined {
        return CoreVideoInternal.playerCapabilities?.widevineLevel;
    }

    public getSupportedVideoCodecs(): Array<VideoCodec> | undefined {
        return CoreVideoInternal.playerCapabilities?.supportedVideoCodecs;
    }

    public setAudioTrack(trackId: Track['id']): void {
        if (this.isBlockingUserInput()) {
            this.logger.warn('SDK.SESSION.SET_AUDIO_TRACK.INVALID_STATE');
            return;
        }
        this.audioTrackWillChangeObservable.notifyObservers(trackId);
        this.currentPlayerItem?.setAudioTrack(trackId);
    }

    public waitForOneOfStates(states: Array<InternalSessionState>): Promise<void> {
        if (states.includes(this.currentState)) {
            return Promise.resolve();
        }

        const callbackOwner = {};
        return new Promise<void>((resolve, reject) => {
            this.sessionStateObservable.registerObserver((newState) => {
                if (states.includes(newState)) {
                    resolve();
                    this.sessionStateObservable.unregisterObservers(callbackOwner);
                    this.errorObservable.unregisterObservers(callbackOwner);
                }
            }, callbackOwner);

            this.errorObservable.registerObserver((error: CvsdkError) => {
                if (error.severity === ErrorSeverity.Fatal) {
                    reject(error);
                    this.sessionStateObservable.unregisterObservers(callbackOwner);
                    this.errorObservable.unregisterObservers(callbackOwner);
                }
            }, callbackOwner);
        });
    }

    public waitForState(state: InternalSessionState): Promise<void> {
        return this.waitForOneOfStates([state]);
    }

    public getCurrentState(): InternalSessionState {
        return this.currentState;
    }

    public getLiveWindow = async (): Promise<LiveWindow | null> => {
        if (this.isBlockingUserInput()) {
            this.logger.warn('SDK.SESSION.GET_LIVE_WINDOW.INVALID_STATE');
            return null;
        }

        if (this.currentTimelineItem?.type !== TimelineItemType.MainContent) {
            return null;
        }

        return this.currentPlayerItem?.getLiveWindow() || null;
    };

    public onAdStarted(callback: (ad: Ad) => void): void {
        this.adStartedObservable.registerObserver(callback, this);
    }

    public onAdFinished(callback: (ad: Ad) => void): void {
        this.adFinishedObservable.registerObserver(callback, this);
    }

    public onAdBreakStarted(callback: (adBreak: AdBreak) => void): void {
        this.adBreakStartedObservable.registerObserver(callback, this);
    }

    public onAdBreakFinished(callback: (adBreak: AdBreak) => void): void {
        this.adBreakFinishedObservable.registerObserver(callback, this);
    }

    public onAdBreakDataReceived(callback: (adBreaks: Array<AdBreak>) => void): void {
        this.adBreakDataReceivedObservable.registerObserver(callback, this);
    }

    public onAdTrackingReceived(callback: (adBreaks: Array<AdBreak>) => void): void {
        this.adTrackingReceivedObservable.registerObserver(callback, this);
    }

    public onCatchupSeek(callback: (catchupSeek: CatchupSeekEvent) => void): void {
        this.catchupSeekObservable.registerObserver(callback, this);
    }

    public onVideoElementCreated(callback: (videoElement: HTMLVideoElement) => void): void {
        this.videoElementCreatedObservable.registerObserver(callback, this);
    }

    public onFullscreenChanged(callback: (isFullscreen: boolean) => void): void {
        this.fullscreenChangedObservable.registerObserver(callback, this);
    }

    public onClicked(callback: () => void): void {
        this.clickedObservable.registerObserver(callback, this);
    }

    public onAppBackgrounded(callback: () => void): void {
        this.appBackgroundedObservable.registerObserver(callback, this);
    }

    public onAppForegrounded(callback: () => void): void {
        this.appForegroundedObservable.registerObserver(callback, this);
    }

    public onQualityFailover(callback: (videoFormat: VideoFormat) => void): void {
        this.qualityFailoverObservable.registerObserver(callback, this);
    }

    public onCdnSwitch(callback: (cdnSwitch: CdnSwitchEvent) => void): void {
        this.cdnSwitchObservable.registerObserver(callback, this);
    }

    public onStateChanged(callback: (state: InternalSessionState, currentPosition?: number) => void, priority?: ObserverPriority): void {
        this.sessionStateObservable.registerObserver(callback, this, { priority });
    }

    public onRebufferStart(callback: (_: void, currentPosition?: number) => void): void {
        this.rebufferStartObservable?.registerObserver(callback, this);
    }

    public onRebufferEnd(callback: (_: void, currentPosition?: number) => void): void {
        this.rebufferEndObservable?.registerObserver(callback, this);
    }

    public onTelemetry(callback: (telemetry: TelemetryEvent) => void): void {
        this.telemetryObservable.registerObserver(callback, this);
    }

    public onTelemetryUpdate(callback: (vstTelemetry: TelemetryDataType) => void): void {
        this.telemetryUpdateObservable.registerObserver(callback, this);
    }

    public updateTelemetryMetrics(telemetryData: TelemetryDataType): void {
        this.telemetryUpdateObservable.notifyObservers(telemetryData);
    }

    public onHttpRequest(callback: (httpRequest: PlayerHttpRequest) => void): void {
        this.httpRequestObservable.registerObserver(callback, this);
    }

    public onHttpResponse(callback: (httpResponse: PlayerHttpResponse) => void): void {
        this.httpResponseObservable.registerObserver(callback, this);
    }

    public onError(callback: (error: CvsdkError) => void): void {
        this.errorObservable.registerObserver(callback, this);
    }

    public onWarning(callback: (error: CvsdkError) => void): void {
        this.warningObservable.registerObserver(callback, this);
    }

    public onPlaybackTimelineUpdated(callback: (playbackTimeline: PlaybackTimelineInternal, currentPosition?: number) => void): void {
        // @ts-ignore
        this.playbackTimelineObservable.registerObserver(callback, this);
    }

    public onAdPositionChanged(callback: (adPosition: AdPosition) => void): void {
        this.adPositionObservable.registerObserver(callback, this);
    }

    public onPauseAd(callback: (event: NonLinearAdEvent) => void): void {
        this.pauseAdObservable.registerObserver(callback, this);
    }

    public onVerifyCompanionAdInsertionEnabled(callback: (companionAdInsertionEnabled: boolean) => void): void {
        this.companionAdInsertionEnabledObservable.registerObserver(callback, this);
    }

    public onCompanionAdOpportunityStarted(callback: (event: CompanionAdOpportunityStartedEvent) => void): void {
        this.companionAdOpportunityStartedObservable.registerObserver(callback, this);
    }

    public onCompanionAdOpportunityEnded(callback: (event: CompanionAdOpportunityEndedEvent) => void): void {
        this.companionAdOpportunityEndedObservable.registerObserver(callback, this);
    }

    public onRenderWatermark(callback: (event: WatermarkData) => void): void {
        this.renderWatermarkObservable.registerObserver(callback, this);
    }

    public onClearWatermark(callback: (event: WatermarkData) => void): void {
        this.clearWatermarkObservable.registerObserver(callback, this);
    }

    public onClearWatermarks(callback: () => void): void {
        this.clearWatermarksObservable.registerObserver(callback, this);
    }

    public onUserWaitStarted(callback: (currentPosition?: number, absolutePosition?: Date) => void): void {
        this.userWaitStartedObservable.registerObserver(
            (_: unknown, currentPosition?: number, absolutePosition?: Date) => callback(currentPosition, absolutePosition),
            this
        );
    }

    public onUserWaitEnded(callback: () => void): void {
        this.userWaitEndedObservable.registerObserver(callback, this);
    }

    public onStreamMetadataReceived(callback: (metadata: StreamMetadata) => void): void {
        this.streamMetadataReceivedObservable.registerObserver(callback, this);
    }

    public onTimedMetadataReceived(callback: (metadata: TimedMetadata) => void): () => void {
        return this.timedMetadataReceivedObservable.registerObserver(callback, this);
    }

    public onBulkTimedMetadataReceived(callback: (metadatas: Array<TimedMetadata>) => void): void {
        this.bulkTimedMetadataReceivedObservable.registerObserver(callback, this, { renotifyLastEvent: true });
    }

    public onBitrateChanged(callback: (bitrateLevel: BitRateLevel) => void): void {
        this.bitrateChangedObservable.registerObserver(callback, this);
    }

    public onEncodedFrameRateChanged(callback: (frameRate: number) => void): void {
        this.encodedFrameRateChangedObservable.registerObserver(callback, this);
    }

    public onRenderedFrameRateChanged(callback: (frameRate: number) => void): void {
        this.renderedFrameRateChangedObservable.registerObserver(callback, this);
    }

    public onVideoTrackChanged(callback: (videoTrack: VideoTrack) => void): void {
        this.videoTrackChangedObservable.registerObserver(callback, this);
    }

    public onAvailableAudioTracksChanged(callback: (audioTracks: Array<Track>) => void): () => void {
        return this.availableAudioTracksChangedObservable.registerObserver(callback, this);
    }

    public onAvailableSubtitlesTracksChanged(callback: (subtitleTracks: Array<Track>) => void): () => void {
        return this.availableSubtitlesTracksChangedObservable.registerObserver(callback, this);
    }

    public onAvailableThumbnailVariantsChanged(callback: (thumbnailVariants: Array<ThumbnailVariant>) => void): void {
        this.availableThumbnailVariantsChangedObservable.registerObserver(callback, this);
    }

    public onAudioTrackWillChange(callback: (trackId: Track['id']) => void): void {
        this.audioTrackWillChangeObservable.registerObserver(callback, this);
    }

    public onAudioTrackChanged(callback: (trackId: Track['id']) => void): void {
        this.audioTrackChangedObservable.registerObserver(callback, this);
    }

    public onBitrateCapChanged(callback: (bitrateCap: BitrateCapData) => void): void {
        this.bitrateCapChangedObservable.registerObserver(callback, this);
    }

    public onBitrateCapRequested(callback: (bitrateCap: BitrateCapData) => void): void {
        this.bitrateCapRequestedObservable.registerObserver(callback, this);
    }

    public onPlayoutDataReceived(callback: (playoutData: PlayoutData) => void): void {
        this.playoutDataObservable.registerObserver(callback, this);
    }

    public onPlayoutRulesReceived(callback: (playoutRules: PlayoutRules) => void): void {
        this.playoutRulesObservable.registerObserver(callback, this);
    }

    public onSubtitlesTrackChanged(callback: (track?: Track) => void): void {
        this.subtitlesTrackChangedObservable.registerObserver(callback, this);
    }

    public onSubtitleCuesChanged(callback: (subtitleCues: Array<SubtitleCue>) => void): void {
        this.subtitleCuesChangedObservable.registerObserver(callback, this);
    }

    public onPlayStart(callback: (_: void, currentPosition?: number) => void): void {
        this.playStartObservable.registerObserver(callback, this);
    }

    public onSeekStarted(callback: (positionMs: number, currentPosition?: number) => void): void {
        this.seekingManager.onSeekStarted(callback);
    }

    public onSeekEnded(callback: (_: void, currentPosition?: number) => void): void {
        this.seekingManager.onSeekEnded(callback);
    }

    public onSessionEnded(callback: () => void): void {
        this.sessionEndedObservable.registerObserver(callback, this, { once: true });
    }

    public onVolumeChanged(callback: (volume: number) => void): void {
        this.volumeChangedObservable.registerObserver(callback, this);
    }

    public onMuteChanged(callback: (isMuted: boolean) => void): void {
        this.isMutedObservable.registerObserver(callback, this);
    }

    public onEventBoundary(callback: (eventBoundary: BoundaryEvent) => void): void {
        this.eventBoundaryObservable.registerObserver(callback, this);
    }

    public onEndOfEventMarkerReceived(callback: (eventTime: number) => void): void {
        this.endOfEventMarkerReceivedObservable.registerObserver(callback, this);
    }

    public onManifestReceived(callback: (manifestReceived: ManifestReceivedEvent) => void): void {
        this.manifestReceivedObservable.registerObserver(callback, this);
    }

    public onPinRequired(callback: (cause: PinRequiredCause) => void): void {
        this.pinRequiredObservable.registerObserver(callback, this);
    }

    public onPinSuccess(callback: () => void): void {
        this.pinSuccessObservable.registerObserver(callback, this);
    }

    public onAutoPlayPolicyPreventedPlayback(callback: () => void): void {
        this.autoPlayPolicyPreventedPlaybackObservable.registerObserver(callback, this);
    }

    public onAssetMetadataUpdated(callback: (assetMetadata: AssetMetadata) => void): void {
        this.assetMetadataUpdateObservable.registerObserver(callback, this);
    }

    public notifySessionEnded(): void {
        this.sessionEndedObservable.notifyObservers();
    }

    public notifyEventBoundary(event: BoundaryEvent): void {
        this.eventBoundaryObservable.notifyObservers(event);
    }

    public notifyEndOfEventMarkerReceived(eventTime: number): void {
        this.endOfEventMarkerReceivedObservable.notifyObservers(eventTime);
    }

    public notifyLiveWindowUpdate = (liveWindow: LiveWindow | null): void => {
        if (!liveWindow) {
            return;
        }

        const modifiedSeekableRange = this.seekingManager.adjustLiveWindow(liveWindow);
        this.playbackTimelineObservable.notifyObservers({ liveWindow: liveWindow, seekableRange: modifiedSeekableRange });
    };

    public notifyStreamMetadataReceived(metadata: StreamMetadata): void {
        this.streamMetadataReceivedObservable.notifyObservers(metadata);
    }

    public notifyAvailableAudioTracksChanged = (audioTracks: Array<Track>): void => {
        const filteredAudioTracks = this.addonManager.filterAudioTracks(audioTracks);
        this.availableAudioTracksChangedObservable.notifyObservers(filteredAudioTracks);
    };

    public notifyAvailableSubtitlesTracksChanged = (subtitlesTracks: Array<Track>): void => {
        const filteredSubtitlesTracks = this.addonManager.filterSubtitlesTracks(subtitlesTracks);
        this.availableSubtitlesTracksChangedObservable.notifyObservers(filteredSubtitlesTracks);
    };

    public notifySubtitleCuesChanged(cues: Array<SubtitleCue>): void {
        this.subtitleCuesChangedObservable.notifyObservers(cues);
    }

    public notifyCurrentSubtitlesTrackChanged = (track: Track): void => {
        this.currentPlayerItem?.notifySubtitleCuesReset();

        this.subtitlesTrackChangedObservable.notifyObservers(track);
    };

    public notifyAvailableThumbnailVariantsChanged = (thumbnailVariants: Array<ThumbnailVariant>): void => {
        this.availableThumbnailVariantsChangedObservable.notifyObservers(thumbnailVariants);
    };

    public notifyTimelineAssetsAvailable(assetGroups: Array<AdAssetGroup>): void {
        this.logger.info('Scheduling linear ads on the timeline:', assetGroups);
        assetGroups.forEach((group) => {
            this.timelineManager.insertAdverts(group);
        });
    }

    public notifyAdBreakStarted(adBreak: AdBreak): void {
        this.adPositionObservable.notifyObservers({
            adPosition: 0,
            adBreakPosition: 0,
            ad: adBreak.ads && adBreak.ads[0],
            adBreak,
        });

        this.adBreakStartedObservable.reset();
        this.adBreakStartedObservable.notifyObservers(adBreak);
    }

    public notifyAdStarted(adData: Ad): void {
        this.adStartedObservable.reset();
        this.adStartedObservable.notifyObservers(adData);
    }

    public notifyAdFinished(adData: Ad): void {
        this.adFinishedObservable.reset();
        this.adFinishedObservable.notifyObservers(adData);
    }

    public notifyAdBreakFinished(adBreak: AdBreak): void {
        this.adBreakFinishedObservable.reset();
        this.adBreakFinishedObservable.notifyObservers(adBreak);
    }

    private updatePlayerStartPosition = (): void => {
        if (
            this.isListeningForVST &&
            this.sessionControllerPrecursor.playoutData?.position &&
            !this.startPositionMoved &&
            checkIsManifestVodType(this.sessionItem.type) &&
            this.advertsManager.isInitialPositionAdjustmentRequired()
        ) {
            this.seekingManager.seekToAdjustedStartPosition(this.sessionControllerPrecursor.playoutData.position!);
            this.startPositionMoved = true;
        }
    };

    public notifyAdBreakDataReceived(adBreaks: Array<AdBreak>): void {
        this.updatePlayerStartPosition();
        const advertising = this.advertsManager.advertisingData;
        if (advertising && !advertising.isKillswitchEnabled && shouldLoadAdvertisingAddons(this.sessionItem)) {
            this.addonManager.prepareAdvertising(advertising);
        }

        this.adBreakDataReceivedObservable.notifyObservers(this.getAdaptedClientFilteredAdBreakData(adBreaks));
    }

    public notifyAdTrackingReceived(adBreaks: Array<AdBreak>): void {
        this.adTrackingReceivedObservable.notifyObservers(this.getAdaptedClientFilteredAdBreakData(adBreaks));
    }

    public notifyCatchupSeek(catchupSeek: CatchupSeekEvent): void {
        this.catchupSeekObservable.notifyObservers(catchupSeek);
    }

    private getAdaptedClientFilteredAdBreakData(adBreaks: Array<AdBreak>) {
        return this.adaptAdBreaksByStreamType(adBreaks.filter((adBreak) => adBreak.ads.length));
    }

    private adaptAdBreaksByStreamType(adBreaks: Array<AdBreak>): Array<AdBreak> {
        if (checkIsManifestVodType(this.sessionItem.type)) {
            return this.advertsManager.adaptAdBreaksPositionsToContentTime(adBreaks);
        } else {
            return adBreaks;
        }
    }

    public notifyError(error: CvsdkError): void {
        error = this.sessionControllerPrecursor.adaptPlayerError(error);

        const timeIncludingAds = this.currentPlayerItem?.getCurrentPosition();
        if (timeIncludingAds !== undefined) {
            const contentTime = this.advertsManager.timeIncludingAdsToContentTime(timeIncludingAds);
            this.logger.breadcrumb(`EngineItem Time: ${timeIncludingAds} | Content Time: ${contentTime}`);
        }
        if (this.sessionControllerPrecursor.isAppBackgrounded()) {
            error.code = `BACKGROUND.${error.code}`;
        }
        this.errorObservable.notifyObservers(error);
    }

    public notifyWarning(errorCode: string, reason: string): void {
        this.warningObservable.notifyObservers(
            CvsdkError.from({
                code: errorCode,
                message: reason,
                severity: ErrorSeverity.Warning,
            })
        );
    }

    // Create a wrapper for the observable that is able to transform instead of having it in every observable
    public setEventTransformers(eventTransformers: EventTransformers): void {
        this.logger.info('Setting transformers on Session-Proxy events');
        if (eventTransformers.onStateChanged) {
            eventTransformers.onStateChanged.forEach((t) => {
                this.sessionStateObservable.addTransformer(t);
            });
        }

        if (eventTransformers.onError) {
            eventTransformers.onError.forEach((t) => {
                this.errorObservable.addTransformer(t);
            });
        }

        if (eventTransformers.onSessionEnded) {
            eventTransformers.onSessionEnded.forEach((t) => {
                this.sessionEndedObservable.addTransformer(t);
            });
        }
    }

    public notifyPauseAd(event: NonLinearAdEvent): void {
        this.pauseAdObservable.notifyObservers(event);
    }

    public notifyCompanionAdInsertionEnabled(companionAdInsertionEnabled: boolean): void {
        if (!checkIsManifestSingleLiveEventType(this.sessionItem.type)) {
            this.logger.info('Companion Ad insertion not supported for non SLE streams, notifyCompanionAdInsertionEnabled will return false');
            this.companionAdInsertionEnabledObservable.notifyObservers(false);
            return;
        }
        this.logger.info('notifyCompanionAdInsertionEnabled', companionAdInsertionEnabled);
        this.companionAdInsertionEnabledObservable.notifyObservers(companionAdInsertionEnabled);
    }

    public notifyCompanionAdOpportunityStarted(event: CompanionAdOpportunityStartedEvent): void {
        this.logger.info('notifyCompanionAdOpportunityStarted', event);
        this.companionAdOpportunityStartedObservable.notifyObservers(event);
    }

    public notifyCompanionAdOpportunityEnded(event: CompanionAdOpportunityEndedEvent): void {
        this.logger.info('notifyCompanionAdOpportunityEnded', event);
        this.companionAdOpportunityEndedObservable.notifyObservers(event);
    }

    public notifyRenderWatermark(data: WatermarkData): void {
        this.logger.info('notifyRenderWatermark', data);
        this.renderWatermarkObservable.notifyObservers(data);
    }

    public notifyClearWatermark(data: WatermarkData): void {
        this.logger.info('notifyClearWatermark', data);
        this.clearWatermarkObservable.notifyObservers(data);
    }

    public notifyClearWatermarks(): void {
        this.logger.info('notifyClearWatermarks');
        this.clearWatermarksObservable.notifyObservers();
    }

    public notifyAdPositionChanged(adPosition: AdPosition): void {
        this.adPositionObservable.notifyObservers(adPosition);
    }

    public notifyAssetMetadataUpdated(assetMetadata: AssetMetadata): void {
        this.assetMetadataUpdateObservable.notifyObservers(assetMetadata);
    }

    public notifyManifestReceived(playerManifestReceivedEvent: PlayerManifestReceivedEvent, timelineItemType?: TimelineItemType): void {
        if (timelineItemType) {
            this.manifestReceivedObservable.notifyObservers({ playerManifestReceivedEvent, timelineItemType });
        }
        this.currentPlayerItem?.setActiveManifest(playerManifestReceivedEvent.manifestResponse.manifest);
    }

    public async requestSessionStop(error: CvsdkError): Promise<void> {
        this.logger.info(`Session stop requested for ${error.message}`);
        this.notifyError(error);
        await this.stop();
    }

    public isFinished(): boolean {
        return FINAL_STATES.includes(this.currentState);
    }

    public restartSession(): void {
        this.sessionControllerPrecursor.restartSession();
    }

    public retrySession(): void {
        // Added here for type safety. Implemented in the Chromecast sender session controller only
        this.logger.warn(`Method 'retrySession' is not implemented`);
    }

    public isBlockingUserInput(): boolean {
        return [...INITIAL_STATES, ...FINAL_STATES].includes(this.currentState);
    }

    public setUserWaitStarted(): void {
        const positionIncludingAds = this.advertsManager.timeIncludingAdsToContentTime(this.currentPlayerItem?.getCurrentPosition() as number);

        if (this.currentPlayerItem) {
            this.currentPlayerItem.getLiveWindow().then((liveWindow) => {
                this.userWaitStartedObservable.notifyObservers(
                    undefined,
                    positionIncludingAds,
                    this.getAbsolutePosition(positionIncludingAds, liveWindow ?? undefined)
                );
            });
        } else {
            this.userWaitStartedObservable.notifyObservers();
        }
    }

    public setUserWaitEnded(): void {
        this.userWaitEndedObservable.notifyObservers();
    }

    public cancelPin(): void {
        if (!this.isWaitingForPin) {
            return;
        }
        this.logger.info('User cancelled entry of PIN');
        this.stop();
        this.destroy();
    }

    public async setPin(pin: string): Promise<void> {
        if (!this.isWaitingForPin || !('key' in this.sessionItem)) {
            return;
        }
        try {
            await this.initialisePrecursor(pin);
            await this.tryPlayout();
        } catch (e) {
            if (e instanceof Error) {
                this.handleStartError(e);
            } else {
                this.logger.warn('Expected error to be instanceof Error', e);
                this.handleStartError(e as Error);
            }
        }
    }

    public setThumbnailVariant(variantId: string): Promise<boolean | undefined> {
        if (checkIsManifestSingleLiveEventType(this.sessionItem.type)) {
            if (CoreVideoInternal.getPropositionExtensions()?.sleThumbnails?.allowed && this.sessionItem.enableSleThumbnails) {
                this.logger.info('SLE thumbnails are enabled');
            } else {
                return Promise.resolve(undefined);
            }
        }
        return this.currentPlayerItem!.setThumbnailVariant(variantId);
    }

    public async getThumbnailForTime(contentTime: number, velocity?: number): Promise<ThumbnailRenderInfo | null> {
        if (this.isBlockingUserInput()) {
            this.logger.warn('SDK.SESSION.GET_THUMBNAIL.INVALID_STATE');
            return null;
        }
        const timeIncludingAds = this.advertsManager.contentTimeToTimeIncludingAds(contentTime);
        const thumbnailRenderInfo = await this.currentPlayerItem!.getThumbnailForTime(timeIncludingAds, contentTime, velocity);

        const thumbnailInfoUtils = this.advertsManager.getThumbnailInfoUtils();
        if (thumbnailInfoUtils && thumbnailRenderInfo) {
            return thumbnailInfoUtils.adaptThumbnailPositionsToContentTime(thumbnailRenderInfo);
        } else {
            return thumbnailRenderInfo;
        }
    }

    public isSeekToDateSupported(): boolean {
        return checkIsManifestLinearType(this.sessionItem.type);
    }

    private notifyAppBackgrounded: () => Promise<void> = () => {
        this.appBackgroundedObservable.notifyObservers();
        return Promise.resolve();
    };

    private notifyAppForegrounded: () => Promise<void> = () => {
        this.appForegroundedObservable.notifyObservers();
        return Promise.resolve();
    };

    private notifyAutoPlayPolicyPreventedPlayback(): void {
        this.autoPlayPolicyPreventedPlaybackObservable.notifyObservers();
    }

    private absoluteSeekDateToContentTime(liveWindow: LiveWindow, seekDate: Date): number {
        const millisecondsInASecond = 1000;
        const positionPastLiveStartSec = (seekDate.getTime() - liveWindow.startDate!.getTime()) / millisecondsInASecond;
        return liveWindow.start + positionPastLiveStartSec;
    }

    private shouldBlockSeekingState(): boolean {
        return (
            Boolean(CoreVideoInternal.playerCapabilities?.hasSeekingStateSupport) &&
            (this.preSeekLock.locked || this.currentState === InternalSessionState.Seeking)
        );
    }

    private callSeekOnTimelineManager(seekTo: number): void {
        this.timelineManager.seek(seekTo);
    }

    private calculateUserSeekedFromLiveEdge(isCurrentlyAtLiveEdge: boolean): void {
        // If content is playing and the player is currently at the live edge, then the user has either not seeked
        // or seeked back back to live edge range
        if (this.currentState === InternalSessionState.Playing && isCurrentlyAtLiveEdge) {
            this.hasSeekedFromLiveEdge = false;
            // If the session is paused, the user may have fallen out of the live edge
            // In this case, the user is considered to have seeked away from the live edge
        } else if (this.currentState === InternalSessionState.Paused && !isCurrentlyAtLiveEdge) {
            this.hasSeekedFromLiveEdge = true;
        }

        this.logger.verbose(`Has seeked from live edge: ${this.hasSeekedFromLiveEdge}`);
    }

    private attemptToCreatePlaybackTimeline = ({
        position,
        currentTime,
        absolutePosition,
        seekableRange,
        liveWindow,
        muteForClients,
    }: Pick<
        PlaybackTimelineInternal,
        'position' | 'currentTime' | 'absolutePosition' | 'seekableRange' | 'liveWindow' | 'muteForClients'
    >): ComposedState<PlaybackTimelineInternal> | null => {
        if (!position && position !== 0) {
            return null;
        }
        if (checkIsManifestLinearType(this.sessionItem.type)) {
            if (!CoreVideoInternal.playerCapabilities.isLinearScrubbingSupported) {
                return {
                    state: {
                        position,
                        currentTime,
                        absolutePosition,
                        liveWindow,
                    },
                };
            }

            const isAtLiveEdge = seekableRange && this.calculateIfAtLiveEdge(seekableRange, position);

            this.calculateUserSeekedFromLiveEdge(!!isAtLiveEdge);

            return {
                state: {
                    liveWindow,
                    seekableRange,
                    position,
                    currentTime,
                    absolutePosition,
                    isAtLiveEdge,
                    muteForClients,
                },
            };
        }

        return {
            state: {
                seekableRange,
                position,
                currentTime,
                muteForClients,
            },
        };
    };

    private handleStartError(e: Error | CvsdkError): void {
        this.logger.error('SessionController::start', e);

        if (e instanceof CvsdkError) {
            this.errorObservable.notifyObservers(e);
        } else if ('code' in e) {
            this.logger.warn('Expected error to be instanceof CvsdkError', e);
            this.errorObservable.notifyObservers(e as CvsdkError);
        } else {
            this.errorObservable.notifyObservers(
                CvsdkError.from({
                    code: 'SESSION.START_FAILURE',
                    message: 'Error starting session',
                    cause: e,
                    severity: ErrorSeverity.Fatal,
                })
            );
        }

        this.destroy();
    }

    private async initialisePrecursor(pin?: string): Promise<void> {
        try {
            await this.sessionControllerPrecursor.initialise(pin);
        } catch (e) {
            const pinCause = PLAYOUT_ERROR_TO_PIN_CAUSE[(e as CvsdkError)?.code as PlayoutDataError];
            if (pinCause) {
                this.isWaitingForPin = true;

                this.pinRequiredObservable.reset();
                this.pinRequiredObservable.notifyObservers(pinCause);

                perfLogger.measure(PerfKey.core, PerfTag.pinRequired);
            } else {
                throw e;
            }
        }
    }

    private shouldDisablePip(): boolean {
        return Boolean(this.addonManager.isWatermarkingEnabled() || this.sessionItem.isMultiview);
    }

    private async tryPlayout(): Promise<void> {
        const playoutData = this.sessionControllerPrecursor.playoutData;

        const mainAsset = this.sessionControllerPrecursor.mainAsset;
        if (!playoutData || !mainAsset) {
            return;
        }

        const colourSpaceSuccess = await this.activateColourSpace(playoutData);
        if (!colourSpaceSuccess) {
            return;
        }

        this.addonManager.lazyLoad(CoreVideoInternal.playerCapabilities, playoutData); // load addons dependent on PlayoutData (e.g. watermarking for browser)

        this.timelineManager.initializeTimeline(
            mainAsset,
            this.advertsManager.getAdvertBoltOns(),
            this.sessionControllerPrecursor.getDRMBoltOns(),
            this.advertsManager.getAdInsertionConfig()
        );

        if (this.isWaitingForPin) {
            this.pinSuccessObservable.notifyObservers();
            perfLogger.measure(PerfKey.core, PerfTag.pinSuccess);
        }
        const advertising = this.advertsManager.advertisingData;
        if (advertising && !advertising.isKillswitchEnabled && shouldLoadAdvertisingAddons(this.sessionItem)) {
            await this.setupAds(advertising);
        }
        perfLogger.measure(PerfKey.root, PerfTag.startingTimeline);
        await this.timelineManager.startTimeline(playoutData.position);
    }

    private async activateColourSpace(playoutData: PlayoutData): Promise<boolean> {
        const display = CoreVideoInternal.display;
        if (!display?.activateColourSpace || !this.sessionItem.display?.playerViewAlwaysPresentedFullScreen) {
            return true;
        }

        const { colourSpace = VideoColourSpace.SDR } = playoutData.stream;
        const colourSpaceActivated = await display.activateColourSpace(
            ClientVideoColourSpace[VideoColourSpace[colourSpace] as keyof typeof ClientVideoColourSpace]
        );
        if (colourSpaceActivated) {
            return true;
        }

        const colourSpaceString: string = typeof colourSpace === 'string' ? colourSpace : VideoColourSpace[colourSpace];
        const code = `${COLOUR_SPACE_ACTIVATION_ERROR_PREFIX}.${colourSpaceString}`;
        const message = `Failed to activate ${colourSpaceString} colour space`;

        // We should avoid notifying an error if we are trying to switch to SDR and it fails because
        // there is nothing we can fallback to so we have to just play anyway. Otherwise we risk a loop where
        // SDR activation fails to we fallback to SDR and try again.
        if (colourSpace === VideoColourSpace.SDR) {
            this.notifyWarning(code, message);
            return true;
        }

        this.notifyError(
            CvsdkError.from({
                code,
                message,
                severity: ErrorSeverity.Fatal,
            })
        );

        await this.destroy();
        return false;
    }

    private async processVodAdverts(): Promise<void> {
        if (!CoreVideoInternal.playerCapabilities.supportsNativeCsai) {
            const advertGroups = await this.advertsManager.getVodAdverts();
            advertGroups.forEach((group) => {
                this.timelineManager.insertAdverts(group);
            });
        }
    }

    private async setupAds(advertising: AdvertisingData): Promise<void> {
        try {
            this.addonManager.prepareAdvertising(advertising);
            await this.processVodAdverts();
            perfLogger.measure(PerfKey.root, PerfTag.advertisingReady);
        } catch (e) {
            let error: unknown = e;
            if (!(e instanceof CvsdkError)) {
                error = CvsdkError.from({
                    code: 'SDK.SESSION.ADS_SETUP',
                    message: 'Error starting session',
                    severity: ErrorSeverity.Warning,
                    cause: e,
                    category: AddonErrorCategory.CsaiAdRequest,
                });
            }
            this.errorObservable.notifyObservers(error as CvsdkError);
        }
    }

    private setSessionState(state: InternalSessionState): AdaptedState<InternalSessionState> {
        const oldState = this.currentState;
        this.currentState = state;
        this.logger.verbose(`Session state changed from "${oldState}" to "${this.currentState}"`);
        const notifiedState = this.sessionStateObservable.notifyObservers(
            this.currentState,
            this.advertsManager.timeIncludingAdsToContentTime(this.currentPlayerItem?.getCurrentPosition() as number)
        );

        if (notifiedState === OBSERVABLE_IGNORE_EVENT) {
            return undefined;
        }

        return notifiedState;
    }

    /*
     * TimelineManager event handlers
     */
    private handleTimelineItemStarted({ timelineItem, playerEngineItem }: TimelineEventData): void {
        this.registerPlayerObservers(playerEngineItem, timelineItem);
        this.currentPlayerItem = playerEngineItem;
        this.currentTimelineItem = timelineItem;
        this.seekingManager.setPlayerEngineItem(playerEngineItem);

        if (timelineItem.type === TimelineItemType.MainContent && playerEngineItem.playoutData.adsFailoverReason) {
            this.logger.warn('Destroying adverts manager due to failover');
            this.advertsManager?.destroy();
        }

        if (timelineItem.type === TimelineItemType.MainContent && !this.telemetrySession.isInitialised) {
            this.telemetrySession.initialise(() => playerEngineItem.getTelemetryData());
        }

        this.logger.verbose(`playerCapabilities: ${JsonUtils.stringify(CoreVideoInternal.playerCapabilities)}`);

        this.metaListManager?.setPrimaryCdn(playerEngineItem.playoutData.cdns[0]);

        if (
            !this.sessionItem.disableCoordinatedBitrateCapping &&
            CoreVideoInternal.playerCapabilities.canSetMidStreamPlayerBitrateLimits &&
            CoreVideoInternal.getPropositionExtensions().coordinatedBitrateCapping &&
            (playerEngineItem.playoutData.type === PlaybackType.SingleLiveEvent || TestingOverrides.coordinatedBitrateCap?.allowAnyAssetType === true)
        ) {
            const callbackOwner = this.bitrateCapManager;
            this.sessionStateObservable.registerObserver((newState) => {
                if (newState === InternalSessionState.Playing) {
                    this.sessionStateObservable.unregisterObservers(callbackOwner);
                    if (!this.config.metalist?.bitrateCapping?.useNewBitrateEndpoint) {
                        this.bitrateCapManager.setLegacyPrimaryCdn(playerEngineItem.playoutData.cdns[0]);
                        this.bitrateCapManager.legacyCapInstructionPollingCycleHandler(
                            this.sessionControllerPrecursor.playoutData!.cdns,
                            this.setPlayerBitrateLimitsWithReason.bind(this)
                        );
                    } else if (this.metaListManager) {
                        this.bitrateCapManager.start(
                            this.setPlayerBitrateLimitsWithReason.bind(this),
                            this.metaListManager,
                            this.sessionControllerPrecursor.playoutData!.cdns
                        );
                    }
                }
            }, callbackOwner);
        }
        if (
            this.config.metalist?.liveActions?.enableLiveActions &&
            playerEngineItem.playoutData.type === PlaybackType.SingleLiveEvent &&
            this.metaListManager
        ) {
            this.liveActionManager?.start(this.registerCue.bind(this), this.metaListManager, this.sessionControllerPrecursor.playoutData!.cdns);
        }

        if (
            timelineItem.type === TimelineItemType.MainContent &&
            Object.keys(this.playerBitrateLimits).length &&
            CoreVideoInternal.playerCapabilities.canSetPlayerBitrateLimits
        ) {
            const maxBitRateCapData = this.bitrateCapManager.getMaxBitRate(this.playerBitrateLimits.maxBitRate, BitrateCapReason.UserConfiguredCap);

            this.bitrateCapManager.notifyRequestedBitrateCap({
                value: playerEngineItem.playoutData.playerBitrateLimits?.maxBitRate ?? Infinity,
                reason: BitrateCapReason.UserConfiguredCap,
            });

            this.bitrateCapManager.notifyAppliedBitrateCap(maxBitRateCapData);
        }

        if (timelineItem.type === TimelineItemType.MainContent && timelineItem.isPreloaded) {
            // If the main item was pre-loaded we need to emit the events that were emitted during the loading stage
            if (playerEngineItem.availableAudioTracks) {
                this.notifyAvailableAudioTracksChanged(playerEngineItem.availableAudioTracks);
            }

            if (playerEngineItem.availableTextTracks) {
                this.notifyAvailableSubtitlesTracksChanged(playerEngineItem.availableTextTracks);
            }

            if (playerEngineItem.duration) {
                this.handleDurationChange(playerEngineItem.duration);
            }

            if (playerEngineItem.currentBitrate) {
                this.bitrateChangedObservable.notifyObservers(playerEngineItem.currentBitrate);
            }

            if (playerEngineItem.currentEncodedFrameRate) {
                this.encodedFrameRateChangedObservable.notifyObservers(playerEngineItem.currentEncodedFrameRate);
            }

            if (playerEngineItem.currentRenderedFrameRate) {
                this.renderedFrameRateChangedObservable.notifyObservers(playerEngineItem.currentRenderedFrameRate);
            }
        }
    }

    private handleTimelineItemEnded(): void {
        this.currentPlayerItem?.removeEventListeners(this);
        this.currentPlayerItem?.removeEventListeners(this.currentPlayerItem);
        this.currentPlayerItem = undefined;
        this.currentTimelineItem = undefined;
        this.bitrateCapManager.stopCapInstructionPolling();
        this.metaListManager?.destroy();
    }

    private handleTimelineItemPreloading({ timelineItem, playerEngineItem }: TimelineEventData): void {
        playerEngineItem.onManifestReceived(this.createManifestReceivedHandler(timelineItem.type), this);
    }

    private notifyQualityFailover(event: VideoFormat): void {
        this.qualityFailoverObservable.notifyObservers(event);
    }

    private notifyCdnSwitch(event: CdnSwitchEvent): void {
        this.cdnSwitchObservable.notifyObservers(event);
    }

    private notifyPlayoutDataReceived(playoutData: PlayoutData): void {
        this.playoutDataObservable.notifyObservers(playoutData);
    }

    private notifyPlayoutRulesReceived(playoutRules: PlayoutRules): void {
        this.playoutRulesObservable.notifyObservers(playoutRules);
    }

    /*
     *  Player item event handlers
     */

    private handlePlayerEvent<T>(observable: Observable<T>, event: T): void {
        observable.notifyObservers(event);
    }

    private errorTransformer(error?: CvsdkError | null): CvsdkError | null | undefined {
        const adaptedError = error;
        if (adaptedError?.severity === ErrorSeverity.Fatal) {
            if ([InternalSessionState.Initialized, InternalSessionState.Loading, InternalSessionState.PlayerLoading].includes(this.currentState)) {
                Object.assign(adaptedError, { code: `VSF:${adaptedError?.code}`, category: PlayerErrorCategory.VSF });
            } else {
                Object.assign(adaptedError, { category: PlayerErrorCategory.VPF });
            }
        }

        return adaptedError;
    }

    private handleErrorReceived(observable: Observable<CvsdkError>, error: CvsdkError): void {
        observable.notifyObservers(error);
    }

    private handleRebufferEnd(): void {
        if (this.currentState === InternalSessionState.Rebuffering) {
            perfLogger.measure(PerfKey.playerState, PerfTag.rebufferEnd);
            this.notifyRebufferEnd();
        }
    }

    private isMainContentCsai(playoutData: PlayoutData): boolean {
        return isMultiplayerCsai(playoutData) && this.currentTimelineItem?.type === TimelineItemType.MainContent;
    }

    private handlePlayerStateChange = (state: PlayerState): void => {
        const playoutData = this.sessionControllerPrecursor.playoutData;

        switch (state) {
            case PlayerState.Loading: {
                if (playoutData && isSleCsai(playoutData) && this.isFirstLoadingStateSet) {
                    this.setSessionState(InternalSessionState.CsaiTransition);
                } else {
                    this.setSessionState(InternalSessionState.PlayerLoading);
                    this.isFirstLoadingStateSet = true;
                }

                if (playoutData && this.isMainContentCsai(playoutData)) {
                    this.isListeningForVST = true;
                }

                break;
            }
            case PlayerState.Paused:
                this.handleRebufferEnd();

                if (this.isListeningForVST) {
                    perfLogger.measure(PerfKey.root, PerfTag.videoStartup);
                    this.telemetryUpdateObservable.notifyObservers({ key: TelemetryNotificationKey.videoStartUp, value: new Date().getTime() });
                    this.isListeningForVST = false;
                }
                this.setSessionState(InternalSessionState.Paused);
                break;
            case PlayerState.Playing:
                this.handleRebufferEnd();

                if (this.isListeningForVST) {
                    perfLogger.measure(PerfKey.root, PerfTag.videoStartup);
                    this.telemetryUpdateObservable.notifyObservers({ key: TelemetryNotificationKey.videoStartUp, value: new Date().getTime() });
                    this.isListeningForVST = false;

                    if (playoutData && this.isMainContentCsai(playoutData)) {
                        perfLogger.measure(PerfKey.root, PerfTag.videoStartupMainContent);
                    }
                }

                this.setSessionState(InternalSessionState.Playing);
                break;

            case PlayerState.Rebuffering: {
                if (this.currentState !== InternalSessionState.Rebuffering) {
                    perfLogger.measure(PerfKey.playerState, PerfTag.rebufferStart);
                    this.notifyRebufferStart();
                }
                this.setSessionState(InternalSessionState.Rebuffering);
                break;
            }
            case PlayerState.Seeking: {
                if (this.currentState !== InternalSessionState.Seeking) {
                    perfLogger.measure(PerfKey.playerState, PerfTag.seekStart);
                }
                this.handlePreSeekEnded();
                this.setSessionState(InternalSessionState.Seeking);
                break;
            }
            default:
        }
    };

    private isInCSAIPreRoll = (): boolean => {
        const currentAdBreak = this.advertsManager.getCurrentAdBreak();
        if (!currentAdBreak || !CoreVideoInternal.playerCapabilities.supportsNativeCsai) {
            return false;
        }
        return currentAdBreak.type === AdBreakType.Preroll && currentAdBreak.ssaiStitcherType === SsaiStitcherType.None;
    };

    private handlePositionChange = async (timeIncludingAds: number): Promise<void> => {
        this.advertsManager.handlePositionChange(timeIncludingAds);
        if (checkIsManifestVodType(this.sessionItem.type)) {
            const contentTime = this.advertsManager.timeIncludingAdsToContentTime(timeIncludingAds);
            const timeline: PlaybackTimelineInternal = { position: contentTime, currentTime: timeIncludingAds };
            /**
             * Android/exo player CVSDK receives only one timeline but with pre roll in it
             * Thus not reporting position of a pre-roll to keep similar behavior to other players who produce separate timeline events for ads and main content
             */
            timeline.muteForClients = this.isInCSAIPreRoll() ? true : false;
            this.playbackTimelineObservable.notifyObservers(timeline, this.currentPlayerItem?.getCurrentPosition());
        } else {
            const liveWindow = (await this.getLiveWindow()) ?? undefined;
            const absolutePosition = this.getAbsolutePosition(timeIncludingAds, liveWindow);
            const timeline: PlaybackTimelineInternal = { position: timeIncludingAds, currentTime: timeIncludingAds, absolutePosition };
            /**
             * Android/exo player CVSDK receives only one timeline but with pre roll in it
             * Thus not reporting position of a pre-roll to keep similar behavior to other players who produce separate timeline events for ads and main content
             */
            timeline.muteForClients = this.isInCSAIPreRoll() ? true : false;
            this.playbackTimelineObservable.notifyObservers(timeline, this.currentPlayerItem?.getCurrentPosition());
        }
    };

    private getAbsolutePosition(timeIncludingAds: number, liveWindow?: LiveWindow): Date | undefined {
        if (liveWindow?.startDate) {
            const msFromWindowStart = (timeIncludingAds - liveWindow.start) * 1000;
            return new Date(liveWindow.startDate.getTime() + msFromWindowStart);
        }

        return undefined;
    }

    private handleDurationChange = (engineItemDuration: number): void => {
        if (checkIsManifestVodType(this.sessionItem.type)) {
            const contentDuration = this.advertsManager.timeIncludingAdsToContentTime(engineItemDuration);
            this.playbackTimelineObservable.notifyObservers({ seekableRange: { start: 0, end: contentDuration } });
        }
    };

    private handlePreSeekStarted = (): void => {
        this.logger.verbose('Pre-Seek started');
        this.preSeekLock.lock();
    };

    private handlePreSeekEnded = (): void => {
        if (this.preSeekLock.locked) {
            this.preSeekLock.unlock();
        }
    };

    private handleSeekStarted = (timeIncludingAds: number): void => {
        this.seekingManager.handleSeekStarted(timeIncludingAds);
    };

    private handleSeekEnded = (): void => {
        this.seekingManager.handleSeekEnded();
    };

    private handleTimelineFinished(): void {
        this.setSessionState(InternalSessionState.Finished);
    }

    private normalizeAdBreakPosition(adBreak: AdBreak): AdBreak {
        const isVod = checkIsManifestVodType(this.sessionItem.type);

        if (isVod) {
            adBreak = this.advertsManager.adBreakPositionIncludingAdsToContentPosition(adBreak);
        }

        return adBreak;
    }

    private handleAdBreakStarted(adBreak: AdBreak): void {
        this.notifyAdBreakStarted(this.normalizeAdBreakPosition(adBreak));
    }

    private handleAdBreakFinished(adBreak: AdBreak): void {
        this.notifyAdBreakFinished(this.normalizeAdBreakPosition(adBreak));
    }

    private handleManifestParsed(): void {
        perfLogger.measure(PerfKey.root, PerfTag.manifestParsed);
    }

    private handleManifestUpdated = async (): Promise<void> => {
        this.metaListManager?.handleManifestUpdated();
    };

    private calculateIfAtLiveEdge(liveWindow: LiveWindow, position: number): boolean {
        const drift = liveWindow.end - position;
        return drift <= this.sessionItem.liveEdgeToleranceSeconds!;
    }

    private async destroy(): Promise<void> {
        if (this.currentState === InternalSessionState.Stopped) {
            return;
        }
        const adaptedState = this.setSessionState(InternalSessionState.Stopped);
        if (adaptedState === InternalSessionState.Stopped) {
            this.notifySessionEnded();
        }

        this.bitrateCapManager.destroy();
        this.metaListManager?.destroy();
        this.telemetrySession.destroy();
        this.preSeekLock.destroy();
        this.currentPlayerItem?.removeEventListeners(this);
        this.currentPlayerItem?.removeEventListeners(this.currentPlayerItem);
        this.timelineManager.removeEventListeners(this);
        this.addonManager.destroy();
        this.eventManager?.destroy?.();
        this.logger.breadcrumb('Session ended');
        this.unregisterSessionObservers();
        this.videoElementCreatedObservable.reset();
        this.seekingManager.destroy();
        CoreVideoInternal.lifecycle?.unregisterResuming?.(this.notifyAppForegrounded);
        CoreVideoInternal.lifecycle?.unregisterSuspending?.(this.notifyAppBackgrounded);
        await CoreVideoInternal.display?.resetDisplay?.();
    }

    private registerPlayerObservers = (item: PlayerEngineItem, timelineItem: TimelineItem) => {
        if (timelineItem.type !== TimelineItemType.Advert) {
            item.onManifestUpdated(this.handleManifestUpdated, this);
            item.onDurationChanged(this.handleDurationChange, this);
            item.onLiveWindowUpdate(this.notifyLiveWindowUpdate.bind(this), this);
            item.onPositionChanged(this.handlePositionChange, this);
            item.onPreSeekStarted(this.handlePreSeekStarted, this);
            item.onSeekStarted(this.handleSeekStarted, this);
            item.onSeekEnded(this.handleSeekEnded, this);
            item.onManifestParsed(this.handleManifestParsed, this);
            item.onAvailableAudioTracksChanged(this.notifyAvailableAudioTracksChanged, this);
            item.onAvailableSubtitlesTracksChanged(this.notifyAvailableSubtitlesTracksChanged, this);
            item.onAvailableThumbnailVariantsChanged(this.notifyAvailableThumbnailVariantsChanged, this);
            item.onStreamMetadataReceived(
                (this.handlePlayerEvent as HandlePlayerEvent<StreamMetadata>).bind(this, this.streamMetadataReceivedObservable),
                this
            );
            item.onTimedMetadataReceived(
                (this.handlePlayerEvent as HandlePlayerEvent<TimedMetadata>).bind(this, this.timedMetadataReceivedObservable),
                this
            );
            if (CoreVideoInternal.playerCapabilities.emitsBulkTimedMetadata) {
                item.onBulkTimedMetadataReceived(
                    (this.handlePlayerEvent as HandlePlayerEvent<Array<TimedMetadata>>).bind(this, this.bulkTimedMetadataReceivedObservable),
                    this
                );
            }
            item.onAudioTrackChanged(
                (this.handlePlayerEvent as HandlePlayerEvent<string | number>).bind(this, this.audioTrackChangedObservable),
                this
            );
            item.onSubtitlesTrackChanged((this.handlePlayerEvent as HandlePlayerEvent<Track>).bind(this, this.subtitlesTrackChangedObservable), this);
            item.onSubtitleCuesChanged(
                (this.handlePlayerEvent as HandlePlayerEvent<Array<SubtitleCue>>).bind(this, this.subtitleCuesChangedObservable),
                this
            );
            item.onEncodedFrameRateChanged(
                (this.handlePlayerEvent as HandlePlayerEvent<number>).bind(this, this.encodedFrameRateChangedObservable),
                this
            );
            item.onRenderedFrameRateChanged(
                (this.handlePlayerEvent as HandlePlayerEvent<number>).bind(this, this.renderedFrameRateChangedObservable),
                this
            );
            item.onHttpRequestChanged((this.handlePlayerEvent as HandlePlayerEvent<PlayerHttpRequest>).bind(this, this.httpRequestObservable), this);

            item.onHttpResponseChanged(
                (this.handlePlayerEvent as HandlePlayerEvent<PlayerHttpResponse>).bind(this, this.httpResponseObservable),
                this
            );

            item.onVideoElementCreated(this.handleVideoElementCreated, this);
            item.onFullscreenChanged((this.handlePlayerEvent as HandlePlayerEvent<boolean>).bind(this, this.fullscreenChangedObservable), this);
            item.onClicked((this.handlePlayerEvent as HandleEmptyPlayerEvent).bind(this, this.clickedObservable), this);
        }
        item.onWarning(this.handleErrorReceived.bind(this, this.warningObservable), this);
        item.onPlayStart(this.notifyPlayStart.bind(this), this);
        item.onStateChanged(this.handlePlayerStateChange, this, ObserverPriority.HIGH);
        item.onBitrateChanged((this.handlePlayerEvent as HandlePlayerEvent<BitRateLevel>).bind(this, this.bitrateChangedObservable), this);
        item.onVideoTrackChanged((this.handlePlayerEvent as HandlePlayerEvent<VideoTrack>).bind(this, this.videoTrackChangedObservable), this);
        item.onVolumeChanged((this.handlePlayerEvent as HandlePlayerEvent<number>).bind(this, this.volumeChangedObservable), this);
        item.onMuteChanged((this.handlePlayerEvent as HandlePlayerEvent<boolean>).bind(this, this.isMutedObservable), this);
        item.onCdnSwitch((this.handlePlayerEvent as HandlePlayerEvent<CdnSwitchEvent>).bind(this, this.cdnSwitchObservable), this);
        item.onAutoPlayPolicyPreventedPlayback(this.notifyAutoPlayPolicyPreventedPlayback.bind(this), this);

        item.onAdBreakDataReceived(this.notifyAdBreakDataReceived.bind(this), this);
        item.onAdBreakFinished(this.handleAdBreakFinished.bind(this), this);
        item.onAdBreakStarted(this.handleAdBreakStarted.bind(this), this);
        item.onAdFinished(this.notifyAdFinished.bind(this), this);
        item.onAdStarted(this.notifyAdStarted.bind(this), this);
        item.onAdPositionUpdate(this.notifyAdPositionChanged.bind(this), this);

        if (!timelineItem.isPreloaded) {
            item.onManifestReceived(this.createManifestReceivedHandler(timelineItem.type), this);
        }
    };

    private handleVideoElementCreated = (element: HTMLVideoElement): void => {
        const display = CoreVideoInternal.display;
        if (this.shouldDisablePip()) {
            display?.disablePictureInPicture?.(element);
        } else {
            display?.enablePictureInPicture?.(element);
        }
        this.videoElementCreatedObservable.notifyObservers(element);
    };

    private createManifestReceivedHandler = (timelineItemType: TimelineItemType) => (playerManifestReceivedEvent: PlayerManifestReceivedEvent) => {
        this.manifestReceivedObservable.notifyObservers({ playerManifestReceivedEvent, timelineItemType });
    };

    private unregisterSessionObservers(): void {
        this.sessionStateObservable.unregisterObservers(this);
        this.telemetryObservable.unregisterObservers(this);
        this.telemetryUpdateObservable.unregisterObservers(this);
        this.httpRequestObservable.unregisterObservers(this);
        this.httpResponseObservable.unregisterObservers(this);
        this.playStartObservable.unregisterObservers(this);
        this.playbackTimelineObservable.unregisterObservers(this);
        this.errorObservable.unregisterObservers(this);
        this.warningObservable.unregisterObservers(this);
        this.streamMetadataReceivedObservable.unregisterObservers(this);
        this.bitrateChangedObservable.unregisterObservers(this);
        this.bitrateCapChangedObservable.unregisterObservers(this);
        this.bitrateCapRequestedObservable.unregisterObservers(this);
        this.encodedFrameRateChangedObservable.unregisterObservers(this);
        this.renderedFrameRateChangedObservable.unregisterObservers(this);
        this.videoTrackChangedObservable.unregisterObservers(this);
        this.availableAudioTracksChangedObservable.unregisterObservers(this);
        this.availableSubtitlesTracksChangedObservable.unregisterObservers(this);
        this.availableThumbnailVariantsChangedObservable.unregisterObservers(this);
        this.audioTrackChangedObservable.unregisterObservers(this);
        this.playoutDataObservable.unregisterObservers(this);
        this.playoutRulesObservable.unregisterObservers(this);
        this.subtitlesTrackChangedObservable.unregisterObservers(this);
        this.subtitleCuesChangedObservable.unregisterObservers(this);
        this.volumeChangedObservable.unregisterObservers(this);
        this.eventBoundaryObservable.unregisterObservers(this);
        this.manifestReceivedObservable.unregisterObservers(this);
        this.pinRequiredObservable.unregisterObservers(this);
        this.autoPlayPolicyPreventedPlaybackObservable.unregisterObservers(this);
        this.appForegroundedObservable.unregisterObservers(this);
        this.appBackgroundedObservable.unregisterObservers(this);
        this.userWaitStartedObservable.unregisterObservers(this);
        this.userWaitEndedObservable.unregisterObservers(this);
        this.pinSuccessObservable.unregisterObservers(this);
        this.endOfEventMarkerReceivedObservable.unregisterObservers(this);
        this.assetMetadataUpdateObservable.unregisterObservers(this);
        this.pauseAdObservable.unregisterObservers(this);
        this.companionAdInsertionEnabledObservable.unregisterObservers(this);
        this.companionAdOpportunityStartedObservable.unregisterObservers(this);
        this.companionAdOpportunityEndedObservable.unregisterObservers(this);
        this.qualityFailoverObservable.unregisterObservers(this);
        this.cdnSwitchObservable.unregisterObservers(this);
        this.adBreakDataReceivedObservable.unregisterObservers(this);
        this.adTrackingReceivedObservable.unregisterObservers(this);
        this.catchupSeekObservable.unregisterObservers(this);
        this.adBreakFinishedObservable.unregisterObservers(this);
        this.adBreakStartedObservable.unregisterObservers(this);
        this.adFinishedObservable.unregisterObservers(this);
        this.adStartedObservable.unregisterObservers(this);
        this.isMutedObservable.unregisterObservers(this);
        this.audioTrackWillChangeObservable.unregisterObservers(this);
        this.timedMetadataReceivedObservable.unregisterObservers(this);
        this.bulkTimedMetadataReceivedObservable.unregisterObservers(this);
        this.adPositionObservable.unregisterObservers(this);
        this.sessionEndedObservable.unregisterObservers(this);
        this.videoElementCreatedObservable.unregisterObservers(this);
        this.fullscreenChangedObservable.unregisterObservers(this);
        this.clickedObservable.unregisterObservers(this);
        this.rebufferStartObservable.unregisterObservers(this);
        this.rebufferEndObservable.unregisterObservers(this);
    }
}
