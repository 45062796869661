import type { Ad, AdBreak } from '../addons/adverts/common';
import type { Position } from '../core/session-controller/timeline/timeline';
import { addNumbersAndResolvePrecision } from './number-precision';
import clone from '@sky-uk-ott/core-video-sdk-js-external-rfdc';

export const PROXIMITY_TO_AD_BREAK_START_THRESHOLD = 2;

export const findCloseToAdBreakStartPosition = (
    position: number,
    adBreaksStartPositions: Array<Position> = [],
    threshold?: number
): number | null => {
    const adBreakNearCurrentPosition: Position | undefined = adBreaksStartPositions.find((adBreakStartPosition) => {
        const absoluteDistanceFromAdBreakStart = Math.abs((adBreakStartPosition as number) - position);
        return absoluteDistanceFromAdBreakStart <= (threshold || PROXIMITY_TO_AD_BREAK_START_THRESHOLD);
    });

    if (!adBreakNearCurrentPosition) {
        return null;
    }

    return adBreakNearCurrentPosition as number;
};

export const cloneAdBreakArray = (adBreaks: Array<AdBreak>): Array<AdBreak> => {
    return adBreaks.map((adBreak) => cloneAdBreak(adBreak));
};

export const cloneAdBreak = (adBreak: AdBreak): AdBreak => {
    const clonedBreak = clone({ circles: true })(adBreak);
    updateAdsForClonedAdBreak(clonedBreak);

    return clonedBreak;
};

export const cloneAd = (ad: Ad): Ad => {
    const clonedAd = clone({ circles: true })(ad);

    updateAdsForClonedAdBreak(ad.adBreak);

    return clonedAd;
};

export const updateAdsForClonedAdBreak = (clonedAdBreak: AdBreak<Ad>): AdBreak<Ad> => {
    clonedAdBreak.ads.forEach((ad) => (ad.adBreak = clonedAdBreak));
    return clonedAdBreak;
};

export const getScheduledEventKeyForAd = (adBreak: AdBreak, ad: Ad): string => {
    return `${adBreak.id}_${ad.id}`;
};

/**
 * Check if two ad breaks refer to the same break.
 */
export const areAdBreaksSame = (adBreak1: AdBreak, adBreak2: AdBreak): boolean => {
    return adBreak1.id === adBreak2.id;
};

/**
 * Helper method to recalculate ad break expected duration based on ad durations.
 * @param adBreak The ad break containing ad durations to aggregate.
 * @returns Calculated ad break duration with precision resolved.
 */
export const recalculateAdBreakDuration = (adBreak: AdBreak): number => {
    return adBreak.ads.reduce((dur, ad) => addNumbersAndResolvePrecision(dur, ad.expectedDuration), 0);
};

/**
 * Helper method to reduce the Position attribute of an AdBreak object to a clean number.
 * @param position position attribute of AdBreak object
 * @returns position sanitised to an umber
 */
export const sanitisePosition = (position?: Position): number => {
    return typeof position !== 'string' ? position! : 0;
};

export const roughlyEquals = (a: number, b: number, tolerance: number) => {
    return Math.abs(a - b) < tolerance;
};

export const isEmptyAdBreak = (adBreak: AdBreak) => {
    return adBreak.ads.length === 0;
};
