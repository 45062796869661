/**
 * @public
 */
export enum PerfTag {
    pinRequired = 'PIN_REQUIRED',
    pinSuccess = 'PIN_SUCCESS',
    sessionStart = 'SESSION_START',
    timelineTransitionStart = 'TIMELINE_TRANSITION_START',
    sessionPrecursorInitialised = 'SESSION_PRECURSOR_INITIALISED',
    playoutRequest = 'Playout Request',
    playoutResponse = 'Playout Response',
    playoutModified = 'Playout Modified',
    addonsLoaded = 'ADDONS_LOADED',
    ovpRequest = 'OVP_REQUEST',
    ovpResponse = 'OVP_RESPONSE',
    preparingAdvertising = 'PREPARING_ADVERTISING',
    advertisingReady = 'ADVERTISING_READY',
    startingTimeline = 'STARTING_TIMELINE',
    videoStartup = 'VIDEO_STARTUP',
    videoStartupMainContent = 'VIDEO_STARTUP_MAIN_CONTENT',
    rebufferStart = 'REBUFFER_START',
    rebufferEnd = 'REBUFFER_END',
    seekStart = 'SEEK_START',
    seekEnd = 'SEEK_END',
    addonLoadStart = 'ADDON_LOAD_START',
    addonLoadEnd = 'ADDON_LOAD_END',
    addonInstantiationStart = 'ADDON_INSTANTIATION_START',
    addonInstantiationEnd = 'ADDON_INSTANTIATION_END',
    vacRequest = 'VAC_REQUEST',
    vacResponse = 'VAC_RESPONSE',
    nativeLoadStart = 'NATIVE_LOAD_START',
    nativeLoadEnd = 'NATIVE_LOAD_END',
    fps = 'FPS',
    cpuUsage = 'CPU_USAGE',
    memory = 'MEMORY',
    memoryLimit = 'MEMORY_LIMIT',
    tizenAvailableMemory = 'TIZEN_AVAILABLE_MEMORY',
    tizenCpuLoad = 'TIZEN_CPU_LOAD',
    ovpRequestAdaptStart = 'OVP_REQ_ADAPT_START',
    ovpRequestAdaptEnd = 'OVP_REQ_ADAPT_END',
    ovpPlayoutReqStart = 'OVP_PLAYOUT_REQ_START',
    ovpPlayoutReqEnd = 'OVP_PLAYOUT_REQ_END',
    ovpResponseAdaptStart = 'OVP_RESP_ADAPT_START',
    ovpResponseAdaptEnd = 'OVP_RESP_ADAPT_END',
    mediaTailorResponse = 'MT_RESPONSE',
    manifestParsed = 'MANIFEST_PARSED',
}

/**
 * @public
 */
export enum PerfKey {
    agfNielsenBrowserSdk = 'AGF_NIELSEN_BROWSER_SDK',
    core = 'CORE',
    render = 'RDR',
    root = 'ROOT',
    playerState = 'PLAYER_STATE',
    multiPlayerCsai = 'MULI_PLAYER_CSAI',
    mediaTailor = 'MEDIA_TAILOR',
    vac = 'VAC',
    eventBoundary = 'EVENT_BOUNDARY',
    waterMarking = 'WATERMARKING',
    conviva = 'CONVIVA',
    comscore = 'COMSCORE',
    adobeAnalytics = 'ADOBE_ANALYTICS',
    nielsen = 'NIELSEN',
    nielsenSdk = 'NIELSEN_SDK',
    ocellus = 'OCELLUS',
    mParticle = 'M_PARTICLE',
    openMeasurement = 'OPEN_MEASUREMENT',
    heartbeat = 'HEARTBEAT',
    bookmarkUpdateEnhancer = 'BOOKMARK_UPDATE_ENHANCER',
    ituQualityAssessment = 'ITU_QUALITY_ASSESSMENT',
    yospace = 'YOSPACE',
    playmakerSsai = 'PLAYMAKER_SSAI',
    legacySubtitles = 'LEGACY_SUBTITLES',
    legacyPin = 'LEGACY_PIN',
    legacyCdnSelector = 'LEGACY_CDN_SELECTOR',
    nativeLoad = 'NATIVE_LOAD',
    streamProxy = 'STREAM_PROXY',
    streamMetadataParser = 'STREAM_METADATA_PARSER',
    syntheticEvents = 'SYNTHETIC_EVENTS',
    streamMetadataFilterer = 'STREAM_METADATA_FILTERER',
    tizen = 'TIZEN',
    pine = 'PINE',
    linearContentEmbargo = 'LINEAR_CONTENT_EMBARGO',
}
