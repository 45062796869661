import type { CsaiAdBreaks } from '../../../players/bolt-ons/ad-breaks-provider/base-csai-ad-breaks-provider';
import { BaseCsaiAdBreaksProvider } from '../../../players/bolt-ons/ad-breaks-provider/base-csai-ad-breaks-provider';
import { JsonAdBreaksAdapter } from '../../../players/bolt-ons/ad-breaks-provider/ad-break-adapters/json/json-ad-breaks-adapter';
import type { AddonAdvertisingData } from '../../addon-playout-data';
export class JsonAdBreaksProvider extends BaseCsaiAdBreaksProvider {
    constructor() {
        super();
    }

    public async getAdBreaks(advertisingData: AddonAdvertisingData): Promise<CsaiAdBreaks> {
        if (!advertisingData.vac?.sleCsai) {
            return { adBreaks: [], nonLinearAds: [] };
        }

        const adapter = new JsonAdBreaksAdapter();

        const adServerCdn = advertisingData.vac.keyValues?.am_cdn?.toUpperCase();
        return adapter.adapt(advertisingData.vac.sleCsai, true, adServerCdn);
    }
}
