const numbers = '\\d+(?:[\\.,]\\d+)?';
const timePattern = new RegExp(`T(${numbers}H)?(${numbers}M)?(${numbers}S)?`);

// Converts values from ISO 8601 durations, such as "PT11M26.143791666S", to seconds (in this case 686.143791666)
// Currently only works with minutes, hours and seconds, as higher than that relies on context a lot more
// (eg: length of a month), but we primarily seem to see this using only those values anyway
// (eg: "PT928H43M15.355S", rather than dividing the 928 hours into days)
export const ptTimeToSeconds = (ptTime: string): number | null => {
    const timeValues = ptTime.match(timePattern)?.slice(1) || [];
    return timeValues.reverse().reduce((acc: number | null, numStr: string, idx) => {
        return numStr ? parseFloat(numStr) * Math.pow(60, idx) + (acc ?? 0) : acc;
    }, null);
};
