/**
 * @public
 */
export enum AdInsertionType {
    MultiPlayerCsai = 'MultiPlayerCsai',
    Yospace = 'Yospace',
    MediaTailor = 'MediaTailor',
    NativeCsai = 'NativeCsai',
    None = 'None',
}
