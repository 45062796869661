import type {
    AndroidAdaptiveBitrateStrategy,
    UwpConfig,
    XfinityDeviceConfig as DeviceXfinityDeviceConfig,
    YouviewDeviceConfig,
} from '@sky-uk-ott/client-lib-js-device';
import type { BandwidthEstimator } from '@sky-uk-ott/vpt-abr-network-estimator';
import type { BandwidthEstimator as LegacyBandwidthEstimator } from '@sky-uk-ott/core-video-sdk-js-bandwidth-estimator';
import type { MediaTailorClientConfig } from '../addons/adverts/server-adverts/media-tailor/media-tailor-types';
import type { YospaceClientConfig } from '../addons/adverts/server-adverts/yospace/yospace-config';
import type { VodAdvertsConfig } from '../addons/adverts/vod-adverts/base';
import type { BookmarkUpdateEnhancerConfig } from '../addons/bookmark-update-enhancer/bookmark-update-enhancer-addon';
import type { EventBoundaryConfig } from '../addons/event-boundary/event-boundary-addon';
import type { HeartbeatConfig } from '../addons/heartbeat/heartbeat-addon';
import type { AdobeMediaAnalyticsClientConfig } from '../addons/reporting/adobe-media-analytics-addon/adobe-media-analytics-addon';
import type { ClientMParticleConfig } from '../addons/reporting/mparticle-addon/mparticle-addon';
import type { ClientNielsenConfig } from '../addons/reporting/nielsen-addon/nielsen-addon';
import type { ClientOcellusConfig } from '../addons/reporting/ocellus-addon/ocellus-addon';
import type { ClientAgfNielsenConfig } from '../addons/reporting/agf-nielsen/agf-nielsen-browser-sdk/agf-nielsen-browser-sdk-addon';
import type { OpenMeasurementClientConfig } from '../addons/reporting/open-measurement-addon/open-measurement-addon';
import type { ClientComscoreAddonConfig, ConvivaConfig } from '../addons/reporting/reporting-addon';
import type { Vac } from '../addons/vac/vac-addon';
import type { WatermarkingConfig } from '../addons/watermarking/watermarking-addon';
import type { PlayerType } from '../core/player/player-type';
import type { PlaybackType } from '../core/player/playout-data';
import type { BufferingLimitConfig } from '../core/session-controller/timeline/buffering-limit';
import type { ErrorSeverity } from '../error';
import type { ShakaInternalRetryConfig } from '../players/shaka';
import type { InterpolationStrategies } from '../utils/interpolated-position';
import type { CompatibleStreamPropertyGroup } from '../core/player/stream-capability-specification';
import type { AllowedDvrWindowDurations, CoreVideoConfigMetadata } from './core-video-config/core-video-config-types';
import type { SemanticVersion } from '../utils/device-firmware';
import type { AdBreakType } from '../addons/adverts/common';
import type { SeekerConfiguration } from '../players/shaka/configuration/seeker-config';
import { ShakaPlayerVersion } from './internal-config.enums';
import type { OverrideableDevice, Proposition, ShakaEcmaScriptVersion, ShakaTimeoutPolicy } from './internal-config.enums';
import type { VideoResolution } from '../core/player/video-format';
import type { AlternativeAbr } from '@sky-uk-ott/vpt-abr';
export { OverrideableDevice, Proposition, ShakaEcmaScriptVersion, ShakaPlayerVersion, ShakaTimeoutPolicy } from './internal-config.enums';
import type { OVPTokenServiceInstance } from '@sky-uk-ott/client-lib-js-ott-ovp-token';
import type { PersonaSegment, SignedRequest } from '@sky-uk-ott/client-lib-js-ott-ovp-service';

/**
 * @internal
 */
export interface AddonConfig<T> {
    enabled: boolean;
    config: T;
}

export type VpiConfig = InternalVpiConfig | CustomVpiConfig;
export interface InternalVpiConfig {
    useCustomVpip: false;
    tokenService: OVPTokenServiceInstance;
    signedRequest: SignedRequest;
    ovpConfig: OVPConfig;
}

export interface OVPProfile {
    id: string;
    personaSegments: PersonaSegment[];
    controls: {
        maturityRating: string;
    };
    isFallback: boolean;
}

export interface OVPConfig {
    activeTerritory: () => string;
    authIssuer: string;
    broadcastRegions: () => string;
    device: string;
    getAccountHolder: () => OVPProfile;
    getActiveProfile: () => OVPProfile;
    language: () => string;
    ovpHostname: string;
    pinOverride: () => boolean;
    platform: string;
    proposition: string;
    provider: string;
    territory: string;
    timeouts?: number[];
}

export interface CustomVpiConfig {
    useCustomVpip: true;
}

export interface StreamProxyConfig {
    proxyHost: string;
    maxAudioTracks?: string;
    maxSubtitleTracks?: string;
}

export interface MseWrapConfig {
    host: string;
    enablePlayerView?: boolean;
    sessionDurationMs?: number;
}
export interface PineConfig {
    clientId: string;
    trunkHost?: string;
    enableMocks?: boolean;
    enableMockAdverts?: boolean;
    mockOnly?: Array<string>;
}

export interface CommonAddonsConfig {
    appName: string;
    appIdentifier: string;
    appVersion: string;
}

export interface AdInsertionConfig {
    mediaTailorAdInsertionOverrideVod?: boolean;
    mediaTailorAdInsertionOverrideFer?: boolean;
    mediaTailorAdInsertionOverrideLinear?: boolean;
    enabled: boolean;
    vac?: Vac.Config;
    multiPlayerCsai?: VodAdvertsConfig;
    yospace?: YospaceClientConfig;
    mediaTailor?: MediaTailorClientConfig;
}

export type DisableAnalyticsForAutoPlayTrailersConfig = {
    [k in keyof Pick<BaseReportingConfig, 'adobe'>]: boolean;
};

interface BaseReportingConfig {
    agfNielsen?: AddonConfig<ClientAgfNielsenConfig>;
    comscore?: AddonConfig<ClientComscoreAddonConfig>;
    nielsen?: AddonConfig<ClientNielsenConfig>;
    adobe?: AddonConfig<AdobeMediaAnalyticsClientConfig>;
    conviva?: AddonConfig<ConvivaConfig>;
    ocellus?: AddonConfig<ClientOcellusConfig>;
    mParticle?: AddonConfig<ClientMParticleConfig>;
    openMeasurement?: AddonConfig<OpenMeasurementClientConfig>;
}

/**
 * @internal
 */
export interface AddonsConfig {
    common?: CommonAddonsConfig;
    eventBoundary?: AddonConfig<EventBoundaryConfig>;
    pine?: AddonConfig<PineConfig>;
    streamMetadataFilterer?: AddonConfig<{}>;
    heartbeat?: AddonConfig<HeartbeatConfig>;
    bookmarkUpdateEnhancer?: AddonConfig<BookmarkUpdateEnhancerConfig>;
    watermarking?: AddonConfig<WatermarkingConfig>;
    reporting?: {
        disableAnalyticsForAutoPlayTrailers?: DisableAnalyticsForAutoPlayTrailersConfig;
    } & BaseReportingConfig;
    adInsertion?: AdInsertionConfig;
    streamProxy?: AddonConfig<StreamProxyConfig>;
    mseWrap?: AddonConfig<MseWrapConfig>;
    ituQualityAssessment?: AddonConfig<{}>;
    linearContentEmbargo?: AddonConfig<object>;
}

// TODO this isn't device config. This is being passed down to the **player** in order to pass `reportingAppPrefix`.
// This should be reworked and `reportingAppPrefix` should be moved to `PlayersConfig`.
/**
 * @internal
 */
export interface YouViewPlayerConfig {
    reportingAppPrefix: string;
}

export type YouViewConfig = YouviewDeviceConfig & YouViewPlayerConfig;

export type CoreSdkCastReceiverOptions = Pick<
    cast.framework.CastReceiverOptions,
    'localSenderId' | 'maxInactivity' | 'statusText' | 'versionCode' | 'customNamespaces'
>;

// TODO this isn't device config. This is being passed down to the **player** in order to pass `castReceiverOptions`.
// This should be reworked and `castReceiverOptions` should be moved to `PlayersConfig`.
// For some reason, after upgrading the chromecast-caf-receiver types,
// adding mediaElement in the list above causes a RangeError: Maximum call stack size exceeded.
// When upgrading typescript-json-schema to a version which supports recursive types, the internal-config.schema.json
// goes from 1500 lines to something like 15k lines because of the HTMLMediaElement type.
export interface ChromecastConfig {
    castReceiverOptions: CoreSdkCastReceiverOptions & {
        /**
         * Optional media element to play content with. Default behavior is to use the first found media element in the page.
         */
        mediaElement?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    };
}

// TODO Currently we are passing **device** config down to the **player** in order to pass `uhdSleMinimumAllowedFirmware`.
// This should be reworked and `uhdSleMinimumAllowedFirmware` should be moved to `AampPlayerConfig`.
export interface ExtraXfinityDeviceConfig {
    uhdSleMinimumAllowedFirmware?: SemanticVersion;
}

export type XfinityDeviceConfig = DeviceXfinityDeviceConfig & ExtraXfinityDeviceConfig;

/**
 * TODO delete this type and import `DevicesConfig` from client-lib-js-device
 * @internal
 */
export interface DevicesConfig {
    uwp?: UwpConfig;
    youview?: YouViewConfig;
    chromecast?: ChromecastConfig;
    xfinity?: XfinityDeviceConfig;
}

export interface AndroidTvPlayerConfig {
    eac3?: boolean;
    adaptiveBitrateStrategy?: AndroidAdaptiveBitrateStrategy;
    maxBitrate?: number;
    exclude4kTrack?: boolean;
    maxResolution?: VideoResolution;
}

export interface AampPlayerConfig {
    manifestTimeoutInSeconds?: number;
    preCachePlaylistTimeInMinutes?: number;
    shouldUseSurround?: boolean;
    shouldUseAverageBandwidth?: boolean;
    progressReportingInterval?: number;
    resumeInAdBreakOffsetInSeconds?: number;
    downloadStallTimeoutInSeconds?: number;
    downloadStartTimeoutInSeconds?: number;
    minimumSafeBitrate?: number;
    shouldUseNewAbr?: boolean;
    enableInternalRetryFunctionality?: boolean;
    useRetuneForUnpairedDiscontinuity?: boolean;
    playlistTimeout?: number;
    vodNetworkTimeout?: number;
    linearNetworkTimeout?: number;
    missingProgressUpdateLimit?: number;
    initFragmentRetryCount?: number;
    enableSeekableRange?: boolean;
    enableLiveLatencyCorrection?: boolean;
    enableAsyncTune?: boolean;
    enablePTSReStamp?: boolean;
}

export interface TapePlayerConfig {
    useDebug?: boolean;
    parsePrftFromManifest?: boolean;
    livePresentationDelay?: number;
}

export const SHAKA_PLAYER_VERSION_DEFAULT = ShakaPlayerVersion.V_3_3_10;

export type ShakaLiveSyncPolicy = {
    liveSync?: boolean;
    targetLatency?: number;
    targetLatencyTolerance?: number;
    maxLatency?: number;
    playbackRate?: number;
    minLatency?: number;
    minPlaybackRate?: number;
    panicMode?: boolean;
    panicThreshold?: number;
    dynamicTargetLatency?: boolean;
    dynamicTargetLatencyStabilityThreshold?: number;
    dynamicTargetLatencyRebufferIncrement?: number;
};

export type ShakaVodDynamicPlaybackRate = {
    lowBufferRate?: number;
    bufferRatio?: number;
};

export type PostAdBreakCorrectiveSeekConfig = {
    enable?: boolean;
    adType?: AdBreakType;
    delayMs?: number;
};

export interface ShakaPlayerConfig {
    shakaPlayerVersion: ShakaPlayerVersion;
    internalRetryConfig?: ShakaInternalRetryConfig;
    useMuxJS?: boolean;
    useDebug?: boolean;
    alternativeAbr?: AlternativeAbr;
    nativeConfig?: shaka.extern.PlayerConfiguration;
    esVersion?: ShakaEcmaScriptVersion;
    shakaTimeoutPolicy?: ShakaTimeoutPolicy;
    startAtSegmentBoundary?: boolean;
    useMseOnSafari?: boolean;
    bufferPoller?: boolean;
    smoothCodecSwitch?: boolean;
    liveSync?: ShakaLiveSyncPolicy;
    vodDynamicPlaybackRate?: ShakaVodDynamicPlaybackRate;
    /**
     * Some devices struggle with the transition from clear content to protected content
     * This configuration option performs a delayed seek to the current position
     * after this transition. Sometimes this kind of seek can help 'kick start'
     * the device back into a good state.
     */
    postAdBreakCorrectiveSeek?: PostAdBreakCorrectiveSeekConfig;
    seeker?: SeekerConfiguration;
    deferMultiviewSetAudioTrackWhileLoading?: boolean;
}

export interface WebMafPlayerConfig {
    nativeAutoplay?: boolean;
}

export interface WebosPlayerConfig {
    appId: string;
}

export interface TheoPlayerConfig {}

export interface TizenPlayerConfig {}

/**
 * @public
 */
export interface PlayersConfig {
    aamp?: AampPlayerConfig;
    androidTv?: AndroidTvPlayerConfig;
    shaka?: ShakaPlayerConfig;
    tizen?: TizenPlayerConfig;
    webMaf?: WebMafPlayerConfig;
    webOS?: WebosPlayerConfig;
    youview?: YouViewConfig;
    tape?: TapePlayerConfig;
    theo?: TheoPlayerConfig;
}

export type PlayerConfig = PlayersConfig[keyof PlayersConfig];

export interface PrefetchingConfig {
    cacheCapacity: number;
    cacheTimeout: number;
}

export interface PerformanceConfig {
    bandwidthEstimator?: BandwidthEstimator | LegacyBandwidthEstimator;
}

export interface ReducedLatencyOptions {
    useReducedLatencyPlayerConfig?: boolean;
}

export interface DrmConfig {
    widevine?: {
        certificateUrl: string;
        certificate?: ArrayBuffer;
    };
    fairplay?: {
        certificateUrl: string;
        certificate?: ArrayBuffer;
        disablePrefetch?: boolean;
    };
}

export type DebugConfig = {
    bufferGapDetectionSeverity?: ErrorSeverity;
    fullySerializedVerboseObservables?: boolean; // can have performance impact if enabled
    interpolationStrategy?: InterpolationStrategies;
    minimumSamsungSdkVersion?: boolean;
};

/**
 * @public
 */
export type TelemetryConfig = {
    periodicReportingSeconds?: number;
};

/**
 * @public
 */
export type CsaiConfig = {
    // The maximum transition time between assets
    // Currently only used for SLE Preroll but may be used for general CSAI in the future
    maxTransitionTimeSecs?: number;
};

/**
 * @public
 */
export type CmcdConfig = {
    enabled: boolean;
};

export type DeviceInitialisationConfig = {
    proposition: Proposition;
    devices?: DevicesConfig;
    deviceOverride?: OverrideableDevice;
    playerOverride?: PlayerType;
};

export type Metalist = {
    minTimeoutMs?: number;
    liveActions?: {
        enableLiveActions?: boolean;
    };
    bitrateCapping?: {
        useNewBitrateEndpoint?: boolean;
    };
};

/**
 * @internal
 * Properties in the internal config that cannot change between sessions
 */
export type InternalConfigStatic = DeviceInitialisationConfig & {
    debug?: DebugConfig;
    drm?: DrmConfig;
    vpi?: VpiConfig;
    bufferingLimit?: BufferingLimitConfig;
};

/**
 * @internal
 * Properties in the internal config that can change between sessions
 */
export interface InternalConfigDynamic {
    configMetadata: CoreVideoConfigMetadata;
    addons: AddonsConfig; // Currently not in Remote Config likely to change in the future
    players?: PlayersConfig; // Currently not in Remote Config likely to change in the future
    prefetching?: PrefetchingConfig; // Currently not in Remote Config likely to change in the future
    preferredStreamPropertyGroups?: Array<CompatibleStreamPropertyGroup>;
    allowedDvrWindowDurations?: AllowedDvrWindowDurations; // Currently not in Remote Config likely to change in the future
    telemetry?: TelemetryConfig; // Currently not in Remote Config likely to change in the future
    csai?: CsaiConfig; // Currently not in Remote Config likely to change in the future
    cmcd?: CmcdConfig; // Currently not in Remote Config likely to change in the future
    performance?: PerformanceConfig;
    reducedLatency?: ReducedLatencyOptions; // Currently not in Remote Config likely to change in the future
    allowedAudioCodecs?: Array<string>; // Currently not in Remote Config likely to change in the future
    hasExtendedDvrSupport?: boolean; // Currently not in Remote Config likely to change in the future
    metalist?: Metalist;
}

/**
 * @internal
 */
export type InternalConfig = InternalConfigStatic & InternalConfigDynamic;

export type ConfigPerPlaybackType<T> = { [key in PlaybackType]?: T } & {
    default: T;
};

/**
 * @internal
 */
export type InternalConfigPerPlaybackType = { staticConfig: InternalConfigStatic } & ConfigPerPlaybackType<InternalConfigDynamic>;
