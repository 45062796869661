import type { AdBreak } from '../../addons/adverts/common';
import { cloneAdBreak } from '../../utils/ad-break-utils';

function sortAdBreaks(adBreaks: Array<AdBreak>): Array<AdBreak> {
    const sortedBreaks = adBreaks.sort((ab1, ab2) => {
        if (ab1.position === 'start' || ab2.position === 'end') {
            return -1;
        }

        if (ab1.position === 'end' || ab2.position === 'start') {
            return 1;
        }

        return ab1.position! - ab2.position!;
    });

    return sortedBreaks;
}

export function toAdBreaksWithPositionIncludingAds(adBreaksWithContentPosition: Array<AdBreak>): Array<AdBreak> {
    const sortedAdBreaksWithContentPosition = sortAdBreaks(adBreaksWithContentPosition);

    const { adBreaksWithPositionIncludingAds } = sortedAdBreaksWithContentPosition.reduce(
        (acc, adBreakWithContentPosition) => {
            const adBreak = cloneAdBreak(adBreakWithContentPosition);
            const breakDuration = adBreak.expectedDuration ?? adBreak.ads.reduce((dur, ad) => dur + ad.expectedDuration, 0);

            if (adBreak.position === 'start') {
                adBreak.position = 0;
            }

            if (typeof adBreak.position === 'number') {
                adBreak.position = adBreak.position + acc.accumulatedBreakDuration;
            }

            return {
                accumulatedBreakDuration: acc.accumulatedBreakDuration + breakDuration,
                adBreaksWithPositionIncludingAds: acc.adBreaksWithPositionIncludingAds.concat([adBreak]),
            };
        },
        { accumulatedBreakDuration: 0, adBreaksWithPositionIncludingAds: [] as Array<AdBreak> }
    );

    return adBreaksWithPositionIncludingAds;
}
