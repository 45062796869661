import type { InternalSessionState } from './internal-session-state';
import type { VideoTrack } from '../player/player-engine-item';
import type { Track } from '../player/track';
import { sdkLogger } from '../../logger';
import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';
import { Observable } from '../../utils/observables/observable';
import type { SessionControllerInternalProxy } from './session-controller-internal-proxy';
import { TrackableEvent } from './session-controller.enums';
export { TrackableEvent } from './session-controller.enums';

type SessionEvent = {
    timestamp: number;
};

type VideoTrackChangedEvent = SessionEvent & {
    videoTrack: VideoTrack;
};

type AudioTrackChangedEvent = SessionEvent & {
    audioTrack: Track;
};

type SessionStateChangedEvent = SessionEvent & {
    state: InternalSessionState;
};

type TrackedDataInternal = {
    [TrackableEvent.VideoTrackChanged]: Array<VideoTrackChangedEvent>;
    [TrackableEvent.AudioTrackChanged]: Array<AudioTrackChangedEvent>;
    [TrackableEvent.SessionStateChanged]: Array<SessionStateChangedEvent>;
};

export type TrackedData = Partial<TrackedDataInternal>;

export class SessionEventTracker {
    private trackedDataObservable: Observable<TrackedData> = new Observable();
    private observedEventTypes: Set<TrackableEvent> = new Set();
    private trackedData: TrackedDataInternal = {
        [TrackableEvent.VideoTrackChanged]: [],
        [TrackableEvent.AudioTrackChanged]: [],
        [TrackableEvent.SessionStateChanged]: [],
    };

    private availableAudioTracks: Array<Track> = [];
    private logger: Logger;

    constructor(private sessionProxy: SessionControllerInternalProxy) {
        this.logger = sdkLogger.withContext('Session Event Tracker');
        this.sessionProxy.onSessionEnded(this.handleSessionEnded.bind(this));
    }

    public requestEventTracking(eventTypes: Array<TrackableEvent>, callback: (ev: TrackedData) => void, owner: Object) {
        eventTypes.forEach((ev) => {
            if (this.observedEventTypes.has(ev)) {
                return;
            }

            this.registerSessionObserver(ev);
            this.observedEventTypes.add(ev);
        });

        this.trackedDataObservable.registerObserver(callback, owner);
    }

    public unsubscribe(owner: Object) {
        this.trackedDataObservable.unregisterObservers(owner);
    }

    private registerSessionObserver(ev: TrackableEvent) {
        switch (ev) {
            case TrackableEvent.VideoTrackChanged:
                this.sessionProxy.onVideoTrackChanged(this.handleVideoTrackChanged.bind(this));
                return;
            case TrackableEvent.AudioTrackChanged:
                this.sessionProxy.onAvailableAudioTracksChanged(this.handleAvailableAudioTracksChanged.bind(this));
                this.sessionProxy.onAudioTrackChanged(this.handleAudioTrackChanged.bind(this));
                return;
            case TrackableEvent.SessionStateChanged:
                this.sessionProxy.onStateChanged(this.handleSessionStateChanged.bind(this));
                return;
        }
    }

    private handleVideoTrackChanged(videoTrack: VideoTrack) {
        const event = this.timestamp({ videoTrack });
        this.trackedData[TrackableEvent.VideoTrackChanged].push(event);
    }

    private handleAvailableAudioTracksChanged(availableAudioTracks: Array<Track>) {
        this.availableAudioTracks = availableAudioTracks;
    }

    private handleAudioTrackChanged(trackId: Track['id']) {
        const audioTrack = this.availableAudioTracks.find((t) => t.id === trackId);
        if (!audioTrack) {
            this.logger.warn("Received an audio track ID which didn't match any available audio tracks");
            return;
        }

        const event = this.timestamp({ audioTrack });
        this.trackedData[TrackableEvent.AudioTrackChanged].push(event);
    }

    private handleSessionStateChanged(state: InternalSessionState) {
        const event = this.timestamp({ state });
        this.trackedData[TrackableEvent.SessionStateChanged].push(event);
    }

    private timestamp<T>(data: T): T & { timestamp: number } {
        return {
            ...data,
            timestamp: Date.now(),
        };
    }

    private handleSessionEnded() {
        if (!this.trackedDataObservable) {
            this.logger.warn('Unable to notify because destroy was called before the session was ended');
            return;
        }

        this.trackedDataObservable.notifyObservers(this.trackedData);
    }

    public destroy() {
        this.trackedDataObservable = undefined!;
        this.observedEventTypes = undefined!;
        this.trackedData = undefined!;
        this.availableAudioTracks = undefined!;
        this.sessionProxy = undefined!;
    }
}
