import type { DeviceInformation } from '@sky-uk-ott/client-lib-js-device';
import { checkIsTizenVersion, DeviceType, TizenVersion } from '@sky-uk-ott/client-lib-js-device';

import { PlayerType } from '../core/player/player-type';
import type { DevicePlayerMapType } from './proposition-extensions';
import { getLgWebOsVersion } from '../utils/device-type';

export const tizenDefaultPlayerSelectionHandler = (deviceInfo?: DeviceInformation): PlayerType => {
    if (deviceInfo && (checkIsTizenVersion(deviceInfo, TizenVersion['Tizen-2.4']) || checkIsTizenVersion(deviceInfo, TizenVersion['Tizen-2.3']))) {
        return PlayerType.Theo;
    } else {
        return PlayerType.Shaka;
    }
};

export const webOsDefaultPlayerSelectionHandler = (deviceInfo?: DeviceInformation): PlayerType => {
    if (deviceInfo && getLgWebOsVersion(deviceInfo) <= 3) {
        return PlayerType.DualPlayer;
    } else {
        return PlayerType.Shaka;
    }
};

export const getDefaultDevicePlayerMap = (deviceInfo?: DeviceInformation): DevicePlayerMapType => ({
    [DeviceType.Generic]: { default: PlayerType.Shaka },
    [DeviceType.Browser]: { default: PlayerType.Shaka },
    [DeviceType.Vidaa]: { default: PlayerType.Shaka },
    [DeviceType.Vizio]: { default: PlayerType.Shaka },
    [DeviceType.Ion]: { default: PlayerType.Tape },
    [DeviceType.WebOS]: { default: webOsDefaultPlayerSelectionHandler(deviceInfo) },
    [DeviceType.Tizen]: { default: tizenDefaultPlayerSelectionHandler(deviceInfo) },
    [DeviceType.WebMaf]: { default: PlayerType.WebMaf },
    [DeviceType.Prospero]: { default: PlayerType.Shaka },
    [DeviceType.XboxOne]: { default: PlayerType.Shaka },
    [DeviceType.XboxSeries]: { default: PlayerType.Shaka },
    [DeviceType.YouView]: { default: PlayerType.YouView },
    [DeviceType.Android]: { default: PlayerType.Android },
    [DeviceType.Chromecast]: { default: PlayerType.Chromecast },
    [DeviceType.Llama]: { default: PlayerType.Shaka },
    [DeviceType.Kepler]: { default: PlayerType.Shaka },
    [DeviceType.Netgem]: { default: PlayerType.Shaka },
    [DeviceType.Xfinity]: { default: PlayerType.Aamp },
    [DeviceType.Zenterio]: { default: PlayerType.Shaka },
    [DeviceType.OneSdk]: { default: PlayerType.Shaka },
    [DeviceType.RDK]: { default: PlayerType.Shaka },
    [DeviceType.Quest]: { default: PlayerType.Shaka },
    [DeviceType.MultiChoiceRdk]: { default: PlayerType.Shaka },
    [DeviceType.Kpn]: { default: PlayerType.Shaka },
});
