import type {
    Advertising,
    Capabilities,
    Deeplink,
    Device,
    DeviceInformation,
    DeviceStorage,
    DeviceTelemetry,
    DeviceType,
    Display,
    Input,
    Lifecycle,
    NetworkStatus,
} from '@sky-uk-ott/client-lib-js-device';
import type { LogHandler } from '@sky-uk-ott/core-video-sdk-js-logger';
import { createConsoleLogHandler, LogLevel } from '@sky-uk-ott/core-video-sdk-js-logger';

import type { InternalConfigPerPlaybackType } from './config/internal-config';
import { InternalConfigProvider } from './config/internal-config-provider';
import type { PlayerCapabilities, PlayerEngine } from './core/player/player-engine';
import type { PlayerEngineFactory, PlayerEngineOptions } from './core/player/player-engine-factory';
import type { PlayerFallbackEngineFactory } from './core/player/player-fallback-engine-factory';
import type { PlayerPrecursorEngine } from './core/player/player-precursor-engine';
import type { PlayerType } from './core/player/player-type';
import type { PlaybackType, PlayoutData, StreamingProtocol } from './core/player/playout-data';
import type { CompatibleStreamPropertyGroup } from './core/player/stream-capability-specification';
import {
    filterStreamsByCodecCompatibility,
    getPreferredCompatibleStreamPropertyGroup,
    prioritiseCompatibleStreamPropertyGroups,
} from './core/player/stream-capability-specification';
import { TestingOverrides } from './core/services/testing-overrides';
import { VideoPlatformIntegration } from './core/video-platform-integration/video-platform-integration';
import { sdkLogger } from './logger';
import type { PropositionExtensions } from './propositions/proposition-extensions';
import { JsonUtils } from './utils/json-utils';
import { version } from './version';
import type { VideoPlatformIntegrationProvider } from './video-platforms/integration-provider';
import type { BandwidthEstimator } from '@sky-uk-ott/vpt-abr-network-estimator';
import type { BandwidthEstimator as LegacyBandwidthEstimator } from '@sky-uk-ott/core-video-sdk-js-bandwidth-estimator';
import type { CoreVideoConfigMetadata } from './config/core-video-config/core-video-config-types';
import type { CoreVideoInternalWrapper } from './core-video-internal.types';
import { AudioCodec } from './core/player/audio-format';

export class CoreVideoInternal {
    public static sdkName = 'core-video-sdk-js';
    protected static device: Device;
    protected static _isInitialised: boolean | null = null;
    protected static playerEngineFactory: PlayerEngineFactory;
    protected static videoPlatformIntegration: VideoPlatformIntegration;
    protected static configProvider: InternalConfigProvider;
    protected static _configFailoverReason: string;
    private static _config: InternalConfigPerPlaybackType;
    private static propositionExtensions: PropositionExtensions;
    private static _memoizedStreamCapabilityGroups: { [key: string]: CompatibleStreamPropertyGroup | undefined } = {};
    protected static coreVideoInternalWrapper: CoreVideoInternalWrapper = {
        config: () => this._config,
        display: () => this.display,
        getCompatibleStreamPropertyGroup: (playbackType?: PlaybackType) => this.getCompatibleStreamPropertyGroup(playbackType),
        capabilities: () => this.capabilities,
        getPropositionExtensions: () => this.propositionExtensions,
        deviceType: () => this.deviceType,
        playerCapabilities: () => this.playerCapabilities,
        getAllowedAudioCodecs: (supportedAudioCodecs?: string[], playbackType?: PlaybackType) =>
            this.getAllowedAudioCodecs(supportedAudioCodecs, playbackType),
    };

    public static get config(): InternalConfigPerPlaybackType {
        this.throwIfCoreSdkNotInitialised();
        return this._config;
    }

    public static get configFailoverReason(): string {
        this.throwIfCoreSdkNotInitialised();
        return this._configFailoverReason;
    }

    public static get bandwidthEstimator(): LegacyBandwidthEstimator | BandwidthEstimator | undefined {
        this.throwIfCoreSdkNotInitialised();
        return this._config?.default?.performance?.bandwidthEstimator;
    }

    public static get deviceInfo(): DeviceInformation {
        this.throwIfDeviceNotInitialised();
        return this.device.deviceInfo;
    }

    public static get storage(): DeviceStorage {
        this.throwIfDeviceNotInitialised();
        return this.device.storage;
    }

    public static get input(): Input | undefined {
        this.throwIfDeviceNotInitialised();
        return this.device.input;
    }

    public static get lifecycle(): Lifecycle | undefined {
        this.throwIfDeviceNotInitialised();
        return this.device.lifecycle;
    }

    public static get networkStatus(): NetworkStatus | undefined {
        this.throwIfDeviceNotInitialised();
        return this.device.networkStatus;
    }

    public static get deeplink(): Deeplink | undefined {
        this.throwIfDeviceNotInitialised();
        return this.device.deeplink;
    }

    public static get capabilities(): Capabilities {
        this.throwIfDeviceNotInitialised();
        return this.device.capabilities;
    }

    public static get display(): Display | undefined {
        this.throwIfDeviceNotInitialised();
        return this.device.display;
    }

    public static get telemetry(): DeviceTelemetry | undefined {
        this.throwIfDeviceNotInitialised();
        return this.device.telemetry;
    }

    public static get deviceType(): DeviceType {
        this.throwIfDeviceNotInitialised();
        return this.device.type;
    }

    public static get advertising(): Advertising | undefined {
        this.throwIfDeviceNotInitialised();
        return this.device.advertising;
    }

    public static get version(): string {
        return version;
    }

    public static get playerVersion(): string {
        this.throwIfCoreSdkNotInitialised();
        return this.playerEngineFactory.playerVersion;
    }

    public static get playerCapabilities(): PlayerCapabilities {
        this.throwIfCoreSdkNotInitialised();
        return this.playerEngineFactory.capabilities;
    }

    public static getCompatibleStreamPropertyGroup(playbackType?: PlaybackType): CompatibleStreamPropertyGroup | undefined {
        if (TestingOverrides.compatibleStreamPropertyGroup) {
            sdkLogger.warn('Overriding Compatible Stream Property Group with: ', JsonUtils.stringify(TestingOverrides.compatibleStreamPropertyGroup));
            return TestingOverrides.compatibleStreamPropertyGroup;
        }

        const key = playbackType?.toString() ?? 'default';
        if (this._memoizedStreamCapabilityGroups[key]) {
            return this._memoizedStreamCapabilityGroups[key];
        } else {
            const compatibleStreamPropertyGroup = this._getCompatibleStreamPropertyGroup(playbackType || 'default');
            this._memoizedStreamCapabilityGroups[key] = compatibleStreamPropertyGroup;
            return compatibleStreamPropertyGroup;
        }
    }

    private static _getCompatibleStreamPropertyGroup(playbackType: PlaybackType | 'default'): CompatibleStreamPropertyGroup | undefined {
        const compatibleStreamPropertyGroups = this.playerCapabilities?.compatibleStreamPropertyGroups;
        if (!compatibleStreamPropertyGroups || compatibleStreamPropertyGroups.length === 0) {
            return;
        }

        const configPerPlaybackType = InternalConfigProvider.getForPlaybackType(this.config, playbackType);
        const preferredStreamPropertyGroups = configPerPlaybackType?.preferredStreamPropertyGroups;

        let preferredCompatibleGroups: Array<CompatibleStreamPropertyGroup> = compatibleStreamPropertyGroups;
        if (preferredStreamPropertyGroups && preferredStreamPropertyGroups.length > 0 && compatibleStreamPropertyGroups.length > 0) {
            preferredCompatibleGroups = preferredStreamPropertyGroups.reduce(
                (arr: Array<CompatibleStreamPropertyGroup>, preferredGroup: CompatibleStreamPropertyGroup) => {
                    const newGroups = compatibleStreamPropertyGroups.map((compatibleGroup: CompatibleStreamPropertyGroup) => {
                        return {
                            maxVideoFormat: Math.min(preferredGroup.maxVideoFormat, compatibleGroup.maxVideoFormat),
                            maxFrameRate: Math.min(preferredGroup.maxFrameRate, compatibleGroup.maxFrameRate),
                            supportedColourSpaces: preferredGroup.supportedColourSpaces.filter((colourSpace) =>
                                compatibleGroup.supportedColourSpaces.includes(colourSpace)
                            ),
                        };
                    });

                    return [...arr, ...newGroups];
                },
                [] as Array<CompatibleStreamPropertyGroup>
            );
        }

        const propositionSpecifications = this.propositionExtensions.streamCapabilitySpecifications;
        let streamSpecifications = (playbackType && propositionSpecifications?.[playbackType]) || propositionSpecifications?.default || [];
        if (this.playerEngineFactory?.canSupportCodec) {
            streamSpecifications = filterStreamsByCodecCompatibility(
                this.playerEngineFactory.canSupportCodec.bind(this.playerEngineFactory),
                streamSpecifications
            );
        }

        const preferredCapabilities = getPreferredCompatibleStreamPropertyGroup(preferredCompatibleGroups, streamSpecifications);
        if (preferredCapabilities) {
            return preferredCapabilities;
        }

        const prioritisedCompatibleGroups = prioritiseCompatibleStreamPropertyGroups(preferredCompatibleGroups);
        return prioritisedCompatibleGroups[0];
    }

    public static getAllowedAudioCodecs(supportedAudioCodecs?: string[], playbackType?: PlaybackType): string {
        const { allowedAudioCodecs } = InternalConfigProvider.getForPlaybackType(this.config, playbackType || 'default');

        return allowedAudioCodecs?.join(',') || supportedAudioCodecs?.join(',') || AudioCodec.MP4A2;
    }

    public static get playerType(): PlayerType {
        this.throwIfCoreSdkNotInitialised();
        return this.playerEngineFactory.type;
    }

    public static get deviceAdsId(): string | undefined {
        this.throwIfDeviceNotInitialised();
        return this.device.advertising?.deviceAdsId();
    }

    /**
     * Register a log handler to receive all framework logging information.
     * If a log handler is being set, it should be set before calling any other framework functions.
     * @param logHandler if no log handler is passed in, it defaults to a console logger set to the highest level of verbosity.
     */
    public static registerLogHandler(logHandler: LogHandler = createConsoleLogHandler(LogLevel.Verbose)): void {
        sdkLogger.addLogger(logHandler);
    }

    public static getSupportedStreamingProtocols(): Array<StreamingProtocol> {
        this.throwIfCoreSdkNotInitialised();
        return this.playerEngineFactory.getSupportedStreamingProtocols();
    }

    public static getCurrentPlayerTypeForPlayoutData(playoutData: PlayoutData): PlayerType {
        this.throwIfCoreSdkNotInitialised();
        return (
            (this.playerEngineFactory as PlayerFallbackEngineFactory).getCurrentPlayerForPlayoutData?.(playoutData) || this.playerEngineFactory.type
        );
    }

    public static getPropositionExtensions(): PropositionExtensions {
        this.throwIfCoreSdkNotInitialised();
        return this.propositionExtensions;
    }

    public static getCoreVideoConfigMetadata(playbackType?: PlaybackType): CoreVideoConfigMetadata {
        this.throwIfCoreSdkNotInitialised();
        if (playbackType) {
            return this.config[playbackType]?.configMetadata || this.config.default.configMetadata;
        }
        return this.config.default.configMetadata;
    }

    protected static getPlayerEngine(playerEngineOptions: PlayerEngineOptions): PlayerEngine {
        this.throwIfCoreSdkNotInitialised();

        if (!this.videoPlatformIntegration) {
            throw new Error('Video platform integration provider not registered');
        }

        if (!this.playerEngineFactory) {
            throw new Error('Player engine factory not registered');
        }

        return this.playerEngineFactory.createPlayerEngine(playerEngineOptions);
    }

    protected static getPlayerPrecursorEngine(): PlayerPrecursorEngine | undefined {
        if (this.playerEngineFactory.capabilities.hasPrefetchSupport) {
            return this.playerEngineFactory.createPlayerPrecursorEngine?.();
        }
        return undefined;
    }

    protected static register(
        config: InternalConfigPerPlaybackType,
        configProvider: InternalConfigProvider,
        videoPlatformIntegrationProvider: VideoPlatformIntegrationProvider,
        device: Device,
        playerEngineFactory: PlayerEngineFactory,
        propositionExtensions: PropositionExtensions
    ): void {
        this._config = config;
        this.configProvider = configProvider;
        this.registerPropositionExtensions(propositionExtensions);
        this.registerVideoPlatformIntegration(videoPlatformIntegrationProvider, config);
        this.device = device;
        this.registerPlayerEngineFactory(playerEngineFactory);
    }

    protected static async reRegister(config: InternalConfigPerPlaybackType, playerEngineFactory: PlayerEngineFactory): Promise<void> {
        this._config = config;
        const loaderPromises = this.propositionExtensions.addonLoaders.map((loader) => loader.load(config));
        await Promise.all(loaderPromises);
        this.registerPlayerEngineFactory(playerEngineFactory);
    }

    private static throwIfCoreSdkNotInitialised(): void {
        if (!this._isInitialised) {
            throw new Error('Core SDK is not initialised');
        }
    }

    private static throwIfDeviceNotInitialised(): void {
        if (!this.device) {
            throw new Error('Device is not initialised, call either loadDevice or initialise first');
        }
    }

    private static registerPropositionExtensions(extensions: PropositionExtensions): void {
        this.propositionExtensions = extensions;
    }

    private static registerPlayerEngineFactory(playerEngineFactory: PlayerEngineFactory): void {
        this.playerEngineFactory = playerEngineFactory;
    }

    private static registerVideoPlatformIntegration(
        videoPlatformIntegrationProvider: VideoPlatformIntegrationProvider,
        config?: InternalConfigPerPlaybackType
    ): void {
        if (!videoPlatformIntegrationProvider) {
            throw new Error('No VPIP was provided');
        }

        this.videoPlatformIntegration = new VideoPlatformIntegration(videoPlatformIntegrationProvider, this.coreVideoInternalWrapper, config);
    }
}
