export enum RemoteConfigErrorCodes {
    Default = 'NA',
    MalformedUri = 'CFG01000',
    HttpBadResponse = 'CFG02',
    SuccessfulEmptyResponse = 'CFG04',
    RequestInProgress = 'CFG06000',
    ParsingFailed = 'CFG03',
    RequestTimedOut = 'CFG05000',
}

export enum MatcherType {
    String = 'String',
    Number = 'Number',
    Version = 'Version',
}
