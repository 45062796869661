import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';

import { sdkLogger } from '../../../logger';
import { Observable } from '../../../utils/observables/observable';
import type { PlayerEngineItem, TimeRanges } from '../../player/player-engine-item';
import { PlayerState } from '../../player/player-state';

import { TimelineItemType } from './timeline';
import type { TimelineEventData } from './timeline-manager';
import { CoreVideoInternal } from '../../../core-video-internal';
import { isSafariDesktop } from '../../../utils/device-type';

export interface BufferingLimitConfig {
    enableRebufferingLimit: boolean;
    enableSeekingLimit?: boolean;
    maxMainContentBufferingSecs: number;
    maxAdvertBufferingSecs: number;
}

export interface BufferingLimitError {
    code: string;
    message: string;
    shouldFailSilently: boolean;
}

const BUFFER_EXHAUSTED_DETECTION_TOLERANCE_SECS = 2;

interface BufferedInfoCheckResult {
    total: boolean;
    audio: boolean;
    video: boolean;
}

export class BufferingLimit {
    public isCdnSwitching = false;
    private logger: Logger = sdkLogger.withContext('BufferLimit');
    private currentTimeout: ReturnType<typeof setTimeout> | null = null;
    private config: BufferingLimitConfig;
    private bufferLimitReachedObservable: Observable<void> = new Observable<void>();
    private unregisterPei?: () => void;
    private currentPei: PlayerEngineItem | null = null;
    private currentPosition: number | null = null;
    private wasBufferExhaustedOnTimerStart = false;
    private statesToLimit: Array<PlayerState> = [PlayerState.Loading];

    constructor(config: BufferingLimitConfig) {
        this.config = config;

        if (this.config.enableRebufferingLimit) {
            this.statesToLimit.push(PlayerState.Rebuffering);
        }

        if (this.config.enableSeekingLimit) {
            this.statesToLimit.push(PlayerState.Seeking);
        }
    }

    public playerEngineItemChanged({ timelineItem, playerEngineItem }: TimelineEventData): void {
        if (this.unregisterPei) {
            this.unregisterPei();
        }

        if (!this.isCdnSwitching) {
            this.stopTimer();
        }

        this.currentPei = playerEngineItem;
        const timeoutLimitSecs =
            timelineItem.type === TimelineItemType.MainContent ? this.config.maxMainContentBufferingSecs : this.config.maxAdvertBufferingSecs;

        playerEngineItem.onStateChanged((state) => this.playerStateChanged(state, timeoutLimitSecs), this);
        playerEngineItem.onPositionChanged((position) => (this.currentPosition = position), this);
        this.unregisterPei = () => {
            playerEngineItem.removeEventListeners(this);
            this.currentPosition = null;
            this.currentPei = null;
        };
    }

    public onBufferingLimitReached(callback: (e: BufferingLimitError) => void, owner: {}): void {
        this.bufferLimitReachedObservable.registerObserver(() => {
            callback(this.detectErrorReason());
        }, owner);
    }

    public removeEventListeners(owner: {}): void {
        this.bufferLimitReachedObservable.unregisterObservers(owner);
    }

    public destroy(): void {
        this.stopTimer();
        if (this.unregisterPei) {
            this.unregisterPei();
        }
        this.currentPei = null;
    }

    private detectErrorReason(): BufferingLimitError {
        const isBackground = !!CoreVideoInternal.lifecycle?.isAppBackgrounded();
        const backgroundPrefix = isBackground ? 'BACKGROUND.' : '';
        const isLoading = this.currentPei?.state === PlayerState.Loading;
        const isSeeking = this.currentPei?.state === PlayerState.Seeking;
        const isSafari = isSafariDesktop(CoreVideoInternal.deviceInfo, CoreVideoInternal.deviceType);
        const limitType = isLoading ? 'LOADING_LIMIT' : 'BUFFERING_LIMIT';
        const context = `${backgroundPrefix}${limitType}`;

        const gapsCheckInfo = this.checkForGaps();
        if (gapsCheckInfo) {
            if (gapsCheckInfo.audio && gapsCheckInfo.video) {
                return {
                    code: `${context}.NEAR_AUDIO_AND_VIDEO_GAP`,
                    message: 'Reached buffering limit near buffer audio and video gap',
                    shouldFailSilently: false,
                };
            }

            if (gapsCheckInfo.video) {
                return {
                    code: `${context}.NEAR_VIDEO_GAP`,
                    message: 'Reached buffering limit near buffer video gap',
                    shouldFailSilently: false,
                };
            }

            if (gapsCheckInfo.audio) {
                return {
                    code: `${context}.NEAR_AUDIO_GAP`,
                    message: 'Reached buffering limit near buffer audio gap',
                    shouldFailSilently: false,
                };
            }

            if (gapsCheckInfo.total) {
                return {
                    code: `${context}.NEAR_GAP`,
                    message: 'Reached buffering limit near buffer gap',
                    shouldFailSilently: false,
                };
            }
        }

        if (this.wasBufferExhaustedOnTimerStart) {
            return {
                code: `${context}.BUFFER_EXHAUSTED`,
                message: 'Buffer exhausted for too long',
                shouldFailSilently: false,
            };
        }

        if (isSafari && isSeeking) {
            return {
                code: `${context}.UNKNOWN_REASON`,
                message: 'Buffering too long for unknown reason',
                shouldFailSilently: isBackground,
            };
        }
        return {
            code: `${context}.UNKNOWN_REASON`,
            message: 'Buffering too long for unknown reason',
            shouldFailSilently: isLoading && isBackground,
        };
    }

    private checkForGaps(): BufferedInfoCheckResult | null {
        const bufferedRanges = this.currentPei?.getBufferedInfo();
        if (!bufferedRanges) {
            return null;
        }

        return {
            total: this.bufferedRangesContainGap(bufferedRanges.total),
            audio: this.bufferedRangesContainGap(bufferedRanges.audio),
            video: this.bufferedRangesContainGap(bufferedRanges.video),
        };
    }

    private bufferedRangesContainGap(bufferedRanges?: TimeRanges): boolean {
        return Boolean(bufferedRanges) && bufferedRanges!.length > 1;
    }

    private isBufferExhausted(): boolean {
        const bufferedInfo = this.currentPei?.getBufferedInfo();
        if (!bufferedInfo) {
            return false;
        }
        const bufferedRanges = bufferedInfo.total;
        if (!bufferedRanges || this.currentPosition === null || bufferedRanges.length === 0) {
            return false;
        }

        const bufferEnd = bufferedRanges[bufferedRanges.length - 1];
        return bufferEnd.end - BUFFER_EXHAUSTED_DETECTION_TOLERANCE_SECS <= this.currentPosition && this.currentPosition <= bufferEnd.end;
    }

    private playerStateChanged(state: PlayerState, timeoutLimitSecs: number): void {
        if (this.statesToLimit.includes(state)) {
            this.startTimer(state, timeoutLimitSecs);
        } else if (!this.isCdnSwitching) {
            setTimeout(() => this.stopTimer());
        }
    }

    private startTimer(state: PlayerState, timeoutLimitSecs: number): void {
        this.logger.info('Timer starting');
        if (!this.currentTimeout) {
            this.wasBufferExhaustedOnTimerStart = this.isBufferExhausted();
            this.currentTimeout = setTimeout(() => {
                this.bufferLimitReachedObservable.notifyObservers();
            }, timeoutLimitSecs * 1000);
        }
    }

    private stopTimer(): void {
        this.logger.info(`Timer stopped`);
        this.wasBufferExhaustedOnTimerStart = false;
        if (this.currentTimeout) {
            clearTimeout(this.currentTimeout);
            this.currentTimeout = null;
        }
    }
}
