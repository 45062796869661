import type { AddonConfig } from '../../internal-config';
import type { RemoteConfigSplit } from '../../sdk-config';
import type { DeclaredOptionalsAddonsConfig, InternalAddonsConfig } from './addons-config-adapter-common';

// Internal Config
type StreamMetadataFiltererInternalConfig = InternalAddonsConfig['streamMetadataFilterer'];

// Client Config when Remote Config is disabled
export type StreamMetadataFiltererClientConfig = AddonConfig<{}>;
export function adaptStreamMetadataFiltererClientConfig(
    config: StreamMetadataFiltererClientConfig | undefined
): StreamMetadataFiltererInternalConfig {
    return config;
}

// Server Config when Remote Config is enabled
type StreamMetadataFiltererRemoteServerConfig = AddonConfig<{}>;

// Client Config when Remote Config is enabled
type StreamMetadataFiltererRemoteClientConfig = {};

export type StreamMetadataFiltererRemoteConfigSplit = RemoteConfigSplit<
    StreamMetadataFiltererRemoteServerConfig,
    StreamMetadataFiltererRemoteClientConfig
>;
export function adaptStreamMetadataFiltererRemoteConfigSplit(
    config: StreamMetadataFiltererRemoteConfigSplit
): DeclaredOptionalsAddonsConfig<StreamMetadataFiltererInternalConfig> {
    return {
        enabled: Boolean(config.remoteConfig?.enabled),
        config: {},
    };
}
