import type { DrmConfig } from '../config/internal-config';
import { sdkLogger } from '../logger';

/**
 * Helper class for managing DRM certificates
 */
export class DrmCertificateManager {
    public static readonly certUrlTimeout: number = 4000;

    /**
     *
     * @param drmConfig Configuration for DRM including a Widevine or Fairplay Certificate URL
     */
    public static preload(drmConfig: DrmConfig): Promise<void> | undefined {
        if (drmConfig?.widevine?.certificateUrl) {
            return DrmCertificateManager.preloadCert('widevine', drmConfig);
        }

        if (drmConfig?.fairplay?.certificateUrl && !drmConfig?.fairplay?.disablePrefetch) {
            return DrmCertificateManager.preloadCert('fairplay', drmConfig);
        }
    }

    private static preloadCert(drmType: keyof DrmConfig, drmConfig: DrmConfig): Promise<void> | undefined {
        /**
         * This is an optional non-blocking optimisation.
         * If this loads in time before playout,
         * we'll use this certificate to prevent an extra acquire licence http request.
         */
        const certificateUrl = drmConfig?.[drmType]?.certificateUrl;
        if (!certificateUrl) return;

        const controller = new AbortController();
        const timer = setTimeout(() => {
            controller.abort();
            onrejected(`request failed to respond within ${DrmCertificateManager.certUrlTimeout} ms`);
        }, DrmCertificateManager.certUrlTimeout);

        const onResponse = (response: Response) => {
            clearTimeout(timer);

            if (!response.ok) {
                return Promise.reject(new Error(`status: ${response.status}, statusText: ${response.statusText}`));
            }
            return response.arrayBuffer();
        };

        const onrejected = (error?: string) => {
            sdkLogger.error(`Failed to preload ${drmType} certificate: ${error}`);
        };

        const options = {
            signal: controller.signal,
            headers: { 'Content-Type': 'text/plain' },
        };
        return fetch(certificateUrl, options)
            .then(onResponse)
            .then((certificate) => {
                drmConfig[drmType]!.certificate = certificate;
            })
            .catch(onrejected);
    }
}
