import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';
import { sdkLogger } from '../../../logger';
import { PerfKey, perfLogger, PerfTag } from '../../../utils/perf';
import type { VideoPlatformIntegration } from '../../video-platform-integration/video-platform-integration';
import type { SessionItem } from '../session-controller';
import type { VideoStartupController } from '../video-startup/video-startup-controller';
import type { VideoStartupState } from '../video-startup/video-startup-states';
import { VideoStartupStates } from '../video-startup/video-startup-states';
import { PrefetchFailureTypes, PrefetchStatus } from './prefetch.enums';
import { checkIsManifestLinearType } from '../../../utils/playback-type';
export { PrefetchFailureTypes, PrefetchStatus } from './prefetch.enums';

// Todo - it may be useful to store prefetch failure types somewhere and then
// Report them to conviva or verbose logging.

export class PrefetchItem {
    private logger: Logger = sdkLogger.withContext('Prefetch');

    private _lastPrefetchStage: VideoStartupState;
    private _isPrefetchLocked = false;

    private _prefetchPromise: Promise<boolean | void> | null = null;

    private _status: PrefetchStatus = PrefetchStatus.Uninitialised;

    constructor(
        private videoStartupController: VideoStartupController,
        private videoPlatformIntegration: VideoPlatformIntegration,
        private sessionItem: SessionItem,
        private destroyHandler: () => void,
        private includeAdsPrefetchWhenPossible?: boolean
    ) {
        this._lastPrefetchStage = this.videoStartupController.state;
    }

    public get isFinished() {
        return this._status === PrefetchStatus.Finished;
    }

    public get isActive() {
        return this._status === PrefetchStatus.Active;
    }

    public get isPaused() {
        return this._status === PrefetchStatus.Paused;
    }

    public get isDestroyed() {
        return this._status === PrefetchStatus.Destroyed;
    }

    public get hasPrefetchEnded() {
        return this.isFinished || this.isDestroyed;
    }

    public get shouldStopPrefetch() {
        return this.hasPrefetchEnded || this.isPaused;
    }

    private mapVamFailureStatusToPrefetchFailureType(status: number) {
        if (status === 204) {
            return PrefetchFailureTypes.VamNoContent;
        } else if (status === 403) {
            return PrefetchFailureTypes.VamUnauthorized;
        } else if (status !== 200) {
            return PrefetchFailureTypes.VamFailure;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public async lockPrefetchStage(): Promise<any> {
        if (this._isPrefetchLocked) {
            return;
        }

        this._lastPrefetchStage = this.videoStartupController.state;
        this._isPrefetchLocked = true;
        this._status = PrefetchStatus.Finished;

        this.videoStartupController.engineStarter?.unregisterPeiPrecursorErrorsObservable();

        return this._prefetchPromise;
    }

    public getLastPrefetchStage(): VideoStartupStates {
        return this._lastPrefetchStage.type();
    }

    public get isPrefetchLocked() {
        return this._isPrefetchLocked;
    }

    private setPrefetchIsFinished() {
        this._status = PrefetchStatus.Finished;
        this.logger.verbose(`Prefetch is finished`);
    }

    private handleStopOnSLEPreroll() {
        const vamPrerollEnabled: boolean | undefined = this.videoStartupController.cachedModifiedPlayoutData!.vamPrerollEnabled;
        if (vamPrerollEnabled) {
            this.setPrefetchIsFinished();
        }
    }

    private prefetchVamFailuresHandler(vamFailureStatus: number) {
        const videoStartupFailureType = this.mapVamFailureStatusToPrefetchFailureType(vamFailureStatus);

        if (videoStartupFailureType === PrefetchFailureTypes.VamFailure) {
            this.videoStartupController.discard();
            this.setPrefetchIsFinished();
        }

        if (videoStartupFailureType === PrefetchFailureTypes.VamNoContent || videoStartupFailureType === PrefetchFailureTypes.VamUnauthorized) {
            this.videoStartupController.skipAdvertisement();
        }
    }

    private handlePrefetchOnVam() {
        const vamFailureStatus: number | undefined = this.videoStartupController.cachedModifiedPlayoutData!.vamFailureStatus;

        if (vamFailureStatus) {
            this.prefetchVamFailuresHandler(vamFailureStatus);
        }
    }

    private handleAdsFailure() {
        const adsFailoverReason: string | undefined = this.videoStartupController.cachedModifiedPlayoutData!.adsFailoverReason;

        if (adsFailoverReason) {
            this.videoStartupController.discard();
            this.setPrefetchIsFinished();
        }
    }

    private handleVideoStartupFailure() {
        this.videoStartupController.engineStarter!.onPeiPrecursorErrorsObservable(() => {
            this.setPrefetchIsFinished();
            this.videoStartupController.discard();
        });
    }

    private handleStartVideoEngine(): Promise<void> {
        this.videoStartupController.createEngineStarter(this.sessionItem);
        this.handleVideoStartupFailure();
        return this.videoStartupController.startEngine(this.sessionItem, { isPrefetch: true });
    }

    public async prefetch(): Promise<void> {
        try {
            const uninitialisedStates = [VideoStartupStates.Uninitialised, VideoStartupStates.Discarded];
            if (uninitialisedStates.includes(this.videoStartupController.state.type())) {
                this.videoStartupController.initialise();
            }

            if (this.videoStartupController.state.type() === VideoStartupStates.Initialised) {
                this._prefetchPromise = this.videoStartupController.callVideoProvider(this.sessionItem, this.videoPlatformIntegration, true);

                const isVideoProviderCalled = await this._prefetchPromise;
                if (isVideoProviderCalled) {
                    this.handleStopOnSLEPreroll();
                }
            }

            if (this.shouldStopPrefetch) {
                return;
            }

            if (this.videoStartupController.state.type() === VideoStartupStates.VideoProviderCalled) {
                this.videoStartupController.initialiseAdverts(this.sessionItem);
            }

            if (this.shouldStopPrefetch) {
                return;
            }

            if (this.videoStartupController.shouldSkipAdvertisements) {
                await this.tryToStartVideoEngine();
                this.setPrefetchIsFinished();
                return;
            }

            if (this.videoStartupController.state.type() === VideoStartupStates.AdvertsInitialised) {
                this._prefetchPromise = this.videoStartupController.callVam(this.sessionItem, { isPrefetch: true });
                const isVamCalled = await this._prefetchPromise;
                if (isVamCalled) {
                    this.handlePrefetchOnVam();
                }
            }

            if (this.shouldStopPrefetch) {
                return;
            }

            if (this.videoStartupController.state.type() === VideoStartupStates.VamCalled) {
                this._prefetchPromise = this.videoStartupController.bootstrapAds();
                const areAdsBootstrapped = await this._prefetchPromise;

                if (!areAdsBootstrapped) {
                    this.handleAdsFailure();
                } else if (!this.includeAdsPrefetchWhenPossible) {
                    this.setPrefetchIsFinished();
                }
            }

            if (this.shouldStopPrefetch) {
                return;
            }

            if (this.videoStartupController.shouldSkipAdvertisements) {
                await this.tryToStartVideoEngine();
                this.setPrefetchIsFinished();
                return;
            }

            if (this.videoStartupController.state.type() === VideoStartupStates.AdsBootstrapped) {
                this._prefetchPromise = this.videoStartupController.callAdsData();
                const isAdInsertionCalled = await this._prefetchPromise;
                if (isAdInsertionCalled) {
                    this.handleAdsFailure();
                }

                perfLogger.measure(PerfKey.root, PerfTag.playoutModified);
            }

            if (this.shouldStopPrefetch) {
                return;
            }

            await this.tryToStartVideoEngine();
            this.setPrefetchIsFinished();
        } catch (e) {
            this.videoStartupController.discard();
            this.setPrefetchIsFinished();
            throw e;
        }
    }

    public pause() {
        if (this.isFinished || this.isDestroyed) {
            this.logger.verbose(`Cannot pause a ${this.status} prefetch`);
            return;
        }

        this._status = PrefetchStatus.Paused;
        this.logger.verbose('Prefetch is paused');
    }

    public async resume(): Promise<void> {
        if (this.hasPrefetchEnded || this.isActive) {
            this.logger.verbose(`Cannot resume a ${this.status} prefetch`);
            return Promise.resolve();
        }

        await this._prefetchPromise;

        if (this.isActive) {
            // Prevent Race condition and resuming twice while waiting for promise to resolve
            return;
        }

        this._status = PrefetchStatus.Active;
        this.logger.verbose('Prefetch is resumed');

        return this.prefetch();
    }

    public cancel() {
        if (this.isDestroyed) {
            this.logger.verbose('Cannot cancel a destroyed prefetch');
            return;
        }

        this._status = PrefetchStatus.Destroyed;
        this.destroyHandler();
    }

    public get status(): PrefetchStatus {
        return this._status;
    }

    private async tryToStartVideoEngine(): Promise<void> {
        const isLinearType = checkIsManifestLinearType(this.sessionItem.type);

        if (!isLinearType) {
            this._prefetchPromise = this.handleStartVideoEngine();
            await this._prefetchPromise;
        }
    }
}
