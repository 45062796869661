import type { BookmarkUpdateEnhancerConfig } from '../../../addons/bookmark-update-enhancer/bookmark-update-enhancer-addon';
import type { AddonConfig } from '../../internal-config';
import type { RemoteConfigSplit } from '../../sdk-config';
import type { DeclaredOptionalsAddonsConfig, InternalAddonsConfig } from './addons-config-adapter-common';

// Internal Config
type BookmarkUpdateEnhancerInternalConfig = InternalAddonsConfig['bookmarkUpdateEnhancer'];

// Client Config when Remote Config is disabled
export type BookmarkUpdateEnhancerClientConfig = AddonConfig<BookmarkUpdateEnhancerConfig>;
export function adaptBookmarkUpdateEnhancerClientConfig(
    config: BookmarkUpdateEnhancerClientConfig | undefined
): BookmarkUpdateEnhancerInternalConfig {
    return config;
}

// Server Config when Remote Config is enabled
type BookmarkUpdateEnhancerRemoteServerConfig = AddonConfig<{}>;

// Client Config when Remote Config is enabled
type BookmarkUpdateEnhancerRemoteClientConfig = BookmarkUpdateEnhancerConfig;

export type BookmarkUpdateEnhancerRemoteConfigSplit = RemoteConfigSplit<
    BookmarkUpdateEnhancerRemoteServerConfig,
    BookmarkUpdateEnhancerRemoteClientConfig
>;
export function adaptBookmarkUpdateEnhancerRemoteConfigSplit(
    config: BookmarkUpdateEnhancerRemoteConfigSplit
): DeclaredOptionalsAddonsConfig<BookmarkUpdateEnhancerInternalConfig> {
    return {
        enabled: Boolean(config.remoteConfig?.enabled),
        config: {
            periodicUpdateIntervalMs: config.clientConfig?.periodicUpdateIntervalMs,
        },
    };
}
