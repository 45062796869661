import { PlayoutDataError } from '../../core/player/playout-data';
import type { ErrorCategory, ErrorCreationParams, ErrorParameters } from '../../error';
import { CvsdkError, ErrorReason, ErrorSeverity } from '../../error';
import { JsonUtils } from '../../utils/json-utils';
import { OvpErrorSource } from './ovp.enums';
export { OvpErrorSource } from './ovp.enums';

const OVP_ERROR_TO_SDK_ERROR: Record<string, PlayoutDataError> = {
    OVP_00019: PlayoutDataError.PARENTAL_PIN_REQUIRED,
    OVP_00020: PlayoutDataError.INVALID_PIN_PROVIDED,
    OVP_00101: PlayoutDataError.PIN_SERVICE_DOWN,
};

const OVP_CONCURRENCY_ERROR_TO_SDK_ERROR: Record<string, string> = {
    8002: 'OVP_00014',
};

type CommonRequestError = Error & {
    code: string;
    url: string;
    statusCode: string;
};

class OvpAdapterError extends CvsdkError {
    constructor(
        code: string,
        message: string,
        severity: ErrorSeverity,
        cause?: unknown,
        category?: ErrorCategory,
        reason?: ErrorReason,
        parameters?: ErrorParameters
    ) {
        super(code, message, severity, cause, category, reason ?? ErrorReason.Undetermined, parameters);
        Object.setPrototypeOf(this, OvpAdapterError.prototype); // restore prototype chain
        this.name = `OvpAdapterError: code: ${code}`;
    }

    public static from({ code, message, severity, cause, category, reason, parameters }: ErrorCreationParams): OvpAdapterError {
        return new OvpAdapterError(code, message, severity, cause, category, reason, parameters);
    }
}

export class OvpErrorAdapter {
    public static adapt(config: { error: unknown; source: OvpErrorSource }) {
        switch (config.source) {
            case OvpErrorSource.PLAYER:
                return this.adaptFromPlayer(config.error);
            case OvpErrorSource.TOKEN_EXCHANGE:
                return this.adaptPreauthorized(config.error);
            case OvpErrorSource.PLAYOUT:
            default:
                return this.adaptPlayout(config.error);
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private static adaptPlayout(e: any): OvpAdapterError {
        return OvpAdapterError.from({
            code:
                (e.code && OVP_ERROR_TO_SDK_ERROR[e.code]) || e.code || (e.response?.status && `OVP_HTTP_${e.response.status}`) || 'SDK_VPI_FAILURE',
            message: e.description || e.response?.statusText || e.message || JsonUtils.stringify(e),
            severity: ErrorSeverity.Fatal,
            cause: this.adaptCauseMessage(e),
        });
    }

    private static adaptCauseMessage(error: Error): Error {
        if (!this.errorIsCommonRequestError(error)) {
            return error;
        }
        if (error.code === 'OVP_00024') {
            error.message = `${error.statusCode} returned from ${error.url}`;
        }
        return error;
    }

    private static errorIsCommonRequestError(error: unknown): error is CommonRequestError {
        return (
            Object.prototype.hasOwnProperty.call(error, 'code') &&
            Object.prototype.hasOwnProperty.call(error, 'statusCode') &&
            Object.prototype.hasOwnProperty.call(error, 'url')
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private static adaptPreauthorized(e: any): OvpAdapterError {
        const code = e.response?.body?.[0]?.errorCode || e.code;
        const description = e.response?.body?.[0]?.description || e.description || e.message;
        return this.adaptPlayout({
            ...e,
            description,
            code: OVP_CONCURRENCY_ERROR_TO_SDK_ERROR[code] || code || 'OVP.HEARTBEAT.UNKNOWN',
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private static adaptFromPlayer(e: any): OvpAdapterError {
        const reason = e.reason || ErrorReason.Undetermined;

        if (reason !== ErrorReason.DRM) {
            return e;
        }

        let adaptedCode = `${e.code || 'SDK_PLAYER_FAILURE'}`;
        const ovpErrorMatches = /(OVP_\d{5})/g.exec(JsonUtils.stringify(e));
        const hasMatch = ovpErrorMatches && ovpErrorMatches.length >= 2;

        if (hasMatch && e.code) {
            adaptedCode += `__${ovpErrorMatches[1]}`;
        }

        return OvpAdapterError.from({ ...e, code: adaptedCode });
    }
}
