import type { JSX } from 'react';

import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';

import type { InternalConfig, InternalConfigPerPlaybackType } from '../../config/internal-config';
import { CoreVideoInternal } from '../../core-video-internal';
import { LogVerbose } from '../../decorators/log-verbose';
import { sdkLogger } from '../../logger';
import type { PropositionExtensions } from '../../propositions/proposition-extensions';
import { Observable } from '../../utils/observables/observable';
import type { PlayerCapabilities, PlayerEngine } from '../player/player-engine';
import type { PlayerEngineCreationOptions } from '../player/player-engine-factory';
import type { PlayerPrecursorEngine } from '../player/player-precursor-engine';
import type { PlayoutRect } from '../player/playout-data';
import { SessionControllerPrecursorCache } from '../session-controller/precursor/session-controller-precursor-cache';
import { SessionControllerPrecursor } from '../session-controller/precursor/session-controller-precursor';

import type { SessionController, SessionItem } from '../session-controller/session-controller';
import { isRawSessionItem } from '../session-controller/session-controller';
import type { VideoPlatformIntegration } from '../video-platform-integration/video-platform-integration';
import { InternalConfigProvider } from '../../config/internal-config-provider';

/**
 * @public
 */
export class PlayerController {
    private currentSession?: SessionController;
    private sessionCreatedObservable: Observable<SessionController> = new Observable<SessionController>();
    private playoutRect?: PlayoutRect;
    private isFullScreen?: boolean;
    private logger: Logger;

    constructor(
        private engine: PlayerEngine,
        private reinitialise: () => Promise<InternalConfigPerPlaybackType>,
        private videoPlatformIntegration: VideoPlatformIntegration,
        private propositionExtensions: PropositionExtensions,
        private engineCreationOptions?: PlayerEngineCreationOptions,
        private peiPrecursorEngine?: PlayerPrecursorEngine
    ) {
        this.logger = sdkLogger.withContext('PlayerController');
    }

    @LogVerbose()
    public createSession(sessionItem: SessionItem): void {
        if (this.currentSession && !this.currentSession.isFinished()) {
            throw new Error('ERROR.SESSION.START.PREVIOUS_SESSION');
        }

        this.createSessionAsync(sessionItem);
    }

    public onSessionCreated(callback: (session: SessionController) => void): void {
        this.sessionCreatedObservable.registerObserver(callback, this);
    }

    @LogVerbose()
    public destroy(): void {
        if (this.currentSession) {
            this.currentSession.stop();
        }

        this.sessionCreatedObservable.unregisterObservers(this);
    }

    @LogVerbose()
    public getElement(): JSX.Element {
        return this.engine.getElement();
    }

    @LogVerbose()
    public disposeElement(): void {
        this.engine.disposeElement();
    }

    @LogVerbose()
    public getPlayoutRect(): PlayoutRect | undefined {
        if (CoreVideoInternal.playerCapabilities.setPlayoutRect) {
            return this.isFullScreen ? this.engine.getFullScreenPlayoutRect() : this.playoutRect;
        }

        if (CoreVideoInternal.playerCapabilities.getPlayoutRect) {
            return this.engine.getFullScreenPlayoutRect();
        }
    }

    @LogVerbose()
    public setPlayoutRect(expressivePlayoutRect: PlayoutRect<string | number>): void {
        this.playoutRect = this.getFixedPlayoutRect(expressivePlayoutRect);
        this.handleSetPlayoutRect();
    }

    @LogVerbose()
    public setFullScreen(flag: boolean): void {
        this.isFullScreen = flag;
        this.handleSetPlayoutRect();
    }

    @LogVerbose()
    public getCapabilities(): PlayerCapabilities {
        return CoreVideoInternal.playerCapabilities;
    }

    @LogVerbose()
    private async createSessionAsync(sessionItem: SessionItem): Promise<void> {
        const configPerPlaybackType = await this.reinitialise();
        const config = InternalConfigProvider.getForPlaybackType(configPerPlaybackType, sessionItem.type);

        const currentPrecursor = this.getSessionControllerPrecursor(config, sessionItem);

        currentPrecursor.onSessionAttached((sessionController: SessionController) => {
            // This should only happen once per precursor instance
            this.currentSession = sessionController;
            this.sessionCreatedObservable.notifyObservers(sessionController);
        });

        currentPrecursor.startSession();
    }

    @LogVerbose()
    private handleSetPlayoutRect(): void {
        if (CoreVideoInternal.playerCapabilities.setPlayoutRect) {
            if (this.isFullScreen) {
                const fullScreenRect = this.engine.getFullScreenPlayoutRect();
                this.engine.setPlayoutRect(fullScreenRect);
            } else {
                this.engine.setPlayoutRect(this.playoutRect!);
            }
        }
    }

    @LogVerbose()
    private getFixedPlayoutRect(expressivePlayoutRect: PlayoutRect<string | number>): PlayoutRect {
        if (CoreVideoInternal.playerCapabilities.getPlayoutRect) {
            const fullScreenRect = this.engine.getFullScreenPlayoutRect();

            if (fullScreenRect) {
                const { x, y, width, height } = expressivePlayoutRect;
                const getValue = (value: number | string, fullScreenRectValue: number) =>
                    Math.round(typeof value === 'number' ? value : this.parsePercentage(value) * fullScreenRectValue);
                return {
                    ...expressivePlayoutRect,
                    x: getValue(x, fullScreenRect.width),
                    y: getValue(y, fullScreenRect.height),
                    width: getValue(width, fullScreenRect.width),
                    height: getValue(height, fullScreenRect.height),
                };
            }
        }

        return expressivePlayoutRect as PlayoutRect;
    }

    @LogVerbose()
    private parsePercentage(val: string): number {
        const parsedValue = parseFloat(val);
        if (isNaN(parsedValue) || parsedValue > 100 || parsedValue < 0 || !val.endsWith('%')) {
            throw new Error('Proportional PlayoutRect must be expressed as a percentage within the screen size (eg: "50%")');
        }
        return parsedValue / 100;
    }

    @LogVerbose()
    private getSessionControllerPrecursor(config: InternalConfig, sessionItem: SessionItem): SessionControllerPrecursor {
        if (!isRawSessionItem(sessionItem)) {
            const precursor = SessionControllerPrecursorCache.getInstance().get(sessionItem.key, true);

            if (precursor) {
                this.logger.info(`Using cached precursor for content ${sessionItem.key}`);

                if (!precursor.getPlayerEngine()) {
                    precursor.setPlayerEngine(this.engine);
                    precursor.bootstrapSession();
                }

                precursor.updateSessionItem(sessionItem);

                return precursor;
            }
        }

        return new SessionControllerPrecursor(
            this.videoPlatformIntegration,
            sessionItem,
            this.propositionExtensions.addonFactories!,
            config,
            this.peiPrecursorEngine,
            this.engineCreationOptions,
            this.engine
        );
    }
}
