type TimedLockConfig = {
    lockTimeMs: number;
};

export class TimedLock {
    private _locked = false;
    private lockTimerHandler: ReturnType<typeof setTimeout> | null = null;
    constructor(private config: TimedLockConfig) {}

    public lock(): void {
        this.clearLockTimer();
        this._locked = true;
        this.setLockTimer();
    }

    public unlock(): void {
        this.clearLockTimer();
        this._locked = false;
    }

    public get locked(): boolean {
        return this._locked;
    }

    public destroy(): void {
        this.clearLockTimer();
    }

    private setLockTimer(): void {
        this.lockTimerHandler = setTimeout(() => {
            this.unlock();
        }, this.config.lockTimeMs);
    }

    private clearLockTimer(): void {
        if (this.lockTimerHandler) {
            clearTimeout(this.lockTimerHandler);
        }
        this.lockTimerHandler = null;
    }
}
