/**
 * @public
 */
export enum AudioFormat {
    STEREO,
    SURROUND_5_1,
    SURROUND_7_1,
    ATMOS,
}

export enum AudioCodec {
    AC3 = 'ac-3',
    EC3 = 'ec-3',
    MP4A2 = 'mp4a.40.2',
    MP4A5 = 'mp4a.40.5',
}

/**
 * @public
 */
export enum TextTrackKind {
    Caption = 'caption',
    Chapters = 'chapters',
    Descriptions = 'descriptions',
    Metadata = 'metadata',
    SubTitles = 'subtitles',
}

export enum TimedMetadataType {
    CEA_708 = 'cea-708',
    DataCue = 'DataCue',
    EMsg = 'EMsg',
    Id3 = 'Id3',
    HlsDaterange = 'HlsDaterange',
    EventStream = 'EventStream',
    MediaRouteInfo = 'MediaRouteInfo',
}

export enum HlsManifestType {
    Master = 'Master',
    Variant = 'Variant',
}

/**
 * @internal Types of playout
 * - Live:   Stream broadcasted in real time
 * - VOD:    Video on-demand, consumed whenever users choose to
 * - Advert: Advertisement stream
 */
export enum PlaybackType {
    Live = 'Live',
    VOD = 'VOD',
    Advert = 'Advert', // Isn't this a kind of VOD?
    SingleLiveEvent = 'SingleLiveEvent',
    Preview = 'Preview',
    Clip = 'Clip',
    FER = 'FER',
}

/**
 * @internal Content sources
 * - OTT:      Over-the-top, content delivered via the internet
 * - Download: Content that was previously downloaded and stored locally, and is streamed from the device's drive
 * - Sideload: Content that was previously downloaded and stored on another device, and is streamed from that device (usually via wi-fi)
 */
export enum Source {
    OTT = 'OTT',
    Download = 'Download',
    Sideload = 'Sideload',
}

/**
 * @internal Only Fairplay on OneApp uses keyos.com at the moment
 *
 */
export enum DrmProvider {
    Ovp = 'Ovp',
    Keyos = 'Keyos',
}

/**
 * @internal DRM types
 *
 */
export enum DrmType {
    PlayReady = 'PlayReady',
    Widevine = 'Widevine',
    Fairplay = 'Fairplay',
    Marlin = 'Marlin',
}

/**
 * @public
 */
export enum DownloadState {
    Queued = 'Queued', // Not currently downloading because the device is downloading other things
    Paused = 'Paused', // Not currently downloading because the user has chosen to pause it
    Downloading = 'Downloading',
    Downloaded = 'Downloaded', // Finished downloading and ready for playout
    Failed = 'Failed', // An error occurred on the download.
    Expired = 'Expired',
    Deleting = 'Deleting',
    Suspended = 'Suspended',
}

/**
 * @public
 */
export enum StreamQuality {
    HD = 'HD',
    SD = 'SD',
    UHD = 'UHD',
}

/**
 * @public
 */
export enum StreamingProtocol {
    MSS = 'MSS',
    HLS = 'HLS',
    DASH = 'DASH',
    PDL = 'PDL',
    PSF = 'PSF',
}

/**
 * @public
 */
export enum MediaContainer {
    TS = 'TS',
    PIFF = 'PIFF',
    ISOBMFF = 'ISOBMFF',
    MP4 = 'MP4',
}

export enum AdInsertionStrategy {
    SSAI = 'SSAI',
    CSAI = 'CSAI',
}

export enum DvrWindowDuration {
    Restricted = 'Restricted',
    Extended = 'Extended',
    FullEvent = 'FullEvent',
}

export enum StreamVendor {
    IStreamPlanet = 'IStreamPlanet',
    Harmonic = 'Harmonic',
    Unknown = 'Unknown',
}

export enum PlayoutDataError {
    PARENTAL_PIN_REQUIRED = 'PARENTAL_PIN_REQUIRED',
    INVALID_PIN_PROVIDED = 'INVALID_PIN_PROVIDED',
    PIN_SERVICE_DOWN = 'PIN_SERVICE_DOWN',
}

export enum AdsFailoverReason {
    Coppa = 'coppa',
    Vam = 'vam',
    MediaTailor = 'media-tailor',
    YoSpace = 'yospace',
    Killswitch = 'killswitch',
    Generic = 'generic failure',
}

export enum MultiviewParallelAudioTracks {
    DEFAULT = '4',
    TWO = '2',
}

/**
 * @public
 */
export enum VideoFormat {
    SD,
    HD,
    UHD,
}

/**
 * @public
 */
export enum VideoColourSpace {
    SDR,
    HDR10,
    HDRHLG,
    DV,
}

/**
 * @public
 */
export enum VideoCodec {
    H264,
    H265,
}

/**
 * @public
 */
export enum HdcpLevel {
    Unknown = 'Unknown',
    Disconnected = 'Disconnected',
    NoSupport = 'NoSupport',
    V1 = '1.0',
    V1_4 = '1.4',
    V2 = '2.0',
    V2_1 = '2.1',
    V2_2 = '2.2',
    V2_3 = '2.3',
}

/**
 * @public
 */
export enum WidevineSecurityLevel {
    L1,
    L2,
    L3,
    UNKNOWN,
}

/**
 * @public
 */
export enum StopReason {
    UserInput = 1,
    Error = 2,
    Finished = 3,
}
