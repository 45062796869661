import type { Ad, AdBreak, AdPosition, PartialAdBreak } from '../../addons/adverts/common';
import type { PlaybackType } from '../../core/player/playout-data';
import { sdkLogger } from '../../logger';
import { cloneAdBreak } from '../../utils/ad-break-utils';
import { checkIsManifestLinearType } from '../../utils/playback-type';

type ComputedBreak = {
    start: number;
    end: number;
    duration: number;
    contentTime: number;
    accumulatedBreakDuration: number;
    originalBreak: PartialAdBreak;
};

export class AdBreakTimeAdapter {
    private computedBreaks: Array<ComputedBreak> = [];

    constructor(private playbackType: PlaybackType) {}

    public initialise(receivedAdBreaks: Array<PartialAdBreak>): void {
        let accumulatedBreakDuration = 0;
        this.computedBreaks = receivedAdBreaks.map((adBreak) => {
            const start = this.getAdBreakPositionSeconds(adBreak);
            const end = start + this.getAdBreakLengthSec(adBreak);
            const duration = end - start;
            const contentTime = start - accumulatedBreakDuration;

            if (contentTime < 0) {
                sdkLogger
                    .withContext('AdBreakTimeAdapter')
                    .error('Unsorted ad breaks detected! Ad Breaks are expected to be sorted in order of chronological appearance.');
            }

            accumulatedBreakDuration += duration;
            return {
                start,
                end,
                duration,
                contentTime,
                accumulatedBreakDuration,
                originalBreak: adBreak,
            };
        });
    }

    public timeIncludingAdsToContentTime(timeIncludingAds: number): number {
        if (checkIsManifestLinearType(this.playbackType)) {
            return timeIncludingAds;
        }
        const seenAdverts = this.computedBreaks.filter((adBreak) => adBreak.start < timeIncludingAds);
        if (seenAdverts.length > 0) {
            const lastSeenBreak = seenAdverts[seenAdverts.length - 1];
            if (lastSeenBreak.end > timeIncludingAds) {
                return lastSeenBreak.contentTime;
            }
            return timeIncludingAds - lastSeenBreak.accumulatedBreakDuration;
        }
        return timeIncludingAds;
    }

    public contentTimeToTimeIncludingAds(contentTime: number): number {
        if (checkIsManifestLinearType(this.playbackType)) {
            return contentTime;
        }
        const seenAdverts = this.computedBreaks.filter((adBreak) => adBreak.contentTime < contentTime);
        if (seenAdverts.length > 0) {
            const lastSeenBreak = seenAdverts[seenAdverts.length - 1];
            return contentTime + lastSeenBreak.accumulatedBreakDuration;
        }
        return contentTime;
    }

    public timeIncludingAdsToAdPosition(timeIncludingAds: number): AdPosition | undefined {
        const adBreak = this.computedBreaks.find((b) => timeIncludingAds >= b.start && timeIncludingAds <= b.end);
        if (adBreak) {
            const adBreakPosition = timeIncludingAds - adBreak.start;
            let accAdDuration = 0;
            let adPosition = 0;
            let ad: Ad | undefined;

            for (let i = 0; i < adBreak.originalBreak.ads.length; i++) {
                const currentAd = adBreak.originalBreak.ads[i];
                if (accAdDuration + currentAd.expectedDuration > adBreakPosition) {
                    adPosition = adBreakPosition - accAdDuration;
                    ad = currentAd;
                    break;
                }
                accAdDuration += currentAd.expectedDuration;
            }
            return {
                adPosition,
                ad,
                adBreakPosition,
                adBreak: adBreak.originalBreak,
            };
        }
    }

    public isCurrentlyInAdBreak = (timeIncludingAds: number) => {
        const prevBreaks = this.computedBreaks.filter((adBreak) => adBreak.start <= timeIncludingAds);
        if (prevBreaks.length > 0) {
            const mostRecentBreak = prevBreaks[prevBreaks.length - 1];
            if (mostRecentBreak.end > timeIncludingAds) {
                return true;
            }
        }
        return false;
    };

    public adBreakPositionIncludingAdsToContentPosition = (adBreak: AdBreak): AdBreak => {
        const clonedAdBreak = cloneAdBreak(adBreak);
        clonedAdBreak.position = this.timeIncludingAdsToContentTime(this.getAdBreakPositionSeconds(adBreak));
        return clonedAdBreak;
    };

    public adBreakContentPositionToPositionIncludingAds = (adBreak: AdBreak): AdBreak => {
        const clonedAdBreak = cloneAdBreak(adBreak);
        clonedAdBreak.position = this.contentTimeToTimeIncludingAds(this.getAdBreakPositionSeconds(adBreak));
        return clonedAdBreak;
    };

    private getAdBreakLengthSec(adBreak: AdBreak | PartialAdBreak): number {
        return adBreak.expectedDuration ?? adBreak.ads.reduce((breakDuration, ad) => breakDuration + ad.expectedDuration, 0);
    }

    private getAdBreakPositionSeconds(adBreak: AdBreak | PartialAdBreak): number {
        return typeof adBreak.position === 'number' ? adBreak.position : 0;
    }
}
