import {
    ConnectionType,
    type AppMemory,
    type MeasurementsStore,
    type NetworkConnectionType,
    type PerfLog,
    type ProcessMemory,
    type StopMonitoringCallback,
    type SystemMemory,
} from '@sky-uk-ott/client-lib-js-device';
import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';

import type { TelemetryConfig } from '../../config/internal-config';
import { CoreVideoInternal } from '../../core-video-internal';
import { sdkLogger } from '../../logger';
import { PerfTag } from '../../utils/perf';
import type { BufferedInfo } from '../player/player-engine-item';
import type { SessionControllerInternal } from '../session-controller/session-controller-internal';
import { TelemetryEventType, TelemetryNotificationKey } from './telemetry.enums';
export { TelemetryEventType, TelemetryNotificationKey } from './telemetry.enums';

export type TimestampValue = {
    time: number;
    value: number;
};

export type MemoryUse = {
    usage: Array<TimestampValue>;
    userAgentSpecificMemory?: number;
    memoryLimit?: number;
    memoryLevel?: string;
    memoryUsageLimit?: number;
    appMemory?: AppMemory;
    systemMemory?: SystemMemory;
    processMemory?: ProcessMemory;
};

export type TelemetryNotification<T extends TelemetryNotificationKey = TelemetryNotificationKey> = { key: T; value: TelemetryNotificationTypes[T] };

export type TelemetryDataType = {
    key: keyof TelemetryNotificationTypes;
    value: number;
};

export type VstMetrics = {
    totalVst?: number;
    vacDownloadTime?: number;
    ovpDownloadTime?: number;
    playerEngineLoadingDownloadTime?: number;
    mediaTailorDownloadTime?: number;
    VAMDownloadTime?: number;
};

export type TelemetryNotificationTypes = {
    [TelemetryNotificationKey.sessionStart]?: number;
    [TelemetryNotificationKey.videoStartUp]?: number;
    [TelemetryNotificationKey.vacRequestStart]?: number;
    [TelemetryNotificationKey.vacResponseEnd]?: number;
    [TelemetryNotificationKey.ovpRequestStart]?: number;
    [TelemetryNotificationKey.ovpResponseEnd]?: number;
    [TelemetryNotificationKey.mediaTailorRequestStart]?: number;
    [TelemetryNotificationKey.mediaTailorResponseEnd]?: number;
    [TelemetryNotificationKey.trackingUrlRequestStart]?: number;
    [TelemetryNotificationKey.trackingUrlResponseEnd]?: number;
};

export type DeviceTelemetryData = {
    fps?: Array<TimestampValue>;
    memory?: MemoryUse;
    cpuUsage?: Array<TimestampValue>;
};

export type Discontinuity = Array<string>;

export type PlayerTelemetryData = {
    encodedFramerates?: number;
    decodedFrames?: number;
    droppedFrames?: number;
    corruptedFrames?: number;
    estimatedBandwidth?: number;
    nonFatalErrorCount?: number;
    manifestTimeSeconds?: number;
    manifestDownloadTimeSeconds?: number;
    manifestParseTimeSeconds?: number;
    manifestSizeBytes?: number;
    manifestPeriodCount?: number;
    manifestGapCount?: number;
    maxSegmentDuration?: number;
    drmTimeSeconds?: number;
    licenseTime?: number;
    liveLatencySeconds?: number;
    loadLatency?: number;
    currentPositionSeconds?: number;
    bufferedInfo?: BufferedInfo;
    appCpu?: number;
    systemCpu?: number;
    memory?: MemoryUse;
    discontinuity?: Discontinuity;
    offsetFromLiveEdgeSeconds?: number;
};

export type SessionLevelTelemetry = {
    hasSeekedAwayFromLiveEdge?: boolean;
};

export interface TelemetryEvent {
    sessionMilestone: TelemetryEventType;
    playerStats: PlayerTelemetryData & SessionLevelTelemetry;
    devicePerformance: DeviceTelemetryData;
    vstMetrics: VstMetrics;
    networkConnectionType: NetworkConnectionType;
}

const DATA_LOOKBACK_SECONDS = 10;

const DEFAULT_PERIODIC_MEASUREMENT_TIME_MS = 120000;

export class TelemetrySession {
    private getPlayerTelemetryData: (() => Promise<PlayerTelemetryData>) | undefined;
    private store: MeasurementsStore | undefined;
    private stopMonitoring: StopMonitoringCallback | undefined;
    private logger: Logger;
    private periodicMeasurementInterval: ReturnType<typeof setInterval> | null = null;
    private vstTelemetryData: TelemetryNotificationTypes = {};

    private periodicMeasurementPeriodMs: number = DEFAULT_PERIODIC_MEASUREMENT_TIME_MS;
    private _isInitialised = false;

    constructor(
        private sessionController: SessionControllerInternal,
        config?: TelemetryConfig
    ) {
        this.logger = sdkLogger.withContext('Telemetry');

        if (config?.periodicReportingSeconds) {
            this.periodicMeasurementPeriodMs = config.periodicReportingSeconds * 1000;
        }
    }

    public get isInitialised(): boolean {
        return this._isInitialised;
    }

    public initialise(getPlayerTelemetryData: () => Promise<PlayerTelemetryData>): void {
        if (this._isInitialised) {
            this.logger.warn('Tried to initialise Telemetry Session when it has already been initialised.');
            return;
        }

        this.getPlayerTelemetryData = getPlayerTelemetryData;
        this.stopMonitoring = CoreVideoInternal.telemetry?.startMonitoring();
        this.store = CoreVideoInternal.telemetry?.getMeasurementsStore();
        this.startPeriodicTelemetry();
        this._isInitialised = true;
    }

    public destroy(): void {
        this._isInitialised = false;
        this.stopPeriodicTelemetry();
        this.stopMonitoring?.();
    }

    public telemetryUpdate(telemetryNotification: TelemetryDataType): void {
        // Reset vstTelemetryData for every playbackSession
        if (telemetryNotification.key === 'sessionStart') {
            this.vstTelemetryData = {};
        }
        if (this.checkIsVstNotification(telemetryNotification.key)) {
            this.vstTelemetryData[telemetryNotification.key] = telemetryNotification.value;
        }
    }

    public listenToSessionEvents = (): void => {
        this.sessionController.onAdBreakStarted(() => {
            this.notifyTelemetry(TelemetryEventType.AdBreakStart);
        });
        this.sessionController.onAdBreakFinished(() => {
            this.notifyTelemetry(TelemetryEventType.AdBreakEnd);
        });

        this.sessionController.onError(() => {
            this.notifyTelemetry(TelemetryEventType.EndError);
        });
        this.sessionController.onTelemetryUpdate((event: TelemetryDataType) => {
            this.telemetryUpdate(event);
        });
    };

    private checkIsVstNotification(key: TelemetryNotificationKey) {
        const vstNotificationKeys = [
            TelemetryNotificationKey.sessionStart,
            TelemetryNotificationKey.videoStartUp,
            TelemetryNotificationKey.vacRequestStart,
            TelemetryNotificationKey.vacResponseEnd,
            TelemetryNotificationKey.ovpRequestStart,
            TelemetryNotificationKey.ovpResponseEnd,
            TelemetryNotificationKey.mediaTailorRequestStart,
            TelemetryNotificationKey.mediaTailorResponseEnd,
            TelemetryNotificationKey.trackingUrlRequestStart,
            TelemetryNotificationKey.trackingUrlResponseEnd,
        ];

        return vstNotificationKeys.includes(key);
    }

    private stopPeriodicTelemetry(): void {
        if (this.periodicMeasurementInterval) {
            clearInterval(this.periodicMeasurementInterval);
        }
    }

    private startPeriodicTelemetry(): void {
        this.periodicMeasurementInterval = setInterval(() => {
            this.notifyTelemetry(TelemetryEventType.Periodic);
        }, this.periodicMeasurementPeriodMs);
    }

    private buildFpsData(data: Array<PerfLog>): Array<TimestampValue> {
        const fps = data.filter((item) => item.tag === PerfTag.fps);
        const fpsValues = fps.map((item) => ({ time: item.t.currentTime, value: item.value! }));
        return fpsValues;
    }

    private buildCpuData(data: Array<PerfLog>): Array<TimestampValue> {
        const cpuUsage = data.filter((item) => item.tag === PerfTag.cpuUsage);
        const cpuValues = cpuUsage.map((item) => ({ time: item.t.currentTime, value: item.value! }));
        return cpuValues;
    }

    private buildDeviceTelemetryData(): DeviceTelemetryData {
        if (!CoreVideoInternal.telemetry || !this.store || this.store.perflogs.length === 0) {
            return {};
        }

        const lastElement = this.store.perflogs[this.store.perflogs.length - 1];
        const xSecondsAgo = lastElement.t.currentTime - DATA_LOOKBACK_SECONDS * 1000;
        const xSecondsData = this.store.perflogs.filter((el) => el.t.currentTime > xSecondsAgo);
        const supportedTags = CoreVideoInternal.telemetry.getPerformanceTags();
        const telemetryData: DeviceTelemetryData = {};

        if (supportedTags.has(PerfTag.fps)) {
            telemetryData.fps = this.buildFpsData(xSecondsData);
        }

        if (supportedTags.has(PerfTag.memory)) {
            telemetryData.memory = CoreVideoInternal.telemetry.getMemoryData(xSecondsData, supportedTags);
        }

        if (supportedTags.has(PerfTag.cpuUsage)) {
            telemetryData.cpuUsage = this.buildCpuData(xSecondsData);
        }

        return telemetryData;
    }

    private buildVstMetrics(): VstMetrics {
        const vstMetrics: VstMetrics = {};

        if (this.vstTelemetryData?.videoStartUp && this.vstTelemetryData?.sessionStart) {
            vstMetrics.totalVst = (this.vstTelemetryData.videoStartUp - this.vstTelemetryData.sessionStart) / 1000;
        }

        if (this.vstTelemetryData?.vacRequestStart && this.vstTelemetryData?.vacResponseEnd) {
            vstMetrics.vacDownloadTime = (this.vstTelemetryData?.vacResponseEnd - this.vstTelemetryData?.vacRequestStart) / 1000;
        }

        if (this.vstTelemetryData?.ovpRequestStart && this.vstTelemetryData?.ovpResponseEnd) {
            vstMetrics.ovpDownloadTime = (this.vstTelemetryData?.ovpResponseEnd - this.vstTelemetryData?.ovpRequestStart) / 1000;
        }

        if (this.vstTelemetryData.mediaTailorRequestStart && this.vstTelemetryData.mediaTailorResponseEnd) {
            vstMetrics.mediaTailorDownloadTime =
                (this.vstTelemetryData?.mediaTailorResponseEnd - this.vstTelemetryData?.mediaTailorRequestStart) / 1000;
        }

        if (this.vstTelemetryData.videoStartUp) {
            if (this.vstTelemetryData.trackingUrlResponseEnd) {
                vstMetrics.playerEngineLoadingDownloadTime =
                    (this.vstTelemetryData.videoStartUp - this.vstTelemetryData.trackingUrlResponseEnd) / 1000;
            } else if (this.vstTelemetryData.ovpResponseEnd) {
                vstMetrics.playerEngineLoadingDownloadTime = (this.vstTelemetryData.videoStartUp - this.vstTelemetryData.ovpResponseEnd) / 1000;
            }
        }

        if (this.vstTelemetryData.trackingUrlRequestStart && this.vstTelemetryData.trackingUrlResponseEnd) {
            vstMetrics.VAMDownloadTime = (this.vstTelemetryData.trackingUrlResponseEnd - this.vstTelemetryData.trackingUrlRequestStart) / 1000;
        }
        return vstMetrics;
    }

    private async notifyTelemetry(event: TelemetryEventType): Promise<void> {
        if (!this.getPlayerTelemetryData) {
            this.logger.warn('Telemetry Session is not initialised');
            return;
        }

        const data: TelemetryEvent = {
            sessionMilestone: event,
            networkConnectionType: CoreVideoInternal.networkStatus?.getNetworkConnectionType() || ConnectionType.Unknown,
            playerStats: {
                ...(await this.getPlayerTelemetryData()),
                hasSeekedAwayFromLiveEdge: this.sessionController.getHasSeekedFromLiveEdge(),
            },
            vstMetrics: this.buildVstMetrics(),
            devicePerformance: this.buildDeviceTelemetryData(),
        };

        this.store?.reset();

        this.logger.verbose(data);

        this.sessionController.notifyTelemetry(data);
    }
}
