import type { Device } from '@sky-uk-ott/client-lib-js-device';
import type TapeModule from '@sky-uk-ott/tape';

import { isIon, isSafariDesktop } from '../../utils/device-type';
import { TextTrackMimeType } from '../../core/player/text-track-mime-type';
import { TapeBundle } from './tape.enums';
import type { TapePlayerConfig } from '../../config/internal-config';

let tapeModule: typeof TapeModule | null = null;

export function loadTapeModuleCached(): typeof TapeModule | null {
    return tapeModule;
}

export async function loadTapeModule(
    playerConfig: TapePlayerConfig = {},
    device: Device,
    subtitlesFormat: Array<TextTrackMimeType>
): Promise<typeof TapeModule> {
    const tapeBundle: TapeBundle = getTapeBundle(playerConfig, device, subtitlesFormat);

    return getDefaultTapeBundle(tapeBundle);
}

async function getDefaultTapeBundle(tapeBundle: TapeBundle): Promise<typeof TapeModule> {
    switch (tapeBundle) {
        case TapeBundle.ALL_ES5:
            tapeModule = await import(/* webpackChunkName: "tape-es5" */ '@sky-uk-ott/tape/es5');
            break;
        case TapeBundle.DEBUG_ES5:
            tapeModule = await import(/* webpackChunkName: "tape-debug-es5" */ '@sky-uk-ott/tape/debug_es5');
            break;
        case TapeBundle.DASH_TTML_ES5:
            tapeModule = await import(/* webpackChunkName: "tape-dash-ttml-es5" */ '@sky-uk-ott/tape/dash_ttml_es5');
            break;
        case TapeBundle.DASH_TTML_CEA_ES5:
            tapeModule = await import(/* webpackChunkName: "tape-dash-ttml-cea-es5" */ '@sky-uk-ott/tape/dash_ttml-cea_es5');
            break;
        case TapeBundle.DASH_WEBVTT_ES5:
            tapeModule = await import(/* webpackChunkName: "tape-dash-webvtt-es5" */ '@sky-uk-ott/tape/dash_webvtt_es5');
            break;
        case TapeBundle.DASH_WEBVTT_CEA_ES5:
            tapeModule = await import(/* webpackChunkName: "tape-dash-webvtt-cea-es5" */ '@sky-uk-ott/tape/dash_webvtt-cea_es5');
            break;
        case TapeBundle.HLS_TTML_ES5:
            tapeModule = await import(/* webpackChunkName: "tape-hls-ttml-es5" */ '@sky-uk-ott/tape/hls_ttml_es5');
            break;
        case TapeBundle.HLS_TTML_CEA_ES5:
            tapeModule = await import(/* webpackChunkName: "tape-hls-ttml-cea-es5" */ '@sky-uk-ott/tape/hls_ttml-cea_es5');
            break;
        case TapeBundle.HLS_WEBVTT_ES5:
            tapeModule = await import(/* webpackChunkName: "tape-hls-webvtt-es5" */ '@sky-uk-ott/tape/hls_webvtt_es5');
            break;
        case TapeBundle.HLS_WEBVTT_CEA_ES5:
            tapeModule = await import(/* webpackChunkName: "tape-hls-webvtt-cea-es5" */ '@sky-uk-ott/tape/hls_webvtt-cea_es5');
            break;
        case TapeBundle.ALL_ESNEXT:
            tapeModule = await import(/* webpackChunkName: "tape-esNext" */ '@sky-uk-ott/tape/esNext');
            break;
        case TapeBundle.DEBUG_ESNEXT:
            tapeModule = await import(/* webpackChunkName: "tape-debug-esNext" */ '@sky-uk-ott/tape/debug_esNext');
            break;
        case TapeBundle.DASH_TTML_ESNEXT:
            tapeModule = await import(/* webpackChunkName: "tape-dash-ttml-esNext" */ '@sky-uk-ott/tape/dash_ttml_esNext');
            break;
        case TapeBundle.DASH_TTML_CEA_ESNEXT:
            tapeModule = await import(/* webpackChunkName: "tape-dash-ttml-cea-esNext" */ '@sky-uk-ott/tape/dash_ttml-cea_esNext');
            break;
        case TapeBundle.DASH_WEBVTT_ESNEXT:
            tapeModule = await import(/* webpackChunkName: "tape-dash-webvtt-esNext" */ '@sky-uk-ott/tape/dash_webvtt_esNext');
            break;
        case TapeBundle.DASH_WEBVTT_CEA_ESNEXT:
            tapeModule = await import(/* webpackChunkName: "tape-dash-webvtt-cea-esNext" */ '@sky-uk-ott/tape/dash_webvtt-cea_esNext');
            break;
        case TapeBundle.HLS_TTML_ESNEXT:
            tapeModule = await import(/* webpackChunkName: "tape-hls-ttml-esNext" */ '@sky-uk-ott/tape/hls_ttml_esNext');
            break;
        case TapeBundle.HLS_TTML_CEA_ESNEXT:
            tapeModule = await import(/* webpackChunkName: "tape-hls-ttml-cea-esNext" */ '@sky-uk-ott/tape/hls_ttml-cea_esNext');
            break;
        case TapeBundle.HLS_WEBVTT_ESNEXT:
            tapeModule = await import(/* webpackChunkName: "tape-hls-webvtt-esNext" */ '@sky-uk-ott/tape/hls_webvtt_esNext');
            break;
        case TapeBundle.HLS_WEBVTT_CEA_ESNEXT:
            tapeModule = await import(/* webpackChunkName: "tape-hls-webvtt-cea-esNext" */ '@sky-uk-ott/tape/hls_webvtt-cea_esNext');
            break;
    }
    return tapeModule as typeof TapeModule;
}

export function getTapeBundle(playerConfig: TapePlayerConfig, device: Device, subtitlesFormat: Array<TextTrackMimeType>): TapeBundle {
    if (Boolean(playerConfig.useDebug)) {
        if (isIon()) {
            return TapeBundle.DEBUG_ESNEXT;
        } else {
            return TapeBundle.DEBUG_ES5;
        }
    }

    let tapeBundle: TapeBundle | null = null;
    const isSafari = device ? isSafariDesktop(device.deviceInfo, device.type) : false;
    const needsTTML = subtitlesFormat.includes(TextTrackMimeType.TTML);
    const needsWebVTT = subtitlesFormat.includes(TextTrackMimeType.WebVTT);
    const needCEA = subtitlesFormat.includes(TextTrackMimeType.CEA_608) || subtitlesFormat.includes(TextTrackMimeType.CEA_708);
    if (isSafari) {
        if (needsTTML && !needsWebVTT) {
            tapeBundle = TapeBundle.HLS_TTML_ES5;
            if (needCEA) {
                tapeBundle = TapeBundle.HLS_TTML_CEA_ES5;
            }
        } else if (!needsTTML && needsWebVTT) {
            tapeBundle = TapeBundle.HLS_WEBVTT_ES5;
            if (needCEA) {
                tapeBundle = TapeBundle.HLS_WEBVTT_CEA_ES5;
            }
        } else {
            tapeBundle = TapeBundle.ALL_ES5;
        }
    } else if (isIon()) {
        if (needsTTML && !needsWebVTT) {
            tapeBundle = TapeBundle.DASH_TTML_ESNEXT;
            if (needCEA) {
                tapeBundle = TapeBundle.DASH_TTML_CEA_ESNEXT;
            }
        } else if (!needsTTML && needsWebVTT) {
            tapeBundle = TapeBundle.DASH_WEBVTT_ESNEXT;
            if (needCEA) {
                tapeBundle = TapeBundle.DASH_WEBVTT_CEA_ESNEXT;
            }
        } else {
            tapeBundle = TapeBundle.ALL_ESNEXT;
        }
    } else {
        if (needsTTML && !needsWebVTT) {
            tapeBundle = TapeBundle.DASH_TTML_ES5;
            if (needCEA) {
                tapeBundle = TapeBundle.DASH_TTML_CEA_ES5;
            }
        } else if (!needsTTML && needsWebVTT) {
            tapeBundle = TapeBundle.DASH_WEBVTT_ES5;
            if (needCEA) {
                tapeBundle = TapeBundle.DASH_WEBVTT_CEA_ES5;
            }
        } else {
            tapeBundle = TapeBundle.ALL_ES5;
        }
    }

    if (!tapeBundle) {
        tapeBundle = TapeBundle.ALL_ES5;
    }

    return tapeBundle;
}
