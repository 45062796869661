import type { AdobeMediaAnalyticsClientConfig as AdobeMediaAnalyticsAddonConfig } from '../../../addons/reporting/adobe-media-analytics-addon/adobe-media-analytics-addon';
import type { AddonConfig } from '../../internal-config';
import type { RemoteConfigSplit } from '../../sdk-config';
import type { DeclaredOptionalsAddonsConfig, InternalReportingConfig, RequiredPropertiesDefinition } from './addons-config-adapter-common';
import { genericConfigEnabledCheck } from './addons-config-adapter-common';

// Internal Config
type AdobeMediaAnalyticsInternalConfig = InternalReportingConfig['adobe'];

// Client Config when Remote Config is disabled
export type AdobeMediaAnalyticsClientConfig = AddonConfig<AdobeMediaAnalyticsAddonConfig>;
export function adaptAdobeMediaAnalyticsClientConfig(config: AdobeMediaAnalyticsClientConfig | undefined): AdobeMediaAnalyticsInternalConfig {
    return config;
}

// Server Config when Remote Config is enabled
type AdobeMediaAnalyticsRemoteServerConfig = AddonConfig<{}>;

// Client Config when Remote Config is enabled
type AdobeMediaAnalyticsRemoteClientConfig = AdobeMediaAnalyticsAddonConfig;

export type AdobeMediaAnalyticsRemoteConfigSplit = RemoteConfigSplit<AdobeMediaAnalyticsRemoteServerConfig, AdobeMediaAnalyticsRemoteClientConfig>;
export function adaptAdobeMediaAnalyticsRemoteConfigSplit(
    config: AdobeMediaAnalyticsRemoteConfigSplit
): DeclaredOptionalsAddonsConfig<AdobeMediaAnalyticsInternalConfig> {
    const requiredFields: RequiredPropertiesDefinition<AdobeMediaAnalyticsRemoteClientConfig> = {
        isDebug: null,
        adobeId: null,
        account: null,
        mediaHeartbeatTrackingServer: null,
        analyticsTrackingServer: null,
        onlineVideoPlatform: null,
        visitorId: null,
    };
    const isEnabled = genericConfigEnabledCheck(config, requiredFields, 'Adobe');
    if (!isEnabled) {
        return;
    }

    const { clientConfig } = config;
    return {
        enabled: isEnabled,
        config: {
            isDebug: clientConfig!.isDebug,
            adobeId: clientConfig!.adobeId,
            account: clientConfig!.account,
            mediaHeartbeatTrackingServer: clientConfig!.mediaHeartbeatTrackingServer,
            analyticsTrackingServer: clientConfig!.analyticsTrackingServer,
            onlineVideoPlatform: clientConfig!.onlineVideoPlatform,
            visitorId: clientConfig!.visitorId,
            pageName: clientConfig!.pageName,
            mvtOptimizely: clientConfig!.mvtOptimizely,
        },
    };
}
