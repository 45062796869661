import { parse, filter } from '@sky-uk-ott/core-video-sdk-js-external-txml/dist/txml';
import type { NonLinearAdVariant, NonLinearAdData } from '../non-linear-adverts/non-linear-ad-types';
import type { tNode } from '../../../utils/txml-types';

function nodeMatchesTagInVmapNamespace(node: tNode, tagName: string) {
    return [tagName, `vmap:${tagName}`].includes(node.tagName);
}

function adToNonLinearAdData(ad: tNode, breakStart: Array<string>, breakEnd: Array<string>): NonLinearAdData | null {
    const impressions = filter([ad], (node: tNode) => nodeMatchesTagInVmapNamespace(node, 'Impression')).map(
        (node: tNode) => node.children[0] as string
    );

    const onClicks = filter([ad], (node: tNode) => nodeMatchesTagInVmapNamespace(node, 'NonLinearClickTracking')).map(
        (node: tNode) => node.children[0] as string
    );

    const variants = filter([ad], (node: tNode) => nodeMatchesTagInVmapNamespace(node, 'NonLinear'))
        .map<[tNode, tNode]>((nonLinearNode: tNode) => [
            nonLinearNode,
            filter([nonLinearNode], (node: tNode) => nodeMatchesTagInVmapNamespace(node, 'StaticResource'))[0],
        ])
        .filter(([_, resource]: [tNode, tNode]) => !!resource)
        .map(([nonLinearNode, resource]: [tNode, tNode]) => ({
            width: parseInt(nonLinearNode.attributes['width'], 10),
            height: parseInt(nonLinearNode.attributes['height'], 10),
            mimeType: resource.attributes['creativeType'],
            resourceUri: resource.children[0] as string,
        }));

    return {
        variants: variants as Array<NonLinearAdVariant>,
        adStartedBeacons: [...new Set([...breakStart, ...impressions])] as Array<string>,
        adFinishedBeacons: [...new Set(breakEnd)],
        adClickedBeacons: [...new Set(onClicks)] as Array<string>,
    };
}

function adBreakToNonLinearAdData(adBreak: tNode): Array<NonLinearAdData> {
    const { breakStart = [], breakEnd = [] } = filter([adBreak], (node: tNode) => nodeMatchesTagInVmapNamespace(node, 'Tracking')).reduce(
        (acc: Record<string, Array<string>>, eventNode: tNode) => {
            if (!acc[eventNode.attributes['event']]) {
                acc[eventNode.attributes['event']] = [];
            }
            acc[eventNode.attributes['event']].push(eventNode.children[0] as string);

            return acc;
        }
    );

    const ads = filter([adBreak], (node: tNode) => nodeMatchesTagInVmapNamespace(node, 'Ad'));

    if (ads.length === 0) {
        // empty ad
        return [
            {
                variants: [],
                adStartedBeacons: [...new Set(breakStart)] as Array<string>,
                adClickedBeacons: [],
                adFinishedBeacons: [...new Set(breakEnd)] as Array<string>,
            },
        ];
    }

    return ads.map((ad) => adToNonLinearAdData(ad, breakStart, breakEnd)!).filter((ad) => !!ad);
}

export function extractNonLinearAdsFromVmap(vmapDocument: string): Array<NonLinearAdData> {
    const parsedVmap = (parse(vmapDocument) as Array<tNode>).filter((node: tNode) => nodeMatchesTagInVmapNamespace(node, 'VMAP'));

    return filter(parsedVmap, (node: tNode) => nodeMatchesTagInVmapNamespace(node, 'AdBreak') && node.attributes['breakType'] === 'nonlinear')
        .map(adBreakToNonLinearAdData)
        .reduce((memo, x) => [...memo, ...x], []);
}
