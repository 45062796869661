import { sdkLogger } from '../../logger';

// eslint-disable-next-line no-restricted-syntax
enum SharedRole {
    Main = 'Main',
    Alternate = 'Alternate',
    Supplementary = 'Supplementary',
    Emergency = 'Emergency',
    Karaoke = 'Karaoke',
    Unknown = 'Unknown', // When the role provided by the underlying player track API is defined but not known on one of the roles enumerators
}

// eslint-disable-next-line no-restricted-syntax
enum VideoExclusiveRole {
    Caption = 'Caption',
    Subtitle = 'Subtitle',
    Sign = 'Sign',
    Easyreader = 'Easyreader',
}

// eslint-disable-next-line no-restricted-syntax
enum TextExclusiveRole {
    Caption = 'Caption',
    Subtitle = 'Subtitle',
    Commentary = 'Commentary',
    Dub = 'Dub',
    Description = 'Description',
    Metadata = 'Metadata',
    ForcedSubtitle = 'ForcedSubtitle',
    Easyreader = 'Easyreader',
}

// eslint-disable-next-line no-restricted-syntax
enum AudioExclusiveRole {
    Commentary = 'Commentary',
    Dub = 'Dub',
    Description = 'Description',
    EnhancedAudioIntelligibility = 'EnhancedAudioIntelligibility',
    MainDesc = 'MainDesc',
    Alternative = 'Alternative',
    Translation = 'Translation',
}

// eslint-disable-next-line no-restricted-syntax
enum ApplicationExclusiveRole {
    Metadata = 'Metadata',
}

export const AudioRole = { ...AudioExclusiveRole, ...SharedRole };
export type AudioRole = keyof typeof AudioRole;

export const VideoRole = { ...VideoExclusiveRole, ...SharedRole };
export type VideoRole = keyof typeof VideoRole;

export const TextRole = { ...TextExclusiveRole, ...SharedRole };
export type TextRole = keyof typeof TextRole;

export const ApplicationRole = { ...ApplicationExclusiveRole, ...SharedRole };
export type ApplicationRole = keyof typeof ApplicationRole;

export type Role = keyof typeof TextRole | keyof typeof ApplicationRole | keyof typeof VideoRole | keyof typeof AudioRole;

/***
 *
 * Adapts DASH Track Roles to CVSDK Track Roles
 *
 ***/
export function adaptDashRole(role?: string): Role {
    // According to DASH spec, no role can be assumed to be main
    if (!role) {
        return AudioRole.Main;
    }
    let playerRole: string = role;
    if (['forced_subtitle', 'forced-subtitle'].includes(role.toLowerCase())) {
        playerRole = TextRole.ForcedSubtitle;
    } else if (role === 'public.accessibility.describes-video') {
        // HLS CHARACTERISTICS attribute e.g. CHARACTERISTICS="public.accessibility.describes-video"
        playerRole = AudioRole.Description;
    } else if (role.includes('-')) {
        const roleArray = role.split('-');
        const roleArrayUpperCase = roleArray.map((r) => r.charAt(0).toUpperCase() + r.slice(1));
        playerRole = roleArrayUpperCase.join('');
    } else {
        // Safari returning 'description' contrary to MDN spec: https://developer.mozilla.org/en-US/docs/Web/API/AudioTrack/kind
        // eliminating all variations of 'description/descriptions' and always return "Description"
        if (role.toLowerCase() === 'descriptions') {
            playerRole = TextRole.Description;
        } else {
            playerRole = role.charAt(0).toUpperCase() + role.slice(1);
        }
    }

    const allRoles = { ...TextRole, ...AudioRole, ...VideoRole, ...ApplicationRole };
    if (playerRole in allRoles) {
        return playerRole as Role;
    } else {
        sdkLogger.warn(`Player track role ${role} was unable to be adapted to Role Enum`);
    }

    return AudioRole.Unknown;
}

export function hasAudioDescriptionRole(roles?: Role[]): boolean {
    if (!roles) {
        return false;
    }
    return roles.includes(AudioRole.Description);
}
