import type { Device } from '@sky-uk-ott/client-lib-js-device';

import type { DeclaredOptionals } from '../../../utils/ts';
import type { InternalConfig } from '../../internal-config';
import type { ClientConfigSplit, RemoteConfigSplit } from '../../sdk-config';
import * as AampAdapter from './aamp-config-adapter';
import * as ShakaAdapter from './shaka-config-adapter';
import * as AndroidTvAdapter from './android-tv-config-adapter';
import type * as TapeAdapter from './tape-config-adapter';

type InternalPlayersConfig = NonNullable<InternalConfig['players']>;
type TizenClientConfig = InternalPlayersConfig['tizen'];
type WebMafClientConfig = InternalPlayersConfig['webMaf'];
type WebosClientConfig = InternalPlayersConfig['webOS'];
type YouViewClientConfig = InternalPlayersConfig['youview'];
type TheoClientConfig = InternalPlayersConfig['theo'];
type TapeClientConfig = InternalPlayersConfig['tape'];

export type SdkPlayersClientConfig = {
    aamp?: AampAdapter.AampClientConfig;
    androidTv?: AndroidTvAdapter.AndroidTvClientConfig;
    shaka?: ShakaAdapter.ShakaClientConfig;
    tizen?: TizenClientConfig;
    webMaf?: WebMafClientConfig;
    webOS?: WebosClientConfig;
    youview?: YouViewClientConfig;
    theo?: TheoClientConfig;
    tape?: TapeClientConfig;
};

export type SdkPlayersDefaultConfig = {
    androidTv?: AndroidTvAdapter.AndroidTvClientConfigSplit['defaultConfig'];
    shaka?: ShakaAdapter.ShakaClientConfigSplit['defaultConfig'];
};

export type SdkPlayersClientConfigSplit = ClientConfigSplit<SdkPlayersDefaultConfig, SdkPlayersClientConfig>;

export function adaptSdkPlayersClientConfig(config: SdkPlayersClientConfigSplit = {}, device: Device): DeclaredOptionals<InternalPlayersConfig> {
    const { clientConfig = {}, defaultConfig = {} } = config;
    return {
        androidTv: AndroidTvAdapter.adaptAndroidTvClientConfig({ defaultConfig: defaultConfig.androidTv, clientConfig: clientConfig.androidTv }),
        tizen: clientConfig.tizen,
        webMaf: clientConfig.webMaf,
        webOS: clientConfig.webOS,
        youview: clientConfig.youview,
        shaka: ShakaAdapter.adaptShakaClientConfig({ defaultConfig: defaultConfig.shaka, clientConfig: clientConfig.shaka }, device),
        aamp: AampAdapter.adaptAampClientConfig(clientConfig.aamp),
        theo: clientConfig.theo,
        tape: clientConfig.tape,
    };
}

type SdkPlayersRemoteClientConfig = {
    aamp?: AampAdapter.AampRemoteConfigSplit['clientConfig'];
    androidTv?: AndroidTvAdapter.AndroidTvRemoteConfigSplit['clientConfig'];
    shaka?: ShakaAdapter.ShakaRemoteConfigSplit['clientConfig'];
    tizen?: TizenClientConfig;
    webMaf?: WebMafClientConfig;
    webOS?: WebosClientConfig;
    youview?: YouViewClientConfig;
    theo?: TheoClientConfig;
    tape?: TapeClientConfig;
};

type SdkPlayersRemoteServerConfig = {
    aamp?: AampAdapter.AampRemoteConfigSplit['remoteConfig'];
    shaka?: ShakaAdapter.ShakaRemoteConfigSplit['remoteConfig'];
    androidTv?: AndroidTvAdapter.AndroidTvRemoteConfigSplit['remoteConfig'];
    tape?: TapeAdapter.TapeRemoteConfigSplit['remoteConfig'];
};

export type SdkPlayersRemoteConfigSplit = RemoteConfigSplit<SdkPlayersRemoteServerConfig, SdkPlayersRemoteClientConfig>;

export function adaptSdkPlayersRemoteConfigSplit(config: SdkPlayersRemoteConfigSplit, device: Device): DeclaredOptionals<InternalPlayersConfig> {
    const { clientConfig = {}, remoteConfig = {} } = config;
    return {
        aamp: AampAdapter.adaptAampRemoteConfigSplit({ remoteConfig: remoteConfig.aamp, clientConfig: clientConfig.aamp }),
        shaka: ShakaAdapter.adaptShakaRemoteConfigSplit({ remoteConfig: remoteConfig.shaka, clientConfig: clientConfig.shaka }, device),
        androidTv: AndroidTvAdapter.adaptAndroidTvRemoteConfigSplit({ remoteConfig: remoteConfig.androidTv, clientConfig: clientConfig.androidTv }),
        tizen: clientConfig.tizen,
        webMaf: clientConfig.webMaf,
        webOS: clientConfig.webOS,
        youview: clientConfig.youview,
        theo: clientConfig.theo,
        tape: clientConfig.tape,
    };
}
