type ResolutionEntry = {
    minHeight: number;
    minWidth: number;
};

type ResolutionMap = {
    SD: ResolutionEntry;
    HD: ResolutionEntry;
    UHD: ResolutionEntry;
};

export const Resolutions: ResolutionMap = {
    SD: {
        minHeight: 1,
        minWidth: 1,
    },
    HD: {
        minHeight: 720,
        minWidth: 1280,
    },
    UHD: {
        minHeight: 2160,
        minWidth: 3840,
    },
};
