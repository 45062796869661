import type { StreamProxyConfig } from '../../../config/internal-config';
import type { PlayoutData } from '../../../core/player/playout-data';
import { StreamingProtocol } from '../../../core/player/playout-data';
import { sdkLogger } from '../../../logger';
import { FileExtension } from './stream-proxy.enums';
export { FileExtension } from './stream-proxy.enums';

export class StreamProxy {
    streamingProtocolToFileExtension: { [key: string]: FileExtension } = {
        [StreamingProtocol.DASH]: FileExtension.MPD,
        [StreamingProtocol.HLS]: FileExtension.M3U8,
    };

    constructor(private config: StreamProxyConfig) {
        sdkLogger.info(`Using stream proxy: ${config.proxyHost}`);
    }

    private streamPlayoutThroughProxy(playoutData: PlayoutData, opts: { fileExtension: FileExtension }) {
        // If no protocol is set, defaults to HTTP
        const proxyHost = this.config.proxyHost.startsWith('http') ? this.config.proxyHost : `http://${this.config.proxyHost}`;

        const maxAudioTracks = this.config.maxAudioTracks ? `&maxAudioTracks=${this.config.maxAudioTracks}` : '';
        const maxSubtitleTracks = this.config.maxSubtitleTracks ? `&maxSubtitleTracks=${this.config.maxSubtitleTracks}` : '';

        return {
            ...playoutData,
            cdns: playoutData.cdns.map((cdn) => ({
                ...cdn,
                // File extension added, as some players make assumptions based on this
                url: `${proxyHost}/stream-proxy/placeholder.${opts.fileExtension}?url=${encodeURIComponent(
                    cdn.url
                )}&originalHost=${encodeURIComponent(proxyHost)}${maxAudioTracks}${maxSubtitleTracks}`,
            })),
        };
    }

    public modifyPlayout(playoutData: PlayoutData): PlayoutData {
        const streamingProtocol = playoutData.stream.protocol;
        const fileExtension = this.streamingProtocolToFileExtension[streamingProtocol];
        if (!fileExtension) {
            return playoutData;
        }

        return this.streamPlayoutThroughProxy(playoutData, { fileExtension });
    }
}
