import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';

import type { Ad, AdBreak } from '../../addons/adverts/common';
import type { PlaybackType } from '../../core/player/playout-data';
import { sdkLogger } from '../../logger';
import { AdBreakTimeAdapter } from '../../players/bolt-ons/ad-break-time-adapter';

import { toAdBreaksWithPositionIncludingAds } from './ad-break-time-conversion';
import type { AdPolicyManager } from './ad-policy-manager';
import type { InterItemAdPolicyManager } from './inter-item-ad-policy-manager';

function floor(x: number, decimalPlaces: number): number {
    const exponent = decimalPlaces;
    return Math.floor(x * 10 ** exponent) / 10 ** exponent;
}

export class AdPolicyManagerContentTimeAdapter implements InterItemAdPolicyManager {
    private adBreakTimeAdapter!: AdBreakTimeAdapter;
    private logger: Logger = sdkLogger.withContext('APMCTA');

    constructor(
        private adPolicyManager: AdPolicyManager,
        private playbackType: PlaybackType
    ) {}

    public setAdBreaksData(adBreaks: Array<AdBreak>): void {
        const adBreaksWithPositionsIncludingAds = toAdBreaksWithPositionIncludingAds(adBreaks);
        this.adBreakTimeAdapter = new AdBreakTimeAdapter(this.playbackType);
        this.adBreakTimeAdapter.initialise(adBreaksWithPositionsIncludingAds);

        this.adPolicyManager.setAdBreaksData(adBreaksWithPositionsIncludingAds);
    }

    public getAdBreaks(): Array<AdBreak> {
        if (!this.adBreakTimeAdapter) {
            return [];
        }

        const adBreaksWithPositionIncludingAds = this.adPolicyManager.getAdBreaks();
        const adBreaksWithContentPosition = adBreaksWithPositionIncludingAds.map((adBreak) =>
            this.adBreakTimeAdapter.adBreakPositionIncludingAdsToContentPosition(adBreak)
        );

        return adBreaksWithContentPosition;
    }

    public onShouldSeek(callback: (postAdSeekPosition: number) => void): void {
        this.adPolicyManager.onShouldSeek((postAdSeekPositionIncludingAds: number) => {
            if (!this.adBreakTimeAdapter) {
                this.logger.error('onShouldSeek called before setAdBreaksData was called');
                return;
            }
            callback(this.adBreakTimeAdapter.timeIncludingAdsToContentTime(postAdSeekPositionIncludingAds));
        });
    }

    public manageSeek(currentContentPosition: number, contentSeekToPosition: number, clearPostAdSeekPosition?: boolean): number {
        if (!this.adBreakTimeAdapter) {
            return contentSeekToPosition;
        }

        const currentContentPositionIncludingAds = this.adBreakTimeAdapter.contentTimeToTimeIncludingAds(currentContentPosition);
        const seekToPositionIncludingAds = this.adBreakTimeAdapter.contentTimeToTimeIncludingAds(contentSeekToPosition);

        const managedPositionIncludingAds = this.adPolicyManager.manageSeek(
            currentContentPositionIncludingAds,
            seekToPositionIncludingAds,
            clearPostAdSeekPosition
        );

        return floor(this.adBreakTimeAdapter.timeIncludingAdsToContentTime(managedPositionIncludingAds!), 2);
    }

    public seekEnded(): void {
        this.adPolicyManager.seekEnded();
    }

    public seekStarted(): void {
        this.adPolicyManager.seekStarted();
    }

    public manageStartPosition(contentTimeStartPosition: number): number {
        if (!this.adBreakTimeAdapter) {
            return contentTimeStartPosition;
        }

        const startPositionIncludingAds = this.adBreakTimeAdapter.contentTimeToTimeIncludingAds(contentTimeStartPosition);
        const managedStartPositionIncludingAds = this.adPolicyManager.manageStartPosition(startPositionIncludingAds);
        return floor(this.adBreakTimeAdapter.timeIncludingAdsToContentTime(managedStartPositionIncludingAds), 2);
    }

    public manageAdBreakStarted(adBreakWithContentPosition: AdBreak<Ad>): AdBreak<Ad> {
        if (!this.adBreakTimeAdapter) {
            this.logger.error('manageAdBreakStarted was called before setAdBreaksData was called');
            return adBreakWithContentPosition;
        }

        const adBreakWithPositionIncludingAds = this.adBreakTimeAdapter.adBreakContentPositionToPositionIncludingAds(adBreakWithContentPosition);
        const managedAdBreakWithPositionIncludingAds = this.adPolicyManager.manageAdBreakStarted(adBreakWithPositionIncludingAds);
        return this.adBreakTimeAdapter.adBreakPositionIncludingAdsToContentPosition(managedAdBreakWithPositionIncludingAds!);
    }

    public manageAdBreakFinished(adBreakWithContentPosition: AdBreak<Ad>): AdBreak<Ad> {
        if (!this.adBreakTimeAdapter) {
            this.logger.error('manageAdBreakFinished was called before setAdBreaksData was called');
            return adBreakWithContentPosition;
        }

        const adBreakWithPositionIncludingAds = this.adBreakTimeAdapter.adBreakContentPositionToPositionIncludingAds(adBreakWithContentPosition);
        const managedAdBreakWithPositionIncludingAds = this.adPolicyManager.manageAdBreakFinished(adBreakWithPositionIncludingAds);
        return this.adBreakTimeAdapter.adBreakPositionIncludingAdsToContentPosition(managedAdBreakWithPositionIncludingAds!);
    }

    public manageAdStarted(ad: Ad): Ad | null {
        return this.adPolicyManager.manageAdStarted(ad);
    }

    public manageAdFinished(ad: Ad): Ad | null {
        return this.adPolicyManager.manageAdFinished(ad);
    }

    public shouldPlayAdBreak = (adBreakWithContentPosition: AdBreak<Ad>, contentPosition: number, isResumingFromBookmark?: boolean): boolean => {
        if (!this.adBreakTimeAdapter) {
            this.logger.error('shouldPlayAdBreak was queried before ad breaks were provided');
            return false;
        }

        const adBreakWithPositionIncludingAds = this.adBreakTimeAdapter.adBreakContentPositionToPositionIncludingAds(adBreakWithContentPosition);
        const positionIncludingAds = this.adBreakTimeAdapter.contentTimeToTimeIncludingAds(contentPosition);
        return this.adPolicyManager.shouldPlayAdBreak(adBreakWithPositionIncludingAds, positionIncludingAds, isResumingFromBookmark);
    };
}
