/**
 * @public
 */
export enum PlayerType {
    Aamp = 'Aamp',
    Android = 'AndroidTV',
    WebMaf = 'WebMaf',
    YouView = 'YouView',
    Chromecast = 'Chromecast',
    Shaka = 'Shaka',
    DualPlayer = 'DualPlayer',
    HtmlVideo = 'HtmlVideo',
    Theo = 'Theo',
    Tape = 'Tape',
}
