import type { DeclaredOptionals } from '../../../utils/ts';
import type { AndroidTvPlayerConfig, InternalConfig } from '../../internal-config';
import type { ClientConfigSplit, RemoteConfigSplit } from '../../sdk-config';
import { AndroidAdaptiveBitrateStrategy } from '@sky-uk-ott/client-lib-js-device';

// Internal Config
type InternalPlayersConfig = NonNullable<InternalConfig['players']>;
type AndroidTvInternalConfig = InternalPlayersConfig['androidTv'];

// Client Config when Remote Config is disabled
export type AndroidTvDefaultConfig = Pick<NonNullable<AndroidTvInternalConfig>, 'eac3' | 'maxBitrate' | 'exclude4kTrack' | 'maxResolution'>;
export type AndroidTvClientConfig = Pick<NonNullable<AndroidTvInternalConfig>, 'adaptiveBitrateStrategy'>;
export type AndroidTvClientConfigSplit = ClientConfigSplit<AndroidTvDefaultConfig, AndroidTvClientConfig>;

export function adaptAndroidTvClientConfig(config: AndroidTvClientConfigSplit = {}): DeclaredOptionals<AndroidTvInternalConfig> {
    const { defaultConfig = {}, clientConfig = {} } = config;
    return {
        eac3: defaultConfig.eac3 || false,
        adaptiveBitrateStrategy: clientConfig.adaptiveBitrateStrategy || AndroidAdaptiveBitrateStrategy.DEFAULT,
        maxBitrate: defaultConfig.maxBitrate,
        exclude4kTrack: defaultConfig.exclude4kTrack,
        maxResolution: defaultConfig.maxResolution,
    };
}

// Server Config when Remote Config is enabled
type AndroidTvRemoteServerConfig = AndroidTvPlayerConfig;

// Client Config when Remote Config is enabled
type AndroidTvRemoteClientConfig = {};
export type AndroidTvRemoteConfigSplit = RemoteConfigSplit<AndroidTvRemoteServerConfig, AndroidTvRemoteClientConfig>;
export function adaptAndroidTvRemoteConfigSplit(config: AndroidTvRemoteConfigSplit): DeclaredOptionals<AndroidTvInternalConfig> {
    const { remoteConfig = {} } = config;
    return {
        eac3: remoteConfig.eac3,
        adaptiveBitrateStrategy: remoteConfig.adaptiveBitrateStrategy || AndroidAdaptiveBitrateStrategy.DEFAULT,
        maxBitrate: remoteConfig.maxBitrate,
        exclude4kTrack: remoteConfig.exclude4kTrack,
        maxResolution: remoteConfig.maxResolution,
    };
}
