import { DeviceType } from '@sky-uk-ott/client-lib-js-device';
import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';

import type { AdvertsManager } from '../../../addons/adverts-manager';
import type { PineManager } from '../../../addons/pine/pine-manager';
import { MseWrapManager } from '../../../addons/tools/mse-wrap/mse-wrap-manager';
import { StreamProxy } from '../../../addons/tools/stream-proxy/stream-proxy';
import type { InternalConfig } from '../../../config/internal-config';
import { CoreVideoInternal } from '../../../core-video-internal';
import { sdkLogger } from '../../../logger';
import { Observable } from '../../../utils/observables/observable';
import { PerfKey, perfLogger, PerfTag } from '../../../utils/perf';
import type { PlayerEngine } from '../../player/player-engine';
import type { PlayerEngineCreationOptions } from '../../player/player-engine-factory';
import type { PlayerEngineItem } from '../../player/player-engine-item';
import type { PlayerEngineItemPrecursor } from '../../player/player-engine-item-precursor';
import type { PlayerPrecursorEngine } from '../../player/player-precursor-engine';
import type { PlayoutData } from '../../player/playout-data';
import { PlaybackType } from '../../player/playout-data';
import { VideoColourSpace } from '../../player/video-format';
import { StaticAsset } from '../asset';
import type { SessionItem } from '../session-controller';
import type { SessionControllerInternalProxy } from '../session-controller-internal-proxy';
import type { AdvertBoltOns, DrmBoltOns } from '../timeline/timeline-manager';
import { PeacockStreamingFormatProxy } from '../../video-platform-integration/psf-proxy';

export interface VideoStartupEngineStarterOptions {
    sessionConfig: InternalConfig;
    advertsManager: AdvertsManager;
    playerPrecursorEngine?: PlayerPrecursorEngine;
    engineCreationOptions?: PlayerEngineCreationOptions;
    sessionProxy: SessionControllerInternalProxy;
    sessionId?: string;
    drmBoltons: DrmBoltOns;
    pineManager?: PineManager;
}
export class VideoStartupEngineStarter {
    private peiPrecursorErrorsObservable: Observable<string> = new Observable<string>();
    private logger: Logger = sdkLogger.withContext('Precursor');

    private peiPrecursor?: PlayerEngineItemPrecursor;
    private streamProxy?: StreamProxy;
    private _mainAsset?: StaticAsset;

    constructor(
        private sessionItem: SessionItem,
        private videoStartupEngineStarterOptions: VideoStartupEngineStarterOptions
    ) {
        if (this.videoStartupEngineStarterOptions.sessionConfig.addons?.streamProxy?.enabled) {
            const streamProxyConfig = this.videoStartupEngineStarterOptions.sessionConfig.addons.streamProxy.config;
            perfLogger.measure(PerfKey.streamProxy, PerfTag.addonInstantiationStart);
            this.streamProxy = new StreamProxy(streamProxyConfig);
            perfLogger.measure(PerfKey.streamProxy, PerfTag.addonInstantiationEnd);
        }

        const addonsFactories = CoreVideoInternal.getPropositionExtensions().addonFactories;

        if (this.videoStartupEngineStarterOptions.sessionConfig.addons?.mseWrap?.enabled) {
            new MseWrapManager(this.videoStartupEngineStarterOptions.sessionProxy, addonsFactories, this.sessionItem);
        }
    }

    public get mainAsset(): StaticAsset | undefined {
        return this._mainAsset;
    }

    public onPeiPrecursorErrorsObservable(callback: (error: string) => void): void {
        this.peiPrecursorErrorsObservable.registerObserver(callback, this);
    }

    public unregisterPeiPrecursorErrorsObservable(): void {
        this.peiPrecursorErrorsObservable.unregisterObservers(this);
    }

    private buildPeiPrecursor(playoutData: PlayoutData): PlayerEngineItemPrecursor | undefined {
        return this.videoStartupEngineStarterOptions.playerPrecursorEngine?.createPlayerItemPrecursor(
            playoutData,
            this.videoStartupEngineStarterOptions.advertsManager.getAdvertBoltOns(),
            this.videoStartupEngineStarterOptions.drmBoltons,
            this.sessionItem,
            this.videoStartupEngineStarterOptions.engineCreationOptions
        );
    }

    public async startEngine(modifiedPlayoutData: PlayoutData, isPrefetching?: boolean): Promise<PlayoutData | undefined> {
        if (this.streamProxy) {
            modifiedPlayoutData = this.streamProxy.modifyPlayout(modifiedPlayoutData);
        }

        if (this.sessionItem.enablePeacockStreamingFormatOverride) {
            modifiedPlayoutData = PeacockStreamingFormatProxy.getModifiedPlayoutData(modifiedPlayoutData);
        }

        if (this.videoStartupEngineStarterOptions.pineManager) {
            modifiedPlayoutData = this.videoStartupEngineStarterOptions.pineManager.getModifiedPlayoutData(modifiedPlayoutData);
        }

        this._mainAsset = new StaticAsset(modifiedPlayoutData);

        if (!isPrefetching) {
            this.peiPrecursor = this.buildPeiPrecursor(modifiedPlayoutData);
            return modifiedPlayoutData;
        }

        const isCsai = this.videoStartupEngineStarterOptions.advertsManager.getAdInsertionConfig()?.isTimelineAdManagementRequired;

        if (
            !this.videoStartupEngineStarterOptions.playerPrecursorEngine || // Shouldn't be prefetching if there's no player precursor engine!
            isCsai // Prefetching is not valid for CSAI
        ) {
            return Promise.resolve(undefined);
        }

        if (!modifiedPlayoutData) {
            return Promise.resolve(undefined);
        }

        this.peiPrecursor = this.buildPeiPrecursor(modifiedPlayoutData);
        if (!this.peiPrecursor) {
            return Promise.resolve(undefined);
        }

        if (modifiedPlayoutData.type === PlaybackType.Live || modifiedPlayoutData.type === PlaybackType.SingleLiveEvent) {
            return Promise.resolve(undefined);
        }

        // TODO - move this into player capabilities
        const isDolbyVisionOnXbox =
            modifiedPlayoutData.stream.colourSpace === VideoColourSpace.DV &&
            (CoreVideoInternal.deviceType === DeviceType.XboxOne || CoreVideoInternal.deviceType === DeviceType.XboxSeries);

        if (isPrefetching && isDolbyVisionOnXbox) {
            return Promise.resolve(undefined);
        }

        this.peiPrecursor!.attachPlayerErrorsHandler?.(() => {
            this.peiPrecursorErrorsObservable.notifyObservers();
        });

        if (isPrefetching) {
            await this.peiPrecursor?.prefetch();
        }

        return modifiedPlayoutData;
    }

    public createPlayerEngineItem(
        engine: PlayerEngine,
        modifiedPlayoutData: PlayoutData,
        advertBoltOns: AdvertBoltOns,
        drmBoltOns: DrmBoltOns
    ): PlayerEngineItem {
        const { sessionId } = this.videoStartupEngineStarterOptions;
        const isPlayoutWithMatchingPrecursor = this.peiPrecursor && sessionId && modifiedPlayoutData.sessionId === sessionId;
        const precursor = isPlayoutWithMatchingPrecursor ? this.peiPrecursor : undefined;
        const pei = engine.createPlayerItem(modifiedPlayoutData, advertBoltOns, drmBoltOns, this.sessionItem, precursor);

        if (isPlayoutWithMatchingPrecursor) {
            this.peiPrecursor = undefined;
        }

        return pei;
    }

    private async teardownPlayer(): Promise<void> {
        if (this.peiPrecursor) {
            try {
                this.peiPrecursor.detachPlayerErrorsHandler?.();
                this.peiPrecursor.destroy();
                this.logger.verbose('PEI Precursor Destroyed');
            } catch (e) {
                const message = `Error while destroying PEI Precursor: ${e}`;
                this.logger.warn(message, e);
                throw new Error(`PRECURSOR_PEIP.DESTROY: ${message}`);
            }
        }
    }

    public destroy() {
        this.peiPrecursorErrorsObservable.unregisterObservers(this);
        this._mainAsset = undefined;
        this.teardownPlayer();
    }
}
