import type { Device } from '@sky-uk-ott/client-lib-js-device';

import type { PlaybackType } from '../core/player/playout-data';
import type { DeclaredOptionals } from '../utils/ts';
import { adaptSdkAddonsClientConfig } from './adapters/addons-config-adapter/addons-config-adapter';
import { adaptSdkPlayersClientConfig } from './adapters/players-config-adapter/players-config-adapter';
import type { ConfigPerPlaybackType, InternalConfigDynamic } from './internal-config';
import type { SdkConfigWithoutRemoteConfig } from './sdk-config';
import { sdkLogger } from '../logger';
import { CoreVideoConfigProvider } from './core-video-config/core-video-config-provider';
import type { CoreVideoConfigCompiled } from './core-video-config/core-video-config-types';

const LOCAL_CONFIG_IDENTIFIER = 'static';

export class LocalConfigProvider {
    private coreVideoConfigProvider: CoreVideoConfigProvider;
    private sdkConfigPerPlaybackType: ConfigPerPlaybackType<SdkConfigWithoutRemoteConfig>;
    private logger = sdkLogger.withContext('Static Config Provider');

    constructor(
        private sdkConfig: SdkConfigWithoutRemoteConfig,
        private device: Device,
        compiledConfigLoader: (configPath: string) => Promise<CoreVideoConfigCompiled>
    ) {
        this.sdkConfigPerPlaybackType = { default: this.sdkConfig };
        this.coreVideoConfigProvider = new CoreVideoConfigProvider(this.sdkConfig, this.device, compiledConfigLoader);
    }

    public async initialise() {
        try {
            await this.coreVideoConfigProvider.initialise();
        } catch (defaultConfigLoadError) {
            this.logger.error('Failed to load default config with error: ', defaultConfigLoadError);
        }
    }

    public getConfig(): ConfigPerPlaybackType<SdkConfigWithoutRemoteConfig> {
        return this.sdkConfigPerPlaybackType;
    }

    public checkHasConfigChanged(lastConfigIdentifier: string): boolean {
        return lastConfigIdentifier !== LOCAL_CONFIG_IDENTIFIER;
    }

    public getFailoverReason(): string {
        return 'NA';
    }

    public buildInternalConfig(playbackType: 'default', device: Device): InternalConfigDynamic;
    public buildInternalConfig(playbackType: PlaybackType, device: Device): InternalConfigDynamic | undefined;
    public buildInternalConfig(playbackType: PlaybackType | 'default', device: Device): InternalConfigDynamic | undefined {
        const localConfig = this.sdkConfigPerPlaybackType.default;

        const defaultConfigPerPlaybackType = this.coreVideoConfigProvider.getConfig();
        let defaultConfig = defaultConfigPerPlaybackType[playbackType];

        // No playback type overrides exist in the default config
        if (!defaultConfig) {
            return;
        }

        defaultConfig = defaultConfig || defaultConfigPerPlaybackType['default'];

        const { addons, players } = localConfig;

        const addonsConfig = adaptSdkAddonsClientConfig({ defaultConfig: defaultConfig.addons, clientConfig: addons });
        const playersConfig = adaptSdkPlayersClientConfig({ defaultConfig: defaultConfig.players, clientConfig: players }, device);
        const metalistConfig = { ...localConfig.metalist, ...defaultConfig.metalist };

        const dynamicConfig: DeclaredOptionals<InternalConfigDynamic> = {
            configMetadata: {
                configIdentifier: LOCAL_CONFIG_IDENTIFIER,
                configTimestamp: `${Date.now()}`,
                appliedDeviceMatchers: defaultConfig.configMetadata.appliedDeviceMatchers,
                appliedPlaybackTypeOverride: defaultConfig.configMetadata.appliedPlaybackTypeOverride,
            },
            hasExtendedDvrSupport: defaultConfig.hasExtendedDvrSupport,
            players: playersConfig,
            addons: addonsConfig,
            prefetching: localConfig.prefetching,
            preferredStreamPropertyGroups: defaultConfig.preferredStreamPropertyGroups,
            allowedDvrWindowDurations: defaultConfig.allowedDvrWindowDurations,
            telemetry: localConfig.telemetry,
            csai: localConfig.csai,
            cmcd: localConfig.cmcd,
            performance: localConfig.performance,
            metalist: Object.keys(metalistConfig).length ? metalistConfig : undefined,
            reducedLatency: localConfig.reducedLatency,
            allowedAudioCodecs: defaultConfig.allowedAudioCodecs,
        };

        return dynamicConfig;
    }
}
