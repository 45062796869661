import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';

import { sdkLogger } from '../../logger';
import { SafeInterval } from '../safe-interval';

class TimedCacheItem<T> {
    public insertTime: number;

    constructor(public item: T) {
        this.insertTime = Date.now();
    }

    public get ageSeconds(): number {
        return (Date.now() - this.insertTime) / 1000;
    }
}

export class TimedCache<T> {
    private cache: { [key: string]: TimedCacheItem<T> } = {};
    private logger: Logger;
    private safeInterval: SafeInterval;

    constructor(
        private timeoutSeconds: number,
        cleanIntervalS: number,
        private destroyHandler: (entity: T) => void,
        private maxCapacity = 1
    ) {
        this.safeInterval = new SafeInterval({
            executor: () => {
                this.clean();
            },
            timeMs: cleanIntervalS * 1000,
        });
        this.safeInterval.start();
        this.logger = sdkLogger.withContext('TimedCache');
    }

    public destroy() {
        this.safeInterval.stop();
        this.cache = {};
    }

    public insert(key: string, item: T): void {
        if (this.isFull) {
            this.dropOldest();
        }
        const cacheItem = new TimedCacheItem(item);
        this.destroyCacheItem(key);
        this.cache[key] = cacheItem;
    }

    public get(key: string, remove?: boolean): T | undefined {
        const cacheEntry = this.cache[key];
        let item = undefined;

        if (cacheEntry) {
            if (cacheEntry.ageSeconds < this.timeoutSeconds) {
                item = cacheEntry.item;
            } else {
                remove = true;
            }
        } else {
            remove = false;
        }

        if (remove === true) {
            delete this.cache[key];
        }

        return item;
    }

    public clearCache() {
        Object.entries(this.cache).forEach(([key, value]) => {
            this.logger.verbose(`Cleanup -- Dropping cache key ${key}`);
            this.destroyCacheItem(key);
        });
    }

    public get isFull(): boolean {
        return Object.keys(this.cache).length === this.maxCapacity;
    }

    public findOldest(): string {
        const keysSortedByTime = Object.keys(this.cache).sort((a, b) => {
            return this.cache[b].ageSeconds - this.cache[a].ageSeconds;
        });
        return keysSortedByTime[0];
    }

    private dropOldest(): void {
        const oldestKey = this.findOldest();
        if (oldestKey) {
            this.logger.verbose(`Dropping oldest cache key ${oldestKey}`);
            this.destroyCacheItem(oldestKey);
        }
    }

    private clean(): void {
        Object.entries(this.cache).forEach(([key, value]) => {
            if (value.ageSeconds > this.timeoutSeconds) {
                this.logger.verbose(`Cleanup -- Dropping expired cache key ${key}`);
                this.destroyCacheItem(key);
            }
        });
    }

    private destroyCacheItem(key: string): void {
        if (!this.cache[key]) {
            return;
        }
        this.logger.verbose(`Destroying item: ${key}`);
        this.destroyHandler(this.cache[key].item);
        delete this.cache[key];
    }
}
