import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';

import type { AddonsConfig } from '../../config/internal-config';
import { CoreVideoInternal } from '../../core-video-internal';
import { getUserAgent } from '../../core/device/device-recognition';
import type { AdInsertionConfig, PlayoutData, PlayoutRect } from '../../core/player/playout-data';
import { AdsFailoverReason } from '../../core/player/playout-data';
import { TestingOverrides } from '../../core/services/testing-overrides';
import type { SessionItem } from '../../core/session-controller/session-controller';
import type { InternalSessionInterface } from '../../core/session-controller/session-controller-internal';
import { TelemetryNotificationKey } from '../../core/telemetry/telemetry-session';
import { CvsdkError, ErrorSeverity } from '../../error';
import { sdkLogger } from '../../logger';

import { PerfKey, perfLogger, PerfTag } from '../../utils/perf';
import type { AdvertisingData } from '../addon-playout-data';
import type { AdInsertionAddon } from '../addons-factories';
import type { Vac } from '../vac/vac-addon';
import type { DevicePlaybackCapabilities } from '../../propositions/proposition-extensions';
import type { DeviceModel } from '@sky-uk-ott/client-lib-js-device';
import { checkIsSessionItemSleAutoplayType } from '../../utils/playback-type';

export class AdvertsVacStageManager {
    private logger: Logger = sdkLogger.withContext('AdvertsVacStageManager');

    constructor(
        private adInsertionClientConfig: AddonsConfig['adInsertion'],
        private session: InternalSessionInterface,
        private adInsertionAddon: AdInsertionAddon | null,
        private adInsertionConfig: AdInsertionConfig,
        private isVacEnabled: boolean | null,
        private vacAddon?: Vac.Addon | null,
        private isNativeCsai?: boolean | null,
        private isPrefetch?: boolean
    ) {}

    public async fetchVacData(sessionItem: SessionItem, initialPlayoutData: PlayoutData): Promise<PlayoutData> {
        perfLogger.measure(PerfKey.root, PerfTag.preparingAdvertising);

        if (!this.adInsertionAddon && !this.isNativeCsai) {
            return initialPlayoutData;
        }

        // "Populated" playout data has additional data required for ads e.g. VAM or adsFailoverReason
        const populatedPlayoutData = await this.populatePlayoutData(initialPlayoutData, sessionItem);
        return populatedPlayoutData;
    }

    private buildVacRequestOptions(playoutData: PlayoutData, sessionItem: SessionItem): Vac.RequestOptions {
        // The playout rectangle does not exist during prefetch time
        // the only purpose of this is for pause ads
        // the mobile team just passes the full screen dimensions for prefetch
        // So this is essentially just a static 1080p resolution dimension
        const playoutRect: PlayoutRect = {
            x: 0,
            y: 0,
            width: 1920,
            height: 1080,
        };

        const propositionExtensions = CoreVideoInternal.getPropositionExtensions();
        const deviceCapabilities = propositionExtensions.devicePlaybackCapabilities;

        return {
            // CoreVideoSdk
            sdkName: CoreVideoInternal.sdkName,
            sdkVersion: CoreVideoInternal.version,

            // Player
            playerVersion: CoreVideoInternal.playerVersion,
            playerDimensions: playoutRect,
            isMiniPlayer: sessionItem.isMiniPlayer,

            // Device
            deviceType: CoreVideoInternal.deviceType,
            deviceModel: CoreVideoInternal.deviceInfo.deviceModel as DeviceModel,
            deviceVersion: CoreVideoInternal.deviceInfo.deviceVersion || CoreVideoInternal.deviceInfo.browserVersion,

            // Playout data
            adServerContentId: playoutData.advertising!.contentId,
            cdnName: playoutData.cdns[0].name, // TODO: How can we be sure this is the right CDN?
            adCompatibilityEncodingProfile: playoutData.advertising!.adCompatibilityEncodingProfile!,
            playbackType: playoutData.type,
            streamingProtocol: playoutData.stream.protocol,

            // Client data
            mvpdHash: 'D2C', // Defaulting to this value, but expected to be overridden by client
            ...sessionItem.vac,
            httpUserAgent: getUserAgent(self as unknown as Window),
            durationMs: sessionItem.vac?.durationMs ?? playoutData.durationMs,
            // @ts-ignore: this property does NOT exists in Vam.RequestOptions
            videoDurationInSeconds: this.sessionItem?.vac?.durationMs ?? playoutData.durationMs / 1000,

            // Ad Insertion Config
            adProvider: this.adInsertionConfig.adProvider,

            slePreRoll: this.getSlePreRoll(deviceCapabilities, playoutData, sessionItem),

            isPrefetch: Boolean(this.isPrefetch),

            isMultiview: sessionItem.isMultiview ?? false,
        };
    }

    private getSlePreRoll(deviceCapabilities: DevicePlaybackCapabilities, playoutData: PlayoutData, sessionItem: SessionItem): boolean {
        let slePreRoll = Boolean(deviceCapabilities.slePrerollSupported && playoutData.vamPrerollEnabled);

        if (
            sessionItem.isMultiview ||
            sessionItem.slePrerollEnabled === false /* by default they should be enabled, but we don't want to enforce this is specified */ ||
            checkIsSessionItemSleAutoplayType(sessionItem)
        ) {
            slePreRoll = false;
        }

        if (TestingOverrides.forceSlePreroll === 'Enabled') {
            slePreRoll = true;
        } else if (TestingOverrides.forceSlePreroll === 'Disabled') {
            slePreRoll = false;
        }
        return slePreRoll;
    }

    private async populatePlayoutData(playoutData: PlayoutData, sessionItem: SessionItem): Promise<PlayoutData> {
        const advertisingData = { ...playoutData.advertising } as AdvertisingData;
        const populatedPlayoutData: PlayoutData = { ...playoutData, advertising: advertisingData };

        if (this.isVacEnabled) {
            const vacRequestOptions = this.buildVacRequestOptions(playoutData, sessionItem);
            try {
                if (this.isNativeCsai) {
                    perfLogger.measure(PerfKey.vac, PerfTag.vacRequest);
                    perfLogger.measure(PerfKey.root, PerfTag.vacRequest);
                    this.session.updateTelemetryMetrics({ key: TelemetryNotificationKey.vacRequestStart, value: new Date().getTime() });
                    const nativeCsaiVac = await this.vacAddon?.fetchVacResponse(vacRequestOptions as Vac.RequestOptions);
                    this.session.updateTelemetryMetrics({ key: TelemetryNotificationKey.vacResponseEnd, value: new Date().getTime() });
                    perfLogger.measure(PerfKey.vac, PerfTag.vacResponse);
                    perfLogger.measure(PerfKey.root, PerfTag.vacResponse);
                    populatedPlayoutData.custom = Object.assign({}, populatedPlayoutData.custom, { vac: nativeCsaiVac });
                } else if (populatedPlayoutData.advertising) {
                    perfLogger.measure(PerfKey.vac, PerfTag.vacRequest);
                    perfLogger.measure(PerfKey.root, PerfTag.vacRequest);
                    this.session.updateTelemetryMetrics({ key: TelemetryNotificationKey.vacRequestStart, value: new Date().getTime() });
                    const response = await this.adInsertionAddon?.prepareAdvertising(vacRequestOptions as Vac.RequestOptions);
                    this.session.updateTelemetryMetrics({ key: TelemetryNotificationKey.vacResponseEnd, value: new Date().getTime() });
                    perfLogger.measure(PerfKey.vac, PerfTag.vacResponse);
                    perfLogger.measure(PerfKey.root, PerfTag.vacResponse);

                    if (response) {
                        populatedPlayoutData.advertising.vac = response;
                        this.notifyCompanionAdInsertionEnabled(this.shouldEnableCompanionAdInsertion(response));
                    }
                } else {
                    this.logger.warn('Missing Advertising Data');
                }
            } catch (e) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const { status, message } = e as any;
                const warning = CvsdkError.from({
                    code: 'SDK.ADVERTSMGR.SETUP_ADS',
                    message: 'Failed to fetch VAC',
                    severity: ErrorSeverity.Warning,
                    cause: e,
                });
                this.logger.warn(warning);

                this.session.notifyWarning(`${warning.code}.VAM_${status ?? 'UNKNOWN'}`, message || 'Unknown error occurred');

                // TODO: NBCU VAC specific logic should be shifted to the VAC Addon
                // https://github.com/sky-uk/core-video-team/issues/7817
                if (status === 204) {
                    populatedPlayoutData.adsFailoverReason = AdsFailoverReason.Coppa;
                } else {
                    populatedPlayoutData.adsFailoverReason = AdsFailoverReason.Vam;
                }

                populatedPlayoutData.vamFailureStatus = status;
                if (!status) {
                    // Blocking request on dev tools yields status as undefined. keep it at 500 for consistency
                    populatedPlayoutData.vamFailureStatus = 500;
                }

                return populatedPlayoutData;
            }
        } else {
            populatedPlayoutData.advertising!.custom = { ...advertisingData.custom, ...this.getCustomAdvertisingReportingData(sessionItem) };
        }

        populatedPlayoutData.adInsertionConfig = this.adInsertionConfig;

        return populatedPlayoutData;
    }

    private getCustomAdvertisingReportingData(sessionItem: SessionItem): {
        csid: string | undefined;
        vcid2: string;
    } {
        const { siteSection, fwVcid2 } = this.adInsertionClientConfig?.yospace ?? {};
        const userId = sessionItem?.user?.ids?.yospace?.profileid;
        return {
            csid: siteSection,
            vcid2: `${fwVcid2}:${userId}`,
        };
    }

    private notifyCompanionAdInsertionEnabled(shouldEnableCompanionAdInsertion: boolean) {
        this.session.notifyCompanionAdInsertionEnabled(shouldEnableCompanionAdInsertion);
    }
    private shouldEnableCompanionAdInsertion(vacResponse: Vac.ResponseData): boolean {
        if (!vacResponse || !vacResponse.keyValues) {
            return false;
        }
        const { bl_enabled, fa_enabled, ma_enabled } = vacResponse.keyValues;
        if (bl_enabled && bl_enabled === 'true' && ((fa_enabled && fa_enabled === 'true') || (ma_enabled && ma_enabled === 'true'))) {
            return true;
        }

        return false;
    }
}
