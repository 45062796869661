// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepFreeze = <T extends Record<string, any>>(obj: T): Readonly<T> => {
    Object.keys(obj).forEach((prop) => {
        if (typeof obj[prop] === 'object') {
            deepFreeze(obj[prop]);
        }
    });
    return Object.freeze(obj);
};

export function getMinimum<T>(arr: Array<T>, mapToNum: (target: T) => number): T {
    return arr.reduce((prev, curr) => (mapToNum(prev) < mapToNum(curr) ? prev : curr));
}

export function getMaximum<T>(arr: Array<T>, mapToNum: (target: T) => number): T {
    return arr.reduce((prev, curr) => (mapToNum(prev) > mapToNum(curr) ? prev : curr));
}

export function typesMatch<T1, T2>(a: T1, b: T2): boolean {
    return typeof a === typeof b;
}

export function isPrimitive<T>(a: T): boolean {
    const t = typeof a;

    return ['string', 'number', 'boolean'].indexOf(t) > -1;
}

export function deepEqual<T>(obj1: T, obj2: T, loopNum: number = 1): boolean {
    // If both objects are pointing to the exact same reference, return true and exit early
    if (obj1 === obj2) {
        return true;
    }
    // If either of the objects are of a different type or are null, then return false
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
        return false;
    }

    if (loopNum > 5) {
        throw new Error(
            'Max number of iterations reached. This may be due to a circular reference, consider preprocessing before performing this comparison.'
        );
    }

    // Get the keys of both objects.
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    // Check if the number of keys is the same.
    if (keys1.length !== keys2.length) {
        return false;
    }

    // Iterate through the keys and compare their values recursively.
    for (const key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key as keyof T], obj2[key as keyof T], loopNum + 1)) {
            return false;
        }
    }
    // If all checks pass, the objects are deep equal.
    return true;
}
