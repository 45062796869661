import type { ErrorReason, ErrorSeverity, ErrorCategory, ErrorCreationParams, ErrorParameters } from './index';

/**
 * Base Error Class
 *
 * Errors that extend CvsdkError must also set their prototype like
 *      Object.setPrototypeOf(this, ExtendedErrorClassName.prototype);
 * in order to use instanceof properly.
 */
export class CvsdkError extends Error {
    constructor(
        public code: string,
        public message: string,
        public severity: ErrorSeverity,
        public cause?: unknown,
        public category?: ErrorCategory,
        public reason?: ErrorReason,
        public parameters?: ErrorParameters,
        public originalSeverity?: ErrorSeverity
    ) {
        super(message);
        Object.setPrototypeOf(this, CvsdkError.prototype); // restore prototype chain
        this.name = `CvsdkError: ${this.code}`;
    }

    /** Create a CvsdkError from an object of parameters rather than parameters */
    public static from({ code, message, severity, cause, category, reason, parameters, originalSeverity }: ErrorCreationParams): CvsdkError {
        return new CvsdkError(code, message, severity, cause, category, reason, parameters, originalSeverity);
    }
}
