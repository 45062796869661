//cspell:words eidr

/**
 * Table 21 segmentation_upid_type
 */
export enum SegmentationUpidType {
    NOT_USED = 0,
    USER_DEFINED = 1,
    ISCI = 2,
    AD_ID = 3,
    UMID = 4,
    ISAN = 5,
    VISAN = 6,
    TID = 7,
    TI = 8,
    ADI = 9,
    EIDR = 10,
    ATSC = 11,
    MPU = 12,
    MID = 13,
    ADS = 14,
    URI = 15,
}

export enum SpliceDescriptorTag {
    AVAIL_DESCRIPTOR = 0,
    DTMF_DESCRIPTOR = 1,
    SEGMENTATION_DESCRIPTOR = 2,
    TIME_DESCRIPTOR = 3,
}

export enum SegmentationTypeId {
    NOT_INDICATED = 0,
    CONTENT_IDENTIFICATION = 1,
    PROGRAM_START = 16,
    PROGRAM_END = 17,
    PROGRAM_EARLY_TERMINATION = 18,
    PROGRAM_BREAKAWAY = 19,
    PROGRAM_RESUMPTION = 20,
    PROGRAM_RUNOVER_PLANNED = 21,
    PROGRAM_RUNOVER_UNPLANNED = 22,
    PROGRAM_OVERLAP_START = 23,
    PROGRAM_BLACKOUT_OVERRIDE = 24,
    PROGRAM_START_IN_PROGRESS = 25,
    CHAPTER_START = 32,
    CHAPTER_END = 33,
    PROVIDER_ADVERTISEMENT_START = 48,
    PROVIDER_ADVERTISEMENT_END = 49,
    DISTRIBUTOR_ADVERTISEMENT_START = 50,
    DISTRIBUTOR_ADVERTISEMENT_END = 51,
    PROVIDER_PLACEMENT_OPPORTUNITY_START = 52,
    PROVIDER_PLACEMENT_OPPORTUNITY_END = 53,
    DISTRIBUTOR_PLACEMENT_OPPORTUNITY_START = 54,
    DISTRIBUTOR_PLACEMENT_OPPORTUNITY_END = 55,
    UNSCHEDULED_EVENT_START = 64,
    UNSCHEDULED_EVENT_END = 65,
    NETWORK_START = 80,
    NETWORK_END = 81,
}
