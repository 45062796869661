import type { AdInsertionConfig } from '../../../internal-config';
import type { RemoteConfigSplit } from '../../../sdk-config';
import type { DeclaredOptionals } from '../../../../utils/ts';
import type { LocalVodAdvertsConfig, VamVodAdvertsConfig, VodAdvertsConfig } from '../../../../addons/adverts/vod-adverts/base';
import { AdsConfigProvider } from '../../../../addons/adverts/vod-adverts/base';
import type { RequiredPropertiesDefinition } from '../addons-config-adapter-common';
import { checkRequiredConfigProperties } from '../addons-config-adapter-common';

// Internal Config
type MultiPlayerCsaiInternalConfig = AdInsertionConfig['multiPlayerCsai'];

// Client Config when Remote Config is disabled
export type MultiPlayerCsaiClientConfig = VodAdvertsConfig;
export function adaptMultiPlayerCsaiClientConfig(config: MultiPlayerCsaiClientConfig | undefined): MultiPlayerCsaiInternalConfig {
    return config;
}

type VamVodAdvertsRemoteConfigProperties = 'type' | 'adsConfigProvider';
// Server Config when Remote Config is enabled
type VamVodAdvertsRemoteServerConfig = Pick<VamVodAdvertsConfig, VamVodAdvertsRemoteConfigProperties>;

// Client Config when Remote Config is enabled
type VamVodAdvertsRemoteClientConfig = Omit<VamVodAdvertsConfig, VamVodAdvertsRemoteConfigProperties>;

type VamVodAdvertsRemoteConfigSplit = RemoteConfigSplit<VamVodAdvertsRemoteServerConfig, VamVodAdvertsRemoteClientConfig>;
function adaptVamVodAdvertsRemoteConfigSplit(config: VamVodAdvertsRemoteConfigSplit): DeclaredOptionals<MultiPlayerCsaiInternalConfig> {
    const { remoteConfig } = config;
    if (!remoteConfig) {
        return;
    }

    return {
        type: remoteConfig.type,
        adsConfigProvider: remoteConfig.adsConfigProvider,
    };
}

type LocalVodAdvertsRemoteConfigProperties = 'type' | 'adsConfigProvider';
// Server Config when Remote Config is enabled
type LocalVodAdvertsRemoteServerConfig = Pick<LocalVodAdvertsConfig, LocalVodAdvertsRemoteConfigProperties>;

// Client Config when Remote Config is enabled
type LocalVodAdvertsRemoteClientConfig = Omit<LocalVodAdvertsConfig, LocalVodAdvertsRemoteConfigProperties>;

type LocalVodAdvertsRemoteConfigSplit = RemoteConfigSplit<LocalVodAdvertsRemoteServerConfig, LocalVodAdvertsRemoteClientConfig>;
function adaptLocalVodAdvertsRemoteConfigSplit(config: LocalVodAdvertsRemoteConfigSplit): DeclaredOptionals<MultiPlayerCsaiInternalConfig> {
    const { remoteConfig, clientConfig } = config;
    if (!remoteConfig) {
        return;
    }

    const requiredClientFields: RequiredPropertiesDefinition<LocalVodAdvertsRemoteClientConfig> = {
        profile: null,
        server: null,
    };

    const missingProperties = checkRequiredConfigProperties(clientConfig || {}, requiredClientFields);
    if (missingProperties.length > 0) {
        return;
    }

    return {
        type: remoteConfig.type,
        adsConfigProvider: remoteConfig.adsConfigProvider,
        profile: clientConfig!.profile,
        server: clientConfig!.server,
    };
}

export type MultiPlayerCsaiRemoteServerConfig<T extends AdsConfigProvider = AdsConfigProvider> = T extends AdsConfigProvider.Vam
    ? VamVodAdvertsRemoteServerConfig
    : T extends AdsConfigProvider.Local
      ? LocalVodAdvertsRemoteServerConfig
      : VamVodAdvertsRemoteServerConfig | LocalVodAdvertsRemoteServerConfig;
export type MultiPlayerCsaiRemoteClientConfig<T extends AdsConfigProvider = AdsConfigProvider> = T extends AdsConfigProvider.Vam
    ? VamVodAdvertsRemoteClientConfig
    : T extends AdsConfigProvider.Local
      ? LocalVodAdvertsRemoteClientConfig
      : VamVodAdvertsRemoteClientConfig | LocalVodAdvertsRemoteClientConfig;
export type MultiPlayerCsaiRemoteConfigSplit<T extends AdsConfigProvider = AdsConfigProvider> = RemoteConfigSplit<
    MultiPlayerCsaiRemoteServerConfig<T>,
    MultiPlayerCsaiRemoteClientConfig<T>
>;

export function adaptMultiPlayerCsaiRemoteConfigSplit(config: MultiPlayerCsaiRemoteConfigSplit): DeclaredOptionals<MultiPlayerCsaiInternalConfig> {
    const { remoteConfig } = config;
    if (!remoteConfig || !remoteConfig.adsConfigProvider) {
        return;
    }

    switch (remoteConfig.adsConfigProvider) {
        case AdsConfigProvider.Local:
            return adaptLocalVodAdvertsRemoteConfigSplit(config as LocalVodAdvertsRemoteConfigSplit);
        case AdsConfigProvider.Vam:
            return adaptVamVodAdvertsRemoteConfigSplit(config as VamVodAdvertsRemoteConfigSplit);
        default:
            return;
    }
}
