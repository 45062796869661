import type { Subtitle, SubtitlesSegment } from './subtitles-types';

export function checkIsInRange(startPosition: number, endPosition: number, element: Subtitle | SubtitlesSegment): boolean {
    const elementEndPosition = element.startPosition + element.duration;
    return element.startPosition >= startPosition && elementEndPosition < endPosition;
}

export function checkIsInBounds(position: number, element: Subtitle | SubtitlesSegment): boolean {
    const endPosition = element.startPosition + element.duration;
    return position >= element.startPosition && position < endPosition;
}

export function parseTimeColonFormat(time: string | null): number | null {
    if (!time) {
        return null;
    }

    // Regex matching a time with colon in between group
    // First group matches hours, with a minimum of two digits
    // Second group matches minutes with two digits
    // Third group matches seconds and milliseconds
    // Example of time: 3633:07:37.025
    const results = /^(?:(\d{2,}):)?(\d{2}):(\d{2}\.\d{2,})$/.exec(time);

    if (!results || results[0] === '') {
        return null;
    }

    const hours = Number(results[1]) || 0;
    const minutes = Number(results[2]) || 0;
    const seconds = Number(results[3]) || 0;
    const milliseconds = Number(results[4]) || 0;

    return milliseconds / 1000 + seconds + minutes * 60 + hours * 3600;
}

export const VTT_TEXT_MIMETYPES = ['text/vtt', 'text/vtt; codecs="vtt"', 'text/vtt; codecs="wvtt"'];
export const TTML_TEXT_MIMETYPES = ['application/ttml+xml'];
export const MP4_VTT_MIMETYPES = ['application/mp4; codecs="wvtt"'];
export const MP4_TTML_MIMETYPES = [
    'application/mp4; codecs="stpp"',
    'application/mp4; codecs="stpp.ttml.im1t"',
    'application/mp4; codecs="stpp.TTML.im1t"',
];
export const TEXT_MIMETYPES = [...VTT_TEXT_MIMETYPES, ...TTML_TEXT_MIMETYPES, ...MP4_VTT_MIMETYPES, ...MP4_TTML_MIMETYPES];
