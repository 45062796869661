export enum AsidVersion {
    V3 = 'V3',
    V4 = 'V4',
}

export enum SecurityError {
    NO_ISSUE = 0,
    IMAGE_HASH_FAIL = 1,
    SIGNATURE_MISMATCH = 2,
    TIMING_ISSUE = 3,
    SERVER_TIMEOUT = 4,
    COULD_NOT_CONNECT = 5,
    TERMINATE_REQUESTED = 6,
    INVALID_REQUEST = 7,
    INVALID_RESPONSE = 8,
    UNEXPECTED_RESPONSE = 9,
    INVALID_TOKEN = 10,
    DOM_MANIPULATION = 100,
}
