import { TrackingEventType } from '../../../addons/adverts/common';
import type { JsonAdBreakTrackingEvent } from './ad-breaks-provider.enums';
export { JsonAdBreakTrackingEvent } from './ad-breaks-provider.enums';

export interface JsonAdTrackingMetadata {
    eventType: TrackingEventType;
    beaconUrls: Array<string>;
}

export const JsonAdBreakTrackingEventMap: { [key in JsonAdBreakTrackingEvent]: TrackingEventType } = {
    BREAK_START: TrackingEventType.BreakStart,
    BREAK_END: TrackingEventType.BreakEnd,
    breakStart: TrackingEventType.BreakStart,
    breakEnd: TrackingEventType.BreakEnd,
};

export interface JsonAdSlotTrackingMetadata {
    eventType: JsonAdBreakTrackingEvent;
    eventId?: string;
    beaconUrls: Array<string>;
}

export interface JsonAdTrackingMetadata {
    eventType: TrackingEventType;
    eventId?: string;
    beaconUrls: Array<string>;
}

export interface JsonAdExtensionMetadata {
    type: string;
    content: string;
}

export interface JsonAdVerificationsMetadata {
    vendor: string; // The home page URL for the verification service provider that supplies the resource file
    javaScriptResource: Array<JavaScriptResource>; // Describes a JavaScript resource used to collect verification data
    verificationParameters: string;
}

export interface JavaScriptResource {
    apiFramework: string; // the name of the API framework
    uri: string; // the uri to this resource
}

export interface JsonAdMetadata {
    adId: string;
    vastAdId: string;
    adTitle: string;
    creativeId: string;
    startTime: string; // Time position in ISO 8601 seconds format, relative to the beginning of the playback session
    startTimeInSeconds: number; // Time position in seconds format, relative to the beginning of the playback session
    duration: string; // Length in ISO 8601 seconds format
    durationInSeconds: number; // Length in seconds format
    companionAds: Array<JsonCompanionAds>; // TODO: Parse the data for non linear ads when they are available on Json
    extensions: Array<JsonAdExtensionMetadata>;
    trackingEvents: Array<JsonAdTrackingMetadata>;
    adVerifications?: Array<JsonAdVerificationsMetadata>;
    mediaFile?: string;
    adSystem?: string;
    skipOffset?: number | null;
}

export interface JsonAdBreakMetadata {
    availId: string;
    startTime: string; // Time position in ISO 8601 seconds format, relative to the beginning of the playback session
    startTimeInSeconds: number; // Time position in seconds format, relative to the beginning of the playback session
    duration: string; // Length in ISO 8601 seconds format
    durationInSeconds: number; // Length in seconds format
    ads: Array<JsonAdMetadata> | Array<JsonNonLinearAdListMetadata>;
    adBreakTrackingEvents?: Array<JsonAdSlotTrackingMetadata>;
}

export interface JsonNonLinearAdMetadata {
    adId: string;
    adTitle: string;
    creativeId: string;
    durationInSeconds?: number;
    height: string;
    width: string;
    staticResource: string;
    staticResourceCreativeType: string;
    clickTracking: string;
    adSystem?: string;
}

export interface JsonNonLinearAdListMetadata {
    nonLinearAdList: Array<JsonNonLinearAdMetadata>;
    extensions: Array<JsonAdExtensionMetadata>;
    trackingEvents: Array<JsonAdTrackingMetadata>;
    adVerifications?: Array<JsonAdVerificationsMetadata>;
}

export interface JsonNonLinearAdBreakMetadata {
    availId: string;
    nonLinearAdsList: Array<JsonNonLinearAdListMetadata>;
    adBreakTrackingEvents: Array<JsonAdSlotTrackingMetadata>;
    startTime: string; // Time position in ISO 8601 seconds format, relative to the beginning of the playback session
    startTimeInSeconds: number; // Time position in seconds format, relative to the beginning of the playback session
}

export interface JsonAdBreaks {
    nextToken: string | null;
    avails: Array<JsonAdBreakMetadata>;
    nonLinearAvails: Array<JsonNonLinearAdBreakMetadata>;
}

export interface JsonCompanionAds {
    adParameters: Object;
    altText: string;
    attributes: {
        // Defined by the VAST spec
        width: string;
        height: string;
        id: string;
        assetWidth: string;
        assetHeight: string;
        expandedWidth: string;
        expandedHeight: string;
        apiFramework: string;
        adSlotId: string; // Current VAST Spec says `adSlotId` but Yospace comply with older spec using `adSlotID`
        pxratio: string;
        renderingMode?: 'default' | 'end-card' | 'concurrent';
    };
    companionClickThrough: string;
    companionClickTracking: string;
    htmlResource: string;
    iFrameResource: string;
    staticResource: string;
    sequence: string;
    trackingEvents: Array<JsonAdTrackingMetadata>;
}

export interface JsonCompanionAdsTracking {
    url: string;
    type: string;
}
