import type { Device, DeviceInformation } from '@sky-uk-ott/client-lib-js-device';
import { DeviceType } from '@sky-uk-ott/client-lib-js-device';

import { CoreVideoInternal } from '../core-video-internal';
import { getLgWebOsVersion } from './device-type';

// Optional device parameter so that this function can be used during initialisation
export function isOldWebOs(device?: Device): boolean {
    let deviceType: DeviceType;
    let deviceInfo: DeviceInformation;
    if (device) {
        deviceType = device.type;
        deviceInfo = device.deviceInfo;
    } else {
        deviceType = CoreVideoInternal.deviceType;
        deviceInfo = CoreVideoInternal.deviceInfo;
    }

    if (deviceType !== DeviceType.WebOS) {
        return false;
    }
    return getLgWebOsVersion(deviceInfo) <= 3;
}
