import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';

import type { AssetMetadata } from '../../addons/addon-playout-data';
import type { AdvertsManager } from '../../addons/adverts-manager';
import type { Ad, AdBreak, AdPosition } from '../../addons/adverts/common';
import type {
    CompanionAdOpportunityStartedEvent,
    CompanionAdOpportunityEndedEvent,
} from '../../addons/adverts/companion-adverts/companion-advert-dispatcher';
import type { NonLinearAdEvent } from '../../addons/adverts/non-linear-adverts/non-linear-ad-types';
import type { AdAssetGroup } from '../../addons/adverts/vod-adverts/vod-adverts-addon';
import type { Proposition } from '../../config/internal-config';
import { CoreVideoInternal } from '../../core-video-internal';
import type { CvsdkError } from '../../error';
import { sdkLogger } from '../../logger';
import type { ThumbnailRenderInfo, ThumbnailVariant } from '../../players/player-extensions/thumbnails/thumbnail-types';
import type { Observable } from '../../utils/observables/observable';
import { createLoggingObservable } from '../../utils/observables/verbose-logging-observable';
import type { AudioFormat } from '../player/audio-format';
import type { HdcpLevel, VideoCodec, VideoColourSpace, VideoFormat, WidevineSecurityLevel } from '../player/video-format';
import type {
    BitRateLevel,
    CdnSwitchEvent,
    LiveWindow,
    PlayerHttpRequest,
    PlayerHttpResponse,
    StreamMetadata,
    SubtitleCue,
    TimedMetadata,
    VideoTrack,
} from '../player/player-engine-item';
import type { Track } from '../player/track';
import type { PlayoutData, PlayerBitrateLimits, PlayoutRules } from '../player/playout-data';
import type { TelemetryDataType, TelemetryEvent } from '../telemetry/telemetry-session';

import type { BoundaryEvent } from './event-boundary';
import type { ManifestReceivedEvent } from './manifest-received-event';
import type { SessionControllerPrecursor } from './precursor/session-controller-precursor';
import type { PinRequiredCause } from './session-controller';
import type { VideoStartupStates } from './video-startup/video-startup-states';
import type { InternalSessionState } from './internal-session-state';
import type { InternalSessionInterface, PlaybackTimelineInternal, SessionControllerInternal } from './session-controller-internal';
import type { CatchupSeekEvent } from '../../propositions/ad-policy/ad-policy-manager';
import type { BitrateCapData } from '../meta-list-manager/bitrate-cap-manager';
import type { EventCueCallback, EventCue, OnEventCueRegisteredCallback, OnEventCueUpdatedCallback } from '../events/event-types';
import type { ItuQaSessionData } from '@sky-uk-ott/core-video-sdk-js-itu-quality-assessment';
import type { ObserverPriority } from '../../utils/observables/observable.enums';
import type { WatermarkData } from '../../addons/watermarking/base';

type WarningLog = {
    errorCode: string;
    reason: string;
};

type WaitForStatePendingResolver = {
    state: InternalSessionState;
    resolver: () => void;
};

export interface EventTransformers {
    onStateChanged?: Array<(state?: InternalSessionState | null) => InternalSessionState>;
    onError?: Array<(error?: CvsdkError | null) => CvsdkError>;
    onSessionEnded?: Array<() => void>;
}

export class SessionControllerInternalProxy implements InternalSessionInterface {
    private eventTransformersToRegister: Required<EventTransformers> = {
        onStateChanged: [],
        onError: [],
        onSessionEnded: [],
    };
    private sessionControllerInternal?: SessionControllerInternal;
    private logger: Logger = sdkLogger.withContext('SCIP');
    private adBreakDataReceivedObservable: Observable<Array<AdBreak>> = createLoggingObservable<Array<AdBreak>>('AdBreakDataReceived', this.logger);
    private adTrackingReceivedObservable: Observable<Array<AdBreak>> = createLoggingObservable<Array<AdBreak>>('AdTrackingReceived', this.logger);
    private adBreakStartedObservable: Observable<AdBreak> = createLoggingObservable<AdBreak>('AdBreakStarted', this.logger);
    private adBreakFinishedObservable: Observable<AdBreak> = createLoggingObservable<AdBreak>('AdBreakFinished', this.logger);
    private adStartedObservable: Observable<Ad> = createLoggingObservable<Ad>('AdStarted', this.logger);
    private adFinishedObservable: Observable<Ad> = createLoggingObservable<Ad>('AdFinished', this.logger);
    private adPositionObservable: Observable<AdPosition> = createLoggingObservable<AdPosition>('AdPositionChanged', this.logger);
    private catchupSeekObservable: Observable<CatchupSeekEvent> = createLoggingObservable<CatchupSeekEvent>('CatchupSeek', this.logger);
    private sessionStateObservable: Observable<InternalSessionState> = createLoggingObservable<InternalSessionState>('SessionState', this.logger);
    private rebufferStartObservable: Observable<void> = createLoggingObservable<void>('BufferingStarted', this.logger);
    private rebufferEndObservable: Observable<void> = createLoggingObservable<void>('BufferingEnded', this.logger);
    private errorObservable: Observable<CvsdkError> = createLoggingObservable<CvsdkError>('Error', this.logger);
    private manifestReceivedObservable: Observable<ManifestReceivedEvent> = createLoggingObservable<ManifestReceivedEvent>(
        'ManifestReceived',
        this.logger
    );
    private isMutedObservable: Observable<boolean> = createLoggingObservable<boolean>('MuteChanged', this.logger);
    private playStartObservable: Observable<void> = createLoggingObservable('PlayStart', this.logger);
    private seekStartedObservable: Observable<number> = createLoggingObservable<number>('SeekStarted', this.logger);
    private seekEndedObservable: Observable<void> = createLoggingObservable('SeekEnded', this.logger);
    private timedMetadataReceivedObservable: Observable<TimedMetadata> = createLoggingObservable<TimedMetadata>('TimedMetadataReceived', this.logger);
    private bulkTimedMetadataReceivedObservable: Observable<Array<TimedMetadata>> = createLoggingObservable<Array<TimedMetadata>>(
        'BulkTimedMetadataReceived',
        this.logger
    );
    private playbackTimelineObservable: Observable<PlaybackTimelineInternal> = createLoggingObservable<PlaybackTimelineInternal>(
        'PlaybackTimelineUpdated',
        this.logger
    );
    private audioTrackWillChangeObservable: Observable<Track['id']> = createLoggingObservable<Track['id']>('AudioTrackWillChange', this.logger);
    private bitrateChangedObservable: Observable<BitRateLevel> = createLoggingObservable<BitRateLevel>('BitrateChanged', this.logger);
    private encodedFrameRateChangedObservable: Observable<number> = createLoggingObservable<number>('EncodedFrameRateChanged', this.logger);
    private renderedFrameRateChangedObservable: Observable<number> = createLoggingObservable<number>('RenderedFrameRateChanged', this.logger);
    private videoTrackChangedObservable: Observable<VideoTrack> = createLoggingObservable<VideoTrack>('VideoTrackChanged', this.logger);
    private qualityFailoverObservable: Observable<VideoFormat> = createLoggingObservable<VideoFormat>('QualityFailover', this.logger);
    private cdnSwitchObservable: Observable<CdnSwitchEvent> = createLoggingObservable<CdnSwitchEvent>('CdnSwitch', this.logger);
    private warningObservable: Observable<CvsdkError> = createLoggingObservable<CvsdkError>('Warning', this.logger);
    private pinRequiredObservable: Observable<PinRequiredCause> = createLoggingObservable<PinRequiredCause>('PinRequired', this.logger);
    private pinSuccessObservable: Observable<void> = createLoggingObservable('PinSuccess', this.logger);
    private autoPlayPolicyPreventedPlaybackObservable: Observable<void> = createLoggingObservable('AutoPlayPolicyPreventedPlayback', this.logger);
    private assetMetadataUpdateObservable: Observable<AssetMetadata> = createLoggingObservable<AssetMetadata>('AssetMetadata', this.logger);
    private playoutDataObservable: Observable<PlayoutData> = createLoggingObservable<PlayoutData>('PlayoutDataReceived', this.logger);
    private playoutRulesObservable: Observable<PlayoutRules> = createLoggingObservable<PlayoutRules>('PlayoutRulesReceived', this.logger);
    private audioTrackChangedObservable: Observable<Track['id']> = createLoggingObservable<Track['id']>('AudioTrackChanged', this.logger);
    private bitrateCapChangedObservable: Observable<BitrateCapData> = createLoggingObservable<BitrateCapData>('BitrateCapChanged', this.logger);
    private bitrateCapRequestedObservable: Observable<BitrateCapData> = createLoggingObservable<BitrateCapData>('BitrateCapChanged', this.logger);
    private ituQaSessionDataObservable: Observable<ItuQaSessionData> = createLoggingObservable<ItuQaSessionData>('ItuQaSessionData', this.logger);

    private availableAudioTracksChangedObservable: Observable<Array<Track>> = createLoggingObservable<Array<Track>>(
        'AvailableAudioTracksChanged',
        this.logger
    );
    private telemetryObservable: Observable<TelemetryEvent> = createLoggingObservable<TelemetryEvent>('TelemetryEvent', this.logger);
    private availableSubtitlesTracksChangedObservable: Observable<Array<Track>> = createLoggingObservable<Array<Track>>(
        'AvailableSubtitlesTracksChanged',
        this.logger
    );
    private streamMetadataReceivedObservable: Observable<StreamMetadata> = createLoggingObservable<StreamMetadata>(
        'StreamMetadataReceived',
        this.logger
    );
    private subtitlesTrackChangedObservable: Observable<Track> = createLoggingObservable<Track>('SubtitlesTrackChanged', this.logger);
    private subtitleCuesChangedObservable: Observable<Array<SubtitleCue>> = createLoggingObservable<Array<SubtitleCue>>(
        'SubtitleCuesChanged',
        this.logger
    );
    private volumeChangedObservable: Observable<number> = createLoggingObservable<number>('VolumeChanged', this.logger);
    private eventBoundaryObservable: Observable<BoundaryEvent> = createLoggingObservable<BoundaryEvent>('EventBoundary', this.logger);
    private sessionEndedObservable: Observable<void> = createLoggingObservable('SessionEnded', this.logger);
    private appBackgroundedObservable: Observable<void> = createLoggingObservable<void>('AppBackgrounded', this.logger);
    private appForegroundedObservable: Observable<InternalSessionState> = createLoggingObservable<InternalSessionState>(
        'AppForegrounded',
        this.logger
    );
    private videoElementCreatedObservable: Observable<HTMLVideoElement> = createLoggingObservable<HTMLVideoElement>(
        'VideoElementCreated',
        this.logger
    );
    private fullscreenChangedObservable: Observable<boolean> = createLoggingObservable<boolean>('FullscreenChanged', this.logger);
    private clickedObservable: Observable<void> = createLoggingObservable<void>('Clicked', this.logger);
    private availableThumbnailVariantsChangedObservable: Observable<Array<ThumbnailVariant>> = createLoggingObservable<Array<ThumbnailVariant>>(
        'AvailableThumbnailVariantsChanged',
        this.logger
    );
    private telemetryUpdateObservable: Observable<TelemetryDataType> = createLoggingObservable<TelemetryDataType>(
        'telemetryUpdateObservable',
        this.logger
    );
    private endOfEventMarkerReceivedObservable: Observable<number> = createLoggingObservable<number>('EndOfEventMarkerReceived', this.logger);

    private pauseAdObservable: Observable<NonLinearAdEvent> = createLoggingObservable<NonLinearAdEvent>('PauseAd', this.logger);
    private companionAdInsertionEnabledObservable: Observable<boolean> = createLoggingObservable<boolean>('CompanionAdInsertionEnabled', this.logger);
    private companionAdOpportunityStartedObservable: Observable<CompanionAdOpportunityStartedEvent> =
        createLoggingObservable<CompanionAdOpportunityStartedEvent>('CompanionAdOpportunityStarted', this.logger);
    private companionAdOpportunityEndedObservable: Observable<CompanionAdOpportunityEndedEvent> =
        createLoggingObservable<CompanionAdOpportunityEndedEvent>('CompanionAdOpportunityEnded', this.logger);

    private renderWatermarkObservable: Observable<WatermarkData> = createLoggingObservable<WatermarkData>('RenderWatermark', this.logger);
    private clearWatermarkObservable: Observable<WatermarkData> = createLoggingObservable<WatermarkData>('ClearWatermark', this.logger);
    private clearWatermarksObservable: Observable<void> = createLoggingObservable<void>('ClearWatermarks', this.logger);

    private cachedAdBreaks: Array<AdBreak> | null = null;
    private warnings: Array<WarningLog> = [];
    private waitForStatePendingResolvers: Array<WaitForStatePendingResolver> = [];
    private sessionStarted = false;

    private sessionRetryStartedObservable: Observable<CvsdkError> = createLoggingObservable<CvsdkError>('SessionRetryStarted', this.logger);
    private sessionRetryEndedObservable: Observable<CvsdkError> = createLoggingObservable<CvsdkError>('SessionRetryEnded', this.logger);

    private sessionRestartStartedObservable: Observable<void> = createLoggingObservable<void>('SessionRestartStarted', this.logger);
    private sessionRestartEndedObservable: Observable<void> = createLoggingObservable<void>('SessionRestartEnded', this.logger);

    private httpRequestObservable: Observable<PlayerHttpRequest> = createLoggingObservable<PlayerHttpRequest>('HttpRequestEvent', this.logger);
    private httpResponseObservable: Observable<PlayerHttpResponse> = createLoggingObservable<PlayerHttpResponse>('HttpResponseEvent', this.logger);

    constructor() {}

    // Create a wrapper for the observable that is able to transform instead of having it in every observable
    public setEventTransformers(eventTransformers: EventTransformers): void {
        if (eventTransformers.onStateChanged && eventTransformers.onStateChanged.length > 0) {
            this.eventTransformersToRegister.onStateChanged.push(...eventTransformers.onStateChanged);
        }

        if (eventTransformers.onError && eventTransformers.onError.length > 0) {
            this.eventTransformersToRegister.onError.push(...eventTransformers.onError);
        }

        if (eventTransformers.onSessionEnded && eventTransformers.onSessionEnded.length > 0) {
            this.eventTransformersToRegister.onSessionEnded.push(...eventTransformers.onSessionEnded);
        }
    }

    public notifySessionRetryStarted(error: CvsdkError): void {
        this.sessionRetryStartedObservable.notifyObservers(error);
    }

    public notifySessionRetryEnded(error: CvsdkError): void {
        this.sessionRetryEndedObservable.notifyObservers(error);
    }

    public notifySessionRestartStarted(): void {
        this.sessionRestartStartedObservable.notifyObservers();
    }

    public notifySessionRestartEnded(): void {
        this.sessionRestartEndedObservable.notifyObservers();
    }

    public notifyItuQaSessionData(ituQaSessionData: ItuQaSessionData): void {
        this.ituQaSessionDataObservable.notifyObservers(ituQaSessionData);
    }

    public notifySessionEnded(): void {
        this.sessionEndedObservable.notifyObservers();
        this.sessionEndedObservable.unregisterObservers(this);
    }

    public notifyAdBreakDataReceived(adBreaks: Array<AdBreak>): void {
        if (this.sessionControllerInternal && this.sessionStarted) {
            this.sessionControllerInternal.notifyAdBreakDataReceived(adBreaks);
        } else {
            this.cachedAdBreaks = adBreaks;
        }
    }

    public notifyAdTrackingReceived(adBreaks: Array<AdBreak>): void {
        if (this.sessionControllerInternal && this.sessionStarted) {
            this.sessionControllerInternal.notifyAdTrackingReceived(adBreaks);
        }
    }

    public notifyCatchupSeek(catchupSeek: CatchupSeekEvent): void {
        this.warnIfNoSession();
        this.sessionControllerInternal?.notifyCatchupSeek(catchupSeek);
    }

    public getAdvertsManager(): AdvertsManager | undefined {
        return this.sessionControllerInternal?.getAdvertsManager();
    }

    public notifyAdBreakFinished(adBreak: AdBreak): void {
        this.warnIfNoSession();
        this.sessionControllerInternal?.notifyAdBreakFinished(adBreak);
    }

    public notifyAdBreakStarted(adBreak: AdBreak): void {
        this.warnIfNoSession();
        this.sessionControllerInternal?.notifyAdBreakStarted(adBreak);
    }

    public notifyAdFinished(adData: Ad): void {
        this.warnIfNoSession();
        this.sessionControllerInternal?.notifyAdFinished(adData);
    }

    public notifyAdPositionChanged(adPosition: AdPosition): void {
        this.warnIfNoSession();
        this.sessionControllerInternal?.notifyAdPositionChanged(adPosition);
    }

    public notifyTelemetry(telemetryEvent: TelemetryEvent): void {
        this.warnIfNoSession();
        this.sessionControllerInternal?.notifyTelemetry(telemetryEvent);
    }

    public getContentTimePosition(): number | undefined {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.getContentTimePosition();
    }

    public notifyAdStarted(adData: Ad): void {
        this.warnIfNoSession();
        this.sessionControllerInternal?.notifyAdStarted(adData);
    }

    public notifyPauseAd(ad: NonLinearAdEvent): void {
        this.warnIfNoSession();
        this.sessionControllerInternal?.notifyPauseAd(ad);
    }

    public notifyCompanionAdInsertionEnabled(companionAdInsertionEnabled: boolean): void {
        this.warnIfNoSession();
        this.sessionControllerInternal?.notifyCompanionAdInsertionEnabled(companionAdInsertionEnabled);
    }

    public notifyCompanionAdOpportunityStarted(ad: CompanionAdOpportunityStartedEvent): void {
        this.warnIfNoSession();
        this.sessionControllerInternal?.notifyCompanionAdOpportunityStarted(ad);
    }

    public notifyCompanionAdOpportunityEnded(ad: CompanionAdOpportunityEndedEvent): void {
        this.warnIfNoSession();
        this.sessionControllerInternal?.notifyCompanionAdOpportunityEnded(ad);
    }

    public notifyTimelineAssetsAvailable(assetGroups: Array<AdAssetGroup>): void {
        this.warnIfNoSession();
        this.sessionControllerInternal?.notifyTimelineAssetsAvailable(assetGroups);
    }

    public notifyWarning(errorCode: string, reason: string): void {
        if (this.sessionControllerInternal && this.sessionStarted) {
            this.flushStoredWarnings();
            this.sessionControllerInternal.notifyWarning(errorCode, reason);
        } else {
            if (!this.warnings) {
                this.warnings = [];
            }
            this.warnings.push({ errorCode, reason });
        }
    }

    public notifyRebufferStart(): void {
        this.warnIfNoSession();
        this.sessionControllerInternal?.notifyRebufferStart();
    }

    public notifyRebufferEnd(): void {
        this.warnIfNoSession();
        this.sessionControllerInternal?.notifyRebufferEnd();
    }

    public onStreamMetadataReceived(callback: (metadata: StreamMetadata) => void): void {
        this.streamMetadataReceivedObservable.registerObserver(callback, this);
    }

    public onSubtitlesTrackChanged(callback: (track?: Track) => void): void {
        this.subtitlesTrackChangedObservable.registerObserver(callback, this);
    }

    public onSubtitleCuesChanged(callback: (subtitleCues: Array<SubtitleCue>) => void): void {
        this.subtitleCuesChangedObservable.registerObserver(callback, this);
    }

    public onVolumeChanged(callback: (volume: number) => void): void {
        this.volumeChangedObservable.registerObserver(callback, this);
    }

    public onEventBoundary(callback: (eventBoundary: BoundaryEvent) => void): void {
        this.eventBoundaryObservable.registerObserver(callback, this);
    }

    public onAudioTrackChanged(callback: (trackId: Track['id']) => void): void {
        this.audioTrackChangedObservable.registerObserver(callback, this);
    }

    public onBitrateCapChanged(callback: (bitrateCap: BitrateCapData) => void): void {
        this.bitrateCapChangedObservable.registerObserver(callback, this);
    }

    public onBitrateCapRequested(callback: (bitrateCap: BitrateCapData) => void): void {
        this.bitrateCapRequestedObservable.registerObserver(callback, this);
    }

    public onItuQaSessionData(callback: (sessionData: ItuQaSessionData) => void): void {
        this.ituQaSessionDataObservable.registerObserver(callback, this);
    }

    public onAvailableAudioTracksChanged(callback: (audioTracks: Array<Track>) => void): () => void {
        return this.availableAudioTracksChangedObservable.registerObserver(callback, this);
    }

    public onAvailableThumbnailVariantsChanged(callback: (thumbnailVariants: Array<ThumbnailVariant>) => void): void {
        this.availableThumbnailVariantsChangedObservable.registerObserver(callback, this);
    }

    public onTelemetry(callback: (telemetry: TelemetryEvent) => void): void {
        this.telemetryObservable.registerObserver(callback, this);
    }

    public onTelemetryUpdate(callback: (telemetryUpdate: TelemetryDataType) => void): void {
        this.telemetryUpdateObservable.registerObserver(callback, this);
    }

    public updateTelemetryMetrics(telemetryUpdate: TelemetryDataType): void {
        this.warnIfNoSession();
        this.sessionControllerInternal?.updateTelemetryMetrics(telemetryUpdate);
    }

    public onHttpRequest(callback: (httpRequest: PlayerHttpRequest) => void): void {
        this.httpRequestObservable.registerObserver(callback, this);
    }

    public onHttpResponse(callback: (httpResponse: PlayerHttpResponse) => void): void {
        this.httpResponseObservable.registerObserver(callback, this);
    }

    public onEndOfEventMarkerReceived(callback: (eventTime: number) => void): void {
        this.endOfEventMarkerReceivedObservable.registerObserver(callback, this);
    }

    public onAvailableSubtitlesTracksChanged(callback: (subtitleTracks: Array<Track>) => void): () => void {
        return this.availableSubtitlesTracksChangedObservable.registerObserver(callback, this);
    }

    public onSessionEnded(callback: () => void): void {
        this.sessionEndedObservable.registerObserver(callback, this);
    }

    public onPinRequired(callback: (cause: PinRequiredCause) => void): void {
        this.pinRequiredObservable.registerObserver(callback, this);
    }

    public onPinSuccess(callback: () => void): void {
        this.pinSuccessObservable.registerObserver(callback, this);
    }

    public onAutoPlayPolicyPreventedPlayback(callback: () => void): void {
        this.autoPlayPolicyPreventedPlaybackObservable.registerObserver(callback, this);
    }

    public onPlayoutDataReceived(callback: (playoutData: PlayoutData) => void): void {
        this.playoutDataObservable.registerObserver(callback, this);
    }

    public onPlayoutRulesReceived(callback: (playoutRules: PlayoutRules) => void): void {
        this.playoutRulesObservable.registerObserver(callback, this);
    }

    public onAssetMetadataUpdated(callback: (assetMetadata: AssetMetadata) => void): void {
        this.assetMetadataUpdateObservable.registerObserver(callback, this);
    }

    public onAudioTrackWillChange(callback: (trackId: Track['id']) => void): void {
        this.audioTrackWillChangeObservable.registerObserver(callback, this);
    }

    public onPauseAd(callback: (event: NonLinearAdEvent) => void): void {
        this.pauseAdObservable.registerObserver(callback, this);
    }

    public onVerifyCompanionAdInsertionEnabled(callback: (companionAdInsertionEnabled: boolean) => void): void {
        this.companionAdInsertionEnabledObservable.registerObserver(callback, this);
    }

    public onCompanionAdOpportunityStarted(callback: (event: CompanionAdOpportunityStartedEvent) => void): void {
        this.companionAdOpportunityStartedObservable.registerObserver(callback, this);
    }

    public onCompanionAdOpportunityEnded(callback: (event: CompanionAdOpportunityEndedEvent) => void): void {
        this.companionAdOpportunityEndedObservable.registerObserver(callback, this);
    }

    public onRenderWatermark(callback: (event: WatermarkData) => void): void {
        this.renderWatermarkObservable.registerObserver(callback, this);
    }

    public onClearWatermark(callback: (event: WatermarkData) => void): void {
        this.clearWatermarkObservable.registerObserver(callback, this);
    }

    public onClearWatermarks(callback: () => void): void {
        this.clearWatermarksObservable.registerObserver(callback, this);
    }

    public onBitrateChanged(callback: (bitrateLevel: BitRateLevel) => void): void {
        this.bitrateChangedObservable.registerObserver(callback, this);
    }

    public onEncodedFrameRateChanged(callback: (frameRate: number) => void): void {
        this.encodedFrameRateChangedObservable.registerObserver(callback, this);
    }

    public onRenderedFrameRateChanged(callback: (frameRate: number) => void): void {
        this.renderedFrameRateChangedObservable.registerObserver(callback, this);
    }

    public onVideoTrackChanged(callback: (videoTrack: VideoTrack) => void): void {
        this.videoTrackChangedObservable.registerObserver(callback, this);
    }

    public onQualityFailover(callback: (videoFormat: VideoFormat) => void): void {
        this.qualityFailoverObservable.registerObserver(callback, this);
    }

    public onCdnSwitch(callback: (cdnSwitch: CdnSwitchEvent) => void): void {
        this.cdnSwitchObservable.registerObserver(callback, this);
    }

    public onWarning(callback: (error: CvsdkError) => void): void {
        this.warningObservable.registerObserver(callback, this);
    }

    public onAdBreakStarted(callback: (adBreak: AdBreak) => void): void {
        this.adBreakStartedObservable.registerObserver(callback, this);
    }

    public onAdBreakFinished(callback: (adBreak: AdBreak) => void): void {
        this.adBreakFinishedObservable.registerObserver(callback, this);
    }

    public onAdStarted(callback: (ad: Ad) => void): void {
        this.adStartedObservable.registerObserver(callback, this);
    }

    public onAdFinished(callback: (ad: Ad) => void): void {
        this.adFinishedObservable.registerObserver(callback, this);
    }

    public onAdBreakDataReceived(callback: (adBreaks: Array<AdBreak>) => void): void {
        this.adBreakDataReceivedObservable.registerObserver(callback, this);
    }

    public onAdTrackingReceived(callback: (adBreaks: Array<AdBreak>) => void): void {
        this.adTrackingReceivedObservable.registerObserver(callback, this);
    }

    public onCatchupSeek(callback: (catchupSeek: CatchupSeekEvent) => void): void {
        this.catchupSeekObservable.registerObserver(callback, this);
    }

    public onAdPositionChanged(callback: (adPosition: AdPosition) => void): void {
        this.adPositionObservable.registerObserver(callback, this);
    }

    public onError(callback: (error: CvsdkError) => void): void {
        this.errorObservable.registerObserver(callback, this);
    }

    public onManifestReceived(callback: (manifestReceived: ManifestReceivedEvent) => void): void {
        this.manifestReceivedObservable.registerObserver(callback, this);
    }

    public onMuteChanged(callback: (isMuted: boolean) => void): void {
        this.isMutedObservable.registerObserver(callback, this);
    }

    public onSeekEnded(callback: (_: void, currentPosition?: number) => void): void {
        this.seekEndedObservable.registerObserver(callback, this);
    }

    public onSeekStarted(callback: (positionMs: number, currentPosition?: number) => void): void {
        this.seekStartedObservable.registerObserver(callback, this);
    }

    public onPlayStart(callback: (_: void, currentPosition?: number) => void): void {
        this.playStartObservable.registerObserver(callback, this);
    }

    public onStateChanged(callback: (state: InternalSessionState, currentPosition?: number) => void, priority?: ObserverPriority): void {
        this.sessionStateObservable.registerObserver(callback, this, { priority });
    }

    public onRebufferStart(callback: (_: void, currentPosition?: number) => void): void {
        this.rebufferStartObservable?.registerObserver(callback, this);
    }

    public onRebufferEnd(callback: (_: void, currentPosition?: number) => void): void {
        this.rebufferEndObservable?.registerObserver(callback, this);
    }

    public onSessionRetryStarted(callback: (error: CvsdkError) => void): void {
        this.sessionRetryStartedObservable.registerObserver(callback, this);
    }

    public onSessionRetryEnded(callback: (error: CvsdkError) => void): void {
        this.sessionRetryEndedObservable.registerObserver(callback, this);
    }

    public onSessionRestartStarted(callback: () => void): void {
        this.sessionRestartStartedObservable.registerObserver(callback, this);
    }

    public onSessionRestartEnded(callback: () => void): void {
        this.sessionRestartEndedObservable.registerObserver(callback, this);
    }

    public onTimedMetadataReceived(callback: (metadata: TimedMetadata) => void): () => void {
        return this.timedMetadataReceivedObservable.registerObserver(callback, this);
    }

    public onBulkTimedMetadataReceived(callback: (metadatas: Array<TimedMetadata>) => void): void {
        this.bulkTimedMetadataReceivedObservable.registerObserver(callback, this);
    }

    public onPlaybackTimelineUpdated(callback: (playbackTimeline: PlaybackTimelineInternal) => void): void {
        this.playbackTimelineObservable.registerObserver(callback, this);
    }

    public onAppBackgrounded(callback: () => void): void {
        this.appBackgroundedObservable.registerObserver(callback, this);
    }

    public onAppForegrounded(callback: () => void): void {
        this.appForegroundedObservable.registerObserver(callback, this);
    }

    public onVideoElementCreated(callback: (videoElement: HTMLVideoElement) => void): void {
        this.videoElementCreatedObservable.registerObserver(callback, this);
    }

    public onFullscreenChanged(callback: (isFullscreen: boolean) => void): void {
        this.fullscreenChangedObservable.registerObserver(callback, this);
    }

    public onClicked(callback: () => void): void {
        this.clickedObservable.registerObserver(callback, this);
    }

    public onUserWaitStarted(callback: (currentPosition?: number, absolutePosition?: Date) => void): void {
        this.warnIfNoSession();
        this.sessionControllerInternal?.onUserWaitStarted(callback);
    }

    public onUserWaitEnded(callback: () => void): void {
        this.warnIfNoSession();
        this.sessionControllerInternal?.onUserWaitEnded(callback);
    }

    public handleSessionStarted(): void {
        this.sessionStarted = true;
        this.flushStoredAdBreaks();
        this.flushStoredWarnings();
    }

    public registerCue<T>(cue: EventCue<T>): string {
        this.warnIfNoSession();
        const id = this.sessionControllerInternal?.registerCue(cue);
        return id ?? '';
    }

    public unregisterCue(cueId: string): boolean {
        this.warnIfNoSession();
        const cueFound = this.sessionControllerInternal?.unregisterCue(cueId);
        return cueFound ?? false;
    }

    public onEventCueEntered(type: string, callback: EventCueCallback): void {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.onEventCueEntered(type, callback);
    }

    public onCueUpdated(type: string, callback: OnEventCueUpdatedCallback): void {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.onEventCueUpdated(type, callback);
    }

    public onEventCueExited(type: string, callback: EventCueCallback): void {
        this.warnIfNoSession();
        return this.sessionControllerInternal!.onEventCueExited(type, callback);
    }

    public onEventCueRegistered(type: string, callback: OnEventCueRegisteredCallback): void {
        this.warnIfNoSession();
        return this.sessionControllerInternal!.onEventCueRegistered(type, callback);
    }

    public onEventCueUnregistered(type: string, callback: EventCueCallback): void {
        this.warnIfNoSession();
        return this.sessionControllerInternal!.onEventCueUnregistered(type, callback);
    }

    public attachSession(sessionController: SessionControllerInternal): void {
        this.sessionControllerInternal = sessionController;
        this.attachWaitForStateResolvers();
        this.attachSessionEvents();
        sessionController.setEventTransformers(this.eventTransformersToRegister);
    }

    public getProposition(): Proposition {
        return CoreVideoInternal.config.staticConfig.proposition;
    }

    public get playerVersion(): string {
        return CoreVideoInternal.playerVersion;
    }

    public getPrecursor(): SessionControllerPrecursor | undefined {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.getPrecursor();
    }

    public getPrefetchStage(): VideoStartupStates | undefined {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.getPrefetchStage();
    }

    public isPrefetchedSession(): boolean {
        this.warnIfNoSession();
        return Boolean(this.sessionControllerInternal?.isPrefetchedSession());
    }

    public async start(): Promise<void> {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.start();
    }

    public async stop(): Promise<void> {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.stop();
    }

    public play(): void {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.play();
    }

    public pause(): void {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.pause();
    }

    public async seekToDate(seekDate: Date): Promise<void> {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.seekToDate(seekDate);
    }

    public seek(contentTime: number): void {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.seek(contentTime);
    }

    public async seekToLiveEdge(): Promise<void> {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.seekToLiveEdge();
    }

    public async seekToLiveStart(): Promise<void> {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.seekToLiveStart();
    }

    public async isAtLiveEdge(): Promise<boolean> {
        this.warnIfNoSession();
        return Boolean(this.sessionControllerInternal?.isAtLiveEdge());
    }

    public isLinearScrubbingSupported(): boolean {
        this.warnIfNoSession();
        return Boolean(this.sessionControllerInternal?.isLinearScrubbingSupported());
    }

    public setVolume(volume: number): void {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.setVolume(volume);
    }

    public setMute(isMuted: boolean): void {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.setMute(isMuted);
    }

    public setPlayerBitrateLimits(playerBitrateLimits: PlayerBitrateLimits): void {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.setPlayerBitrateLimits(playerBitrateLimits);
    }

    public getMaxVideoFormat(): VideoFormat | undefined {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.getMaxVideoFormat();
    }

    public getSupportedColourSpaces(): Array<VideoColourSpace> | undefined {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.getSupportedColourSpaces();
    }

    public getSupportedAudioFormats(): Array<AudioFormat> | undefined {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.getSupportedAudioFormats();
    }

    public getSupportedMaxHdcpLevel(): HdcpLevel | undefined {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.getSupportedMaxHdcpLevel();
    }

    public getConnectedHdcpLevel(): HdcpLevel | undefined {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.getConnectedHdcpLevel();
    }

    public getWidevineSecurityLevel(): WidevineSecurityLevel | undefined {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.getWidevineSecurityLevel();
    }

    public getSupportedVideoCodecs(): Array<VideoCodec> | undefined {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.getSupportedVideoCodecs();
    }

    public resetPlayerBitrateLimits(): void {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.resetPlayerBitrateLimits();
    }

    public enableSubtitles(trackId: Track['id']): void {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.enableSubtitles(trackId);
    }

    public disableSubtitles(): void {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.disableSubtitles();
    }

    public setAudioTrack(trackId: Track['id']): void {
        this.warnIfNoSession();
        return this?.sessionControllerInternal?.setAudioTrack(trackId);
    }

    public setUserWaitStarted(): void {
        this.warnIfNoSession();
        this.sessionControllerInternal?.setUserWaitStarted();
    }

    public setUserWaitEnded(): void {
        this.warnIfNoSession();
        this.sessionControllerInternal?.setUserWaitEnded();
    }

    public waitForState(state: InternalSessionState): Promise<void> {
        this.warnIfNoSession();

        if (this.sessionControllerInternal) {
            return this.sessionControllerInternal.waitForState(state);
        } else {
            return new Promise((resolve) => {
                this.waitForStatePendingResolvers.push({
                    state,
                    resolver: resolve,
                });
            });
        }
    }

    public waitForOneOfStates(states: Array<InternalSessionState>): Promise<void> {
        this.warnIfNoSession();
        if (this.sessionControllerInternal) {
            return this.sessionControllerInternal.waitForOneOfStates(states);
        }
        return Promise.resolve();
    }

    public async requestSessionStop(error: CvsdkError): Promise<void> {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.requestSessionStop(error);
    }

    public isFinished(): boolean {
        this.warnIfNoSession();
        if (this.sessionControllerInternal) {
            return Boolean(this.sessionControllerInternal.isFinished());
        }
        return true;
    }

    public restartSession(): void {
        this.warnIfNoSession();

        this.sessionControllerInternal?.restartSession();
    }

    public retrySession(): void {
        // Added here for type safety. Implemented in the Chromecast sender session controller only
        this.logger.warn(`Method 'retrySession' is not implemented`);
    }

    public isBlockingUserInput(): boolean {
        this.warnIfNoSession();
        if (this.sessionControllerInternal) {
            return Boolean(this.sessionControllerInternal.isBlockingUserInput());
        }
        return true;
    }

    public cancelPin(): void {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.cancelPin();
    }

    public async setPin(pin: string): Promise<void> {
        this.warnIfNoSession();
        this.pinRequiredObservable.reset();
        return this.sessionControllerInternal?.setPin(pin);
    }

    public setThumbnailVariant(variantId: string): Promise<boolean | undefined> {
        this.warnIfNoSession();
        if (this.sessionControllerInternal?.setThumbnailVariant) {
            return this.sessionControllerInternal.setThumbnailVariant(variantId);
        }
        return Promise.resolve(undefined);
    }

    public async getThumbnailForTime(contentTime: number, velocitySecondsPerSecond?: number): Promise<ThumbnailRenderInfo | null> {
        this.warnIfNoSession();
        if (!this.sessionControllerInternal) {
            return null;
        }
        return this.sessionControllerInternal.getThumbnailForTime(contentTime, velocitySecondsPerSecond);
    }

    public isSeekToDateSupported(): boolean {
        this.warnIfNoSession();
        return Boolean(this.sessionControllerInternal?.isSeekToDateSupported());
    }

    public async getLiveWindow(): Promise<LiveWindow | null> {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.getLiveWindow() || null;
    }

    public notifyAssetMetadataUpdated(assetMetadata: AssetMetadata): void {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.notifyAssetMetadataUpdated(assetMetadata);
    }

    public notifyEventBoundary(event: BoundaryEvent): void {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.notifyEventBoundary(event);
    }

    public notifyEndOfEventMarkerReceived(eventTime: number): void {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.notifyEndOfEventMarkerReceived(eventTime);
    }

    public notifyStreamMetadataReceived(metadata: StreamMetadata): void {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.notifyStreamMetadataReceived(metadata);
    }

    public notifyError(error: CvsdkError): void {
        this.warnIfNoSession();
        return this.sessionControllerInternal?.notifyError(error);
    }

    public destroy(): void {
        this.removeEventListeners();
        this.videoElementCreatedObservable.reset();
        this.sessionControllerInternal = undefined;
    }

    private removeEventListeners(): void {
        this.streamMetadataReceivedObservable.unregisterObservers(this);
        this.subtitlesTrackChangedObservable.unregisterObservers(this);
        this.subtitleCuesChangedObservable.unregisterObservers(this);
        this.volumeChangedObservable.unregisterObservers(this);
        this.eventBoundaryObservable.unregisterObservers(this);
        this.audioTrackChangedObservable.unregisterObservers(this);
        this.availableAudioTracksChangedObservable.unregisterObservers(this);
        this.availableThumbnailVariantsChangedObservable.unregisterObservers(this);
        this.telemetryObservable.unregisterObservers(this);
        this.telemetryUpdateObservable.unregisterObservers(this);
        this.httpRequestObservable.unregisterObservers(this);
        this.httpResponseObservable.unregisterObservers(this);
        this.endOfEventMarkerReceivedObservable.unregisterObservers(this);
        this.availableSubtitlesTracksChangedObservable.unregisterObservers(this);
        this.sessionEndedObservable.unregisterObservers(this);
        this.pinRequiredObservable.unregisterObservers(this);
        this.pinSuccessObservable.unregisterObservers(this);
        this.autoPlayPolicyPreventedPlaybackObservable.unregisterObservers(this);
        this.playoutDataObservable.unregisterObservers(this);
        this.playoutRulesObservable.unregisterObservers(this);
        this.assetMetadataUpdateObservable.unregisterObservers(this);
        this.audioTrackWillChangeObservable.unregisterObservers(this);
        this.pauseAdObservable.unregisterObservers(this);
        this.companionAdInsertionEnabledObservable.unregisterObservers(this);
        this.companionAdOpportunityStartedObservable.unregisterObservers(this);
        this.companionAdOpportunityEndedObservable.unregisterObservers(this);
        this.renderWatermarkObservable.unregisterObservers(this);
        this.clearWatermarkObservable.unregisterObservers(this);
        this.clearWatermarksObservable.unregisterObservers(this);
        this.bitrateChangedObservable.unregisterObservers(this);
        this.bitrateCapChangedObservable.unregisterObservers(this);
        this.bitrateCapRequestedObservable.unregisterObservers(this);
        this.ituQaSessionDataObservable.unregisterObservers(this);
        this.encodedFrameRateChangedObservable.unregisterObservers(this);
        this.renderedFrameRateChangedObservable.unregisterObservers(this);
        this.videoTrackChangedObservable.unregisterObservers(this);
        this.qualityFailoverObservable.unregisterObservers(this);
        this.cdnSwitchObservable.unregisterObservers(this);
        this.warningObservable.unregisterObservers(this);
        this.adBreakStartedObservable.unregisterObservers(this);
        this.adBreakFinishedObservable.unregisterObservers(this);
        this.adStartedObservable.unregisterObservers(this);
        this.adFinishedObservable.unregisterObservers(this);
        this.adBreakDataReceivedObservable.unregisterObservers(this);
        this.adTrackingReceivedObservable.unregisterObservers(this);
        this.catchupSeekObservable.unregisterObservers(this);
        this.adPositionObservable.unregisterObservers(this);
        this.errorObservable.unregisterObservers(this);
        this.manifestReceivedObservable.unregisterObservers(this);
        this.isMutedObservable.unregisterObservers(this);
        this.seekEndedObservable.unregisterObservers(this);
        this.seekStartedObservable.unregisterObservers(this);
        this.sessionStateObservable.unregisterObservers(this);
        this.sessionRetryStartedObservable.unregisterObservers(this);
        this.sessionRetryEndedObservable.unregisterObservers(this);
        this.sessionRestartStartedObservable.unregisterObservers(this);
        this.sessionRestartEndedObservable.unregisterObservers(this);
        this.timedMetadataReceivedObservable.unregisterObservers(this);
        this.bulkTimedMetadataReceivedObservable.unregisterObservers(this);
        this.playbackTimelineObservable.unregisterObservers(this);
        this.appBackgroundedObservable.unregisterObservers(this);
        this.appForegroundedObservable.unregisterObservers(this);
        this.videoElementCreatedObservable.unregisterObservers(this);
        this.fullscreenChangedObservable.unregisterObservers(this);
        this.clickedObservable.unregisterObservers(this);
        this.rebufferStartObservable.unregisterObservers(this);
        this.rebufferEndObservable.unregisterObservers(this);
        this.playStartObservable.unregisterObservers(this);
    }

    private warnIfNoSession(): void {
        if (!this.sessionControllerInternal) {
            this.logger.warn('No session controller currently attached');
        }
    }

    private flushStoredWarnings(): void {
        if (this.sessionControllerInternal) {
            this.warnings?.forEach((warning) => {
                this.sessionControllerInternal?.notifyWarning(warning.errorCode, warning.reason);
            });
            this.warnings = [];
        }
    }

    private flushStoredAdBreaks(): void {
        if (this.sessionControllerInternal && this.cachedAdBreaks) {
            this.sessionControllerInternal.notifyAdBreakDataReceived(this.cachedAdBreaks);
            this.cachedAdBreaks = null;
        }
    }

    private attachWaitForStateResolvers(): void {
        this.waitForStatePendingResolvers.forEach((pendingResolver) => {
            this.sessionControllerInternal?.waitForState(pendingResolver.state)?.then(() => {
                pendingResolver.resolver();
            });
        });
        this.waitForStatePendingResolvers = [];
    }

    private attachSessionEvents(): void {
        this.logger.verbose('Attaching session events');
        this.sessionControllerInternal?.onAdBreakDataReceived((adBreaks: Array<AdBreak>) =>
            this.adBreakDataReceivedObservable.notifyObservers(adBreaks)
        );
        this.sessionControllerInternal?.onAdTrackingReceived((adBreaks: Array<AdBreak>) =>
            this.adTrackingReceivedObservable.notifyObservers(adBreaks)
        );
        this.sessionControllerInternal?.onAdBreakStarted((adBreak: AdBreak) => this.adBreakStartedObservable.notifyObservers(adBreak));
        this.sessionControllerInternal?.onAdBreakFinished((adBreak: AdBreak) => this.adBreakFinishedObservable.notifyObservers(adBreak));
        this.sessionControllerInternal?.onAdStarted((adData: Ad) => this.adStartedObservable.notifyObservers(adData));
        this.sessionControllerInternal?.onAdFinished((adData: Ad) => this.adFinishedObservable.notifyObservers(adData));
        this.sessionControllerInternal?.onAdPositionChanged((adPosition: AdPosition) => this.adPositionObservable.notifyObservers(adPosition));
        this.sessionControllerInternal?.onCatchupSeek((catchupSeek: CatchupSeekEvent) => this.catchupSeekObservable.notifyObservers(catchupSeek));
        this.sessionControllerInternal?.onError((error: CvsdkError) => this.errorObservable.notifyObservers(error));
        this.sessionControllerInternal?.onManifestReceived((manifestReceived: ManifestReceivedEvent) =>
            this.manifestReceivedObservable.notifyObservers(manifestReceived)
        );
        this.sessionControllerInternal?.onMuteChanged((isMuted: boolean) => this.isMutedObservable.notifyObservers(isMuted));
        this.sessionControllerInternal?.onSeekEnded((_: void, currentPosition?: number) =>
            this.seekEndedObservable.notifyObservers(undefined, currentPosition)
        );
        this.sessionControllerInternal?.onSeekStarted((positionMs: number, currentPosition?: number) =>
            this.seekStartedObservable.notifyObservers(positionMs, currentPosition)
        );
        this.sessionControllerInternal?.onStateChanged((state: InternalSessionState, currentPosition?: number) =>
            this.sessionStateObservable.notifyObservers(state, currentPosition)
        );
        this.sessionControllerInternal?.onPlayStart((_: void, currentPosition?: number) =>
            this.playStartObservable.notifyObservers(undefined, currentPosition)
        );
        this.sessionControllerInternal?.onRebufferStart((_: void, currentPosition?: number) =>
            this.rebufferStartObservable.notifyObservers(undefined, currentPosition)
        );
        this.sessionControllerInternal?.onRebufferEnd((_: void, currentPosition?: number) =>
            this.rebufferEndObservable.notifyObservers(undefined, currentPosition)
        );
        this.sessionControllerInternal?.onTimedMetadataReceived((metadata: TimedMetadata) =>
            this.timedMetadataReceivedObservable.notifyObservers(metadata)
        );
        this.sessionControllerInternal?.onBulkTimedMetadataReceived((metadatas: Array<TimedMetadata>) =>
            this.bulkTimedMetadataReceivedObservable.notifyObservers(metadatas)
        );
        this.sessionControllerInternal?.onPlaybackTimelineUpdated((playbackTimeline: PlaybackTimelineInternal, currentPosition?: number) =>
            this.playbackTimelineObservable.notifyObservers(playbackTimeline, currentPosition)
        );
        this.sessionControllerInternal?.onAudioTrackWillChange((trackId: Track['id']) =>
            this.audioTrackWillChangeObservable.notifyObservers(trackId)
        );
        this.sessionControllerInternal?.onBitrateChanged((bitrateLevel: BitRateLevel) => this.bitrateChangedObservable.notifyObservers(bitrateLevel));
        this.sessionControllerInternal?.onEncodedFrameRateChanged((frameRate: number) =>
            this.encodedFrameRateChangedObservable.notifyObservers(frameRate)
        );
        this.sessionControllerInternal?.onRenderedFrameRateChanged((frameRate: number) =>
            this.renderedFrameRateChangedObservable.notifyObservers(frameRate)
        );
        this.sessionControllerInternal?.onVideoTrackChanged((videoTrack: VideoTrack) => this.videoTrackChangedObservable.notifyObservers(videoTrack));
        this.sessionControllerInternal?.onQualityFailover((videoFormat: VideoFormat) => this.qualityFailoverObservable.notifyObservers(videoFormat));
        this.sessionControllerInternal?.onCdnSwitch((cdnSwitch: CdnSwitchEvent) => this.cdnSwitchObservable.notifyObservers(cdnSwitch));
        this.sessionControllerInternal?.onWarning((error: CvsdkError) => this.warningObservable.notifyObservers(error));
        this.sessionControllerInternal?.onPinRequired((cause: PinRequiredCause) => this.pinRequiredObservable.notifyObservers(cause));
        this.sessionControllerInternal?.onPinSuccess(() => this.pinSuccessObservable.notifyObservers());
        this.sessionControllerInternal?.onAutoPlayPolicyPreventedPlayback(() => this.autoPlayPolicyPreventedPlaybackObservable.notifyObservers());
        this.sessionControllerInternal?.onPlayoutDataReceived((playoutData: PlayoutData) => this.playoutDataObservable.notifyObservers(playoutData));
        this.sessionControllerInternal?.onPlayoutRulesReceived((playoutRules: PlayoutRules) =>
            this.playoutRulesObservable.notifyObservers(playoutRules)
        );
        this.sessionControllerInternal?.onAssetMetadataUpdated((metadata: AssetMetadata) =>
            this.assetMetadataUpdateObservable.notifyObservers(metadata)
        );
        this.sessionControllerInternal?.onAudioTrackChanged((trackId: Track['id']) => this.audioTrackChangedObservable.notifyObservers(trackId));
        this.sessionControllerInternal?.onBitrateCapChanged((bitrateCap: BitrateCapData) =>
            this.bitrateCapChangedObservable.notifyObservers(bitrateCap)
        );
        this.sessionControllerInternal?.onBitrateCapRequested((bitrateCap: BitrateCapData) =>
            this.bitrateCapRequestedObservable.notifyObservers(bitrateCap)
        );

        this.sessionControllerInternal?.onAvailableAudioTracksChanged((tracks: Array<Track>) =>
            this.availableAudioTracksChangedObservable.notifyObservers(tracks)
        );
        this.sessionControllerInternal?.onAvailableSubtitlesTracksChanged((tracks: Array<Track>) =>
            this.availableSubtitlesTracksChangedObservable.notifyObservers(tracks)
        );
        this.sessionControllerInternal?.onSessionEnded(() => this.sessionEndedObservable.notifyObservers());
        this.sessionControllerInternal?.onStreamMetadataReceived((metadata: StreamMetadata) =>
            this.streamMetadataReceivedObservable.notifyObservers(metadata)
        );
        this.sessionControllerInternal?.onSubtitleCuesChanged((cues: Array<SubtitleCue>) => this.subtitleCuesChangedObservable.notifyObservers(cues));
        this.sessionControllerInternal?.onSubtitlesTrackChanged((track?: Track) => this.subtitlesTrackChangedObservable.notifyObservers(track));
        this.sessionControllerInternal?.onVolumeChanged((volume: number) => this.volumeChangedObservable.notifyObservers(volume));
        this.sessionControllerInternal?.onEventBoundary((eventBoundary: BoundaryEvent) =>
            this.eventBoundaryObservable.notifyObservers(eventBoundary)
        );
        this.sessionControllerInternal?.onAvailableThumbnailVariantsChanged((thumbnailVariants: Array<ThumbnailVariant>) =>
            this.availableThumbnailVariantsChangedObservable.notifyObservers(thumbnailVariants)
        );
        this.sessionControllerInternal?.onEndOfEventMarkerReceived((eventTime: number) =>
            this.endOfEventMarkerReceivedObservable.notifyObservers(eventTime)
        );
        this.sessionControllerInternal?.onPauseAd((event: NonLinearAdEvent) => this.pauseAdObservable.notifyObservers(event));
        this.sessionControllerInternal?.onVerifyCompanionAdInsertionEnabled((companionAdInsertionEnabled: boolean) =>
            this.companionAdInsertionEnabledObservable.notifyObservers(companionAdInsertionEnabled)
        );
        this.sessionControllerInternal?.onCompanionAdOpportunityStarted((event: CompanionAdOpportunityStartedEvent) =>
            this.companionAdOpportunityStartedObservable.notifyObservers(event)
        );
        this.sessionControllerInternal?.onCompanionAdOpportunityEnded((event: CompanionAdOpportunityEndedEvent) =>
            this.companionAdOpportunityEndedObservable.notifyObservers(event)
        );
        this.sessionControllerInternal?.onRenderWatermark((data: WatermarkData) => this.renderWatermarkObservable.notifyObservers(data));
        this.sessionControllerInternal?.onClearWatermark((data: WatermarkData) => this.clearWatermarkObservable.notifyObservers(data));
        this.sessionControllerInternal?.onClearWatermarks(() => this.clearWatermarksObservable.notifyObservers());
        this.sessionControllerInternal?.onAppBackgrounded(() => this.appBackgroundedObservable.notifyObservers());
        this.sessionControllerInternal?.onAppForegrounded(() => this.appForegroundedObservable.notifyObservers());
        this.sessionControllerInternal?.onTelemetry((event: TelemetryEvent) => this.telemetryObservable.notifyObservers(event));
        this.sessionControllerInternal?.onHttpRequest((event: PlayerHttpRequest) => this.httpRequestObservable.notifyObservers(event));
        this.sessionControllerInternal?.onHttpResponse((event: PlayerHttpResponse) => this.httpResponseObservable.notifyObservers(event));
        this.sessionControllerInternal?.onVideoElementCreated((event: HTMLVideoElement) => this.videoElementCreatedObservable.notifyObservers(event));
        this.sessionControllerInternal?.onFullscreenChanged((isFullscreen: boolean) =>
            this.fullscreenChangedObservable.notifyObservers(isFullscreen)
        );
        this.sessionControllerInternal?.onClicked(() => this.clickedObservable.notifyObservers());
    }
}
