import type {
    Ad,
    AdBreak,
    AdExtension,
    AdTrackingEvent,
    BaseAd,
    CombinedAdBreaks,
    ProgrammaticNodes,
    ServerSideAd,
    VodAd,
} from '../../../../../addons/adverts/common';
import { AdBreakType, AdStreamType, CompanionProvider, SsaiStitcherType, TrackingEventType } from '../../../../../addons/adverts/common';
import type {
    CompanionAdVariant,
    PauseAdVariant,
    NonLinearAdData,
    CompanionAdVariantType,
} from '../../../../../addons/adverts/non-linear-adverts/non-linear-ad-types';
import { CoreVideoInternal } from '../../../../../core-video-internal';
import { StreamingProtocol } from '../../../../../core/player/playout-data';
import { isSafariDesktop } from '../../../../../utils/device-type';
import { ptTimeToSeconds } from '../../../../../utils/pt-time-conversion';

import type {
    JsonAdBreakMetadata,
    JsonAdBreaks,
    JsonAdExtensionMetadata,
    JsonAdMetadata,
    JsonAdSlotTrackingMetadata,
    JsonAdTrackingMetadata,
    JsonAdVerificationsMetadata,
    JsonNonLinearAdBreakMetadata,
    JsonNonLinearAdListMetadata,
    JsonNonLinearAdMetadata,
} from '../../json-ad-breaks';
import { JsonAdBreakTrackingEvent, JsonAdBreakTrackingEventMap } from '../../json-ad-breaks';
import { mapCompanionAds } from '../../media-tailor-companions';
import { DoubleBoxToAdBreaksAdapter } from '../double-box/double-box-to-ad-breaks-adapter';
import { roughlyEquals } from '../../../../../utils/ad-break-utils';
import type { ExtensionParams } from '../../../../../addons/reporting/common/metadata-ad-helper';
import { filterTxmlContent, findNodeWithTagName, getAttributeFromNode, parseTxmlContent } from '../../../../../utils/txml-utils';
import type { tNode } from '../../../../../utils/txml-types';
import { sdkLogger } from '../../../../../logger';
import { getAdvertisingContentType } from '../../../../../addons/adverts/advert-content-type';

export class JsonAdBreaksAdapter {
    constructor(
        private ssaiStitcherType: SsaiStitcherType = SsaiStitcherType.None,
        private adBreakEndAdjustment: number = 0,
        private adBreakStartAdjustment: number = 0
    ) {}
    public adapt(jsonAdBreaks: JsonAdBreaks, isCsai: true, adServerCdn?: string): CombinedAdBreaks<VodAd>;
    public adapt(jsonAdBreaks: JsonAdBreaks, isCsai: false, adServerCdn?: string): CombinedAdBreaks<ServerSideAd>;
    public adapt(jsonAdBreaks: JsonAdBreaks, isCsai: boolean, adServerCdn?: string) {
        const { avails } = jsonAdBreaks;
        const adBreakData = avails
            .sort((a1, a2) => a1.startTimeInSeconds - a2.startTimeInSeconds)
            .map((adBreak) => {
                return this.parseAdBreakData(adBreak, isCsai, adServerCdn);
            });

        const { doubleBoxAds, nonLinearAds } = DoubleBoxToAdBreaksAdapter.splitDoubleBoxFromNonLinearAvails(jsonAdBreaks.nonLinearAvails);

        let combinedAdBreaks: AdBreak<Ad>[] = adBreakData;

        if (doubleBoxAds.length) {
            combinedAdBreaks = DoubleBoxToAdBreaksAdapter.adapt(adBreakData, doubleBoxAds, this.parseAdBreakData.bind(this), isCsai, adServerCdn);
        }

        const nonLinearAdData = nonLinearAds.map(this.parseNonLinearAdBreakData);

        return { adBreaks: combinedAdBreaks, nonLinearAds: nonLinearAdData };
    }

    protected parseAdExtension = (extension: JsonAdExtensionMetadata): AdExtension => {
        const extensionDocument = parseTxmlContent(extension.content);
        const paramNodes: Array<tNode> = filterTxmlContent(extensionDocument, (node: tNode) => node.tagName.toLowerCase() === 'creativeparameter');
        const programmaticChildren: Array<tNode> =
            (filterTxmlContent(extensionDocument, (node: tNode) => node.tagName.toLowerCase() === 'programmatic')[0]?.children as Array<tNode>) ?? [];

        return {
            type: extension.type,
            parameters: paramNodes.map((parameter) => {
                const value = parameter.children.map((node: tNode | string) => (typeof node === 'string' ? node : node.children[0])).join('');
                return {
                    creativeId: parameter.attributes['creativeId'],
                    type: parameter.attributes['type'],
                    name: parameter.attributes['name'],
                    value,
                };
            }),
            programmatic: programmaticChildren.map(
                (element: tNode) =>
                    ({
                        name: element.tagName,
                        value: typeof element === 'string' ? element : element.children[0],
                    }) as ProgrammaticNodes
            ),
        };
    };

    protected parseBaseAdData(ad: JsonAdMetadata | JsonNonLinearAdListMetadata, adBreak: AdBreak): BaseAd {
        const trackingEvents: Array<AdTrackingEvent> = [];
        ad.trackingEvents.forEach((event: JsonAdTrackingMetadata) => {
            trackingEvents.push(...this.parseTrackingEvent(event));
        });

        const nonLinearAd = (ad as JsonNonLinearAdListMetadata).nonLinearAdList?.[0];
        const baseAdGenericMetadata: JsonNonLinearAdMetadata | JsonAdMetadata = nonLinearAd ?? ad;
        const adMetadata = {
            id: baseAdGenericMetadata.adId,
            name: baseAdGenericMetadata.adTitle,
            creativeId: baseAdGenericMetadata.creativeId,
            adSystem: baseAdGenericMetadata.adSystem,
            expectedDuration: baseAdGenericMetadata.durationInSeconds,
        };

        const baseData: BaseAd = {
            adBreak,
            extensions: ad.extensions.map(this.parseAdExtension),
            verifications: this.getAdVerifications(ad),
            streamingProtocol: this.determineAdStreamProtocol(),
            trackingEvents,
            ...adMetadata,
        } as BaseAd;

        const availAd = ad as JsonAdMetadata;

        if (availAd.vastAdId) {
            baseData.freewheelId = availAd.vastAdId;
        } else if (nonLinearAd) {
            baseData.freewheelId = nonLinearAd.adId;
        }

        if (availAd.companionAds) {
            baseData.companions = mapCompanionAds(availAd.companionAds);
        }

        return baseData;
    }

    protected parseAdData(ad: JsonAdMetadata | JsonNonLinearAdListMetadata, adBreak: AdBreak, adType: 'VodAd', adServerCdn?: string): VodAd;
    protected parseAdData(
        ad: JsonAdMetadata | JsonNonLinearAdListMetadata,
        adBreak: AdBreak,
        adType: 'ServerSideAd',
        adServerCdn?: string
    ): ServerSideAd;
    protected parseAdData(
        ad: JsonAdMetadata | JsonNonLinearAdListMetadata,
        adBreak: AdBreak,
        adType: 'VodAd' | 'ServerSideAd',
        adServerCdn?: string
    ) {
        const baseAd = this.parseBaseAdData(ad, adBreak);

        if (adType !== 'VodAd') {
            return baseAd;
        } else {
            return {
                ...baseAd,
                cdns: (ad as JsonAdMetadata).mediaFile ? [{ url: (ad as JsonAdMetadata).mediaFile, name: adServerCdn ?? 'ad-server' }] : [],
                trackingEvents: baseAd.trackingEvents ?? [],
            };
        }
    }

    private getAdBreakTrackingEvents(rawAdBreak: JsonNonLinearAdBreakMetadata | JsonAdBreakMetadata): Array<AdTrackingEvent> {
        const adBreakTrackingEvents: Array<AdTrackingEvent> = [];

        if (rawAdBreak.adBreakTrackingEvents) {
            rawAdBreak.adBreakTrackingEvents.forEach((event: JsonAdSlotTrackingMetadata) => {
                const adaptedEvent = {
                    ...event,
                    eventType: JsonAdBreakTrackingEventMap[event.eventType],
                };
                adBreakTrackingEvents.push(...this.parseTrackingEvent(adaptedEvent));
            });
        }

        return adBreakTrackingEvents;
    }

    private initialiseAdBreak(rawAdBreak: JsonAdBreakMetadata | JsonNonLinearAdBreakMetadata): AdBreak {
        const adBreakType = rawAdBreak.startTimeInSeconds === 0 ? AdBreakType.Preroll : AdBreakType.Midroll;

        const adBreak: AdBreak = {
            ads: [],
            id: rawAdBreak.availId,
            ssaiStitcherType: this.ssaiStitcherType,
            trackingEvents: [],
            streamType: this.ssaiStitcherType === SsaiStitcherType.None ? AdStreamType.Separate : AdStreamType.InStream,
            type: adBreakType,
            watched: false,
        };

        if (ptTimeToSeconds(rawAdBreak.startTime)) {
            adBreak.position = ptTimeToSeconds(rawAdBreak.startTime)! + this.adBreakStartAdjustment;
        } else if (rawAdBreak.startTimeInSeconds !== undefined && rawAdBreak.startTimeInSeconds !== null) {
            adBreak.position = rawAdBreak.startTimeInSeconds + this.adBreakStartAdjustment;
        }

        if ((rawAdBreak as JsonAdBreakMetadata).durationInSeconds) {
            adBreak.expectedDuration = Math.max(
                (rawAdBreak as JsonAdBreakMetadata).durationInSeconds - (adBreakType === AdBreakType.Preroll ? 0 : this.adBreakEndAdjustment),
                0
            );
        }

        return adBreak;
    }

    private adaptAd(
        adBreak: AdBreak,
        rawAdBreak: JsonAdBreakMetadata | JsonNonLinearAdBreakMetadata,
        rawAd: JsonNonLinearAdListMetadata | JsonAdMetadata,
        idx: number,
        isCsai: boolean,
        adServerCdn?: string
    ): Ad {
        const adaptedAd = isCsai
            ? this.parseAdData(rawAd, adBreak, 'VodAd', adServerCdn)
            : this.parseAdData(rawAd, adBreak, 'ServerSideAd', adServerCdn);

        if (adaptedAd.companions) {
            const isBrightlineAd = adaptedAd.companions.some((cmp) => cmp.provider === CompanionProvider.Brightline);
            if (isBrightlineAd) {
                adBreak.features = { ...adBreak.features, containsBrightlineAds: true };
                adaptedAd.features = { ...adaptedAd.features, isBrightlineAd: true };
            }
        }
        adaptedAd.advertisingContentType = getAdvertisingContentType(adaptedAd.extensions || []);

        let isLastAdInBreak = false;
        if ((rawAdBreak as JsonAdBreakMetadata).duration) {
            // With paginated ad data coming in, not all ads will be in the same ad call - e.g. in a 4-ad break, the last MT tracking call will contain only ad 3 and ad 4
            isLastAdInBreak = roughlyEquals(
                rawAdBreak.startTimeInSeconds + (rawAdBreak as JsonAdBreakMetadata).durationInSeconds,
                (rawAd as JsonAdMetadata).startTimeInSeconds + (rawAd as JsonAdMetadata).durationInSeconds,
                0.75 // must be larger than this.adBreakEndAdjustment
            );
        } else {
            isLastAdInBreak = idx === (rawAdBreak as JsonNonLinearAdBreakMetadata).nonLinearAdsList.length - 1;
        }

        if (isLastAdInBreak) {
            adaptedAd.expectedDuration = adaptedAd.expectedDuration - this.adBreakEndAdjustment;
        }

        return adaptedAd;
    }
    protected parseAdBreakData(rawAdBreak: JsonAdBreakMetadata, isCsai: boolean, adServerCdn?: string): AdBreak {
        const adBreak: AdBreak = this.initialiseAdBreak(rawAdBreak);
        const adBreakTrackingEvents: Array<AdTrackingEvent> = this.getAdBreakTrackingEvents(rawAdBreak);

        rawAdBreak.ads.forEach((rawAd: JsonAdMetadata | JsonNonLinearAdListMetadata, idx) => {
            rawAd.trackingEvents = this.populateAdTrackingEventsAndAdBreakTrackingEvents(rawAd, adBreakTrackingEvents);

            const adaptedAd = this.adaptAd(adBreak, rawAdBreak, rawAd, idx, isCsai, adServerCdn);
            adBreak.ads.push(adaptedAd);
        });

        if (!adBreak.expectedDuration) {
            // If there is still no expectedDuration then we will infer it based on duration of individual ads
            // TODO: Fix this for pagination?
            adBreak.expectedDuration = adBreak.ads.reduce((acc: number, ad: Ad) => (acc += ad.expectedDuration), 0);
        }

        adBreak.trackingEvents = this.dedupeTrackingEvents(adBreakTrackingEvents);

        return adBreak;
    }

    private dedupeTrackingEvents(trackingEventsArray: Array<AdTrackingEvent>) {
        const seenEventUrls: Set<string> = new Set();
        return trackingEventsArray.filter((trackingEvent) => (seenEventUrls.has(trackingEvent.url) ? false : seenEventUrls.add(trackingEvent.url)));
    }

    private populateAdTrackingEventsAndAdBreakTrackingEvents(
        rawAd: JsonAdMetadata | JsonNonLinearAdListMetadata,
        adBreakTrackingEvents: Array<AdTrackingEvent>
    ) {
        const rawAdBreakEvents = rawAd.trackingEvents.filter((event: JsonAdTrackingMetadata) =>
            [TrackingEventType.BreakStart, TrackingEventType.BreakEnd].includes(event.eventType)
        );

        rawAdBreakEvents?.forEach((event: JsonAdTrackingMetadata) => {
            adBreakTrackingEvents.push(...this.parseTrackingEvent(event));
        });

        return rawAd.trackingEvents.filter((event: JsonAdTrackingMetadata) => !rawAdBreakEvents.includes(event));
    }

    protected parseTrackingEvent = (trackingEvent: JsonAdTrackingMetadata): Array<AdTrackingEvent> => {
        return trackingEvent.beaconUrls.map((url: string) => ({
            type: trackingEvent.eventType,
            url,
        }));
    };

    protected parseNonLinearAdData = (nonLinearAdList: JsonNonLinearAdListMetadata): NonLinearAdData<PauseAdVariant> => {
        if (!nonLinearAdList || !nonLinearAdList.nonLinearAdList || nonLinearAdList.nonLinearAdList.length === 0) {
            return {
                adStartedBeacons: [],
                adFinishedBeacons: [],
                adClickedBeacons: [],
                variants: [],
            };
        }

        const { trackingEvents } = nonLinearAdList;
        const freewheelStartBeacons = trackingEvents.find((event) => event.eventType === TrackingEventType.AdImpression)?.beaconUrls || [];
        const nonLinearAd = nonLinearAdList.nonLinearAdList[0];

        return {
            adStartedBeacons: freewheelStartBeacons,
            adFinishedBeacons: [],
            adClickedBeacons: [nonLinearAd.clickTracking],
            variants: [
                {
                    height: Number(nonLinearAd.height),
                    width: Number(nonLinearAd.width),
                    mimeType: nonLinearAd.staticResourceCreativeType,
                    resourceUri: nonLinearAd.staticResource,
                },
            ],
        };
    };

    protected parseCompanionAdData = (
        nonLinearAdList: JsonNonLinearAdListMetadata,
        adStartTimeInSeconds: number,
        companionAdTemplateType: CompanionAdVariant['companionAdTemplateType']
    ): NonLinearAdData<CompanionAdVariant> => {
        if (!nonLinearAdList || !nonLinearAdList.nonLinearAdList || nonLinearAdList.nonLinearAdList.length === 0) {
            return {
                adStartedBeacons: [],
                adFinishedBeacons: [],
                adClickedBeacons: [],
                variants: [],
            };
        }

        const {
            extensions,
            trackingEvents,
            nonLinearAdList: [{ clickTracking, adId, adSystem, adTitle, creativeId, durationInSeconds, staticResource }],
        } = nonLinearAdList;
        const freewheelStartBeacons = trackingEvents.find((event) => event.eventType === TrackingEventType.AdImpression)?.beaconUrls || [];

        return {
            adStartedBeacons: freewheelStartBeacons,
            adFinishedBeacons: [],
            adClickedBeacons: [clickTracking],
            variants: [
                {
                    adBreak: { id: adId },
                    adId,
                    adInsights: this.parseNonLinearAdDataExtensions(extensions[0].content, creativeId),
                    adSystem,
                    adTitle,
                    creativeId,
                    durationInSeconds: durationInSeconds!,
                    startTimeInSeconds: adStartTimeInSeconds,
                    staticResource,
                    companionAdTemplateType,
                },
            ],
        };
    };

    protected parseNonLinearAdBreakData = (nonLinearAdBreak: JsonNonLinearAdBreakMetadata): NonLinearAdData => {
        const startBeacons = nonLinearAdBreak.adBreakTrackingEvents.find((event) => event.eventType === JsonAdBreakTrackingEvent.BREAK_START);
        const endBeacons = nonLinearAdBreak.adBreakTrackingEvents.find((event) => event.eventType === JsonAdBreakTrackingEvent.BREAK_END);

        const { nonLinearAdsList, startTimeInSeconds } = nonLinearAdBreak;
        const nonLinearAdType = this.determineNonLinearAdType(nonLinearAdsList[nonLinearAdsList.length - 1]?.extensions);
        const ad = nonLinearAdType
            ? this.parseCompanionAdData(nonLinearAdsList[nonLinearAdsList.length - 1], startTimeInSeconds, nonLinearAdType)
            : this.parseNonLinearAdData(nonLinearAdsList[0]);

        return {
            adStartedBeacons: [...(startBeacons ? startBeacons.beaconUrls : []), ...ad.adStartedBeacons],
            adFinishedBeacons: [...(endBeacons ? endBeacons.beaconUrls : []), ...ad.adFinishedBeacons],
            adClickedBeacons: ad.adClickedBeacons,
            variants: ad.variants,
        };
    };

    private determineAdStreamProtocol(): StreamingProtocol {
        return isSafariDesktop(CoreVideoInternal.deviceInfo, CoreVideoInternal.deviceType) ? StreamingProtocol.HLS : StreamingProtocol.DASH;
    }

    private determineNonLinearAdType(nonLinearAdMetExtensionsContent: JsonAdExtensionMetadata[]): CompanionAdVariantType | null {
        let nonLinearAdType: CompanionAdVariantType | null = null;
        if (!nonLinearAdMetExtensionsContent) return nonLinearAdType;

        nonLinearAdMetExtensionsContent.forEach(({ content }) => {
            if (!content) return;
            if (content.includes('framead')) return (nonLinearAdType = 'Frame');
            if (content.includes('marqueead')) return (nonLinearAdType = 'Marquee');
        });
        return nonLinearAdType;
    }

    private parseNonLinearAdDataExtensions(extensionsContent: string, adCreativeId: string): ExtensionParams {
        const regexpPattern =
            '(?:<CreativeParameter creativeId="' +
            adCreativeId +
            '" name="Conviva Ad Insights" type="(?:.+)"><!\\[CDATA\\[)({.+})(?:\\]\\]><\\/CreativeParameter>)';
        const adInsights = extensionsContent.match(new RegExp(regexpPattern));
        return adInsights ? JSON.parse(decodeURIComponent(adInsights[1])) : {};
    }

    /**
     * Ad Verifications may be included as a top level property in the AdMetadata object or
     * as XML in the extensions. We should default to using the top level property if it
     * exists and fall back to parsing the extensions.
     *
     * See VAST guidelines: https://www.iab.com/wp-content/uploads/2016/04/VAST4.0_Updated_April_2016.pdf#page=61
     *
     * An example of the AdVerifications node in extensions is:
     *
     * "<AdVerifications>
     *    <Verification vendor="moat.com-gfddsfgfds234523523">
     *        <JavaScriptResource apiFramework="omid" browserOptional="true">
     *            <![CDATA[ https://z.moatads.com/sdgdsgdfsgds/moatvideo.js ]]>
     *        </JavaScriptResource>
     *        <TrackingEvents/>
     *        <VerificationParameters>
     *            <![CDATA[ {"zMoatTPC":"preroll"} ]]>
     *        </VerificationParameters>
     *    </Verification>
     * </AdVerifications>"
     *
     * Which we should parse into an AdVerifications object:
     *
     * {
     *      vendor: 'moat.com-gfddsfgfds234523523',
     *      javaScriptResource: {
     *          apiFramework: 'omid',
     *          browserOptional: 'true',
     *          uri: 'https://z.moatads.com/sdgdsgdfsgds/moatvideo.js',
     *      },
     *      verificationParameters: '{"zMoatTPC":"preroll"}',
     *      executableResource: [],
     *      trackingEvents: [],
     * }
     *
     * @param ad
     * @returns JsonAdVerificationsMetadata[]
     */
    private getAdVerifications(ad: JsonAdMetadata | JsonNonLinearAdListMetadata): JsonAdVerificationsMetadata[] {
        if (ad.adVerifications && ad.adVerifications.length > 0) {
            return ad.adVerifications;
        }

        try {
            const adVerificationsContext = ad.extensions.find((extension) => extension.type === 'AdVerifications');
            if (adVerificationsContext === undefined) {
                return [];
            }
            const verifications: tNode[] = (parseTxmlContent(adVerificationsContext.content)[0] as tNode).children as tNode[];
            return verifications.map((verificationNode: tNode) => {
                const javaScriptResourceNode = findNodeWithTagName(verificationNode, 'JavaScriptResource');
                const verificationParametersNode = findNodeWithTagName(verificationNode, 'VerificationParameters');
                const vendorString = getAttributeFromNode(verificationNode, 'vendor');

                return {
                    vendor: vendorString,
                    javaScriptResource:
                        javaScriptResourceNode === undefined
                            ? []
                            : [
                                  {
                                      apiFramework: getAttributeFromNode(javaScriptResourceNode, 'apiFramework'),
                                      browserOptional: getAttributeFromNode(javaScriptResourceNode, 'browserOptional'),
                                      uri: javaScriptResourceNode.children[0] as string,
                                  },
                              ],
                    verificationParameters: verificationParametersNode?.children[0] as string,
                    executableResource: [],
                    trackingEvents: [],
                };
            });
        } catch (e) {
            sdkLogger.withContext('JsonAdBreaksAdapter').error('Failed to parse Ad Verifications', e);
            return [];
        }
    }
}
