import type { OvpOptions, OvpPlayoutRequestOptions } from '@sky-uk-ott/client-lib-js-ott-ovp-service';

/**
 * @public
 */
export enum VideoPlatform {
    OVP = 'OVP',
    EXTERNAL = 'EXTERNAL',
    SKYSTORE = 'SKYSTORE',
    SMIL = 'SMIL',
    ONE_APP = 'ONE_APP',
}

export type VpiOptions = OvpOptions /* | OtherVpiOptions*/;

export type VpiSessionRequestOptions = Pick<OvpPlayoutRequestOptions, 'isPrefetch' | 'isRetrying' | 'hdcpEnabled'>;

export type VpiSessionResponseOptions = {
    penalizedCdnName?: string;
    isRetrying?: boolean;
};

/**
 * @public
 */
export type LicenseRequest = {
    url: string;
    method: 'GET' | 'POST' | 'HEAD';
    credentials?: 'omit' | 'same-origin' | 'include';
    headers: {
        [fieldName: string]: string;
    };
    body?: ArrayBuffer | ArrayBufferView | Blob | FormData | string | Document | URLSearchParams | Uint8Array | null;
};

/**
 * @public
 */
export type LicenseResponse = {
    url: string;
    method?: 'GET' | 'POST' | 'HEAD';
    status?: number;
    headers: {
        [fieldName: string]: string;
    };
    body?: string | ArrayBuffer | Blob | object | Document;
};

/**
 * @public
 */
export interface VideoPlatformIntegrationProvider {
    type: VideoPlatform;
    getVodPlayoutData(contentId: string, options?: unknown): Promise<unknown>;
    sendHeartbeat(url: string, streamPosition?: number, options?: unknown): Promise<unknown>;
    stopHeartbeat(url: string, streamPosition?: number, options?: unknown): Promise<unknown>;
    updateBookmark(contentId: string, streamPosition: number, options?: unknown): Promise<unknown>;

    getLivePlayoutData?(serviceKey: string, options?: unknown): Promise<unknown>;
    getEventPlayoutData?(contentId: string, options?: unknown): Promise<unknown>;
    getPreviewPlayoutData?(contentId: string, options?: unknown): Promise<unknown>;
    exchangePrefetchToken?(prePlayoutId: string, metadataToken: string, streamSourceType: string, options?: unknown): Promise<unknown>;

    decorateLicenseRequest?(request: LicenseRequest): LicenseRequest;
    validateLicenseResponse?(response: LicenseResponse): boolean;
    /**
     * @deprecated use the request options via parameter in playout request or update service configs by re-instantiating the service
     */
    updateOptions?(options?: unknown): void;
    getToken?(): Promise<string | undefined>;
}
