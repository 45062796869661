import type { InternalConfig, InternalConfigPerPlaybackType } from '../../config/internal-config';
import { InternalConfigProvider } from '../../config/internal-config-provider';
import type { AllowedDvrWindowDurations } from '../../config/core-video-config/core-video-config-types';
import { isRemoteConfigEnabled } from '../../config/core-video-config/core-video-config-types';
import type { PlaybackType } from '../../core/player/playout-data';
import { DvrWindowDuration, StreamVendor } from '../../core/player/playout-data';
import type { PlayerCapabilities } from '../../core/player/player-engine';

type LiveWindowConfig = { [key in StreamVendor]?: DvrWindowDuration };

const REMOTE_CONFIG_ENABLED_OVERRIDE = false; // will be removed when extended window fully onboarded to remote config

export class ExtendedWindowManager {
    public static getExtendedWindowConfig(
        playbackType: PlaybackType,
        preferredWindowDuration: DvrWindowDuration,
        playerCapabilities: PlayerCapabilities,
        internalConfigPerPlaybackType: InternalConfigPerPlaybackType
    ): LiveWindowConfig {
        const internalConfig = InternalConfigProvider.getForPlaybackType(internalConfigPerPlaybackType, playbackType);
        if (isRemoteConfigEnabled(internalConfig) && REMOTE_CONFIG_ENABLED_OVERRIDE) {
            return ExtendedWindowManager.getFromRemoteConfig(internalConfig, playerCapabilities);
        }

        return ExtendedWindowManager.getFromClientPreferredWindow(
            preferredWindowDuration,
            internalConfig.allowedDvrWindowDurations,
            playerCapabilities
        );
    }

    private static getBestAvailableDuration(
        allowedDvrWindows: Array<DvrWindowDuration> = [],
        playerCapabilities: PlayerCapabilities
    ): DvrWindowDuration {
        const containsFullEvent = allowedDvrWindows.includes(DvrWindowDuration.FullEvent);
        const containsExtended = allowedDvrWindows.includes(DvrWindowDuration.Extended);
        if (containsFullEvent && playerCapabilities.supportsFullEvent) {
            return DvrWindowDuration.FullEvent;
        } else if (containsExtended) {
            return DvrWindowDuration.Extended;
        }

        return DvrWindowDuration.Restricted;
    }

    private static getFromRemoteConfig(internalConfig: InternalConfig, playerCapabilities: PlayerCapabilities): LiveWindowConfig {
        const defaultConfiguration = {
            [StreamVendor.Harmonic]: DvrWindowDuration.Restricted,
            [StreamVendor.IStreamPlanet]: DvrWindowDuration.Restricted,
            [StreamVendor.Unknown]: DvrWindowDuration.Restricted,
        };

        const preferredConfig: LiveWindowConfig = {
            ...defaultConfiguration,
        };

        const coreVideoConfig = internalConfig.allowedDvrWindowDurations || {};
        Object.keys(coreVideoConfig).forEach((key) => {
            const vendor = key as keyof typeof coreVideoConfig;
            const bestAvailable = ExtendedWindowManager.getBestAvailableDuration(coreVideoConfig[vendor], playerCapabilities);
            preferredConfig[vendor] = bestAvailable;
        });

        return preferredConfig;
    }

    private static getFromClientPreferredWindow(
        preferredWindowDuration: DvrWindowDuration,
        allowedDvrWindowDurations: AllowedDvrWindowDurations = {},
        playerCapabilities: PlayerCapabilities
    ): LiveWindowConfig {
        function getWindowForVendor(vendor: StreamVendor): DvrWindowDuration {
            const isPreferredWindowAllowed =
                preferredWindowDuration === DvrWindowDuration.Restricted || allowedDvrWindowDurations[vendor]?.includes(preferredWindowDuration);
            const isExtendedAllowed = allowedDvrWindowDurations[vendor]?.includes(DvrWindowDuration.Extended);
            const isFullEventSupported = playerCapabilities.supportsFullEvent;
            const isFullEventAllowed = isFullEventSupported && allowedDvrWindowDurations[vendor]?.includes(DvrWindowDuration.FullEvent);

            switch (preferredWindowDuration) {
                case DvrWindowDuration.FullEvent:
                    if (isPreferredWindowAllowed && isFullEventSupported) {
                        return DvrWindowDuration.FullEvent;
                    }

                    if (isExtendedAllowed) {
                        return DvrWindowDuration.Extended;
                    }

                    return DvrWindowDuration.Restricted;
                case DvrWindowDuration.Extended:
                    if (isPreferredWindowAllowed) {
                        return DvrWindowDuration.Extended;
                    }

                    if (isFullEventAllowed) {
                        return DvrWindowDuration.FullEvent;
                    }

                    return DvrWindowDuration.Restricted;
                default:
                    return DvrWindowDuration.Restricted;
            }
        }

        const configuration = {
            [StreamVendor.Harmonic]: getWindowForVendor(StreamVendor.Harmonic),
            [StreamVendor.IStreamPlanet]: getWindowForVendor(StreamVendor.IStreamPlanet),
            [StreamVendor.Unknown]: getWindowForVendor(StreamVendor.Unknown),
        };

        return configuration;
    }
}
