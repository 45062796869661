import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';
import { sdkLogger } from '../../logger';
import type { Cdn } from '../player/playout-data';

import type { MetaListManager, MetaListInstructions } from './meta-list-manager';
import type { EventCue } from '../events/event-types';
import { TestingOverrides } from '../services/testing-overrides';
import { secondsToMs } from '../../utils/time-util';

const ENTER_EVENT_DELAY_FOR_TESTING = 10_000;
const EVENT_DURATION_FOR_TESTING = 5_000;

export class LiveActionManager {
    private logger: Logger = sdkLogger.withContext('LAM');
    private registerCue?: <T>(cue: EventCue<T>) => string;
    private usedIDs = new Set();
    constructor() {}

    public start(
        registerCue: <T>(cue: EventCue<T>) => string,
        metaListManager: MetaListManager,
        cdns: Array<Cdn>,
        options?: RequestInit,
        startIndex = 0
    ) {
        this.registerCue = registerCue;
        metaListManager.registerCallBack(this.handleResponse.bind(this));
        metaListManager.handleStartPooling(cdns, options, startIndex);
    }

    private handleResponse(jsonResponse: MetaListInstructions): boolean {
        const timelineEvents = jsonResponse.timelineEvents;
        if (timelineEvents) {
            for (let i = 0; i < timelineEvents.length; i++) {
                const { persistent, id, ...timelineEvent } = timelineEvents[i];
                if (this.usedIDs.has(id)) {
                    continue;
                }
                this.usedIDs.add(id);

                let startTime, endTime;
                if (TestingOverrides.liveActionsTestCustomEvents) {
                    const startTimeNumber = new Date().getTime() + ENTER_EVENT_DELAY_FOR_TESTING * (i + 1);
                    startTime = new Date(startTimeNumber);
                    endTime = new Date(startTimeNumber + EVENT_DURATION_FOR_TESTING);
                } else {
                    startTime = new Date(secondsToMs(timelineEvent.startTime));
                    endTime = new Date(secondsToMs(timelineEvent.endTime));
                }

                this.registerCue!({
                    ...timelineEvent,
                    startTime,
                    endTime,
                    isPersistent: persistent,
                });
            }
            return true;
        }
        this.logger.warn!(`Live Actions not received`);
        return false;
    }
}
