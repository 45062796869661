import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';
import { CoreVideoInternal } from '../../../core-video-internal';
import type { CvsdkError } from '../../../error';
import { ErrorReason, ErrorSeverity } from '../../../error';
import { sdkLogger } from '../../../logger';
import { Observable } from '../../../utils/observables/observable';
import { checkIsManifestLinearType, checkIsManifestVodType, checkIsSessionItemSleAutoplayType } from '../../../utils/playback-type';
import type { Track } from '../../player/track';
import type { PlayoutData } from '../../player/playout-data';
import { DvrWindowDuration } from '../../player/playout-data';
import { VideoFormat, VideoColourSpace } from '../../player/video-format';
import type { SessionItem } from '../session-controller';
import { InternalSessionState } from '../internal-session-state';
import type { PlaybackTimelineInternal, SessionControllerInternal } from '../session-controller-internal';
import { COLOUR_SPACE_ACTIVATION_ERROR_PREFIX } from '../session-controller-internal';
import type { SessionControllerInternalProxy } from '../session-controller-internal-proxy';
import type { BitrateCapData } from '../../meta-list-manager/bitrate-cap-manager';
import type { CdnSwitchEvent } from '../../player/player-engine-item';
import { SessionRestartStatus, SessionRestartType } from './restart.enums';
import { AudioRole } from '../../player/role';
import type { OvpPlayoutRequestOptions } from '../../../video-platforms/ovp/ovp-integration-provider.types';
export { SessionRestartStatus, SessionRestartType } from './restart.enums';

/**
 * @public
 */
export type SessionRetryConfig = Pick<SessionRestartControllerVpfRetryConfig, 'enabled' | 'cooldownPeriodSecs' | 'hdcpFailoverEnabled'>;

export type SessionRestartControllerVpfRetryConfig = {
    enabled: boolean;
    hdcpFailoverEnabled?: boolean;
    cooldownPeriodSecs?: number; // Amount of time that should pass before another retry can occur
    blockedErrorCodes?: Array<string>; // Errors for which VPF Retry should be disabled
};

export type SessionRetryObservers = {
    onSessionRetryStarted: (callback: (error: CvsdkError) => void) => void;
    onSessionRetryEnded: (callback: (error: CvsdkError) => void) => void;
};

export type SessionRestartObservers = {
    onSessionRestartStarted: (callback: () => void) => void;
    onSessionRestartEnded: (callback: () => void) => void;
};

type InternalRetryConfig = {
    vpfRetryEnabled: boolean;
    qualityFailoverEnabled: boolean;
    colourSpaceRetryEnabled: boolean;
    cooldownPeriodSecs?: number; // Amount of time that should pass before another retry can occur
    hdcpFailoverRetryEnabled: boolean;
};

type AudioState = {
    volume?: number;
    muted?: boolean;
};

export type SessionRestartPlayoutRequestOverrides = Pick<OvpPlayoutRequestOptions, 'hdcpEnabled'>;

export interface RestartOptions {
    restartType: SessionRestartType;
    penalizedCdnName?: string;
    playoutDataOverrides?: Partial<PlayoutData>;
    playoutRequestOverrides?: SessionRestartPlayoutRequestOverrides;
    sessionItemOverrides?: Partial<SessionItem>;
}

// 30 minutes
const DEFAULT_COOLDOWN_PERIOD_SECS = 1800;
const MAX_720P_BITRATE_CAP = 5000000;
const HDCP_ERROR_CODE = 'HDCP';

export class SessionRestartController {
    private currentSession?: SessionControllerInternal;
    private sessionFatalError: CvsdkError | null = null;
    private restartStatus: SessionRestartStatus = SessionRestartStatus.IDLE;
    private isSeeking = false;
    private lastRetryDateTimestamp = 0;
    private hasReceivedSleBingeEvent = false;
    private hasSessionFinishedLoading = false;
    private hasInitialLoad = false;
    private availableAudioTracks?: Array<Track>;
    private newPreferredAudioTrack?: Track;
    private newPreferredSubtitlesTrack?: Track;
    private newPreferredBitrateCap?: number;
    private newMaxVideoFormat?: VideoFormat;
    private playbackTimeline: PlaybackTimelineInternal | null = null;
    private audioState: AudioState = {};
    private currentCdnName?: string;
    private isVodManifest?: boolean;
    private isLinearManifest?: boolean;
    private isUsingExtendedDvrWindow = false;
    private logger: Logger = sdkLogger.withContext('SessionRestartController');
    private internalRetryConfig: InternalRetryConfig;
    private restartSessionInitiatedObservable: Observable<RestartOptions> = new Observable<RestartOptions>();
    private isStoppingSessionForRestart: boolean = false;
    private isRetryEnabledForSessionItem: boolean = true;

    constructor(
        private controllerFactory: () => SessionControllerInternal,
        private sessionEventProxy: SessionControllerInternalProxy,
        sessionItem: SessionItem
    ) {
        this.internalRetryConfig = this.buildInternalConfig(sessionItem);
        this.sessionEventProxy.setEventTransformers({
            onStateChanged: [this.stateChangeTransformer.bind(this)],
            onError: [this.errorTransformer.bind(this)],
        });
        this.setIsRetryEnabledForSessionItem(sessionItem);
    }

    public isRestarting(): boolean {
        return this.restartStatus !== SessionRestartStatus.IDLE;
    }

    public onRestartSessionInitiated(callback: (restartOptions: RestartOptions) => void): void {
        this.restartSessionInitiatedObservable.registerObserver(callback, this);
    }

    public initialise(): void {
        this.currentSession = this.createSessionController();
        this.currentSession.onCdnSwitch(this.handleCdnSwitch.bind(this));
    }

    public startSession(): void {
        this.currentSession?.start();
    }

    public destroy(): void {
        this.restartSessionInitiatedObservable.unregisterObservers(this);
        this.currentSession = undefined;
    }

    private handleCdnSwitch(event: CdnSwitchEvent) {
        this.currentCdnName = event.toCdn.name;
    }

    private buildInternalConfig(sessionItem: SessionItem): InternalRetryConfig {
        const { sessionRetryConfig, display, enableQualityFailover } = sessionItem;
        const internalRetryConfig = {
            vpfRetryEnabled: sessionRetryConfig?.enabled ?? false,
            qualityFailoverEnabled: enableQualityFailover ?? false,
            colourSpaceRetryEnabled: false,
            cooldownPeriodSecs: sessionRetryConfig?.cooldownPeriodSecs,
            hdcpFailoverRetryEnabled: sessionRetryConfig?.hdcpFailoverEnabled ?? false,
        };

        if (sessionRetryConfig?.enabled && typeof sessionRetryConfig?.cooldownPeriodSecs !== 'number') {
            internalRetryConfig.cooldownPeriodSecs = DEFAULT_COOLDOWN_PERIOD_SECS;
        }

        if (CoreVideoInternal.display?.activateColourSpace && display?.playerViewAlwaysPresentedFullScreen) {
            internalRetryConfig.colourSpaceRetryEnabled = true;
        }

        return internalRetryConfig;
    }

    private createSessionController(): SessionControllerInternal {
        const sessionController = this.controllerFactory();
        this.listenToSessionEvents(sessionController);
        return sessionController;
    }

    private listenToSessionEvents = (sessionController: SessionControllerInternal): void => {
        sessionController.onPlaybackTimelineUpdated(this.handlePlaybackTimeline.bind(this));
        sessionController.onPlayoutDataReceived(this.handlePlayoutDataReceived.bind(this));
        sessionController.onEndOfEventMarkerReceived(() => (this.hasReceivedSleBingeEvent = true));

        sessionController.onVolumeChanged((volume) => (this.audioState.volume = volume));
        sessionController.onMuteChanged((isMuted) => (this.audioState.muted = isMuted));
        sessionController.onAvailableAudioTracksChanged((tracks) => (this.availableAudioTracks = tracks));

        sessionController.onAudioTrackChanged((trackId: Track['id']) => {
            if (this.hasSessionFinishedLoading) {
                const audioTrack = this.availableAudioTracks?.find(({ id }) => id === trackId);
                this.newPreferredAudioTrack = audioTrack;
            }
        });
        sessionController.onSubtitlesTrackChanged((track?: Track) => {
            if (this.hasSessionFinishedLoading && !track?.forced) {
                this.newPreferredSubtitlesTrack = track;
            }
        });

        sessionController.onBitrateCapChanged((bitrateCap: BitrateCapData) => {
            if (this.hasSessionFinishedLoading) {
                this.newPreferredBitrateCap = bitrateCap.value;
            }
        });

        sessionController.onQualityFailover((videoFormat: VideoFormat) => {
            if (!this.hasSessionFinishedLoading) {
                this.newMaxVideoFormat = videoFormat;
            }
        });

        sessionController.onSeekStarted(() => {
            this.isSeeking = true;
        });
        sessionController.onSeekEnded(() => {
            this.isSeeking = false;
        });

        // These are the states where we can consider that the session has finished Loading successfully
        sessionController
            .waitForOneOfStates([InternalSessionState.Playing, InternalSessionState.Paused, InternalSessionState.Seeking])
            ?.then(() => {
                this.hasSessionFinishedLoading = true;
                this.hasInitialLoad = true;
            })
            .catch((e) => {
                sdkLogger.warn('Initial session never finished loading - this can be valid for VSF scenarios: ', e);
            });
    };

    private errorTransformer = (error?: CvsdkError | null): CvsdkError => {
        const adaptedError = error;
        if (adaptedError?.severity === ErrorSeverity.Fatal && this.isErrorRecoverable(adaptedError)) {
            if (this.canRetryWithError(error)) {
                Object.assign(adaptedError, { originalSeverity: adaptedError.severity, severity: ErrorSeverity.Warning });
            }
            this.sessionFatalError = adaptedError!;
        }

        return adaptedError!;
    };

    private isColourSpaceRetry(error?: CvsdkError | null): boolean | undefined {
        return error?.code.startsWith(COLOUR_SPACE_ACTIVATION_ERROR_PREFIX);
    }

    private isHdcpRetry(error: CvsdkError): boolean {
        return error.code.toUpperCase().includes(HDCP_ERROR_CODE);
    }

    private isQualityFailoverRetry(error?: CvsdkError | null): boolean {
        if (!error) return false;
        return error.code.indexOf('SDK.QUALITY.FAILOVER') >= 0;
    }

    private isVsfRetry(error: CvsdkError | null) {
        return this.isColourSpaceRetry(error) || (error && !this.hasInitialLoad && this.isHdcpRetry(error));
    }

    private stateChangeTransformer = (newState?: InternalSessionState | null): InternalSessionState => {
        const error = this.sessionFatalError;
        if (this.restartStatus === SessionRestartStatus.RESTARTING || this.restartStatus === SessionRestartStatus.VPF_RETRYING) {
            switch (newState) {
                case InternalSessionState.Loading:
                    this.sessionEventProxy.notifyRebufferStart();
                // eslint-disable-next-line no-fallthrough
                case InternalSessionState.PlayerLoading:
                    return this.isVsfRetry(error) ? newState : InternalSessionState.Rebuffering;
                case InternalSessionState.Playing:
                case InternalSessionState.Paused:
                case InternalSessionState.Seeking:
                    if (this.restartStatus === SessionRestartStatus.VPF_RETRYING && error) {
                        this.sessionEventProxy.notifySessionRetryEnded(error);
                    } else if (this.restartStatus === SessionRestartStatus.RESTARTING) {
                        this.sessionEventProxy.notifySessionRestartEnded();
                    }

                    this.sessionEventProxy.notifyRebufferEnd();
                    this.sessionFatalError = null;
                    this.restartStatus = SessionRestartStatus.IDLE;
                    return newState;
                default:
                    return newState!;
            }
        }

        if (this.isColourSpaceRetry(error)) {
            return InternalSessionState.Loading;
        } else if (this.canRetryWithError() && this.isStopping(newState!)) {
            this.restartStatus = SessionRestartStatus.IDLE;
            if (newState === InternalSessionState.Stopped) {
                this.restart(SessionRestartType.VPF_RETRY);
            }
            return error && this.isVsfRetry(error) ? InternalSessionState.Loading : InternalSessionState.Rebuffering;
        } else if (this.isStoppingSessionForRestart) {
            return InternalSessionState.Rebuffering;
        }

        return newState!;
    };

    private isStopping(sessionState: InternalSessionState): boolean {
        return [InternalSessionState.Stopped, InternalSessionState.Stopping].includes(sessionState);
    }

    public async restart(restartType: SessionRestartType): Promise<void> {
        if (restartType === SessionRestartType.RESTART && this.canRestart()) {
            await this.handleRestart(restartType);
        } else if (restartType === SessionRestartType.VPF_RETRY && this.canRetryWithError()) {
            await this.handleRestart(restartType);
        }
    }

    private getSessionItemOverrides(): Partial<SessionItem> {
        if (this.sessionFatalError && this.isHdcpRetry(this.sessionFatalError)) {
            this.newMaxVideoFormat = VideoFormat.HD;
            this.newPreferredBitrateCap = MAX_720P_BITRATE_CAP;
        }

        const sessionItemOverrides: Partial<SessionItem> = {
            ...(Boolean(this.newPreferredAudioTrack !== undefined) && {
                preferredAudioLanguages: [this.newPreferredAudioTrack?.languageTag || this.newPreferredAudioTrack?.language],
                preferredAudioMetadata: [
                    {
                        languageTag: this.newPreferredAudioTrack?.languageTag || this.newPreferredAudioTrack?.language,
                        isAudioDescription: !!this.newPreferredAudioTrack?.roles?.includes(AudioRole.Description),
                    },
                ],
            }),
            ...(Boolean(this.newPreferredSubtitlesTrack !== undefined) && {
                preferredSubtitlesLanguages: [this.newPreferredSubtitlesTrack?.languageTag || this.newPreferredSubtitlesTrack?.language],
                preferredSubtitleMetadata: [
                    { languageTag: this.newPreferredSubtitlesTrack?.languageTag || this.newPreferredSubtitlesTrack?.language },
                ],
            }),
            ...(Boolean(this.newPreferredBitrateCap !== undefined) && {
                playerBitrateLimits: { maxBitRate: this.newPreferredBitrateCap },
            }),
            ...(this.isColourSpaceRetry(this.sessionFatalError!) && {
                videoFormatConfig: {
                    support: {
                        supportedColourSpaces: [VideoColourSpace.SDR],
                    },
                },
            }),
            ...(Boolean(this.sessionFatalError) && {
                slePrerollEnabled: false,
            }),
        } as Partial<SessionItem>;

        if (
            this.sessionFatalError &&
            (this.isHdcpRetry(this.sessionFatalError) || this.isQualityFailoverRetry(this.sessionFatalError)) &&
            Boolean(this.newMaxVideoFormat !== undefined)
        ) {
            if (sessionItemOverrides.videoFormatConfig?.support) {
                sessionItemOverrides.videoFormatConfig.support.maxVideoFormat = this.newMaxVideoFormat;
            } else {
                sessionItemOverrides.videoFormatConfig = {
                    support: {
                        maxVideoFormat: this.newMaxVideoFormat,
                    },
                };
            }
        }

        return sessionItemOverrides;
    }

    private handleRestart = async (restartType: SessionRestartType): Promise<void> => {
        this.logger.info(`Restarting session (Type: ${SessionRestartType[restartType]})`);
        const restartOptions: RestartOptions = this.createRestartOptions(restartType);

        await this.prepareForRestart(restartType);
        this.restartSessionInitiatedObservable.notifyObservers(restartOptions);

        this.currentSession = this.createSessionController();
        if (restartType === SessionRestartType.VPF_RETRY) {
            // Only VPF retry should have a cooldown
            this.lastRetryDateTimestamp = Date.now();
        }
        await this.currentSession.start();
    };

    private setIsRetryEnabledForSessionItem(sessionItem: SessionItem): void {
        if (checkIsSessionItemSleAutoplayType(sessionItem)) {
            this.isRetryEnabledForSessionItem = false;
        }
    }

    private canRestart(): boolean {
        return Boolean(
            this.hasSessionFinishedLoading &&
                !this.hasReceivedSleBingeEvent &&
                this.restartStatus === SessionRestartStatus.IDLE &&
                !this.isStoppingSessionForRestart &&
                this.isRetryEnabledForSessionItem
        );
    }

    private canRetryWithError(error: CvsdkError | null = this.sessionFatalError): boolean {
        if (!this.isRetryEnabledForSessionItem) {
            return false;
        }
        const timePassedSinceLastRetry = (Date.now() - this.lastRetryDateTimestamp) / 1000;
        // VPF
        const vpfRetry = Boolean(
            this.internalRetryConfig?.vpfRetryEnabled &&
                this.hasSessionFinishedLoading &&
                error &&
                !this.hasReceivedSleBingeEvent &&
                timePassedSinceLastRetry >= (this.internalRetryConfig?.cooldownPeriodSecs ?? DEFAULT_COOLDOWN_PERIOD_SECS) &&
                (!this.isHdcpRetry(error) || this.internalRetryConfig?.hdcpFailoverRetryEnabled)
        );
        // VSF - ColourSpaceActivation
        const vsfRetry = Boolean(this.isColourSpaceRetry(error));

        const vsfHdcpFailover = Boolean(
            error &&
                this.internalRetryConfig?.hdcpFailoverRetryEnabled &&
                (!this.hasSessionFinishedLoading || this.lastRetryDateTimestamp) &&
                this.isHdcpRetry(error)
        );

        // VSF - QualityFailover
        const vsfQualityFailover = Boolean(this.internalRetryConfig?.qualityFailoverEnabled && this.isQualityFailoverRetry(error));

        return vpfRetry || vsfRetry || vsfQualityFailover || vsfHdcpFailover;
    }

    private isErrorRecoverable(error: CvsdkError): boolean {
        const NON_RECOVERABLE_ERROR_CODES = ['ADDON.OVP-HEARTBEAT.ERROR', 'ERROR.PLAYER.ANDROID.DRM', 'Security_Terminate'];
        const NON_RECOVERABLE_ERROR_REASONS = new Set<ErrorReason>([ErrorReason.Embargo]);
        const isNonRecoverableCode = NON_RECOVERABLE_ERROR_CODES.some((code) => error.code.includes(code));
        const isNonRecoverableReason = error.reason !== undefined && NON_RECOVERABLE_ERROR_REASONS.has(error.reason);

        return !isNonRecoverableCode && !isNonRecoverableReason;
    }

    private async prepareForRestart(restartType: SessionRestartType): Promise<void> {
        if (restartType === SessionRestartType.RESTART) {
            this.isStoppingSessionForRestart = true;
            await this.currentSession!.stop()
                .catch(() => {})
                .then(() => {
                    this.isStoppingSessionForRestart = false;
                });
        }

        switch (restartType) {
            case SessionRestartType.RESTART:
                this.restartStatus = SessionRestartStatus.RESTARTING;
                this.sessionEventProxy.notifySessionRestartStarted();
                break;
            case SessionRestartType.VPF_RETRY:
                this.restartStatus = SessionRestartStatus.VPF_RETRYING;
                this.sessionEventProxy.notifySessionRetryStarted(this.sessionFatalError!);
                break;
        }
        this.hasSessionFinishedLoading = false;
        this.playbackTimeline = null;
    }

    private getPlayoutDataOverrides(): Partial<PlayoutData> {
        const playoutDataOverrides: Partial<PlayoutData> = { ...this.audioState };
        playoutDataOverrides.preferredAudioTrack = this.newPreferredAudioTrack;
        playoutDataOverrides.preferredSubtitlesTrack = this.newPreferredSubtitlesTrack;

        if (this.playbackTimeline) {
            const { position, liveWindow } = this.playbackTimeline;

            if (this.isVodManifest) {
                playoutDataOverrides.position = position;
            } else if (this.isLinearManifest && this.shouldBookmarkLinearContent()) {
                playoutDataOverrides.linearPosition = { liveWindow, position };
            }
        }

        return playoutDataOverrides;
    }

    private getPlayoutRequestOverrides(): SessionRestartPlayoutRequestOverrides | undefined {
        return this.sessionFatalError && this.isHdcpRetry(this.sessionFatalError) ? { hdcpEnabled: false } : undefined;
    }

    private createRestartOptions(retryType: SessionRestartType): RestartOptions {
        const restartOptions: RestartOptions = {
            restartType: retryType,
            penalizedCdnName: retryType === SessionRestartType.VPF_RETRY ? this.currentCdnName : undefined,
        };

        restartOptions.playoutDataOverrides = this.getPlayoutDataOverrides();
        restartOptions.sessionItemOverrides = this.getSessionItemOverrides();
        restartOptions.playoutRequestOverrides = this.getPlayoutRequestOverrides();

        return restartOptions;
    }

    /**
     * Should only do a Linear/SLE bookmark when playing an asset and device/player combination that allows it.
     * If it's allowed, we should only do it when not at LiveEdge.
     */
    private shouldBookmarkLinearContent(): boolean {
        return Boolean(
            this.isUsingExtendedDvrWindow &&
                !this.playbackTimeline?.isAtLiveEdge &&
                CoreVideoInternal.capabilities.hasExtendedDvrSupport() &&
                this.currentSession?.isLinearScrubbingSupported()
        );
    }

    private handlePlaybackTimeline = (playbackTimeline: PlaybackTimelineInternal): void => {
        if (this.hasSessionFinishedLoading && !this.isSeeking) {
            this.playbackTimeline = playbackTimeline;
        }
    };

    private handlePlayoutDataReceived = (playoutData: PlayoutData): void => {
        this.isVodManifest = checkIsManifestVodType(playoutData.type);
        this.isLinearManifest = checkIsManifestLinearType(playoutData.type);
        // The CDN that is used is always the first one in the array
        const { name, streamInfo } = playoutData.cdns[0];

        this.currentCdnName = name;
        if (this.isLinearManifest && streamInfo && 'windowDuration' in streamInfo) {
            this.isUsingExtendedDvrWindow = streamInfo.windowDuration !== DvrWindowDuration.Restricted;
        }
    };
}
