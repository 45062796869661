import type { CvsdkError } from '../../error';
import { Observable } from '../../utils/observables/observable';
import { PerfKey, perfLogger, PerfTag } from '../../utils/perf';
import { throwUnsupportedValue } from '../../utils/ts';
import type {
    LicenseRequest,
    LicenseResponse,
    VideoPlatformIntegrationProvider,
    VpiSessionRequestOptions,
    VpiSessionResponseOptions,
} from '../../video-platforms/integration-provider';
import { VideoPlatform } from '../../video-platforms/integration-provider';
import { OvpDataAdapters } from '../../video-platforms/ovp/ovp-data-adapters';
import type {
    OvpBookmarkRequestOptions,
    OvpHeartbeatRequestOptions,
    ThirdPartiesOptions,
} from '../../video-platforms/ovp/ovp-integration-provider.types';
import {
    playoutDataErrorAdapter as skyStorePlayoutDataErrorAdapter,
    playerErrorAdapter as skyStorePlayerErrorAdapter,
    playoutResponseAdapter as skyStoreResponseAdapter,
} from '../../video-platforms/skystore/skystore-data-adapters';
import {
    playoutDataErrorAdapter as smilPlayoutDataErrorAdapter,
    playerErrorAdapter as smilPlayerErrorAdapter,
    playoutResponseAdapter as smilResponseAdapter,
} from '../../video-platforms/smil/smil-data-adapters';
import type { PlayoutData } from '../player/playout-data';
import { PlaybackType } from '../player/playout-data';
import type { OvpSessionItem, SessionItem, VpiSessionItem } from '../session-controller/session-controller';
import type { AddonsConfig, InternalConfigPerPlaybackType } from '../../config/internal-config';
import { InternalConfigProvider } from '../../config/internal-config-provider';
import type { CoreVideoInternalWrapper } from '../../core-video-internal.types';
import type { OvpPlayoutRequestOptions } from '@sky-uk-ott/client-lib-js-ott-ovp-service';

const PLAYOUT_REQUEST_ERROR_MESSAGE: Record<string, string> = {
    'Request timeout': 'Request timeout on playout fetch',
};

export class VideoPlatformIntegration {
    private playoutDataRequestedObservable = new Observable<Promise<PlayoutData>>();
    private playoutResponseAdapter: (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        responseData: any,
        sessionItem: VpiSessionItem,
        vpiSessionResponseOptions: VpiSessionResponseOptions
    ) => PlayoutData;
    private playoutDataErrorAdapter: (e: unknown) => CvsdkError;
    private playerErrorAdapter: (e: CvsdkError) => CvsdkError;
    private prefetchTokenExchangeErrorAdapter?: (e: unknown) => CvsdkError;
    private heartbeatRequestOptionsAdapter?: (sessionItem: SessionItem) => OvpHeartbeatRequestOptions;
    private bookmarkRequestOptionsAdapter?: (sessionItem: OvpSessionItem) => OvpBookmarkRequestOptions;
    private thirdPartiesRequestOptionsAdapter?: (config: AddonsConfig) => Array<ThirdPartiesOptions> | null;
    private ovpDataAdapters?: OvpDataAdapters;
    constructor(
        private vpip: VideoPlatformIntegrationProvider,
        cvi: CoreVideoInternalWrapper,
        private config?: InternalConfigPerPlaybackType
    ) {
        switch (vpip.type) {
            case VideoPlatform.OVP:
            case VideoPlatform.ONE_APP:
                this.ovpDataAdapters = new OvpDataAdapters(cvi);
                this.playoutResponseAdapter = (
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    responseData: any,
                    sessionItem: VpiSessionItem,
                    vpiSessionResponseOptions: VpiSessionResponseOptions
                ) => {
                    return this.ovpDataAdapters!.playoutResponseAdapter(responseData, sessionItem, vpiSessionResponseOptions);
                };
                this.playoutDataErrorAdapter = (e: unknown) => {
                    return this.ovpDataAdapters!.playoutDataErrorAdapter(e);
                };
                this.playerErrorAdapter = (e: CvsdkError) => {
                    return this.ovpDataAdapters!.playerErrorAdapter(e);
                };
                this.prefetchTokenExchangeErrorAdapter = (e: unknown) => {
                    return this.ovpDataAdapters!.prefetchTokenExchangeErrorAdapter(e);
                };
                this.heartbeatRequestOptionsAdapter = (sessionItem: SessionItem) => {
                    return this.ovpDataAdapters!.heartbeatRequestOptionsAdapter(sessionItem);
                };
                // @ts-ignore
                this.bookmarkRequestOptionsAdapter = (sessionItem: OvpSessionItem) => {
                    return this.ovpDataAdapters!.bookmarkRequestOptionsAdapter(sessionItem);
                };
                this.thirdPartiesRequestOptionsAdapter = (config: AddonsConfig) => {
                    return this.ovpDataAdapters!.thirdPartiesRequestOptionsAdapter(config);
                };
                break;
            case VideoPlatform.SKYSTORE:
                // @ts-ignore
                this.playoutResponseAdapter = skyStoreResponseAdapter;
                this.playoutDataErrorAdapter = skyStorePlayoutDataErrorAdapter;
                this.playerErrorAdapter = skyStorePlayerErrorAdapter;
                break;
            case VideoPlatform.SMIL:
                // @ts-ignore
                this.playoutResponseAdapter = smilResponseAdapter;
                this.playoutDataErrorAdapter = smilPlayoutDataErrorAdapter;
                this.playerErrorAdapter = smilPlayerErrorAdapter;
                break;
            default:
                throw new Error(`Unsupported Video Platform: ${vpip.type}`);
        }
    }

    public async getPlayoutData(
        sessionItem: VpiSessionItem,
        vpiSessionRequestOptions: VpiSessionRequestOptions,
        vpiSessionResponseOptions: VpiSessionResponseOptions
    ): Promise<PlayoutData> {
        const playoutDataPromise = this.handleGetPlayoutData(sessionItem, vpiSessionRequestOptions, vpiSessionResponseOptions);
        this.playoutDataRequestedObservable.notifyObservers(playoutDataPromise);
        return playoutDataPromise;
    }

    public async getUMVToken(): Promise<string | undefined> {
        return this.vpip.getToken?.();
    }

    public sendHeartbeat = (url: string, sessionItem: SessionItem, streamPosition?: number): Promise<unknown> => {
        const options = this.heartbeatRequestOptionsAdapter!(sessionItem);
        return this.vpip.sendHeartbeat(url, streamPosition, options);
    };

    public stopHeartbeat = (url: string, sessionItem: SessionItem, streamPosition?: number): Promise<unknown> => {
        const options = this.heartbeatRequestOptionsAdapter!(sessionItem);
        return this.vpip.stopHeartbeat(url, streamPosition, options);
    };

    public updateBookmark = (sessionItem: VpiSessionItem, streamPosition: number): Promise<unknown> => {
        const bookmarkRequestOptions = this.bookmarkRequestOptionsAdapter!(sessionItem);
        return this.vpip.updateBookmark(sessionItem.key, streamPosition, bookmarkRequestOptions);
    };

    public decorateLicenseRequest = (request: LicenseRequest): LicenseRequest => {
        return this.vpip.decorateLicenseRequest?.(request) ?? request;
    };

    public validateLicenseResponse = (response: LicenseResponse): boolean => {
        return this.vpip.validateLicenseResponse?.(response) ?? true;
    };

    public exchangePrefetchToken = async (sessionItem: VpiSessionItem, playoutData: PlayoutData): Promise<unknown> => {
        const options = this.ovpDataAdapters!.exchangePrefetchTokenRequestOptionsAdapter(sessionItem, playoutData);

        const errorAdapter = this.prefetchTokenExchangeErrorAdapter ?? this.playoutDataErrorAdapter;

        let streamSourceType;

        switch (sessionItem.type) {
            case PlaybackType.VOD:
            case PlaybackType.Clip:
            case PlaybackType.Preview:
            case PlaybackType.FER:
                streamSourceType = 'VOD';
                break;
            case PlaybackType.Live:
                streamSourceType = 'LINEAR';
                break;
            case PlaybackType.SingleLiveEvent:
                streamSourceType = 'EVENT';
                break;
            default:
                throw errorAdapter('Unknown session item type');
        }

        if (!playoutData.prefetch) {
            throw errorAdapter('No prefetch data provided');
        }

        try {
            const resp = await this.vpip.exchangePrefetchToken!(
                playoutData.prefetch.prePlayoutId,
                playoutData.prefetch.metadataToken,
                streamSourceType,
                options
            );
            return resp;
        } catch (e: unknown) {
            throw errorAdapter(e) ?? e;
        }
    };

    public onPlayoutDataRequested = (callback: (playoutDataPromise: Promise<PlayoutData>) => void, callbackOwner: object) => {
        this.playoutDataRequestedObservable.registerObserver(callback, callbackOwner);
    };

    public adaptPlayerError = (error: CvsdkError) => {
        return this.playerErrorAdapter(error);
    };

    public removePlayoutDataRequestedListener = (callbackOwner: object) => {
        this.playoutDataRequestedObservable.unregisterObservers(callbackOwner);
    };

    private async handleGetPlayoutData(
        sessionItem: VpiSessionItem,
        vpiSessionRequestOptions: VpiSessionRequestOptions,
        vpiSessionResponseOptions: VpiSessionResponseOptions
    ) {
        const playbackType = sessionItem.type;
        try {
            perfLogger.measure(PerfKey.root, PerfTag.ovpRequestAdaptStart);

            let requestOptions = await this.ovpDataAdapters?.playoutRequestAdapter(this.config!, sessionItem, vpiSessionRequestOptions);

            perfLogger.measure(PerfKey.root, PerfTag.ovpRequestAdaptEnd);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let responseData: any;
            let vpipGetPlayout: ((key: string, opts?: OvpPlayoutRequestOptions) => Promise<unknown>) | null = null;
            switch (playbackType) {
                case PlaybackType.Live:
                    vpipGetPlayout = this.vpip.getLivePlayoutData!;
                    break;
                case PlaybackType.Clip:
                case PlaybackType.FER:
                case PlaybackType.VOD:
                    vpipGetPlayout = this.vpip.getVodPlayoutData!;
                    break;
                case PlaybackType.SingleLiveEvent:
                    vpipGetPlayout = this.vpip.getEventPlayoutData!;
                    break;
                case PlaybackType.Preview:
                    vpipGetPlayout = this.vpip.getPreviewPlayoutData!;
                    break;
                default:
                    break;
            }

            if (vpipGetPlayout) {
                vpipGetPlayout = vpipGetPlayout.bind(this.vpip);
                const addonsConfig = InternalConfigProvider.getForPlaybackType(this.config!, sessionItem.type).addons;
                const thirdParties = this.thirdPartiesRequestOptionsAdapter?.(addonsConfig);

                if (requestOptions) {
                    requestOptions.thirdParties = thirdParties || [];
                } else {
                    requestOptions = { thirdParties: thirdParties || [] } as OvpPlayoutRequestOptions;
                }

                perfLogger.measure(PerfKey.root, PerfTag.ovpPlayoutReqStart);
                responseData = await vpipGetPlayout(sessionItem.key, requestOptions!);

                perfLogger.measure(PerfKey.root, PerfTag.ovpPlayoutReqEnd);
            }

            if (responseData) {
                perfLogger.measure(PerfKey.root, PerfTag.ovpResponseAdaptStart);

                const adaptedResponse = this.playoutResponseAdapter(responseData, sessionItem, vpiSessionResponseOptions);
                perfLogger.measure(PerfKey.root, PerfTag.ovpResponseAdaptEnd);
                return adaptedResponse;
            }
        } catch (e) {
            this.alterPlayoutError(e);
            throw this.playoutDataErrorAdapter(e);
        }

        throwUnsupportedValue(playbackType as never, Object.values(PlaybackType));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private alterPlayoutError(e: any) {
        if (e.message && PLAYOUT_REQUEST_ERROR_MESSAGE[e.message]) {
            e.message = PLAYOUT_REQUEST_ERROR_MESSAGE[e.message];
        }
    }
}
