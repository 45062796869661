import type { AdInsertionConfig } from '../../../internal-config';
import type { RemoteConfigSplit } from '../../../sdk-config';
import type { DeclaredOptionals } from '../../../../utils/ts';
import type { RequiredPropertiesDefinition } from '../addons-config-adapter-common';
import { checkRequiredConfigProperties } from '../addons-config-adapter-common';
import type { MediaTailorClientConfig as MediaTailorAddonConfig } from '../../../../addons/adverts/server-adverts/media-tailor/media-tailor-types';

// Internal Config
type MediaTailorInternalConfig = AdInsertionConfig['mediaTailor'];

// Client Config when Remote Config is disabled
export type MediaTailorClientConfig = MediaTailorAddonConfig;
export function adaptMediaTailorClientConfig(config: MediaTailorClientConfig | undefined): MediaTailorInternalConfig {
    return config;
}

type MediaTailorRemoteConfigProperties = 'enableVamProxy';
// Server Config when Remote Config is enabled
type MediaTailorRemoteServerConfig = Pick<NonNullable<MediaTailorInternalConfig>, MediaTailorRemoteConfigProperties>;

// Client Config when Remote Config is enabled
type MediaTailorRemoteClientConfig = Omit<NonNullable<MediaTailorInternalConfig>, MediaTailorRemoteConfigProperties>;

export type MediaTailorRemoteConfigSplit = RemoteConfigSplit<MediaTailorRemoteServerConfig, MediaTailorRemoteClientConfig>;
export function adaptMediaTailorRemoteConfigSplit(config: MediaTailorRemoteConfigSplit): DeclaredOptionals<MediaTailorInternalConfig> {
    const requiredFields: RequiredPropertiesDefinition<MediaTailorRemoteClientConfig> = {
        host: null,
    };

    const missingProperties = checkRequiredConfigProperties(config.clientConfig || {}, requiredFields);
    if (missingProperties.length > 0) {
        return;
    }

    const { clientConfig, remoteConfig } = config;
    return {
        enableVamProxy: remoteConfig?.enableVamProxy,
        host: clientConfig!.host,
        apiKey: clientConfig!.apiKey,
        deriveAdInfoFromManifest: clientConfig?.deriveAdInfoFromManifest,
        enablePagination: clientConfig?.enablePagination,
        mtUrl: clientConfig!.mtUrl,
        mtUhdUrl: clientConfig!.mtUhdUrl,
        debug: clientConfig!.debug,
        availSuppression: clientConfig!.availSuppression,
        siteSection: clientConfig!.siteSection,
        territory: clientConfig!.territory,
        skydd: clientConfig!.skydd,
    };
}
