import type { CoreVideoConfigCompiled } from './core-video-config/core-video-config-types';

export async function defaultCompiledConfigLoader(configPath: string): Promise<CoreVideoConfigCompiled> {
    // Webpack chunking needs to have the base-path hardcoded here, but we
    // can't use `this` in the import. So instead we are grabbing the path
    // from the `this` and storing it in a variable to use in the import.
    const module: { default: CoreVideoConfigCompiled } = await import(
        /* webpackChunkName: "cvsdk-default-config-[request]" */ `@sky-uk-ott/core-video-sdk-core-video-config/${configPath}-config.json`
    );

    return module.default;
}
