export enum PrefetchFailureTypes {
    // response status 204 on VAM request (freewheel-params)
    VamNoContent = 'vam-no-content',
    // response status 403 on VAM request (freewheel-params)
    VamUnauthorized = 'vam-unauthorized',
    // response status 500 on VAM request (freewheel-params)
    VamFailure = 'vam-failure',
    // response status 500 on Adverts request (ex. Media Tailor)
    AdvertisementsFailure = 'ads-failure',
}

export enum PrefetchStatus {
    Uninitialised = 'Uninitialised',
    Active = 'Active',
    Paused = 'Paused',
    Finished = 'Finished',
    Destroyed = 'Destroyed',
}
