import { PlaybackType } from '../core/player/playout-data';
import type { SessionItem } from '../core/session-controller/session-controller';
import { OvpJourneyContext } from '@sky-uk-ott/client-lib-js-ott-ovp-service';

export function checkIsManifestVodType(playbackType: PlaybackType): boolean {
    return [PlaybackType.FER, PlaybackType.VOD, PlaybackType.Clip, PlaybackType.Preview, PlaybackType.Advert].includes(playbackType);
}

export function checkIsManifestLinearType(playbackType: PlaybackType): boolean {
    return [PlaybackType.Live, PlaybackType.SingleLiveEvent].includes(playbackType);
}

export function checkIsManifestFerType(playbackType: PlaybackType): boolean {
    return playbackType === PlaybackType.FER;
}

export function checkIsManifestSingleLiveEventType(playbackType: PlaybackType): boolean {
    return playbackType === PlaybackType.SingleLiveEvent;
}

export function checkIsSessionItemSleAutoplayType(sessionItem: SessionItem): boolean {
    return (
        sessionItem.type === PlaybackType.SingleLiveEvent &&
        'journeyContext' in sessionItem &&
        sessionItem.journeyContext === OvpJourneyContext.SleAutoplay
    );
}
