import type { DeviceInformation } from '@sky-uk-ott/client-lib-js-device';

import type { MatcherObject, MatcherValue, NumberMatcher, StringMatcher, VersionMatcher } from './core-video-config-types';
import { MatcherType } from './core-video-config-types';
import { sdkLogger } from '../../logger';
import { CvsdkError, ErrorSeverity } from '../../error';
import { validate, satisfies } from '@sky-uk-ott/compare-versions';

function regexFromString(s: string): RegExp {
    const firstIndex = s.indexOf('/');
    const lastIndex = s.lastIndexOf('/');
    const isRegexString = firstIndex === 0 && lastIndex !== -1;
    if (!isRegexString) {
        return new RegExp(s);
    }

    const pattern = s.slice(1, lastIndex);
    const options = s.slice(lastIndex + 1);

    return new RegExp(pattern, options);
}

function createInvalidMatcherWarning(message: string) {
    return new CvsdkError('INVALID_MATCHER', message, ErrorSeverity.Warning);
}

function checkIsStringMatcher(matcherProperty: MatcherValue | undefined): matcherProperty is string | StringMatcher {
    return typeof matcherProperty === 'string' || (typeof matcherProperty === 'object' && matcherProperty.type === MatcherType.String);
}

function testStringMatcher(matcher: string | StringMatcher, deviceInfoString: string) {
    if (typeof matcher === 'string') {
        const regex = regexFromString(matcher);
        return regex.test(deviceInfoString);
    }

    const isBlacklistSpecified = matcher.isNot !== undefined && matcher.isNot.length > 0;
    const isWhitelistSpecified = matcher.is !== undefined && matcher.is.length > 0;

    if (!isBlacklistSpecified && !isWhitelistSpecified) {
        const errorMessage = 'A device matcher of type "String" was used without specifying any constraints';
        const error = createInvalidMatcherWarning(errorMessage);
        throw error;
    }

    if (isBlacklistSpecified) {
        const isBlacklisted = matcher.isNot!.some((matcherString) => {
            const regex = regexFromString(matcherString);
            return regex.test(deviceInfoString);
        });

        if (isBlacklisted) {
            return false;
        }
    }

    if (isWhitelistSpecified) {
        const isWhitelisted = matcher.is!.some((matcherString) => {
            const regex = regexFromString(matcherString);
            return regex.test(deviceInfoString);
        });

        if (!isWhitelisted) {
            return false;
        }
    }

    return true;
}

function checkIsNumberMatcher(matcherProperty: MatcherValue | undefined): matcherProperty is NumberMatcher {
    return typeof matcherProperty === 'object' && matcherProperty.type === MatcherType.Number;
}

function testNumberMatcher(matcher: NumberMatcher, deviceInfoString: string) {
    const isMinSpecified = matcher.min !== undefined;
    const isMaxSpecified = matcher.max !== undefined;
    const isBlacklistSpecified = matcher.isNot !== undefined && matcher.isNot.length > 0;

    if (!isBlacklistSpecified && !isMinSpecified && !isMaxSpecified) {
        const errorMessage = 'A device matcher of type "Number" was used without specifying any constraints';
        const error = createInvalidMatcherWarning(errorMessage);
        throw error;
    }

    const deviceInfoNumber = parseInt(deviceInfoString, 10);

    if (isNaN(deviceInfoNumber)) {
        return false;
    }

    if (isBlacklistSpecified) {
        if (matcher.isNot!.includes(deviceInfoNumber)) {
            return false;
        }
    }

    if (isMinSpecified) {
        if (deviceInfoNumber < matcher.min!) {
            return false;
        }
    }

    if (isMaxSpecified) {
        if (deviceInfoNumber > matcher.max!) {
            return false;
        }
    }

    return true;
}

function checkIsVersionMatcher(matcherProperty: MatcherValue | undefined): matcherProperty is VersionMatcher {
    return typeof matcherProperty === 'object' && matcherProperty.type === MatcherType.Version;
}

function testVersionMatcher(matcher: VersionMatcher, deviceInfoString: string) {
    const isValidVersion = validate(deviceInfoString);
    if (!isValidVersion) {
        const errorMessage = 'A device matcher of type "Version" was used when the device info value did not parse to a version number.';
        const error = createInvalidMatcherWarning(errorMessage);
        throw error;
    }

    return satisfies(deviceInfoString, matcher.test);
}

export function checkForMatch(deviceInfo: DeviceInformation, matchers: Array<MatcherObject>) {
    const isDeviceMatch = matchers.some((matcher) => {
        return Object.keys(matcher).every((deviceInfoKey) => {
            const typedKey = deviceInfoKey as keyof DeviceInformation;

            const deviceInfoPropertyMatcher = matcher[typedKey];
            const deviceInfoProperty = deviceInfo[typedKey];

            if (deviceInfoProperty === undefined) {
                return false;
            }

            try {
                if (checkIsStringMatcher(deviceInfoPropertyMatcher)) {
                    return testStringMatcher(deviceInfoPropertyMatcher, deviceInfoProperty);
                }

                if (checkIsVersionMatcher(deviceInfoPropertyMatcher)) {
                    return testVersionMatcher(deviceInfoPropertyMatcher, deviceInfoProperty);
                }

                if (checkIsNumberMatcher(deviceInfoPropertyMatcher)) {
                    return testNumberMatcher(deviceInfoPropertyMatcher, deviceInfoProperty);
                }
            } catch (e) {
                if (e instanceof CvsdkError) {
                    const messageWithContext = `Invalid Matcher For Key "${typedKey}": ${e.message}`;
                    sdkLogger.withContext('Core Video Config').warn(messageWithContext);
                    return false;
                }

                sdkLogger.error(e);
                return false;
            }

            return false;
        });
    });

    return isDeviceMatch;
}
