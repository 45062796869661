import type { DeviceInformation, XfinityInfo } from '@sky-uk-ott/client-lib-js-device';
import { DeviceType, XfinityVariant } from '@sky-uk-ott/client-lib-js-device';

/**
 * @public
 */
export function isSafariDesktop(deviceInfo: DeviceInformation, deviceType: DeviceType): boolean {
    return !!deviceInfo.browserName?.toLowerCase().includes('safari') && deviceType === DeviceType.Browser;
}

export function isSafariMobile(deviceInfo: DeviceInformation, deviceType: DeviceType): boolean {
    return isSafariDesktop(deviceInfo, deviceType) && deviceInfo.browserName?.toLowerCase() === 'mobile safari';
}

export function isSafariMajorVersion(deviceInfo: DeviceInformation, deviceType: DeviceType, minimumMajorVersion: number): boolean {
    const isSafari = isSafariDesktop(deviceInfo, deviceType);

    if (!isSafari) {
        return false;
    }

    try {
        const majorSafariVersion = parseInt(deviceInfo.browserVersion.split('.')[0], 10);
        return majorSafariVersion === minimumMajorVersion;
    } catch (e) {
        return false;
    }
}

export function isEdgeDesktop(deviceInfo: DeviceInformation, deviceType: DeviceType): boolean {
    return !!deviceInfo.browserName?.toLowerCase().includes('edge') && deviceType === DeviceType.Browser;
}

export function isChromeDesktop(deviceInfo: DeviceInformation, deviceType: DeviceType): boolean {
    return !!deviceInfo.browserName?.toLowerCase().includes('chrome') && deviceType === DeviceType.Browser;
}

export function isChromeOrChromium(deviceInfo: DeviceInformation): boolean {
    return !!deviceInfo.browserName?.toLowerCase().includes('chrome');
}

export function isIOSDevice(deviceInfo: DeviceInformation, deviceType: DeviceType): boolean {
    return !!deviceInfo.operatingSystemName?.toLowerCase().includes('ios') && deviceType === DeviceType.Browser;
}

export function isFirefoxDesktop(deviceInfo: DeviceInformation, deviceType: DeviceType): boolean {
    return !!deviceInfo.browserName?.toLowerCase().includes('firefox') && deviceType === DeviceType.Browser;
}

export function isEdgeOnWindows(deviceInfo: DeviceInformation, deviceType: DeviceType): boolean {
    return isEdgeDesktop(deviceInfo, deviceType) && isWindows(deviceInfo.operatingSystemName);
}

export function isMac(operatingSystemName: string): boolean {
    return operatingSystemName.indexOf('Mac') > -1;
}

export function isWindows(operatingSystemName: string): boolean {
    return operatingSystemName.indexOf('Windows') > -1;
}

export function getLgWebOsVersion(deviceInfo: DeviceInformation): number {
    const [, webOsVersionMatch] = (deviceInfo.browserVersion || '').match(/^WebOS ([0-9]+)/) || [];
    return parseInt(webOsVersionMatch, 10);
}

export function getLgWebOsSubVersion(deviceInfo: DeviceInformation): number {
    const match = deviceInfo.browserVersion.match(/^WebOS [0-9]+\.([0-9]+)/);
    if (match) {
        const minorVersion = parseInt(match[1], 10);
        return isNaN(minorVersion) ? 0 : minorVersion;
    }
    return 0;
}

export const isWebOsVeryLowPoweredDevice = (deviceInfo: DeviceInformation): boolean => {
    if (!deviceInfo) {
        return false;
    }

    if (getLgWebOsVersion(deviceInfo) === 3 && getLgWebOsSubVersion(deviceInfo) <= 4) {
        return true;
    }

    return false;
};

export function getProsperoDeviceVersion(deviceInfo: DeviceInformation, deviceType: DeviceType): number {
    if (deviceType === DeviceType.Prospero) {
        return parseInt(deviceInfo.deviceVersion, 10);
    }
    throw new Error('Check for prospero devices is only available for PS5 and PS4 (on Webmaf version equal or above 3)');
}

export function isPs4(deviceInfo: DeviceInformation, deviceType: DeviceType): boolean {
    try {
        return getProsperoDeviceVersion(deviceInfo, deviceType) === 4;
    } catch (e) {
        return false;
    }
}

export function isIon() {
    return Object.prototype.hasOwnProperty.call(self, 'ion');
}

export function isYouviewQtBrowser(deviceInfo: DeviceInformation): boolean {
    const browserVersion = deviceInfo.browserVersion.split('/')[2];
    return parseFloat(browserVersion) < 600 ? true : false;
}

export function isX1(deviceType: DeviceType, deviceInfo: DeviceInformation): boolean {
    return deviceType === DeviceType.Xfinity && (deviceInfo as XfinityInfo).xfinityVariant === XfinityVariant.X1;
}

export function isXumosb(deviceType: DeviceType, deviceInfo: DeviceInformation): boolean {
    return deviceType === DeviceType.Xfinity && (deviceInfo as XfinityInfo).xfinityVariant === XfinityVariant.Xumosb;
}

export function isXClass(deviceType: DeviceType, deviceInfo: DeviceInformation): boolean {
    return deviceType === DeviceType.Xfinity && (deviceInfo as XfinityInfo).xfinityVariant === XfinityVariant.XClass;
}
