import type { HeartbeatConfig } from '../../../addons/heartbeat/heartbeat-addon';
import type { AddonConfig } from '../../internal-config';
import type { RemoteConfigSplit } from '../../sdk-config';
import type { DeclaredOptionalsAddonsConfig, InternalAddonsConfig } from './addons-config-adapter-common';

// Internal Config
type HeartbeatInternalConfig = InternalAddonsConfig['heartbeat'];

// Client Config when Remote Config is disabled
export type HeartbeatClientConfig = AddonConfig<HeartbeatConfig>;
export function adaptHeartbeatClientConfig(config: HeartbeatClientConfig | undefined): HeartbeatInternalConfig {
    return config;
}

// Server Config when Remote Config is enabled
type HeartbeatRemoteServerConfig = AddonConfig<{}>;

// Client Config when Remote Config is enabled
type HeartbeatRemoteClientConfig = HeartbeatConfig;

export type HeartbeatRemoteConfigSplit = RemoteConfigSplit<HeartbeatRemoteServerConfig, HeartbeatRemoteClientConfig>;
export function adaptHeartbeatRemoteConfigSplit(config: HeartbeatRemoteConfigSplit): DeclaredOptionalsAddonsConfig<HeartbeatInternalConfig> {
    return {
        enabled: Boolean(config.remoteConfig?.enabled),
        config: {},
    };
}
