import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';
import type { AddonsConfig } from '../../config/internal-config';

import type { PlayoutData } from '../../core/player/playout-data';
import type { SessionItem } from '../../core/session-controller/session-controller';
import type { SessionControllerInternalProxy } from '../../core/session-controller/session-controller-internal-proxy';
import { sdkLogger } from '../../logger';
import type { AddonsFactories } from '../addons-factories';

import type { PineAddon } from './pine-addon';

export class PineManager {
    private pineAddon: PineAddon | undefined;
    private logger: Logger = sdkLogger.withContext('PineManager');

    constructor(pineConfig: AddonsConfig['pine'], addonsFactories: AddonsFactories) {
        this.pineAddon = addonsFactories.pine?.(pineConfig);
    }

    public async connect() {
        await this.pineAddon!.connect();
    }

    public init(sessionItem: SessionItem, session: SessionControllerInternalProxy) {
        this.pineAddon!.init(sessionItem, session);
    }

    public getModifiedPlayoutData(initialPlayoutData: PlayoutData): PlayoutData {
        if (!this.pineAddon) {
            return initialPlayoutData;
        }

        return this.pineAddon!.modifyPlayout(initialPlayoutData!);
    }

    public destroy(): void {
        this.logger.verbose(`Destroying Pine Manager`);
        this.pineAddon?.destroy();
    }
}
