import type { ClientOcellusConfig } from '../../../addons/reporting/ocellus-addon/ocellus-addon';
import type { AddonConfig } from '../../internal-config';
import type { RemoteConfigSplit } from '../../sdk-config';
import type { DeclaredOptionalsAddonsConfig, InternalAddonsConfig } from './addons-config-adapter-common';

// Internal Config
type InternalReportingConfig = NonNullable<InternalAddonsConfig['reporting']>;
type OcellusInternalConfig = InternalReportingConfig['ocellus'];

// Client Config when Remote Config is disabled
export type OcellusClientConfig = AddonConfig<ClientOcellusConfig>;
export function adaptOcellusClientConfig(config: OcellusClientConfig | undefined): OcellusInternalConfig {
    return config;
}

type OcellusRemoteConfigProperties = 'environment';

// Server Config when Remote Config is enabled
type OcellusRemoteServerConfig = AddonConfig<Pick<ClientOcellusConfig, OcellusRemoteConfigProperties>>;

// Client Config when Remote Config is enabled
type OcellusRemoteClientConfig = Omit<ClientOcellusConfig, OcellusRemoteConfigProperties>;

export type OcellusRemoteConfigSplit = RemoteConfigSplit<OcellusRemoteServerConfig, OcellusRemoteClientConfig>;
export function adaptOcellusRemoteConfigSplit(config: OcellusRemoteConfigSplit): DeclaredOptionalsAddonsConfig<OcellusInternalConfig> {
    const isEnabled = Boolean(config.remoteConfig?.enabled);
    if (!isEnabled) {
        return;
    }

    const { clientConfig, remoteConfig } = config;

    return {
        enabled: isEnabled,
        config: {
            environment: remoteConfig!.config.environment,
            token: clientConfig!.token,
            debug: clientConfig!.debug,
        },
    };
}
