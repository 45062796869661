import { AdvertisingContentType } from './adverts.enums';
import { type AdExtension, AdExtensionType } from './types';

export function getAdvertisingContentType(adExtensions: Array<AdExtension>): AdvertisingContentType {
    const shouldDisableCommercialOverlay = adExtensions.some(
        (ext) =>
            ext.type === AdExtensionType.FreeWheel &&
            ext.parameters.some((param) => param.name?.toLowerCase() === 'ad_type' && 'non_commercial_disable_overlay' === param.value)
    );

    return shouldDisableCommercialOverlay ? AdvertisingContentType.Other : AdvertisingContentType.Advert;
}
