import type { CvsdkError } from '../../error';
import { Observable } from '../../utils/observables/observable';
import type { PlayerState } from '../player/player-state';
import type { PlayoutData } from '../player/playout-data';

export interface AssetMetadata {
    expectedDuration?: number; // this is the *reported* duration by the platform and is known to have a low resolution
    id?: string; // a platform specific ID for the asset e.g. "1234-1234-1234-1234" or "freewheel-ad-12356"
    name?: string;
}

export abstract class Asset {
    private static idCount = 0;

    public id = `Asset-${`0000${++Asset.idCount}`.slice(-4)}`;

    protected stateObservable = new Observable<PlayerState>();
    protected currentPositionObservable = new Observable<number>();
    protected errorObservable = new Observable<CvsdkError>();
    protected skipObservable = new Observable<void>();

    public abstract getPlayoutData(): Promise<PlayoutData>;

    public onStateChanged(callback: (state: PlayerState) => void, callbackOwner: Object): void {
        this.stateObservable.registerObserver(callback, callbackOwner);
    }

    public onPositionChanged(callback: (currentTime: number) => void, callbackOwner: Object): void {
        this.currentPositionObservable.registerObserver(callback, callbackOwner);
    }

    public onError(callback: (error: CvsdkError) => void, callbackOwner: Object): void {
        this.errorObservable.registerObserver(callback, callbackOwner);
    }

    public onSkipped(callback: () => void, callbackOwner: Object): void {
        this.skipObservable.registerObserver(callback, callbackOwner);
    }

    public notifyStateChanged(state: PlayerState) {
        this.stateObservable.notifyObservers(state);
    }

    public notifyPositionChanged(position: number) {
        this.currentPositionObservable.notifyObservers(position);
    }

    public notifyError(error: CvsdkError) {
        this.errorObservable.notifyObservers(error);
    }
}

export class DynamicAsset extends Asset {
    constructor(private getDynamicPlayoutData: () => Promise<PlayoutData>) {
        super();
    }

    public async getPlayoutData() {
        return this.getDynamicPlayoutData();
    }
}

export class StaticAsset extends Asset {
    constructor(private playoutData: PlayoutData) {
        super();
    }

    public getPlayoutData() {
        return Promise.resolve(this.playoutData);
    }
}
