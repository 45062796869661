/* eslint-disable @typescript-eslint/no-explicit-any */

import { PromiseStatus } from './utils.enums';
export { PromiseStatus } from './utils.enums';

export function promiseWithTimeout<T>(promise: Promise<T>, limitMs: number): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        const timeoutId = setTimeout(() => reject(new Error(`Promise exceeded ${limitMs}ms timeout limit`)), limitMs);

        promise.then(
            (result) => {
                clearTimeout(timeoutId);
                resolve(result);
            },
            (error: Error) => {
                clearTimeout(timeoutId);
                reject(error);
            }
        );
    });
}

// Stage 3 Proposal
// https://github.com/tc39/proposal-promise-allSettled

export interface PromiseFulfilled {
    status: PromiseStatus.Fulfilled;
    value: any;
}

export interface PromiseRejected {
    status: PromiseStatus.Rejected;
    reason: any;
}

export type PromiseResult = PromiseFulfilled | PromiseRejected;

function reflect(a: any): Promise<PromiseResult> {
    return a.then(
        (v: any) => {
            return { status: PromiseStatus.Fulfilled, value: v };
        },
        (error: any) => {
            return { status: PromiseStatus.Rejected, reason: error };
        }
    );
}

export function allSettled(promises: Array<Promise<any>>): Promise<Array<PromiseResult>> {
    return Promise.all(promises.map(reflect));
}

export function timeDelay(milliseconds: number): Promise<void> {
    return new Promise<void>((resolve) => setTimeout(resolve, milliseconds));
}
