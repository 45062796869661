import { DeviceType } from '@sky-uk-ott/client-lib-js-device';
import type { AddonPlayoutData, AdvertisingData } from '../../addons/addon-playout-data';
import { NielsenTrackingType } from '../../addons/addon-playout-data';
import { AsidVersion } from '../../addons/watermarking/friend-watermarking-addon/friends-watermarking-config';
import type { AddonsConfig, InternalConfigPerPlaybackType } from '../../config/internal-config';
import type {
    AudioTrackMetadata,
    SubtitleTrackMetadata,
    MediaContainer,
    PlayerPlayoutData,
    Cdn as PlayoutCdn,
    PlayoutData,
    PlayoutRules,
    ShakaAbrConfiguration,
    Stream,
} from '../../core/player/playout-data';
import { PlaybackType, DrmType as SdkDrmType, StreamQuality, StreamingProtocol } from '../../core/player/playout-data';
import { VideoColourSpace, VideoFormat } from '../../core/player/video-format';
import type { OvpSessionItem, SessionItem } from '../../core/session-controller/session-controller';
import { SecurityRestrictionReason } from '../../drm/security-level-based-cap-manager';
import type { CvsdkError } from '../../error';
import { sdkLogger } from '../../logger';
import { checkIsManifestLinearType, checkIsManifestVodType } from '../../utils/playback-type';
import { getLinearStreamInfo } from '../../utils/stream-vendor';
import type { VpiSessionRequestOptions, VpiSessionResponseOptions } from '../integration-provider';
import { Url } from '../url';

import { CoreVideoInternal } from '../../core-video-internal';
import type { OvpCdnOptionalParams } from './OvpCdnOptionalParams';
import type { OvpExchangePrefetchTokenOptions, OvpResponse } from './common';
import type { OvpBookmarkRequestOptions, OvpHeartbeatRequestOptions, ThirdPartiesOptions } from './ovp-integration-provider.types';

import { OvpUrlExtendedWindowManager } from './ovp-url-extended-window-manager/ovp-url-extended-window-manager';
import type { OvpUrlTemplateParams } from './ovp-url-template-manager/ovp-url-template-manager';
import { OvpUrlTemplateManager } from './ovp-url-template-manager/ovp-url-template-manager';

import {
    OvpColourSpace,
    OvpNielsenTrackingType,
    OvpVideoCodec,
    OvpVideoFormat,
    StreamTransport,
    DrmType,
    OvpJourneyContext,
} from '@sky-uk-ott/client-lib-js-ott-ovp-service';
import type { CoreVideoInternalWrapper } from '../../core-video-internal.types';
import { OvpErrorAdapter, OvpErrorSource } from './ovp-error-adapter';
import type {
    AssetData,
    BookmarkData,
    Cdn,
    DeviceCapability,
    FairplayDrmData,
    FreewheelData,
    LivePlayoutResponseData,
    MarlinDrmData,
    OvpTrack,
    PlayoutResponseData,
    PlayReadyDrmData,
    WidevineDrmData,
    YospaceData,
    OvpPlayoutRequestOptions,
} from '@sky-uk-ott/client-lib-js-ott-ovp-service';
import { getHDCPStatusForHD } from '../../utils/probe-utils';

const STREAM_TRANSPORT_TO_STREAMING_PROTOCOL_MAP = {
    [StreamTransport.DASH]: StreamingProtocol.DASH,
    [StreamTransport.HSS]: StreamingProtocol.MSS,
    [StreamTransport.HLS]: StreamingProtocol.HLS,
    [StreamTransport.PDL]: StreamingProtocol.PDL,
    [StreamTransport.PSF]: StreamingProtocol.PSF,
};

const VIDEO_FORMAT_TO_STREAM_QUALITY_MAP = {
    [OvpVideoFormat.HD]: StreamQuality.HD,
    [OvpVideoFormat.SD]: StreamQuality.SD,
    [OvpVideoFormat.UHD]: StreamQuality.UHD,
    [OvpVideoFormat.UNKNOWN]: null as unknown as StreamQuality,
};

const OVP_DRM_TYPE_TO_SDK_DRM_TYPE = {
    [DrmType.PlayReady]: SdkDrmType.PlayReady,
    [DrmType.Marlin]: SdkDrmType.Marlin,
    [DrmType.Widevine]: SdkDrmType.Widevine,
    [DrmType.Fairplay]: SdkDrmType.Fairplay,
    [DrmType.None]: null as unknown as SdkDrmType,
};

const SDK_VIDEO_FORMAT_TO_OVP: Record<VideoFormat, OvpVideoFormat> = {
    [VideoFormat.SD]: OvpVideoFormat.SD,
    [VideoFormat.HD]: OvpVideoFormat.HD,
    [VideoFormat.UHD]: OvpVideoFormat.UHD,
};

// Using map specifically so keys can be iterated through in order of colour space preference
// OVP expects an ordered list
const SDK_COLOUR_SPACE_TO_OVP: Map<VideoColourSpace, OvpColourSpace> = new Map([
    [VideoColourSpace.DV, OvpColourSpace.DolbyVision],
    [VideoColourSpace.HDR10, OvpColourSpace.HDR10],
    [VideoColourSpace.HDRHLG, OvpColourSpace.HLG],
    [VideoColourSpace.SDR, OvpColourSpace.SDR],
]);

const NORMAL_VOD_SIX_SECONDS = 'mpeg_cenc';
const NORMAL_VOD_TWO_SECONDS = 'mpeg_cenc_2sec';

const SHORTFORM_SIX_SECONDS = 'mpeg_6sec';
const SHORTFORM_TWO_SECONDS = 'mpeg_2sec';

const STANDARD_MPD = 'master_cmaf.mpd';
const PS4_MPD = 'master_cmaf_fxbw.mpd';

export class OvpDataAdapters {
    constructor(private coreVideoInternal: CoreVideoInternalWrapper) {}

    adaptSupportedColourSpaces(
        sdkColourSpaces?: Array<VideoColourSpace>,
        playerViewAlwaysPresentedFullScreen?: boolean
    ): Array<OvpColourSpace> | undefined {
        if (!sdkColourSpaces || sdkColourSpaces.length === 0) {
            return undefined;
        }

        // specific behaviour for Playstation and Xbox for now
        if (this.coreVideoInternal.display()) {
            const supportsResizeWithHdr: boolean = this.coreVideoInternal.display()!.supportsResizeWithHdr();
            const explicitActivateColourSpace = !!this.coreVideoInternal.display()!.activateColourSpace;
            if ((explicitActivateColourSpace || !supportsResizeWithHdr) && !playerViewAlwaysPresentedFullScreen) {
                return [OvpColourSpace.SDR];
            }
        }

        const supportedColourSpaces = new Array<OvpColourSpace>();
        const sdkColourSpacesSet = new Set(sdkColourSpaces);

        for (const [key, value] of SDK_COLOUR_SPACE_TO_OVP) {
            if (sdkColourSpacesSet.has(key)) {
                supportedColourSpaces.push(value);
            }
        }

        return supportedColourSpaces;
    }

    adaptSdkMaxVideoFormat(sdkMaxVideoFormat?: VideoFormat, playerViewAlwaysPresentedFullScreen?: boolean): VideoFormat | undefined {
        if (sdkMaxVideoFormat === undefined) {
            return undefined;
        }

        if (this.coreVideoInternal.display() && sdkMaxVideoFormat === VideoFormat.UHD) {
            const supportsResizeWithUhd: boolean = this.coreVideoInternal.display()!.supportsResizeWithUhd();
            if (!supportsResizeWithUhd && !playerViewAlwaysPresentedFullScreen) {
                return VideoFormat.HD;
            }
        }

        return sdkMaxVideoFormat;
    }

    getJourneyContextFromVpiSessionRequestOptions(sessionItem: OvpSessionItem, vpiSessionRequestOptions: VpiSessionRequestOptions) {
        if (vpiSessionRequestOptions.isPrefetch) {
            return OvpJourneyContext.PreFetch;
        }

        if (vpiSessionRequestOptions.isRetrying) {
            return OvpJourneyContext.VpfRetry;
        }

        return sessionItem.journeyContext;
    }

    getVariantCapableFromPropositionExtensionsAndSessionItemOverrides(sessionItem: OvpSessionItem) {
        if (sessionItem.enableVariantCapableOverride === undefined) {
            return Boolean(this.coreVideoInternal.getPropositionExtensions().propositionOvpConfig?.endpoints?.[sessionItem.type]?.variantCapable);
        }

        // Testing override
        return Boolean(sessionItem.enableVariantCapableOverride);
    }

    async getHdcpEnabled(
        config: InternalConfigPerPlaybackType,
        sessionItem: OvpSessionItem,
        vpiSessionRequestOptions: VpiSessionRequestOptions
    ): Promise<boolean> {
        if (config.staticConfig.vpi?.useCustomVpip) {
            // If there's a resolution cap due to HDCP
            const securityBasedCapManager = CoreVideoInternal.getPropositionExtensions().securityLevelBasedCapManager;
            const maxResolution = securityBasedCapManager?.getMaxResolution(sessionItem.drmConfiguration?.type);

            // We are going to cap the resolution to a low enough value that it's safe to disable HDCP
            return maxResolution?.reason !== SecurityRestrictionReason.HDCP;
        }

        // 8656: we need to import logic from getOptionsWithMseCapabilities() to get device.capabilities[*].protection value (ovp-provider.ts)
        const drmType = sessionItem.drmConfiguration?.type || SdkDrmType.Widevine;
        return vpiSessionRequestOptions.hdcpEnabled === undefined ? getHDCPStatusForHD(drmType) : vpiSessionRequestOptions.hdcpEnabled;
    }

    async playoutRequestAdapter(
        config: InternalConfigPerPlaybackType,
        sessionItem: OvpSessionItem,
        vpiSessionRequestOptions: VpiSessionRequestOptions
    ): Promise<OvpPlayoutRequestOptions> {
        const sdkMaxVideoFormat = this.adaptSdkMaxVideoFormat(
            sessionItem.videoFormatConfig?.support?.maxVideoFormat,
            sessionItem.display?.playerViewAlwaysPresentedFullScreen
        );

        const compatibleStreamPropertyGroup = this.coreVideoInternal.getCompatibleStreamPropertyGroup(sessionItem.type);

        let supportedVideoCodecs = [OvpVideoCodec.H264];
        if (compatibleStreamPropertyGroup?.maxVideoFormat === VideoFormat.UHD) {
            supportedVideoCodecs = [OvpVideoCodec.H265, OvpVideoCodec.H264];
        }

        const maxVideoFormat: OvpVideoFormat | undefined =
            sdkMaxVideoFormat !== undefined && SDK_VIDEO_FORMAT_TO_OVP[sdkMaxVideoFormat] ? SDK_VIDEO_FORMAT_TO_OVP[sdkMaxVideoFormat] : undefined;
        const supportedColourSpaces = this.adaptSupportedColourSpaces(
            sessionItem.videoFormatConfig?.support?.supportedColourSpaces,
            sessionItem.display?.playerViewAlwaysPresentedFullScreen
        );
        const hdcpEnabled = await this.getHdcpEnabled(config, sessionItem, vpiSessionRequestOptions);

        sdkLogger.verbose(`OVP Maximum Video Format: ${maxVideoFormat}`);
        sdkLogger.verbose(`OVP Supported color spaces: ${supportedColourSpaces}`);
        sdkLogger.verbose(`OVP Supported video codecs: ${supportedVideoCodecs}`);
        sdkLogger.verbose(`OVP HDCP enabled: ${hdcpEnabled}`);

        const options: Partial<OvpPlayoutRequestOptions> = {
            parentalControlPin: sessionItem.parentalControlPin,
            disableParentalPinCheck: sessionItem.disableParentalPinCheck,
            coppaApplies: sessionItem.vac?.coppaApplies,
            context: sessionItem,
            journeyContext: this.getJourneyContextFromVpiSessionRequestOptions(sessionItem, vpiSessionRequestOptions),
            maxVideoFormat,
            supportedColourSpaces,
            supportedVideoCodecs,
            hdcpEnabled,
        };

        if (this.getVariantCapableFromPropositionExtensionsAndSessionItemOverrides(sessionItem)) {
            options.variantCapable = true;
        }

        if (this.coreVideoInternal.capabilities().requiresPlayReadyPersistentLicense?.()) {
            options.requiresPlayReadyPersistentLicense = true;
        }

        if (
            sessionItem.type === PlaybackType.VOD ||
            sessionItem.type === PlaybackType.Clip ||
            sessionItem.type === PlaybackType.FER ||
            sessionItem.type === PlaybackType.SingleLiveEvent
        ) {
            options.providerVariantId = sessionItem.providerVariantId;
        }

        if (sessionItem.type === PlaybackType.Live || sessionItem.type === PlaybackType.VOD) {
            options.passActivationObject = sessionItem.passActivationObject;
        }

        if (vpiSessionRequestOptions.isPrefetch) {
            options.isPrefetch = true;
        }

        if (vpiSessionRequestOptions.isRetrying) {
            options.isRetrying = true;
        }

        if (sessionItem.personaParentalControlRating) {
            options.personaParentalControlRating = sessionItem.personaParentalControlRating;
        }

        return options as OvpPlayoutRequestOptions;
    }

    heartbeatRequestOptionsAdapter(sessionItem: SessionItem): OvpHeartbeatRequestOptions {
        return {
            coppaApplies: sessionItem.vac?.coppaApplies,
            context: sessionItem,
        };
    }

    bookmarkRequestOptionsAdapter(sessionItem: OvpSessionItem): OvpBookmarkRequestOptions {
        if (sessionItem.type === PlaybackType.VOD || sessionItem.type === PlaybackType.Clip || sessionItem.type === PlaybackType.FER) {
            return {
                providerVariantId: sessionItem.providerVariantId,
            };
        }

        return {};
    }

    thirdPartiesRequestOptionsAdapter(config: AddonsConfig): Array<ThirdPartiesOptions> | null {
        const thirdParties: Array<ThirdPartiesOptions> = new Array<ThirdPartiesOptions>();

        if (config.adInsertion?.enabled) {
            thirdParties.push('FREEWHEEL');
            if (config.adInsertion?.mediaTailor) {
                thirdParties.push('MEDIATAILOR');
            }
            if (config.adInsertion?.yospace) {
                thirdParties.push('YOSPACE');
            }
        }

        if (config.reporting?.conviva?.enabled) {
            thirdParties.push('CONVIVA');
        }

        switch (this.coreVideoInternal.getPropositionExtensions()?.propositionOvpConfig?.watermarking?.version) {
            case AsidVersion.V3:
                thirdParties.push('FRIENDS');
                break;
            case AsidVersion.V4:
                thirdParties.push('FRV4');
                break;
            default:
        }

        return thirdParties.length > 0 ? thirdParties : null;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    playoutDataErrorAdapter(e: any): CvsdkError {
        return OvpErrorAdapter.adapt({ error: e, source: OvpErrorSource.PLAYOUT });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    prefetchTokenExchangeErrorAdapter(e: any): CvsdkError {
        return OvpErrorAdapter.adapt({ error: e, source: OvpErrorSource.TOKEN_EXCHANGE });
    }

    playerErrorAdapter(e: CvsdkError): CvsdkError {
        return OvpErrorAdapter.adapt({ error: e, source: OvpErrorSource.PLAYER });
    }

    exchangePrefetchTokenRequestOptionsAdapter(sessionItem: OvpSessionItem, playoutData: PlayoutData): OvpExchangePrefetchTokenOptions {
        const contentId = playoutData.assetMetadata?.assetId || sessionItem.key;
        switch (sessionItem.type) {
            case PlaybackType.VOD:
            case PlaybackType.Clip:
            case PlaybackType.Preview:
            case PlaybackType.FER:
                return { contentId, providerVariantId: sessionItem.providerVariantId };
            case PlaybackType.Live:
                return { serviceKey: contentId };
            case PlaybackType.SingleLiveEvent:
                return { eventId: contentId, providerVariantId: sessionItem.providerVariantId };
            default:
                throw this.playoutDataErrorAdapter('Unknown session item type');
        }
    }

    // TODO ☢️WID-1687a: https://github.com/sky-uk/core-video-team/issues/4031
    applyEndpointModifications(originalCdn: Cdn, sessionItem: SessionItem, transport: StreamTransport, drmType: DrmType): PlayoutCdn {
        const modifiedCdn: PlayoutCdn = {
            url: originalCdn.url,
            name: originalCdn.cdn,
            streamInfo: getLinearStreamInfo(originalCdn.url),
        };

        if (sessionItem.shouldUseTwoSecondFragments) {
            const isNormalVod = modifiedCdn.url.includes(NORMAL_VOD_SIX_SECONDS);
            if (isNormalVod) {
                modifiedCdn.url = modifiedCdn.url.replace(NORMAL_VOD_SIX_SECONDS, NORMAL_VOD_TWO_SECONDS);
            } else {
                modifiedCdn.url = modifiedCdn.url.replace(SHORTFORM_SIX_SECONDS, SHORTFORM_TWO_SECONDS);
            }
        }

        // TODO: Needed until OVP return PS4 specific streams. No ETA :(
        const fxbwReplacement = this.coreVideoInternal.getPropositionExtensions()?.propositionOvpConfig?.fxbwReplacement;
        if (
            fxbwReplacement?.[this.coreVideoInternal.deviceType()] &&
            [PlaybackType.VOD, PlaybackType.Preview, PlaybackType.Clip].includes(sessionItem.type)
        ) {
            modifiedCdn.url = modifiedCdn.url.replace(STANDARD_MPD, PS4_MPD);
        }

        if (originalCdn.url !== modifiedCdn.url) {
            sdkLogger.info(`OVP stream endpoint modified:\n  from ${originalCdn.url}\n    to "${modifiedCdn.url}"`);
        }

        return modifiedCdn;
    }

    buildPlayoutCdnList(ovpData: AssetData, sessionItem: OvpSessionItem, dvrWindowSupported?: boolean): Array<PlayoutCdn> {
        const {
            endpoints: ovpEndpoints,
            format: { transport, protection: DrmType },
            audioTracks,
            subtitleTracks,
            streamVariants,
        } = ovpData;

        const {
            type,
            preferredWindowDuration,
            preferredAudioLanguages,
            preferredSubtitlesLanguages,
            preferredAudioMetadata,
            preferredSubtitleMetadata,
        } = sessionItem;
        const { maxAudioTracks, maxSubtitleTracks, hasForcedNarrativeSupport, supportedAudioCodecs } = this.coreVideoInternal.playerCapabilities();

        const isLinearStream = checkIsManifestLinearType(type);
        const cdnParameters = this.coreVideoInternal.getPropositionExtensions()?.propositionOvpConfig?.cdnParameters;

        const checkLimit = (
            tracks: Array<OvpTrack> = [],
            preferredLangs: Array<string> = [],
            tracksLimit?: number,
            preferredMetadata: Array<AudioTrackMetadata | SubtitleTrackMetadata> = []
        ) => {
            if (!tracksLimit || tracksLimit === Infinity) {
                return 'all';
            }
            if (preferredMetadata.length > 0) {
                const trackLocales = tracks.map((track) => track.locale.split('-')[0]);
                const preferredMetadataLanguages = preferredMetadata.map((pm) => pm.languageTag.split('-')[0]);
                const notPreferred = trackLocales.filter((track) => preferredMetadataLanguages.indexOf(track) === -1);
                return preferredMetadataLanguages.concat(notPreferred).slice(0, tracksLimit).join(',');
            } else {
                const trackLocales = tracks.map((track) => track.locale.split('-')[0]);
                const notPreferred = trackLocales.filter((track) => preferredLangs.indexOf(track) === -1);
                return preferredLangs.concat(notPreferred).slice(0, tracksLimit).join(',');
            }
        };

        const updateCdnUrl = (cdn: PlayoutCdn): PlayoutCdn => {
            const url = Url.parse<OvpCdnOptionalParams>(cdn.url).setUrlScheme(this.shouldUseHttpsScheme(sessionItem, transport, DrmType));
            url.if(cdnParameters?.audio, (u) =>
                u.setParameter('audio', checkLimit(audioTracks, preferredAudioLanguages, maxAudioTracks, preferredAudioMetadata))
            )
                .if(cdnParameters?.subtitle, (u) =>
                    u.setParameter('subtitle', checkLimit(subtitleTracks, preferredSubtitlesLanguages, maxSubtitleTracks, preferredSubtitleMetadata))
                )
                .if(cdnParameters?.forcedNarrative, (u) => u.setParameter('forcedNarrative', (hasForcedNarrativeSupport ?? true).toString()))
                .if(cdnParameters?.trickplay, (u) =>
                    u.setParameter('trickplay', (this.coreVideoInternal.capabilities().hasThumbnailScrubbingSupport() ?? true).toString())
                )
                .if(cdnParameters?.audioCodec && !isLinearStream, (u) =>
                    u.setParameter('audioCodec', this.coreVideoInternal.getAllowedAudioCodecs(supportedAudioCodecs, type))
                );

            return {
                ...cdn,
                url: url.toString(),
            };
        };

        const getCdnsFromOvp = (): Array<PlayoutCdn> => {
            if (isLinearStream) {
                let playoutCdns: Array<PlayoutCdn> = ovpEndpoints.map((endpoint: Cdn) => ({ url: endpoint.url, name: endpoint.cdn }));

                if (streamVariants) {
                    playoutCdns = OvpUrlTemplateManager.parseDvrWindowUrlTemplate({
                        ovpEndpoints: playoutCdns,
                        streamVariants,
                        dvrWindowSupported,
                        playbackType: type,
                        preferredWindowDuration,
                    } as OvpUrlTemplateParams);
                } else if (
                    CoreVideoInternal.getPropositionExtensions()?.propositionOvpConfig?.endpoints?.[sessionItem.type]
                        ?.shouldApplyLinearEndpointModifications
                ) {
                    playoutCdns = OvpUrlExtendedWindowManager.buildExtendedAndFullEventDvrWindowWhenPossible(
                        playoutCdns,
                        type,
                        preferredWindowDuration
                    );
                }

                return playoutCdns.map((cdn: PlayoutCdn) => ({ ...cdn, streamInfo: getLinearStreamInfo(cdn.url) })).map(updateCdnUrl);
            }

            return ovpEndpoints.map((endpoint) => this.applyEndpointModifications(endpoint, sessionItem, transport, DrmType)).map(updateCdnUrl);
        };

        return getCdnsFromOvp();
    }

    shouldUseHttpsScheme(sessionItem: SessionItem, transport: StreamTransport, drmType: DrmType): boolean {
        if (sessionItem.disableCdnOverwrite) {
            return false;
        }

        if (this.coreVideoInternal.capabilities().prefersHttp()) {
            return false;
        }

        return true;
    }

    penalizeSpecificCdn(ovpData: AssetData, penalizedCdnName?: string): AssetData {
        if (!penalizedCdnName) {
            return ovpData;
        }

        const penalizedCdnIndex = ovpData.endpoints.findIndex((endpoint) => endpoint.cdn === penalizedCdnName);
        if (penalizedCdnIndex === -1) {
            return ovpData;
        }

        const adaptedEndpoints = [...ovpData.endpoints];
        const [penalizedEndpoint] = adaptedEndpoints.splice(penalizedCdnIndex, 1);
        adaptedEndpoints.push(penalizedEndpoint);

        sdkLogger.info(`Penalizing ${penalizedCdnName} cdn from ovp playout response. Endpoint: ${penalizedEndpoint.url}`);

        return { ...ovpData, endpoints: adaptedEndpoints };
    }

    playoutResponseAdapter(
        { ovpData }: OvpResponse<PlayoutResponseData>,
        sessionItem: OvpSessionItem,
        vpiSessionResponseOptions: VpiSessionResponseOptions
    ): PlayoutData {
        const adaptedOvpAsset = this.penalizeSpecificCdn(ovpData.asset, vpiSessionResponseOptions.penalizedCdnName);
        const adaptedData: PlayoutData = {
            // Depend on the application
            autoplay: sessionItem.autoplay,
            muted: sessionItem.muted,
            preferredAudioMetadata: sessionItem.preferredAudioMetadata,
            preferredAudioLanguages: sessionItem.preferredAudioLanguages,
            preferredSubtitleMetadata: sessionItem.preferredSubtitleMetadata,
            preferredSubtitlesLanguages: sessionItem.preferredSubtitlesLanguages,
            playerBitrateLimits: sessionItem.playerBitrateLimits,
            type: sessionItem.type,

            // Depend on OVP
            cdns: this.buildPlayoutCdnList(adaptedOvpAsset, sessionItem, (ovpData as LivePlayoutResponseData).dvrWindowSupported),
            stream: this.getStream(adaptedOvpAsset.format, ovpData.videoFormat),
            durationMs: 'durationMs' in ovpData ? ovpData.durationMs : 0,
            containsMandatoryPinEvents: 'containsMandatoryPinEvents' in ovpData ? Boolean(ovpData.containsMandatoryPinEvents) : false,
            ...this.getAddonData(ovpData, sessionItem),

            // Depend on both the application and OVP
            ...this.getDrmData(ovpData),
            abrConfiguration: {
                ...(sessionItem.abrConfiguration as ShakaAbrConfiguration),
            },
            position: this.getPosition(sessionItem, ovpData.bookmark),
            vamMidrollEnabled: true,
            vamPrerollEnabled: false,
            display: sessionItem.display,

            // From SDK itself
            sessionId: sessionItem.sessionId,
        };

        const playoutRules = this.getPlayoutRules(ovpData);
        if (Object.keys(playoutRules).length) {
            adaptedData.playoutRules = playoutRules;
        }

        // Must be explicitly false
        if (ovpData.adInstructions?.dai_MidRollEnabled === 'false') {
            adaptedData.vamMidrollEnabled = false;
        }

        // Must be explicitly true
        if (!vpiSessionResponseOptions.isRetrying && ovpData.adInstructions?.dai_PreRollEnabled === 'true') {
            adaptedData.vamPrerollEnabled = true;
        }

        if (
            adaptedData.drmConfiguration &&
            ovpData.protection?.licenceAcquisitionUrl &&
            !this.coreVideoInternal.config().staticConfig.vpi?.useCustomVpip
        ) {
            adaptedData.drmConfiguration.licenceAcquisitionUrl = this.adaptLicenseUrl(ovpData.protection.licenceAcquisitionUrl);
        }

        return { ...adaptedData, custom: { ...this.getCustomData(sessionItem, ovpData), ...adaptedData.custom } };
    }

    private adaptLicenseUrl(originalLicense: string) {
        if (![DeviceType.Xfinity, DeviceType.Android, DeviceType.WebMaf].includes(this.coreVideoInternal.deviceType())) {
            const urlRegex = /ovp.(stable-int\.)?(peacocktv|skyshowtime|gottmax|ott.showmax|nowtv).com/;
            const match = urlRegex.exec(originalLicense);

            if (match && originalLicense) {
                const isPeacock = originalLicense?.includes('peacocktv.com');
                const isStableInt = Boolean(match[1]);
                const propositionHost = match[2];
                const urlSegments = ['tv', 'clients'];

                if (isPeacock) urlSegments.push('a');

                if (isStableInt) urlSegments.push('stable-int');

                urlSegments.push(propositionHost, 'com');
                return originalLicense.replace(match[0], urlSegments.join('.'));
            }
        }

        return originalLicense;
    }

    getPlayoutRules(ovpData: PlayoutResponseData): PlayoutRules {
        const playoutRules: PlayoutRules = {};

        if ((ovpData as LivePlayoutResponseData).dvrWindowSupported !== undefined) {
            playoutRules.dvrWindowSupported = (ovpData as LivePlayoutResponseData).dvrWindowSupported;
        }

        return playoutRules;
    }

    getStream(format: DeviceCapability, videoFormat: OvpVideoFormat): Stream {
        const { acodec, vcodec, container, protection, transport, colourSpace } = format;
        return {
            audioCodec: acodec,
            videoCodec: vcodec,
            colourSpace: this.mapOvpColourFormatToSdkFormat(colourSpace),
            container: container as unknown as MediaContainer,
            protection: this.mapDrmTypeToSdkDrmType(protection as DrmType),
            protocol: this.getStreamingProtocol(transport),
            quality: this.getStreamQuality(videoFormat),
            frameRate: format.frameRate,
        };
    }

    mapOvpColourFormatToSdkFormat(desiredOvpColourSpace: OvpColourSpace | undefined): VideoColourSpace | undefined {
        if (desiredOvpColourSpace) {
            let entry: VideoColourSpace = VideoColourSpace.SDR;

            // Iterate for case insensitive comparison
            for (const [sdkColourSpace, ovpColourSpace] of SDK_COLOUR_SPACE_TO_OVP) {
                if (desiredOvpColourSpace.toUpperCase() === ovpColourSpace.toUpperCase()) {
                    entry = sdkColourSpace;
                    break;
                }
            }

            return entry;
        }
    }

    mapDrmTypeToSdkDrmType(DrmType: DrmType): SdkDrmType {
        return OVP_DRM_TYPE_TO_SDK_DRM_TYPE[DrmType];
    }

    getStreamingProtocol(transport: StreamTransport): StreamingProtocol {
        return STREAM_TRANSPORT_TO_STREAMING_PROTOCOL_MAP[transport];
    }

    getStreamQuality(videoFormat: OvpVideoFormat): StreamQuality {
        return VIDEO_FORMAT_TO_STREAM_QUALITY_MAP[videoFormat];
    }

    getCustomData(sessionItem: OvpSessionItem, ovpData: PlayoutResponseData): PlayerPlayoutData['custom'] {
        if (this.coreVideoInternal.deviceType() === DeviceType.YouView) {
            const assetId = this.getAssetId(ovpData);
            return {
                assetId,
                assetContext: sessionItem.reporting?.youviewAppId,
                nativeReporting: {
                    ...sessionItem.reporting,
                },
            };
        }

        return {
            nativeReporting: {
                ...sessionItem.reporting,
                userId: sessionItem.user?.ids?.conviva?.profileid,
            },
        };
    }

    getDrmData(ovpData: PlayoutResponseData): Pick<PlayoutData, 'drmConfiguration' | 'custom'> | undefined {
        if (!ovpData.protection) {
            return {};
        } else if (ovpData.protection.type === DrmType.PlayReady) {
            const { licenceAcquisitionUrl, deviceId, headers } = ovpData.protection as PlayReadyDrmData;
            return {
                drmConfiguration: {
                    type: SdkDrmType.PlayReady,
                    licenceAcquisitionUrl: licenceAcquisitionUrl,
                    customChallengeData: `01${deviceId}`,
                    deviceId: deviceId,
                    headers,
                },
            };
        } else if (ovpData.protection.type === DrmType.Marlin) {
            const { licenceToken, licenceAcquisitionUrl } = ovpData.protection as MarlinDrmData;

            return {
                drmConfiguration: {
                    type: SdkDrmType.Marlin,
                    licenceAcquisitionUrl,
                },
                custom: {
                    licenceToken,
                },
            };
        } else if (ovpData.protection.type === DrmType.Widevine) {
            const { licenceAcquisitionUrl, headers } = ovpData.protection as WidevineDrmData;
            return {
                drmConfiguration: {
                    type: SdkDrmType.Widevine,
                    licenceAcquisitionUrl,
                    headers,
                },
            };
        } else if (ovpData.protection.type === DrmType.Fairplay) {
            const protectionData = ovpData.protection as FairplayDrmData;

            if (CoreVideoInternal.getPropositionExtensions()?.propositionOvpConfig?.drm?.excludeCertificateUrl) {
                return {
                    drmConfiguration: {
                        ...protectionData,
                        type: SdkDrmType.Fairplay,
                    },
                };
            }

            // TODO: Remove this once certificate URL is added to OVP API
            const relativePath = 'drm/certificate/fairplay.cer';
            const licenceAcquisitionUrlObj = new URL(protectionData.licenceAcquisitionUrl);
            const staticCertificateUrl = `${licenceAcquisitionUrlObj.origin}/${relativePath}`;

            return {
                drmConfiguration: {
                    ...protectionData,
                    type: SdkDrmType.Fairplay,
                    certificateUrl: staticCertificateUrl,
                },
            };
        }
    }

    // condition for checking if a killswitch should be enabled to disable ads
    isKillswitchEnabled(sessionItem: OvpSessionItem, freewheel: FreewheelData) {
        const { adCompatibilityLegacyLinearSupport, adCompatibilityLegacyVodSupport } = freewheel;
        const isVodType = checkIsManifestVodType(sessionItem.type);
        const isLinearType = checkIsManifestLinearType(sessionItem.type);
        return (isLinearType && adCompatibilityLegacyLinearSupport) || (isVodType && adCompatibilityLegacyVodSupport);
    }

    getFreewheelAdvertisingData(sessionItem: OvpSessionItem, freewheel: FreewheelData) {
        const { adCompatibilityEncodingProfile, contentId } = freewheel;
        const isKillswitchEnabled = this.isKillswitchEnabled(sessionItem, freewheel);
        if (isKillswitchEnabled) {
            sdkLogger.warn('OVP Freewheel Ads Killswitch is enabled, requests for adverts will not be sent');
        }
        return {
            adCompatibilityEncodingProfile,
            contentId,
            isKillswitchEnabled,
        };
    }

    getYospaceAdvertisingData(sessionItem: OvpSessionItem, yospace: YospaceData) {
        const { eventId, streamId } = yospace;
        return { eventId, streamId };
    }

    getAdvertisingData(ovpData: PlayoutResponseData, sessionItem: OvpSessionItem): AdvertisingData | undefined {
        let advertising: AdvertisingData | undefined;

        if (typeof ovpData.thirdParties === 'object') {
            const freewheel = ovpData.thirdParties.FREEWHEEL;
            const yospace = ovpData.thirdParties.YOSPACE;

            advertising = {} as AdvertisingData;
            if (freewheel) {
                Object.assign(advertising, this.getFreewheelAdvertisingData(sessionItem, freewheel));
            }
            if (yospace) {
                Object.assign(advertising, this.getYospaceAdvertisingData(sessionItem, yospace));
            }
        }

        return advertising;
    }

    getAddonData(ovpData: PlayoutResponseData, sessionItem: OvpSessionItem): AddonPlayoutData {
        const assetId = this.getAssetId(ovpData);
        const addonData: AddonPlayoutData = { assetMetadata: { assetId } };

        if (ovpData.thirdParties && ovpData.thirdParties.FRV4) {
            const { token, userId } = ovpData.thirdParties.FRV4;
            addonData.watermarking = { token, userId };
        }

        if (ovpData.thirdParties && ovpData.thirdParties.FRIENDS && !ovpData.thirdParties.FRV4) {
            const { token: accessToken, userId, sourceName } = ovpData.thirdParties.FRIENDS;
            addonData.watermarking = { accessToken, userId, sourceName };
        }

        if (ovpData.events && ovpData.events.heartbeat) {
            const { url, frequency, allowedMissed } = ovpData.events.heartbeat;
            addonData.heartbeat = {
                allowedMissed,
                frequencyMs: frequency,
                url,
            };
        }

        if (ovpData?.session?.prePlayoutId && ovpData?.session?.metadataToken) {
            addonData.prefetch = {
                prePlayoutId: ovpData.session.prePlayoutId,
                metadataToken: ovpData.session.metadataToken,
            };
        }

        if (ovpData?.schedule) {
            addonData.programSchedule = ovpData.schedule;
        }

        if ('nielsenTrackingType' in ovpData && ovpData.nielsenTrackingType) {
            const nielsenTrackingTypeMap: { [key in OvpNielsenTrackingType]: NielsenTrackingType } = {
                [OvpNielsenTrackingType.DCR]: NielsenTrackingType.DCR,
                [OvpNielsenTrackingType.DTVR]: NielsenTrackingType.DTVR,
                [OvpNielsenTrackingType.not_required]: NielsenTrackingType.NOT_REQUIRED,
            };

            addonData.nielsen = { nielsenTrackingType: nielsenTrackingTypeMap[ovpData.nielsenTrackingType] };
        }

        const advertising = this.getAdvertisingData(ovpData, sessionItem);
        if (advertising) {
            addonData.advertising = advertising;
        }

        return addonData;
    }

    getPosition(sessionItem: OvpSessionItem, ovpBookmark: Partial<BookmarkData> = {}): number | undefined {
        if (typeof sessionItem.startPosition === 'number') {
            return sessionItem.startPosition;
        } else if ('position' in ovpBookmark) {
            return Number(ovpBookmark.position);
        }
    }

    getAssetId(ovpData: PlayoutResponseData): string {
        if ('serviceKey' in ovpData) {
            return ovpData.serviceKey;
        } else {
            return ovpData.contentId;
        }
    }
}
