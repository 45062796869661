import type { AdInsertionConfig, AddonConfig, CommonAddonsConfig } from '../../internal-config';
import type { ClientConfigSplit, RemoteConfigSplit } from '../../sdk-config';
import type { DeclaredOptionals, DeclaredOptionalsNested } from '../../../utils/ts';
import type { InternalAddonsConfig } from './addons-config-adapter-common';

import * as EventBoundaryAdapter from './event-boundary-config-adapter';
import * as PineAdapter from './pine-config-adapter';
import * as StreamMetadataFiltererAdapter from './stream-metadata-filterer-config-adapter';
import * as HeartbeatAdapter from './heartbeat-config-adapter';
import * as BookmarkUpdateEnhancerAdapter from './bookmark-update-enhancer-config-adapter';
import * as WatermarkingAdapter from './watermarking-config-adapter';
import * as ComscoreAdapter from './comscore-config-adapter';
import * as NielsenAdapter from './nielsen-config-adapter';
import * as AdobeMediaAnalyticsAdapter from './adobe-media-analytics-config-adapter';
import * as ConvivaAdapter from './conviva-config-adapter';
import * as OcellusAdapter from './ocellus-config-adapter';
import * as MParticleAdapter from './m-particle-config-adapter';
import * as OpenMeasurementAdapter from './open-measurement-config-adapter';
import * as StreamProxyAdapter from './stream-proxy-config-adapter';
import * as AdInsertionAdapter from './ad-insertion-config-adapter/ad-insertion-config-adapter';
import * as MseWrapAdapter from './mse-wrap-config-adapter';
import * as AgfNielsenAdapter from './agf-nielsen-config-adapter';
import * as DisableAnalyticsForAutoplayTrailerAdapter from './disable-analytics-for-autoplay-trailers-adapter';

// Client Config when Remote Config is disabled
export type SdkAddonsClientConfig = {
    common?: CommonAddonsConfig;
    eventBoundary?: EventBoundaryAdapter.EventBoundaryClientConfig;
    pine?: PineAdapter.PineClientConfig;
    mseWrap?: MseWrapAdapter.MseWrapClientConfig;
    streamMetadataFilterer?: StreamMetadataFiltererAdapter.StreamMetadataFiltererClientConfig;
    heartbeat?: HeartbeatAdapter.HeartbeatClientConfig;
    bookmarkUpdateEnhancer?: BookmarkUpdateEnhancerAdapter.BookmarkUpdateEnhancerClientConfig;
    watermarking?: WatermarkingAdapter.WatermarkingClientConfig;
    reporting?: {
        disableAnalyticsForAutoPlayTrailers?: DisableAnalyticsForAutoplayTrailerAdapter.DisableAnalyticsForAutoPlayTrailersClientConfig;
        agfNielsen?: AgfNielsenAdapter.AgfNielsenClientConfig;
        comscore?: ComscoreAdapter.ComscoreClientConfig;
        nielsen?: NielsenAdapter.NielsenClientConfig;
        adobe?: AdobeMediaAnalyticsAdapter.AdobeMediaAnalyticsClientConfig;
        conviva?: ConvivaAdapter.ConvivaClientConfig;
        ocellus?: OcellusAdapter.OcellusClientConfig;
        mParticle?: MParticleAdapter.MParticleClientConfig;
        openMeasurement?: OpenMeasurementAdapter.OpenMeasurementClientConfig;
    };
    adInsertion?: AdInsertionAdapter.AdInsertionClientConfig;
    streamProxy?: StreamProxyAdapter.StreamProxyClientConfig;
    ituQualityAssessment?: AddonConfig<{}>;
    linearContentEmbargo?: AddonConfig<object>;
};

export type SdkAddonsDefaultConfig = {
    reporting?: {
        conviva?: ConvivaAdapter.ConvivaDefaultConfig;
    };
};

export type SdkAddonsClientConfigSplit = ClientConfigSplit<SdkAddonsDefaultConfig, SdkAddonsClientConfig>;

export function adaptSdkAddonsClientConfig(defaultClientConfigSplit: SdkAddonsClientConfigSplit = {}): DeclaredOptionalsNested<InternalAddonsConfig> {
    const { defaultConfig = {}, clientConfig = {} }: SdkAddonsClientConfigSplit = defaultClientConfigSplit;

    return {
        common: clientConfig.common,
        eventBoundary: EventBoundaryAdapter.adaptEventBoundaryClientConfig(clientConfig.eventBoundary),
        pine: PineAdapter.adaptPineClientConfig(clientConfig.pine),
        mseWrap: MseWrapAdapter.adaptMseWrapClientConfig(clientConfig.mseWrap),
        streamMetadataFilterer: StreamMetadataFiltererAdapter.adaptStreamMetadataFiltererClientConfig(clientConfig.streamMetadataFilterer),
        heartbeat: HeartbeatAdapter.adaptHeartbeatClientConfig(clientConfig.heartbeat),
        bookmarkUpdateEnhancer: BookmarkUpdateEnhancerAdapter.adaptBookmarkUpdateEnhancerClientConfig(clientConfig.bookmarkUpdateEnhancer),
        watermarking: WatermarkingAdapter.adaptWatermarkingClientConfig(clientConfig.watermarking),
        reporting: {
            disableAnalyticsForAutoPlayTrailers: DisableAnalyticsForAutoplayTrailerAdapter.adaptDisableAnalyticsForAutoPlayTrailersClientConfig(
                clientConfig.reporting?.disableAnalyticsForAutoPlayTrailers
            ),
            agfNielsen: AgfNielsenAdapter.adaptAgfNielsenClientConfig(clientConfig.reporting?.agfNielsen),
            nielsen: NielsenAdapter.adaptNielsenClientConfig(clientConfig.reporting?.nielsen),
            conviva: ConvivaAdapter.adaptConvivaClientConfig({
                clientConfig: clientConfig.reporting?.conviva,
                defaultConfig: defaultConfig.reporting?.conviva,
            }),
            comscore: ComscoreAdapter.adaptComscoreClientConfig(clientConfig.reporting?.comscore),
            adobe: AdobeMediaAnalyticsAdapter.adaptAdobeMediaAnalyticsClientConfig(clientConfig.reporting?.adobe),
            ocellus: OcellusAdapter.adaptOcellusClientConfig(clientConfig.reporting?.ocellus),
            mParticle: MParticleAdapter.adaptMParticleClientConfig(clientConfig.reporting?.mParticle),
            openMeasurement: OpenMeasurementAdapter.adaptOpenMeasurementClientConfig(clientConfig.reporting?.openMeasurement),
        },
        adInsertion: AdInsertionAdapter.adaptAdInsertionClientConfig(clientConfig.adInsertion) as DeclaredOptionals<AdInsertionConfig>,
        streamProxy: StreamProxyAdapter.adaptStreamProxyClientConfig(clientConfig.streamProxy),
        ituQualityAssessment: clientConfig.ituQualityAssessment,
        linearContentEmbargo: clientConfig.linearContentEmbargo,
    };
}

type ConfigObject<T> = { config: T };

// Client Config when Remote Config is enabled
type SdkAddonsRemoteClientConfig = {
    common?: CommonAddonsConfig;
    eventBoundary?: ConfigObject<EventBoundaryAdapter.EventBoundaryRemoteConfigSplit['clientConfig']>;
    pine?: ConfigObject<PineAdapter.PineRemoteConfigSplit['clientConfig']>;
    mseWrap?: ConfigObject<MseWrapAdapter.MseWrapRemoteConfigSplit['clientConfig']>;
    streamMetadataFilterer?: ConfigObject<StreamMetadataFiltererAdapter.StreamMetadataFiltererRemoteConfigSplit['clientConfig']>;
    heartbeat?: ConfigObject<HeartbeatAdapter.HeartbeatRemoteConfigSplit['clientConfig']>;
    bookmarkUpdateEnhancer?: ConfigObject<BookmarkUpdateEnhancerAdapter.BookmarkUpdateEnhancerRemoteConfigSplit['clientConfig']>;
    watermarking?: ConfigObject<WatermarkingAdapter.WatermarkingRemoteConfigSplit['clientConfig']>;
    reporting?: {
        disableAnalyticsForAutoPlayTrailers?: ConfigObject<
            DisableAnalyticsForAutoplayTrailerAdapter.DisableAnalyticsForAutoPlayTrailersRemoteConfigSplit['clientConfig']
        >;
        agfNielsen?: ConfigObject<AgfNielsenAdapter.AgfNielsenRemoteConfigSplit['clientConfig']>;
        comscore?: ConfigObject<ComscoreAdapter.ComscoreRemoteConfigSplit['clientConfig']>;
        nielsen?: ConfigObject<NielsenAdapter.NielsenRemoteConfigSplit['clientConfig']>;
        adobe?: ConfigObject<AdobeMediaAnalyticsAdapter.AdobeMediaAnalyticsRemoteConfigSplit['clientConfig']>;
        conviva?: ConfigObject<ConvivaAdapter.ConvivaRemoteConfigSplit['clientConfig']>;
        ocellus?: ConfigObject<OcellusAdapter.OcellusRemoteConfigSplit['clientConfig']>;
        mParticle?: ConfigObject<MParticleAdapter.MParticleRemoteConfigSplit['clientConfig']>;
        openMeasurement?: ConfigObject<OpenMeasurementAdapter.OpenMeasurementRemoteConfigSplit['clientConfig']>;
    };
    adInsertion?: AdInsertionAdapter.AdInsertionRemoteConfigSplit['clientConfig'];
    streamProxy?: ConfigObject<StreamProxyAdapter.StreamProxyRemoteConfigSplit['clientConfig']>;
    ituQualityAssessment?: AddonConfig<{}>;
    linearContentEmbargo?: AddonConfig<object>;
};

// Server Config when Remote Config is enabled
type SdkAddonsRemoteServerConfig = {
    eventBoundary?: EventBoundaryAdapter.EventBoundaryRemoteConfigSplit['remoteConfig'];
    pine?: PineAdapter.PineRemoteConfigSplit['remoteConfig'];
    mseWrap?: MseWrapAdapter.MseWrapRemoteConfigSplit['remoteConfig'];
    streamMetadataFilterer?: StreamMetadataFiltererAdapter.StreamMetadataFiltererRemoteConfigSplit['remoteConfig'];
    heartbeat?: HeartbeatAdapter.HeartbeatRemoteConfigSplit['remoteConfig'];
    bookmarkUpdateEnhancer?: BookmarkUpdateEnhancerAdapter.BookmarkUpdateEnhancerRemoteConfigSplit['remoteConfig'];
    watermarking?: WatermarkingAdapter.WatermarkingRemoteConfigSplit['remoteConfig'];
    reporting?: {
        disableAnalyticsForAutoPlayTrailers?: DisableAnalyticsForAutoplayTrailerAdapter.DisableAnalyticsForAutoPlayTrailersRemoteConfigSplit['remoteConfig'];
        agfNielsen?: AgfNielsenAdapter.AgfNielsenRemoteConfigSplit['remoteConfig'];
        comscore?: ComscoreAdapter.ComscoreRemoteConfigSplit['remoteConfig'];
        nielsen?: NielsenAdapter.NielsenRemoteConfigSplit['remoteConfig'];
        adobe?: AdobeMediaAnalyticsAdapter.AdobeMediaAnalyticsRemoteConfigSplit['remoteConfig'];
        conviva?: ConvivaAdapter.ConvivaRemoteConfigSplit['remoteConfig'];
        ocellus?: OcellusAdapter.OcellusRemoteConfigSplit['remoteConfig'];
        mParticle?: MParticleAdapter.MParticleRemoteConfigSplit['remoteConfig'];
        openMeasurement?: OpenMeasurementAdapter.OpenMeasurementRemoteConfigSplit['remoteConfig'];
    };
    adInsertion?: AdInsertionAdapter.AdInsertionRemoteConfigSplit['remoteConfig'];
    streamProxy?: StreamProxyAdapter.StreamProxyRemoteConfigSplit['remoteConfig'];
};

export type SdkAddonsRemoteConfigSplit = RemoteConfigSplit<SdkAddonsRemoteServerConfig, SdkAddonsRemoteClientConfig>;

export function adaptSdkAddonsRemoteConfigSplit(config: SdkAddonsRemoteConfigSplit): DeclaredOptionalsNested<InternalAddonsConfig> {
    const { clientConfig = {}, remoteConfig = {} } = config;

    return {
        common: clientConfig.common,
        eventBoundary: EventBoundaryAdapter.adaptEventBoundaryRemoteConfigSplit({
            clientConfig: clientConfig.eventBoundary?.config,
            remoteConfig: remoteConfig.eventBoundary,
        }),
        pine: PineAdapter.adaptPineRemoteConfigSplit({ clientConfig: clientConfig.pine?.config, remoteConfig: remoteConfig.pine }),
        mseWrap: MseWrapAdapter.adaptMseWrapRemoteConfigSplit({ clientConfig: clientConfig.mseWrap?.config, remoteConfig: remoteConfig.mseWrap }),
        streamMetadataFilterer: StreamMetadataFiltererAdapter.adaptStreamMetadataFiltererRemoteConfigSplit({
            clientConfig: clientConfig.streamMetadataFilterer?.config,
            remoteConfig: remoteConfig.streamMetadataFilterer,
        }),
        heartbeat: HeartbeatAdapter.adaptHeartbeatRemoteConfigSplit({
            clientConfig: clientConfig.heartbeat?.config,
            remoteConfig: remoteConfig.heartbeat,
        }),
        bookmarkUpdateEnhancer: BookmarkUpdateEnhancerAdapter.adaptBookmarkUpdateEnhancerRemoteConfigSplit({
            clientConfig: clientConfig.bookmarkUpdateEnhancer?.config,
            remoteConfig: remoteConfig.bookmarkUpdateEnhancer,
        }),
        watermarking: WatermarkingAdapter.adaptWatermarkingRemoteConfigSplit({
            clientConfig: clientConfig.watermarking?.config,
            remoteConfig: remoteConfig.watermarking,
        }),
        reporting: {
            disableAnalyticsForAutoPlayTrailers: DisableAnalyticsForAutoplayTrailerAdapter.adaptDisableAnalyticsForAutoPlayTrailersRemoteConfigSplit({
                clientConfig: clientConfig.reporting?.disableAnalyticsForAutoPlayTrailers?.config,
                remoteConfig: remoteConfig.reporting?.disableAnalyticsForAutoPlayTrailers,
            }),
            agfNielsen: AgfNielsenAdapter.adaptAgfNielsenRemoteConfigSplit({
                clientConfig: clientConfig.reporting?.agfNielsen?.config,
                remoteConfig: remoteConfig.reporting?.agfNielsen,
            }),
            nielsen: NielsenAdapter.adaptNielsenRemoteConfigSplit({
                clientConfig: clientConfig.reporting?.nielsen?.config,
                remoteConfig: remoteConfig.reporting?.nielsen,
            }),
            conviva: ConvivaAdapter.adaptConvivaRemoteConfigSplit({
                clientConfig: clientConfig.reporting?.conviva?.config,
                remoteConfig: remoteConfig.reporting?.conviva,
            }),
            comscore: ComscoreAdapter.adaptComscoreRemoteConfigSplit({
                clientConfig: clientConfig.reporting?.comscore?.config,
                remoteConfig: remoteConfig.reporting?.comscore,
            }),
            adobe: AdobeMediaAnalyticsAdapter.adaptAdobeMediaAnalyticsRemoteConfigSplit({
                clientConfig: clientConfig.reporting?.adobe?.config,
                remoteConfig: remoteConfig.reporting?.adobe,
            }),
            ocellus: OcellusAdapter.adaptOcellusRemoteConfigSplit({
                clientConfig: clientConfig.reporting?.ocellus?.config,
                remoteConfig: remoteConfig.reporting?.ocellus,
            }),
            mParticle: MParticleAdapter.adaptMParticleRemoteConfigSplit({
                clientConfig: clientConfig.reporting?.mParticle?.config,
                remoteConfig: remoteConfig.reporting?.mParticle,
            }),
            openMeasurement: OpenMeasurementAdapter.adaptOpenMeasurementRemoteConfigSplit({
                clientConfig: clientConfig.reporting?.openMeasurement?.config,
                remoteConfig: remoteConfig.reporting?.openMeasurement,
            }),
        },
        adInsertion: AdInsertionAdapter.adaptAdInsertionRemoteConfigSplit({
            clientConfig: clientConfig.adInsertion,
            remoteConfig: remoteConfig.adInsertion,
        }),
        streamProxy: StreamProxyAdapter.adaptStreamProxyRemoteConfigSplit({
            clientConfig: clientConfig.streamProxy?.config,
            remoteConfig: remoteConfig.streamProxy,
        }),
        ituQualityAssessment: clientConfig.ituQualityAssessment,
        linearContentEmbargo: clientConfig.linearContentEmbargo,
    };
}
