import type { AdInsertionConfig } from '../../../internal-config';
import type { RemoteConfigSplit } from '../../../sdk-config';
import type { DeclaredOptionals } from '../../../../utils/ts';
import type { RequiredPropertiesDefinition } from '../addons-config-adapter-common';
import { checkRequiredConfigProperties } from '../addons-config-adapter-common';
import type { Vac } from '../../../../addons/vac/vac-addon';
import { VacType } from '../../../../addons/vac/base';
import type { ClientProvidedVac } from '../../../../addons/vac/client-provided-vac/client-provided-vac-addon';
import type { Vam } from '../../../../addons/vac/vam/vam-addon';

// Internal Config
type VacInternalConfig = AdInsertionConfig['vac'];

// Client Config when Remote Config is disabled
export type VacClientConfig = Vac.Config;
export function adaptVacClientConfig(config: VacClientConfig | undefined): VacInternalConfig {
    return config;
}

type VamRemoteConfigProperties = 'type' | 'requestTimeoutMs';
// Server Config when Remote Config is enabled
type VamRemoteServerConfig = Pick<Vam.Config, VamRemoteConfigProperties>;

// Client Config when Remote Config is enabled
type VamRemoteClientConfig = Omit<Vam.Config, VamRemoteConfigProperties>;

type VamRemoteConfigSplit = RemoteConfigSplit<VamRemoteServerConfig, VamRemoteClientConfig>;
function adaptVamRemoteConfigSplit(config: VamRemoteConfigSplit): DeclaredOptionals<VacInternalConfig> {
    const { remoteConfig, clientConfig } = config;
    if (!remoteConfig) {
        return;
    }

    const requiredClientFields: RequiredPropertiesDefinition<VamRemoteClientConfig> = {
        requestData: null,
        url: null,
    };

    const missingProperties = checkRequiredConfigProperties(clientConfig || {}, requiredClientFields);
    if (missingProperties.length > 0) {
        return;
    }

    return {
        type: VacType.VAM,
        requestTimeoutMs: remoteConfig.requestTimeoutMs,
        url: clientConfig!.url,
        doubleBoxEnabled: clientConfig?.doubleBoxEnabled,
        requestData: clientConfig!.requestData,
    };
}

type ClientProvidedVacRemoteConfigProperties = 'type';
// Server Config when Remote Config is enabled
type ClientProvidedVacRemoteServerConfig = Pick<ClientProvidedVac.Config, ClientProvidedVacRemoteConfigProperties>;

// Client Config when Remote Config is enabled
type ClientProvidedVacRemoteClientConfig = Omit<ClientProvidedVac.Config, ClientProvidedVacRemoteConfigProperties>;

type ClientProvidedVacRemoteConfigSplit = RemoteConfigSplit<ClientProvidedVacRemoteServerConfig, ClientProvidedVacRemoteClientConfig>;
function adaptClientProvidedVacRemoteConfigSplit(config: ClientProvidedVacRemoteConfigSplit): DeclaredOptionals<VacInternalConfig> {
    const { clientConfig } = config;
    const requiredClientFields: RequiredPropertiesDefinition<ClientProvidedVacRemoteClientConfig> = {
        vacDataProvider: null,
    };

    const missingProperties = checkRequiredConfigProperties(clientConfig || {}, requiredClientFields);
    if (missingProperties.length > 0) {
        return;
    }

    return {
        type: VacType.CLIENTPROVIDED,
        vacDataProvider: clientConfig!.vacDataProvider,
        url: clientConfig!.url,
    };
}

type VacConfigTypeMap = {
    [VacType.VAM]: VamRemoteConfigSplit;
    [VacType.CLIENTPROVIDED]: ClientProvidedVacRemoteConfigSplit;
};

type VacRemoteConfigAdapterMap = { [key in VacType]: (config: VacConfigTypeMap[key]) => DeclaredOptionals<VacInternalConfig> };

const vacRemoteConfigAdapterMap: VacRemoteConfigAdapterMap = {
    [VacType.VAM]: adaptVamRemoteConfigSplit,
    [VacType.CLIENTPROVIDED]: adaptClientProvidedVacRemoteConfigSplit,
};

export type VacRemoteServerConfig<T extends VacType = VacType> = T extends VacType.VAM
    ? VamRemoteServerConfig
    : T extends VacType.CLIENTPROVIDED
      ? ClientProvidedVacRemoteServerConfig
      : VamRemoteServerConfig | ClientProvidedVacRemoteServerConfig;
export type VacRemoteClientConfig<T extends VacType = VacType> = T extends VacType.VAM
    ? VamRemoteClientConfig
    : T extends VacType.CLIENTPROVIDED
      ? ClientProvidedVacRemoteClientConfig
      : VamRemoteClientConfig | ClientProvidedVacRemoteClientConfig;
export type VacRemoteConfigSplit<T extends VacType = VacType> = RemoteConfigSplit<VacRemoteServerConfig<T>, VacRemoteClientConfig<T>>;

export function adaptVacRemoteConfigSplit(config: VacRemoteConfigSplit): DeclaredOptionals<VacInternalConfig> {
    const vacType = config.remoteConfig?.type;
    if (!vacType) {
        return;
    }

    const adapter = vacRemoteConfigAdapterMap[vacType];
    if (!adapter) {
        return;
    }

    // Can't find a way to fix the typing so that the config is
    // recognised as matching the type for the adapter so I have
    // to use `any` here
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return adapter(config as any);
}
