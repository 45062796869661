import { AdInsertionType } from '../../addons/ad-insertion-type';
import type { AddonPlayoutData, ReportingData } from '../../addons/addon-playout-data';
import type { Ad, AdBreak, SsaiStitcherType } from '../../addons/adverts/common';
import type { Vac } from '../../addons/vac/vac-addon';

import type { LiveWindow } from './player-engine-item';
import type {
    AdsFailoverReason,
    DrmType,
    DvrWindowDuration,
    MediaContainer,
    PlaybackType,
    Source,
    StreamQuality,
    StreamVendor,
    StreamingProtocol,
} from './player.enums';
import { AdInsertionStrategy } from './player.enums';

export {
    AdInsertionStrategy,
    AdsFailoverReason,
    DrmType,
    DvrWindowDuration,
    MediaContainer,
    PlaybackType,
    Source,
    StreamQuality,
    StreamVendor,
    StreamingProtocol,
    PlayoutDataError,
    DownloadState,
    DrmProvider,
    MultiviewParallelAudioTracks,
} from './player.enums';

import type { ExternalTrack, Track } from './track';
import type { VideoColourSpace } from './video-format';

/**
 * @internal Playout attributes needed for the player to actually playing
 *         Stream parameters needed to play the actual stream
 */
export interface Cdn {
    url: string;
    name: string;
    adsUrl?: string;
    originalUrl?: string;
    index?: number;
    streamInfo?: LinearStreamInfo;
}

/**
 * @internal Needed DRM protection attributes
 *
 */
interface CommonDrmConfiguration {
    type: DrmType;
    licenceAcquisitionUrl: string;
    headers?: {
        [k: string]: string;
    };
    certificateUrl?: string;
}

export interface MarlinDrmConfiguration extends CommonDrmConfiguration {
    type: DrmType.Marlin;
}

export interface PlayReadyDrmConfiguration extends CommonDrmConfiguration {
    type: DrmType.PlayReady;
    customChallengeData?: string;
    deviceId?: string;
}

export interface WidevineDrmConfiguration extends CommonDrmConfiguration {
    type: DrmType.Widevine;
}

export interface FairplayDrmConfiguration extends CommonDrmConfiguration {
    type: DrmType.Fairplay;
}

/**
 * @public
 */
export type DrmConfiguration = MarlinDrmConfiguration | PlayReadyDrmConfiguration | WidevineDrmConfiguration | FairplayDrmConfiguration;

/**
 * @internal Support for HTML element to wrap the video element
 *         and also supports adding a css class name to it
 *
 */
export interface VideoHtmlContainerInfo {
    videoHTMLContainer: HTMLElement;
    videoElementClassName?: string;
}

/**
 * @internal Supports setting visual properties to the native player, either with exact
 *         pixel measurements or by specifying a percentage of the player size
 */
export type PlayoutRect<T extends string | number = number> = {
    x: T;
    y: T;
    width: T;
    height: T;
    zIndex?: number;
    backgroundColor?: string;
};

/**
 * @internal Adaptative bit rate (audio + video) configuration
 *         Unit: Bits per Second (b/s)
 */
export interface PlayerBitrateLimits {
    maxBitRate?: number;
    minBitRate?: number;
}

export interface ShakaAbrConfiguration {
    minimumUpdatePeriodOverride: number;
    suggestedPresentationDelayOverride: number;
}

export type AbrConfiguration = ShakaAbrConfiguration;

export type AdInsertionConfig = {
    ssaiStitcherType?: SsaiStitcherType;
    adInsertionStrategy: AdInsertionStrategy;
    isPlayerAdEventsRequired: boolean;
    isPlayerAdBreakDataRequired: boolean;
    isTimelineAdManagementRequired: boolean;
    adProvider: AdInsertionType;
};

/**
 * @public
 */
export interface Stream {
    protocol: StreamingProtocol;
    container?: MediaContainer;
    protection?: DrmType;
    quality?: StreamQuality;
    audioCodec?: string;
    videoCodec?: string;
    colourSpace?: VideoColourSpace;
    frameRate?: string;
}

export type UnknownLinearStreamInfo = {
    vendor: StreamVendor.Unknown;
};

export type KnownLinearStreamInfo = {
    vendor: StreamVendor.Harmonic | StreamVendor.IStreamPlanet;
    windowDuration: DvrWindowDuration;
};

export type LinearStreamInfo = KnownLinearStreamInfo | UnknownLinearStreamInfo;

export type LinearPositionInfo = {
    position: number;
    liveWindow?: LiveWindow;
};

export interface PlayoutRules {
    dvrWindowSupported?: boolean;
}

export type AudioTrackMetadata = {
    languageTag: string; // what clients used to sent before as a string
    isAudioDescription?: boolean; // optional - Boolean on whether the language has audio description (AD) or not
};

export type SubtitleTrackMetadata = {
    languageTag: string; // what clients used to sent before as a string
};

/**
 * @internal Playout attributes needed for the player to actually play and render the stream's video
 *
 */
export type PlayerPlayoutData = {
    // DISCUSS: We should review if we need all this attributes
    //           e.g. at this time we maybe only need 1 cdn, the selection
    //                should be made before at a session level

    // DISCUSS: Should we have support for multiple streaming protocols?
    // Some web players can play different types of streaming protocols
    // (e.g. Shaka Player can play HLS and DASH)
    type: PlaybackType;
    cdns: Array<Cdn>;
    stream: Stream;
    source?: Source;
    autoplay?: boolean;
    // DISCUSS: muted, volume, position, audioLanguage, textLanguage
    //          should this (maybe) be inside a structure called "initialPlayerState" (or something similar)
    muted?: boolean;
    volume?: number;
    position?: number;
    linearPosition?: LinearPositionInfo;
    preferredAudioMetadata?: Array<AudioTrackMetadata>;
    preferredAudioLanguages?: Array<string>;
    preferredAudioTrack?: Track; // Used for VPF Retry to indicate information about a specific track (e.g. English+Surround vs English+Stereo)
    preferredSubtitleMetadata?: Array<SubtitleTrackMetadata>;
    preferredSubtitlesLanguages?: Array<string>;
    preferredSubtitlesTrack?: Track; // Used for VPF Retry to indicate information about a specific track (e.g. English+CEA vs English+WebVTT)
    initialBitrateBps?: number;
    abrConfiguration?: AbrConfiguration;
    drmConfiguration?: DrmConfiguration;
    // DISCUSS: Need for this custom field
    custom?: {
        [key: string]: unknown;
        nativeReporting?: ReportingData & { [key: string]: unknown };
        vac?: Vac.ResponseData;
        requiresMediaSequenceSync?: boolean;
    };
    // TODO: discuss the following attribute, it appears as it is not being used at all
    currentTimeChangedInterval?: number;
    playerBitrateLimits?: PlayerBitrateLimits;
    adInsertionConfig?: AdInsertionConfig;
    durationMs?: number;
    // Having Conviva custom tags in the base of 'PlayoutData' isn't great
    // We should create a new field `AddonPlayoutData.Conviva` or something similar
    adsFailoverReason?: AdsFailoverReason;
    failedCdns?: Array<string>;
    sessionId?: string;
    containsMandatoryPinEvents?: boolean;
    display?: {
        playerViewAlwaysPresentedFullScreen?: boolean;
    };
    externalTracks?: Array<ExternalTrack>;
    vamPrerollEnabled?: boolean;
    vamMidrollEnabled?: boolean;
    vamFailureStatus?: number;
    playoutRules?: PlayoutRules;
    csaiAdBreaks?: Array<AdBreak<Ad>>;
};

export function isMultiplayerCsai(playoutData: PlayoutData): boolean {
    const adInsertionConfig = playoutData.adInsertionConfig!;
    return adInsertionConfig?.adInsertionStrategy === AdInsertionStrategy.CSAI && adInsertionConfig.adProvider === AdInsertionType.MultiPlayerCsai;
}

export function isSleCsai(playoutData: PlayoutData): boolean {
    return !!playoutData.advertising?.vac?.sleCsai;
}

/**
 * @public
 */
export type PlayoutData = PlayerPlayoutData & AddonPlayoutData;
