import { type AndroidWindow, DeviceType } from '@sky-uk-ott/client-lib-js-device';

import { CoreVideoInternal } from '../../core-video-internal';

declare let self: AndroidWindow;

export function checkIsFireTv(explicitDeviceType?: DeviceType): boolean {
    const deviceType = explicitDeviceType || CoreVideoInternal.deviceType;
    return deviceType === DeviceType.Android && Boolean(self.Android?.isFire());
}

export function checkIsAndroidPrefetchEnabled(explicitDeviceType?: DeviceType): boolean {
    const deviceType = explicitDeviceType || CoreVideoInternal.deviceType;
    return deviceType === DeviceType.Android && Boolean(self.Android?.isPrefetchEnabled?.());
}

export function checkIsAndroidInfiniteDvrEnabled(): boolean {
    return Boolean(self.Android?.isInfiniteDvrEnabled?.());
}

export function checkIsThumbnailTimeIncludingAds(): boolean {
    return Boolean(self.Android?.isThumbnailTimeIncludingAds?.());
}

export function checkIs4k60fpsAllowed(): boolean {
    return Boolean(self.Android?.is4k60fpsAllowed?.());
}
