export enum TapeBundle {
    ALL_ES5,
    DEBUG_ES5,
    DASH_TTML_ES5,
    DASH_TTML_CEA_ES5,
    DASH_WEBVTT_ES5,
    DASH_WEBVTT_CEA_ES5,
    HLS_TTML_ES5,
    HLS_TTML_CEA_ES5,
    HLS_WEBVTT_ES5,
    HLS_WEBVTT_CEA_ES5,
    ALL_ESNEXT,
    DEBUG_ESNEXT,
    DASH_TTML_ESNEXT,
    DASH_TTML_CEA_ESNEXT,
    DASH_WEBVTT_ESNEXT,
    DASH_WEBVTT_CEA_ESNEXT,
    HLS_TTML_ESNEXT,
    HLS_TTML_CEA_ESNEXT,
    HLS_WEBVTT_ESNEXT,
    HLS_WEBVTT_CEA_ESNEXT,
}
