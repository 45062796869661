import type { Capabilities } from '@sky-uk-ott/client-lib-js-device';

import type { KnownLinearStreamInfo } from '../../core/player/playout-data';
import { DvrWindowDuration, StreamVendor } from '../../core/player/playout-data';
import { sdkLogger } from '../../logger';
import {
    HARMONIC_2H_PATH,
    HARMONIC_2M_PATH,
    HARMONIC_EVENT_PATH,
    HARMONIC_V1,
    HARMONIC_V1_2M_PATH,
    HARMONIC_V2,
    ISTREAMPLANET_2H_PATH,
    ISTREAMPLANET_2M_PATH,
    ISTREAMPLANET_EVENT_PATH,
} from '../../utils/stream-vendor';

interface LinearStreamEndpoint {
    stream: KnownLinearStreamInfo;
    buildRestrictedDvrWindowUrl: (endpointUrl: string) => string;
    buildExtendedDvrWindowUrl: (endpointUrl: string, capabilities?: Capabilities) => string;
    buildFullEventDvrWindowUrl: (endpointUrl: string) => string;
}

class HarmonicEndpoint implements LinearStreamEndpoint {
    public readonly stream: KnownLinearStreamInfo;
    constructor(stream: KnownLinearStreamInfo) {
        this.stream = stream;
    }

    public buildRestrictedDvrWindowUrl(endpointUrl: string): string {
        if (this.stream.windowDuration === DvrWindowDuration.Extended) {
            endpointUrl = endpointUrl.replace(HARMONIC_2H_PATH, HARMONIC_2M_PATH);
        } else if (this.stream.windowDuration === DvrWindowDuration.FullEvent) {
            endpointUrl = endpointUrl.replace(HARMONIC_EVENT_PATH, HARMONIC_2M_PATH);
        }
        return endpointUrl;
    }

    public buildExtendedDvrWindowUrl(endpointUrl: string): string {
        if (!endpointUrl.includes(HARMONIC_V2)) {
            endpointUrl = endpointUrl.replace(HARMONIC_V1, HARMONIC_V2).replace(HARMONIC_V1_2M_PATH, HARMONIC_2M_PATH);
        }

        if (this.stream.windowDuration === DvrWindowDuration.Restricted) {
            endpointUrl = endpointUrl.replace(HARMONIC_2M_PATH, HARMONIC_2H_PATH);
        } else if (this.stream.windowDuration === DvrWindowDuration.FullEvent) {
            endpointUrl = endpointUrl.replace(HARMONIC_EVENT_PATH, HARMONIC_2H_PATH);
        }

        return endpointUrl;
    }

    public buildFullEventDvrWindowUrl(endpointUrl: string): string {
        if (!endpointUrl.includes(HARMONIC_V2)) {
            endpointUrl = endpointUrl.replace(HARMONIC_V1, HARMONIC_V2).replace(HARMONIC_V1_2M_PATH, HARMONIC_2M_PATH);
        }

        if (this.stream.windowDuration === DvrWindowDuration.Restricted) {
            endpointUrl = endpointUrl.replace(HARMONIC_2M_PATH, HARMONIC_EVENT_PATH);
        } else if (this.stream.windowDuration === DvrWindowDuration.Extended) {
            endpointUrl = endpointUrl.replace(HARMONIC_2H_PATH, HARMONIC_EVENT_PATH);
        }

        return endpointUrl;
    }
}

class IStreamPlanetEndpoint implements LinearStreamEndpoint {
    public readonly stream: KnownLinearStreamInfo;
    constructor(stream: KnownLinearStreamInfo) {
        this.stream = stream;
    }

    public buildRestrictedDvrWindowUrl(endpointUrl: string): string {
        if (this.stream.windowDuration === DvrWindowDuration.Extended) {
            endpointUrl = endpointUrl.replace(ISTREAMPLANET_2H_PATH, ISTREAMPLANET_2M_PATH);
        } else if (this.stream.windowDuration === DvrWindowDuration.FullEvent) {
            endpointUrl = endpointUrl.replace(ISTREAMPLANET_EVENT_PATH, ISTREAMPLANET_2M_PATH);
        }
        return endpointUrl;
    }

    public buildExtendedDvrWindowUrl(endpointUrl: string, capabilities?: Capabilities): string {
        if (!capabilities?.supportsExtendedDvrOnIsp?.()) {
            return endpointUrl;
        }

        if (this.stream.windowDuration === DvrWindowDuration.Restricted) {
            endpointUrl = endpointUrl.replace(ISTREAMPLANET_2M_PATH, ISTREAMPLANET_2H_PATH);
        } else if (this.stream.windowDuration === DvrWindowDuration.FullEvent) {
            endpointUrl = endpointUrl.replace(ISTREAMPLANET_EVENT_PATH, ISTREAMPLANET_2H_PATH);
        }
        return endpointUrl;
    }

    public buildFullEventDvrWindowUrl(endpointUrl: string): string {
        sdkLogger.warn('FullEvent not supported for ISP');
        return endpointUrl;
    }
}

function assertUnknownVendor(vendor: never): never {
    throw new Error(`Unknown vendor: ${vendor}`);
}

export class LinearStreamEndpointFactory {
    public static fromVendor(stream: KnownLinearStreamInfo): LinearStreamEndpoint {
        switch (stream.vendor) {
            case StreamVendor.Harmonic:
                return new HarmonicEndpoint(stream);
            case StreamVendor.IStreamPlanet:
                return new IStreamPlanetEndpoint(stream);
            default:
                break;
        }
        return assertUnknownVendor(stream.vendor);
    }
}
