import type { Device } from '@sky-uk-ott/client-lib-js-device';

import type { InternalConfig, InternalConfigPerPlaybackType, InternalConfigDynamic, InternalConfigStatic } from './internal-config';
import { Proposition } from './internal-config';
import type { CoreVideoConfigProvider } from './core-video-config/core-video-config-provider';
import { CoreVideoConfigProvider as RemoteConfigProvider } from './core-video-config/core-video-config-provider';
import type { CoreVideoConfigCompiled } from './core-video-config/core-video-config-types';
import { isRemoteConfigEnabled } from './core-video-config/core-video-config-types';
import type { SdkConfig } from './sdk-config';
import { PlaybackType } from '../core/player/playout-data';
import { GLOBAL_MUTED_ERROR_CODES } from './conviva-blocklist';
import { LocalConfigProvider } from './local-config-provider';
import type { BufferingLimitConfig } from '../core/session-controller/timeline/buffering-limit';
import type { DeclaredOptionals } from '../utils/ts';
import { isSafariDesktop } from '../utils/device-type';

export type CompiledConfigLoaderFunc = (configPath: string) => Promise<CoreVideoConfigCompiled>;

export class InternalConfigProvider {
    static getForPlaybackType(configPerPlaybackType: InternalConfigPerPlaybackType, playbackType: PlaybackType | 'default'): InternalConfig {
        const dynamicConfig = configPerPlaybackType[playbackType] || configPerPlaybackType.default;
        return {
            ...configPerPlaybackType.staticConfig,
            ...dynamicConfig,
        };
    }

    private internalConfigPerPlaybackType?: InternalConfigPerPlaybackType;
    private externalConfigProvider?: CoreVideoConfigProvider | LocalConfigProvider;

    constructor(
        private sdkConfig: SdkConfig,
        private device: Device,
        private compiledConfigLoader: CompiledConfigLoaderFunc
    ) {}

    public async initialise() {
        if (isRemoteConfigEnabled(this.sdkConfig)) {
            this.externalConfigProvider = new RemoteConfigProvider(this.sdkConfig, this.device, this.compiledConfigLoader);
        } else {
            this.externalConfigProvider = new LocalConfigProvider(this.sdkConfig, this.device, this.compiledConfigLoader);
        }

        await this.externalConfigProvider.initialise();
        this.internalConfigPerPlaybackType = this.buildConfig();
    }

    public getConfig(): [InternalConfigPerPlaybackType, string] {
        if (!this.internalConfigPerPlaybackType || !this.externalConfigProvider) {
            throw new Error('Tried to retrieve config before internal config provider was initialised');
        }

        const failOverReason = this.externalConfigProvider?.getFailoverReason() || 'NA';

        if (!this.externalConfigProvider.checkHasConfigChanged(this.internalConfigPerPlaybackType.default.configMetadata.configIdentifier)) {
            return [this.internalConfigPerPlaybackType, failOverReason];
        }

        this.internalConfigPerPlaybackType = this.buildConfig();
        return [this.internalConfigPerPlaybackType, failOverReason];
    }

    private applyBufferingLimitModifications(config: InternalConfigStatic) {
        const defaultRebufferingLimitConfig: BufferingLimitConfig = {
            enableRebufferingLimit: true,
            enableSeekingLimit: false,
            maxMainContentBufferingSecs: 60,
            maxAdvertBufferingSecs: 10,
        };

        config.bufferingLimit = defaultRebufferingLimitConfig;

        const disableSafariBufferingLimit =
            ![Proposition.NowTV, Proposition.SkyStore].includes(config.proposition) &&
            isSafariDesktop(this.device.deviceInfo, this.device.type) &&
            config.bufferingLimit;
        if (disableSafariBufferingLimit) {
            config.bufferingLimit!.enableRebufferingLimit = false;
        }

        /**
         * Since we want to affect the config before it is passed to any component that means we can't use PropositionExtensions here.
         * So unfortunately for now we will need to have this condition here :(
         */
        if (config.bufferingLimit && [Proposition.NowTV, Proposition.SkyStore].includes(config.proposition)) {
            config.bufferingLimit.enableSeekingLimit = true;
        }
    }

    private applyGlobalReportingConfig(dynamicConfig: InternalConfigDynamic): void {
        if (!dynamicConfig.addons.reporting?.conviva) {
            return;
        }

        const mutedErrorCodes = [...(dynamicConfig.addons.reporting.conviva.config.mutedErrorCodes || []), ...GLOBAL_MUTED_ERROR_CODES];
        dynamicConfig.addons.reporting.conviva.config.mutedErrorCodes = mutedErrorCodes;
    }

    private applyCommonModifications(internalConfig: InternalConfigPerPlaybackType) {
        const { staticConfig, ...dynamicConfig } = internalConfig;
        this.applyBufferingLimitModifications(staticConfig);

        Object.values(dynamicConfig).forEach((config) => {
            this.applyGlobalReportingConfig(config);
        });
    }

    private buildStaticConfig(): DeclaredOptionals<InternalConfigStatic> {
        return {
            proposition: this.sdkConfig.proposition,
            devices: this.sdkConfig.devices,
            deviceOverride: this.sdkConfig.deviceOverride,
            playerOverride: this.sdkConfig.playerOverride,
            debug: this.sdkConfig.debug,
            drm: this.sdkConfig.drm,
            vpi: this.sdkConfig.vpi,
            bufferingLimit: undefined,
        };
    }

    private buildConfig(): InternalConfigPerPlaybackType {
        const configProvider = this.externalConfigProvider;
        if (!configProvider) {
            throw new Error('Tried to retrieve config before internal config provider was initialised');
        }

        const internalConfigPerPlaybackType: InternalConfigPerPlaybackType = {
            staticConfig: this.buildStaticConfig(),
            default: configProvider.buildInternalConfig('default', this.device),
        };

        Object.keys(PlaybackType).forEach((playbackTypeKey) => {
            const playbackType = playbackTypeKey as PlaybackType;
            const internalConfig = configProvider.buildInternalConfig(playbackType, this.device);
            if (internalConfig) {
                internalConfigPerPlaybackType[playbackType] = internalConfig;
            }
        });

        this.applyCommonModifications(internalConfigPerPlaybackType);
        return internalConfigPerPlaybackType;
    }
}
