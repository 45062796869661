import { sdkLogger } from '../logger';
import { JsonUtils } from '../utils/json-utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LogVerbose: any = (name = '') =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function (target: Object, propertyName: string, descriptor: TypedPropertyDescriptor<any>): TypedPropertyDescriptor<any> {
        try {
            const originalDescriptor = descriptor.value;

            const ctxName = name || target.constructor.name;

            if (typeof originalDescriptor !== 'function') {
                throw new Error(`Target member must be a function`);
            }

            descriptor.value = function (...args: Array<any>) {
                sdkLogger.verbose(`Called ${ctxName}.${propertyName}:`, ...args.map(JsonUtils.stringify));
                return originalDescriptor.apply(this, args);
            };
        } catch (e) {
            sdkLogger.warn(`LogVerbose decorator error on property: "${propertyName}"`);
        }

        return descriptor;
    };
