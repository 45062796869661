import type { ClientComscoreAddonConfig } from '../../../addons/reporting/comscore-addon/comscore-addon';
import type { AddonConfig } from '../../internal-config';
import type { RemoteConfigSplit } from '../../sdk-config';
import type { DeclaredOptionalsAddonsConfig, InternalReportingConfig, RequiredPropertiesDefinition } from './addons-config-adapter-common';
import { genericConfigEnabledCheck } from './addons-config-adapter-common';

// Internal Config
type ComscoreInternalConfig = InternalReportingConfig['comscore'];

// Client Config when Remote Config is disabled
export type ComscoreClientConfig = AddonConfig<ClientComscoreAddonConfig>;
export function adaptComscoreClientConfig(config: ComscoreClientConfig | undefined): ComscoreInternalConfig {
    return config;
}

// Server Config when Remote Config is enabled
type ComscoreRemoteServerConfig = AddonConfig<{}>;

// Client Config when Remote Config is enabled
type ComscoreRemoteClientConfig = ClientComscoreAddonConfig;

export type ComscoreRemoteConfigSplit = RemoteConfigSplit<ComscoreRemoteServerConfig, ComscoreRemoteClientConfig>;
export function adaptComscoreRemoteConfigSplit(config: ComscoreRemoteConfigSplit): DeclaredOptionalsAddonsConfig<ComscoreInternalConfig> {
    const requiredFields: RequiredPropertiesDefinition<ComscoreRemoteClientConfig> = { customerC2: null, publisherSecret: null };
    const isEnabled = genericConfigEnabledCheck(config, requiredFields, 'Comscore');
    if (!isEnabled) {
        return;
    }

    const { clientConfig } = config;
    return {
        enabled: isEnabled,
        config: {
            customerC2: clientConfig!.customerC2,
            publisherSecret: clientConfig!.publisherSecret,
            allowOfflineTransmission: clientConfig!.allowOfflineTransmission,
        },
    };
}
