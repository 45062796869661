import type { ConvivaConfig } from '../../../addons/reporting/reporting-addon';
import type { AddonConfig } from '../../internal-config';
import type { ClientConfigSplit, RemoteConfigSplit } from '../../sdk-config';
import type { DeclaredOptionalsAddonsConfig, InternalAddonsConfig, RequiredPropertiesDefinition } from './addons-config-adapter-common';
import { genericConfigEnabledCheck } from './addons-config-adapter-common';

// Internal Config
type InternalReportingConfig = NonNullable<InternalAddonsConfig['reporting']>;
type ConvivaInternalConfig = InternalReportingConfig['conviva'];

// Client Config when Remote Config is disabled
type ConvivaDefaultConfigProperties = 'shouldReportChipsetWithModel';
export type ConvivaClientConfig = AddonConfig<Omit<ConvivaConfig, ConvivaDefaultConfigProperties>>;
export type ConvivaDefaultConfig = AddonConfig<Pick<ConvivaConfig, ConvivaDefaultConfigProperties>>;
export type ConvivaClientConfigSplit = ClientConfigSplit<ConvivaDefaultConfig, ConvivaClientConfig>;

export function adaptConvivaClientConfig(config: ConvivaClientConfigSplit = {}): DeclaredOptionalsAddonsConfig<ConvivaInternalConfig> {
    const { defaultConfig = {} as ConvivaDefaultConfig, clientConfig = {} as ConvivaClientConfig } = config;
    const isEnabled = Boolean(clientConfig.enabled);
    return {
        enabled: isEnabled,
        config: {
            // Default config values
            shouldReportChipsetWithModel: defaultConfig.config?.shouldReportChipsetWithModel,
            // Client config values
            heartbeatInterval: clientConfig.config?.heartbeatInterval,
            mutedErrorCodes: clientConfig.config?.mutedErrorCodes,
            enableAdInsights: clientConfig.config?.enableAdInsights,
            customerKey: clientConfig.config?.customerKey,
            gatewayUrl: clientConfig.config?.gatewayUrl,
            isDebug: clientConfig.config?.isDebug,
        },
    };
}

type ConvivaRemoteConfigProperties = 'heartbeatInterval' | 'mutedErrorCodes' | 'enableAdInsights' | 'shouldReportChipsetWithModel';

// Server Config when Remote Config is enabled
type ConvivaRemoteServerConfig = AddonConfig<Pick<ConvivaConfig, ConvivaRemoteConfigProperties>>;

// Client Config when Remote Config is enabled
type ConvivaRemoteClientConfig = Omit<ConvivaConfig, ConvivaRemoteConfigProperties>;

export type ConvivaRemoteConfigSplit = RemoteConfigSplit<ConvivaRemoteServerConfig, ConvivaRemoteClientConfig>;
export function adaptConvivaRemoteConfigSplit(config: ConvivaRemoteConfigSplit): DeclaredOptionalsAddonsConfig<ConvivaInternalConfig> {
    const requiredFields: RequiredPropertiesDefinition<ConvivaRemoteClientConfig> = {
        customerKey: null,
        gatewayUrl: null,
    };
    const isEnabled = genericConfigEnabledCheck(config, requiredFields, 'Conviva');
    if (!isEnabled) {
        return;
    }

    const { clientConfig, remoteConfig } = config;

    return {
        enabled: isEnabled,
        config: {
            heartbeatInterval: remoteConfig!.config.heartbeatInterval,
            mutedErrorCodes: remoteConfig!.config.mutedErrorCodes,
            enableAdInsights: remoteConfig!.config.enableAdInsights,
            shouldReportChipsetWithModel: remoteConfig!.config.shouldReportChipsetWithModel,
            customerKey: clientConfig!.customerKey,
            gatewayUrl: clientConfig!.gatewayUrl,
            isDebug: clientConfig!.isDebug,
        },
    };
}
