import type { EventCue, TimeRangeEvent } from './event-types';
import type { PlaybackTimeline } from '../session-controller/session-controller-internal';

/**
 * Given a cue, validates whether the startTime and endTime have been converted to stream positions
 * If they haven't an error is thrown. This is useful in safeguarding that the times have been converted.
 * @param cue
 */
export function validateCueHasPositionsNotDates(cue: EventCue) {
    if (cue.startTime instanceof Date) {
        throw new Error('This is not a cue.startTime position');
    }

    if (cue.endTime instanceof Date) {
        throw new Error('This is not a cue.endTime position');
    }
}

export const byStartTime = (a: TimeRangeEvent, b: TimeRangeEvent): number => {
    const [aStartTime] = a.range;
    const [bStartTime] = b.range;

    if (aStartTime < bStartTime) {
        return -1;
    } else if (aStartTime > bStartTime) {
        return 1;
    }
    return 0;
};

export const byEndTime = (a: TimeRangeEvent, b: TimeRangeEvent): number => {
    const aEndTime = a.range[1];
    const bEndTime = b.range[1];

    if (aEndTime < bEndTime) {
        return -1;
    } else if (aEndTime > bEndTime) {
        return 1;
    }

    return byStartTime(a, b);
};

/**
 * Convert an absoluteDate to a stream position (seconds), using the seekableRange as a reference point
 * @param absoluteDate the dateTime corresponding with the stream position
 * @param seekableRange used as a reference point to calculate the stream position
 */
export function calculateStreamPositionFromDate(absoluteDate: Date, playbackTimeline: PlaybackTimeline) {
    let positionOfSeekableRangeStart, differenceInMilliseconds;

    if (playbackTimeline.seekableRange?.startDate) {
        positionOfSeekableRangeStart = playbackTimeline.seekableRange.start;
        differenceInMilliseconds = absoluteDate.getTime() - playbackTimeline.seekableRange.startDate.getTime();
    } else {
        if (playbackTimeline.absolutePosition) {
            positionOfSeekableRangeStart = playbackTimeline.position;
            differenceInMilliseconds = absoluteDate.getTime() - playbackTimeline.absolutePosition.getTime();
        } else {
            throw new Error('Cannot workout position of date without seekableRange or absolutePosition');
        }
    }

    const position = positionOfSeekableRangeStart + differenceInMilliseconds / 1000;
    return position;
}
