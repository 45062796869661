import type { DisableAnalyticsForAutoPlayTrailersConfig } from '../../internal-config';
import type { RemoteConfigSplit } from '../../sdk-config';
import type { InternalReportingConfig } from './addons-config-adapter-common';

// Internal Config
type DisableAnalyticsForAutoPlayTrailersInternalConfig = InternalReportingConfig['disableAnalyticsForAutoPlayTrailers'];

// Client Config when Remote Config is disabled
export type DisableAnalyticsForAutoPlayTrailersClientConfig = DisableAnalyticsForAutoPlayTrailersConfig;
export function adaptDisableAnalyticsForAutoPlayTrailersClientConfig(
    config: DisableAnalyticsForAutoPlayTrailersClientConfig | undefined
): DisableAnalyticsForAutoPlayTrailersInternalConfig {
    return config;
}

// Server Config when Remote Config is enabled
type DisableAnalyticsForAutoPlayTrailersRemoteServerConfig = {};

// Client Config when Remote Config is enabled
type DisableAnalyticsForAutoPlayTrailersRemoteClientConfig = DisableAnalyticsForAutoPlayTrailersConfig;

export type DisableAnalyticsForAutoPlayTrailersRemoteConfigSplit = RemoteConfigSplit<
    DisableAnalyticsForAutoPlayTrailersRemoteServerConfig,
    DisableAnalyticsForAutoPlayTrailersRemoteClientConfig
>;
export function adaptDisableAnalyticsForAutoPlayTrailersRemoteConfigSplit(
    config: DisableAnalyticsForAutoPlayTrailersRemoteConfigSplit
): DisableAnalyticsForAutoPlayTrailersInternalConfig {
    return {
        adobe: Boolean(config.clientConfig?.adobe),
    };
}
