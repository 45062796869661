import { DeviceType } from '@sky-uk-ott/client-lib-js-device';

import type { Ad, AdBreak } from '../../addons/adverts/common';
import { AdBreakType } from '../../addons/adverts/common';
import type { Proposition } from '../../config/internal-config';
import { CoreVideoInternal } from '../../core-video-internal';
import type { SessionItem } from '../../core/session-controller/session-controller';
import type { PlaybackTimeline } from '../../core/session-controller/session-controller-internal';
import { CvsdkError, ErrorSeverity } from '../../error';
import { sdkLogger } from '../../logger';
import { isSafariDesktop } from '../../utils/device-type';
import { Observable } from '../../utils/observables/observable';
import { checkIsManifestLinearType } from '../../utils/playback-type';

import type { AdPolicy } from './ad-policy';
import type { InterItemAdPolicyManager } from './inter-item-ad-policy-manager';
import { SimpleTimer } from '../../utils/simple-timer';
import { cloneAdBreak, cloneAdBreakArray } from '../../utils/ad-break-utils';

export type AdPolicyConfig = {
    isSeekManagementRequired: boolean;
    isWatchedAdsManagementRequired: boolean;
    allowSeekingDuringAdBreaks: boolean;
    allowAdSkipCatchup: boolean;
    proposition: Proposition;
    sessionItem: SessionItem;
    adBreakEndTolerance?: number;
};

const AdBreakMatchTolerance = 1;
const ChunkSizeSeconds = 6;

// sometimes there's an automatic seek when going into an adBreak. if this happens, we don't want to skip the adBreak
const SkipAdTolerance = 0.5;

// Default ad break end tolerance
const AdBreakEndTolerance = 0.5;

// Allow inputs after X seconds in case we don't receive a break started event
export const InputBlockingTimeLimit = 3000;

// Starting playout or seeking too close to an advert can cause issues, so we need to modify
// the seek position to leave a 2 chunk gap before the adBreak start.
export const PlayFromAdBreakOffset = ChunkSizeSeconds * 2;

declare let self: Window;

export interface CatchupSeekEvent {
    adsSkipped: number;
    index: number;
    duration: number;
}

export class AdPolicyManager implements InterItemAdPolicyManager {
    private seekObservable: Observable<number> = new Observable<number>();
    private catchupSeekObservable: Observable<CatchupSeekEvent> = new Observable<CatchupSeekEvent>();
    private adBreaks: Array<AdBreak> | null = null;
    private postAdSeekPosition: number | null = null;
    private postAdSeekDeltaToLiveEdge: number | null = null;
    private watchedAds: Array<string> = [];
    private adPolicy: AdPolicy;
    private inputBlockingMaxTimeout: SimpleTimer = new SimpleTimer();
    private isSeekingToAdBreak = false;
    private currentPlaybackTimeline: PlaybackTimeline | null = null;
    private adBreakEndTolerance: number;

    constructor(private adPolicyConfig: AdPolicyConfig) {
        const propositionExtensions = CoreVideoInternal.getPropositionExtensions();
        const AdPolicyClass: { new (): AdPolicy } | undefined = propositionExtensions.adPolicy;
        if (AdPolicyClass) {
            this.adPolicy = new AdPolicyClass();
        } else {
            const error = CvsdkError.from({
                severity: ErrorSeverity.Fatal,
                message: `No AdPolicyManager support found for ${adPolicyConfig.proposition}`,
                code: 'AD_POLICY_MANAGER.NO_PROPOSITION_SUPPORT',
            });
            throw error;
        }

        this.adBreakEndTolerance = typeof adPolicyConfig.adBreakEndTolerance === 'number' ? adPolicyConfig.adBreakEndTolerance : AdBreakEndTolerance;
    }

    public initialise(onPlaybackTimelineUpdated: (callback: (currentTimeSeconds: PlaybackTimeline) => void) => void): void {
        onPlaybackTimelineUpdated((playbackTimeline: PlaybackTimeline) => {
            this.currentPlaybackTimeline = playbackTimeline;
        });
    }

    public notifyPositionChanged(currentTimeSeconds: number): void {
        this.adPolicy.notifyPositionChanged(currentTimeSeconds);
    }

    public setAdBreaksData(adBreaks: Array<AdBreak>): void {
        const adBreakIsPlayable = (adBreak: AdBreak) => adBreak.ads.length > 0;

        this.adBreaks = cloneAdBreakArray(adBreaks)
            // Ad Policy manager assumes every ad stored in its internal list
            // is _playable_, so omit empty ad breaks
            .filter(adBreakIsPlayable)
            .map((adBreak) => {
                //Sync watched statuses
                if (this.watchedAds.includes(adBreak.id)) {
                    adBreak.watched = true;
                } else if (adBreak.watched) {
                    this.watchedAds.push(adBreak.id);
                }
                return adBreak;
            });

        this.adPolicy.notifyAdBreakDataChanged(this.adBreaks);
    }

    /**
     * Returns a direct reference to the specified adBreak managed by this ad policy manager instance. Will not return stale ads that have been since replaced by setAdBreaksData().
     * @param id The id of the managed ad break to retrieve.
     * @returns The resulting ad break, or null if it cannot be found.
     */
    public getManagedAdBreakById(id: string): AdBreak | null {
        return this.adBreaks?.find((adBreak) => adBreak.id === id) ?? null;
    }

    /**
     * Helper to return the watched status of the specified ad break. Searches through both the currently assigned adBreaks as well as any historically watched breaks.
     * @param id The id of ad break to check watched status for.
     * @returns True when there is a record that the adBreak has been watched at least once.
     */
    private getAdBreakWatchedStatus(id: string): boolean {
        return this.getManagedAdBreakById(id)?.watched ?? this.watchedAds.includes(id);
    }

    /**
     * Returns a clone of the ad breaks that are being actively managed by this ad policy manager instance. Will not return stale ads that have been since replaced by setAdBreaksData().
     * @returns
     */
    public getAdBreaks(): Array<AdBreak<Ad>> {
        return cloneAdBreakArray(this.adBreaks || []);
    }

    public onShouldSeek(callback: (postAdSeekPosition: number) => void): void {
        this.seekObservable.registerObserver(callback, this);
    }

    public onCatchupSeek(callback: (catchupSeek: CatchupSeekEvent) => void): void {
        this.catchupSeekObservable.registerObserver(callback, this);
    }

    public manageSeek(currentPosition: number, seekToPosition: number, clearPostAdSeekPosition = true): number | null {
        if (this.adPolicyConfig.isSeekManagementRequired && this.adBreaks) {
            if (this.shouldEnforceAdRules(seekToPosition)) {
                return this.enforceUnwatchedBreaks(currentPosition, seekToPosition, clearPostAdSeekPosition);
            }
            return this.skipToAdBreakEnd(seekToPosition);
        }
        return seekToPosition;
    }

    public seekEnded(): void {
        this.adPolicy.notifySeekEnded();

        if (this.isSeekingToAdBreak) {
            // Allow inputs after X seconds in case we don't receive a break started event
            this.inputBlockingMaxTimeout.start({
                timeMs: InputBlockingTimeLimit,
                callback: () => {
                    this.isSeekingToAdBreak = false;
                },
            });
        }
    }

    public seekStarted(): void {
        this.adPolicy.notifySeekStarted();
    }

    public isPlayingAdBreakPositionSSAI(position: number): boolean {
        if (this.adPolicyConfig.isSeekManagementRequired) {
            const adBreakPlaying = this.getAdBreakPlayingByPositionSSAI(position);
            if (adBreakPlaying) {
                return true;
            }
        }
        return false;
    }

    public manageStartPosition(startPosition: number | undefined): number {
        // This is a workaround for an issue on samsung TVs.
        // A ticket has been created for a further investigation.
        // https://github.com/sky-uk/core-video-team/issues/6025
        if (!startPosition && CoreVideoInternal.deviceType === DeviceType.Tizen) {
            const hasPreRoll = !!this.adBreaks && this.adBreaks.some((adBreak: AdBreak) => adBreak.type === AdBreakType.Preroll);
            return hasPreRoll ? 0.01 : (startPosition as number);
        }

        // If start position is 0 or undefined we don't need to modify
        if (!startPosition || !this.adBreaks) {
            return startPosition as number;
        }

        this.validateStartPosition(startPosition);

        const midrollAtStart = this.adBreaks.find((adBreak) => {
            return (
                adBreak.type === AdBreakType.Midroll &&
                typeof adBreak.position === 'number' &&
                adBreak.position - startPosition >= 0 &&
                adBreak.position - startPosition < PlayFromAdBreakOffset
            );
        });

        const preroll = this.adBreaks.find((adBreak: AdBreak) => adBreak.type === AdBreakType.Preroll);

        if (preroll && isSafariDesktop(CoreVideoInternal.deviceInfo, CoreVideoInternal.deviceType)) {
            // Safari has an issue where it will show the first few frames of
            // the preroll before reaching the start position.
            // This workaround will play the preroll and skip the midroll if on safari
            return this.useSafariPrerollWorkaround(startPosition, midrollAtStart);
        }

        if (midrollAtStart) {
            return CoreVideoInternal.deviceType === DeviceType.YouView ? startPosition : (midrollAtStart.position as number) - PlayFromAdBreakOffset;
        }

        const midrollAtEnd = this.adBreaks.find(
            (adBreak) =>
                adBreak.type === AdBreakType.Midroll &&
                typeof adBreak.position === 'number' &&
                adBreak.expectedDuration &&
                adBreak.position + adBreak.expectedDuration <= startPosition &&
                adBreak.position + adBreak.expectedDuration + this.adBreakEndTolerance > startPosition
        );

        if (midrollAtEnd) {
            return (midrollAtEnd.position as number) + midrollAtEnd.expectedDuration! + this.adBreakEndTolerance;
        }

        return startPosition;
    }

    public manageAdBreakStarted(adBreak: AdBreak): AdBreak | null {
        this.isSeekingToAdBreak = false;
        this.inputBlockingMaxTimeout.stop();
        if (this.adPolicyConfig.isWatchedAdsManagementRequired && this.shouldEnforceAdRules() && this.getAdBreakWatchedStatus(adBreak.id)) {
            this.skipAdBreak(adBreak);
            return null;
        }
        if (
            this.adPolicyConfig.allowAdSkipCatchup &&
            this.adPolicy.canAdSkipCatchup() &&
            CoreVideoInternal.playerCapabilities.supportsAdSkipCatchup
        ) {
            const adSkipPosition = this.skipAds(adBreak);
            if (adSkipPosition && adSkipPosition >= (adBreak.position as number) + adBreak.expectedDuration!) {
                // In case an entire ad break is skipped
                return null;
            }
        }
        this.adPolicy.notifyAdBreakStarted(adBreak);
        return adBreak;
    }

    public manageAdBreakFinished(adBreak: AdBreak): AdBreak | null {
        const localAdBreak = this.getManagedAdBreakById(adBreak.id);
        const hasWatchHistory = this.watchedAds.includes(adBreak.id);
        const wasWatched = localAdBreak?.watched || hasWatchHistory;
        if (this.adPolicyConfig.isWatchedAdsManagementRequired && this.shouldEnforceAdRules() && wasWatched) {
            return null;
        }

        // Update watched statuses
        if (localAdBreak) {
            localAdBreak.watched = true;
        }
        if (!hasWatchHistory) {
            this.watchedAds.push(adBreak.id);
        }

        if (typeof this.postAdSeekPosition === 'number') {
            this.notifySeekSafelyInNextTick(this.postAdSeekPosition);
        } else if (typeof this.postAdSeekDeltaToLiveEdge === 'number' && this.currentPlaybackTimeline?.seekableRange?.end) {
            const postAdSeekPosition = this.currentPlaybackTimeline?.seekableRange?.end - this.postAdSeekDeltaToLiveEdge;
            this.notifySeekSafelyInNextTick(postAdSeekPosition);
        }

        this.adPolicy.notifyAdBreakEnded(adBreak);

        if (!wasWatched) {
            // Only notify of change if the watched field was updated
            this.adPolicy.notifyAdBreakDataChanged(this.adBreaks!);
        }

        return adBreak;
    }

    public manageAdStarted(ad: Ad): Ad | null {
        if (this.adPolicyConfig.isWatchedAdsManagementRequired && this.shouldEnforceAdRules() && this.getAdBreakWatchedStatus(ad.adBreak?.id)) {
            return null;
        }

        return ad;
    }

    public manageAdFinished(ad: Ad): Ad | null {
        if (this.adPolicyConfig.isWatchedAdsManagementRequired && this.shouldEnforceAdRules() && this.getAdBreakWatchedStatus(ad.adBreak?.id)) {
            return null;
        }

        return ad;
    }

    public shouldPlayAdBreak = (adBreak: AdBreak, position: number, isResumingFromBookmark?: boolean): boolean => {
        adBreak = cloneAdBreak(adBreak);
        if (this.watchedAds.includes(adBreak.id)) {
            // Ensure the ad break has the proper watched status based on the internal record.
            adBreak.watched = true;
        }

        return this.adPolicy.shouldPlayAdBreak(adBreak, position, isResumingFromBookmark);
    };

    public getAdBreaksToPlayOnSeekingForward(
        skipped?: Array<AdBreak>,
        currentPosition?: number,
        seekToPosition?: number
    ): Array<AdBreak> | undefined {
        let adBreak;
        if (skipped) {
            adBreak = this.adPolicy.getAdBreakToPlayBySkippedAdBreaks(skipped);
        } else if (currentPosition != null && seekToPosition != null) {
            adBreak = this.getAdBreakToPlayByPosition(this.adBreaks, currentPosition, seekToPosition);
        } else {
            sdkLogger.warn('When seeking should provide skipped ad breaks, or, the from and to seek position in order to get the adBreaks to play');
        }

        if (adBreak) {
            return [adBreak];
        }
    }
    public disable(): void {
        sdkLogger.warn('Ad Policy Manager - Disabling ad policy manager');
        this.clearAdBreaks();
        this.disableAdPolicyLogic();
        this.seekObservable.unregisterObservers(this);
        this.catchupSeekObservable.unregisterObservers(this);
        this.inputBlockingMaxTimeout.destroy();
    }

    private clearAdBreaks(): void {
        this.adBreaks = null;
    }

    private disableAdPolicyLogic(): void {
        this.adPolicyConfig.isWatchedAdsManagementRequired = false;
        this.adPolicyConfig.isSeekManagementRequired = false;
    }

    private doesAdBreakStartBefore(adBreak: AdBreak, position: number): boolean {
        if (typeof adBreak.position === 'number') {
            return adBreak.position - SkipAdTolerance <= position;
        }
        return adBreak.position === 'start' && position > 0;
    }

    private findAdBreakForPosition(position: number, shouldAddEndTolerance?: boolean): AdBreak | undefined {
        return this.adBreaks!.find((adBreak) => {
            if (typeof adBreak.position === 'number') {
                const endTolerance = shouldAddEndTolerance ? this.adBreakEndTolerance : 0;
                const startsInPast = this.doesAdBreakStartBefore(adBreak, position);
                const endsInFuture = adBreak.position + adBreak.expectedDuration! + endTolerance > position;
                return startsInPast && endsInFuture;
            }
            return adBreak.position === 'start' && position === 0;
        });
    }

    private getAdBreakToPlayByPosition(adBreaks: Array<AdBreak> | null, currentPosition: number, seekToPosition: number): AdBreak<Ad> | null {
        if (!this.adPolicy.canPlayAdBreak() || adBreaks === null) {
            return null;
        }

        const skippedAdBreaks = adBreaks.filter((adBreak) => {
            if (typeof adBreak.position === 'string') {
                // if its string then it will be preroll or post role. N/A for now
                return false;
            }

            const isFutureAdBreak = adBreak.position! >= currentPosition;
            const wouldBeSkippedOver = this.doesAdBreakStartBefore(adBreak, seekToPosition);

            return isFutureAdBreak && wouldBeSkippedOver;
        });

        return this.adPolicy.getAdBreakToPlayBySkippedAdBreaks(skippedAdBreaks);
    }

    private getAdBreakPlayingByPositionSSAI(currentPosition: number): AdBreak | null {
        if (this.adBreaks === null) {
            return null;
        }

        const skippedAdBreaks = this.adBreaks.filter((adBreak) => {
            if (typeof adBreak.position === 'string') {
                // if its string then it will be preroll or postroll. N/A for now
                return false;
            }

            return adBreak.position! <= currentPosition && adBreak.position! + adBreak.expectedDuration! > currentPosition;
        });

        return this.adPolicy.getAdBreakToPlayBySkippedAdBreaks(skippedAdBreaks);
    }

    private useSafariPrerollWorkaround(startPosition: number, midroll?: AdBreak): number {
        if (midroll && typeof midroll.position === 'number' && typeof midroll.expectedDuration === 'number') {
            this.postAdSeekPosition = midroll.position + midroll.expectedDuration + this.adBreakEndTolerance;
        } else {
            this.postAdSeekPosition = startPosition;
        }
        return 0;
    }

    private manageSeekCloseToBreak(position: number): number | null {
        let nearBreakPosition = 0;

        for (let i = 0; i < this.adBreaks!.length; i++) {
            const adBreak = this.adBreaks![i];
            if (typeof adBreak.position === 'string') {
                continue;
            }

            if (adBreak.position! >= position && adBreak.position! - position < PlayFromAdBreakOffset) {
                nearBreakPosition = adBreak.position!;
                break;
            }
        }

        if (nearBreakPosition) {
            const positionWithOffset = Math.max(nearBreakPosition - PlayFromAdBreakOffset, 0);
            sdkLogger.info(`Fixing post ad break seek position (from ${position} to ${positionWithOffset}) to have a safe distance from next break`);
            return positionWithOffset;
        } else if (this.findAdBreakForPosition(position)) {
            return null;
        } else {
            return position;
        }
    }

    private validateStartPosition(startPosition: number): void {
        const reverseAdBreaks: Array<AdBreak<Ad>> = this.adBreaks ? [...this.adBreaks!].reverse() : [];
        const adBreakBeforeStart = reverseAdBreaks.find((x) => +x.position! <= startPosition + AdBreakMatchTolerance);
        if (
            adBreakBeforeStart &&
            typeof adBreakBeforeStart.position === 'number' &&
            startPosition > adBreakBeforeStart.position &&
            adBreakBeforeStart.position + adBreakBeforeStart.expectedDuration! > startPosition
        ) {
            throw new Error('Not supported: bookmark is set to position within ad break');
        }
    }

    private skipAdBreak(adBreak: AdBreak): void {
        if (adBreak.position !== 'end' && adBreak.expectedDuration) {
            const adStartPosition = adBreak.position === 'start' ? 0 : adBreak.position!;
            this.seekObservable.reset();
            this.seekObservable.notifyObservers(adStartPosition + adBreak.expectedDuration + this.adBreakEndTolerance);
        }
    }

    private skipAds(adBreak: AdBreak): number | undefined {
        if (checkIsManifestLinearType(this.adPolicyConfig.sessionItem.type) && typeof adBreak.position === 'number' && adBreak.expectedDuration) {
            let { minDrift, maxDrift, minSeek } = CoreVideoInternal.playerCapabilities.adSkipCatchup || {};
            minDrift ||= 20;
            maxDrift ||= 240;
            minSeek ||= 10;
            const adStartPosition = adBreak.position;
            const distanceToLiveEdge = this.currentPlaybackTimeline!.seekableRange!.end - adStartPosition;
            if (distanceToLiveEdge > minDrift && distanceToLiveEdge < maxDrift) {
                let postAdSkipPosition = adStartPosition;
                let adsSkipped = 0;
                for (const ad of adBreak.ads) {
                    if (postAdSkipPosition + ad.expectedDuration < this.currentPlaybackTimeline!.seekableRange!.end) {
                        postAdSkipPosition += ad.expectedDuration;
                        adsSkipped++;
                        continue;
                    }
                    break;
                }

                if (postAdSkipPosition - adStartPosition < minSeek) {
                    return;
                }

                if (postAdSkipPosition + this.adBreakEndTolerance > adStartPosition + adBreak.expectedDuration) {
                    postAdSkipPosition += this.adBreakEndTolerance;
                }
                this.seekObservable.reset();
                this.seekObservable.notifyObservers(postAdSkipPosition);
                this.catchupSeekObservable.notifyObservers({
                    adsSkipped,
                    index: 0, // Phase I - start from the first ad
                    duration: postAdSkipPosition - adStartPosition,
                });
                return postAdSkipPosition;
            }
        }
    }

    private notifySeekSafelyInNextTick(postAdSeekPosition: number): void {
        Promise.resolve().then(() => {
            this.seekObservable.reset();
            this.seekObservable.notifyObservers(postAdSeekPosition);

            this.postAdSeekPosition = null;
            this.postAdSeekDeltaToLiveEdge = null;
        });
    }

    private enforceUnwatchedBreaks(currentPosition: number, seekToPosition: number, clearPostAdSeekPosition: boolean): number | null {
        const shouldBlockSeekDuringAd = !this.adPolicyConfig.allowSeekingDuringAdBreaks && this.isPlayingAdBreakPositionSSAI(currentPosition);

        if (this.isSeekingToAdBreak || shouldBlockSeekDuringAd) {
            return null;
        }

        if (seekToPosition <= 0) {
            return this.adPolicy.getMinimumSeekablePositionAfterPreroll(this.adBreaks!);
        }

        if (clearPostAdSeekPosition) {
            this.postAdSeekPosition = null;
            this.postAdSeekDeltaToLiveEdge = null;
        }

        const adBreakToPlay = this.getAdBreakToPlayByPosition(this.adBreaks, currentPosition, seekToPosition);
        if (adBreakToPlay) {
            this.isSeekingToAdBreak = true;
            this.inputBlockingMaxTimeout.stop();

            if (!checkIsManifestLinearType(this.adPolicyConfig.sessionItem.type)) {
                this.postAdSeekPosition = this.manageSeekCloseToBreak(seekToPosition);
            } else if (this.currentPlaybackTimeline?.seekableRange?.end) {
                this.postAdSeekDeltaToLiveEdge = Math.max(this.currentPlaybackTimeline?.seekableRange?.end - seekToPosition, 0);
            }
            return adBreakToPlay.position as number;
        } else {
            const currentAdBreak = this.findAdBreakForPosition(seekToPosition, true);
            if (typeof currentAdBreak?.position === 'number') {
                return currentAdBreak.position + currentAdBreak.expectedDuration! + this.adBreakEndTolerance;
            }
            return this.skipToAdBreakEnd(seekToPosition);
        }
    }

    private skipToAdBreakEnd(seekToPosition: number): number {
        const currentAdBreak = this.findAdBreakForPosition(seekToPosition);
        if (currentAdBreak && typeof currentAdBreak?.position === 'number') {
            const positionAtEndOfAd = currentAdBreak.position + currentAdBreak.expectedDuration! + this.adBreakEndTolerance;
            if (this.currentPlaybackTimeline!.seekableRange!.end < positionAtEndOfAd || seekToPosition - SkipAdTolerance < currentAdBreak.position) {
                return currentAdBreak.position;
            }
            return positionAtEndOfAd;
        }
        return seekToPosition;
    }

    /**
     * See https://github.com/NBCUDTC/core-video-team/wiki/Peacock-Scrubbing-Requirements
     *
     * For VOD we will always enforce ad rules
     * For Live at live edge we will always enforce ad rules
     * For Live in DVR mode (behind live edge) the following applies:
     *     - Ad rules around seeking over ad breaks are not enforced (the user will not be forced to watch an ad break after seeking)
     *     - Ad rules inside ad breaks are enforced EG once the timeline reaches a ad break the user is forced to watch that ad break,
     *           player controls are disabled and only pause play will be allowed (similar to live mode experience)
     *
     * TODO: https://gspcloud.atlassian.net/browse/VPTJS-10420 - Remove when propositions are aligned on Linear DVR ad behavior,
     *  see also https://github.com/NBCUDTC/core-video-sdk-js/pull/10183
     *
     * @param seekToPosition
     * @returns
     */
    private shouldEnforceAdRules(seekToPosition?: number): boolean {
        if (!checkIsManifestLinearType(this.adPolicyConfig.sessionItem.type) || this.adPolicy.shouldSkipWatchedAdsForLinearDvr()) {
            return true;
        }

        let wantsToGoToLiveEdge = false;
        if (this.currentPlaybackTimeline?.seekableRange?.end && seekToPosition) {
            const distanceToLiveEdge = this.currentPlaybackTimeline?.seekableRange?.end - seekToPosition;
            wantsToGoToLiveEdge = distanceToLiveEdge < this.adPolicyConfig.sessionItem.liveEdgeToleranceSeconds!;
        }

        return !this.currentPlaybackTimeline || Boolean(this.currentPlaybackTimeline!.isAtLiveEdge) || wantsToGoToLiveEdge;
    }
}
