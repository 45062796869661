import { checkIsManifestLinearType } from '../../../utils/playback-type';
import type { PlaybackType } from '../../player/playout-data';
import type { Asset } from '../asset';
import { TimelineItemType } from './timeline.enums';
export { TimelineItemType } from './timeline.enums';

/**
 * @public
 */
export type Position = number | 'start' | 'end';

export interface TimelineOptions {
    playbackType: PlaybackType;
}

export interface TimelineItem {
    id: string;
    type: TimelineItemType;
    startTime: number | 'start';
    endTime: number | 'end';
    asset: Asset;
    isPreloaded?: boolean;
    isPreconstructed?: boolean;
    isSuspending?: boolean;
    resumePosition?: number | null;
}

export class Timeline {
    private static timelineItemIdCount = 0;
    private static idCount = 0;

    public id = `TLine-${`0000${++Timeline.idCount}`.slice(-4)}`;

    private timelineItems: Array<TimelineItem>;

    constructor(
        mainAsset: Asset,
        private options: TimelineOptions
    ) {
        this.timelineItems = [
            {
                startTime: 'start',
                endTime: 'end',
                type: TimelineItemType.MainContent,
                asset: mainAsset,
                id: this.generateTimelineItemId(),
            },
        ];
    }

    public insertAdverts(advertAssets: Array<Asset>, position: Position, adBreakDuration: number): Array<TimelineItem> {
        const adverts = advertAssets.map<TimelineItem>((advertAsset) => ({
            startTime: 'start',
            endTime: 'end',
            type: TimelineItemType.Advert,
            asset: advertAsset,
            id: this.generateTimelineItemId(),
        }));

        if (position === 'start' || position === 0) {
            this.insertPreAdverts(adverts);
        } else if (position === 'end') {
            this.insertPostAdverts(adverts);
        } else {
            this.insertMidAdverts(adverts, position, adBreakDuration);
        }

        return adverts;
    }

    public removeAdverts(advertAssets: Array<Asset>): Array<TimelineItem> {
        // remove the specified adverts from the timeline
        const removed: Array<TimelineItem> = [];

        const newTimelineItems = this.timelineItems.filter((timelineItem) => {
            const isAdvert = timelineItem.type === TimelineItemType.Advert;
            const isRemoved = isAdvert && advertAssets.find((advertAsset) => advertAsset.id === timelineItem.asset.id);
            if (isRemoved) {
                removed.push(timelineItem);
            }
            return !isRemoved;
        });

        // stitch together any consecutive pieces of main content
        this.timelineItems = newTimelineItems.reduce(
            (reducedItems, currentItem, index) => {
                if (reducedItems.length === 0) {
                    return [currentItem];
                }

                const previousItem = reducedItems[reducedItems.length - 1];
                if (
                    previousItem.type === TimelineItemType.MainContent &&
                    currentItem.type === TimelineItemType.MainContent &&
                    previousItem.endTime === currentItem.startTime
                ) {
                    previousItem.endTime = currentItem.endTime;
                    return reducedItems;
                }

                reducedItems.push(currentItem);
                return reducedItems;
            },
            [] as Array<TimelineItem>
        );

        return removed;
    }

    public getMainContentItemForPosition(targetPosition: number): TimelineItem | undefined {
        return this.timelineItems.find(
            (item) =>
                item.type === TimelineItemType.MainContent &&
                (item.startTime === 'start' || item.startTime <= targetPosition) &&
                (item.endTime === 'end' || item.endTime >= targetPosition)
        );
    }

    public getFirstTimelineItem(): TimelineItem {
        return this.timelineItems[0];
    }

    public getNextTimelineItem(currentTimelineItem: TimelineItem | null): TimelineItem | null {
        const currentIndex = this.timelineItems.findIndex((timelineItem) => timelineItem === currentTimelineItem);

        if (this.timelineItems.length > currentIndex + 1) {
            return this.timelineItems[currentIndex + 1];
        } else {
            return null;
        }
    }

    public toString(): string {
        let output = `Details of ${this.id}:\n`;

        this.timelineItems.forEach((timelineItem, index) => {
            output += `${index + 1}. ${timelineItem.id} ${timelineItem.asset.id} (${timelineItem.type}): `;
            output += `${timelineItem.startTime} to ${timelineItem.endTime}\n`;
        });

        return output;
    }

    private insertMidAdverts(adverts: Array<TimelineItem>, position: Position, adBreakDuration: number): void {
        const newTimeline = new Array<TimelineItem>();
        const offsettedResumePosition = checkIsManifestLinearType(this.options.playbackType) ? adBreakDuration : 0;
        this.timelineItems.forEach((timelineItem) => {
            if (this.isWithinBounds(timelineItem.startTime, timelineItem.endTime, position) && timelineItem.type === TimelineItemType.MainContent) {
                const [timelineItemFirstHalf, timelineItemSecondHalf] = this.splitTimelineItem(timelineItem, position);
                (timelineItemSecondHalf.startTime as number) += offsettedResumePosition;
                newTimeline.push(timelineItemFirstHalf, ...adverts, timelineItemSecondHalf);
            } else {
                newTimeline.push(timelineItem);
            }
        });
        this.timelineItems = newTimeline;
    }

    private insertPreAdverts(adverts: Array<TimelineItem>): void {
        this.timelineItems = [...adverts, ...this.timelineItems];
    }

    private insertPostAdverts(adverts: Array<TimelineItem>): void {
        this.timelineItems = [...this.timelineItems, ...adverts];
    }

    private splitTimelineItem(timelineItem: TimelineItem, splitPosition: Position): Array<TimelineItem> {
        if (!this.isWithinBounds(timelineItem.startTime, timelineItem.endTime, splitPosition)) {
            throw new Error('ERROR.TIMELINE.INVALID_SPLIT');
        }

        if (splitPosition === 'start' || splitPosition === 'end') {
            throw new Error('ERROR.TIMELINE.INVALID_SPLIT');
        }

        const endTime = timelineItem.endTime;
        timelineItem.endTime = splitPosition;

        return [
            timelineItem,
            {
                ...timelineItem,
                startTime: splitPosition,
                endTime,
                id: this.generateTimelineItemId(),
            },
        ];
    }

    private isWithinBounds(start: Position, end: Position, position: Position): boolean {
        if (start === 'start' && end === 'end') {
            return true;
        } else if (start === position || end === position) {
            return true;
        } else if (start === 'start' && position <= end) {
            return true;
        } else if (end === 'end' && position >= start) {
            return true;
        } else {
            return start <= position && position <= end;
        }
    }

    private generateTimelineItemId(): string {
        return `TLineItem-${`0000${++Timeline.timelineItemIdCount}`.slice(-4)}`;
    }
}
