// Feature Overview
// https://github.com/NBCUDTC/core-video-team/discussions/11051

import { sdkLogger } from '../../logger';
import { VideoColourSpace, VideoFormat } from './video-format';

// A group of compatible player capabilities
export type CompatibleStreamPropertyGroup = {
    maxFrameRate: number;
    supportedColourSpaces: Array<VideoColourSpace>;
    maxVideoFormat: VideoFormat;
    codecs?: Array<string>;
};

// A specification of which streams are available for us to request from the video platform (e.g. OVP)
// Based on the available streams for a given playback type we will request the best
// stream capability group.
export type StreamCapabilitySpecification = {
    maxFrameRate: number;
    maxVideoFormat: VideoFormat;
    maxColorSpace: VideoColourSpace;
    codecs?: Array<string>;
};

// Prioritise by frame rate, then video format, then colour space
// Reverse order sorting so that higher enum values (better stream specs) are at the front of the array.
// i.e. Prefer 60fps SDR to 30fps HDR
//
// This could be shifted out into the proposition extensions if each proposition
// wanted a different prioritisation order but for now it makes sense to sit here
function prioritiseStreamCapabilitySpecifications(streamSpecifications: Array<StreamCapabilitySpecification>): Array<StreamCapabilitySpecification> {
    return [...streamSpecifications].sort((a, z) => {
        return z.maxFrameRate - a.maxFrameRate || z.maxVideoFormat - a.maxVideoFormat || z.maxColorSpace - a.maxColorSpace;
    });
}

export function prioritiseCompatibleStreamPropertyGroups(
    compatibleStreamPropertyGroups: Array<CompatibleStreamPropertyGroup>
): Array<CompatibleStreamPropertyGroup> {
    return [...compatibleStreamPropertyGroups].sort((a, z) => {
        const aMaxColorSpace = Math.max(...a.supportedColourSpaces);
        const zMaxColorSpace = Math.max(...z.supportedColourSpaces);
        return z.maxFrameRate - a.maxFrameRate || z.maxVideoFormat - a.maxVideoFormat || zMaxColorSpace - aMaxColorSpace;
    });
}

function getBestStreamSpecificationIndex(
    capabilityGroup: CompatibleStreamPropertyGroup,
    streamSpecifications: Array<StreamCapabilitySpecification>
): number {
    return streamSpecifications.findIndex((specification) => {
        return (
            capabilityGroup.maxFrameRate >= specification.maxFrameRate &&
            capabilityGroup.maxVideoFormat >= specification.maxVideoFormat &&
            capabilityGroup.supportedColourSpaces.includes(specification.maxColorSpace)
        );
    });
}

export function getPreferredCompatibleStreamPropertyGroup(
    capabilityGroups: Array<CompatibleStreamPropertyGroup>,
    streamSpecifications: Array<StreamCapabilitySpecification>
): CompatibleStreamPropertyGroup | undefined {
    const prioritisedSpecifications = prioritiseStreamCapabilitySpecifications(streamSpecifications);
    let bestCapabilityGroup: CompatibleStreamPropertyGroup | undefined;
    let bestSpecificationIndex = Infinity;

    capabilityGroups.forEach((capabilityGroup) => {
        const specificationIndex = getBestStreamSpecificationIndex(capabilityGroup, prioritisedSpecifications);
        if (specificationIndex !== -1 && specificationIndex < bestSpecificationIndex) {
            bestCapabilityGroup = capabilityGroup;
            bestSpecificationIndex = specificationIndex;
        }
    });

    if (!bestCapabilityGroup) {
        return;
    }

    const bestSpecification = prioritisedSpecifications[bestSpecificationIndex];

    if (!bestSpecification) {
        return;
    }

    return {
        maxVideoFormat: bestSpecification.maxVideoFormat,
        maxFrameRate: bestSpecification.maxFrameRate,
        supportedColourSpaces: bestCapabilityGroup.supportedColourSpaces.filter((c) => c <= bestSpecification.maxColorSpace),
    };
}

export function filterStreamsByCodecCompatibility(
    codecCheck: (codec: string) => boolean,
    capabilityGroups: Array<StreamCapabilitySpecification>
): Array<StreamCapabilitySpecification> {
    return capabilityGroups.filter((capabilityGroup: StreamCapabilitySpecification) => {
        if (capabilityGroup?.codecs?.length) {
            return capabilityGroup?.codecs?.every((codec: string) => {
                const result = codecCheck(codec);
                if (!result) {
                    sdkLogger.warn(
                        `Device does not support codec: ${codec}, ` +
                            `removing capabilityGroup: ${VideoFormat[capabilityGroup.maxVideoFormat].toString()}, ` +
                            `${capabilityGroup.maxFrameRate}fps, ` +
                            `${VideoColourSpace[capabilityGroup.maxColorSpace].toString()}.`
                    );
                }
                return result;
            });
        }
        return true;
    });
}

export const isNowPremiumUhdSupported = async (): Promise<boolean> => {
    const { CoreVideoInternal } = await import('../../core-video-internal'); // to avoid circular dependency with ../../core-video-internal.ts
    const compatibleStreamPropertyGroups = CoreVideoInternal?.playerCapabilities?.compatibleStreamPropertyGroups;
    let hasSupportedStreamGroup = false;

    if (!compatibleStreamPropertyGroups) {
        return false;
    }

    for (const group of compatibleStreamPropertyGroups) {
        const { maxFrameRate: fps, maxVideoFormat: resolution, supportedColourSpaces: colourSpaces } = group;

        if (fps >= 50 && resolution === VideoFormat.UHD && colourSpaces.includes(VideoColourSpace.HDR10)) {
            hasSupportedStreamGroup = true;
            break;
        }
    }

    if (!hasSupportedStreamGroup) {
        return false;
    }

    return true;
};
