export enum MetadataType {
    PodBegin = 'PodBegin',
    PrerollPodBegin = 'PrerollPodBegin',
    PodEnd = 'PodEnd',
    PrerollPodEnd = 'PrerollPodEnd',
    AdBegin = 'AdBegin',
    AdEnd = 'AdEnd', // Not 100% sure this event exists, but it would make sense if it did.
    NonLinearPod = 'NonLinearPod',
}

export enum JsonAdBreakTrackingEvent {
    BREAK_START = 'BREAK_START',
    BREAK_END = 'BREAK_END',
    breakStart = 'breakStart',
    breakEnd = 'breakEnd',
}

export enum MediaTailorErrorCodes {
    BAD_HTTP_RESPONSE_CODE = 'MT_BAD_HTTP_RESPONSE_CODE',
    NETWORK_ERROR = 'MT_NETWORK_ERROR',
    PARSE_ERROR = 'MT_PARSE_ERROR',
    TIMEOUT = 'MT_TIMEOUT',
    DESTROYED = 'MT_DESTROYED',
    INTERVAL_EXCEEDED = 'MT_INTERVAL_EXCEEDED',
}
