export enum TimelineState {
    WaitingToStart = 'WaitingToStart',
    Started = 'Started',
    Ended = 'Ended',
}

export enum TimelineItemType {
    MainContent = 'MainContent',
    Advert = 'Advert',
}
