import { sdkLogger } from '../../logger';
import { isIon } from '../../utils/device-type';

const PARALLEL: number = 4;
const ION_REQUEST_TIMEOUT = 5000;

export class PixelFetch {
    private static active: Array<string> = [];
    private static pending: Array<string> = [];

    constructor(private context: Window) {}

    public static reset() {
        PixelFetch.active = [];
        PixelFetch.pending = [];
    }

    public fetch(src: string) {
        if (isIon()) {
            this.throttledFetch(src);
        } else {
            this.pixelFetch(src);
        }
    }

    private throttledFetch(src: string): void {
        if (PixelFetch.active.length >= PARALLEL) {
            sdkLogger.verbose(`pixel-fetch:pending:${src}`);
            PixelFetch.pending.push(src);

            return;
        }

        const xhr = new XMLHttpRequest();
        const handleCompletion = () => {
            sdkLogger.verbose(`pixel-fetch:complete:${src}`);

            // cleanup
            xhr.onloadend = null;
            xhr.ontimeout = null;

            PixelFetch.active.splice(PixelFetch.active.indexOf(src), 1);
            // TODO: filter pending requests that have outlived a reasonable TTL
            if (PixelFetch.pending.length >= 0) {
                const urlSrc: string | undefined = PixelFetch.pending.shift();
                if (urlSrc) {
                    this.throttledFetch(urlSrc);
                }
            }
        };

        PixelFetch.active.push(src);

        xhr.timeout = ION_REQUEST_TIMEOUT;
        xhr.ontimeout = () => {
            sdkLogger.warn(`pixel-fetch:timeout:${src}`);
        };
        xhr.onloadend = handleCompletion;

        sdkLogger.verbose(`pixel-fetch:sending:${src}`);
        xhr.open('GET', src);
        xhr.send();
    }

    private pixelFetch(src: string): void {
        const imageElement = this.context.document.createElement('img');
        imageElement.width = 1;
        imageElement.height = 1;
        imageElement.src = src;
        this.context.document.body.appendChild(imageElement);
        this.context.document.body.removeChild(imageElement);
    }
}
