export enum DateRangeAttributes {
    Duration = 'DURATION',
    Class = 'CLASS',
    PlannedDuration = 'PLANNED-DURATION',
    StartDate = 'START-DATE',
    Scte35Cmd = 'SCTE35-CMD',
    Scte35In = 'SCTE35-IN',
    Scte35Out = 'SCTE35-OUT',
    EndDate = 'END-DATE',
    Id = 'ID',
}

/**
 * @public
 */
export enum InterpolationStrategies {
    JustThrottle = 'JustThrottle',
    FullInterpolation = 'FullInterpolation',
    None = 'None',
}

export enum PlaybackTypeKey {
    LINEAR = 'linear',
    VOD = 'vod',
}

// Stage 3 Proposal
// https://github.com/tc39/proposal-promise-allSettled
export enum PromiseStatus {
    Fulfilled = 'Fulfilled',
    Rejected = 'Rejected',
}
