import type { PlayoutResponseData as SkyStorePlayoutResponseData } from './playout-types';
import type { DrmConfiguration, PlayoutData } from '../../core/player/playout-data';
import { StreamingProtocol } from '../../core/player/playout-data';
import type { SkyStoreSessionItem } from '../../core/session-controller/session-controller';
import { CvsdkError, ErrorSeverity } from '../../error';
import { JsonUtils } from '../../utils/json-utils';

export function playoutResponseAdapter(skyStoreVideoData: SkyStorePlayoutResponseData, sessionItem: SkyStoreSessionItem): PlayoutData {
    const adaptedData = {
        // Depend on the application
        autoplay: sessionItem.autoplay,
        muted: sessionItem.muted,
        preferredAudioMetadata: sessionItem.preferredAudioMetadata,
        preferredAudioLanguages: sessionItem.preferredAudioLanguages,
        preferredSubtitleMetadata: sessionItem.preferredSubtitleMetadata,
        preferredSubtitlesLanguages: sessionItem.preferredSubtitlesLanguages,
        playerBitrateLimits: sessionItem.playerBitrateLimits,
        type: sessionItem.type,

        // Depend on SkyStore Video Platform
        drmConfiguration: {
            type: skyStoreVideoData.videoCodingDrm,
            licenceAcquisitionUrl: skyStoreVideoData.licensingUrl,
        } as DrmConfiguration,
        cdns: [{ url: skyStoreVideoData.deliveryFilePath, name: 'SkyStore' }],
        stream: {
            protocol: StreamingProtocol.MSS, // Hardcode for now SkyStore only returns MSS for browser
        },
        durationMs: skyStoreVideoData.runtimeInSeconds * 1000,
        containsMandatoryPinEvents: false,

        // Depend on both the application and SkyStore Video Platform
        abrConfiguration: sessionItem.abrConfiguration,
        position: sessionItem.startPosition ? sessionItem.startPosition : skyStoreVideoData.playbackPositionInSeconds ?? 0,

        // From SDK itself
        sessionId: sessionItem.sessionId,
    };

    return adaptedData;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function playoutDataErrorAdapter(e: any): CvsdkError {
    return CvsdkError.from({
        code: e.code || (e.meta?.httpCode && `SkyStore_HTTP_${e.meta.httpCode}`) || 'SDK_VPI_FAILURE',
        message: e.meta?.message || JsonUtils.stringify(e),
        severity: ErrorSeverity.Fatal,
        cause: e,
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function playerErrorAdapter(e: CvsdkError): CvsdkError {
    return e;
}
