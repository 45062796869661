import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';

import { sdkLogger } from '../../logger';
import { ObserverPriority } from './observable.enums';

export interface Observer<T> {
    (source: T): void;
    (source: T, currentPosition: number | undefined): void;
    (source: T, currentPosition: number | undefined, absolutePosition: Date | undefined): void;
}

export const createErrorLogger = (name: string, logger: Logger) => (error: Error) => {
    logger.error(`Error when notifying observers for ${name}: `, { ...error, message: `${error.message} Error in observable: ${name}` });
};

export const OBSERVABLE_IGNORE_EVENT: unique symbol = Symbol('OBSERVABLE_IGNORE_EVENT');

const defaultErrorHandler = createErrorLogger('Observable', sdkLogger.withContext('Observable'));

interface RegisteredObserver<T> {
    observer: Observer<T>;
    owner: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    meta: RegisteredObserverMeta;
}

export type ObservableState<T> = T | null | undefined;
export type AdaptedState<T> = ObservableState<T> | typeof OBSERVABLE_IGNORE_EVENT;

type ObserverRegistrationConfig = {
    renotifyLastEvent?: boolean;
    priority?: ObserverPriority;
    once?: boolean;
};

type RegisteredObserverMeta = Required<Pick<ObserverRegistrationConfig, 'once' | 'priority'>> & {
    hasBeenNotifiedTimes: number;
};

export class Observable<T> {
    protected currentState: ObservableState<T> = null;
    private hasStateBeenInitialised = false;
    private eventTransformers: Array<(args: ObservableState<T>) => AdaptedState<T>> = [];
    private registeredObservers: Array<RegisteredObserver<T>> = new Array<{
        observer: Observer<T>;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        owner: any;
        meta: RegisteredObserverMeta;
    }>();

    constructor(private errorHandler: (e: Error) => void = defaultErrorHandler) {}

    public getCurrentState(): ObservableState<T> {
        return this.currentState;
    }

    public addTransformer(transformingFunction: (args: ObservableState<T>) => AdaptedState<T>): void {
        this.eventTransformers.push(transformingFunction);
    }

    public registerObserver(observer: Observer<T>, owner: object, config?: ObserverRegistrationConfig): () => void {
        const observerMetadata = this.setupObserverMetadata(config);
        this.addObserverWithPriority({ observer, owner, meta: observerMetadata });

        if (config?.renotifyLastEvent) {
            this.doNotify([{ observer, owner, meta: observerMetadata }], this.currentState);
        }
        return () => this.unregisterObserver(observer);
    }

    private addObserverWithPriority(item: RegisteredObserver<T>) {
        for (let i = 0; i < this.registeredObservers.length; i++) {
            // Note: Higher priority means a lower number (e.g. P0 is higher priority than P1)
            const isHigherPriority = item.meta.priority < this.registeredObservers[i].meta.priority;
            if (isHigherPriority) {
                this.registeredObservers.splice(i, 0, item);
                return;
            }
        }
        this.registeredObservers.push(item);
    }

    public unregisterObserver(observer: Observer<T>): void {
        this.registeredObservers.forEach((item, index) => {
            if (item.observer !== observer) {
                return;
            }

            this.registeredObservers.splice(index, 1);
        });
    }

    public unregisterObservers(owner: object): void {
        this.registeredObservers = this.registeredObservers.filter((item) => item.owner !== owner);
    }

    public reset(): void {
        this.currentState = null;
        this.hasStateBeenInitialised = false;
    }

    public notifyObservers(newState?: T, currentPosition?: number, absolutePosition?: Date): AdaptedState<T> {
        const isNewStateUndefined = typeof newState === 'undefined';
        const isStatelessObservable = typeof this.currentState === 'undefined' && isNewStateUndefined;

        const adaptedState: AdaptedState<T> = this.transformEventState(newState);

        if (adaptedState !== OBSERVABLE_IGNORE_EVENT && (isStatelessObservable || this.currentState !== adaptedState)) {
            this.hasStateBeenInitialised = true;
            this.currentState = adaptedState;
            this.doNotify(this.registeredObservers, adaptedState, currentPosition, absolutePosition);
        }

        return adaptedState;
    }

    private doNotify(
        observers: Array<RegisteredObserver<T>>,
        adaptedState: ObservableState<T>,
        currentPosition?: number,
        absolutePosition?: Date
    ): void {
        if (!this.hasStateBeenInitialised) {
            return;
        }
        observers?.forEach((item) => {
            try {
                if (item.meta.hasBeenNotifiedTimes++ > 0 && item.meta.once) {
                    defaultErrorHandler(new Error('Observable has already notified but was configured to notify once.'));
                    return;
                }
                if (item.observer.length > 2) {
                    item.observer(adaptedState as T, currentPosition, absolutePosition);
                } else if (item.observer.length > 1) {
                    item.observer(adaptedState as T, currentPosition);
                } else {
                    item.observer(adaptedState as T);
                }
            } catch (error) {
                this.errorHandler(error as Error);
            }
        });
    }

    private transformEventState = (newState: ObservableState<T>): AdaptedState<T> => {
        let transformedState: AdaptedState<T> = newState;

        for (const fn of this.eventTransformers) {
            if (transformedState === OBSERVABLE_IGNORE_EVENT) {
                break;
            }

            try {
                const nextState = fn(transformedState);
                transformedState = nextState;
            } catch (error) {
                this.errorHandler(error as Error);
            }
        }

        return transformedState;
    };

    private setupObserverMetadata(config?: ObserverRegistrationConfig): RegisteredObserverMeta {
        return {
            once: config?.once ?? false,
            hasBeenNotifiedTimes: 0,
            priority: config?.priority ?? ObserverPriority.NORMAL,
        };
    }
}
