import type { Track } from '../../../core/player/track';
import type { PlaybackTimelineInternal, SessionControllerInternal } from '../../../core/session-controller/session-controller-internal';
import type { CompanionAdVariant, NonLinearAdData } from '../non-linear-adverts/non-linear-ad-types';

import type { Logger } from '@sky-uk-ott/core-video-sdk-js-logger';
import { PixelFetch } from '../pixel-fetch';
import { sdkLogger } from '../../../logger';
import { AdBreakType, AdMetadataSource, AdStreamType, SsaiStitcherType } from '../common';
import type { Ad } from '../common';

type CompanionAdSubsetSessionController = Pick<
    SessionControllerInternal,
    | 'notifyCompanionAdOpportunityStarted'
    | 'notifyCompanionAdOpportunityEnded'
    | 'onSubtitlesTrackChanged'
    | 'onPlaybackTimelineUpdated'
    | 'notifyAdStarted'
    | 'notifyAdFinished'
>;

/**
 * Raised at a time where a non-linear frame ad may be shown
 * We only currently expect the companion ad to be of the
 * 'Frame ad' template type but there is planned work to
 * add more (i.e. 'Marquee ad variant')
 * @public
 */
export type CompanionAdOpportunityStartedEvent = {
    data: CompanionAdVariant;
    companionAdDidStart: () => void;
    companionAdDidEnd: () => void;
};

export type CompanionAdOpportunityEndedEvent = {
    adId: string;
    companionAdTemplateType: string;
};

export class CompanionAdvertDispatcher {
    private logger: Logger = sdkLogger.withContext('CompanionAdvertDispatcher');
    private encounteredAdIds: Set<string> = new Set<string>();
    private consumedAdStartedIds: Set<string> = new Set<string>();
    private consumedAdEndedIds: Set<string> = new Set<string>();
    private notifiedAdStartedIds: Set<string> = new Set<string>();
    private notifiedAdEndedIds: Set<string> = new Set<string>();
    private scheduledCompanionAdOpportunityEvents: Map<
        string,
        {
            startTime: number;
            endTime: number;
            signalAdOpportunityStarted: Function;
            signalAdOpportunityEnded: Function;
        }
    > = new Map();
    private currentTimeSeconds: number | undefined = 0;

    private isCompanionAdsDispatchEnabled = true;

    constructor(
        private sessionController: CompanionAdSubsetSessionController,
        private disableCompanionAdsWhenCaptionEnabled: boolean = false,
        private pixelFetch: PixelFetch = new PixelFetch(self as unknown as Window)
    ) {
        this.listenToSessionEvents();
    }

    public addAdEventsFromAdData(ads: Array<NonLinearAdData<CompanionAdVariant>>): void {
        if (!this.isCompanionAdsDispatchEnabled) {
            this.logger.info('Companion Ad opportunity encountered but ignored because captions have been enabled');
            return;
        }

        if (!ads) {
            return this.logger.error(`Malformed nonLinear ads data received, expected array of Companion ads but received`, ads);
        }

        ads.filter(
            (nonLinearAd): nonLinearAd is NonLinearAdData<CompanionAdVariant> =>
                this.isValidCompanionAd(nonLinearAd) && !this.adAlreadyEncountered(nonLinearAd.variants[0].adId)
        ).forEach((companionAd) => {
            const {
                variants: [data], // frame ads only have 1 variant
            } = companionAd;
            const { adId, durationInSeconds, startTimeInSeconds, companionAdTemplateType } = data;

            // malformed opportunity received, do not raise as an opportunity and skip any initialisation of beacons
            if (!companionAdTemplateType) {
                return this.logger.error(
                    `Malformed ad opportunity received from SSAI tracking call: an companion ad template type is expected but got ${companionAdTemplateType}, this opportunity with id ${adId} will be ignored and no no reporting beacons will be fired`,
                    adId
                );
            }

            const adReportingData = this.buildAdReportingData(data);
            const endOpportunityTimeInSeconds = startTimeInSeconds + durationInSeconds;
            const uniqueAdIdWithTime = this.generateAdIdWithTimeInSecondsLabel(adId, startTimeInSeconds);

            this.logger.info(
                `${companionAdTemplateType} Ad opportunity received, scheduling notifications for [start, end] of opportunity`,
                adId,
                `[${startTimeInSeconds}, ${endOpportunityTimeInSeconds}]`,
                `-- current time: ${this.currentTimeSeconds} seconds --`
            );

            // record encountered opportunity by id to ensure we handle edge case of duplicates
            this.encounteredAdIds.add(adId);

            this.scheduledCompanionAdOpportunityEvents.set(uniqueAdIdWithTime, {
                startTime: startTimeInSeconds,
                endTime: endOpportunityTimeInSeconds,
                signalAdOpportunityStarted: this.signalAdOpportunityStarted.bind(this, uniqueAdIdWithTime, companionAd, adReportingData),
                signalAdOpportunityEnded: this.signalAdOpportunityEnded.bind(this, uniqueAdIdWithTime, companionAd, adReportingData),
            });
        });
    }

    private generateAdIdWithTimeInSecondsLabel(adId: string, startTimeInSeconds: number): string {
        return `${adId}_${startTimeInSeconds}`;
    }

    private logFrameAdIgnoreDuplicateReportingBeacon(
        consumedContext: Set<string>,
        adId: string,
        readableCompanionAdType: string,
        context: string
    ): boolean {
        const hasBeenEncountered = consumedContext.has(adId);
        if (!hasBeenEncountered) {
            return false;
        }

        this.logger.warn(`[ ${readableCompanionAdType} ${context} ]: Duplicate ${readableCompanionAdType} Ad (adId: ${adId}) encountered`);
        return true;
    }

    private isValidCompanionAd(nonLinearAd: NonLinearAdData): nonLinearAd is NonLinearAdData<CompanionAdVariant> {
        if (!nonLinearAd) {
            this.logger.verbose('Expected companion ad data but instead got', nonLinearAd);
            return false;
        }
        if (!nonLinearAd.hasOwnProperty('variants')) {
            this.logger.verbose('Expected companion ad data to contain a variant property but none was found', nonLinearAd.variants);
            return false;
        }
        if (!nonLinearAd.variants || !nonLinearAd.variants.length) {
            this.logger.verbose('Expected companion ad variant data to be an array of variants but instead got', nonLinearAd.variants);
            return false;
        }
        if (!nonLinearAd.variants[0].hasOwnProperty('adId')) {
            this.logger.verbose(
                'Expected companion ad variant data array to contain a variant element with an "adId" property but none was found',
                nonLinearAd.variants
            );
            return false;
        }
        if (!(nonLinearAd.variants[0] as CompanionAdVariant).adId) {
            this.logger.verbose(
                'Expected companion ad variant data to contain a variant element with a valid "adId" property but but found adId is',
                (nonLinearAd.variants[0] as CompanionAdVariant).adId
            );
            return false;
        }

        return true;
    }

    private adAlreadyEncountered(adId: string): boolean {
        const alreadyEncountered = this.encounteredAdIds.has(adId);
        if (!alreadyEncountered) {
            this.logger.info(`NEW Ad opportunity encountered`, adId, `[current time in seconds: ${this.currentTimeSeconds}]`);
        }
        return alreadyEncountered;
    }

    private listenToSessionEvents(): void {
        this.sessionController.onSubtitlesTrackChanged((track?: Track): void => {
            if (this.disableCompanionAdsWhenCaptionEnabled && track && track.id) {
                this.isCompanionAdsDispatchEnabled = false;
            } else {
                this.isCompanionAdsDispatchEnabled = true;
            }
        });

        this.sessionController.onPlaybackTimelineUpdated((_timeline: PlaybackTimelineInternal, currentTimeSeconds: number | undefined): void => {
            this.currentTimeSeconds = currentTimeSeconds;
            // check if there are any encountered ad opportunities in our collection
            if (this.scheduledCompanionAdOpportunityEvents.size < 1 || !currentTimeSeconds) {
                return;
            }

            /**
             * - iterate through companion ad opportunity collection
             * - (key is the scheduled time of start time + duration)
             * - IF they exceed current time
             *   - notify subscribers that the ad opportunity has started/ended
             *   - delete the scheduled signal to avoid duplicate notifications of the event
             */
            for (const companionAd of this.scheduledCompanionAdOpportunityEvents) {
                const [uniqueAdId, { startTime, endTime, signalAdOpportunityStarted, signalAdOpportunityEnded }] = companionAd;
                // only notify if within the ad opportunity range and if this specified opportunity starting has not already been signalled
                if (currentTimeSeconds >= startTime && currentTimeSeconds < endTime && !this.notifiedAdStartedIds.has(uniqueAdId)) {
                    this.logger.info(
                        'frame notifySTART @',
                        currentTimeSeconds,
                        `[startTime, endTime]: [${startTime}, ${endTime}]`,
                        uniqueAdId,
                        this.notifiedAdStartedIds
                    );
                    signalAdOpportunityStarted();
                }

                // only notify if if outside of ad opportunity range, start signal had been made and has not been already notified of the end signal
                if (currentTimeSeconds >= endTime && this.notifiedAdStartedIds.has(uniqueAdId) && !this.notifiedAdEndedIds.has(uniqueAdId)) {
                    this.logger.info(
                        'frame notifyEND @',
                        currentTimeSeconds,
                        `[startTime, endTime]: [${startTime}, ${endTime}]`,
                        uniqueAdId,
                        this.notifiedAdStartedIds
                    );
                    signalAdOpportunityEnded();
                }
            }
        });
    }

    private signalAdOpportunityStarted(uniqueAdId: string, companionAdData: NonLinearAdData<CompanionAdVariant>, adReportingData: Ad): void {
        const {
            adStartedBeacons,
            adFinishedBeacons,
            variants: [data],
        } = companionAdData;
        const { companionAdTemplateType, startTimeInSeconds } = data;
        this.logger.info(
            `${companionAdTemplateType} Ad opportunity started, notifying subscribers`,
            uniqueAdId,
            startTimeInSeconds,
            `[current time in seconds: ${this.currentTimeSeconds}]`
        );

        this.notifiedAdStartedIds.add(uniqueAdId);
        this.sessionController.notifyAdStarted(adReportingData);
        this.sessionController.notifyCompanionAdOpportunityStarted({
            data,
            companionAdDidStart: () => {
                if (this.logFrameAdIgnoreDuplicateReportingBeacon(this.consumedAdStartedIds, uniqueAdId, companionAdTemplateType, 'Ad Started')) {
                    return;
                }

                adStartedBeacons.forEach((beacon) => this.pixelFetch.fetch(beacon));
                this.consumedAdStartedIds.add(uniqueAdId);
                this.logger.info(`${companionAdTemplateType} Ad started (adId: ${uniqueAdId}), impression beacon(s) sent`);
            },
            companionAdDidEnd: () => {
                if (this.logFrameAdIgnoreDuplicateReportingBeacon(this.consumedAdEndedIds, uniqueAdId, companionAdTemplateType, 'Ad Ended')) {
                    return;
                }

                adFinishedBeacons.forEach((beacon) => this.pixelFetch.fetch(beacon));
                this.consumedAdEndedIds.add(uniqueAdId);
                this.logger.info(`${companionAdTemplateType} Ad ended (adId: ${uniqueAdId}), ending beacon(s) sent`);
            },
        });
    }

    private signalAdOpportunityEnded(uniqueAdId: string, companionAdData: NonLinearAdData<CompanionAdVariant>, adReportingData: Ad): void {
        const {
            variants: [data],
        } = companionAdData;
        const { adId, companionAdTemplateType, durationInSeconds, startTimeInSeconds } = data;
        this.logger.info(
            `${companionAdTemplateType} Ad opportunity ended, notifying subscribers`,
            adId,
            startTimeInSeconds + durationInSeconds,
            `[current time in seconds: ${this.currentTimeSeconds}]`
        );
        this.notifiedAdEndedIds.add(uniqueAdId);
        this.sessionController.notifyAdFinished(adReportingData);
        this.sessionController.notifyCompanionAdOpportunityEnded({ adId, companionAdTemplateType });
        this.scheduledCompanionAdOpportunityEvents.delete(uniqueAdId);
    }

    private buildAdReportingData(adData: CompanionAdVariant): Ad {
        const { adTitle, creativeId: adCreativeId, durationInSeconds, adInsights } = adData;
        const modifiedCreativeId = adInsights.creativeId ? 'fa:' + adInsights.creativeId : adCreativeId ? 'fa:' + adCreativeId : 'NA';
        const ad = {
            id: adInsights.breakId || 'NA', // adBreaks.id is used to ensure `breakId` is set in Ad Insights. Needs to match adBreak.id (see line 235)
            creativeId: modifiedCreativeId, // createId is the value suffixed with `fa:` to help differentiate it from regular ad types
            name: adInsights.creativeName || adTitle,
            expectedDuration: durationInSeconds,
            advertiser: adInsights.advertiser || 'NA',
        };
        return {
            ...ad,
            adBreak: {
                id: adInsights.breakId || 'NA', // adBreaks.id is used to ensure `breakId` is set in Ad Insights. Needs to match adBreak.i (see line 226)
                expectedDuration: durationInSeconds,
                // the `ads` field is necessary for adBreak property because for reporting to be correct
                // the ads array needs to be populated with at least one Ad-like element so that the `sequence`
                // value can be calculated correctly as 1 for companion ads
                ads: [ad as Ad],
                type: AdBreakType.Midroll,
                watched: true,
                streamType: AdStreamType.InStream,
                ssaiStitcherType: SsaiStitcherType.MediaTailor,
                metadataSource: AdMetadataSource.MediaTailor,
            },
        } as Ad;
    }
}
