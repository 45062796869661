import type { Ad, AdBreak } from '../../../../../addons/adverts/common';
import type { JsonAdBreakMetadata, JsonNonLinearAdBreakMetadata } from '../../json-ad-breaks';

/**
 * Double Box Adapter converts NonLinearAvails into Regular DAI ads using adapter pattern
 * Step by step algorithm
 * 1. Retrieve DoubleBox NonLinearAvails from the rest by identifying nonLinearAd.staticResourceCreativeType === 'null/null'
 * 2. Convert Double Box NonLinearAvails to DAI ad breaks using parser callback
 * 3. Combine regular DAI with new double box DAI and filter out duplicates, while favouring the double box ads.
 * Sometimes regular DAI may have same startPosition as doubleBox DAI, but these regular DAI are empty so they are safe to remove
 */
export class DoubleBoxToAdBreaksAdapter {
    public static adapt(
        adBreaks: AdBreak<Ad>[],
        doubleBoxAds: JsonNonLinearAdBreakMetadata[],
        parseAdBreakData: (rawAdBreak: JsonAdBreakMetadata, isCsai: boolean, adServerCdn?: string) => AdBreak,
        isCsai: boolean,
        adServerCdn?: string
    ): Array<AdBreak<Ad>> {
        const doubleBoxAdBreaks = DoubleBoxToAdBreaksAdapter.convertDoubleBoxIntoDaiAdBreaks(doubleBoxAds, parseAdBreakData, isCsai, adServerCdn);
        const combinedAdBreaks = adBreaks
            .filter((adBreak: AdBreak<Ad>) => {
                // For DAI Ad Breaks which ID is the same as nonLinearAvails Id.
                // DAI Ad Breaks have ads = [], so they can be filtered out. Their complementary from nonLinearAvails contains ads when converted to DAI so the ad break is preserved
                // And duplicated is filtered out
                const isDuplicated = Boolean(
                    doubleBoxAdBreaks.find((convertedDaiAdBreak: AdBreak<Ad>) => {
                        let convertedDaiAdBreakId = convertedDaiAdBreak.id;
                        if (convertedDaiAdBreakId.includes('_')) {
                            // Format like: 705534_PT764H51M50.0103622S -> 705534s
                            convertedDaiAdBreakId = convertedDaiAdBreakId.slice(0, convertedDaiAdBreakId.lastIndexOf('_'));
                        }

                        let adBreakId = adBreak.id;
                        if (adBreakId.includes('_')) {
                            // Format like: 705534_PT764H51M50.0103622S -> 705534
                            adBreakId = adBreakId.slice(0, adBreakId.lastIndexOf('_'));
                        }

                        return convertedDaiAdBreakId === adBreakId;
                    })
                );
                return !isDuplicated;
            })
            .concat(doubleBoxAdBreaks);

        return combinedAdBreaks;
    }

    public static splitDoubleBoxFromNonLinearAvails(nonLinearAvails: Array<JsonNonLinearAdBreakMetadata>): {
        doubleBoxAds: Array<JsonNonLinearAdBreakMetadata>;
        nonLinearAds: Array<JsonNonLinearAdBreakMetadata>;
    } {
        return nonLinearAvails.reduce(
            (acc, nonLinearAvail) => {
                const isDoubleBoxAd = nonLinearAvail.nonLinearAdsList.some((nonLinearAds) => {
                    return nonLinearAds.nonLinearAdList.some((nonLinearAd) => nonLinearAd.staticResourceCreativeType === 'null/null');
                });
                if (isDoubleBoxAd) {
                    acc.doubleBoxAds.push(nonLinearAvail);
                } else {
                    acc.nonLinearAds.push(nonLinearAvail);
                }
                return acc;
            },
            { doubleBoxAds: [] as Array<JsonNonLinearAdBreakMetadata>, nonLinearAds: [] as Array<JsonNonLinearAdBreakMetadata> }
        );
    }

    private static convertDoubleBoxIntoDaiAdBreaks(
        nonLinearAvails: Array<JsonNonLinearAdBreakMetadata>,
        parseAdBreakData: (rawAdBreak: JsonAdBreakMetadata, isCsai: boolean, adServerCdn?: string) => AdBreak,
        isCsai: boolean,
        adServerCdn?: string
    ): AdBreak[] {
        const adBreaks: AdBreak[] = nonLinearAvails.map((nonLinearAvail: JsonNonLinearAdBreakMetadata) => {
            const rawAdBreak: Partial<JsonAdBreakMetadata> = {
                ...nonLinearAvail,
                ads: nonLinearAvail.nonLinearAdsList,
            };

            return parseAdBreakData(rawAdBreak as JsonAdBreakMetadata, isCsai, adServerCdn);
        });

        return adBreaks;
    }
}
