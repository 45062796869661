import { DeviceModel, DeviceType } from '@sky-uk-ott/client-lib-js-device';
import type { OVPTokenServiceInstance } from '@sky-uk-ott/client-lib-js-ott-ovp-token';

import type { CommonAddonsConfig } from '../../../config/internal-config';
import { InternalConfigProvider } from '../../../config/internal-config-provider';
import { CoreVideoInternal } from '../../../core-video-internal';
import type { PlayoutData } from '../../../core/player/playout-data';
import { PlaybackType, StreamingProtocol } from '../../../core/player/playout-data';
import type { FreewheelConfig, SlotParameters } from '../../../core/services/freewheel-types';
import { TestingOverrides } from '../../../core/services/testing-overrides';
import { sdkLogger } from '../../../logger';
import type { JsonAdBreaks } from '../../../players/bolt-ons/ad-breaks-provider/json-ad-breaks';
import { isSafariDesktop, isXClass, isX1, isXumosb } from '../../../utils/device-type';
import { checkIsFireTv } from '../../../utils/platform-recognition/android';
import { checkIsManifestLinearType } from '../../../utils/playback-type';
import { promiseWithTimeout } from '../../../utils/promise-utils';
import type { QueryParams } from '../../../utils/url-builder';
import { appendQueryParams } from '../../../utils/url-builder';
import { AdInsertionType } from '../../ad-insertion-type';
import type { AssetMetadata, ReportingData } from '../../addon-playout-data';
import { AssetType } from '../../addon-playout-data';
import type { BaseVacConfig, VacType } from '../base';
import type { Vac } from '../vac-addon';
import { Platform, StreamSubType, StreamType } from './vam.enums';
import { MultiPlayerDisplayType } from '../../adverts/types';
import { isOldWebOs } from '../../../utils/device-webos';

const VAM_PROMISE_DEFAULT_TIMEOUT = 2000;

// API Docs Available:
// https://videoadsmodulev1.docs.apiary.io/#reference/0/freewheel-params/freewheel-params

//cspell:words oogway

export namespace Vam {
    export interface Config extends BaseVacConfig {
        type: VacType.VAM;
        requestTimeoutMs?: number;
        doubleBoxEnabled?: boolean;
        requestData: Pick<
            RequestParameters,
            | 'appBrand'
            | 'appBuild'
            | 'appBundleId'
            | 'appName'
            | 'deviceAdvertisingId'
            | 'deviceAdvertisingIdType'
            | 'obfuscatedFreewheelPersonaId'
            | 'obfuscatedFreewheelProfileId'
        > & {
            accountSegments?: Array<string>; // Mutated into accountSegment
            contentSegments?: Array<string>; // Mutated into contentSegment
            personaSegments?: Array<string>; // Mutated into personaSegment
            playerName?: string; // Optional from clients
        };
    }

    export type ClientData = Pick<
        RequestParameters,
        | 'brightlineEnabled'
        | 'coppaApplies'
        | 'curator'
        | 'deviceAdvertisingTrackingConsent'
        | 'frameAdsEnabled'
        | 'gdprApplies'
        | 'gdprConsent'
        | 'gdprConsentString'
        | 'gpp'
        | 'gppSid'
        | 'httpReferer'
        | 'locationPostalCode'
        | 'mParticleId'
        | 'marqueeAdsEnabled'
        | 'mvpdHash'
        | 'territory'
        | 'usPrivacy'
    > & {
        bingeCount?: number | null; // Maps to 'bc' parameter
        disableFrameAdsWhenCaptionEnabled?: boolean;
        disableCompanionAdsWhenCaptionEnabled?: boolean;
        durationMs?: number; // Mutated into videoDurationInSeconds
    };

    type SdkGeneratedOptions = {
        deviceType: DeviceType; // inferred and populated by Core Video SDK
        deviceModel?: DeviceModel; // inferred and populated by Core Video SDK
        deviceVersion?: string; // inferred and populated by Core Video SDK
        playbackType: PlaybackType; // populated by Core Video SDK
        streamingProtocol: StreamingProtocol;
        playerDimensions: { width: number; height: number };
        adProvider: AdInsertionType;
        isMultiview?: boolean; // inferred and populated by Core Video SDK
    } & Pick<
        RequestParameters,
        | 'slePreRoll'
        | 'isMiniPlayer'
        | 'adServerContentId'
        | 'cdnName'
        | 'adCompatibilityEncodingProfile'
        | 'httpUserAgent'
        | 'playerVersion'
        | 'sdkName'
        | 'sdkVersion'
        | 'isPrefetch'
    >;

    export type RequestOptions = ClientData & SdkGeneratedOptions;

    // NOTE: This list should ONLY contain valid vam params from their API documentation
    // https://videoadsmodulev1.docs.apiary.io/#introduction/parameters
    export type RequestParameters = {
        accountSegment?: string;
        adCompatibilityEncodingProfile: string; // A concatenated string of the media container and frame rate (e.g. CMAF_24). These values are defined by NBCU. Will be provided by OVP.
        adServerContentId: string; // Will be a different ID to the current content ID. Will be provided in the OVP response
        appBrand?: string;
        appBuild: string;
        appBundleId: string;
        appName: string;
        appVersion: string;
        // Generally combination of the ISO 639-1 + ISO 3166 standards (2-2) (ex: en-US, en-GB)
        // but there are exceptions, see: https://wiki.inbcu.com/display/PTC/Language+Codes+for+Playback
        audioLanguage?: string;
        bc?: number; // The binge view counter. Start with 0 to first, 1 to second, etc.
        brand: string; // the content owner will be one of the NBCUniversal entertainment brands, such as NBC, USA, Bravo, Oxygen, etc.
        brightlineEnabled?: boolean;
        cdnName: string; // populated by Core Video SDK. Name of the CDN being used. Should be populated from the OVP response
        channelName?: string;
        contentSegment?: string;
        coppaApplies?: boolean; // COPPA is a piece of work that will be coming in shortly, but we can assume to receive this field from clients
        curator?: string; // Curator ads allows advertisers a prominent position in a video environment without using traditional video formats that extend the breaks between content. (https://github.com/sky-uk/core-video-team/issues/4378)
        deviceAdvertisingId: string;
        deviceAdvertisingIdType: string;
        deviceAdvertisingTrackingConsent?: boolean;
        doubleBoxEnabled?: boolean;
        extmp?: string; // Experience Template, a string which will pass through VAM to Freewheel as 'am_extmp". A request containing extmp will not be subject to AB testing.
        frameAdsEnabled?: boolean;
        fwAdUnitId?: string; // Ad Integrations can support 'tearsheet' capability, allowing ad sales to demo client advertisers' ads 'in situ', forcing ads to play in selected content by including one of the VAM UI-managed testKeys values in the web player ONLY AND one or more fwAdUnitId values. globalParameters.cana=fwAdUnitId.
        gdprApplies?: boolean;
        gdprConsent?: boolean;
        gdprConsentString?: string; // A long string of characters representing specific attributes of GDPR consent
        gpp?: string; // Will be provided by clients. An encoded string parameter - standardized way for privacy signals to be created
        gppSid?: number; // // Will be provided by clients. GPP section, numeric section ID that indicates the jurisdiction to which the GPP string applies
        httpReferer?: string; // will be provided by clients. required only for web browsers
        httpUserAgent: string; // inferred and populated by Core Video SDK
        isMiniPlayer?: boolean; // Tell Freewheel if someone is watching content full screen or in the mini player.
        isPrefetch?: boolean;
        locationPostalCode?: string; // only mandatory for X1. Will be provided by clients
        mParticleId?: string;
        marqueeAdsEnabled?: boolean;
        multiplayer?: string; // attached only when multiview is enabled
        mvpdHash?: string; // populated by Core Video SDK. Will be hardcoded as 'D2C' for now
        obfuscatedFreewheelPersonaId?: string;
        obfuscatedFreewheelProfileId?: string; // Retrieved from Oogway
        personaSegment?: string;
        platform: Platform; // populated by Core Video SDK. Should work in a similar way to Conviva - we receive the platform already as an `enum`, and Core Video SDK transforms this into the expected value, so that if a value changed in the future, it would only need to be changed in one place
        playerHeightPixels: number; // inferred and populated by Core Video SDK
        playerName: string; // populated by Core Video SDK. Name of the player being used. Should be aligned with Conviva player names
        playerVersion: string; // populated by Core Video SDK. Version number of the player being used
        playerWidthPixels: number; // inferred and populated by Core Video SDK
        playlistClipPosition?: number; // the number of the clip from the playlist eg. 1, 2, etc.
        playlistName?: string; // the content playlist that is playing. For example, SNL Halloween may pass "snlhalloween"
        sdkName: string; // populated by Core Video SDK. Name of playback SDK being used (e.g. core-video-sdk-js)
        sdkVersion: string; // populated by Core Video SDK. Version number of playback SDK being used (e.g. 0.4.0)
        slePreRoll?: boolean;
        streamSubType?: StreamSubType;
        streamType: StreamType;
        subtitleLanguage?: string;
        territory?: string;
        usPrivacy?: string;
        variantId?: string; // set to `safari` when running on Safari
        videoDurationInSeconds?: number; // will be provided by OVP"
    };

    // VAM will return a slot parameter object where the outer ID is contextual and
    // the inner IDs are the standard key-value pairs.
    export type SlotParams = { [key: string]: SlotParameters };
    export interface ResponseData {
        ['yo.ap']?: string;
        ['mt.config']?: string;
        baseUrlIpv4: string;
        baseUrlIpv6: string;
        globalParameters: FreewheelConfig['globalParameters'];
        keyValues: FreewheelConfig['keyValues'];
        slotParameters?: SlotParams;
        sleCsai?: JsonAdBreaks;
        omallows?: Array<OpenMeasurementAllowedScript>;
    }

    export type OpenMeasurementAllowedScript = {
        scriptURL: string;
        checksum: string;
    };

    const devicePlatformMap: { [key in DeviceType]: Platform | null } = {
        [DeviceType.Android]: Platform.androidtv,
        [DeviceType.Browser]: Platform.web,
        [DeviceType.Chromecast]: Platform.chromecast,
        [DeviceType.Kepler]: Platform.firetvkepler, // firetv-kepler
        [DeviceType.Tizen]: Platform.samsungtv,
        [DeviceType.WebMaf]: Platform.ps4,
        [DeviceType.Prospero]: Platform.ps5,
        [DeviceType.WebOS]: Platform.lgtv,
        [DeviceType.XboxOne]: Platform.windows,
        [DeviceType.XboxSeries]: Platform.windows,
        [DeviceType.Xfinity]: Platform.x1,
        [DeviceType.Generic]: null,
        [DeviceType.Ion]: null,
        [DeviceType.Llama]: null,
        [DeviceType.Netgem]: Platform.netgem,
        [DeviceType.YouView]: Platform.youview,
        [DeviceType.Zenterio]: null,
        [DeviceType.Vidaa]: Platform.vidaatv,
        [DeviceType.Vizio]: Platform.viziotv,
        [DeviceType.RDK]: Platform.windows,
        [DeviceType.OneSdk]: Platform.windows,
        [DeviceType.MultiChoiceRdk]: null,
        [DeviceType.Quest]: Platform.quest,
        [DeviceType.Kpn]: null, // TODO: https://jira.inbcu.com/browse/PBSCVJS-3939
    };

    const ionPlatformMap: { [key in DeviceModel]: Platform | null } = {
        [DeviceModel.PS4]: Platform.ps5,
        [DeviceModel.PS5]: Platform.ps5,
    };

    export function hydrateVamResponse(
        vac: Vam.ResponseData,
        segmentationUpId: string,
        duration: number,
        scte35_counter: number
    ): Vam.ResponseData | null {
        // https://github.com/NBCUDTC/core-video-team/wiki/Ad-Insertion
        const initialVacData = { ...vac };
        const slotParameters = initialVacData.slotParameters ? { ...initialVacData.slotParameters } : {};
        const globalParameters = { ...initialVacData.globalParameters, caid: segmentationUpId };

        const zeroContextParams: SlotParameters = slotParameters[''] || {};
        zeroContextParams.slid = `midroll_${scte35_counter}`;
        zeroContextParams.slau = 'midroll';
        zeroContextParams.tpos = '0';
        zeroContextParams.ptgt = 'a';
        zeroContextParams.mind = `${duration}`;
        zeroContextParams.maxd = `${duration}`;
        zeroContextParams.cpsq = `${scte35_counter}`;

        slotParameters[''] = zeroContextParams;

        return {
            ...initialVacData,
            slotParameters,
            globalParameters,
        };
    }

    export class Addon implements Vac.Addon {
        public readonly name: string = 'VAM';

        constructor(
            private config: Config,
            private reportingData: ReportingData,
            private playoutData: PlayoutData,
            private umvToken: Promise<string | undefined>,
            private getToken?: OVPTokenServiceInstance['getToken']
        ) {}

        public destroy(): void {}

        public getVacRequestParameters(requestOptions: Vac.RequestOptions): Vac.VamRequestParameters;
        public getVacRequestParameters(requestOptions?: Vac.RequestOptions): Vac.RequestParameters;

        public getVacRequestParameters(requestOptions: RequestOptions): Vac.VamRequestParameters {
            const assetMetadata = this.reportingData?.assetMetadata;
            const isLinear = checkIsManifestLinearType(requestOptions.playbackType);

            const streamTypes = this.convertToVACStreamTypes(this.reportingData, requestOptions.playbackType);
            const platform = this.detectPlatform(requestOptions.deviceType, requestOptions.deviceModel);
            const variantId = this.detectVariantId(requestOptions.adProvider, requestOptions.deviceType, requestOptions.streamingProtocol);

            const { channelName, playlistName, playlistClipPosition } = this.getPlaylistInfo(isLinear);

            const videoDurationInSeconds = this.calculateVideoDuration(isLinear, requestOptions.durationMs);
            sdkLogger.verbose(`VAC Asset duration ${requestOptions.durationMs}ms`);

            const config = InternalConfigProvider.getForPlaybackType(CoreVideoInternal.config, requestOptions.playbackType);
            const { appIdentifier, appVersion } = config.addons?.common || ({} as CommonAddonsConfig);
            const configuredRequestData = this.config.requestData;
            const audioLanguage = this.playoutData.preferredAudioLanguages?.[0] || this.playoutData.preferredAudioTrack?.language;
            const subtitleLanguage = this.playoutData.preferredSubtitlesLanguages?.[0] || this.playoutData.preferredSubtitlesTrack?.language;

            const requestData: RequestParameters = {
                accountSegment: configuredRequestData.accountSegments?.join('|'),
                adCompatibilityEncodingProfile: requestOptions.adCompatibilityEncodingProfile,
                adServerContentId: requestOptions.adServerContentId,
                appBuild: configuredRequestData.appBuild,
                appBundleId: configuredRequestData.appBundleId,
                appName: configuredRequestData.appName,
                appBrand: configuredRequestData?.appBrand,
                appVersion,
                audioLanguage,
                bc: requestOptions.bingeCount ?? 0,
                brand: assetMetadata?.brand as string,
                brightlineEnabled: Boolean(requestOptions.brightlineEnabled),
                cdnName: requestOptions.cdnName,
                channelName,
                contentSegment: configuredRequestData.contentSegments?.join('|'),
                coppaApplies: requestOptions.coppaApplies,
                curator: requestOptions.curator,
                deviceAdvertisingId: CoreVideoInternal.deviceAdsId ?? configuredRequestData.deviceAdvertisingId,
                deviceAdvertisingIdType: configuredRequestData.deviceAdvertisingIdType,
                deviceAdvertisingTrackingConsent: requestOptions.deviceAdvertisingTrackingConsent,
                doubleBoxEnabled: this.config?.doubleBoxEnabled,
                frameAdsEnabled: Boolean(requestOptions.frameAdsEnabled),
                gdprApplies: requestOptions.gdprApplies,
                gdprConsent: requestOptions.gdprConsent,
                gdprConsentString: requestOptions.gdprConsentString,
                gpp: requestOptions.gpp,
                gppSid: requestOptions.gppSid,
                httpReferer: requestOptions.httpReferer,
                httpUserAgent: requestOptions.httpUserAgent,
                isMiniPlayer: requestOptions.isMiniPlayer,
                isPrefetch: requestOptions.isPrefetch,
                locationPostalCode: requestOptions.locationPostalCode,
                mParticleId: requestOptions.mParticleId,
                marqueeAdsEnabled: Boolean(requestOptions.marqueeAdsEnabled),
                mvpdHash: requestOptions.mvpdHash,
                obfuscatedFreewheelPersonaId: configuredRequestData.obfuscatedFreewheelPersonaId,
                obfuscatedFreewheelProfileId: configuredRequestData.obfuscatedFreewheelProfileId,
                personaSegment: configuredRequestData.personaSegments?.join('|'),
                platform: platform,
                playerHeightPixels: requestOptions.playerDimensions?.height || 1080,
                playerName: configuredRequestData.playerName ?? appIdentifier,
                playerVersion: requestOptions.playerVersion,
                playerWidthPixels: requestOptions.playerDimensions?.width || 1920,
                playlistClipPosition,
                playlistName,
                sdkName: requestOptions.sdkName,
                sdkVersion: requestOptions.sdkVersion,
                slePreRoll: requestOptions.slePreRoll,
                streamSubType: streamTypes.streamSubType,
                streamType: streamTypes.streamType,
                subtitleLanguage,
                territory: requestOptions.territory,
                usPrivacy: requestOptions.usPrivacy,
                variantId,
                videoDurationInSeconds,
            };

            // If not live, short form, or SLE - add bc (bingeCount) value to VAC request
            if ([PlaybackType.Live, PlaybackType.SingleLiveEvent, PlaybackType.Clip].includes(requestOptions.playbackType)) {
                delete requestData.bc;
            }

            if (requestOptions.isMultiview) {
                requestData.multiplayer = MultiPlayerDisplayType.MultiView;
            }

            if (platform !== Platform.x1) {
                delete requestData.locationPostalCode;
            }

            return requestData;
        }

        public async fetchVacResponse(requestOptions: RequestOptions): Promise<ResponseData> {
            const requestData = this.getVacRequestParameters(requestOptions);
            let umvToken = await this.umvToken;

            if (typeof umvToken === 'undefined' && this.getToken) {
                umvToken = await this.getToken();
            }
            const headers = umvToken !== 'null' && typeof umvToken !== 'undefined' ? new Headers({ 'X-SkyOTT-UserToken': umvToken }) : undefined;

            if (this.config.requestTimeoutMs) {
                sdkLogger.verbose('Configured VAC timeout: ', this.config.requestTimeoutMs);
            }

            return promiseWithTimeout(
                fetch(appendQueryParams(this.config.url, requestData as QueryParams), { headers }),
                this.config.requestTimeoutMs || VAM_PROMISE_DEFAULT_TIMEOUT
            ).then(this.processVacResponse, (error: Error) => {
                throw new Error(`Request to VAC failed because ${error.message}`);
            });
        }

        private getPlaylistInfo(isLinear: boolean): Pick<RequestParameters, 'playlistClipPosition' | 'playlistName' | 'channelName'> {
            let playlistName: string | undefined;
            let playlistClipPosition: number | undefined;
            let channelName;

            const assetMetadata = this.reportingData.assetMetadata;

            if (assetMetadata?.isVodChannel) {
                channelName = assetMetadata?.channelName;
            } else {
                playlistName = assetMetadata?.playlistName;
                playlistClipPosition = assetMetadata?.playlistClipPosition;
            }

            if (isLinear && assetMetadata?.channelName) {
                channelName = assetMetadata.channelName;
            }
            return { channelName, playlistName, playlistClipPosition };
        }

        private calculateVideoDuration(isLinear: boolean, durationMs?: number): number {
            let videoDurationInSeconds;
            if (isLinear) {
                videoDurationInSeconds = 600;
            } else {
                // No ADS will be added if no duration is provided or if it's 0,
                // fall back to 600 seconds instead.
                videoDurationInSeconds = durationMs ? durationMs / 1000 : 600;
            }

            return videoDurationInSeconds;
        }

        private detectVariantId(adProvider: AdInsertionType, deviceType: DeviceType, streamingProtocol: StreamingProtocol): string | undefined {
            const isMultiplayerCsaiOverride = TestingOverrides.adInsertionOverride === AdInsertionType.MultiPlayerCsai;
            const mediatailorVariantId =
                streamingProtocol === StreamingProtocol.DASH && deviceType === DeviceType.Xfinity ? 'mediatailor_dash' : 'mediatailor';
            if (isMultiplayerCsaiOverride || isOldWebOs()) {
                return 'webos3';
            }
            return deviceType === DeviceType.WebMaf && adProvider === AdInsertionType.Yospace ? undefined : mediatailorVariantId;
        }

        private detectPlatform(deviceType: DeviceType, deviceModel?: DeviceModel): Platform {
            let platform = deviceType === DeviceType.Ion && deviceModel ? ionPlatformMap[deviceModel] : devicePlatformMap[deviceType];

            const isYospaceOverride = TestingOverrides.adInsertionOverride === AdInsertionType.Yospace;
            const isMultiplayerCsaiOverride = TestingOverrides.adInsertionOverride === AdInsertionType.MultiPlayerCsai;
            if (isYospaceOverride || isMultiplayerCsaiOverride) {
                platform = Platform.lgtv;
            } else if (checkIsFireTv(deviceType)) {
                platform = Platform.firetv;
            } else if (isSafariDesktop(CoreVideoInternal.deviceInfo, deviceType)) {
                platform = Platform.safari;
            } else if (isXClass(deviceType, CoreVideoInternal.deviceInfo)) {
                platform = Platform.xclass;
            } else if (isXumosb(deviceType, CoreVideoInternal.deviceInfo)) {
                platform = Platform.xumosb;
            } else if (isX1(deviceType, CoreVideoInternal.deviceInfo)) {
                platform = Platform.x1;
            }

            return platform as Platform;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        private async processVacResponse(response: Response): Promise<any> {
            let responseBody;
            try {
                responseBody = await response.json();
            } catch (_) {
                let message;
                if (response.status === 204) {
                    message = 'No ad configuration returned for COPPA asset';
                } else {
                    message = 'No error message in VAM response';
                }
                // TODO: NBCU VAC specific logic should be shifted to the VAC Addon
                // https://github.com/sky-uk/core-video-team/issues/7817
                // eslint-disable-next-line no-throw-literal
                throw {
                    status: response.status,
                    message,
                };
            }

            if (response.status !== 200) {
                // TODO: NBCU VAC specific logic should be shifted to the VAC Addon
                // https://github.com/sky-uk/core-video-team/issues/7817
                // eslint-disable-next-line no-throw-literal
                throw {
                    status: response.status,
                    message: responseBody.message,
                };
            }

            if (responseBody.globalParameters && TestingOverrides.isForcedUnitedStatesIpAddress) {
                const unitedStatesIpAddress = '72.229.28.185';
                responseBody.globalParameters.vip = unitedStatesIpAddress;
            }

            return responseBody;
        }

        private electVacStreamType(playbackType: PlaybackType): StreamType | undefined {
            switch (playbackType) {
                case PlaybackType.Live:
                    return StreamType.linear;
                case PlaybackType.VOD:
                    return StreamType.vod;
                case PlaybackType.SingleLiveEvent:
                    return StreamType.live;
                default:
                    break;
            }
        }

        private electVacVodStreamSubtype(assetMetadata: AssetMetadata): StreamSubType {
            if (assetMetadata.isVodChannel) {
                return StreamSubType.vodChannel;
            }

            switch (assetMetadata.type) {
                case AssetType.Episode:
                    return StreamSubType.fullEpisodePlayer;
                case AssetType.Programme:
                    return StreamSubType.movie;
                default:
                    return StreamSubType.longForm;
            }
        }

        private convertToVACStreamTypes(
            reportingData: ReportingData,
            playbackType: PlaybackType
        ): { streamType: StreamType; streamSubType?: StreamSubType } {
            let streamType: StreamType | undefined;
            let streamSubType: StreamSubType | undefined;

            if (playbackType === PlaybackType.Clip) {
                return {
                    streamType: StreamType.vod,
                    streamSubType: StreamSubType.shortForm,
                };
            }

            if (playbackType === PlaybackType.FER) {
                return {
                    streamType: StreamType.vod,
                    streamSubType: StreamSubType.fer,
                };
            }
            // eslint-disable-next-line prefer-const
            streamType = this.electVacStreamType(playbackType)!;
            if (streamType === StreamType.linear) {
                if (reportingData.assetMetadata && reportingData.assetMetadata.isExclusiveChannel) {
                    streamSubType = StreamSubType.exclusiveChannel;
                }
            } else if (streamType === StreamType.vod) {
                if (reportingData.assetMetadata) {
                    const assetMetadata = reportingData.assetMetadata;

                    streamSubType = this.electVacVodStreamSubtype(assetMetadata);
                } else {
                    streamSubType = StreamSubType.longForm;
                }
            }

            return {
                streamType,
                streamSubType,
            };
        }
    }
}
