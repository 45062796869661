import type { ClientNielsenConfig } from '../../../addons/reporting/nielsen-addon/nielsen-types';
import type { AddonConfig } from '../../internal-config';
import type { RemoteConfigSplit } from '../../sdk-config';
import type { DeclaredOptionalsAddonsConfig, InternalReportingConfig, RequiredPropertiesDefinition } from './addons-config-adapter-common';
import { genericConfigEnabledCheck } from './addons-config-adapter-common';

// Internal Config
type NielsenInternalConfig = InternalReportingConfig['nielsen'];

// Client Config when Remote Config is disabled
export type NielsenClientConfig = AddonConfig<ClientNielsenConfig>;
export function adaptNielsenClientConfig(config: NielsenClientConfig | undefined): NielsenInternalConfig {
    return config;
}

type NielsenRemoteConfigProperties = 'enabledPlaybackTypes';

// Server Config when Remote Config is enabled
type NielsenRemoteServerConfig = AddonConfig<Pick<ClientNielsenConfig, NielsenRemoteConfigProperties>>;

// Client Config when Remote Config is enabled
type NielsenRemoteClientConfig = Omit<ClientNielsenConfig, NielsenRemoteConfigProperties>;

export type NielsenRemoteConfigSplit = RemoteConfigSplit<NielsenRemoteServerConfig, NielsenRemoteClientConfig>;
export function adaptNielsenRemoteConfigSplit(config: NielsenRemoteConfigSplit): DeclaredOptionalsAddonsConfig<NielsenInternalConfig> {
    const requiredFields: RequiredPropertiesDefinition<NielsenRemoteClientConfig> = {
        enableProduction: null,
        graceId: null,
    };
    const isEnabled = genericConfigEnabledCheck(config, requiredFields, 'Conviva');
    if (!isEnabled) {
        return;
    }

    const { clientConfig, remoteConfig } = config;

    return {
        enabled: isEnabled,
        config: {
            enabledPlaybackTypes: remoteConfig!.config.enabledPlaybackTypes,
            cloudAppId: clientConfig!.cloudAppId,
            sdkAppId: clientConfig!.sdkAppId,
            enableProduction: clientConfig!.enableProduction,
            graceId: clientConfig!.graceId,
        },
    };
}
