import type { AddonConfig, InternalConfig } from '../../internal-config';
import type { DeclaredOptionals, PickRequired } from '../../../utils/ts';
import type { RemoteConfigSplit } from '../../sdk-config';
import { sdkLogger } from '../../../logger';

export type InternalAddonsConfig = InternalConfig['addons'];
export type InternalReportingConfig = NonNullable<InternalAddonsConfig['reporting']>;

// A utility type for addons config which turns top level optionals in the config
// object to required properties which can be undefined. This is
// useful to help guarantee that all properties from the client
// config are proxied to internal config without using a rest-spread
// which would risk overwriting values from the remote config.
export type DeclaredOptionalsAddonsConfig<T extends AddonConfig<unknown> | undefined> = T extends AddonConfig<unknown>
    ? { enabled: boolean; config: DeclaredOptionals<T['config']> }
    : undefined;

// We can use this type to ensure all required properties are specified
// in an object which can be used to validate against client config.
//
// This is important since we will be taking ownership of enabling and disabling addons
// so we need to be careful of enabling addons where clients have not specified
// valid config
export type RequiredPropertiesDefinition<T> = { [K in keyof PickRequired<NonNullable<T>>]: null };

export function checkRequiredConfigProperties(config: object, requiredProperties: RequiredPropertiesDefinition<unknown>): Array<string> {
    const missingKeys = Object.keys(requiredProperties).filter((k) => config[k as keyof typeof config] === undefined);
    return missingKeys;
}

export function genericConfigEnabledCheck(
    config: RemoteConfigSplit<AddonConfig<unknown>, object>,
    requiredClientProperties: RequiredPropertiesDefinition<unknown>,
    addonName: string
): boolean {
    const { clientConfig, remoteConfig } = config;
    if (!remoteConfig?.enabled) {
        return false;
    }

    const missingClientFields = checkRequiredConfigProperties(clientConfig || {}, requiredClientProperties);
    if (missingClientFields.length > 0) {
        sdkLogger.warn(
            `${addonName} Addon was enabled by the remote config but was missing required client config for: [${missingClientFields.join(', ')}]`
        );
        return false;
    }

    return true;
}
