import type { SessionControllerInternal } from '../../../core/session-controller/session-controller-internal';
import { extractNonLinearAdsFromVmap } from '../parsers/vmap-non-linear-ad-extractor';
import { PixelFetch } from '../pixel-fetch';

import { mapNonLinearAdDataToEvent } from '../non-linear-adverts/map-non-linear-ad-data-to-event';
import type { NonLinearAd, NonLinearAdEvent, NonLinearAdData } from '../non-linear-adverts/non-linear-ad-types';

type PausingSessionController = Pick<
    SessionControllerInternal,
    'notifyPauseAd' | 'onAdBreakStarted' | 'onAdBreakFinished' | 'onPlaybackTimelineUpdated'
>;

// NOTE: The logic of how and when to raise events
// is a bit convoluted. For detail, see the flow chart here:
// https://wiki.at.sky/display/CC/Peacock+Ads
export class PauseAdvertDispatcher {
    private ads: Array<NonLinearAdEvent> = [];
    private isLinearAdShowing = false;
    private isPrerollComplete = false;

    constructor(
        private sessionController: PausingSessionController,
        private pixelFetch: PixelFetch = new PixelFetch(self as unknown as Window)
    ) {
        sessionController.onAdBreakStarted(() => (this.isLinearAdShowing = true));
        sessionController.onAdBreakFinished(() => (this.isLinearAdShowing = false));
        sessionController.onPlaybackTimelineUpdated(({ position }) => {
            if (position > 0) {
                // TODO handle linear prerolls
                this.isPrerollComplete = true;
            }
        });
    }

    public addAdEvents(adEvents: Array<NonLinearAdEvent>): void {
        this.ads = this.ads.concat(adEvents);
    }

    public addAdEventsFromAdData(ads: Array<NonLinearAdData>): void {
        this.addAdEvents(ads.map((ad) => mapNonLinearAdDataToEvent(ad, this.pixelFetch)));
    }

    public addAdEventsFromVmap(vmapDocument: string): void {
        const ads = extractNonLinearAdsFromVmap(vmapDocument);
        this.addAdEventsFromAdData(ads);
    }

    public registerUserPause(): void {
        if (!this.ads.length) {
            return;
        }
        if (!this.isPrerollComplete) {
            return;
        }
        if (this.isLinearAdShowing) {
            return;
        }
        this.sessionController.notifyPauseAd(this.makeNonLinearAdEvent());
    }

    private makeNonLinearAdEvent(): {
        consume: () => NonLinearAd | null | undefined;
    } {
        // this logic ensures that if consume is not called,
        // the same Pause ad is reused the next time an event is raised.
        return {
            consume: () => {
                const adEvent = this.ads.shift();
                return adEvent?.consume();
            },
        };
    }
}
export type { NonLinearAdData };
