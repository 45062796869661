import type { AudioTrackMetadata } from '../core/player/playout-data';
import { AudioRole } from '../core/player/role';
import type { Track } from '../core/player/track';

export function getPreferredTrackFromMetadata(
    preferredMetadataArr: Array<AudioTrackMetadata> = [],
    availableTrackArr: Array<Track> = []
): Track | undefined {
    let preferredTrack: Track | undefined;
    const myMap = new Map<string, Track>();

    for (const availableTrack of availableTrackArr) {
        myMap.set(availableTrack.languageTag + '-' + !!availableTrack.roles?.includes(AudioRole.Description), availableTrack);
        myMap.set(availableTrack.language + '-' + !!availableTrack.roles?.includes(AudioRole.Description), availableTrack);
    }

    for (const preferredMetadata of preferredMetadataArr) {
        preferredTrack = myMap.get(preferredMetadata.languageTag + '-' + !!preferredMetadata.isAudioDescription);
        if (preferredTrack) {
            break;
        }
    }
    return preferredTrack;
}
